import { createTheme } from '@mui/material/styles';
import { defaultLightTheme } from 'react-admin';

export const theme = createTheme({
  ...defaultLightTheme,
  palette: {
    primary: process.env.REACT_APP_ENVIRONMENT === 'test' ? {
      main: '#2943f0',
      light: '#adb5f0',
      dark: '#0619a1',
    } : {
      main: '#e65017',
      light: '#ffa464',
      dark: '#b62100',
    },
    secondary: process.env.REACT_APP_ENVIRONMENT === 'test' ? {
      main: '#065ea1',
      light: '#7ab8e6',
      dark: '#052b47',
    } : {
      main: '#e65017',
      light: '#ffa464',
      dark: '#b62100',
    },
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
      root: {
        border: '1px solid #e0e0e3',
        backgroundClip: 'padding-box',
      },
    },
  },
  sidebar: {
    width: 280
  },
  components: {
    ...defaultLightTheme.components,
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: '3px solid #fff',
          '&.RaMenuItemLink-active': {
            borderLeft: '3px solid #e65017',
          },
        },
      },
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          "& .RaDatagrid-headerCell": {
            backgroundColor: process.env.REACT_APP_ENVIRONMENT === 'test' ? "#e6e9ff": "#ffece6",
          },
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          color: '#e65017'
        }
      }
    }
  },
})
