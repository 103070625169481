import { PERMISSIONS } from '../../constants';
import list from './ListBulkSend';
import create from './CreateBulkSend';
import show from './ShowBulkSend';

export default {
  list,
  create,
  show,
  name: 'bulk-sends',
  permissions: [PERMISSIONS.BULK_SEND]
}
