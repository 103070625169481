import React, { useState } from "react";
import { SimpleForm, TextInput, minLength, useDataProvider, useNotify, useRecordContext, useRefresh } from "react-admin";
import { Box, Button } from "@mui/material";
import RecoveryIcon from "@mui/icons-material/Restore";

const RecoveryUserExpand = () => {
  const [isLoading, setIsLoading] = useState(false);
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleRecovery = () => {
    setIsLoading(true);
    dataProvider.update('employees/recovery', {
      id: record.id,
      data: { ...record },
      previousData: { ...record, password: null },
    }).then(() => refresh())
      .catch((error) => notify(error.message, { type: 'error' }))
      .finally(() => setIsLoading(false));
  };

  return (
    <SimpleForm toolbar={false}>
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextInput
          source="password"
          label="Nova Senha"
          onChange={({ target }) => record.password = target.value}
          style={{ marginLeft: 8 }}
          validate={minLength(6)}
          disabled={isLoading}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleRecovery}
          style={{ marginLeft: 16, marginBottom: 16 }}
          startIcon={<RecoveryIcon />}
          disabled={isLoading}
        >
          Recuperar Usuário
        </Button>
      </Box>
    </SimpleForm>
  );
};

export default RecoveryUserExpand;
