import React from 'react';
import { ReferenceArrayInput, SelectArrayInput } from 'react-admin';
import { TagType } from '../../../models';

const FormCustomer: React.FC<any> = () => (
  <>
    <ReferenceArrayInput
      sort={{ field: 'name', order: 'ASC' }}
      //@ts-ignore
      perPage={null}
      source="tagIds"
      reference="chains/chainId/tag"
      filter={{ type: TagType.client }}
    >
      <SelectArrayInput style={{ minWidth: 260 }} label="Etiquetas" optionText="name" />
    </ReferenceArrayInput>
  </>
);

export default FormCustomer;