import StarsIcon from '@mui/icons-material/Stars';
import { PERMISSIONS } from '../../constants';
import list from './ratingList';
import show from './ratingShow';

export default {
  list,
  show,
  name: 'ratings',
  permissions: [PERMISSIONS.LIST_RATINGS],
  options: { label: 'Avaliações' },
  icon: StarsIcon,
};