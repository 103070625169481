import { Typography } from '@mui/material';
import TrueIcon from '@mui/icons-material/Done';
import FalseIcon from '@mui/icons-material/Clear';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import {
  ArrayField,
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  EditButton,
  EmailField,
  Filter,
  FunctionField,
  Labeled,
  List,
  NumberField,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  SelectArrayInput,
  SelectField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useListContext,
  usePermissions,
  useRecordContext,
} from "react-admin";
import { FillinsDatagrids, Permission, PrintButton, ReportPrint, Tooltip } from '../../components';
import { BetweenDatesInput } from '../../components/BetweenDatesInput';
import { CNPJField, PhoneField } from '../../components/Fields';
import ListPagination from '../../components/ListPagination';
import { PERMISSIONS, daysOfTheWeek, minimumValueCashbackToolTip } from '../../constants';
import useExpandedEvery from '../../hooks/useExpandedEvery';
import { FinancePaymentMethodType, TagType } from '../../models';
import { formatNumber } from '../../utils';
import dayjs from 'dayjs';


const useStyles = makeStyles(theme => ({
  listQrcodes: {
    padding: 20,
  },
  title: {
    margin: '10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  filterTablePrint: {
    display: 'none',
    '@media print': {
      display: 'block'
    },
    marginBottom: 10,
  },
  titleCard: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
}));

const QrcodePanel = () => {
  const record = useRecordContext();
  if (!record) { return null; }

  return (
    <Datagrid bulkActionButtons={false} data={record.hoses}>
      <TextField source="hoseNumber" label="Bico" textAlign="center" />
      <TextField source="fuel.name" label="Combustível" textAlign="center" />
    </Datagrid>
  );
};

const ListQrcodes = () => {
  const record = useRecordContext();
  const qrCodesRef = useRef();
  if (!record) { return null; }

  const ActionsQrCodes = () => {
    const { data } = useListContext();
    const [expandedEvery, toggleExpandedEvery] = useExpandedEvery("station-qrcodes", (data || []).map((code) => code.id));

    useEffect(() => {
      if (!expandedEvery) {
        toggleExpandedEvery();
      }
    }, [data]);


    const onClick = () => {
      if (!expandedEvery) {
        toggleExpandedEvery();
      }
    }

    return (
      <TopToolbar>
        <PrintButton
          onClick={onClick}
          printRef={qrCodesRef}
        />
      </TopToolbar>
    )
  }

  const DataGridStationQrCode = () => {
    const props = useListContext();
    const data = (props.data || []).filter((station) => station.hoses.some((hose) => hose.hoseNumber));

    return (
      <Datagrid bulkActionButtons={false} expand={<QrcodePanel />} data={data}>
        <TextField source="qrcode" label="Qrcode" sortable={false} />
      </Datagrid>
    );
  }

  return (
    <List
      title=" "
      resource="station-qrcodes"
      filter={{ numericIds: record.numericIds }}
      pagination={false}
      exporter={false}
      actions={<ActionsQrCodes />}
      syncWithLocation={false}
    >
      <ReportPrint ref={qrCodesRef} title="Mapa de Pista">
        <DataGridStationQrCode />
      </ReportPrint>
    </List>
  )
};

const FilterTablePrint = ({ stationName }) => {
  const classes = useStyles();
  const { filterValues } = useListContext();

  return (
    <Datagrid bulkActionButtons={false} data={[{ ...filterValues, stationName }]} total={1}>
      <DateField source="from" textAlign="center" label="De" sortable={false} />
      <DateField source="to" textAlign="center" label="Até" sortable={false} />
      <ReferenceArrayField label="Combustíveis" source="fuelIds" textAlign="center" basePath="fuels" reference="fuels">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source="stationName" label="Posto" />
    </Datagrid>
  );
};

const FilterFillin = (props) => {
  // let propsCopy = Object.assign({}, props);
  // delete propsCopy.record.fuelIds;
  const record = useRecordContext();
  if (!record) { return null; }

  return (
    <Filter>
      <BetweenDatesInput alwaysOn />
      <ReferenceArrayInput
        source="fuelIds"
        reference="fuels"
        sort={{ field: "name", order: "ASC" }}
        perPage={null}
        alwaysOn
      >
        <SelectArrayInput
          style={{ minWidth: 180 }}
          label="Combustíveis"
          optionText={"name"}
        />
      </ReferenceArrayInput>
    </Filter>
  );
};

const ListFillins = ({ ...props }) => {
  const station = useRecordContext();
  const classes = useStyles();
  const fillinsRef = useRef();

  const ListFillinsActions = () => (
    <TopToolbar>
      <PrintButton
        printRef={fillinsRef}
      />
    </TopToolbar>
  );

  return (
    <List
      title=" "
      resource="fillins"
      sort={{ field: "createdAt", order: "DESC" }}
      filterDefaultValues={{
        from: moment().startOf("day").subtract(1, "month").toISOString(),
        to: moment().endOf("day").toISOString()
      }}
      filter={{ stationIds: [station.id] }}
      perPage={25}
      pagination={<ListPagination />}
      exporter={false}
      actions={<ListFillinsActions />}
      filters={<FilterFillin {...props} />}
    >
      <ReportPrint ref={fillinsRef} title="Abastecimentos" landscape>
        <div className={classes.filterTablePrint}>
          <Typography className={classes.titleCard}>Filtros</Typography>
          <FilterTablePrint stationName={station.name} />
        </div>
        <FillinsDatagrids />
      </ReportPrint>
    </List>
  );
};

const StationIdentify = () => {
  const NumericIdField = () => {
    const record = useRecordContext();
    if (!record) { return null; }

    return record.numericIds?.map((_, ind) => (
      <ChipField source={`numericIds[${ind}]`} />
    ));
  };

  return (
    <SimpleShowLayout sx={{ padding: 0 }}>
      <TextField label="ID" source="id" />
      {
        Permission({
          permission: PERMISSIONS.ADMIN,
          children: (
            <ArrayField source="numericIds" label="IDs Numéricos">
              <NumericIdField />
            </ArrayField>
          )
        })
      }
      <TextField source="name" label="Nome" />
      <EmailField source="email" label="Email" emptyText='-' />
      <CNPJField source="cnpj" label="CNPJ" />
      <PhoneField source="phone" label="Telefone" />
      {Permission({
        permission: PERMISSIONS.ADMIN,
        children: (
          <ReferenceField
            source="chainId"
            reference="chains"
            sort={{ field: 'name', order: 'ASC' }}
            label="Rede"
            link={false}
          >
            <TextField
              source="name"
            />
          </ReferenceField>
        )
      })}
      <ReferenceArrayField
        sort={{ field: 'name', order: 'ASC' }}
        source="fuelIds"
        reference="fuels"
        label="Combustível"
      >
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField
        style={{ minWidth: 260 }}
        sort={{ field: 'name', order: 'ASC' }}
        source="serviceIds"
        reference="services"
      >
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>

      <ReferenceArrayField
        style={{ minWidth: 260 }}
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ type: TagType.station }}
        source="tagIds"
        label="Etiquetas"
        reference="chains/chainId/tag"
      >
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      {
        Permission({
          permission: PERMISSIONS.ADMIN,
          children: (
            <TextField
              label="Token RTI"
              source="rtiToken"
            />)
        })
      }
      {
        Permission({
          permission: PERMISSIONS.ADMIN,
          children: (
            <BooleanField
              label="Preset por Valor (Encher tanque)"
              source="presetUsingValue"
            />)
        })
      }
      <BooleanField label="Aceita cashback?" source="acceptsCashback" />
      <Tooltip title={minimumValueCashbackToolTip} placement="top-start" sxContainer={{ display: "-webkit-box" }}>
        <Labeled>
          <NumberField emptyText="R$ 0.00" source="minimumValueToRedeemCashback" label="Mínimo para resgate de cashback" sx={{ minWidth: 220 }} options={{ style: 'currency', currency: 'BRL' }} />
        </Labeled>
      </Tooltip>
      <BooleanField label="Usar preset no abastecimento" source="useFillinPreset" />
      <BooleanField label="Usar níveis de preço" source="usePriceTiers" />
      <BooleanField label="Usar integração com Software House" source="useIntegrationSoftwareHouse" />
      <BooleanField label="Usar Acompanhamento de Abastecimento" source="useFillinFollowUp" />
      <BooleanField label="Encher Tanque (999 litros)" source="usePresetLessThan1000" />
    </SimpleShowLayout>
  )
}

const StationAddress = () => (
  <SimpleShowLayout sx={{ padding: 0 }}>
    <TextField source="street" label="Rua" />
    <TextField source="streetNumber" label="Número" />
    <TextField source="neighbourhood" label="Bairro" />
    <TextField source="city" label="Cidade" />
    <TextField source="state" label="Estado" />
    <TextField source="cep" label="CEP" />
    <NumberField source="latitude" label="Latitude" />
    <NumberField source="longitude" label="Longitude" />
  </SimpleShowLayout>
)

const StationGps = () => (
  <SimpleShowLayout sx={{ padding: 0 }}>
    <ArrayField source="coordinates" label="GPS">
      <Datagrid bulkActionButtons={false}>
        <NumberField label="Longitude" source="longitude" sortable={false} textAlign="center" />
        <NumberField label="Latitude" source="latitude" sortable={false} textAlign="center" />
      </Datagrid>
    </ArrayField>
  </SimpleShowLayout>
);

const StationSync = () => {
  const record = useRecordContext();
  if (!record) { return null; }

  return (
    <SimpleShowLayout sx={{ padding: 0 }}>
      <SelectField
        label="Sistema"
        source="syncType"
        choices={
          [
            { id: 'company-tec', name: 'CompanyTec' },
            { id: 'ez-tec', name: 'EZTec' },
            { id: 'auto-system', name: 'AutoSystem' },
          ]
        }
      />
      {
        record.syncType === "auto-system" &&
        <SimpleShowLayout sx={{ padding: 0 }}>
          <TextField
            source="credentials.host"
            label="Host ou ip"
          />
          <NumberField
            source="credentials.port"
            label="Porta"
          />
          <TextField
            source="credentials.user"
            label="Usuário"
          />
          <TextField
            source="credentials.password"
            label="Senha"
            type="password"
          />
          <TextField
            source="credentials.database"
            label="Banco"
          />
          {
            record.lastInsertedTransaction && (
              <TextField
                label="Último Ponto da Sincronização"
                source="lastInsertedTransaction"
              />
            )
          }
        </SimpleShowLayout>
      }
    </SimpleShowLayout>
  )
}

const StationOpeningHours = () => (
  <ArrayField source="openingHours" label="Horários">
    <Datagrid rowClick={false} bulkActionButtons={false}>
      <FunctionField render={record => record && daysOfTheWeek.find(wd => wd.id === record.dayOfTheWeek)?.name} label="Dia" />
      <FunctionField label="Hora de abertura" render={record => {
        if (!record || record.closed || record.open24hrs || !record.startTime) {
          return "--"
        }
        return dayjs(record.startTime).format('HH:mm');
      }} />
      <FunctionField label='Hora de fechamento' render={record => {
        if (!record || record.closed || record.open24hrs || !record.endTime) {
          return "--"
        }
        return dayjs(record.endTime).format('HH:mm');
      }} />
      <FunctionField label="Aberto 24 Horas" textAlign="center"
        render={record => record && (record.open24hrs && !record.closed) ? <TrueIcon fontSize="small" /> : <FalseIcon fontSize="small" />} />
      <BooleanField source="closed" label="Fechado" textAlign="center" sortable={false} />
    </Datagrid>
  </ArrayField>
);

const StationFinancial = () => (
  <SimpleShowLayout sx={{ padding: 0 }}>
    <ReferenceField
      label="Revenda"
      source="retailerId"
      reference="retailers"
      textAlign="center"
      emptyText="Revenda não identificada"
      link={false}
      sortable={false}
    >
      <TextField source="name" />
    </ReferenceField>
    <SelectField
      source="commissioningType"
      label="Tipo de comissão"
      choices={[{ id: 'fixed', name: 'Fixada' }, { id: 'percentage', name: 'Porcentagem' }]}
    />
    <FunctionField label="Porcentagem da revenda"
      textAlign="center"
      render={record => {
        if (record && record.commissioningType === 'percentage') {
          return (
            record.commissioningValue ? `${record.commissioningValue}%` : '0%'
          );
        } else {
          return formatNumber(record?.commissioningValue || 0, { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
      }}
    />
    <NumberField
      source="monthlyPrice"
      label="Valor do Faturamento"
      textAlign="center"
      emptyText='R$ 0,00'
      options={{
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }}
    />
    <DateField source="billingStartDate" label="Data de início do faturamento" emptyText="--" />
    <TextField source="emailInvoice" label="E-mail para envio da nota fiscal" />
    <TextField source="emailBill" label="E-mail para envio do boleto" />
    <NumberField
      source="installationCost"
      label="Valor da instalação"
      textAlign="center"
      emptyText='R$ 0,00'
      options={{
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }}
    />
    <NumberField source="monthlyDueDate" label="Vencimento da mensalidade (dia)" />
    <NumberField source="gracePeriod" label="Período de carência" />
    <SelectField source="financePaymentMethod" label="Forma de pagamento" choices={FinancePaymentMethodType.financePaymentMethodChoices} />
    <TextField source="responsible" label="Responsável" />
    <PhoneField source="contactResponsible" label="Contato" />
  </SimpleShowLayout>
);

const StationActions = () => {
  const { permissions } = usePermissions();
  return (
    <TopToolbar>
      {permissions && [
        PERMISSIONS.UPDATE_STATION,
        PERMISSIONS.REGISTER_STATION_OPENING_HOURS,
        PERMISSIONS.REGISTER_STATION_ADDRESS,
        PERMISSIONS.REGISTER_STATION_FINANCIAL,
        PERMISSIONS.REGISTER_STATION_PAYMENT,
        PERMISSIONS.REGISTER_STATION_GPS,
        PERMISSIONS.REGISTER_STATION_CONCENTRATOR,
        PERMISSIONS.REGISTER_STATION_SYNCHONIZATION,
        PERMISSIONS.REGISTER_STATION_IDENTIFICATION,
      ].some(((permission) => permissions.includes(permission))) && (
          <EditButton />
        )}
    </TopToolbar>
  )
}

export default () => {
  return (
    <Show actions={<StationActions />}>
      <TabbedShowLayout>
        <Tab label="Identificação">
          <StationIdentify />
        </Tab>
        <Tab label="Endereço">
          <StationAddress />
        </Tab>
        <Tab label="GPS">
          <StationGps />
        </Tab>
        {
          Permission({
            permission: PERMISSIONS.ADMIN,
            children: (
              <Tab label="Sincronização">
                <StationSync />
              </Tab>
            )
          })
        }
        <Tab label="Horários">
          <StationOpeningHours />
        </Tab>
        {
          Permission({
            permission: PERMISSIONS.REGISTER_STATION_FINANCIAL,
            children: (
              <Tab label="Financeiro">
                <StationFinancial />
              </Tab>
            )
          })
        }
        <Tab label="Mapa de Pista">
          <ListQrcodes />
          <br />
        </Tab>
        {
          Permission({
            permission: PERMISSIONS.LIST_FILLINS,
            children: (
              <Tab label="Abastecimentos" path="fillins">
                <ListFillins />
              </Tab>
            )
          })
        }
      </TabbedShowLayout>
    </Show>
  )
}
