import ConfirmationIcon from '@mui/icons-material/ConfirmationNumber'
import { PERMISSIONS } from '../../constants'
import list from './CouponSearch'

export default {
  list,
  name: 'coupons',
  permissions: [PERMISSIONS.VALIDATE_COUPON],
  options: { label: 'Validar Cupom' },
  icon: ConfirmationIcon,
}
