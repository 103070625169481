export enum FillinOrigin {
  app = 'app',
  externalApi = 'external_api',
  autoSystem = 'auto_system',
  manuallyAdded = 'manually_added'
};

export namespace FillinOrigin {
  export const translateOrigin = (origin: FillinOrigin): string => {
    switch (origin) {
      case FillinOrigin.app:
        return "Aplicativo"
      case FillinOrigin.externalApi:
        return "Parceiros Externos";
      case FillinOrigin.autoSystem:
        return "Auto System";
      case FillinOrigin.manuallyAdded:
        return "Adicionado Manualmente";
      default: return "";
    };
  };

  export const fillinOriginChoices = Object.values(FillinOrigin).filter(x => typeof x === "string")
    .map(type => {
      return ({
        id: type,
        name: FillinOrigin.translateOrigin(type)
      })
    });
};
