import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { BooleanInput, DateTimeInput, FormDataConsumer, Labeled, TextInput, maxLength, minValue, required } from 'react-admin';
import { RichTextInputCustom } from '../../../components/RichTextInputCustom';
import { addMinuteToDate } from '../../../lib';

const FormNotification: React.FC<{ formData?: any }> = ({ formData }) => {
  const classes = useStyles();

  const [counterCharSms, setCounterCharSms] = React.useState(0);
  const [counterCharPush, setCounterCharPush] = React.useState(0);

  const MAX_LENGTH_SMS = 160;
  const MAX_LENGTH_PUSH = 130;

  const nowDatePlus10Minutes = () => addMinuteToDate(new Date(), 10);

  const counterCharInput = (inputName: string, valueInput: string) => {
    const valueFormater = valueInput.length
    switch (inputName) {
      case 'sms':
        setCounterCharSms(valueFormater);
        break;

      case 'push':
        setCounterCharPush(valueFormater);
        break;

      default:
        break;
    }
  }

  return (
    <>
      <BooleanInput source="hasNotification" label="Ativar notificação" defaultValue={false} />
      {
        formData?.hasNotification &&
        <>
          <TextInput
            source="notification.title"
            label="Título"
            InputProps={{ autoComplete: 'off' }}
            // validate={[required(), maxLength(65)]}
            style={{ minWidth: 500 }}

          />
          <div className={classes.richText}>
            <Labeled label="Descrição: ">
              <RichTextInputCustom
                source="notification.body"
                label="Essa mensagem pode conter formatações e será exibida no aplicativo, na tela de notificação. Ela NÃO será enviada na notificação PUSH e no SMS"
                validate={required()}
              />
            </Labeled>
          </div>
          <Labeled label="Enviar via: " fullWidth>
            <>
              {/* <BooleanInput source="sms" label="SMS" /> */}
              <BooleanInput source="notification.pushNotification" label="Notificação no aplicativo" />
            </>
          </Labeled>
          <FormDataConsumer>
            {({ formData }) => {
              if (formData.notification?.sms) {
                return (
                  <Labeled label="Esta mensagem será enviada no SMS: " className={classes.labeledWidth}>
                    <>
                      <TextInput
                        style={{ width: '100%' }}
                        source="notification.smsMessage"
                        label="Mensagem SMS"
                        validate={[required(), maxLength(MAX_LENGTH_SMS)]}
                        inputProps={{ autoComplete: 'off' }}
                        onChange={(e) => counterCharInput('sms', e.target.value)}
                      />
                      <span className={classes.charCounter}>{counterCharSms}/{MAX_LENGTH_SMS}</span>
                    </>
                  </Labeled>
                );
              }
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) => {
              if (formData.notification?.pushNotification) {
                return (
                  <Labeled label="Esta mensagem será enviada na notificação Push:" className={classes.labeledWidth}>
                    <>
                      <TextInput
                        style={{ width: '100%' }}
                        source="notification.pushMessage"
                        label="Mensagem Push"
                        validate={[required(), maxLength(MAX_LENGTH_PUSH)]}
                        inputProps={{ autoComplete: 'off' }}
                        onChange={(e) => counterCharInput('push', e.target.value)}
                        helperText={false}
                      />
                      <span className={classes.charCounter}>{counterCharPush}/{MAX_LENGTH_PUSH}</span>
                    </>
                  </Labeled>
                );
              }
            }}
          </FormDataConsumer>

          <BooleanInput source="notification.sendNow" label="Enviar agora" />
          <FormDataConsumer>
            {({ formData }) => {
              if (formData.notification?.sendNow) {
                return (
                  <Typography variant="subtitle1" gutterBottom>
                    A notifição pode demorar até 10 minutos para ser enviada.
                  </Typography>
                )
              }
            }}
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData }) => {
              if (!formData.notification?.sendNow) {
                return <DateTimeInput
                  source={"notification.sendAt"}
                  label="Data de Envio"
                  validate={[required(), minValue(
                    nowDatePlus10Minutes(),
                    'A hora de envio deve ser no mínimo 10 minutos apartir de agora',
                  )]}
                />
              }
            }}
          </FormDataConsumer>
        </>
      }
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  Toolbar: {
    justifyContent: 'space-between'
  },
  richText: {
    width: 'auto',
    '& .ql-editor': {
      minHeight: 200
    }
  },
  labeledWidth: {
    width: '70%'
  },
  charCounter: {
    top: '35%',
    right: '1%',
    width: 'auto',
    heigth: '100%',
    fontSize: 12,
    opacity: .75,
    fontFamily: 'Helvetica',
    textAlign: 'end',
  },
}));

export default React.memo(FormNotification);