import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import {
    Datagrid,
    DateField,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField,
    useListContext,
    usePermissions
} from 'react-admin';
import { CustomizableDatagrid, ReportList } from '../../components';
import { BetweenDatesInput } from "../../components/BetweenDatesInput";
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import { PERMISSIONS } from "../../constants";
import { getPlaceId } from "../../lib";

const useStyles = makeStyles(theme => ({
    title: {
        margin: '10px 0 10px 0',
        textAlign: 'center',
        fontWeight: 'bold'
    },
}));

const PaymentByPixList = () => {
    const classes = useStyles();
    const props = useListContext();
    let data = [], summaryData = [];

    if (props.data) {
        const reportInfo = props.data[0];

        if (reportInfo) {
            data = reportInfo.data;
            summaryData = [reportInfo.summaryData];
        }
    }

    return (
        <>
            <Typography className={classes.title}>Total</Typography>
            <Datagrid bulkActionButtons={false} style={{ marginBottom: 60 }} data={summaryData} total={1}>
                <NumberField
                    source="total_value"
                    label="Total em Dinheiro"
                    textAlign="center"
                    emptyText="R$ 0,00"
                    options={{
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }}
                    sortable={false}
                />
                <TextField source="total_fillins" textAlign="center" label="Total de abastecimentos" sortable={false} />
                <NumberField
                    source="average_ticket"
                    label="Ticket Médio"
                    textAlign="center"
                    emptyText="R$ 0,00"
                    options={{
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }}
                    sortable={false}
                />
            </Datagrid>

            <Typography className={classes.title}>Pagamentos</Typography>
            <CustomizableDatagrid bulkActionButtons={false} resource={props.resource} data={data} defaultColumns={['place_id', 'chain_id', 'value', 'amount', 'price_per_liter', 'old_price_per_liter', 'status', 'fuel_id', 'created_at']}>
                <ReferenceField label="Posto" emptyText="Posto não identificado" source="place_id" basePath="places" reference="places" link="show">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Cliente" emptyText="Não identificado" source="customer_id" basePath="customers" reference="customers" link="show">
                    <TextField source="name" />
                </ReferenceField>
                <NumberField
                    source="value"
                    label="Valor"
                    textAlign="center"
                    emptyText="R$ 0,000"
                    options={{
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }}
                />
                <TextField
                    source="status"
                    label="Status do pagamento"
                    textAlign="center"
                    emptyText="Indefinido"
                />
                <ReferenceField label="Combustível" emptyText="Não identificado" source="fuel_id" basePath="fuels" reference="fuels" link={null}>
                    <TextField source="name" />
                </ReferenceField>
                <TextField
                    source="devedor_nome"
                    label="Nome do devedor"
                    textAlign="center"
                    emptyText="Indefinido"
                />
                <NumberField
                    source="amount"
                    label="Litros"
                    textAlign="center"
                    emptyText="0"
                    options={{
                        maximumFractionDigits: 2
                    }}
                />
                <DateField source="created_at" label="Data" showTime />
            </CustomizableDatagrid>
        </>
    );
};

const FilterTablePrint = () => {
    const classes = useStyles();
    const { filterValues } = useListContext();
    return (
        <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
            <DateField source="from" textAlign="center" label="De" sortable={false} />
            <DateField source="to" textAlign="center" label="Até" sortable={false} />
            <ReferenceField label="Posto" emptyText="Todos" source="placeId" textAlign="center" basePath="places" reference="places" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Produto" emptyText="Todos" source="fuelId" textAlign="center" basePath="fuels" reference="fuels" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
        </Datagrid>
    );
}

const ReportPaymentsByPix = (props) => {
    const { permissions } = usePermissions();
    const statusChoices = [
        { id: 'ATIVA', name: 'Ativa' },
        { id: 'EXPIRADA', name: 'Expirada' },
        { id: 'CONCLUIDA', name: 'Concluída' },
    ];

    const listFilters = [
        <BetweenDatesInput alwaysOn />,
        <ReferenceInput
            source="placeId"
            reference="stations"
            sort={{ field: "name", order: "ASC" }}
            perPage={null}
            alwaysOn
        >
            <SelectInput
                label="Posto"
                emptyText="Todos"
                optionText={'name'}
            />
        </ReferenceInput>,
        <ReferenceInput source="fuelId" reference="fuels" sort={{ field: "name", order: "ASC" }} alwaysOn>
            <SelectInput
                label="Produto"
                emptyText="Todos"
                optionText={'name'}
            />
        </ReferenceInput>,
        <SelectInput
            label="Status"
            source="status"
            emptyText="Todos"
            alwaysOn
            choices={statusChoices}
        />
    ];

    if (permissions === PERMISSIONS.ADMIN) {
        listFilters.splice(2, 0,
            <ReferenceInput source="chainId" reference="chains" sort={{ field: 'name', order: 'ASC' }} perPage={null} alwaysOn>
                <SelectInput
                    label="Rede"
                    emptyText="Todos"
                    optionText={'name'} />
            </ReferenceInput>,
        )
    }

    const stationsIndex = listFilters.findIndex(({ props }) => props.reference === 'stations');
    if (!!getPlaceId() && stationsIndex > -1) {
        listFilters.splice(stationsIndex, 1);
    }

    return (
        <Permission permission={PERMISSIONS.PAYMENT_BY_PIX_REPORT}>
            <ReportList
                {...props}
                basePath="report-payments-by-pix"
                title="Pagamentos por Pix"
                resource="chains/chainId/reports/payments-by-pix"
                filters={listFilters}
                filter={localStorage.getItem('chainId') ? { chainId: localStorage.getItem('chainId') } : null}
                sort={{ field: 'created_at', order: 'DESC' }}
                perPage={25}
                filterDefaultValues={{
                    placeId: getPlaceId(),
                    from: moment().startOf('day').toISOString(),
                    to: moment().endOf('day').toISOString()
                }}
                pagination={<ListPagination />}
                bulkActionButtons={false}
                titleOnPrint="Relatório de pagamentos por Pix"
                filterTablePrint={<FilterTablePrint />}
                landscape
                showPagination
            >
                <PaymentByPixList />
            </ReportList>
        </Permission>
    )
};

export default ReportPaymentsByPix;