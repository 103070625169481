import React from 'react';
import { List, NumberField, TextField, ReferenceField, Datagrid, EditButton, DeleteButton, CreateButton } from 'react-admin';
import { Wrapper } from '../../components';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';

const FuelList = (props) => {
    return (
        <Permission permission={PERMISSIONS.ADMIN}>
            <Wrapper>
                <List
                    {...props}
                    title="Combustíveis"
                    exporter={false}
                    bulkActionButtons={false}
                    actions={<CreateButton />}
                >
                    <Datagrid bulkActionButtons={false}>
                        <TextField source="acronym" label="Sigla" />
                        <TextField source="name" label="Nome" />
                        <TextField source="anpCode" label="Código ANP" />
                        <NumberField source="fuelType" label='Tipo do combustível (ConectTec)' />
                        <ReferenceField label="Combustível pai" source="parentFuelId" reference="parent-fuels">
                            <TextField source="name" />
                        </ReferenceField>
                        <EditButton />
                        <DeleteButton />
                    </Datagrid>
                </List>
            </Wrapper>
        </Permission>
    )
};

export default FuelList;
