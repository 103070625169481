import { useCallback } from "react";
import { Identifier, useStore } from "react-admin";
import { flushSync } from 'react-dom';

const useExpandAllEvery = (
  resource: string,
  ids: Identifier[]
): [boolean, () => void] => {
  const [expandedIds, setExpandedIds] = useStore<Identifier[]>(
      `${resource}.datagrid.expanded`,
      []
  );

  const isExpanded = (Array.isArray(expandedIds) && expandedIds.length > 0)
      ? // eslint-disable-next-line eqeqeq
        ids.every(id => expandedIds.some(id2 => id2 == id))
      : false;
  const toggleExpandedAll = useCallback(() => {
      const unaffectedExpandedIds = expandedIds.filter(
          // eslint-disable-next-line eqeqeq
          expanded_id => !ids.some(id => id == expanded_id)
      );
      flushSync(() => {
        setExpandedIds(
          isExpanded
              ? unaffectedExpandedIds
              : unaffectedExpandedIds.concat(ids)
      );
      })
  }, [expandedIds, setExpandedIds, isExpanded, ids]);

  return [isExpanded, toggleExpandedAll];
};

export default useExpandAllEvery