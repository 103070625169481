import {
  BooleanField,
  Datagrid,
  DateField,
  Filter,
  List,
  SearchInput,
  SelectInput,
  ShowButton,
  TextField,
} from 'react-admin'

import ListPagination from '../../components/ListPagination'
import Permission from '../../components/Permission'
import SupporTicketIsResolvedButton from '../../components/SupportTicketIsResolvedButton'
import { DATE_FORMATER, PERMISSIONS } from '../../constants'

const isResolvedFilterChoices = [
  { id: true, name: 'Resolvidas' },
  { id: false, name: 'Não Resolvidas' },
];

const MessageFilter = props => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn />
    <SelectInput
      label="Tipo"
      source="isResolved"
      emptyText="Todos"
      alwaysOn
      choices={isResolvedFilterChoices}
    />
  </Filter>
)

export default props => (
  <Permission permission={PERMISSIONS.LIST_MESSAGES}>
    <List
      {...props}
      perPage={25}
      pagination={<ListPagination />}
      exporter={false}
      bulkActionButtons={false}
      title="Mensagens"
      filters={<MessageFilter />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid bulkActionButtons={false}>
        <DateField source="createdAt" label="Data" options={DATE_FORMATER} />
        <TextField source="customer.name" label="Nome do Cliente" />
        <TextField
          source="place.name"
          label="Nome do Posto"
          emptyText="Todos os Postos"
        />
        <TextField
          source="subject.name"
          label="Assunto"
          emptyText="Sem Assunto"
        />
        <SupporTicketIsResolvedButton isOutlined />
        <BooleanField source="isResolved" label="Resolvida" />
        <ShowButton />
      </Datagrid>
    </List>
  </Permission>
)
