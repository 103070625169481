import { PERMISSIONS } from '../../constants';
import list from './ListTag';
import create from './CreateTag';
import edit from './EditTag';

export default {
  list,
  create,
  edit,
  name: 'chains/chainId/tag',
  permissions: [PERMISSIONS.TAG]
}