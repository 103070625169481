import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import { isEqual } from "../lib";

const StoreContext = createContext({});
const useStoreContext = () => useContext(StoreContext);

export const StoreProvider = ({ children }) => {

  const reducer = (state, action) => {
    return { ...state, [action.key]: action.value };
  };

  const [state, dispatch] = useReducer(reducer, {});

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = (key: string, initialValue?: any) => {
  const { state, dispatch } = useStoreContext() as any;

  const [value, setValue] = useState(state[key] || initialValue);

  useEffect(() => {
    if (initialValue && state[key] === undefined) {
      set(initialValue);
    }

    if (!isEqual(value, state[key])) {
      setValue(state[key]);
    }
  }, [key, state, value]);

  const set = (value: any) => {
    dispatch({ key, value });
  }

  return [value, set];
}
