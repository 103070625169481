import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutline";
import { IconButtonWithTooltip } from "react-admin";
import { useFormContext, useFieldArray } from 'react-hook-form';

const AddCustomItemButton = ({ onClick, name, resetFields, ...rest }) => {
  const { getValues } = useFormContext();
  const { update } = useFieldArray({ name });

  return (
    <IconButtonWithTooltip
      label="ra.action.add"
      size="small"
      color="primary"
      {...rest}
      onClick={(e) => {
        const fieldState = getValues(name);
        const nextIndex = Array.isArray(fieldState) ? fieldState.length : 0;
        onClick && onClick(e);
        update(nextIndex, { ...resetFields });
      }}>
      <AddCircleOutlineRoundedIcon fontSize="small" />
    </IconButtonWithTooltip>
  );
};
export default AddCustomItemButton;
