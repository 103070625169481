import { DayScheduledDiscount } from "./dayScheduledDiscount";
import { FuelDiscount } from "./fuelDiscount";

export enum DiscountFrequency {
  Daily = 'daily',
  Never = "never",
  Weekly = "weekly",
}

export class Discount {

  id: string | undefined;
  identify: string | undefined;
  description: string | undefined;
  chainId: string | undefined;
  createdByEmployeeId: string | undefined;
  startAt: string | undefined;
  endAt: string | undefined;
  frequency: DiscountFrequency | undefined;
  type: string | undefined;
  usageLimit: number | undefined;
  intervalInHours: number | undefined;
  isEnabled: boolean | undefined;
  isDefault: boolean | undefined;
  overlapsStandardDiscount: boolean | undefined;
  createdAt: string | undefined;
  fuelDiscounts: FuelDiscount[] = [];
  dayScheduledDiscounts: DayScheduledDiscount[] = [];
  weekSchedual: DayScheduledDiscount[] = [];
  customerGroupIds: Array<string> = [];
  stationIds: Array<string> = [];
  startTime: Date | undefined;
  endTime: Date | undefined;
  isSchedule: boolean = false;
  customerIds: Array<string> = [];

  static createDiscount(value: Partial<Discount>) {
    const discount: Partial<Discount> = {
      type: value.type,
      description: value.description,
      chainId: value.chainId,
      fuelDiscounts: value.fuelDiscounts,
      dayScheduledDiscounts: value.dayScheduledDiscounts,
      stationIds: value.stationIds,
      usageLimit: value.usageLimit,
      intervalInHours: value.intervalInHours,
      isSchedule: !!(value.startAt || value.endAt || value.frequency),
      startAt: value.startAt,
      endAt: value.endAt,
      frequency: value.frequency,
      customerGroupIds: value.customerGroupIds,
      customerIds: value.customerIds,
    };

    if (value.frequency === DiscountFrequency.Daily) {
      discount.startTime = value.dayScheduledDiscounts && value.dayScheduledDiscounts[0].startTime;
      discount.endTime = value.dayScheduledDiscounts && value.dayScheduledDiscounts[0].endTime;
    }

    return discount;
  }

  constructor(init?: Partial<Discount>) {
    Object.assign(this, init);
  }
}