import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Box } from '@mui/material';
import React, { useEffect, useState } from "react";
import { Button, DateInput, Edit, FileField, FileInput, FormDataConsumer, FormTab, LoadingIndicator, NumberInput, RadioButtonGroupInput, SaveButton, SelectInput, TabbedForm, TextInput, Toolbar, ToolbarProps, email, maxValue, minValue, required, useDataProvider, useNotify, useRecordContext, useRefresh } from "react-admin";
import { LazyLoadAutoCompleteInput, Permission, PriceInput } from "../../components";
import { CEPInput, CNPJInput, CPFInput, IEInput, PhoneInput, PixInput, StateInput } from "../../components/Inputs";
import { MAX_DATE, PERMISSIONS } from "../../constants";
import { Bank } from "../../models";
import BankService from "../../services/bankService";

const CustomToolbar: React.FC<ToolbarProps> = props => {
  const [updating, setUpdating] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const record = useRecordContext();

  const handleClick = () => {
    setUpdating(true);
    dataProvider.update('retailers', {
      id: record.id,
      data: { isActive: !record?.isActive },
      previousData: { ...record }
    })
      .then(() => {
        notify(`Revenda ${record?.isActive ? 'desativada' : 'ativada'} com sucesso`);
      }).catch(() => {
        notify(`Erro ao ${record?.isActive ? 'desativar' : 'ativar'} revenda`, { type: 'warning' })
      }).finally(() => {
        setUpdating(false);
        refresh();
      });
  };

  return (
    <Toolbar {...props} style={{ display: 'flex', justifyContent: 'space-between' }}>
      <SaveButton />
      <Button
        startIcon={updating ? <LoadingIndicator /> : record?.isActive ? <RemoveCircleIcon /> : <AddCircleIcon />}
        label={record?.isActive ? 'Desativar' : 'Ativar'}
        onClick={handleClick}
        disabled={updating}
      />
    </Toolbar>
  );
};

const editRetailer: React.FC = props => {
  const [banks, setBanks] = useState<Bank[]>([]);

  useEffect(() => {
    const bankService = new BankService();
    bankService.getBanks().then(({ data }) => {
      setBanks(data.sort((a, b) => a.code - b.code));
    });
  }, []);

  return (
    <Permission permission={PERMISSIONS.ADMIN}>
      <Edit {...props} title="Editar revenda">
        <TabbedForm toolbar={<CustomToolbar {...props} />}>
          <FormTab label="Dados">
            <TextInput source="name" label="Nome" validate={required()} style={{ minWidth: 260 }} />
            <TextInput source="corporateName" label="Razão Social" style={{ minWidth: 260 }} />
            <TextInput source="commercialName" label="Nome Fantasia" style={{ minWidth: 260 }} />
            <RadioButtonGroupInput
              label="Tipo"
              source="personType"
              choices={[
                { id: 'corporate', name: 'Jurídica' },
                { id: 'individual', name: 'Física' },
              ]}
              defaultValue="corporate"
              validate={required()}
              style={{ minWidth: 260 }}
            />
            <FormDataConsumer>
              {({ formData }) => {
                if (formData.personType === 'corporate') {
                  return (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      <CNPJInput source="cnpj" label="CNPJ" isRequired style={{ minWidth: 260 }} />
                      <IEInput
                        source="stateRegistration"
                        label="Inscrição Estadual"
                        style={{ minWidth: 260 }}
                      />
                      <TextInput
                        source="municipalRegistration"
                        label="Inscrição Municipal"
                        style={{ minWidth: 260 }}
                        format={value => value && value.toString().replace(/[^\d]/g, '')}
                        parse={(value) => value && value.replace(/[^\d]/g, '')}
                      />
                    </Box>
                  );
                } else if (formData.personType === 'individual') {
                  return (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      <CPFInput source="cpf" label="CPF" isRequired style={{ minWidth: 260 }} />
                      <TextInput
                        source="rg"
                        label="RG"
                        validate={required()}
                        style={{ minWidth: 260 }}
                        format={value => value && value.toString().replace(/[^\d]/g, '')}
                        parse={(value) => value && value.replace(/[^\d]/g, '')}
                      />
                    </Box>
                  );
                }
              }}
            </FormDataConsumer>
          </FormTab>
          <FormTab label="Contato">
            <TextInput source="answerableEmployee" label="Responsável" validate={required()} />
            <PhoneInput source="phone" label="Telefone" landline />
            <PhoneInput source="cellphone" label="Celular" />
            <TextInput source="email" label="Email" type="email" validate={[email(), required()]} />
          </FormTab>
          <FormTab label="Endereço">
            <TextInput source="street" label="Rua" validate={required()} />
            <TextInput source="streetNumber" label="Número" validate={required()} />
            <CEPInput source="cep" label="CEP" validate={required()} />
            <TextInput source="neighbourhood" label="Bairro" validate={required()} />
            <TextInput source="complement" label="Complemento" />
            <TextInput source="city" label="Cidade" validate={required()} />
            <StateInput source="state" label="Estado" validate={required()} />
          </FormTab>
          <FormTab label="Dados Bancários">
            {
              banks.length ?
                //@ts-ignore
                <LazyLoadAutoCompleteInput
                  label="Banco"
                  source="bankCode"
                  format={choice => `${choice.code} - ${choice.name}`}
                  style={{ width: 260, marginTop: 8 }}
                  defaultChoices={banks}
                />
                : <></>
            }
            <TextInput source="agency" label="Agência" style={{ minWidth: 260 }} parse={val => val.toUpperCase()} />
            <TextInput source="accountNumber" label="Conta" style={{ minWidth: 260 }} parse={val => val.toUpperCase()} />
            <PixInput source="pixKey" label="Chave Pix" style={{ minWidth: 260 }} />
          </FormTab>
          <FormTab label="Contrato">
            <SelectInput
              source="commissioningType"
              label="Tipo de comissão"
              choices={[{ id: 'fixed', name: 'Fixada' }, { id: 'percentage', name: 'Porcentagem' }]}
              validate={[required()]}
              style={{ minWidth: 260 }}
            />
            <FormDataConsumer>
              {({ formData }) => <>
                {formData.commissioningType === 'percentage' &&
                  <NumberInput
                    source="commissioningValue"
                    label="Valor da comissão"
                    min={0.01}
                    max={100}
                    step={0.01}
                    validate={[minValue(0.01), maxValue(100), required()]}
                    style={{ minWidth: 260 }}
                    placeholder="%"
                  />}
                {formData.commissioningType === 'fixed' &&
                  <PriceInput
                    source="commissioningValue"
                    label="Valor da comissão"
                    validate={[minValue(0.01), required()]}
                    style={{ minWidth: 260 }}
                  />}
              </>}
            </FormDataConsumer>
            <DateInput label="Vencimento do contrato" source="expiresAt" inputProps={{ max: MAX_DATE }} style={{ minWidth: 260 }} />
            <NumberInput label="Dia de Pagamento" source="paymentDay" validate={[minValue(1), maxValue(31)]} style={{ minWidth: 260 }} />
            <FileInput
              source="file"
              label="Anexar contrato"
              accept="application/pdf"
            >
              <FileField source="src" title="title" />
            </FileInput>
          </FormTab>
        </TabbedForm>
      </Edit>
    </Permission>
  );
};


export default editRetailer;
