import GroupWorkIcon from '@mui/icons-material/GroupWork';
import { Collapse } from '@mui/material';
import { FilterList, ReferenceInput, SelectInput } from 'react-admin';
import useDashboardContext from '../../hooks/useDashboardContext';
import { getChainId } from '../../lib';

const DashboardChainInput = ({ isOpen }) => {
  const { chain, setChain } = useDashboardContext();

  return (
    <>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <FilterList
          label="Rede"
          icon={<GroupWorkIcon titleAccess="Rede" />}
        />
      </Collapse>
      <ReferenceInput
        source="chainId"
        reference="chains"
        style={{ width: 160, marginTop: !isOpen && 8, padding: 0 }}
        perPage={null}
        sort={{ field: "name", order: "ASC" }}
      >
        <SelectInput
          onChange={({ target }) => {
            setChain(target.value)
          }}
          defaultValue={getChainId()}
          emptyText="Todas"
          label={!isOpen && 'Rede'}
          optionText="name"
        />
      </ReferenceInput>
    </>);
}

export default DashboardChainInput;
