import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import {
  Create,
  Datagrid,
  DateField,
  FunctionField,
  List,
  SaveButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  maxLength,
  required,
  useDataProvider,
  useNotify,
  usePermissions,
  useRecordContext
} from "react-admin";
import { StarRatingField } from "../../components";
import { PERMISSIONS } from "../../constants";
import { StoresType } from "../../models";

const useStyles = makeStyles({
  listActions: {
    display: 'flex',
    justifyContent: 'flex-start',
    background: 'none',
    padding: '16px',
  },
  warning: {
    '&::before': {
      content: "'* '",
      color: '#ff0000',
      fontSize: 20
    }
  },
  removePadding: {
    padding: '0px',
  }
});

const ObservationTypography = () => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.listActions}>
      <Typography className={classes.warning}>
        Observação: É possível recuperar somente as avaliações que os usuários criaram ou modificaram na última semana (Google Store).
      </Typography>
    </TopToolbar>
  );
};

const ReplyPanel = ({ ...props }) => {
  const [disableButton, setDisableButton] = React.useState<boolean>(false);
  const [store, setStore] = React.useState<string>("");

  const { permissions } = usePermissions();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const record = useRecordContext();
  if (!record) { return null; }

  React.useEffect(() => {
    setStore(record.store);
  }, [record]);

  const answerRating = async ({ replyText }) => {
    await dataProvider.create(`chains/chainId/stores-rating/${record.id}`, {
      data: {
        store: store,
        replyText: replyText
      }
    })
      .then(() => {
        setDisableButton(true);
        notify("Resposta enviada com sucesso!");
      })
      .catch((error) => notify(error.message, { type: 'warning' }));
  };

  const AnswerToolbar = (props) => {
    return (
      <Toolbar {...props} >
        <SaveButton label="Enviar Resposta" disabled={disableButton} />
      </Toolbar>
    );
  };

  return (
    <SimpleShowLayout>
      <TextField source="userComment" label="Comentário do Usuário" sortable={false} />
      {(
        record && record.developerComment
          ? <TextField source="developerComment" label="Resposta" sortable={false} />
          : permissions && permissions.includes(PERMISSIONS.ANSWER_RATING)
            ? (
              <Create title=" " {...props}>
                <SimpleForm toolbar={<AnswerToolbar />} save={answerRating} >
                  <TextInput source="replyText" label="Responder Comentário" validate={[required(), maxLength(350)]} />
                </SimpleForm>
              </Create>
            )
            : null
      )}
    </SimpleShowLayout>
  );
};

const RatingList = ({ permissions, ...props }) => {
  return (
    <List
      title="Avaliações nas Lojas"
      bulkActionButtons={false}
      exporter={false}
      {...props}
    >
      <>
        <ObservationTypography />
        <Datagrid expand={<ReplyPanel />} >
          <DateField source="userCommentLastModified" label="Data" showTime sortable={false} />
          <TextField source="authorName" label="Cliente" sortable={false} />
          <FunctionField
            label="Loja"
            render={record => StoresType.translateType(record?.store)}
            sortable={false}
          />
          <StarRatingField source="starRating" label="Nota" sortable={false} />
        </Datagrid>
      </>
    </List>
  );
};

export default RatingList;
