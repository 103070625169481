export enum BulkSendStatus {
  scheduled = 'scheduled',
  dispatched = 'dispatched',
  failed = 'failed',
}

export namespace BulkSendStatus {
  export const translateStatus = (type: BulkSendStatus): string => {
    switch (type) {
      case BulkSendStatus.dispatched:
        return "Entregue";
      case BulkSendStatus.scheduled:
        return "Pendente";
      case BulkSendStatus.failed:
        return "Erro";
      default:
        return "";
    }
  };

  export const bulkSendStatusChoices = Object.values(BulkSendStatus).filter(x => typeof x === "string")
    .map(status => {
      return ({
        id: status,
        name: BulkSendStatus.translateStatus(status)
      });
    });
}