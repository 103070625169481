import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  RadioButtonGroupInput,
  SelectInput,
  required,
  minLength,
  ReferenceInput,
  email,
  BooleanInput,
} from 'react-admin'

import { DEFAULT_API_ADMIN, PERMISSIONS } from '../../constants'
import { getChainId } from '../../lib'
import useFetch from '../../lib/useFetch'
import { makeStyles } from '@mui/styles';
import Permission from '../../components/Permission'

const useStyles = makeStyles((theme) => ({
  profiles: {
    width: 220,
  }
}));

const validatePassword = [required(), minLength(6)]
const validateEmail = [required(), email()]

export default props => {
  const classes = useStyles();
  const [{ data }] = useFetch({
    url: `${DEFAULT_API_ADMIN}/places`,
    initialState: { data: [] },
  })


  const getChoices = () =>
    data
      .filter(item => item.chainId === getChainId())
      .map(({ id, name }) => ({ id, name }))


  return (
    <Permission permission={PERMISSIONS.CREATE_LOCAL_USER}>
      <Create {...props} title="Cadastrar novo usuário">
        <SimpleForm>
          <SelectInput
            label="Parceiro ou Posto"
            source="placeId"
            choices={data ? getChoices() : []}
            validate={[required()]}
          />
          <TextInput source="name" label="Nome" validate={[required()]} InputProps={{ autoComplete: 'off' }} />
          <TextInput source="login" label="Login" validate={validateEmail} InputProps={{ autoComplete: 'off' }} />
          <TextInput
            InputProps={{ autoComplete: 'new-password' }}
            source="password"
            label="Senha"
            validate={validatePassword}
          />
          <ReferenceInput
            className={classes.profiles}
            source="profileId"
            reference="profiles"
            sort={{ field: 'name', order: 'ASC' }}
            perPage={9999}
            validate={[required()]} >
            <RadioButtonGroupInput label="Perfil" optionText="name" />
          </ReferenceInput>
          <BooleanInput source="mustChangePassword" label="Forçar usuário a trocar senha no primeiro acesso" />
        </SimpleForm>
      </Create>
    </Permission>
  )
}
