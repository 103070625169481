import React from 'react';
import {
  Create,
  TextInput,
  required,
  SimpleForm,
  ReferenceArrayInput,
  SelectArrayInput,
} from 'react-admin';
import { PERMISSIONS } from '../../constants';
import Permission from '../../components/Permission';

const create = ({ ...props }) => (
  <Permission permission={PERMISSIONS.STATION_GROUPS}>
    <Create {...props} title="Cadastrar novo grupo de postos">
      <SimpleForm>
        <TextInput source="name" label="Nome" InputProps={{ autoComplete: 'off' }} validate={required()} />
        <TextInput source="description" label="Descrição" InputProps={{ autoComplete: 'off' }} />
        <ReferenceArrayInput
          sort={{ field: 'name', order: 'ASC' }}
          perPage={null}
          label="Postos"
          source="stationIds"
          reference="chains/chainId/stations"
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  </Permission>
)

export default create;
