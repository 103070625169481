import { Typography } from '@mui/material';
import moment from "moment";
import {
  ArrayField,
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  Filter,
  FormDataConsumer,
  ImageField,
  NumberField,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  SearchInput,
  SelectArrayInput,
  SingleFieldList,
  TextField,
  TextInput,
  minValue,
  useListContext,
  usePermissions,
} from "react-admin";
import { ReportList } from "../../components";
import ListPagination from "../../components/ListPagination";
import Permission from "../../components/Permission";
import { PERMISSIONS } from "../../constants";
import { getChainId } from "../../lib";
import { TagType } from "../../models";
import RequiredChain from '../../components/RequiredChain';

const ListProductPlace = (props) => {

  return (
    <Datagrid bulkActionButtons={false} {...props}>
      <ReferenceField source="placeId" reference="places" label="Local" link={false} sortable={false}>
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="price" label="Pontos" textAlign="center" sortable={false} />
      <NumberField
        source="partnerPrice"
        label="Valor em reais"
        textAlign="center"
        emptyText="R$ 0,00"
        options={{
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }}
        sortable={false}
      />
      <NumberField source="limit" label="Resgates por dia" textAlign="center" emptyText="Sem limite" sortable={false} />
      <NumberField source="discountValue" label="Porcentagem de desconto" textAlign="center" options={{ style: 'percent' }} sortable={false} />
      <BooleanField source="isGift" label="É brinde" sortable={false} looseValue />
    </Datagrid>
  );
};

const ListProducts = () => {
  const { data, ids, resource } = useListContext();

  return (
    <Datagrid
      bulkActionButtons={false}
      resource={resource}
      data={data}
      style={{ marginTop: 40 }}
    >
      <ImageField source="imagePath" label="Imagem" sortable={false} link={false} />
      <TextField source="name" textAlign="center" label="Nome" link={false} />
      <ReferenceField source="categoryId" reference="chains/chainId/product-categories" label="Categoria" link={false} emptyText="Não definida">
        <TextField source="name" />
      </ReferenceField>
      <BooleanField source="stock" label="Estoque Controlado" looseValue />
      <ArrayField source="productPlaces" label="Locais de resgate">
        <ListProductPlace />
      </ArrayField>
    </Datagrid>
  );
};

const FilterTablePrint = () => {
  const { filterValues } = useListContext();
  const { permissions } = usePermissions();

  return (
    <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
      <ReferenceArrayField source="placeIds" emptyText="Todos" reference="places" label="Locais" sortable={false}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField source="categoryIds" emptyText="Todos" reference="chains/chainId/product-categories" label="Categorias" sortable={false}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      {
        permissions && permissions.includes(PERMISSIONS.TAG) &&
        <ReferenceArrayField source="tagIds" emptyText="Todos" reference="chains/chainId/tag" label="Etiquetas" sortable={false}>
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
      }
    </Datagrid>
  );
};

const ListFilters = (props) => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn />
    <ReferenceArrayInput
      source="placeIds"
      reference="places"
      perPage={null}
      sort={{ field: "name", order: "ASC" }}
      filter={{ chainId: getChainId() }}
      style={{ minWidth: 160 }}
      emptyText="Todos"
      alwaysOn
    >
      <SelectArrayInput optionText="name" label="Locais" />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      source="categoryIds"
      reference="chains/chainId/product-categories"
      perPage={null}
      sort={{ field: "name", order: "ASC" }}
      filter={{ chainId: getChainId() }}
      style={{ minWidth: 160 }}
      emptyText="Todas"
      alwaysOn
    >
      <SelectArrayInput label="Categorias" optionText="name" />
    </ReferenceArrayInput>
    <FormDataConsumer alwaysOn>
      {({ formData }) => <span>
        <TextInput
          source="fromPrice"
          label="Mínimo"
          format={value => value && value.toString().replace(/\D/g, '')}
          parse={value => {
            if (value)
              return +value > 0 ? +value : 1;
            return null;
          }}
          validate={[
            minValue(1, 'O valor mínimo deve ser maior ou igual a 1')
          ]}
          style={{ width: 120, marginRight: 16, marginTop: 0 }} />
        <TextInput
          source="toPrice"
          label="Máximo"
          format={value => value && value.toString().replace(/\D/g, '')}
          parse={value => {
            if (value)
              return +value > 0 ? +value : formData.fromPrice || 1;
            return null;
          }}
          validate={[minValue(formData.fromPrice, 'O valor máximo deve ser maior ou igual ao valor mínimo')]}
          style={{ width: 120, marginTop: 0 }} />
      </span>}
    </FormDataConsumer>
    <Permission permission={PERMISSIONS.TAG} alwaysOn>
      <ReferenceArrayInput
        style={{ minWidth: 180 }}
        source="tagIds"
        reference="chains/chainId/tag"
        perPage={null}
        sort={{ field: "name", order: "ASC" }}
        filter={{ type: TagType.product }}
      >
        <SelectArrayInput label="Etiqueta" optionText="name" />
      </ReferenceArrayInput>
    </Permission>
  </Filter>
);

const ReportProducts = (props) => {

  const productsExporter = async (data, fetchRelatedRecords) => {
    let productsData = data.reduce((acc, { productPlaces, ...item }) => ([
      ...acc, ...(productPlaces || []).map(productP => ({ ...item, ...productP }))
    ]), []);

    const relations = [
      { field: 'categoryId', resource: 'chains/chainId/product-categories' },
      { field: 'placeId', resource: 'chains/chainId/places' }
    ];
    const fetchProducts = await fetchRelatedRecords(relations, productsData);

    const getData = (resource) => {
      return fetchProducts.find(({ resource: res }) => res === resource)?.results || [];
    };

    productsData = productsData.map(product => {
      const categories = getData('chains/chainId/product-categories').find(({ id }) => id === product.categoryId);
      const places = getData('chains/chainId/places').find(({ id }) => id === product.placeId);

      return {
        id: product.id,
        nome: product?.name,
        categoria: categories ? categories.name : '',
        estoque_controlado: product.stock,
        local: places ? places.name : '',
        pontos: product.price,
        preco_em_reais: product.partnerPrice,
        resgate_por_dia: product.limit || 'Sem limite',
        porcentagem_de_desconto: product.discountValue,
        brinde: product.isGift,
      };
    });

    return productsData
  };

  return (
    <Permission permission={PERMISSIONS.PRODUCTS_REPORT}>
      <RequiredChain>
        <ReportList
          {...props}
          basePath="report-products"
          title="Relatório de Produtos"
          resource="chains/chainId/reports/products"
          filters={<ListFilters />}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={25}
          pagination={<ListPagination />}
          filterDefaultValues={{ from: moment().startOf('day').toDate(), to: moment().endOf('day').toDate() }}
          bulkActionButtons={false}
          customExporter={productsExporter}
          titleOnPrint="Relatório de Produtos"
          filterTablePrint={<FilterTablePrint />}
          landscape
        >
          <ListProducts />
        </ReportList>
      </RequiredChain>
    </Permission >
  );
};

export default ReportProducts;