import {
  Close as CloseIcon,
  Phone as PhoneIcon,
  Place as PlaceIcon,
} from "@mui/icons-material";
import {
  CardHeader,
  CardMedia,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
  styled,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { ReferenceField, TextField } from "react-admin";
import { Permission } from "../../components";
import { PERMISSIONS } from "../../constants";
import { formatCellphone, formatCnpj } from "../../utils";

const CloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: 10,
  left: 10,
  width: 48,
  height: 48,
}));

const StationInfoSideBar: React.FC<{ data: any, isVisible: boolean, togglerDrawer: (open: boolean) => void; }> = ({ data, isVisible, togglerDrawer }) => {
  return (
    data &&
    <Drawer
      sx={{
        '& .MuiDrawer-paper': {
          top: 48,
          minWidth: 400
        },
        left: 'auto',
      }}
      anchor='right'
      open={isVisible}
      hideBackdrop={false}
    >
      <CloseButton sx={{ color: data.imagePath ? 'white' : 'black', backgroundColor: 'gray' }} aria-label="fechar" onClick={() => togglerDrawer(false)}>
        <CloseIcon />
      </CloseButton>
      <CardMedia
        sx={{ height: 240, bgcolor: grey[100] }}
        image={data.imagePath || require('../../assets/images/no-image-map.jpeg')}
      />
      <CardHeader
        sx={{
          '& .MuiCardHeader-action': {
            alignSelf: 'center'
          }
        }}
        title={data.name}
        titleTypographyProps={{ fontSize: 20 }}
      />
      <Permission permission={PERMISSIONS.ADMIN}>
        <List
          sx={{ width: '100%' }}
          subheader={<ListSubheader>Rede</ListSubheader>}
        >
          <ReferenceField reference='chains' source="chainId" record={data} link={false}>
            <TextField style={{ paddingLeft: 16 }} source="name" />
          </ReferenceField>
        </List>
      </Permission>
      {

        <List
          sx={{ width: '100%' }}
          subheader={<ListSubheader>Informações básicas</ListSubheader>}
        >
          {
            (data.street && data.city && data.state) &&
            <ListItem>
              <ListItemIcon>
                <PlaceIcon />
              </ListItemIcon>
              <Link target='_blank' underline='hover' href={`https://www.google.com/maps/search/?api=1&query=${data.latitude}%2C${data.longitude}`} >
                <ListItemText sx={{ maxWidth: '312px' }} primary={`${data.street}, ${data.streetNumber || 's/n'}, ${data.city} - ${data.state}`} />
              </Link>
            </ListItem>
          }
          {
            data.phone &&
            <ListItem>
              <ListItemIcon>
                <PhoneIcon />
              </ListItemIcon>
              <ListItemText primary={formatCellphone(data.phone)} />
            </ListItem>
          }
          {
            data.cnpj &&
            <ListItem>
              <ListItemIcon>
                <Typography sx={{ fontSize: 12, fontWeight: 'bold', fontStyle: 'italic' }}>CNPJ</Typography>
              </ListItemIcon>
              <ListItemText primary={formatCnpj(data.cnpj)} />
            </ListItem>
          }
        </List>
      }
    </Drawer>
  );
};

export default StationInfoSideBar;