import { Chip, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React from 'react';
import { useInput } from 'react-admin';

interface ArrayChipInputProps {
  source: string;
  label: string;
}

const ArrayChipInput: React.FC<ArrayChipInputProps> = ({ source, label }) => {

  const { field } = useInput({ source });

  return (
    <Autocomplete
      multiple
      id="tags-filled"
      options={(field.value || []).map((option) => option)}
      defaultValue={field.value}
      freeSolo
      onChange={(event, newValue) => {
        field.onChange(newValue)
      }}
      renderTags={(value: readonly string[], getTagProps) =>
        value.map((option: string, index: number) => (
          <Chip label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          label={label || source}
        />
      )}
      style={{ minWidth: 220 }}
    />
  );
}

export default ArrayChipInput;