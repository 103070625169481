import React from 'react';
import {
  Create,
  TextInput,
  required,
  minLength,
  maxLength,
  TabbedForm,
  FormTab,
} from 'react-admin';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';

const CreateCustomerGroup = (props) => {
  return (
    <Permission permission={PERMISSIONS.CUSTOMER_GROUPS}>
      <Create {...props} title='Criar grupo de clientes'>
        <TabbedForm>
          <FormTab label="Identificação">
            <TextInput source="name" label="Nome" validate={[required(), minLength(3), maxLength(240)]} InputProps={{ autoComplete: 'off' }} />
            <TextInput source="description" label="Descrição" validate={[required(), minLength(3), maxLength(240)]} InputProps={{ autoComplete: 'off' }} />
          </FormTab>
        </TabbedForm>
      </Create>
    </Permission>
  );
};

export default CreateCustomerGroup;