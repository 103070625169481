import { PERMISSIONS } from '../../constants';
import list from './listMarketingCampaigns';
import edit from './editMarketingCampaigns';
import create from './createMarketingCampaigns';

export default {
  list,
  create,
  edit,
  name: 'chains/chainId/chain-marketing-campaigns',
  permissions: [PERMISSIONS.MARKETING_CAMPAIGNS]
}
