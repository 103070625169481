import { Box, Typography } from '@mui/material';
import React from 'react';
import {
  ArrayInput,
  BooleanInput,
  Edit,
  FormDataConsumer,
  SaveButton,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  Toolbar,
  minValue,
  useNotify,
  useRefresh
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Wrapper, Tooltip } from '../../components';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { getChainId } from '../../lib';
import { PotencialFraudRule } from '../../models';

const patchPotencialFraudRule: React.FC = () => {
  const notify = useNotify();
  const navigate = useNavigate();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify('ra.notification.updated', { messageArgs: { smart_count: 1 } });
    refresh();
  }

  const onError = () => {
    notify('ra.notification.data_provider_error', { type: 'error' });
    navigate('/security-rules-edit');
  }

  const CustomToolbar = () => (
    <Toolbar>
      <SaveButton />
    </Toolbar>
  );

  return (
    <Permission permission={PERMISSIONS.POTENCIAL_FRAUD_RULE}>
      {
        getChainId() ?
          <Edit id={getChainId()} title="Regras de Segurança" resource="security-rules" mutationMode="pessimistic" mutationOptions={{ onSuccess, onError }}>
            <SimpleForm toolbar={<CustomToolbar />}>
              <ArrayInput source="fraudRules" label="">
                <SimpleFormIterator disableReordering disableAdd disableRemove>
                  <FormDataConsumer>
                    {({ scopedFormData, getSource }) => {
                      getSource && getSource('');
                      return (
                        <Tooltip title={PotencialFraudRule.description(scopedFormData?.name)}>
                          <TextField source={"label"} sx={{ marginTop: 2 }} record={scopedFormData} />
                        </Tooltip>
                      );
                    }}
                  </FormDataConsumer>
                  <FormDataConsumer>
                    {({ scopedFormData: scopedFraudRules, getSource: getSourceFraudRules }) => {
                      if (scopedFraudRules && scopedFraudRules.fraudRuleChains.length === 0) {
                        scopedFraudRules.fraudRuleChains = [{ fraudId: scopedFraudRules.id, chainId: getChainId() }]
                      }
                      return (
                        <ArrayInput source={getSourceFraudRules("fraudRuleChains")} label={false}>
                          <SimpleFormIterator disableReordering disableAdd disableRemove>
                            <FormDataConsumer>
                              {({ getSource }) => (
                                getSource && getSource('') &&
                                <Box sx={{ display: 'flex', marginTop: 2, alignItems: 'center' }}>
                                  <BooleanInput label="Habilitar" source={getSource("isActive")} defaultValue={false} />
                                  {
                                    ![PotencialFraudRule.redeemedGiftOnCanceledSale, PotencialFraudRule.negativeBalance].includes(scopedFraudRules?.name) &&
                                    <TextInput
                                      label="Valor mínimo"
                                      source={getSource("minimumValue")}
                                      textAlign="center"
                                      style={{ width: 180, marginLeft: 10 }}
                                      validate={[minValue(1)]}
                                      format={value => value ? (+value).toFixed(0) : ''}
                                      parse={value => value ? +value.replace(/\D/g, '') : null}
                                    />
                                  }
                                </Box>
                              )}
                            </FormDataConsumer>
                          </SimpleFormIterator>
                        </ArrayInput>
                      )
                    }}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleForm>
          </Edit>
          :
          <Wrapper>
            <Typography>Selecione uma rede para fazer alterações</Typography>
          </Wrapper>
      }
    </Permission>
  );
};

export default patchPotencialFraudRule;