export enum AlterValueDrawing {
  amount = 'amount',
  value = 'value',
  point = 'point',
}

export namespace AlterValueDrawing {
  export const translateType = (type: string): string => {
    switch (type) {
      case AlterValueDrawing.amount:
        return "Litros";
      case AlterValueDrawing.value:
        return "Valor";
      case AlterValueDrawing.point:
        return "Pontos";
      default:
        return "";
    }
  };

  export const alterValueDrawingChoices = Object.values(AlterValueDrawing)
    .filter(x => typeof x === "string")
    .map((type) => ({
      id: type,
      name: AlterValueDrawing.translateType(type),
    }));
}