import React from "react";
import { Datagrid, EmailField, FunctionField, List, ReferenceArrayInput, SearchInput, SelectArrayInput, SelectField, SelectInput, TextField } from "react-admin";
import { Permission } from "../../components";
import { CNPJField, CPFField, PhoneField } from "../../components/Fields";
import { PERMISSIONS } from "../../constants";

const listRetailer: React.FC = props => {

  const listFilters = [
    <SearchInput source="search" alwaysOn />,
    <SelectInput
      source="status"
      label="Status"
      choices={[{ id: true, name: 'Ativa' }, { id: false, name: 'Inativa' }]}
      alwaysOn
    />,
    <ReferenceArrayInput
      reference="stations"
      source="stationIds"
      style={{ minWidth: 160 }}
      sort={{ field: "name", order: "ASC" }}
      perPage={9999}
      alwaysOn
    >
      <SelectArrayInput label="Postos" optionText="name" />
    </ReferenceArrayInput>,
  ];

  return (
    <Permission permission={PERMISSIONS.ADMIN}>
      <List
        {...props}
        title="Revendas"
        sort={{ field: "name", order: "DESC" }}
        filters={listFilters}
        filterDefaultValues={{ status: true }}
        exporter={false}
      >
        <Datagrid rowClick="edit" bulkActionButtons={false}>
          <TextField source="name" label="Nome" />
          <TextField source="answerableEmployee" label="Responsável" />
          <FunctionField label="CPF/CNPJ" render={(record) => record?.personType === 'individual' ? <CPFField source="cpf" /> : <CNPJField source="cnpj" />} />
          <PhoneField source="phone" label="Telefone" />
          <EmailField source="email" label="Email" />
          <SelectField source="commissioningType" label="Tipo de comissão" choices={[{ id: 'fixed', name: 'Fixada' }, { id: 'percentage', name: 'Porcentagem' }]} />
          <FunctionField label="Valor da comissão" render={record => record?.commissioningType === 'percentage' ? `${record.commissioningValue}%` : `R$ ${record?.commissioningValue}`} />
        </Datagrid>
      </List>
    </Permission>
  );
};

export default listRetailer;
