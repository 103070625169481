export enum GiftRuleType {
  fillin = 'fillin',
}

export namespace GiftRuleType {
  export const translateType = (type: GiftRuleType): string => {
    switch (type) {
      case GiftRuleType.fillin:
        return "Abastecimento";
      default:
        return "";
    }
  };

  export const giftRuleTypeChoices = Object.values(GiftRuleType)
    .filter(x => typeof x === "string")
    .map((type) => ({
      id: type,
      name: GiftRuleType.translateType(type),
    }));
}