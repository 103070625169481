import React, { Fragment, useState } from 'react';
import { Button, FormDataConsumer, ReferenceInput, SimpleForm, SelectInput, useNotify } from 'react-admin'
import IconContentAdd from '@mui/icons-material/GetApp';
import IconCancel from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ImportButton } from 'react-admin-import-csv';
import { getChainId } from '../lib';
import Permission from './Permission';
import { PERMISSIONS } from '../constants';
import { Typography } from '@mui/material';
import { isValidCPF, onlyNumbers } from '@brazilian-utils/brazilian-utils';
import { formatDecimal } from '../utils';
import moment from 'moment';

export default props => {
    const notify = useNotify();
    const [showDialog, setShowDialog] = useState(false);

    const handleClick = () => {
        setShowDialog(true);
    };

    const closeDialog = () => {
        setShowDialog(false)
    };

    return (
        <Fragment>
            <Button onClick={handleClick} label="Importar CSV">
                <IconContentAdd style={{ transform: "rotate(180deg)", fontSize: "20" }} />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={closeDialog}
                aria-label="Importar CSV"
            >
                <DialogTitle>Importar CSV</DialogTitle>
                <DialogContent>
                    <SimpleForm
                        // We want no toolbar at all as we have our modal actions
                        toolbar={null}
                        resource="attendants"
                        defaultValues={{ chainId: getChainId() }}
                    >
                        <Typography style={{ marginTop: 10 }}>
                            Importar uma planilha com as colunas nessa ordem, independente do nome da coluna: CPF (com ou sem máscara), Nome e identfid
                        </Typography>
                        <Permission permission={PERMISSIONS.ADMIN}>
                            <ReferenceInput
                                source="chainId"
                                reference="chains"
                                perPage={null}
                                sort={{ field: "name", order: "ASC" }}
                            >
                                <SelectInput label="Rede" optionText="name" />
                            </ReferenceInput>
                        </Permission>
                        <FormDataConsumer>
                            {({ formData, ...rest }) => {
                                const config = {
                                    logging: true,
                                    parseConfig: {
                                        delimiter: ";"
                                    },
                                    transformRows: (rows) => {
                                        const transformedRows = [];

                                        rows.forEach(row => {
                                            const values = Object.values(row);

                                            const transformedRow = {
                                                cpf: onlyNumbers(values[0]),
                                                name: values[1],
                                                identfid: values[2],
                                                chainId: formData.chainId || getChainId(),
                                            }
                                            transformedRows.push(transformedRow);
                                        });
                                        return transformedRows.filter(row => isValidCPF(row.cpf) && row.name && row.identfid);
                                    },
                                    postCommitCallback: (result) => {
                                        closeDialog();
                                        if (result.length && !result[0].success) {
                                            notify(result[0].err.message, { type: 'warning' })
                                        }
                                    }
                                };
                                return (<ImportButton {...props} {...rest} {...config} />);
                            }}
                        </FormDataConsumer>
                    </SimpleForm>
                </DialogContent>
                <DialogActions>
                    <Button label="ra.action.cancel" onClick={closeDialog}>
                        <IconCancel />
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );

}
