import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import { Wrapper } from '../../components';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';

const ParentFuelEdit = (props) => {
    return (
        <Permission permission={PERMISSIONS.ADMIN}>
            <Wrapper>
                <Edit title='Editar combustível' {...props}>
                    <SimpleForm>
                        <TextInput disabled label="ID" source="id" />
                        <TextInput source="acronym" label="Sigla" maxlength="3" />
                        <TextInput source="name" label="Nome" />
                    </SimpleForm>
                </Edit>
            </Wrapper>
        </Permission>
    )
};

export default ParentFuelEdit;
