import { getChainId } from '../lib'

export default (data, resourceName) => {
    if (resourceName === 'employees' || resourceName === '_employees') {
        if (!data.hasOwnProperty('chainId')) {
            data.chainId = getChainId()
        }
    }
    return data
}
