import React, { useCallback, useEffect, useState } from 'react';
import { Create, useDataProvider, useGetOne, useNotify, useRedirect } from 'react-admin';
import Permission from '../../components/Permission';
import WizardForm from '../../components/WizardForm';
import { PERMISSIONS } from '../../constants';

import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { getChainId } from '../../lib';
import FormCustomerGroup from './wizardForms/FormCustomerGroup';
import FormDiscountType from './wizardForms/FormDiscountType';
import FormFuelDiscount from './wizardForms/FormFuelDiscount';
import FormScheduleDiscount from './wizardForms/FormScheduleDiscount';
import FormSummary from './wizardForms/FormSummary';

import { Typography } from '@mui/material';
import { uniq, uniqBy } from 'lodash';
import { DayScheduledDiscount } from '../../models/dayScheduledDiscount';
import { Discount } from '../../models/discount';
import { FuelDiscount } from '../../models/fuelDiscount';
import FormNotification from './wizardForms/FormNotification';

const DiscountCreate: React.FC = () => {
    const { state: params }: { state: Object } = useLocation();
    const [chainId] = useState(getChainId());
    if (!chainId) { return <Typography>Selecione uma Rede</Typography>; }

    const { data: chain } = useGetOne('chains', { id: chainId });

    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    const [isLoading, setIsloading] = useState<boolean>(false);
    const [fuels, setFuels] = useState<{ [k: string]: any }[]>([]);
    const [completedSteps, setCompletedSteps] = React.useState<{
        [k: number]: boolean;
    }>({});

    useEffect(() => {
        //@ts-ignore
        dataProvider.getList('fuels', { sort: { field: 'name', order: 'ASC' }, pagination: { page: 1, perPage: 9999 } })
            .then(({ data }) => {
                setFuels(data as unknown as { [k: string]: any; }[]);
            });
    }, [])

    const validateDiscountType = (formData: any) => {
        if (formData.type === 'special' && !formData.description) {
            return { error: true, message: 'Campo de descrição está vazio' };
        }
        return { error: false };
    }

    const handleDiscountType = (formData: any, nextStep: (index?: number) => void) => {
        nextStep();
    }

    const validateFuelDiscount = (formData: any) => {
        if ((!formData.stationIds || !formData.stationIds?.length) && !formData.selectsAllPlaces) {
            return { error: true, message: 'Campo de postos está vazio' };
        }
        if (!formData.fuelDiscounts.filter(fd => fd.discounts && fd.discounts.length).length) {
            return { error: true, message: 'Nenhum desconto foi definido' };
        }
        if (formData.intervalInHours && !formData.usageLimit) {
            return { error: true, message: 'Defina um limite de uso ou remova o intervalo de uso' };
        }

        return { error: false };
    }

    const handleFuelDiscount = (formData: any, nextStep: (index?: number) => void) => {
        if (formData.type === 'default') {
            nextStep(4);
        } else {
            nextStep();
        }
    }

    const validateScheduleDiscount = (formData: any) => {
        if (formData.isSchedule) {
            if (!formData.startAt || !formData.endAt) {
                return { error: true, message: 'Defina uma data de início e fim' };
            } else if (!moment(formData.startAt).isValid() || !moment(formData.endAt).isValid()) {
                return { error: true, message: 'Defina uma data válida' };
            } else if (moment(formData.endAt) <= moment(formData.startAt)) {
                return { error: true, message: 'Data final não pode ser inferior a data inicial' };
            }

            if (!formData.frequency) {
                return { error: true, message: 'Defina o tipo de frequência' };
            }

            if (formData.frequency === 'daily' && (!formData.startTime || !formData.endTime)) {
                return { error: true, message: 'Defina um horário de início e fim' };
            } else if (formData.frequency === 'daily' && (formData.startTime && formData.endTime) && moment(formData.endTime) <= moment(formData.startTime)) {
                return { error: true, message: 'Horário de fim não pode ser inferior ao horário de início' };
            }

            if (formData.frequency === 'weekly' && (!formData.weekSchedual || formData.weekSchedual.filter(ws => ws.isActive && (!ws.startTime || !ws.endTime)).length)) {
                return { error: true, message: 'Defina um horário de início e fim' };
            } else if (formData.frequency === 'weekly' && formData.weekSchedual && formData.weekSchedual.filter(ws => ws.isActive && moment(ws.endTime) <= moment(ws.startTime)).length) {
                return { error: true, message: 'Horário de fim não pode ser inferior ao horário de início' };
            }
        }

        return { error: false };
    }

    const handleScheduleDiscount = (formData: any, nextStep: () => void) => {
        nextStep();
    }

    const validateCustomerGroup = (formData: any) => {
        if (formData.type === 'special' && !formData.isSchedule && !formData.customerGroupIds && !formData.customerIds) {
            return { error: true, message: 'Defina um grupo de clientes' };
        }
        return { error: false };
    }

    const validateNotification = (formData: any) => {
        if (formData.type === 'special' && formData.hasNotification) {
            if (!formData.notification?.title) {
                return { error: true, message: 'Defina um título para notificação' };
            }
            if (!formData.notification?.body) {
                return { error: true, message: 'Defina uma descrição para notificação' };
            }
            if (!formData.notification?.pushNotification && !formData.notification?.sms) {
                return { error: true, message: 'Defina um método de notificação' };
            }
            if (formData.notification.pushNotification && !formData.notification?.pushMessage) {
                return { error: true, message: 'Defina uma messagem push' };
            }
            if (formData.notification.sms && !formData.notification?.smsMessage) {
                return { error: true, message: 'Defina uma messagem sms' };
            }
            if (formData.notification?.sendNow !== true && !(moment(formData.notification?.sendAt) > moment(Date.now()).add(10, 'minutes'))) {
                return { error: true, message: 'Defina um horário de envio válido' };
            }
        }
        return { error: false };
    }

    const handleCustomerGroup = (formData: any, nextStep: () => void) => {
        nextStep();
    }

    const handleNotification = (formData: any, nextStep: () => void) => {
        nextStep();
    }

    const handleConcluedStep = (index) => {
        setCompletedSteps(value => ({ ...value, [index]: true }));
    }

    const handleCreateDiscount = (formData: any) => {
        setIsloading(true);
        dataProvider.create('discounts', {
            data: {
                ...formData,
                fuelDiscounts: formData.fuelDiscounts.reduce((acc, fuelDiscount) => {
                    if (fuelDiscount.discounts && fuelDiscount.discounts.length) {
                        return [...acc, ...fuelDiscount.discounts.map(discount => ({ ...discount, fuelId: fuelDiscount.id }))];
                    }
                    return acc;
                }, []),
            }
        }).then(response => {
            notify('Desconto criado com sucesso');
            redirect('/discounts');
        }).catch(error => {
            notify(error.message || 'Erro ao criar desconto', { type: 'warning' });
        }).finally(() => {
            setIsloading(false);
        });
    }

    const getInitialValues = useCallback(() => {
        const { fuelDiscounts: paramsFuelDiscounts, placeDiscounts: paramsPlaceDiscounts, dayScheduledDiscounts: paramsDayScheduledDiscounts, _scrollToTop, ...restParams } = params as { fuelDiscounts: FuelDiscount[], dayScheduledDiscounts: DayScheduledDiscount[], [k: string]: any } || {};

        const stationIds = uniq(paramsPlaceDiscounts?.map(fd => fd.placeId));
        let fuelDiscounts = uniqBy(paramsFuelDiscounts, v => [v.fuelId, v.alterPriceValue, v.alterPriceType, v.alterPriceValueType, v.points].join());
        fuelDiscounts = (fuels ? Object.values(fuels) : []).map(fuel => ({
            ...fuel,
            discounts: fuelDiscounts?.map((fuelDiscount) => FuelDiscount.createFuelDiscount(fuelDiscount)).filter(fd => fd.fuelId === fuel.id),
        }));

        const dayScheduledDiscounts = Array(7).fill({}).map((_, index) => (
            paramsDayScheduledDiscounts?.map(dayS => DayScheduledDiscount.createDayScheduledDiscount(dayS, true)).find(dayS => dayS.dayOfTheWeek === index) || DayScheduledDiscount.createDayScheduledDiscount({ dayOfTheWeek: index }, false)
        ));

        const { dayScheduledDiscounts: discountDayScheduledDiscounts, ...discount } = Discount.createDiscount({ type: 'default', chainId, stationIds, fuelDiscounts, dayScheduledDiscounts, ...restParams });

        return { ...discount, weekSchedual: discountDayScheduledDiscounts, chainId };
    }, [fuels, chainId]);

    return (
        <Permission permission={PERMISSIONS.FUEL_DISCOUNTS}>
            <Create title='Cadastrar desconto'>
                <WizardForm
                    sanitizeEmptyValues
                    isLoading={isLoading}
                    completedSteps={completedSteps}
                    defaultValues={getInitialValues()}
                    handleConcluedStep={handleConcluedStep}
                    formSteps={[
                        {
                            label: "Desconto",
                            component: <FormDiscountType />,
                            validate: validateDiscountType,
                            onNextFunction: handleDiscountType,
                        },
                        {
                            label: "Postos e Combustíveis",
                            component: <FormFuelDiscount fuels={fuels} chain={chain} />,
                            validate: validateFuelDiscount,
                            onNextFunction: handleFuelDiscount,
                        },
                        {
                            label: "Agendamento",
                            component: <FormScheduleDiscount />,
                            validate: validateScheduleDiscount,
                            onNextFunction: handleScheduleDiscount,
                            disableStep: (data) => {
                                return data.type === 'default';
                            }
                        },
                        {
                            label: "Clientes",
                            component: <FormCustomerGroup />,
                            validate: validateCustomerGroup,
                            onNextFunction: handleCustomerGroup,
                            disableStep: (data) => {
                                return data.type === 'default';
                            }
                        },
                        {
                            label: "Notificação",
                            component: <FormNotification />,
                            validate: validateNotification,
                            onNextFunction: handleNotification,
                            disableStep: (data) => {
                                return data.type === 'default';
                            }
                        },
                        {
                            label: "Confirmação",
                            component: <FormSummary chain={chain} />,
                            onNextFunction: handleCreateDiscount,
                            validate: (formData) => {
                                for (const validate of [validateDiscountType, validateFuelDiscount, validateScheduleDiscount, validateCustomerGroup]) {
                                    const result = validate(formData);
                                    if (result.error === true) {
                                        return result;
                                    }
                                }
                                return { error: false };
                            },
                            disableStep: (data, completedSteps) => {
                                if (data.type === 'default') {
                                    return [0, 1].some(index => !completedSteps[index] || completedSteps[index] === false);
                                } else if (data.type === 'special') {
                                    return [0, 1, 2, 3].some(index => !completedSteps[index] || completedSteps[index] === false);
                                }
                                return false;
                            }
                        },
                    ]}
                />
            </Create>
        </Permission>
    );
};

export default DiscountCreate;