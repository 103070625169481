import React from 'react';
import { Button, ButtonProps, useDataProvider, useListContext, useNotify, useRecordContext, useRefresh } from 'react-admin';

interface ButtonActionProps extends Omit<ButtonProps, 'onClick'> {
  onClick: (update: Function) => void;
}

const ButtonAction: React.FC<ButtonActionProps> = ({ onClick, label }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const record = useRecordContext();
  if (!record) { return null; }

  const doUpdate = ({ resource, id, data, previousData, successMessage, errorMessage }) => {
    dataProvider.update(resource, { id, data, previousData })
      .then(() => {
        notify(successMessage);
        refresh();
      })
      .catch(() => { notify(errorMessage, { type: 'warning' }); })
  }

  return (
    <Button
      label={label}
      onClick={(event) => {
        event.stopPropagation();
        onClick(doUpdate);
      }}
    />
  );
}

export const BlockCustomerButton: React.FC<ButtonProps> = () => {
  const record = useRecordContext();
  if (!record) { return null; }

  const { customer } = record;
  return (
    <ButtonAction
      label={customer.isBlocked ? 'Desbloquear' : 'Bloquear'}
      onClick={(update) => {
        const successMessage = `Cliente ${customer.isBlocked ? 'desbloqueado' : 'bloqueado'}`;
        const errorMessage = `Erro ao ${customer.isBlocked ? 'desbloquear' : 'bloquear'} cliente`;
        update({
          resource: 'customers',
          id: customer.id,
          data: { isBlocked: !customer.isBlocked },
          previousData: customer,
          successMessage,
          errorMessage
        })
      }} />
  );
}

export const IgnoreFraudButton: React.FC<ButtonProps> = () => {
  const record = useRecordContext();
  if (!record) { return null; }

  const { resource } = useListContext();
  const { isIgnored } = record;

  return (
    <ButtonAction
      disabled={isIgnored}
      label={isIgnored ? 'Ignorada' : 'Ignorar'}
      onClick={(update) =>
        update({
          resource: resource,
          id: record.id,
          data: { isIgnored: true },
          previousData: record,
          successMessage: 'Possível fraude ignorada',
          errorMessage: 'Erro ao ignorar possível fraude'
        })
      }
    />
  );
}

export const IgnoreCustomerFraudButton: React.FC<ButtonProps> = () => {
  const record = useRecordContext();
  if (!record) { return null; }

  let { customer } = record;

  if (!customer) {
    customer = record;
  }

  return (
    <ButtonAction
      label={customer.ignoreFraud ? 'Considerar novamente' : 'Ignorar Sempre'}
      onClick={(update) => {
        const successMessage = `Cliente ${customer.ignoreFraud ? 'removido da' : 'adicionado à'} lista branca`;
        const errorMessage = `Erro ao ${customer.ignoreFraud ? 'remover cliente da' : 'adicionar cliente à'} lista branca`;
        update({
          resource: 'customers',
          id: customer.id,
          data: { ignoreFraud: !customer.ignoreFraud },
          previousData: customer,
          successMessage,
          errorMessage
        })
      }}
    />
  );
}
