import { useState } from 'react';
import { ArrayField, BooleanField, ChipField, Datagrid, FunctionField, ReferenceArrayField, Show, SimpleShowLayout, SingleFieldList, Tab, TabbedShowLayout, TextField, useGetOne, usePermissions, useRecordContext } from 'react-admin';
import Permission from '../../components/Permission';
import { PERMISSIONS, daysOfTheWeek, frequencyChoices } from '../../constants';

import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { AdvertisementDisableButton } from '../../components';
import { getChainId } from '../../lib';
import AdPreviewOnMobile from './adPreviewOnMobile';

const DayScheduledAdvertisementsField = ({ data }: any) => (
    data?.dayScheduledAdvertisements ?
        <ArrayField source="dayScheduledAdvertisements">
            <Datagrid bulkActionButtons={false}>
                <FunctionField render={record => record && daysOfTheWeek.find(wd => wd.id === record.dayOfTheWeek)?.name} label="Dia" />
                <FunctionField render={record => record && record.startTime ? moment(record.startTime).format('HH:mm') : '--'} label="Inicio" />
                <FunctionField render={record => record && record.endTime ? moment(record.endTime).format('HH:mm') : '--'} label="Fim" />
            </Datagrid>
        </ArrayField>
        :
        <></>
);

const ScheduleField = () => {
    const record = useRecordContext();
    if (!record) { return null; }

    return (
        (record.startAt || record.endAt || record.frequency) ?
            <Box component="div" sx={{ p: 2, border: '1px dashed grey', marginTop: 2, marginBottom: 2 }}>
                <Typography variant="body1" style={{ marginBottom: 10 }}>Agendamento</Typography>
                <SimpleShowLayout>
                    <FunctionField render={record => record && record.startAt ? moment(record.startAt).format('DD/MM/YYYY HH:mm') : '--'} label="Data inicial" />
                    <FunctionField render={record => record && record.endAt ? moment(record.endAt).format('DD/MM/YYYY HH:mm') : '--'} label="Data final" />
                    <FunctionField render={record => record && record.frequency ? frequencyChoices.find(dt => dt.id === record.frequency)?.name : '--'} label="Frequência" />
                    <DayScheduledAdvertisementsField data={record} label="Horários" />
                </SimpleShowLayout>
            </Box>
            :
            <></>
    );
}

const Preview = () => {
    const record = useRecordContext();
    if (!record) { return null; }

    if (!record.filePath) {
        return (
            <Typography sx={{ marginTop: 4, marginBottom: 2 }}>O vídeo ainda está sendo processado, aguarde alguns minutos...</Typography>
        );
    }

    return (
        <AdPreviewOnMobile file={{ src: record.filePath, rawFile: { type: record.fileType } }} />
    );
}

const AdvertisementShow = (props) => {

    const [chainId] = useState(getChainId());
    if (!chainId) { return <Typography>Selecione uma Rede</Typography>; }

    const { data: chain } = useGetOne('chains', { id: chainId });
    const { permissions } = usePermissions();

    return (
        <Permission permission={PERMISSIONS.ADVERTISEMENT}>
            <Show title='Anúncio' {...props}>
                <TabbedShowLayout>
                    <Tab label="Resumo">
                        <AdvertisementDisableButton style={{ marginTop: 20, marginBottom: 20 }} isOutlined />
                        <TextField source="name" label="Nome" />
                        <TextField source="priority" label="Prioridade" emptyText="--" />
                        <TextField source="duration" label="Duração (segundos)" emptyText="--" />
                        <ReferenceArrayField label="Postos" reference="stations" source="stationIds">
                            <SingleFieldList>
                                <ChipField source="name" />
                            </SingleFieldList>
                        </ReferenceArrayField>
                        <ReferenceArrayField source="fuelIds" reference="fuels" label="Combustíveis">
                            <SingleFieldList>
                                <ChipField source="name" />
                            </SingleFieldList>
                        </ReferenceArrayField>
                        <BooleanField source="isEnabled" label="Ativo" />
                        <ScheduleField />
                    </Tab>
                    <Tab label="Visualização">
                        <Preview />
                    </Tab>
                </TabbedShowLayout>
            </Show>
        </Permission>
    )
};

export default AdvertisementShow;
