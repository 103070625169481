import React, { useState, useEffect } from 'react';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { PERMISSIONS } from '../../constants';
import { formatNumber, getInitialChart, getDatesInRange } from '../../utils';
import { getChainId } from '../../lib';
import useDashboardContext from '../../hooks/useDashboardContext';
import { DashboardWrapper, RotatedAxisTick } from '../../components';
import moment from 'moment';

const initialData = { generatedpoints: 0, redeemedpoints: 0 };

export const GeneratedXRedeemedGraph = () => {
    const dataProvider = useDataProvider();
    const { permissions } = usePermissions();
    const notify = useNotify();
    const { from, to, chain, stationIds } = useDashboardContext();

    const initialChart = getInitialChart(from, to, initialData);
    const [chartData, setChartData] = useState(initialChart);

    const chainId = permissions && permissions.includes(PERMISSIONS.ADMIN) ? chain : getChainId();

    useEffect(() => {
        const filter = {
            from: moment.utc(from),
            to: moment.utc(to),
        };
        if (stationIds && stationIds.length > 0) { filter.placeIds = stationIds; }

        const data = [];
        getDatesInRange(from, to).map(date =>
            data.push(
                { id: date, generatedpoints: 0, redeemedpoints: 0 }
            )
        );

        dataProvider.getList('dashboard/generated-points', { filter, chainId })
            .then(response => {
                const datesWithdata = response.data;

                datesWithdata.forEach(date => {
                    const currDate = data.find(chartDate => chartDate.id === date.id);
                    if (currDate && (date.generatedpoints > 0 || date.redeemedpoints > 0)) {
                        currDate.generatedpoints = date.generatedpoints;
                        currDate.redeemedpoints = date.redeemedpoints;
                    }
                });
                setChartData(data);
            })
            .catch((e) => { notify('Erro ao carregar gráfico de pontos gerados X pontos resgatados', { type: 'warning' }); });
    }, [from, to, chain, stationIds]);

    return (
        <DashboardWrapper
            permission={PERMISSIONS.GENERATED_REDEEMED_POINTS}
            width='45%'
            // link='/report-points'
            title="Pontos Gerados X Pontos Resgatados"
            content={
                <LineChart data={chartData}>
                    <XAxis dataKey="id" tick={<RotatedAxisTick fontSize={12} />} height={60} />
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis domain={['auto', 'auto']} type='number' />
                    <Tooltip formatter={(value, name, props) => formatNumber(value)} />
                    <Legend height={50} layout="vertical" verticalAlign="top" />
                    <Line type='monotone' dataKey='generatedpoints' stroke="#82ca9d" activeDot={{ r: 6 }} name='Pontos Gerados' />
                    <Line type='monotone' dataKey='redeemedpoints' stroke="#8884d8" name='Pontos Resgatados' />
                </LineChart>
            }
        />
    );
}