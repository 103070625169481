import React, { useEffect, useState } from 'react';
import { useDataProvider, usePermissions } from 'react-admin';
import { Area, AreaChart, XAxis, YAxis, Tooltip } from 'recharts';
import { RadioGroup, Radio, FormControlLabel, FormControl } from '@mui/material';
import { PERMISSIONS } from '../../constants';
import { getChainId, getEmployeeType, getAttendantId } from '../../lib';
import useDashboardContext from '../../hooks/useDashboardContext';
import { formatNumber, getInitialChart } from '../../utils';
import { DashboardWrapper, RotatedAxisTick } from '../../components';
import { EmployeeType } from '../../models';
import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

const colors = ['#82ca9d', '#03c8ff', '#ffe203'];
const initialData = { totalValue: 0, totalFillins: 0, totalAmount: 0, discount: 0 };

export const FillinsChart = () => {
    const dataProvider = useDataProvider();
    const { permissions } = usePermissions();
    const { from, to, chain, origin, stationIds } = useDashboardContext();

    const initialChart = getInitialChart(from, to, initialData);

    const [chartColor, setChartColor] = useState(colors[0]);
    const [chartData, setChartData] = useState(initialChart);
    const [chartType, setChartType] = useState('totalValue');

    const chainId = permissions && permissions.includes(PERMISSIONS.ADMIN) ? chain : getChainId();

    useEffect(() => {
        const filter = { from, to, chainId, origin };
        if (stationIds && stationIds.length > 0) { filter.stationIds = stationIds; }
        if (getEmployeeType() === EmployeeType.attendant) { filter.attendantId = getAttendantId(); }

        dataProvider.getList('dashboard/fillins-summary', { filter })
            .then(({ data }) => setChartData(data));
    }, [from, to, chain, origin, stationIds]);

    return (
        <DashboardWrapper
            permission={PERMISSIONS.FILLINS_CHART}
            filters={
                <FormControl>
                    <RadioGroup row defaultValue='totalValue' onChange={({ target: { value } }) => {
                        setChartType(value);
                        switch (value) {
                            case 'totalAmount':
                                setChartColor(colors[2]);
                                break;
                            case 'totalFillins':
                                setChartColor(colors[1]);
                                break;
                            default:
                                setChartColor(colors[0]);
                                break;
                        }
                        // work around for line animation
                        setChartData([...chartData]);
                    }}>
                        <FormControlLabel value='totalValue' label='Dinheiro' control={<Radio />} />
                        <FormControlLabel value='totalFillins' label='Abastecimentos' control={<Radio />} />
                        <FormControlLabel value='totalAmount' label='Volume' control={<Radio />} />
                        <FormControlLabel value='discount' label='Descontos' control={<Radio />} />
                    </RadioGroup>
                </FormControl>
            }
            aspectRatio={6.0 / 2.5}
            content={
                <AreaChart data={chartData}>
                    <YAxis domain={[0, 'auto']} type='number' />
                    <XAxis dataKey="date" tick={<RotatedAxisTick fontSize={12} />} height={60} />
                    <Tooltip
                        labelFormatter={(value) => `${value} ${moment(value, 'DD/MM/YYYY').format('ddd').toLocaleUpperCase()}`}
                        formatter={(value) => {
                            switch (chartType) {
                                case 'totalAmount':
                                    return formatNumber(value, { style: 'decimal', unit: 'liter' });
                                case 'totalFillins':
                                    return `${formatNumber(value)} Abastecimentos`;
                                default:
                                    return formatNumber(value, { style: 'currency', currency: 'BRL' });
                            }
                        }} />
                    <defs>
                        {colors.map((color, i) => (
                            <linearGradient key={i} id={color} x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={color} stopOpacity={0.75} />
                                <stop offset="95%" stopColor={color} stopOpacity={0} />
                            </linearGradient>
                        ))}
                    </defs>
                    <Area
                        stroke={chartColor}
                        fillOpacity={1}
                        fill={`url(#${chartColor})`}
                        dataKey={chartType}
                        name='Total'
                        type="monotone"
                    />
                </AreaChart>
            }
        />
    );
};
