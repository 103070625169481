import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateTimeInput, useDataProvider, useNotify } from 'react-admin';
import { Loader, ReportLayout, TableGeneric } from '../../components';
import { PERMISSIONS } from '../../constants';
import { getChainId } from '../../lib';

const MinimumStockReport = ({ data: { transactions, totalPoints } }) =>
    <>
        <TableGeneric
            headCells={['Total']}
            arrayData={totalPoints}
            title="Pontos expirados"
            style={{ width: '100%', margin: '0 0 1em 0' }}
        />
        <TableGeneric
            headCells={[
                'Cliente',
                'Quantidade de pontos',
                'Data e hora da expiração',
            ]}
            arrayData={transactions}
            title="Transações de expiração"
            style={{ width: '100%' }}
        />
    </>

export default function ReportMinimumStock() {
    const [dateFrom, setDateFrom] = useState(moment().startOf('day'));
    const [dateTo, setDateTo] = useState(moment().endOf('day'));
    const [loading, setLoading] = useState();
    const [expiredPointsData, setExpiredPointsData] = useState();
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const fetchData = async () => {
        setLoading(true);
        dataProvider.getList(`chains/${getChainId()}/reports/expired-points`, { filter: { from: dateFrom.utc(), to: dateTo.utc() } })
            .then(response => {
                const formattedExpiredTransactions = response.data.expiredTransactions.map(({ createdAt, customerCpf, value }) => ({
                    customerCpf,
                    value: -value,
                    createdAt
                }))

                setExpiredPointsData({
                    transactions: formattedExpiredTransactions,
                    totalPoints: [{ total: response.data.totalExpiredPoints }]
                });
            }).catch(() => {
                notify('Erro ao carregar pontos expirados', { type: 'warning' });
            })
        setLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, [dateFrom, dateTo]);

    const filters = [{
        from: dateFrom.format('DD/MM/YYYY'),
        to: dateTo.format('DD/MM/YYYY')
    }];

    return (
        <ReportLayout
            permission={PERMISSIONS.EXPIRED_POINTS_REPORT}
            title='Relatório de Expiração de pontos'
            filterTablePrint={
                <TableGeneric
                    headCells={['De', 'Até']}
                    arrayData={filters}
                    title="Filtros"
                    style={{ width: '100%', margin: '1em 0' }}
                />
            }
            filters={[
                <DateTimeInput
                    label='De'
                    ampm={false}
                    disableFuture
                    maxDate={dateTo}
                    parse={(value) => moment(value).toISOString()}
                    defaultValue={moment().startOf('day').toISOString()}
                    onChange={date => setDateFrom(date)}
                />,
                <DateTimeInput
                    label='Até'
                    ampm={false}
                    disableFuture
                    minDate={dateFrom}
                    parse={(value) => moment(value).toISOString()}
                    defaultValue={moment().endOf('day').toISOString()}
                    onChange={date => setDateTo(date)}
                />,
            ]}
            content={loading ? <Loader /> : expiredPointsData ? <MinimumStockReport data={expiredPointsData} /> : null} />
    )
}