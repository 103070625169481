import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from "moment";
import {
  Datagrid,
  DateField,
  NumberField,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  SelectArrayInput,
  TextField,
  useListContext,
} from "react-admin";
import { ReportList } from "../../components";
import { BetweenDatesInput } from "../../components/BetweenDatesInput";
import ListPagination from "../../components/ListPagination";
import Permission from "../../components/Permission";
import { PERMISSIONS } from "../../constants";
import { getPlaceId } from "../../lib";

const useStyles = makeStyles({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
});

const FavoriteStations = () => {
  const classes = useStyles();
  const props = useListContext();

  let data = [];
  let summaryData = [];

  if (props.data) {
    const reportInfo = props.data[0];

    if (reportInfo) {
      data = reportInfo.data;
      summaryData = [reportInfo.summaryData];
    }
  }

  return (
    <>
      <Typography className={classes.title}>Totais</Typography>
      <Datagrid bulkActionButtons={false} style={{ marginBottom: 40 }} data={summaryData}>
        <ReferenceField
          source="id"
          reference="stations"
          label="Posto"
          textAlign="center"
          link={false}
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <NumberField
          source="total_periodo"
          label="Favoritos no período"
          textAlign="center"
          emptyText="0"
          sortable={false}
        />
        <NumberField
          source="total"
          label="Favoritos no geral"
          textAlign="center"
          emptyText="0"
          sortable={false}
        />
      </Datagrid>

      <Typography className={classes.title}>Favoritos</Typography>
      <Datagrid bulkActionButtons={false} data={data}>
        {!getPlaceId() &&
          <ReferenceField
            source="station_id"
            reference="stations"
            label="Posto"
            link="show"
            textAlign="center"
          >
            <TextField source="name" />
          </ReferenceField>
        }
        <ReferenceField
          source="id"
          reference="customers"
          label="Cliente"
          textAlign="center"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField source="created_at" label="Favoritado em" textAlign="center" />
      </Datagrid>
    </>
  );
};

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  return (
    <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
      <DateField source="from" textAlign="center" label="De" sortable={false} />
      <DateField source="to" textAlign="center" label="Até" sortable={false} />
      <ReferenceArrayField source="stationIds" reference="stations" emptyText="Todos" textAlign="center" label="Postos" sortable={false} >
        <Datagrid bulkActionButtons={false}>
          <TextField source="name" label={"Postos"} />
        </Datagrid>
      </ReferenceArrayField>
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
    </Datagrid>
  );
};

const ReportFavoriteStation = (props) => {

  const listFilters = [
    <BetweenDatesInput alwaysOn />,
    <ReferenceArrayInput
      source="stationIds"
      reference="stations"
      perPage={null}
      sort={{ field: "name", order: "ASC" }}
      alwaysOn>
      <SelectArrayInput
        label="Posto"
        emptyText="Todos"
        style={{ minWidth: 180 }}
        optionText={'name'}
      />
    </ReferenceArrayInput>

  ];

  const stationIndex = listFilters.findIndex(({ props }) => props.reference === "stations");
  const placeId = getPlaceId();

  if (placeId && stationIndex > -1) {
    listFilters.splice(stationIndex, 1);
  }

  return (
    <Permission permission={PERMISSIONS.FAVORITE_STATION_REPORT}>
      <ReportList
        {...props}
        basePath="report-favorite-station"
        title="Postos Favoritos"
        resource="chains/chainId/reports/favorite-station"
        filters={listFilters}
        sort={{ field: 'created_at', order: 'DESC' }}
        perPage={25}
        pagination={<ListPagination />}
        filterDefaultValues={{
          stationIds: placeId ? [placeId] : null,
          from: moment().startOf('day').subtract(1, "month").toISOString(),
          to: moment().endOf('day').toISOString()
        }}
        bulkActionButtons={false}
        exporter={false}
        landscape
        titleOnPrint="Postos Favoritos"
        filterTablePrint={<FilterTablePrint />}
      >
        <FavoriteStations />
      </ReportList>
    </Permission >
  );
};

export default ReportFavoriteStation;