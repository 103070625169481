import { USER_KEY, JWT_TOKEN, DEFAULT_API_ADMIN } from '../constants';

const authProvider = {
    login: params => {
        const { username, password } = params;
        const request = new Request(`${DEFAULT_API_ADMIN}/employees/login`, {
            method: 'POST',
            body: JSON.stringify({ login: username?.trim(), password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw response;
                }

                return response.json();
            })
            .then((user) => {
                if (user?.mustChangePassword === true) {
                    throw user;
                }

                localStorage.setItem(USER_KEY, JSON.stringify(user));
                localStorage.setItem('id', user.id);
                const { authorization } = user;
                localStorage.setItem(JWT_TOKEN, authorization);
            });
    },
    updatePassword: (id, params, user) => {
        const { password } = params;
        const request = new Request(`${DEFAULT_API_ADMIN}/employees/${id}/update-password`, {
            method: 'PATCH',
            body: JSON.stringify({ password: password?.trim() }),
            headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': user.authorization }),
        });

        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }

                return response.json();
            })
            .then(() => {
                localStorage.setItem(USER_KEY, JSON.stringify(user));
                const { authorization } = user;
                localStorage.setItem(JWT_TOKEN, authorization);
            });
    },
    logout: params => {
        localStorage.removeItem(USER_KEY);
        localStorage.removeItem(JWT_TOKEN);
        localStorage.removeItem('fullName');
        localStorage.removeItem('role');
        localStorage.removeItem('authorization');
        localStorage.removeItem('id');
        localStorage.removeItem('permissions');
        return Promise.resolve();
    },
    checkAuth: params => {
        if (localStorage.getItem(JWT_TOKEN)) {
            return Promise.resolve()
        }
        else {
            return Promise.reject({ redirectTo: '/login' });
        }
    },
    checkError: error => {
        const { status } = error;
        if (status === 401 || status === 403) {
            localStorage.removeItem(JWT_TOKEN);
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: params => {
        const user = JSON.parse(localStorage.getItem(USER_KEY) || '{}');
        if (!user || !user.permissions || user.permissions.length === 0) {
            return Promise.reject('logout');
        }

        return Promise.resolve(user.permissions);
    },
    getIdentity: params => {
        const { id, employeeName } = JSON.parse(localStorage.getItem(USER_KEY) || '{}');
        return Promise.resolve({ id, fullName: employeeName });
    }
};
export default authProvider;