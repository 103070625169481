import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React from 'react';
import {
  BooleanInput,
  DateField,
  Edit,
  FormDataConsumer,
  Labeled,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  TimeInput,
  maxLength,
  required
} from 'react-admin';
import Permission from '../../components/Permission';
import { RichTextInputCustom } from '../../components/RichTextInputCustom';
import { PERMISSIONS } from '../../constants';

const useStyles = makeStyles({
  richText: {
    marginTop: 16,
    width: 'auto',
    '& .ql-editor': {
      minHeight: 200
    }
  },
  charCounter: {
    right: '1%',
    width: 'auto',
    heigth: '100%',
    fontSize: 12,
    opacity: .75,
    fontFamily: 'Helvetica',
    textAlign: 'end',
  },
  labeledWidth: {
    width: '70%'
  },
});

const timetzRegex = /^\d{2}:\d{2}:\d{2}\.\d{3}\+\d{2}$/;

const editMarketingCampaigns: React.FC = props => {
  const MAX_LENGTH_PUSH = 130;
  const classes = useStyles();

  return (
    <Permission permission={PERMISSIONS.MARKETING_CAMPAIGNS}>
      <Edit {...props} title="Editar campanha">
        <SimpleForm>
          <TextInput source="title" label="Título" sx={{ minWidth: 400 }} />
          <ReferenceInput source="marketingCampaignId" reference="chains/chainId/marketing-campaigns" label="Campanha">
            <SelectInput
              label="Campanha"
              source="marketingCampaignId"
              optionText="label"
              validate={required()}
              sx={{ minWidth: 400 }}
            />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData }) =>
              <Box sx={{ marginBottom: 2 }}>
                <Labeled label="Início da campanha">
                  <DateField variant="body1" source="startDate" sx={{ marginRight: 12 }} />
                </Labeled>
                <Labeled label="Fim da campanha">
                  {formData.alwaysActive
                    ? <Typography variant="body1">Sempre ativa</Typography>
                    : <DateField source="endDate" variant="body1" />}
                </Labeled>
              </Box>}
          </FormDataConsumer>
          <TimeInput
            label="Horário de Envio"
            source="time"
            format={(v) => {
              if (timetzRegex.test(v)) {
                return moment(v, "HH:mm:ss.SSSZ").format("HH:mm");

              } else if (v instanceof Date) {
                return moment(v).format("HH:mm");
              }
              return null;
            }}
            variant="filled"
            sx={{ width: 160, contain: 'content' }}
            required
          />
          <div className={classes.richText}>
            <Labeled label="Descrição: ">
              <RichTextInputCustom
                source="body"
                label="Essa mensagem pode conter formatações e será exibida no aplicativo, na tela de notificações. Ela NÃO será enviada na notificação PUSH"
                validate={required()}
              />
            </Labeled>
          </div>
          <Labeled label="Enviar via: ">
            <>
              {/* <BooleanInput source="sms" label="SMS" /> */}
              <BooleanInput source="pushNotification" label="Notificação no aplicativo" />
            </>
          </Labeled>
          <FormDataConsumer>
            {({ formData }) => formData.pushNotification &&
              <Labeled label="Esta mensagem será enviada na notificação Push:" className={classes.labeledWidth}>
                <>
                  <TextInput
                    style={{ width: '100%' }}
                    source="pushMessage"
                    label="Mensagem Push"
                    validate={[required(), maxLength(MAX_LENGTH_PUSH)]}
                    inputProps={{ autoComplete: 'off' }}
                    helperText={false}
                  />
                  <span className={classes.charCounter}>
                    {formData.pushMessage?.length || 0}/{MAX_LENGTH_PUSH}
                  </span>
                </>
              </Labeled>
            }
          </FormDataConsumer>
          <BooleanInput source="endCampaign" label="Encerrar campanha" sx={{ marginTop: 4 }} />
          <Typography variant="body1" style={{ width: '100%', marginTop: 16 }}>
            * Quaisquer alterações realizadas estarão valendo apenas a partir do dia seguinte.
          </Typography>
        </SimpleForm>
      </Edit>
    </Permission>
  )
}

export default editMarketingCampaigns;
