import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import {
    ChipField,
    Datagrid,
    DateField,
    DateInput,
    NumberField,
    ReferenceArrayField,
    ReferenceArrayInput,
    ReferenceField,
    SelectArrayInput,
    SingleFieldList,
    TextField,
    useListContext,
    usePermissions
} from 'react-admin';
import { CustomizableDatagrid, ReportList } from '../../components';
import { PartiallyHiddenCpfField } from "../../components/Fields";
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import { PERMISSIONS } from "../../constants";
import { getChainId, getPlaceId } from "../../lib";
import { BetweenDatesInput } from '../../components/BetweenDatesInput';

const useStyles = makeStyles(theme => ({
    title: {
        margin: '10px 0 10px 0',
        textAlign: 'center',
        fontWeight: 'bold'
    },
}));

const TopCustomersList = () => {
    const classes = useStyles();
    const props = useListContext();

    let data = [], summaryData = [];

    if (props.data) {
        const reportInfo = props.data[0];

        if (reportInfo) {
            data = reportInfo.data;
            summaryData = [reportInfo.summaryData];
        }
    }

    return (
        <>
            <Typography className={classes.title}>Total</Typography>
            <Datagrid bulkActionButtons={false} style={{ marginBottom: 60 }} data={summaryData} total={1}>
                <NumberField
                    source="total_value"
                    label="Total em Dinheiro"
                    textAlign="center"
                    emptyText="R$ 0,00"
                    options={{
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }}
                    sortable={false}
                />
                <TextField
                    source="total_fillins"
                    textAlign="center"
                    label="Total de abastecimentos"
                    sortable={false}
                />
            </Datagrid>

            <Typography className={classes.title}>Top Clientes</Typography>
            <CustomizableDatagrid bulkActionButtons={false} resource={props.resource} data={data} defaultColumns={['cpf', 'id', 'refuels', 'total_points', 'total_amount']}>
                <PartiallyHiddenCpfField
                    source="cpf"
                    label="CPF do Cliente"
                    textAlign="center"
                    emptyText="Indefinido"
                    sortable={false}
                />
                <ReferenceField
                    reference="customers"
                    source="id"
                    label="Nome"
                    textAlign="center"
                    emptyText="Indefinido"
                    basePath="customers"
                    sortable={false}
                >
                    <TextField source="name" />
                </ReferenceField>
                <TextField
                    source="refuels"
                    label="Reabastecimentos"
                    textAlign="center"
                    emptyText="Indefinido"
                />
                <NumberField
                    source="total_points"
                    label="Valor Total"
                    textAlign="center"
                    emptyText="R$ 0,000"
                    options={{
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }}
                />
                <NumberField
                    source="total_amount"
                    label="Quantidade de Litros"
                    textAlign="center"
                    emptyText="0 l"
                    options={{
                        style: 'unit',
                        unit: 'liter',
                    }}
                />
            </CustomizableDatagrid>
        </>
    );
};

const FilterTablePrint = () => {
    const classes = useStyles();
    const { filterValues } = useListContext();

    return (
        <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
            <DateField source="from" textAlign="center" label="De" sortable={false} />
            <DateField source="to" textAlign="center" label="Até" sortable={false} />
            <ReferenceArrayField source="placeIds" emptyText="Todos" reference="stations" label="Postos">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <ReferenceArrayField source="fuelIds" emptyText="Todos" reference="fuels" label="Combustíveis">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
        </Datagrid>
    );
}
const topCustomersExport = async (data, fetchRelatedRecords) => {
    let topCustomers = data[0].data;
    const relations = [
        { field: 'id', resource: 'customers' }
    ];

    const fetchData = await fetchRelatedRecords(relations, topCustomers);

    const getData = (resource) => {
        return fetchData.find(({ resource: res }) => res === resource)?.results || [];
    }

    return topCustomers.map((topCustomer) => {
        const { id: customer_id } = topCustomer;
        const customer = getData('customers').find(({ id }) => id === customer_id);
        return {
            cpf: topCustomer.cpf,
            nome: customer?.name || 'Indefinido',
            reabastecimentos: topCustomer.refuels,
            valor_total: topCustomer.total_points,
            quantidade_de_litros: topCustomer.total_amount,
        }
    })
}

const ReportTopCustomers = (props) => {
    const { permissions } = usePermissions();

    const listFilters = [
        <BetweenDatesInput alwaysOn />,
        <ReferenceArrayInput
            source="placeIds"
            reference="stations"
            sort={{ field: "name", order: "ASC" }}
            perPage={null}
            alwaysOn
        >
            <SelectArrayInput
                label="Postos"
                style={{ minWidth: 180 }}
                optionText="name" />
        </ReferenceArrayInput>,
        <ReferenceArrayInput
            source="fuelIds"
            reference="fuels"
            sort={{ field: "name", order: "ASC" }}
            perPage={null}
            alwaysOn
        >
            <SelectArrayInput
                label="Combustíveis"
                style={{ minWidth: 180 }}
                optionText="name" />
        </ReferenceArrayInput>,
    ];

    if (permissions === PERMISSIONS.ADMIN) {
        listFilters.splice(2, 0,
            <ReferenceInput source="chainId" reference="chains" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
                <SelectInput
                    label="Rede"
                    emptyText="Todos"
                    optionText={'name'} />
            </ReferenceInput>,
        )
    }

    const stationsIndex = listFilters.findIndex(({ props }) => props.reference === 'stations');
    if (!!getPlaceId() && stationsIndex > -1) {
        listFilters.splice(stationsIndex, 1);
    }

    return (
        <Permission permission={PERMISSIONS.TOP_CUSTOMERS_REPORT}>
            <ReportList
                {...props}
                basePath="report-top-customers"
                title="Top Clientes"
                resource="chains/chainId/reports/top-customers"
                filters={listFilters}
                filter={getChainId() && { chainId: getChainId() }}
                sort={{ field: 'total_points', order: 'DESC' }}
                perPage={25}
                filterDefaultValues={{
                    placeId: getPlaceId(),
                    from: moment().startOf('day').subtract(1, 'month').toISOString(),
                    to: moment().endOf('day').toISOString()
                }}
                pagination={<ListPagination />}
                bulkActionButtons={false}
                titleOnPrint="Relatório de Top Clientes"
                filterTablePrint={<FilterTablePrint />}
                customExporter={topCustomersExport}
                landscape
                showPagination
            >
                <TopCustomersList />
            </ReportList>
        </Permission>
    )
};

export default ReportTopCustomers;