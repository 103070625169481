import React from 'react';
import {
  Edit,
  TextInput,
  SimpleForm,
  ReferenceArrayInput,
  SelectArrayInput,
} from 'react-admin';
import { PERMISSIONS } from '../../constants';
import Permission from '../../components/Permission';

const edit = ({ ...props }) => {
  const transform = data => {
    delete data.stations
    return data
  }

  return (
    <Permission permission={PERMISSIONS.STATION_GROUPS}>
      <Edit {...props} transform={transform} title="Editar grupo de postos">
        <SimpleForm>
          <TextInput source="name" label="Nome" />
          <TextInput source="description" label="Descrição" />
            <ReferenceArrayInput
              sort={{ field: 'name', order: 'ASC' }}
              perPage={null}
              label="Postos"
              source="stationIds"
              reference="chains/chainId/stations"
            >
              <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
        </SimpleForm>
      </Edit>
    </Permission>
  )
}

export default edit;
