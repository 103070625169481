import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateInput, DateTimeInput, NumberInput, SaveButton } from 'react-admin';
import { DropDownPartners } from '../../components';
import { getPlaceId } from '../../lib/';

const useStyles = makeStyles({
    imageWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        height: 100,
        width: 120,
    },
    button: {
        margin: '25px auto'
    },
    row: {
        display: 'flex',
        alignItems: "flex-end",
        justifyContent: 'space-between',
        margin: '0 0 10px 0'
    },
    filterRow: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    filterWrapper: {
        margin: '0 12px 0 0',
    },
});

const Filters = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.filterRow}>
            {React.Children.map(children, child =>
                <div className={classes.filterWrapper}>
                    {child}
                </div>
            )}
        </div>
    )
}

export default ({ products, placeId, saveCallback, places, setSelectedPlaceCallback, loading, entry, writeOff }) => {
    const classes = useStyles();
    const [date, setDate] = useState(moment());
    const [reason, setReason] = useState("");
    const [changedProducts, setChangedProducts] = useState([]);

    const inputOnChange = ({ productId, value, stockType }) => {
        const changedProductIndex = changedProducts.findIndex((p) => p.productId === productId && p.stockType === stockType)
        if (changedProductIndex === -1) {
            changedProducts.push({ productId, stockType, value });
        } else {
            changedProducts[changedProductIndex].value = value;
        }
        setChangedProducts([...changedProducts]);
    };

    useEffect(() => {
        setChangedProducts([])
    }, [placeId]);

    return (
        <>
            <div className={classes.row}>
                <Filters>
                    <DateInput
                        label={entry ? 'Data de entrada' : 'Data da baixa'}
                        ampm={false}
                        name="date"
                        source="date"
                        disableFuture
                        parse={(value) => moment(value).toISOString()}
                        defaultValue={moment().startOf('day').toISOString()}
                        onChange={date => setDate(date)}
                        sx={{ marginBottom: 0 }}
                        helperText={false}
                    />
                    {writeOff &&
                        <TextField
                            label="Justificativa"
                            variant="standard"
                            value={reason}
                            required
                            sx={{ marginBottom: 0 }}
                            onChange={event => setReason(event.target.value)}
                        />
                    }
                    {!getPlaceId() &&
                        <DropDownPartners
                            label="Unidade"
                            value={placeId}
                            setValue={setSelectedPlaceCallback}
                            arrayData={places}
                        />
                    }
                </Filters>
                <SaveButton
                    disabled={(loading || (!changedProducts || changedProducts.length === 0)) || (writeOff && !reason)}
                    onClick={
                        () => {
                            saveCallback({ date: date.utc().toISOString(), changedProducts, reason })
                            setChangedProducts([])
                            setReason("")
                        }}
                />
            </div>
            {products && products.length > 0 ?
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Produto</TableCell>
                                <TableCell align="center">Nome</TableCell>
                                <TableCell align="center">{entry ? 'Entrada em mostruário' : 'Baixa em mostruário'}</TableCell>
                                <TableCell align="center">{entry ? 'Entrada em depósito' : 'Baixa em depósito'}</TableCell>
                                <TableCell align="center">Mostruário</TableCell>
                                <TableCell align="center">Depósito Central</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products && products.map(row => {
                                const showcaseProduct = changedProducts.find((p) => p.productId === row.id && p.stockType === 'showcase')
                                const warehouseProduct = changedProducts.find((p) => p.productId === row.id && p.stockType === 'warehouse')
                                return (
                                    <TableRow key={row.id}>
                                        <TableCell align="center">
                                            {row.imagePath ?
                                                <div className={classes.imageWrapper}>
                                                    <img src={row.imagePath} style={{ width: '100%' }} alt='Imagem do produto' />
                                                </div>
                                                :
                                                <p>Imagem indisponível</p>
                                            }
                                        </TableCell>
                                        <TableCell align="center">{row.name}</TableCell>
                                        <TableCell align="center">
                                            <NumberInput
                                                label={entry ? 'Entrada' : 'Baixa'}
                                                min={0}
                                                value={(showcaseProduct ? showcaseProduct.value || '' : '')}
                                                variant="outlined"
                                                
                                                source={`showcaseEntry-${row.id}`}
                                                onChange={event => inputOnChange({ value: event.target.value, productId: row.id, stockType: 'showcase' })}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <NumberInput
                                                label={entry ? 'Entrada' : 'Baixa'}
                                                value={(warehouseProduct ? warehouseProduct.value || '' : '')}
                                                min={0}
                                                variant="outlined"
                                                source={`warehouseEntry-${row.id}`}
                                                onChange={event => inputOnChange({ value: event.target.value, productId: row.id, stockType: 'warehouse' })}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            {entry ?
                                                (+row.productPlaceInfo.showcase || 0) + (showcaseProduct ? +showcaseProduct.value || 0 : 0)
                                                :
                                                (+row.productPlaceInfo.showcase || 0) - (showcaseProduct ? +showcaseProduct.value || 0 : 0)
                                            }
                                        </TableCell>
                                        <TableCell align="center">
                                            {entry ?
                                                (+row.productPlaceInfo.warehouse || 0) + (warehouseProduct ? +warehouseProduct.value || 0 : 0)
                                                :
                                                (+row.productPlaceInfo.warehouse || 0) - (warehouseProduct ? +warehouseProduct.value || 0 : 0)
                                            }
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <Typography style={{ marginTop: 20 }}>Nenhum produto no inventário</Typography>
            }
        </>
    );
}