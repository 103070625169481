import UserIcon from '@mui/icons-material/People'
import list from './AttendantUserList'
import create from './AttendantUserCreate'
import edit from './AttendantUserEdit'

export default {
  edit,
  list,
  create,
  name: 'attendant-employees',
  permissions: [],
  options: { label: 'Usuários frentistas' },
  icon: UserIcon,
}
