import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useCallback, useEffect, useState } from 'react';
import { FormDataConsumer, InputHelperText, useDataProvider, useInput, useNotify } from 'react-admin';

const getItemIndex = (indexString) => {
    const matches = indexString.match(/\[([0-9]*?)\]/);
    return matches ? matches[1] : '';
};

const LazyLoadAutoCompleteInput = ({ source, reference, label, style, format, parse, isInsideArray, arraySource, id, record = {}, defaultChoices, defaultId, validate, helperText, ...props }) => {
    const {
        field,
        fieldState: { error, invalid, isTouched },
        formState: { isSubmitted },
    } = useInput({ source, validate });

    const [choices, setChoices] = useState([]);
    const [search, setSearch] = useState('');
    const [selectedIds, setSelectedIds] = useState();
    const [value, setValue] = useState(null);
    const [timeoutId, setTimeoutId] = useState(setTimeout(() => { }, 1));
    const [itemIndex, setItemIndex] = useState('');
    const dataProvider = useDataProvider();
    const notify = useNotify();

    useEffect(() => {
        getChoices();
        getInitialSelected();
    }, [props.filter]);

    useEffect(() => {
        (defaultChoices) && getChoices();
    }, [defaultChoices]);

    const onInputChange = useCallback((event, newValue) => {
        const parsedValue = parse ? parse(newValue) : newValue;
        if (parsedValue !== search) {
            setSearch(newValue);
            clearTimeout(timeoutId);
            const id = setTimeout(() => { getChoices(parsedValue); }, 400);
            setTimeoutId(id);
        }
    }, [search]);

    const getInitialSelected = () => {
        const id = field.value || defaultId;
        if (id && defaultChoices) {
            const initialValue = defaultChoices.find(item => item.id === id);
            setValue(initialValue);
        } else if (id) {
            dataProvider.getOne(`${reference}`, { id })
                .then(selectedItemData => {
                    const initialValue = selectedItemData.data;
                    if (initialValue) {
                        setValue(initialValue);
                    }
                });
        }
    };

    const getChoices = (search) => {
        if (defaultChoices) {
            const filteredChoices = search
                ? defaultChoices.filter(c => c.name.toLowerCase().includes(search.toLowerCase()) || c.licensePlate.toLowerCase().includes(search.toLowerCase()))
                : defaultChoices;
            setChoices(filteredChoices);

            if (!value && selectedIds && itemIndex) {
                const initialValue = defaultChoices.find(d => d.id === selectedIds[itemIndex]);
                if (initialValue) {
                    setValue(initialValue);
                }
            }
        } else {
            const filter = { ...props.filter };
            if (search) {
                filter.search = search;
            }

            dataProvider.getList(reference, {
                sort: { field: 'name', order: 'ASC' },
                filter,
                pagination: { page: 1, perPage: 25 }
            })
                .then(data => {
                    setChoices(data.data);
                    if (!value && selectedIds && itemIndex) {
                        const initialValue = data.data.find(d => d.id === selectedIds[itemIndex]);
                        if (initialValue) {
                            setValue(initialValue);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    notify('Erro ao carregar opções', { type: 'warning' })
                });
        }
    };

    return (
        <FormDataConsumer>
            {({ formData }) => {
                if (isInsideArray) {
                    if (id) {
                        const index = getItemIndex(id);
                        setItemIndex(index);
                    }
                }

                if (!selectedIds && formData && arraySource) {
                    const ids = formData[arraySource].map(p => p && p.id);
                    if (ids) {
                        setSelectedIds(ids);
                    }
                }

                const onValueChange = (event, newValue) => {
                    if (arraySource && itemIndex) {
                        formData[arraySource][itemIndex] = { id: newValue.id };
                        field.onChange(formData[arraySource]);
                    } else {
                        field.onChange(newValue ? (newValue[source] || newValue.id) : null);
                    }
                    setValue(newValue);
                };

                return (
                    <Autocomplete
                        {...props}
                        value={value}
                        onChange={onValueChange}
                        options={choices}
                        getOptionLabel={format || (option => option.name)}
                        noOptionsText={"Buscar"}
                        inputValue={search}
                        onInputChange={onInputChange}
                        style={style}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label={label}
                                error={(isTouched || isSubmitted) && invalid}
                                helperText={
                                    <InputHelperText
                                        touched={isTouched || isSubmitted}
                                        error={error?.message}
                                        helperText={helperText}
                                    />
                                } />
                        }
                    />
                );
            }}
        </FormDataConsumer>
    );
};

export default LazyLoadAutoCompleteInput;