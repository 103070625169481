import ArrowLeftIcon from '@mui/icons-material/ArrowBack'
import ArrowDownIcon from '@mui/icons-material/ArrowDownward'
import ArrowRightIcon from '@mui/icons-material/ArrowForward'
import ArrowUpIcon from '@mui/icons-material/ArrowUpward'
import SaveIcon from '@mui/icons-material/Save'
import { CSSProperties, useEffect, useState } from 'react'
import Permission from '../../components/Permission'
import { DEFAULT_API_ADMIN, PERMISSIONS } from '../../constants'

import { Check, CloudUpload, RemoveCircle } from '@mui/icons-material'
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNotify } from 'react-admin'
import noProductImage from '../../assets/images/no-product-image.jpg'
import { Loader, SimpleText, Title, Wrapper } from '../../components'
import { get, getChainId, moveItemInArray, post, request, to } from '../../lib'

const useStyles = makeStyles({
  secondaryAction: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    right: 1
  },
  divButtons: {
    display: 'flex',
    gap: 5
  },
  iconButton: {
    padding: 8
  },
  icon: {
    width: 22,
    height: 22
  }
});

const MAX_SPOTLIGHT_PRODUCTS = 6

interface IProduct {
  id: string
  imagePath: string
  createdAt: string
  isCash: boolean
  name: string
  description: string
  chainId: string
  productPlaces: any[]
  hide: boolean,
  featuredImagePath: string
}

interface IFeaturedProduct {
  product: IProduct
  order: number
}

interface IFeaturedImage {
  file: File
  id: string
}

const ItemImage = ({ src }) => (
  <img src={src} style={{ maxWidth: 50, maxHeight: 50 }} />
)

const ErrorContainer = ({ children }) => <div>{children}</div>

export default () => {
  const [left, setLeft] = useState<IProduct[]>([])
  const [right, setRight] = useState<IProduct[]>([])
  const [checked, setChecked] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [images, setImages] = useState<IFeaturedImage[]>([]);
  const notify = useNotify();
  const classes = useStyles();

  useEffect(() => {
    fetchProducts()
  }, [])

  const fetchProducts = async () => {
    setLoading(true)
    const URL = `${DEFAULT_API_ADMIN}/chains/${getChainId()}/products`
    const [error, response] = await to<{ data: IProduct[] }>(get(URL))
    if (error) {
      setError(true)
      setLoading(false)
      return
    }

    const products = response.data.filter(({ isCash }) => !isCash)

    const spotlightProducts = await fetchSpotlightProducts()
    setRight(spotlightProducts)

    const availableProducts = products.filter(
      product =>
        !spotlightProducts.find(
          featuredProduct => product.id === featuredProduct.id,
        ),
    )
    setLeft(availableProducts)

    setLoading(false)
  }

  const fetchSpotlightProducts = async (): Promise<IProduct[]> => {
    const URL = `${DEFAULT_API_ADMIN}/chains/${getChainId()}/featured-products`
    const [error, response] = await to<{ data: IFeaturedProduct[] }>(get(URL))

    if (error) {
      console.error('error', error)
      setError(true)
      return []
    }

    const spotilightProducts = response.data
      .sort((a, b) => a.order - b.order)
      .map(sProduct => sProduct.product)

    return spotilightProducts
  }

  const handleToggle = ({ id }) => () => {
    if (checked === id) {
      setChecked(undefined)
    } else {
      setChecked(id)
    }
  }

  const handleMoveToRight = () => {
    const numberOfSpotlightProducts = right.length

    if (numberOfSpotlightProducts < MAX_SPOTLIGHT_PRODUCTS) {
      const selectedProduct = left.find(({ id }) => id === checked)
      if (selectedProduct) {
        setRight(right.concat(selectedProduct))
        setLeft(left.filter(({ id }) => id !== selectedProduct.id))
        setChecked(undefined)
      }
    }
  }

  const handleMoveToLeft = () => {
    const selectedProduct = right.find(product => product.id === checked)
    if (selectedProduct) {
      setLeft(left.concat(selectedProduct))
      setRight(right.filter(({ id }) => selectedProduct.id !== id))
      setChecked(undefined)
    }
  }

  const handleMoveUp = () => {
    const selectedProduct = right.find(product => product.id === checked)
    if (selectedProduct) {
      const newRight = moveItemInArray.moveUp(right, selectedProduct)
      setRight(newRight)
    }
  }

  const handleMoveDown = () => {
    const selectedProduct = right.find(product => product.id === checked)
    if (selectedProduct) {
      const newRight = moveItemInArray.moveDown(right, selectedProduct)
      setRight(newRight)
    }
  }

  const handleAddImage = async (event, id: string) => {
    const file = event.target.files[0];

    if (file.size > (3 * 1024 * 1024)) {
      console.error('Tamanho da imagem é maior que o suportado');
      return notify('Tamanho da imagem é maior que o suportado', { type: 'warning' });
    }

    const index = images.findIndex((image) => image.id === id);

    if (index > -1) {
      const updatedImages = [...images];

      updatedImages[index] = { id, file };

      setImages(updatedImages);
    } else {
      setImages([...images, { id, file }]);
    }

    return notify('Imagem selecionada');
  };

  const removeImage = (id: string) => {
    const index = images.findIndex((image) => image.id === id);

    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  }

  const handleRemoveImage = (id: string) => {
    removeImage(id);
    return notify('Imagem removida');
  }

  const handleUpload = async (id: string) => {
    setLoading(true);
    const URL = `${DEFAULT_API_ADMIN}/chains/${getChainId()}/products/${id}`;

    const file = images.find((image) => image.id === id)?.file;

    const payload = new FormData();
    payload.append('featuredImageFile', file as File);

    const [error] = await to(request(URL, 'PATCH', payload));

    if (error) {
      console.error('error', error);
      return notify('Erro no upload de imagem', { type: 'warning' });
    }

    removeImage(id);
    setLoading(false);
    return notify('Imagem salva', { type: 'success' });
  }

  const handleSaveProducts = async () => {
    setLoading(true)
    const URL = `${DEFAULT_API_ADMIN}/chains/${getChainId()}/featured-products`
    const payload = {
      products: right.map((product, index) => {
        return { id: product.id, order: index++ }
      }),
    }
    const [error] = await to(post(URL, payload))

    if (error) {
      console.error('error', error)
      setError(true)
    }

    setLoading(false)
  }

  const filterByName = ({ target }) => {
    const { value } = target

    const filtered = left.map(product => {
      const hasSubString = product.name
        .toLowerCase()
        .includes(value.toLowerCase())

      if (hasSubString) {
        return {
          ...product,
          hide: false,
        }
      }

      return {
        ...product,
        hide: true,
      }
    })

    setLeft(filtered)
  }

  const renderProductsList = (products: IProduct[], position: string) => {
    const notHideProducts = products.filter(product => !product.hide)
    const missingProducts = MAX_SPOTLIGHT_PRODUCTS - notHideProducts.length
    let spacers = []

    if (missingProducts > 0) {
      spacers = new Array(missingProducts)
        .fill('')
        .map((_, idx) => <div key={idx} style={{ height: 70 }} />) as never[]
    }

    const overflowTextStyle = {
      margin: '15px',
      marginRight: '30px'
    } as CSSProperties

    return (
      <Paper>
        <List dense role="list">
          {notHideProducts.map(product => {
            const { id, name, description, imagePath, featuredImagePath } = product
            const checkedProduct = checked === id
            let image: string;
            if ((position === 'right' && !featuredImagePath) || position === 'left') {
              image = imagePath;
            } else {
              image = featuredImagePath;
            }

            return (
              <ListItem
                key={id}
                role="listitem"
                button
                style={{
                  backgroundColor: checkedProduct ? '#ccc' : undefined,
                }}
                onClick={handleToggle(product)}
              >
                {image ? (
                  <ItemImage src={image} />
                ) : (
                  <ItemImage src={noProductImage} />
                )}
                <ListItemText
                  id={id}
                  style={overflowTextStyle}
                  title={name}
                  primary={name}
                  secondary={description}
                />
                {
                  position === 'right' &&
                  <ListItemSecondaryAction className={classes.secondaryAction}>
                    <label htmlFor={`upload-button-${id}`}>
                      <input
                        accept='image/jpeg, image/png'
                        id={`upload-button-${id}`}
                        type='file'
                        style={{ display: 'none' }}
                        onChange={(event) => handleAddImage(event, id)}
                      />
                      <IconButton component='span' className={classes.iconButton}>
                        <CloudUpload className={classes.icon} />
                        <span style={{ color: 'red', fontSize: 15 }}>*</span>
                      </IconButton>
                    </label>

                    {images.some((image) => image.id === id) &&
                      <div className={classes.divButtons}>
                        <IconButton
                          className={classes.iconButton}
                          onClick={() => handleUpload(id)}
                        >
                          <Check style={{ color: 'green' }} className={classes.icon} />
                        </IconButton>
                        <IconButton
                          className={classes.iconButton}
                          onClick={() => handleRemoveImage(id)}
                        >
                          <RemoveCircle style={{ color: 'red' }} className={classes.icon} />
                        </IconButton>
                      </div>
                    }
                  </ListItemSecondaryAction>
                }
              </ListItem>
            )
          })}
          {spacers}
        </List>
      </Paper>
    )
  }

  const renderMiddleButtons = () => (
    <Grid container direction="column" alignItems="center">
      <Button
        variant="outlined"
        size="small"
        onClick={handleMoveToRight}
        disabled={left.length === 0}
        aria-label="move selected right"
      >
        <ArrowRightIcon />
      </Button>
      <Button
        variant="outlined"
        size="small"
        onClick={handleMoveToLeft}
        disabled={right.length === 0}
        aria-label="move selected left"
      >
        <ArrowLeftIcon />
      </Button>
    </Grid>
  )

  const renderRightButtons = () => (
    <Grid container direction="column" alignItems="center">
      <Button
        variant="outlined"
        size="small"
        onClick={handleMoveUp}
        disabled={right.length === 0}
        aria-label="move all right"
      >
        <ArrowUpIcon />
      </Button>
      <Button
        variant="outlined"
        size="small"
        onClick={handleMoveDown}
        disabled={right.length === 0}
        aria-label="move all left"
      >
        <ArrowDownIcon />
      </Button>
    </Grid>
  )

  const fullWidthDivStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
  } as CSSProperties

  return (
    <Permission permission={PERMISSIONS.UPDATE_FEATURED_PRODUCTS}>
      <Wrapper>
        <div>
          <Title text="Produtos Destaque" />
          <SimpleText text="Selecione no máximo 6 produtos para aparecer em destaque" />
        </div>
        <div style={fullWidthDivStyle}>
          <TextField
            id="search"
            label="Pesquisar produto"
            type="search"
            margin="normal"
            onChange={filterByName}
          />
          <Button
            variant="outlined"
            size="small"
            color="primary"
            disabled={loading}
            onClick={handleSaveProducts}
            aria-label="move selected right"
          >
            {loading ? (
              <>
                <CircularProgress size={20} />
                Salvar
              </>
            ) : (
              <>
                <SaveIcon />
                Salvar
              </>
            )}
          </Button>
        </div>
        <div>
          <p>
            <span style={{ color: 'red' }}>*</span>
            Adicionar Imagem de Destaque. A imagem deve ter o tamanho máximo de 3MB. Os formatos aceitos são .jpeg e .png.
          </p>
        </div>
        {loading ? (
          <Loader />
        ) : !error ? (
          <Grid container spacing={0}>
            <Grid item xs={5}>
              {renderProductsList(left, 'left')}
            </Grid>
            <Grid container xs={7} alignItems="center" style={{ height: 'fit-content' }}>
              <Grid item xs={2}>
                {renderMiddleButtons()}
              </Grid>
              <Grid item xs={8}>
                {renderProductsList(right, 'right')}
              </Grid>
              <Grid item xs={2}>
                {renderRightButtons()}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <ErrorContainer>Não foi possível carregar os dados.</ErrorContainer>
        )}
      </Wrapper>
    </Permission>
  )
}
