import { Resource } from '../index'
import GroupWork from '@mui/icons-material/GroupWork'
import { PERMISSIONS } from '../../constants'
import list from './ChainList'
import create from './ChainCreate'
import edit from './ChainEdit'

export default {
  edit,
  list,
  create,
  name: 'chains',
  permissions: [PERMISSIONS.ADMIN],
  options: { label: 'Rede' },
  icon: GroupWork,
} as Resource;
