import { makeStyles } from "@mui/styles";
import moment from "moment";
import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  Filter,
  FormDataConsumer,
  FunctionField,
  NumberField,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectArrayInput,
  SelectField,
  SelectInput,
  SingleFieldList,
  TextField,
  useListContext,
} from 'react-admin';
import { CustomizableDatagrid, Permission, ReportList } from "../../components";
import { CNPJField, FullAdressField, PhoneField } from "../../components/Fields";
import { PERMISSIONS } from "../../constants";
import { getChainId, getEmployeeType } from '../../lib';
import { EmployeeType, TagType } from "../../models";
import { formatCnpj, formatNumber } from "../../utils";

const useStyles = makeStyles(theme => ({
  groupInput: {
    minWidth: 180,
    '& p': {
      height: 0,
    },
  }
}));

const StationFilter = () => {
  const classes = useStyles();
  return (
    <Filter>
      <SearchInput source="search" alwaysOn />
      {
        Permission({
          permission: PERMISSIONS.ADMIN,
          children: (
            <ReferenceInput source="chainId" reference="chains" sort={{ field: 'name', order: 'ASC' }} perPage={null} alwaysOn>
              <SelectInput label="Rede" resettable optionText="name" />
            </ReferenceInput>
          )
        })
      }
      {
        Permission({
          permission: PERMISSIONS.ADMIN,
          children: (
            <ReferenceArrayInput source="retailerIds" reference="retailers" sort={{ field: 'name', order: 'ASC' }} perPage={null} alwaysOn>
              <SelectArrayInput label="Revenda" optionText="name" />
            </ReferenceArrayInput>
          )
        })
      }
      <SelectInput
        label="Status"
        source="isEnabled"
        choices={[{ label: 'Ativos', value: true }, { label: 'Inativos', value: false }]}
        optionText="label"
        optionValue="value"
        emptyText="Todos"
        alwaysOn
      />
      <FormDataConsumer alwaysOn >
        {({ formData }) => (
          <ReferenceArrayInput className={classes.groupInput} source="groupIds" reference="chains/chainId/station-groups" perPage={9999} sort={{ field: "name", order: "ASC" }}
            filter={{ chainId: formData.chainId }} emptyText="Todos">
            <SelectArrayInput optionText="name" label="Grupo" />
          </ReferenceArrayInput>
        )}
      </FormDataConsumer>
      <FormDataConsumer alwaysOn >
        {({ formData }) => (
          <ReferenceArrayInput className={classes.groupInput} source="tagIds" reference="chains/chainId/tag" perPage={null} sort={{ field: "name", order: "ASC" }}
            filter={{ chainId: formData.chainId, type: TagType.station }} emptyText="Todos">
            <SelectArrayInput optionText="name" label="Etiqueta" />
          </ReferenceArrayInput>
        )}
      </FormDataConsumer>
    </Filter>
  );
};

const FilterTablePrint = () => {
  const { filterValues } = useListContext();

  return (
    <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
      {
        Permission({
          permission: PERMISSIONS.ADMIN,
          children: (
            <ReferenceField source="chainId" reference="chains" emptyText="Todos" textAlign="center" label="Rede" sortable={false} link={false}>
              <TextField source="name" />
            </ReferenceField>
          )
        })
      }
      {
        Permission({
          permission: PERMISSIONS.ADMIN,
          children: (
            <ReferenceArrayField source="retailerIds" reference="retailers" emptyText="Todos" textAlign="center" label="Revenda" sortable={false}>
              <SingleFieldList>
                <ChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>
          )
        })
      }
      <SelectField
        label="Status"
        source="isEnabled"
        choices={[{ label: 'Ativos', value: true }, { label: 'Inativos', value: false }]}
        optionText="label"
        optionValue="value"
        emptyText="Todos"
        alwaysOn
      />
      <ReferenceArrayField source="groupIds" reference="chains/chainId/station-groups" emptyText="Todos" textAlign="center" label="Grupo" sortable={false}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField source="tagIds" reference="chains/chainId/tag" emptyText="Todos" textAlign="center" label="Etiqueta" sortable={false}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
  );
};

const exportStation = async (data, fetchRelatedRecords) => {
  const employeeType = getEmployeeType();
  const relations = [{
    field: 'stationGroupId', resource: 'chains/chainId/station-groups',
  }]

  if (employeeType === EmployeeType.admin) {
    relations.push(
      { field: 'chainId', resource: 'chains' },
      { field: 'retailerId', resource: 'retailers' },
    )
  }

  const fetchData = await fetchRelatedRecords(relations, data);

  const getData = (resource) => {
    return fetchData.find(({ resource: res }) => res === resource)?.results || [];
  }

  const adminExport = (station) => {
    if (employeeType === EmployeeType.admin) {
      const chain = getData('chains').find(({ id }) => id === station.chainId);
      const retailer = getData('retailers').find(({ id }) => id === station.retailerId);
      const options = { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 };
      const { commissioningValue, commissioningType, monthlyPrice } = station;
      return {
        rede: chain?.name || 'Rede não identificada',
        rede_com_codigo: chain ? chain.code + ' - ' + chain.name : '',
        revenda: retailer?.name || 'Revenda não identificada',
        porcentagem_da_revenda: commissioningType === 'percentage' ? commissioningValue ? `${commissioningValue}%` : '0%' : formatNumber(commissioningValue || 0, options),
        valor_do_faturamento: formatNumber(monthlyPrice || 0, options),
      }
    }
    return {}
  }

  const stations = data.map((station) => {
    const stationGroup = getData('chains/chainId/station-groups').find(({ id }) => id === station.stationGroupId);

    return {
      nome: station.name,
      grupo: stationGroup?.name || 'Sem grupo',
      telefone: station.phone,
      cnpj: formatCnpj(station.cnpj),
      endereco_completo: (station.street ? station.street : '') +
        (station.streetNumber ? `, n° ${station.streetNumber}` : '') +
        (station.complement ? `, ${station.complement}` : '') +
        (station.neighbourhood ? ` - ${station.neighbourhood}` : '') +
        (station.city ? `, ${station.city}` : '') +
        (station.state ? ` - ${station.state}` : ''),
      rua: station.street,
      numero_rua: station.streetNumber,
      complemento: station.complement,
      bairro: station.neighbourhood,
      cidade: station.city,
      estado: station.state,
      ...adminExport(station)
    }
  });
  console.log("🚀 ~ stations ~ stations:", data)

  return stations;
};

const ReportStation = (props) => {
  return (
    <Permission permission={PERMISSIONS.STATION_REPORT}>
      <ReportList
        {...props}
        basePath="report-attendant"
        title="Posto"
        resource="chains/chainId/reports/station"
        perPage={25}
        filters={<StationFilter />}
        filterTablePrint={<FilterTablePrint />}
        bulkActionButtons={false}
        sort={{ field: 'name', order: 'ASC' }}
        titleOnPrint="Relatório de Postos"
        customExporter={exportStation}
        isReport={false}
        filterDefaultValues={{
          chainId: getChainId(),
        }}
      >
        <CustomizableDatagrid bulkActionButtons={false} defaultColumns={['name', 'stationGroupId', 'cnpj', 'phone', 'address', 'chainId', 'retailerId']}>
          <TextField source="name" label="Nome" textAlign="center" />
          <ReferenceField source="stationGroupId" reference="chains/chainId/station-groups" label="Grupo" textAlign="center" sortable={false} emptyText="Sem grupo" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <CNPJField source="cnpj" label="CNPJ" textAlign="center" />
          <PhoneField source="phone" label="Telefone" sortable={false} textAlign="center" />
          <FullAdressField source="address" label="Endereço Completo" />
          <TextField source="street" label="Rua" />
          <TextField source="streetNumber" label="Nº da Rua" />
          <TextField source="complement" label="Complemento" />
          <TextField source="neighbourhood" label="Bairro" />
          <TextField source="state" label="Estado" />
          {
            Permission({
              permission: PERMISSIONS.ADMIN,
              children: (
                <ReferenceField
                  label="Rede"
                  source="chainId"
                  reference="chains"
                  textAlign="center"
                  emptyText="Rede não identificada"
                  link={false}
                >
                  <TextField source="name" />
                </ReferenceField>
              )
            })
          }
          {
            Permission({
              permission: PERMISSIONS.ADMIN,
              children: (
                <ReferenceField
                  label="Revenda"
                  source="retailerId"
                  reference="retailers"
                  textAlign="center"
                  emptyText="Revenda não identificada"
                  link={false}
                  sortable={false}
                >
                  <TextField source="name" />
                </ReferenceField>
              )
            })
          }
          {
            Permission({
              permission: PERMISSIONS.ADMIN,
              children: (
                <FunctionField label="Porcentagem da revenda"
                  textAlign="center"
                  render={record => {
                    if (record && record.commissioningType === 'percentage') {
                      return (
                        record.commissioningValue ? `${record.commissioningValue}%` : '0%'
                      );
                    } else {
                      return formatNumber(record?.commissioningValue || 0, { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 });
                    }
                  }}
                />
              )
            })
          }
          {
            Permission({
              permission: PERMISSIONS.ADMIN,
              children: (
                <NumberField
                  source="monthlyPrice"
                  label="Valor do Faturamento"
                  textAlign="center"
                  emptyText='R$ 0,00'
                  options={{
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }}
                />
              )
            })
          }
          {
            Permission({
              permission: PERMISSIONS.ADMIN,
              children: (
                <ArrayField source="concentrators" label="Versão" sortBy="concentrators.version">
                  <SingleFieldList linkType={null}>
                    <ChipField source="version" />
                  </SingleFieldList>
                </ArrayField>
              )
            })
          }
        </CustomizableDatagrid>
      </ReportList>
    </Permission>
  );
};

export default ReportStation;