export class DayScheduledAdvertisement {
    dayOfTheWeek: number | undefined;
    startTime: Date | undefined;
    endTime: Date | undefined;
    isActive: boolean = false;

    static createDayScheduledAdvertisement(value: Partial<DayScheduledAdvertisement>, isActive: boolean) {
        return {
            dayOfTheWeek: value.dayOfTheWeek,
            startTime: value.startTime,
            endTime: value.endTime,
            isActive,
        };
    }

    constructor(init?: Partial<DayScheduledAdvertisement>) {
        Object.assign(this, init);
    }
}