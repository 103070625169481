import { PERMISSIONS } from '../../constants';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import list from './NewsList';
import create from './NewsCreate';
import edit from './NewsEdit';
import show from './NewsShow';
import ComponentWithPermissions from '../ComponentWithPermissions';
import { UserRole } from '../../models';

export default {
  list,
  edit: new ComponentWithPermissions({ component: edit, permissions: [PERMISSIONS.NEWS], userRoles: [UserRole.admin] }),
  create: new ComponentWithPermissions({ component: create, permissions: [PERMISSIONS.NEWS], userRoles: [UserRole.admin] }),
  show,
  name: 'news',
  permissions: [PERMISSIONS.LIST_NEWS],
  options: { label: 'Novidades' },
  icon: NewspaperIcon,
}