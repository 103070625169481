import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useState } from "react";
import {
    Datagrid,
    DateField,
    DateTimeInput,
    FunctionField,
    NumberField,
    ReferenceField,
    TextField,
    useListContext,
} from 'react-admin';
import { ReportList } from '../../components';
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import { DEFAULT_API_ADMIN, JWT_TOKEN, PERMISSIONS } from "../../constants";
import { getChainId } from "../../lib";
import { formatPoints } from "../../utils";

const useStyles = makeStyles(theme => ({
    title: {
        margin: '10px 0 10px 0',
        textAlign: 'center',
        fontWeight: 'bold'
    },
}));

const PointsBalanceList = () => {
    const classes = useStyles();
    const props = useListContext();

    let data = [], summaryData = [];

    if (props.data) {
        const reportInfo = props.data[0];

        if (reportInfo) {
            data = reportInfo.data;
            summaryData = [reportInfo.summaryData];
        }
    }

    return (
        <>
            <Typography className={classes.title}>Total</Typography>
            <Datagrid bulkActionButtons={false} classes={{ rowCell: classes.printCell }} style={{ marginBottom: 60 }} data={summaryData} total={1} hover={false}>
                <NumberField
                    source="customerCount"
                    label="Clientes com saldo"
                    textAlign="center"
                    sortable={false}
                />
                <FunctionField
                    label="Saldo"
                    emptyText="0"
                    textAlign="center"
                    sortable={false}
                    render={record => formatPoints(record.totalBalance)}
                />
                <NumberField
                    source="totalCashback"
                    label="Valor Cashback"
                    emptyText="R$ 0,00"
                    options={{ style: 'currency', currency: 'BRL' }}
                    textAlign="center"
                    sortable={false}
                />
            </Datagrid>

            <Datagrid bulkActionButtons={false} classes={{ rowCell: classes.printCell }} data={data}>
                <ReferenceField source="id" reference="customers" label="Clientes" sortable={false} basePath="customers">
                    <TextField source="name" emptyText="Não identificado" />
                </ReferenceField>
                <NumberField
                    source="balance"
                    label="Saldo"
                    textAlign="left"
                />
                <NumberField
                    source="cashback"
                    label="Valor Cashback"
                    emptyText="R$ 0,00"
                    options={{ style: 'currency', currency: 'BRL' }}
                    textAlign="center"
                />
            </Datagrid>
        </>
    );
};
const FilterTablePrint = () => {
    const { filterValues } = useListContext();

    return (
        <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
            <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
        </Datagrid>
    );
}

const ReportPointsBalance = (props) => {
    const [filter, setFilter] = useState(
        {
            to: moment().endOf('day').toISOString()
        }
    );
    const pointsBalanceExporter = async () => {
        try {
            const res = await fetch(DEFAULT_API_ADMIN + `/chains/${getChainId()}/reports/points-balance/export?` + new URLSearchParams({
                filter: `{"to":"${moment(filter.to).toISOString()}"}`,
                chainId: `"${getChainId()}"`
            }),
                {
                    method: 'GET',
                    headers: {
                        Authorization: localStorage.getItem(JWT_TOKEN), 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    }
                });
            const arrayBuffer = await res.arrayBuffer();
            var blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.href = url;
            link.download = 'Saldo_de_Pontos.xlsx';
            link.click();
        } catch (err) {
            console.log(err);
        }
    };

    const listFilters = [
        <DateTimeInput
            style={{ minWidth: 240 }}
            source="to"
            label="Até"
            onChange={(data) => setFilter(prevState => ({ ...prevState, to: data.target.value }))}
            alwaysOn
        />
    ];

    return (
        <Permission permission={PERMISSIONS.POINTS_BALANCE_REPORT}>
            <ReportList
                {...props}
                basePath="report-points-balance"
                title="Saldo de Pontos"
                resource="chains/chainId/reports/points-balance"
                filters={listFilters}
                filter={getChainId() ? { chainId: getChainId() } : null}
                perPage={25}
                filterDefaultValues={{
                    to: moment().endOf("day").toISOString(),
                }}
                sort={{ field: 'balance', order: 'DESC' }}
                pagination={<ListPagination />}
                bulkActionButtons={false}
                titleOnPrint="Relatório de Saldo de Pontos"
                filterTablePrint={<FilterTablePrint />}
                landscape
                showPagination
                exportFile={pointsBalanceExporter}
                maxResults={25}
            >
                <PointsBalanceList />
            </ReportList>
        </Permission>
    )
};

export default ReportPointsBalance;