import { PERMISSIONS } from '../../constants'
import list from './ListUnifiedSend'
import edit from './EditUnifiedSend'
import create from './CreateUnifiedSend'

export default {
  list,
  create,
  name: 'chains/chainId/unified-send',
  permissions: [PERMISSIONS.UNIFIED_SEND]
}
