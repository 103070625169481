import { CardMedia, Typography } from "@mui/material";
import React from "react";
import { EditButton, Show, SimpleShowLayout, TopToolbar, useShowContext } from "react-admin";
import { Permission } from "../../components";
import { PERMISSIONS } from "../../constants";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'start',
    fontWeight: 'bold'
  },
  iframe: {
    height: '80vh',
  }
})

const NewsShow = () => {

  const ShowLayout = () => {
    const classes = useStyles();
    const { record } = useShowContext();

    if (!record) { return null; }

    return (
      <SimpleShowLayout>
        <Typography variant="h4" className={classes.title}>{record.title}</Typography>
        <CardMedia className={classes.iframe} component="iframe" src={record.link} />
      </SimpleShowLayout>
    );
  }

  const NewsActions = () => {
    return (
      <TopToolbar>
        <Permission permission={PERMISSIONS.NEWS}>
          <EditButton />
        </Permission>
      </TopToolbar>
    );
  }

  return (
    <Show title="Novidade" actions={<NewsActions />}>
      <ShowLayout />
    </Show>
  );
}

export default NewsShow;
