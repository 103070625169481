import React from 'react';
import { Edit, required, SimpleForm, TextInput } from 'react-admin';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { CNPJInput } from '../../components/Inputs';

const CompanyWithoutPointEdit = (props) => {
    return (
        <Permission permission={PERMISSIONS.MANAGE_COMPANY_WITHOUT_POINT}>
            <Edit title='Editar empresa sem pontuação' {...props}>
                <SimpleForm>
                    <TextInput disabled label="ID" source="id" />
                    <TextInput source="name" label="Nome" />
                    <CNPJInput source="cnpj" label="CNPJ" validate={required()} />
                </SimpleForm>
            </Edit>
        </Permission>
    );
};

export default CompanyWithoutPointEdit;
