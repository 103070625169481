import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

import { PERMISSIONS } from '../../constants';
import Permission from '../../components/Permission';

export default props => {
  return (
    <Permission permission={PERMISSIONS.ADMIN}>
      <Create {...props} title="Cadastrar novo usuário">
        <SimpleForm>
          <TextInput source="name" label="Nome" validate={required()} InputProps={{ autoComplete: 'off' }} />
        </SimpleForm>
      </Create>
    </Permission>
  )
};