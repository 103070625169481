import UserIcon from '@mui/icons-material/People';
import { PERMISSIONS } from '../../constants';
import list from './AttendantList';
import create from './CreateAttendant';
import edit from './EditAttendant';
import show from './AttendantShow';

export default {
  show,
  list,
  create,
  edit,
  name: 'attendants',
  permissions: [PERMISSIONS.CREATE_ATTENDANT],
  options: { label: 'Frentistas' },
  icon: UserIcon,
}
