import { makeStyles } from '@mui/styles';
import moment from 'moment';
import {
    Datagrid,
    DateField,
    useListContext,
} from 'react-admin';
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { ReportList, RotatedAxisTick } from '../../components';
import { BetweenDatesInput } from '../../components/BetweenDatesInput';
import { GenericDatagrid, GenericDatagridField } from '../../components/GenericDatagrid';
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import { PERMISSIONS } from "../../constants";

const colors = ['#608DE0', '#E0348A', '#E0AD3F', '#34E034', '#17E0DC', '#9934E0', '#E0793F', '#E05C3F', '#4612E0', '#16E097', '#E0D134', '#E016CE'];

const useStyles = makeStyles(theme => ({
    title: {
        margin: '10px 0 10px 0',
        textAlign: 'center',
        fontWeight: 'bold'
    },
}));

const SocketError = () => {
    const props = useListContext();

    let data = [], chartData = [];

    if (props.data) {
        const reportInfo = props.data[0];

        if (reportInfo) {
            data = reportInfo.data.filter(e => e.count > 0).sort((a, b) => b.count - a.count);
            chartData = reportInfo.summaryData;
        }
    }
    return (
        <>
            <GenericDatagrid
                style={{ margin: '0 0 40px 0' }}
                textAlign='center'
                data={data}
            >
                <GenericDatagridField
                    label='Erro'
                    source='message'
                />
                <GenericDatagridField
                    label='Total de Ocorrências'
                    source='count'
                />
            </GenericDatagrid>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <LineChart width={790} height={600} data={chartData}>
                    <XAxis dataKey="date" tick={<RotatedAxisTick fontSize={16} />} height={100} />
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis domain={['auto', 'auto']} type='number' />
                    <Tooltip />
                    {data.map((error, i) =>
                        chartData.some(point => point[error.message]) &&
                        <Line
                            type='monotone'
                            dataKey={error.message}
                            stroke={i <= 11 ? colors[i] : colors[((i + 1) % 12) - 1]}
                            name={error.message}
                            activeDot={{ r: 5 }}
                            key={i}
                        />
                    )}
                </LineChart>
            </div>
        </>
    );
};

const listFilters = [
    <BetweenDatesInput alwaysOn />,
];

const FilterTablePrint = () => {
    const classes = useStyles();
    const { filterValues } = useListContext();
    return (
        <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
            <DateField source="from" textAlign="center" label="De" sortable={false} />
            <DateField source="to" textAlign="center" label="Até" sortable={false} />
            <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
        </Datagrid>
    );
}

const ReportSocketError = (props) => {
    return (
        <Permission permission={PERMISSIONS.ADMIN}>
            <ReportList
                {...props}
                basePath="report-socket-error"
                title="Erros do Socket"
                resource="reports/socket-error"
                filters={listFilters}
                sort={{ field: 'created_at', order: 'DESC' }}
                filterDefaultValues={{ from: moment().startOf('day').subtract(1, "month").toISOString(), to: moment().endOf('day').toISOString() }}
                pagination={<ListPagination />}
                bulkActionButtons={false}
                exporter={false}
                titleOnPrint="Relatório de erros do Socket"
                filterTablePrint={<FilterTablePrint />}
                landscape
            >
                <SocketError />
            </ReportList>
        </Permission >
    );
};

export default ReportSocketError;