import { PeriodPointType } from "./periodPointType";

interface BaseLimit extends PointRule {
  days: number | undefined;
  period: PeriodPointType | undefined;
}

interface PointLimit extends BaseLimit {
  amountPoints: number | undefined;
}

interface LimitNumberFillin extends BaseLimit {
  amountFillins: number | undefined;
}

interface LimitFillinHours extends PointRule {
  fuelId: string | undefined;
  interval: string | undefined;
}

export class PointRule {
  id: string | undefined;
  stationIds: Array<string> = [];
  identify: string | undefined;
  deletedByEmployeeId: string | undefined;

  static createPointLimit(value: Partial<PointLimit>) {
    const limit: Partial<PointLimit> = {
      period: value.period,
      days: value.days,
      amountPoints: value.amountPoints,
      stationIds: value.stationIds,
    }
    return limit;
  }

  static createLimitNumberFillin(value: Partial<LimitNumberFillin>) {
    const limitNumberFillin: Partial<LimitNumberFillin> = {
      period: value.period,
      days: value.days,
      amountFillins: value.amountFillins,
      stationIds: value.stationIds,
    }
    return limitNumberFillin;
  }

  static createLimitInterval(value: Partial<LimitFillinHours>) {
    const limitInterval: Partial<LimitFillinHours> = {
      interval: value.interval,
      fuelId: value.fuelId,
      stationIds: value.stationIds,
    }
    return limitInterval;
  }

  constructor(init?: Partial<PointRule>) {
    Object.assign(this, init);
  }
}