import { Typography } from '@mui/material';
import { ChipField, CreateButton, Datagrid, DateField, Filter, FormDataConsumer, FunctionField, List, NumberField, ReferenceArrayField, ReferenceArrayInput, ReferenceInput, SelectArrayInput, SelectInput, SingleFieldList, TextField, useRecordContext } from 'react-admin';
import Permission from '../../components/Permission';
import { PERMISSIONS, frequencyChoices } from '../../constants';
import { getChainId } from '../../lib';

const StationField = (props: { label: string }) => {
    const record = useRecordContext();
    if (!record) { return null; }

    return (
        <>
            <ReferenceArrayField record={{ ...record, stationIds: record.stationIds.slice(0, 4) }} source="stationIds" reference="stations" label="">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            {
                record.stationIds.length > 4 &&
                <Typography variant="subtitle2" sx={{ paddingTop: 1, paddingLeft: 1 }}>mais {record.stationIds.length - 4} posto{record.stationIds.length - 4 > 1 ? 's' : ''}</Typography>
            }
        </>
    );
};

const FuelField = (props: { label: string }) => {
    const record = useRecordContext();
    if (!record) { return null; }

    return (
        <>
            <ReferenceArrayField record={{ ...record, fuelIds: record.fuelIds.slice(0, 4) }} source="fuelIds" reference="fuels" label="">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            {
                record.fuelIds.length > 4 &&
                <Typography variant="subtitle2" sx={{ paddingTop: 1, paddingLeft: 1 }}>mais {record.fuelIds.length - 4} combustíveis{record.fuelIds.length - 4 > 1 ? 's' : ''}</Typography>
            }
        </>
    );
};

const ListFilters = () => (
    <Filter>
        <SelectInput
            label="Status"
            source="isEnabled"
            choices={[{ label: 'Ativos', value: true }, { label: 'Inativos', value: false }]}
            optionText="label"
            optionValue="value"
            emptyText="Todos"
            alwaysOn
        />
        {
            Permission({
                permission: PERMISSIONS.ADMIN, children:
                    <ReferenceInput
                        reference="chains"
                        source="chainId"
                        style={{ minWidth: 220 }}
                        sort={{ field: 'name', order: 'ASC' }}
                        perPage={9999}
                        alwaysOn
                    >
                        <SelectInput
                            label="Rede"
                            emptyText="Todos"
                            optionText="name" />
                    </ReferenceInput>
            })
        }
        <FormDataConsumer alwaysOn>
            {({ formData }) => (
                <ReferenceArrayInput
                    reference="stations"
                    source="placeIds"
                    sort={{ field: 'name', order: 'ASC' }}
                    perPage={9999}
                    style={{ minWidth: 220, marginBottom: -16 }}
                    filter={formData.chainId ? { chainId: formData.chainId } : {}}
                >
                    <SelectArrayInput label="Postos" optionText="name" />
                </ReferenceArrayInput>
            )}
        </FormDataConsumer>
        <ReferenceArrayInput
            reference="fuels"
            source="fuelIds"
            style={{ minWidth: 220 }}
            sort={{ field: "name", order: "ASC" }}
            alwaysOn
        >
            <SelectArrayInput label="Combustíveis" optionText="name" />
        </ReferenceArrayInput>
    </Filter>
);

const AdvertisementList = (props) => {

    const chainId = getChainId();

    const filterDefaultValues: any = { isEnabled: true };
    if (chainId) {
        filterDefaultValues.chainId = chainId;
    }

    return (
        <Permission permission={PERMISSIONS.ADVERTISEMENT}>
            <List
                {...props}
                title="Anúncios"
                sort={{ field: 'createdAt', order: 'DESC' }}
                filterDefaultValues={filterDefaultValues}
                filters={<ListFilters />}
                exporter={false}
                actions={<CreateButton />}
            >
                <Datagrid bulkActionButtons={false} rowClick="show">
                    <TextField source="name" label="Nome" />
                    <DateField source="startAt" label="Data de início" showTime textAlign="center" emptyText="--" />
                    <DateField source="endAt" label="Data de fim" showTime textAlign="center" emptyText="--" />
                    <NumberField source="priority" label="Prioridade" textAlign="center" emptyText="--" />
                    <FunctionField
                        render={record => record && record.frequency ? frequencyChoices.find(dt => dt.id === record.frequency)?.name : '--'}
                        label="Frequência"
                        textAlign="center"
                    />
                    <StationField label="Postos" />
                    <FuelField label="Combustíveis" />
                    <FunctionField
                        render={record => record && record.isEnabled ? "Ativo" : "Inativo"}
                        label="Status"
                        textAlign="center"
                    />
                </Datagrid>
            </List>
        </Permission>
    );
};

export default AdvertisementList;
