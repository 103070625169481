import * as React from 'react';
import { createElement } from 'react';
import { Card, Box, Typography, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { theme } from '../../theme';

export const CardWithIcon = (props) => {
    const { icon, title, subtitle, to = false, children } = props;

    const BoxItem = () => (
        <Box
            sx={{
                overflow: 'inherit',
                padding: '16px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& .icon': {
                    color: () => theme.palette.primary.main,
                },
            }}
        >
            <Box width="3em" className="icon">
                {createElement(icon, { fontSize: 'large' })}
            </Box>
            <Box textAlign="right">
                <Typography color="textSecondary">{title}</Typography>
                <Typography variant="h5" component="h2">
                    {subtitle || ' '}
                </Typography>
            </Box>
        </Box>
    );

    return (
        <Card
            variant="outlined"
            sx={{
                borderRadius: 3,
                minHeight: 52,
                display: 'flex',
                flexDirection: 'column',
                flex: '1',
                '& a': {
                    textDecoration: 'none',
                    color: 'inherit',
                },
            }}
        >
            {
                to ?
                    <Link to={false}>
                        <BoxItem />
                    </Link>
                    :
                    <BoxItem />
            }

            {children && <Divider />}
            {children}
        </Card>
    );
};