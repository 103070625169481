import BlockIcon from '@mui/icons-material/Block';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import {
  Button,
  DateInput,
  DeleteButton,
  Edit,
  FormTab,
  Identifier,
  ReferenceArrayInput,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  ShowButton,
  TabbedForm,
  TextInput,
  Toolbar,
  TopToolbar,
  email,
  minLength,
  useDataProvider,
  useEditContext,
  useNotify,
  useRecordContext,
  useRefresh
} from 'react-admin';
import { AddCoupon, BalanceButton } from '../../components';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { TagType } from '../../models';
import { dateInputFormatter, validateFurtherDate } from '../../utils';

const validatePassword = [minLength(6)]

const EditActions = () => (
  <TopToolbar>
    <ShowButton />
  </TopToolbar>
);

const editStyles = makeStyles({
  Toolbar: {
    justifyContent: 'space-between'
  },
  ToolbarButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '300px',
  }
});

const CustomToolbar = () => {
  const classes = editStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const record = useRecordContext();
  const { resource } = useEditContext();

  return (
    <Toolbar className={classes.Toolbar}>
      <SaveButton />
      <div
        className={classes.ToolbarButtonWrapper}
        style={{ justifyContent: 'flex-end' }}
      >
        <Permission permission={PERMISSIONS.BLOCK_CUSTOMER}>
          <Button
            startIcon={record && record.isBlocked ? <LockOpenIcon /> : <BlockIcon />}
            label={record && record.isBlocked ? 'Desbloquear' : 'Bloquear'}
            sx={{ marginRight: 2 }}
            onClick={() => {
              dataProvider.update(resource, { id: record.id, data: { isBlocked: !record.isBlocked }, previousData: { ...record } })
                .then(() => {
                  notify(`Cliente ${record && record.isBlocked ? 'ativado' : 'desativado'} com sucesso`)
                }).catch(() => {
                  notify(`Erro ao ${record && record.isBlocked ? 'ativar' : 'desativar'} cliente`, { type: 'warning' })
                }).finally(() => {
                  refresh()
                })
            }}
          />
        </Permission>
        <DeleteButton />
      </div>
    </Toolbar>
  )
}

export default props => {
  const [displayBalanceModal, setDisplayBalanceModal] = useState(false);
  const [displayCouponModal, setDisplayCouponModal] = useState(false);
  const dataProvider = useDataProvider();
  const [customerGroups, setCustomerGroups] = useState<any[]>([]);

  useEffect(() => {
    dataProvider.getList('chains/chainId/customer-groups', {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'name', order: 'ASC' },
      filter: {}
    }).then((response) => {
      const groups: Array<{ id: Identifier | null; name: string }> = [{ id: null, name: 'Sem grupo' }];
      response && response.data && response.data.forEach(group => {
        groups.push({
          id: group.id,
          name: group.name
        });
      });
      setCustomerGroups(groups);
    });
  }, []);

  return (
    <Permission permission={PERMISSIONS.UPDATE_CUSTOMER}>
      <Edit {...props} title="Editar cliente" actions={<EditActions />}>
        <TabbedForm toolbar={<CustomToolbar {...props} />}>
          <FormTab label="Identificação">
            <TextInput source="name" label="Nome" InputProps={{ autoComplete: 'off' }} />
            <TextInput source="email" label="Email" InputProps={{ autoComplete: 'off' }} validate={[email()]} />
            <TextInput
              InputProps={{ autoComplete: 'off' }}
              source="cellphone"
              label="Telefone"
              validate={[minLength(11)]}
              format={(value) => {
                if (!value || value === "") {
                  return "";
                }
                const ddd = value.substr(0, 2)
                const firstStep = value.substr(2, 5)
                const secondStep = value.substr(7, 4)
                let phoneString = `(${ddd}`;

                if (firstStep) {
                  phoneString += `) ${firstStep}`;
                }
                if (secondStep) {
                  phoneString += `-${secondStep}`;
                }
                return phoneString;
              }}
              parse={(value) => {
                if (!value || value === '') {
                  return '';
                }
                const onlyNumbers = value.replace(/[^\d]/g, '').substr(0, 11);
                return onlyNumbers;
              }}
            />
            <DateInput
              source="birthDate"
              label="Data de Nascimento"
              format={dateInputFormatter}
              validate={validateFurtherDate()}
            />
            <SelectInput
              source="gender"
              label="Sexo"
              InputProps={{ autoComplete: 'off' }}
              choices={[
                { id: 'M', name: 'Masculino' },
                { id: 'F', name: 'Feminino' },
                { id: 'X', name: 'Outro' },
              ]}
            />
            <TextInput
              source="password"
              label="Alterar Senha"
              type="password"
              InputProps={{ autoComplete: 'new-password' }}
              validate={validatePassword}
            />
            <SelectInput label='Grupo' source='groupId' choices={customerGroups} />
            <ReferenceArrayInput
              style={{ minWidth: 260 }}
              sort={{ field: 'name', order: 'ASC' }}
              filter={{ type: TagType.client }}
              perPage={null}
              source="tagIds"
              reference="chains/chainId/tag"
            >
              <SelectArrayInput
                label="Etiquetas"
                optionText="name" />
            </ReferenceArrayInput>
            <Permission permission={PERMISSIONS.ADD_POINTS}>
              <Button
                variant="outlined"
                label="Adicionar Pontos"
                onClick={() => setDisplayBalanceModal(true)}
              />
            </Permission>
            <Permission permission={PERMISSIONS.CREATE_COUPON}>
              <Button
                sx={{ marginTop: 2 }}
                variant="outlined"
                label="Adicionar Cupom"
                onClick={() => setDisplayCouponModal(true)}
              />
            </Permission>
            {displayCouponModal && <Permission permission={PERMISSIONS.CREATE_COUPON}>
              <AddCoupon onClose={() => setDisplayCouponModal(false)} />
            </Permission>}
            {displayBalanceModal && <Permission permission={PERMISSIONS.ADD_POINTS}>
              <BalanceButton onClose={() => setDisplayBalanceModal(false)} />
            </Permission>}
          </FormTab>

          <FormTab label="Endereço">
            <TextInput source="street" label="Rua/Avenida/Logradouro" style={{ width: 400 }} InputProps={{ autoComplete: 'off' }} />
            <TextInput source="streetNumber" label="Número" InputProps={{ autoComplete: 'off' }} />
            <TextInput source="complement" label="Complemento" InputProps={{ autoComplete: 'off' }} />
            <TextInput source="neighbourhood" label="Bairro" InputProps={{ autoComplete: 'off' }} />
            <TextInput source="city" label="Cidade" InputProps={{ autoComplete: 'off' }} />
            <TextInput source="state" label="Estado" InputProps={{ autoComplete: 'off' }} />
            <TextInput source="cep" label="CEP"
              InputProps={{ autoComplete: 'off' }}
              format={(value) => {
                if (!value || value === "") {
                  return "";
                }
                const firstStep = value.substr(0, 5)
                const secondStep = value.substr(5, 3)
                let phoneString = '';

                if (firstStep) {
                  phoneString += `${firstStep}`;
                }
                if (secondStep) {
                  phoneString += `-${secondStep}`;
                }
                return phoneString;
              }}
              parse={(value) => {
                if (!value || value === '') {
                  return '';
                }
                const onlyNumbers = value.replace(/[^\d]/g, '').substr(0, 8);
                return onlyNumbers;
              }}
            />
          </FormTab>
        </TabbedForm>
      </Edit>
    </Permission>
  )
}
