import React from 'react';
import {
    List,
    Filter,
    Datagrid,
    TextField,
    SearchInput,
    ReferenceField,
    DateField
} from 'react-admin'
import ListPagination from '../../components/ListPagination';
import { Wrapper } from '../../components';
import Permission from '../../components/Permission';
import { PERMISSIONS, DATE_FORMATER } from '../../constants';
import RequiredChain from '../../components/RequiredChain';

const GroupFilter: React.FC = props =>
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
    </Filter>


const GroupRulesList: React.FC = (props) =>
    <Permission permission={PERMISSIONS.RULES_BY_GROUP}>
        <RequiredChain>
            <Wrapper>
                <List
                    {...props}
                    perPage={25}
                    pagination={<ListPagination />}
                    exporter={false}
                    title="Regras para grupos de clientes"
                    filters={<GroupFilter />}
                >
                    <Datagrid bulkActionButtons={false} rowClick="edit">
                        <ReferenceField source="groupId" reference="chains/chainId/customer-groups" label="Grupo">
                            <TextField source="name" />
                        </ReferenceField>
                        <DateField source="createdAt" label="Data de criação" options={DATE_FORMATER} />
                    </Datagrid>
                </List>
            </Wrapper>
        </RequiredChain>
    </Permission>

export default GroupRulesList;
