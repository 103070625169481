import { Box, Checkbox, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { Children, useCallback, useState } from 'react';
import { Create, Datagrid, DatagridHeaderCell, DateField, FunctionField, List, NumberField, ReferenceField, TextField, useListContext, useRecordContext, useResourceContext } from 'react-admin';
import ListPagination from '../../components/ListPagination';
import { CreateBillingBulkAction, GroupCoupon, Permission } from '../../components';
import { BetweenDatesInput } from '../../components/BetweenDatesInput';
import { PERMISSIONS } from '../../constants';
import { formatPoints } from '../../utils';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  list: {
    //@ts-ignore
    [theme.breakpoints.down('xl')]: {
      '& .RaList-main': {
        maxWidth: '80vW',
        overflowX: 'auto'
      }
    }
  }
}));

const CheckboxRow = ({ isCheck, sortCheckedItems, selectedIds }) => {
  const record = useRecordContext();

  const onChangeHandler = (e) => {
    e.stopPropagation();

    sortCheckedItems(e.target.checked, record);
  };

  const activeCheckBox = useCallback(() => {
    return !!(selectedIds.includes(record.id));
  }, [selectedIds]);

  return (
    <Checkbox
      checked={activeCheckBox()}
      onChange={onChangeHandler}
      onClick={(e) => {
        e.stopPropagation();
      }}
    />
  );
}

const TopListActions = ({ selectedIds, totalValue, coupons, ...props }) => {

  return (
    <CreateBillingBulkAction {...props} selectedIds={selectedIds} totalValue={totalValue} coupons={coupons} />
  );
}

const DataGridHeader = ({ children, selectedIds, handleSelectAll }) => {
  const resource = useResourceContext();
  const { sort, setSort, data } = useListContext();

  const updateSortCallback = useCallback(
    event => {
      event.stopPropagation();
      const newField = event.currentTarget.dataset.field;
      const newOrder =
        sort.field === newField
          ? sort.order === 'ASC'
            ? 'DESC'
            : 'ASC'
          : event.currentTarget.dataset.order;

      setSort({ field: newField, order: newOrder });
    },
    [sort.field, sort.order, setSort]
  );

  const activeCheckBoxHeader = useCallback(() => {
    if (selectedIds.length === 0) { return false; }

    return !data.some(coupon => !selectedIds.includes(coupon.id));
  }, [selectedIds, data])

  return (
    <TableHead>
      <TableRow>
        {Children.map(children, (field, index) => {
          if (field.props.isCheck) {
            return (
              <TableCell key={field.props.label}>
                <Checkbox
                  checked={activeCheckBoxHeader()}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelectAll(e.target.checked);
                  }}
                />
              </TableCell>
            );
          }
          return (
            <DatagridHeaderCell
              sort={sort}
              field={field}
              isSorting={
                sort.field ===
                (field.props.sortBy ||
                  field.props.source)
              }
              sx={{ fontSize: 12 }}
              key={field.props.source || index}
              resource={resource}
              updateSort={updateSortCallback}
            />
          );
        })}
      </TableRow>
    </TableHead>
  );
}

const CouponsDatagrid = (props) => {
  const infos = useListContext();
  const classes = useStyles();

  const handleSelectAll = (checked) => {
    const ids = infos.data.map(({ id }) => id);
    if (checked) {
      const filterCoupons = infos.data.filter((coupon) => !props.selectedIds.includes(coupon.id))
      props.setSelectedIds((prev) => Array.from(new Set(prev.concat(ids))));
      props.setTotalValue(prev => prev += infos.data.reduce((acc, curr) => {
        if (props.selectedIds.includes(curr.id)) {
          return acc;
        } else {
          return acc += curr.partnerValue;
        }
      }, 0)
      );
      props.setCoupons(prev => [...prev, ...filterCoupons]);
    } else {
      props.setSelectedIds((prev) => [...prev.filter((id) => !ids.includes(id))]);
      props.setCoupons((prev) => prev.filter((coupon) => !infos.data.some((data) => data.id === coupon.id)));
      props.setTotalValue(prev => prev -= infos.data.reduce((acc, curr) => {
        if (!props.selectedIds.includes(curr.id)) {
          return acc;
        } else {
          return acc += curr.partnerValue;
        }
      }, 0)
      );
    }
  }

  return (
    <>
      <Typography className={classes.title}>Cupons Resgatados</Typography>
      <Datagrid
        {...props}
        rowClick={false}
        bulkActionButtons={false}
        data={infos.data}
        header={<DataGridHeader selectedIds={props.selectedIds} setSelectedIds={props.setSelectedIds} handleSelectAll={handleSelectAll} />}
      >
        <CheckboxRow isCheck sortCheckedItems={props.sortCheckedItems} selectedIds={props.selectedIds} />
        <ReferenceField source="customerId" reference="customers" label="Cliente" link="show" sortable={false} textAlign="center">
          <TextField source="name" sx={{ fontSize: 12 }} />
        </ReferenceField>
        <FunctionField label="Produto" textAlign="center" render={(record) => record.isCashback ? 'Cashback' : record.title || record.product?.name} sx={{ fontSize: 12 }} />
        <TextField source="barcode" label="Cupom" sx={{ fontSize: 12 }} />
        <DateField source="redeemedAt" label="Data do resgate" textAlign="center" showTime sx={{ fontSize: 12 }} />
        <FunctionField
          source="value"
          label="Pontos"
          textAlign="center"
          render={record => formatPoints(+record?.value)}
          sx={{ fontSize: 12 }}
        />
        <NumberField
          source="partnerValue"
          label="Valor"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sx={{ fontSize: 12 }}
        />
        <ReferenceField
          source="redemptionEmployeeId"
          reference="employees"
          label="Funcionário"
          textAlign="center"
          link={false}
        >
          <TextField source="name" sx={{ fontSize: 12 }} />
        </ReferenceField>
      </Datagrid>
    </>
  );
}

const ListCoupons = ({ sortCheckedItems, totalValue, setTotalValue, coupons, setCoupons, selectedIds, setSelectedIds, ...props }) => {
  const classes = useStyles();
  const listFilters = [
    <BetweenDatesInput style={{ paddingLeft: 20 }} alwaysOn />
  ];

  return (
    <List
      basePath="coupons"
      resource="coupons"
      exporter={false}
      actions={false}
      syncWithLocation={false}
      perPage={25}
      pagination={<ListPagination />}
      title=" "
      sort={{ field: 'redeemedAt', order: "DESC" }}
      filters={listFilters}
      filterDefaultValues={{
        redeemed: true,
        dateField: "redeemed_at",
        from: moment().startOf("day").subtract(1, "month").toISOString(),
        to: moment().endOf("day").toISOString(),
        billingId: false,
      }}
      className={classes.list}
    >
      <>
        <TopListActions {...props}
          selectedIds={selectedIds}
          totalValue={totalValue}
          coupons={coupons}
          setSelectedIds={setSelectedIds}
          setTotalValue={setTotalValue}
          setCoupons={setCoupons}
        />
        <CouponsDatagrid
          sortCheckedItems={sortCheckedItems}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          setTotalValue={setTotalValue}
          setCoupons={setCoupons}
        />
      </>
    </List>
  );
}

export default props => {
  const [selectedIds, setSelectedIds] = useState([] as string[]);
  const [coupons, setCoupons] = useState<Array<{ [key: string]: any }>>([]);
  const [totalValue, setTotalValue] = useState(0);

  const sortCheckedItems = (checked, record) => {
    if (checked) {
      setTotalValue((total) => total + record.partnerValue);
      setSelectedIds(prev => [...prev, record.id]);
      setCoupons((prev) => ([...prev, record]));
    } else {
      setTotalValue((total) => total - record.partnerValue);
      setSelectedIds((prev) => (prev || []).filter((itemId) => itemId != record.id));
      setCoupons((prev) => (prev || []).filter(({ id }) => id != record.id));
    }
  }

  return (
    <Permission permission={PERMISSIONS.BILLING}>
      <Create {...props} title="Criar fatura" aside={<GroupCoupon coupons={coupons} totalValue={totalValue} />}>
        <ListCoupons
          {...props}
          sortCheckedItems={sortCheckedItems}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          coupons={coupons}
          setCoupons={setCoupons}
          totalValue={totalValue}
          setTotalValue={setTotalValue}
        />
      </Create>
    </Permission>
  );
}