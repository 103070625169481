import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { makeStyles } from "@mui/styles";
import moment from 'moment';
import { useState } from "react";
import {
  ChipField,
  Datagrid,
  DateField,
  Filter,
  FormDataConsumer,
  FunctionField,
  NumberField,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SingleFieldList,
  TextField,
  useListContext,
  usePermissions
} from 'react-admin';
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { CheckChainUsesModule, ModuleKey, ReportList, RotatedAxisTick } from '../../components';
import { BetweenDatesInput } from '../../components/BetweenDatesInput';
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import { PERMISSIONS } from "../../constants";
import { getAttendantId, getChainId, getEmployeeType, getPlaceId, getUserId } from "../../lib";
import { EmployeeType, TagType } from "../../models";
import { formatNumber } from '../../utils';

const colors = ['#608DE0', '#E0348A', '#E0AD3F', '#34E034', '#17E0DC', '#9934E0', '#E0793F', '#E05C3F', '#4612E0', '#16E097', '#E0D134', '#E016CE'];

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
}));

const sortData = (a, b) => {
  return b.totalValue - a.totalValue;
}

const FillinByFuel = () => {
  const [chartType, setChartType] = useState('totalValue');
  const props = useListContext();
  const classes = useStyles();

  let chartData = [];
  let fuelData = [];

  if (props.data) {
    const fillinInfo = props.data[0];

    if (fillinInfo) {
      fuelData = fillinInfo.data.sort((a, b) => b.totalAmount - a.totalAmount);
      chartData = fillinInfo.summaryData;
    }
  }

  return (
    <>
      <Datagrid bulkActionButtons={false} style={{ marginTop: 40 }} data={fuelData}>
        <TextField
          source="name"
          textAlign="center"
          label="Combustível"
          sortable={false}
        />
        <NumberField
          source="totalValue"
          label="Total em Dinheiro"
          textAlign="center"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
        <NumberField
          source="totalAmount"
          label="Total em Litros"
          textAlign="center"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
          sortable={false}
        />
        <TextField
          source="totalFillins"
          textAlign="center"
          label="Total de abastecimentos"
          sortable={false}
        />
        <FunctionField
          render={record => `${formatNumber(record.totalValue / record.totalFillins || 0, { style: 'currency', currency: 'BRL' })}`}
          label="Ticket Médio"
          textAlign="center"
        />
        <FunctionField
          render={record => `${record.totalAmount ? (record.totalAmount / record.totalFillins).toFixed(2) : 0}`}
          label="Volume Médio"
          textAlign="center"
        />
      </Datagrid>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 30 }}>
        <FormControl>
          <RadioGroup row defaultValue='totalValue' onChange={({ target: { value } }) => {
            setChartType(value);
            // work around for line animation
            fuelData = { ...fuelData, chartData: [...chartData] };
          }}>
            <FormControlLabel value='totalValue' label='Dinheiro' control={<Radio />} />
            <FormControlLabel value='totalFillins' label='Número de Abastecimentos' control={<Radio />} />
            <FormControlLabel value='totalAmount' label='Volume' control={<Radio />} />
          </RadioGroup>
        </FormControl>
        <LineChart width={790} height={600} data={chartData ? chartData : []}>
          <XAxis dataKey="date" tick={<RotatedAxisTick fontSize={16} />} height={100} />
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis domain={['auto', 'auto']} type='number' />
          <Tooltip formatter={(value) => {
            switch (chartType) {
              case 'totalValue':
                return formatNumber(value, { style: 'currency', currency: 'BRL' });
              case 'totalAmount':
                return formatNumber(value, { style: 'decimal', unit: 'liter' });
              case 'totalFillins':
                return `${formatNumber(value)} Abastecimentos`;
              default:
                return ''
            }
          }} />
          {chartData.length && Object.values(fuelData).map((fuel, i) =>
            chartData.some(point => point[`${fuel.id}-totalFillins`]) &&
            <Line
              type='monotone'
              dataKey={`${fuel.id}-${chartType}`}
              stroke={i <= 11 ? colors[i] : colors[((i + 1) % 12) - 1]}
              name={fuel.name}
              activeDot={{ r: 5 }}
              key={i}
            />
          )}
        </LineChart>
      </div>
    </>
  );
};

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  return (
    <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
      <DateField source="from" textAlign="center" label="De" sortable={false} />
      <DateField source="to" textAlign="center" label="Até" sortable={false} />
      <ReferenceArrayField label="Posto" emptyText="Todos" source="stationIds" textAlign="center" basePath="stations" reference="stations" link={false}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField label="Produto" emptyText="Todos" source="fuelIds" textAlign="center" basePath="fuels" reference="fuels" link={false}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <Permission permission={PERMISSIONS.TAG}>
        <ReferenceArrayField label="Etiquetas" emptyText="Todas" source="tagIds" textAlign="center" reference='chains/chainId/tag'>
          <SingleFieldList>
            <ChipField source='name' />
          </SingleFieldList>
        </ReferenceArrayField>
      </Permission>
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
    </Datagrid>
  );
}

const FiltersReportList = (props) => {
  const { permissions } = usePermissions();
  const { filterValues } = useListContext();
  const employeeType = getEmployeeType();

  return (
    <Filter {...props}>
      <BetweenDatesInput alwaysOn />
      {(permissions && permissions.includes(PERMISSIONS.ADMIN) &&
        <ReferenceInput
          source="chainId"
          reference="chains"
          sort={{ field: "name", order: "ASC" }}
          perPage={null}
          alwaysOn
        >
          <SelectInput
            label="Rede"
            emptyText="Todos"
            style={{ minWidth: 180 }}
            defaultValue={getChainId()}
            optionText={'name'}
          />
        </ReferenceInput>
      )}
      <ReferenceArrayInput
        source="stationIds"
        reference="stations"
        perPage={null}
        filter={{ chainId: filterValues.chainId, ...(getEmployeeType() === EmployeeType.attendant && { attendantId: getAttendantId() }) }}
        sort={{ field: "name", order: "ASC" }}
        alwaysOn
      >
        <SelectArrayInput
          label="Posto"
          emptyText="Todos"
          optionText={'name'}
          style={{ minWidth: 160 }}
          disabled={filterValues.tagIds}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="fuelIds"
        reference="fuels"
        sort={{ field: "name", order: "ASC" }}
        alwaysOn
      >
        <SelectArrayInput
          label="Produto"
          emptyText="Todos"
          optionText={'name'}
          style={{ minWidth: 160 }}
        />
      </ReferenceArrayInput>
      {
        (employeeType === EmployeeType.admin || [EmployeeType.chain, EmployeeType.place].includes(employeeType)) &&
        <CheckChainUsesModule module={ModuleKey.PROMOTER} alwaysOn>
          <FormDataConsumer>
            {({ formData }) => (
              <ReferenceArrayInput
                source="promoterIds"
                reference="promoters"
                sort={{ field: "name", order: "ASC" }}
                filter={formData.chainId ? { chainId: formData.chainId } : {}}
              >
                <SelectArrayInput
                  label="Promoter"
                  emptyText="Todos"
                  style={{ minWidth: 180 }}
                  helperText={false}
                  optionText={'name'}
                />
              </ReferenceArrayInput>
            )}
          </FormDataConsumer>
        </CheckChainUsesModule>
      }
      <Permission permission={PERMISSIONS.TAG} alwaysOn>
        <ReferenceArrayInput
          style={{ minWidth: 160 }}
          source="tagIds"
          reference="chains/chainId/tag"
          sort={{ field: "name", order: "ASC" }}
          filter={{ type: TagType.station, chainId: filterValues.chainId }}
        >
          <SelectArrayInput
            label="Etiqueta"
            optionText={'name'}
            disabled={filterValues.stationIds}
          />
        </ReferenceArrayInput>
      </Permission>
    </Filter>
  );
}

const ReportFillinByFuel = (props) => {
  const placeId = getPlaceId();

  const filterValues = {
    chainId: getChainId(),
    from: moment().startOf('day').subtract(1, "month").toISOString(),
    to: moment().endOf('day').toISOString()
  };

  if (placeId) {
    filterValues.stationIds = [placeId];
  }

  if (getEmployeeType() === EmployeeType.promoter) {
    filterValues.promoterIds = [getUserId()];
  }

  if (getEmployeeType() === EmployeeType.attendant) {
    filterValues.attendantIds = [getAttendantId()];
  }

  return (
    <Permission permission={PERMISSIONS.FILLIN_BY_FUEL_REPORT}>
      <ReportList
        {...props}
        basePath="fillin-by-fuel"
        title="Abastecimentos por Combustível"
        resource="chains/chainId/reports/fillin-by-fuel"
        filters={<FiltersReportList />}
        sort={{ field: 'created_at', order: 'DESC' }}
        perPage={25}
        filterDefaultValues={filterValues}
        pagination={<ListPagination />}
        bulkActionButtons={false}
        exporter={false}
        titleOnPrint="Relatório de Abast. por Combustível"
        filterTablePrint={<FilterTablePrint />}
        landscape
      >
        <FillinByFuel />
      </ReportList>
    </Permission >
  );
};

export default ReportFillinByFuel;