import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import {
	Datagrid,
	DateField,
	NumberField,
	ReferenceInput,
	SelectInput,
	TextField,
	useListContext,
	usePermissions,
} from 'react-admin';
import { BetweenDatesInput } from '../../components/BetweenDatesInput';
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import ReportList from '../../components/ReportList';
import { PERMISSIONS } from '../../constants';
import { getChainId } from '../../lib';

const useStyles = makeStyles(theme => ({
	title: {
		margin: '10px 0 10px 0',
		textAlign: 'center',
		fontWeight: 'bold'
	},
}));

const PointsList = () => {
	const classes = useStyles();
	const props = useListContext();
	let data = [];
	let summaryData = [];

	if (props.data) {
		const reportInfo = props.data[0];

		if (reportInfo) {
			data = reportInfo.data;
			summaryData = [reportInfo.summaryData];
		}
	}

	return (
		<>
			<Typography className={classes.title}>Total</Typography>
			<Datagrid bulkActionButtons={false} style={{ marginBottom: 60 }} data={summaryData} total={1}>
				<NumberField
					source="total_value"
					label="Total de Pontos"
					textAlign="center"
					emptyText="0"
					options={{
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					}}
					sortable={false}
				/>
			</Datagrid>

			<Typography className={classes.title}>Pontos a Expirar</Typography>
			<Datagrid
				bulkActionButtons={false}
				resource={props.resource}
				data={data}
			>
				<TextField source="id" label="CPF" />
				<NumberField
					source="points"
					label="Pontos"
					textAlign="center"
					emptyText="0"
					options={{
						maximumFractionDigits: 2
					}}
				/>
			</Datagrid>
		</>
	);
}

const FilterTablePrint = () => {
	const classes = useStyles();
	const { filterValues } = useListContext();
	return (
		<Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
			<DateField source="from" textAlign="center" label="De" sortable={false} />
			<DateField source="to" textAlign="center" label="Até" sortable={false} />
			<DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
		</Datagrid>
	);
}

const ReportExpirationByCustomer = (props) => {
	const { permissions } = usePermissions();

	const listFilters = [
		<BetweenDatesInput />
	];

	if (permissions && permissions.includes(PERMISSIONS.ADMIN)) {
		listFilters.push(
			<ReferenceInput
				source="chainId"
				reference="chains"
				perPage={null}
				sort={{ field: "name", order: "ASC" }}
				defaultValue={getChainId()}
			>
				<SelectInput
					label="Rede"
					emptyText="Todos"
					style={{ minWidth: 180 }}
					optionText={'name'}
				/>
			</ReferenceInput>)
	}

	return (
		<Permission permission={PERMISSIONS.EXPIRED_POINTS_REPORT}>
			<ReportList
				{...props}
				basePath="fillins"
				title="Postos a Expirar"
				resource="reports/expiring-points-by-customer"
				filters={listFilters}
				sort={{ field: 'created_at', order: 'DESC' }}
				filterDefaultValues={{
					chainId: getChainId(),
					from: moment().startOf('day').toISOString(),
					to: moment().endOf('day').toISOString()
				}}
				bulkActionButtons={false}
				titleOnPrint="Relatório de Pontos Expirados Por Cliente"
				filterTablePrint={<FilterTablePrint />}
				landscape
				perPage={25}
				pagination={<ListPagination />}
			>
				<PointsList />
			</ReportList>
		</Permission >
	);
};

export default ReportExpirationByCustomer;