import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import {
  Edit,
  FormDataConsumer,
  Labeled,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  UpdateWithConfirmButton,
  useDataProvider,
  useNotify,
  useRefresh
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { getChainId } from '../../lib';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 300,
    marginTop: 40,
  },
}));

const FuelInput = ({ placeId, fuelId, label, placeFuels }) => {
  const placeIndex = placeFuels.findIndex(item => item.placeId === placeId);
  const fuelIndex = placeFuels[placeIndex].fuels.findIndex(item => item.fuelId === fuelId);

  return (
    <Labeled label={label}>
      <TextInput
        source={`placeFuels[${placeIndex}].fuels[${fuelIndex}].factor`}
        label="Fator Pontos/Litro"
        format={value => (!value ? '0,00' : `${Number(value).toFixed(2)}`)}
        parse={value => (!value ? 0 : +value.replace(/[^\d]/g, '') / 100)}
      />
    </Labeled>
  );
};

const StationFuels = ({ placeFuels, placeId }) => {
  const [fuels, setFuels] = useState([]);
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();

  useEffect(() => {
    setLoading(true);
    dataProvider.getList('fuels', { sort: { field: 'name', order: 'ASC' }, pagination: { page: 1, perPage: false }, filter: { placeId } })
      .then(({ data }) => {
        setFuels(data);
      }).finally(() => setLoading(false));
  }, [placeId]);

  if (loading) { return null; }

  return (
    fuels.map(({ id, name }) => (
      <FuelInput placeFuels={placeFuels} placeId={placeId} fuelId={id} label={name} />
    ))
  );
}

const DefaultPoints = () => {
  const notify = useNotify();
  const classes = useStyles();
  const navigate = useNavigate();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify('ra.notification.updated', { messageArgs: { smart_count: 1 } });
    refresh();
  };

  const onError = (error) => {
    notify('ra.notification.data_provider_error', { type: 'error' });
    navigate('/default-points-edit');
  };

  const ToolbarCustom = () => {
    return (
      <Toolbar>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const placeFuel = formData.placeFuels && formData.placeFuels.find(pf => pf.placeId === formData.placeId);
            return (
              <UpdateWithConfirmButton
                {...rest}
                data={{
                  ...formData, placeFuels: formData.placeFuels.map(({ fuels, ...rest }) => ({
                    ...rest, fuels: fuels.map(({ fuelId, placeId, factor }) => ({ fuelId, placeId, factor }))
                  }))
                }}
                mutationOptions={{}}
                confirmTitle="Atualizar pontuação"
                confirmContent={placeFuel && placeFuel.fuels && placeFuel.fuels.some(f => f.factor > 2) ? "A pontuação parece estar muito alta. Deseja confirmar?" : "A pontuação será atualizada. Esta medida terá efeito imediato."}
              />
            );
          }}
        </FormDataConsumer>
      </Toolbar>
    );
  }

  return (
    <Permission permission={PERMISSIONS.DEFAULT_POINTS}>
      <Edit id={getChainId()} title="Atualizar pontuação" resource="default-points" mutationMode="pessimistic" mutationOptions={{ onSuccess, onError }}>
        <SimpleForm toolbar={<ToolbarCustom />}>
          <div className={classes.container}>
            <ReferenceInput
              filter={{ chainId: getChainId(), type: 'station' }}
              sort={{ field: 'name', order: 'ASC' }}
              source="placeId"
              reference="places"
              perPage={null}
            >
              <SelectInput
                label="Local"
                optionText="name" />
            </ReferenceInput>
            <FormDataConsumer>
              {({ formData: { placeId, placeFuels } }) =>
                placeId && (<StationFuels placeFuels={placeFuels} placeId={placeId} />)
              }
            </FormDataConsumer>
          </div>
        </SimpleForm>
      </Edit>
    </Permission>
  );
};

export default DefaultPoints;