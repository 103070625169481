import moment from 'moment';
import { useEffect, useState } from 'react';
import {
    SearchInput,
    SimpleForm,
    useDataProvider,
    useNotify,
    usePermissions
} from 'react-admin';
import { Wrapper } from '../../components';
import { getPlaceId } from '../../lib';
import AdjustTable from './AdjustTable';

export default props => {
    const { isLoading: isLoadingPermissions, permissions } = usePermissions();
    const [search, setSearch] = useState(null);
    const [products, setProducts] = useState();
    const [loading, setLoading] = useState(false);
    const [places, setPlaces] = useState(null);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [date, setDate] = useState(moment());
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const fetchPlaces = async () => {
        setLoading(true)

        dataProvider.getList(`chains/chainId/places`, { sort: { field: 'name', order: 'ASC' } }).then(response => {
            const formattedResponse = response.data
                .map(({ id, name }) => ({ id, name }))
                .sort((a, b) => { return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0; });

            if (formattedResponse.length === 0) {
                notify('Nenhum Ponto de Troca encontrado', { type: 'warning' })
                setLoading(false);
                return
            }

            setLoading(false);
            setSelectedPlace(formattedResponse[0].id);
            setPlaces(formattedResponse);
        });
    }

    const fetchData = async () => {
        setLoading(true)
        const filter = { placeId: selectedPlace, stock: true }
        if (search) { filter.search = search }
        dataProvider.getList(`chains/chainId/products`, { filter }).then(response => {
            const products = response.data;
            if (selectedPlace) {
                products.forEach((p) => {
                    p.productPlaceInfo = p.productPlaces.find((pp) => pp.placeId === selectedPlace)
                })
            }
            setProducts(products);
            setLoading(false)
        });
    }

    const postData = async (date, adjustedProducts) => {
        setLoading(true)
        dataProvider.create(`chains/chainId/places/${selectedPlace}/adjust-products`, { data: { date, adjustments: adjustedProducts } }).then(async response => {
            notify('Ajuste de produtos realizado com sucesso');
            fetchData();
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            notify('Erro ao salvar ajuste de produtos', { type: 'warning' })
        })
    }

    useEffect(() => {
        if (!isLoadingPermissions && permissions.includes("stock-adjustment")) {
            fetchPlaces();
        } else if (permissions) {
            setSelectedPlace(getPlaceId())
        }
    }, [isLoadingPermissions])

    useEffect(() => {
        if (selectedPlace) {
            fetchData();
        }
    }, [search, selectedPlace, date])

    return (
        <Wrapper>
            <SimpleForm toolbar={null}>
                <SearchInput
                    source="search"
                    variant="outlined"
                    onChange={event => {
                        if (event.target) { setSearch(event.target.value) }
                        else { setSearch(null) }
                    }}
                />
                {products &&
                    <AdjustTable
                        products={products}
                        places={places}
                        placeId={selectedPlace}
                        saveCallback={(date, addedProducts) => postData(date, addedProducts)}
                        setSelectedPlaceCallback={setSelectedPlace}
                        loading={loading}
                        setDateCallback={date => setDate(date)}
                        date={date}
                    />
                }
            </SimpleForm>
        </Wrapper>

    );

};