export enum ConcentratorBrandType {
  companyTec = 'company-tec',
  ezTec = 'ez-tec',
  hiro = 'hiro',
}

export namespace ConcentratorBrandType {
  export const translateType = (type: ConcentratorBrandType): string => {
    switch (type) {
      case ConcentratorBrandType.companyTec:
        return "Company Tec";
      case ConcentratorBrandType.ezTec:
        return "EZ Tech";
      case ConcentratorBrandType.hiro:
        return "Hiro";
      default:
        return "";
    }
  };

  export const concentratorBrandChoices = Object.values(ConcentratorBrandType)
    .filter(x => typeof x === "string")
    .map((type) => ({
      id: type,
      name: ConcentratorBrandType.translateType(type),
    }));
}