import { httpClientReactAdmin } from '../../lib'
import { DEFAULT_API_ADMIN } from '../../constants'
import { getChainId } from '../../lib'

export default async (resourceName, params) => {

  const URL = `${DEFAULT_API_ADMIN}/${(resourceName || '').replace('chainId', getChainId())}`

  await Promise.all(params.ids.map(async id => await httpClientReactAdmin(`${URL}/${id}`, { method: 'DELETE' })))

  // Necessary workaround due to React Admin issue
  return { data: [] }
}
