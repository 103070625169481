import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  usePermissions
} from 'react-admin'
import ListPagination from '../../components/ListPagination'
import { PERMISSIONS } from '../../constants';

export default props => {
  const { permissions } = usePermissions();

  return (
    <List {...props}
      perPage={25}
      pagination={<ListPagination />}
      exporter={false}
      title="Permissões"
      filter={(permissions && !permissions.includes(PERMISSIONS.ADMIN_PROFILE)) && { isAdmin: false }}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="name" label="Nome" />
        <TextField source="label" label="Descrição" />
        <ReferenceField source="groupId" reference="permission-groups" label="Grupo">
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
}
