import { EmployeeType } from "../models";

export default (params, resourceName) => {

  if (resourceName === 'employees') {
    params.filter = { ...params.filter, type: params.filter.type || EmployeeType.chain };
  }

  if (resourceName === '_employees') {
    params.filter = { ...params.filter, type: EmployeeType.place };
  }

  if (resourceName === 'chains/chainId/products') {
    params.filter = { ...params.filter, isCash: false };
  }

  return params;
};