import React from "react";
import {
  Datagrid,
  TextField,
  Filter,
  ReferenceInput,
  SelectInput,
  SearchInput,
  usePermissions,
  EmailField,
} from 'react-admin';
import { Permission, ReportList } from "../../components";
import ListPagination from '../../components/ListPagination';
import { CNPJField, FullAdressField, PhoneField } from "../../components/Fields";
import { PERMISSIONS } from "../../constants";

const ListPartners = () => (
  <Datagrid bulkActionButtons={false}>
    <TextField source="name" label="Nome" />
    <CNPJField source="cnpj" label="CNPJ" />
    <PhoneField source="phone" label="Telefone" />
    <EmailField source="email" emptyText="Não definido" />
    <FullAdressField label="Endereço" />
  </Datagrid>
);

const ListFilters = props => {
  const { permissions } = usePermissions();

  return <Filter {...props}>
    <SearchInput source="search" alwaysOn />
    {permissions && permissions.includes(PERMISSIONS.ADMIN) &&
      <ReferenceInput
        source="chainId"
        reference="chains"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={null}
        alwaysOn
      >
        <SelectInput
          label="Rede"
          emptyText="Todos"
          optionText="name"
        />
      </ReferenceInput>}
  </Filter>
}

const ReportPartners = props => (
  <Permission permission={PERMISSIONS.PARTNERS_REPORT}>
    <ReportList
      {...props}
      basePath="report-partners"
      resource="chains/chainId/reports/partners"
      title="Relatório de Parceiros"
      filters={<ListFilters />}
      perPage={25}
      pagination={<ListPagination />}
      titleOnPrint="Relatório de Parceiros"
    >
      <ListPartners />
    </ReportList>
  </Permission>
);

export default ReportPartners;