import { PERMISSIONS } from '../../constants'
import edit from './PermissionGroupEdit'
import list from './PermissionGroupList'
import create from './PermissionGroupCreate'

export default {
  create,
  list,
  edit,
  name: 'permission-groups',
  permissions: [PERMISSIONS.CREATE_PROFILE],
}
