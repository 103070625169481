import React from 'react';
import { Create, required, SimpleForm, TextInput } from 'react-admin';
import { Wrapper } from '../../components';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';

const ParentFuelCreate = (props) => {
    return (
        <Permission permission={PERMISSIONS.ADMIN}>
            <Wrapper>
                <Create title='Adicionar combustível' {...props}>
                    <SimpleForm>
                        <TextInput source="acronym" label="Sigla" maxLength="3" validate={required()} />
                        <TextInput source="name" label="Nome" validate={required()} />
                    </SimpleForm>
                </Create>
            </Wrapper>
        </Permission>
    )
};

export default ParentFuelCreate;
