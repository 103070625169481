import { isValidCPF } from '@brazilian-utils/brazilian-utils';
import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import {
    Create,
    FormDataConsumer,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    Toolbar,
    useNotify,
    useRefresh,
} from 'react-admin';
import { ImportButton } from 'react-admin-import-csv';
import { Wrapper } from '../../components';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { getChainId } from '../../lib';
import { formatDecimal } from '../../utils';

const onlyNumbers = cpf => cpf.replace(/[^0-9]/g, '');

const ImportPoints = (props) => {
    const [chainId, setChainId] = useState(getChainId());
    const [placeId, setPlaceId] = useState();
    const refresh = useRefresh();
    const notify = useNotify();

    return (
        <Wrapper>
            <Permission permission={PERMISSIONS.IMPORT_POINTS}>
                <Create
                    {...props}
                    title="Adicionar Pontos"
                    resource={`chains/chainId/import-points`}
                    redirect={false}
                >
                    <SimpleForm
                        toolbar={
                            placeId ?
                                <Toolbar {...props}>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => {
                                            const config = {
                                                parseConfig: {
                                                    delimiter: ";"
                                                },
                                                logging: false,
                                                transformRows: (rows) => {
                                                    const transformedRows = [];

                                                    rows.forEach(row => {
                                                        const values = Object.values(row);

                                                        const transformedRow = {
                                                            cpf: onlyNumbers(values[0]),
                                                            value: +formatDecimal(values[1]),
                                                            createdAt: moment(values[2] ? values[2] : new Date(), 'DD/MM/YYYY'),
                                                            placeId: placeId,
                                                        }
                                                        transformedRows.push(transformedRow);
                                                    });
                                                    return transformedRows.filter(row => isValidCPF(row.cpf) && row.value && row.createdAt && row.placeId);
                                                },
                                                postCommitCallback: (result) => {
                                                    setPlaceId(null);
                                                    if ((result.length && !result[0].success) ||
                                                        (!result[0].response.data.length)) {
                                                        notify('Algo deu errado: nenhum ponto foi importado!', { type: 'warning' });
                                                    } else {
                                                        notify('Pontos importados com sucesso!');
                                                        refresh();
                                                    }
                                                }
                                            };
                                            return (<ImportButton label="Importar" disabled {...props} {...rest} {...config} />);
                                        }}
                                    </FormDataConsumer>

                                </Toolbar>
                                : false
                        }
                    >
                        <Grid container width={{ xs: '100%', xl: 800 }} spacing={2}>
                            <Typography style={{ marginTop: 10 }}>
                                Importar uma planilha com as colunas nessa ordem, independente do nome: CPF (com ou sem máscara), Valor dos pontos e Data (DD/MM/AAAA)
                            </Typography>
                            {!getChainId() &&
                                <ReferenceInput source="chainId" reference="chains" perPage={null} sort={{ field: "name", order: "ASC" }} onChange={({ target: { value } }) => setChainId(value)}>
                                    <SelectInput label="Rede" resettable optionText="name" />
                                </ReferenceInput>
                            }
                            {chainId && (
                                <ReferenceInput
                                    source="placeId"
                                    reference="stations"
                                    perPage={null}
                                    filter={{ chainId }}
                                    sort={{ field: "name", order: "ASC" }}
                                    >
                                    <SelectInput
                                        label="Posto"
                                        onChange={({ target: { value } }) => setPlaceId(value)}
                                        optionText="name"
                                        helperText="O local é necessário para que se especifique a origem dos pontos."
                                    />
                                </ReferenceInput>
                            )}
                        </Grid>
                    </SimpleForm>
                </Create>
            </Permission>
        </Wrapper>
    );
}

export default ImportPoints;