import React from 'react'
import {
	Edit,
	SimpleForm,
	TextInput,
	NumberInput,
	RadioButtonGroupInput,
	minLength,
	ReferenceInput,
	SelectInput,
	required,
	email,
	BooleanInput,
	FormDataConsumer,
	useNotify,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';

import Permission from '../../components/Permission';
import { makeStyles } from '@mui/styles';
import { PERMISSIONS } from '../../constants';

const useStyles = makeStyles((theme) => ({
	profiles: {
		width: 220,
	}
}));

const validatePassword = [minLength(6)];
const validateLogin = [required(), email()];


export default props => {
	const classes = useStyles();
	const transform = data => {
		delete data.profile;
		return data;
	};
	const notify = useNotify();
	const navigate = useNavigate();

	const onSuccess = () => {
    notify('ra.notification.updated', { messageArgs: { smart_count: 1 } });
    navigate('/system-users');
  }

	return (
		<Permission permission={PERMISSIONS.UPDATE_USER}>
			<Edit {...props} actions={false} resource="employees" transform={transform} title="Editar usuário" mutationMode="optimistic" mutationOptions={{ onSuccess }}>
				<SimpleForm>
					<TextInput source="name" label="Nome" validate={[required()]} InputProps={{ autoComplete: 'off' }} />
					<TextInput source="login" label="Login" validate={validateLogin} InputProps={{ autoComplete: 'off' }} />
					<TextInput source="password" label="Senha" validate={validatePassword} InputProps={{ autoComplete: 'new-password' }} />
					<NumberInput source="maxPoints" label="Pontos por CPF" InputProps={{ autoComplete: 'off' }} />
					<FormDataConsumer>
            {({ formData }) => (
              <ReferenceInput
                className={classes.profiles}
                source="profileId"
                reference="profiles"
                sort={{ field: 'name', order: 'ASC' }}
                perPage={9999}
                filter={{ type: 'admin' }}
                validate={[required()]} >
                <RadioButtonGroupInput label="Perfil" optionText="name" />
              </ReferenceInput>
            )}
          </FormDataConsumer>
					<BooleanInput source="mustChangePassword" label="Forçar usuário a trocar senha no primeiro acesso" />
				</SimpleForm>
			</Edit>
		</Permission>
	)
}
