import React from 'react'
import {
  List,
  Datagrid,
  TextField
} from 'react-admin'
import ListPagination from '../../components/ListPagination'

export default props => (
  <List {...props}
    perPage={25}
    pagination={<ListPagination />}
    exporter={false}
    bulkActionButtons={false}
    title="Permissões">
    <Datagrid rowClick="edit">
      <TextField source="name" label="Nome" />
    </Datagrid>
  </List>
)
