import React, { useCallback, useEffect, useState } from 'react';
import { useDataProvider, useInput, useNotify } from 'react-admin';
import { AutocompleteArrayInput, AutocompleteArrayInputProps } from 'ra-ui-materialui';
import { CircularProgress, IconButton, InputAdornment, debounce } from '@mui/material';
import { uniqBy } from 'lodash';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

interface AutoCompleteSelectArrayInputProps extends AutocompleteArrayInputProps {
  source: string,
  references: string,
  defaultFilter?: { [k: string]: any },
  sort?: { field: string, order: 'ASC' | 'DESC' },
}

const AutoCompleteSelectArrayInput: React.FC<AutoCompleteSelectArrayInputProps> = (props) => {

  const PER_PAGE = 25;

  const {
    source,
    references,
    defaultFilter = {},
    sort = { field: 'name', order: 'ASC' },
    ...restProps
  } = props;

  const [choices, setChoices] = useState<any[]>([]);
  const dataProvider = useDataProvider();
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [searchItems, setSearchItems] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const notify = useNotify();

  const [queryOptions, setQueryOptions] = useState<{ page: number, search: string | null }>({ page: 1, search: null });
  const [total, setTotal] = useState(0);

  const { field } = useInput({ source });

  const fetchSelectedItems = useCallback((ids: string[]) => {
    dataProvider.getMany(references, { ids }).then(({ data }) => {
      setSelectedItems(data);
      if (queryOptions.search) {
        setQueryOptions({ search: null, page: 1 });
      }
    });
  }, [field]);

  useEffect(() => {
    if (field.value && field.value.length > 0) {
      fetchSelectedItems(field.value);
    }
  }, []);

  useEffect(() => {
    if (choices.length > 0 && !queryOptions.search) {
      setSearchItems([]);
    }

    setIsLoading(true);

    dataProvider.getList(references,
      {
        filter: queryOptions.search ? { ...defaultFilter, search: queryOptions.search } : defaultFilter,
        pagination: { page: queryOptions.page, perPage: PER_PAGE },
        sort,
      }
    )
      .then(({ data, total }) => {
        if (queryOptions.search) {
          setSearchItems(data);
        } else if (choices.length === 0 || queryOptions.page === 1) {
          setChoices(data);
        } else if (queryOptions.page > 1) {
          setChoices(choices => [...choices, ...data]);
        }
        setTotal(total || 0);
        setIsLoading(false);
      })
      .catch(() => {
        notify('ra.notification.http_error', { type: 'error' });
      });
  }, [queryOptions]);

  const handleChange = (ids: string[]) => {
    if (ids.length === 0) {
      setSelectedItems([]);
      return;
    }

    fetchSelectedItems(ids);
  }

  const handleChangeInput = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryOptions({ search: event.target.value, page: 1 });
  }, 300);

  const handleScroll = (event: React.UIEvent<HTMLUListElement>) => {
    const bottom = event.currentTarget.scrollHeight - event.currentTarget.scrollTop === event.currentTarget.clientHeight;
    if (bottom) {
      if (Math.ceil(total / PER_PAGE) > queryOptions.page) {
        setQueryOptions(val => ({ ...val, page: val.page + 1 }));
      }
    }
  };

  const handleClearInput = (event) => {
    event.stopPropagation();
    field.onChange(null);
  };

  return (
    <AutocompleteArrayInput
      {...restProps}
      choices={uniqBy([...choices, ...searchItems, ...selectedItems], 'id')}
      source={source}
      onChangeCapture={handleChangeInput}
      onChange={handleChange}
      onBlur={() => setQueryOptions({ search: null, page: 1 })}
      blurOnSelect={false}
      ListboxProps={{
        onScroll: handleScroll,
      }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      filterSelectedOptions={false}
      TextFieldProps={{
        InputProps: {
          endAdornment: (
            <InputAdornment position="end" sx={{ top: 'calc(50%)', position: 'absolute', right: 8 }}>
              {
                isLoading ?
                  <CircularProgress sx={{ marginRight: '4px' }} color="inherit" size={18} />
                  :
                  field.value && field.value.length > 0 &&
                  <IconButton
                    size="small"
                    onClick={handleClearInput}
                    edge="end"
                  >
                    <ClearIcon sx={{ fontSize: '1.25rem' }} />
                  </IconButton>
              }
              <IconButton size="small" onClick={() => setOpen(!open)} edge="end">
                {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </IconButton>
            </InputAdornment>
          )
        }
      }}
      disableCloseOnSelect
    />
  );
}

export default React.memo(AutoCompleteSelectArrayInput);