import { useEffect, useState } from 'react';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import { DashboardWrapper } from '../../components';
import { PERMISSIONS } from '../../constants';
import useDashboardContext from '../../hooks/useDashboardContext';
import { getChainId } from '../../lib';
import { formatCpf, formatPoints } from '../../utils';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

const RANK_CUSTOMERS_LIMIT = 5;

const useStyles = makeStyles({
  table: {
    minWidth: 280,
  },
  tableRow: {
    textDecoration: 'none',
  },
});

export const RankCustomersAddedPoints = () => {
  const [customersData, setCustomersData] = useState(null)
  const classes = useStyles();
  const { permissions } = usePermissions();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { chain, from, to, stationIds } = useDashboardContext();

  const chainId = permissions && permissions.includes(PERMISSIONS.ADMIN) ? chain : getChainId();

  useEffect(() => {
    const filter = {
      limit: RANK_CUSTOMERS_LIMIT,
      from: moment.utc(from),
      to: moment.utc(to),
    };

    if (stationIds && stationIds.length > 0) { filter.placeIds = stationIds; }

    if (permissions && permissions.includes(PERMISSIONS.RANK_CUSTOMERS_ADDED_POINTS)) {
      let rank = 1;
      dataProvider.getList('dashboard/rank-customers-added-points', { filter, chainId })
        .then(response => {
          const customers = response.data;
          customers.forEach(c => {
            c.rank = rank;
            rank++;
          })
          setCustomersData(customers);
        })
        .catch((e) => { notify('Erro ao carregar o ranking de clientes', { type: 'warning' }); });
    }
  }, [permissions, from, to, chain, stationIds]);

  return (
    <DashboardWrapper
      permission={PERMISSIONS.RANK_CUSTOMERS_ADDED_POINTS}
      title='Ranking Pontos Adicionados'
      width='50%'
      content={
        <TableContainer>
          <Table className={classes.table} aria-label="customized table">
            <TableBody>
              {customersData
                ? customersData.map(c => (
                  <TableRow
                    className={classes.tableRow}
                    key={c.id} hover>
                    <TableCell>{c.rank}</TableCell>
                    <TableCell >{formatCpf(c.id)}</TableCell>
                    <TableCell align="right">{formatPoints(c.totalpoints)}</TableCell>
                  </TableRow>
                )) : null}
            </TableBody>
          </Table>
        </TableContainer>
      }
    />
  )
}
