import { PERMISSIONS } from '../../constants'
import edit from './PermissionEdit'
import list from './PermissionList'
import create from './PermissionCreate'

export default {
  create,
  list,
  edit,
  name: 'user-permissions',
  permissions: [PERMISSIONS.ADMIN],
}