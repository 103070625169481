import { Box } from '@mui/material';
import {
    ArrayField,
    ChipField,
    CreateButton,
    DatagridConfigurable,
    DateField,
    Filter,
    List,
    ReferenceArrayInput,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectArrayInput,
    SelectInput,
    SingleFieldList,
    TextField,
    TopToolbar,
    useRecordContext,
} from 'react-admin';
import { CustomExportButton, Tooltip } from '../../components';
import { PartiallyHiddenCpfField } from '../../components/Fields';
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import { BlockCustomerButton, IgnoreCustomerFraudButton, IgnoreFraudButton } from '../../components/PotencialFraudActions';
import { PERMISSIONS } from '../../constants';
import { PotencialFraudRule } from '../../models';
import { getChainId } from '../../lib';

const ListActions = () => {
    const preExporter = (customers) => {
        const potencialFraud = [];
        customers.forEach(c => {
            potencialFraud.push({
                cliente: c.customer.name,
                cpf: c.customer.cpf,
                motivoDaSuspeita: c.fraud.label,
                adicionadoEm: c.createdAt
            });
        });

        return potencialFraud;
    };

    return (
        <TopToolbar>
            <Box sx={{ display: 'flex' }}>
                <Permission permission={PERMISSIONS.POTENCIAL_FRAUD_LIST}>
                    <CreateButton />
                </Permission>
                <CustomExportButton fileName="Possíveis fraudes" preExport={preExporter} />
            </Box>
        </TopToolbar>
    );
};

const PotencialFraudFilter = props =>
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
        <SelectInput
            source="isOpen"
            alwaysOn
            label='Situação'
            emptyText="Todos"
            choices={[
                { id: true, name: 'Em aberto' },
                { id: false, name: 'Fechadas' }
            ]}
        />

        <ReferenceArrayInput source="fraudIds" reference="security-rules" perPage={null} sort={{ field: "label", order: "ASC" }} alwaysOn>
            <SelectArrayInput sx={{ minWidth: 180 }} label="Motivo da suspeita" optionText="label" />
        </ReferenceArrayInput>
        {getChainId() &&
            <ReferenceInput source="groupId" reference="chains/chainId/customer-groups" sort={{ field: "name", order: "ASC" }} alwaysOn>
                <SelectInput label="Grupo" optionText="name" emptyText="Todos" />
            </ReferenceInput>
        }
    </Filter>;

export const PotencialFraudRuleField = ({ label }) => {
    const { fraud } = useRecordContext();
    return (
        <Tooltip title={PotencialFraudRule.description(fraud?.name)} placement="bottom">
            <TextField source="fraud.label" label={label} sortable={false} />
        </Tooltip>
    );
}

export const PotencialFraudDataGrid = ({ hideName }) => {
    return (
        <DatagridConfigurable bulkActionButtons={false} rowClick="show">
            {
                !hideName &&
                <ReferenceField label="Cliente" link="show" reference="customers" source="customerId">
                    <TextField source="name" />
                </ReferenceField>
            }
            <ReferenceField
                reference="customers"
                source="customerId"
                label="CPF"
                link={false}
                sortable={false}
            >
                <PartiallyHiddenCpfField source="cpf" />
            </ReferenceField>
            <PotencialFraudRuleField label="Motivo da suspeita" />
            <DateField source="createdAt" label="Adicionado em" locales="pt-BR" sortable={false} />
            <ArrayField source="placeFraudLog" label="Posto" sortable={false} textAlign="center" emptyText="Não identificado" >
                <SingleFieldList>
                    <ReferenceField source="placeId" reference="places" >
                        <ChipField source="name" />
                    </ReferenceField>
                </SingleFieldList>
            </ArrayField>
            <Permission permission={PERMISSIONS.IGNORE_FRAUD_LOG}>
                <IgnoreFraudButton />
            </Permission>
            <Permission permission={PERMISSIONS.IGNORE_CUSTOMER_FRAUD_LOG}>
                <IgnoreCustomerFraudButton />
            </Permission>
            <Permission permission={PERMISSIONS.BLOCK_CUSTOMER}>
                <BlockCustomerButton />
            </Permission>
        </DatagridConfigurable>
    );
};


const PotencialFraudList = props => {

    return (
        <Permission permission={PERMISSIONS.POTENCIAL_FRAUD_LIST}>
            <List
                {...props}
                perPage={25}
                pagination={<ListPagination />}
                exporter={false}
                actions={<ListActions />}
                title="Possíveis fraudes"
                filters={<PotencialFraudFilter />}
                filterDefaultValues={{ isOpen: true }}
            >
                <PotencialFraudDataGrid />
            </List>
        </Permission>
    );
};

export default PotencialFraudList;