import React from 'react';
import { Show, SimpleShowLayout, ReferenceManyField, Datagrid, TextField, EditButton, DeleteButton, List } from 'react-admin';
import { Wrapper } from '../../components';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';

const FuelsList = ({ permissions, ...props }) => (
    <Show
        actions={false}
        {...props}
        /* disable the app title change when shown */
        title=''
    >
        <SimpleShowLayout>
            <ReferenceManyField EditButton={false} reference="fuels" target="parentFuelId" addLabel={false}>
                <Datagrid rowClick="show">
                    <TextField source="acronym" label="Sigla" />
                    <TextField source="name" label="Nome" />
                </Datagrid>
            </ReferenceManyField>
        </SimpleShowLayout>
    </Show>
);

const ParentFuelList = (props) => {
    return (
        <Permission permission={PERMISSIONS.ADMIN}>
            <Wrapper>
                <List
                    {...props}
                    title="Combustíveis"
                    exporter={false}
                    bulkActionButtons={false}
                >
                    <Datagrid expand={<FuelsList />}>
                        <TextField source="acronym" label="Sigla" />
                        <TextField source="name" label="Nome" />
                        <EditButton />
                        <DeleteButton />
                    </Datagrid>
                </List>
            </Wrapper>
        </Permission>
    )
};

export default ParentFuelList;
