import { DayScheduledDiscount } from "./dayScheduledDiscount";
import { GiftExpirationType } from "./giftExpirationType";
import { GiftRule } from "./giftRule";

export enum GiftFrequency {
  Daily = 'daily',
  Never = "never",
  Weekly = "weekly",
}

export class Gift {

  id: string | undefined;
  identify: string | undefined;
  productName: string | undefined;
  productDescription: string | undefined;
  productValue: number | undefined;
  productImagePath: string | undefined;
  productId: string | undefined;
  hasProduct: boolean | undefined;
  chainId: string | undefined;
  createdByEmployeeId: string | undefined;
  startAt: string | undefined;
  endAt: string | undefined;
  frequency: GiftFrequency | undefined;
  limitQuantity: number | undefined;
  limitIntervalInHours: number | undefined;
  expirationType: GiftExpirationType | undefined;
  expirationInHours: number | undefined;
  expirationTime: string | undefined;
  createdAt: string | undefined;
  giftRules: any[] = [];
  dayScheduledGifts: DayScheduledDiscount[] = [];
  weekSchedual: DayScheduledDiscount[] = [];
  tagIds: Array<string> = [];
  placeIds: Array<string> = [];
  placeGifts: Array<{ placeId: string, rtiCode?: string | null }> = [];
  startTime: Date | undefined;
  endTime: Date | undefined;
  isSchedule: boolean = false;
  rtiPlaces: any[] = [];

  static createGift(value: Partial<Gift>) {
    const gift: Partial<Gift> = {
      productName: value.productName,
      productDescription: value.productDescription,
      productValue: value.productValue,
      productImagePath: value.productImagePath,
      productId: value.productId,
      chainId: value.chainId,
      giftRules: (value.giftRules || [])?.map((giftRule) => GiftRule.createGiftRule(giftRule)),
      dayScheduledGifts: value.dayScheduledGifts,
      placeIds: value.placeIds,
      limitQuantity: value.limitQuantity,
      limitIntervalInHours: value.limitIntervalInHours,
      expirationType: value.expirationType,
      expirationInHours: value.expirationInHours,
      expirationTime: value.expirationTime,
      isSchedule: !!(value.startAt || value.endAt || value.frequency),
      hasProduct: !!value.productId,
      startAt: value.startAt,
      endAt: value.endAt,
      frequency: value.frequency,
      tagIds: value.tagIds,
      rtiPlaces: (value.placeGifts || []).map(placeGift => ({ placeId: placeGift.placeId, rtiCode: placeGift.rtiCode })),
    };

    if (value.frequency === GiftFrequency.Daily) {
      gift.startTime = value.dayScheduledGifts && value.dayScheduledGifts[0].startTime;
      gift.endTime = value.dayScheduledGifts && value.dayScheduledGifts[0].endTime;
    }

    return gift;
  }

  constructor(init?: Partial<Gift>) {
    Object.assign(this, init);
  }
}