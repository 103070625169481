import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from "moment";
import {
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  SelectArrayInput,
  SingleFieldList,
  TextField,
  useListContext,
} from 'react-admin';
import { Permission, ReportList } from "../../components";
import { BetweenDatesInput } from "../../components/BetweenDatesInput";
import { PartiallyHiddenCpfField } from "../../components/Fields";
import ListPagination from '../../components/ListPagination';
import { PERMISSIONS } from "../../constants";
import { formatPoints } from "../../utils";

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
}));

const ListPendingTransaction = () => {

  const classes = useStyles();
  const props = useListContext();

  let data = [], summaryData = [];

  if (props.data) {
    const reportInfo = props.data[0];

    if (reportInfo) {
      data = reportInfo.data;
      summaryData = [reportInfo.summaryData];
    }
  }

  return (
    <>
      <Typography className={classes.title} variant="h6">Total</Typography>
      <Datagrid bulkActionButtons={false} style={{ marginBottom: 60 }} data={summaryData} total={1}>
        <NumberField
          source="total_value"
          label="Total de Pontos"
          textAlign="center"
          emptyText="0"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
          sortable={false}
        />
      </Datagrid>
      <Typography className={classes.title} variant="h6">Pontos Pendentes</Typography>
      <Datagrid
        bulkActionButtons={false}
        resource={props.resource}
        data={data}

      >
        <PartiallyHiddenCpfField source="customerCpf" label="CPF" />
        <ReferenceField
          label="Posto"
          emptyText="Posto não identificado"
          source="placeId"
          reference="places"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          source="value"
          label="Pontos"
          render={record => formatPoints(+record?.value)}
        />
        <DateField
          source="createdAt"
          textAlign="center"
          label="Data"
        />
        <NumberField
          source="pendingFillins.amount"
          label="Litros"
          textAlign="center"
          sortable={false}
        />
        <ReferenceField
          source="pendingFillins.fuelId"
          reference="fuels"
          label="Combustível"
          textAlign="center"
          link={false}
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>

    </>
  );
};

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  return (
    <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
      <DateField source="from" textAlign="center" label="De" sortable={false} />
      <DateField source="to" textAlign="center" label="Até" sortable={false} />
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
      <ReferenceArrayField source="placeIds" emptyText="Todos" reference="stations" label="Posto" sortable={false}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
  );
};

const ReportPendingTransaction = (props) => {
  const listFilters = [
    <BetweenDatesInput alwaysOn />,
    <ReferenceArrayInput
      source="placeIds"
      reference="stations"
      perPage={null}
      alwaysOn
    >
      <SelectArrayInput
        label="Posto"
        style={{ minWidth: 180 }}
        optionText={"name"}
      />
    </ReferenceArrayInput>
  ];

  return (
    <Permission permission={PERMISSIONS.PENDING_TRANSACTION_REPORT}>
      <ReportList
        {...props}
        basePath="report-pending-transaction"
        resource="chains/chainId/reports/pending-transaction"
        title="Pontos Pendentes"
        filters={listFilters}
        perPage={25}
        pagination={<ListPagination />}
        filterDefaultValues={{
          from: moment().startOf("day").subtract(1, "month").toISOString(),
          to: moment().endOf("day").toISOString(),
        }}
        titleOnPrint="Pontos Pendentes"
        filterTablePrint={<FilterTablePrint />}
      >
        <ListPendingTransaction />
      </ReportList>
    </Permission>
  );
};

export default ReportPendingTransaction;