import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import {
  ChipField,
  Datagrid,
  DateField,
  Filter,
  FormDataConsumer,
  NumberField,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SingleFieldList,
  TextField,
  useListContext,
  useRecordContext,
} from 'react-admin';
import { Wrapper } from '../../components';
import { CPFField, FullAdressField, PhoneField } from '../../components/Fields';
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import ReportList from '../../components/ReportList';
import { PERMISSIONS } from '../../constants';
import { formatReal } from "../../lib";
import { TagType } from '../../models';
import RequiredChain from '../../components/RequiredChain';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '.5rem 0 .5rem 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  align: {
    textAlign: 'center',
  },
}));

const DrawingList = () => {
  const classes = useStyles();
  const props = useListContext();

  let data = [];
  let summaryData = [];

  if (props.data) {
    const reportInfo = props.data[0];

    if (reportInfo) {
      data = reportInfo.data;
      summaryData = reportInfo.summaryData;
    }
  }

  return (
    <>
      <Typography className={classes.title}>Total</Typography>
      <Datagrid bulkActionButtons={false} data={summaryData} style={{ marginBottom: 60 }}>
        <NumberField textAlign="center" label="Clientes" source="cnt_customer" sortable={false} emptyText="0" />
        <NumberField textAlign="center" label="Números" source="cnt_number" sortable={false} emptyText="0" />
      </Datagrid>
      <Typography className={classes.title}>Relatório de Sorteios</Typography>
      <Datagrid bulkActionButtons={false} data={data}>
        <TextField label='Nome' source='name' />
        <CPFField label='CPF' source='cpf' />
        <TextField label='RG' source='identity_card' />
        <NumberField label='Número da Sorte' source='lucky_number' textAlign='center' />
        <PhoneField label='Telefone' source='cellphone' />
        <FullAdressField label='Endereço' />
      </Datagrid>
    </>
  );
};

const DrawingExporter = async (data) => {
  const customers = data[0].data.map(customer => ({
    nome: customer.name,
    cpf: customer.cpf,
    rg: customer.identity_card,
    numero_da_sorte: customer.lucky_number,
    telefone: customer.cellphone,
    endereco: (customer.street ? customer.street : '') +
      (customer.street_number ? `, n° ${customer.street_number}` : '') +
      (customer.complement ? `, ${customer.complement}` : '') +
      (customer.neighbourhood ? ` - ${customer.neighbourhood}` : '') +
      (customer.city ? `, ${customer.city}` : '') +
      (customer.state ? ` - ${customer.state}` : ''),
  }));

  return customers;
};

const DrawingRuleChip = () => {
  const record = useRecordContext();
  if (!record) { return null };

  const name = `${record.drawingRule.label} - ${record.fuel?.name || 'Todos'} ${formatValueToDrawingRule(record)}`;
  return <ChipField record={{ name }} source="name" />;
};

const FilterTablePrint = () => {
  const { filterValues } = useListContext();
  const classes = useStyles();

  return (
    <Datagrid
      bulkActionButtons={false}
      data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]}
      total={1}>
      <ReferenceField source="drawingId" textAlign="center" label="Sorteio" reference="chains/chainId/drawings" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceArrayField
        label="Regra - Combustível - Valor"
        source="drawingRuleFuelIds"
        reference="drawing-rule-fuels"
        link={false}
        textAlign="center"
      >
        <SingleFieldList>
          <DrawingRuleChip />
        </SingleFieldList>
      </ReferenceArrayField>
      {
        Permission({
          permission: PERMISSIONS.TAG,
          children: (
            <ReferenceArrayField textAlign="center" label="Etiqueta" source="tagIds" reference="chains/chainId/tag">
              <SingleFieldList>
                <ChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>
          )
        })
      }
      <DateField source='generatedReportAt' label='Gerado às' showTime sortable={false} locales='pt-BR' className={classes.align} />
    </Datagrid>
  );
};

const CustomPagination = () => {
  const { filterValues } = useListContext();
  if (!filterValues.drawingId) {
    return (
      <Wrapper >
        <Typography style={{ textAlign: 'center', fontSize: 20 }}>Selecione um Sorteio</Typography>
      </Wrapper>
    );
  }
  return <ListPagination />;
};

const formatValueToDrawingRule = (drawingRuleFuel) => {
  if (!drawingRuleFuel.value) { return ''; }

  if (drawingRuleFuel.drawingRule && drawingRuleFuel.drawingRule.name === 'fillin-value') {
    return `- ${formatReal(drawingRuleFuel.value)}`;
  }

  return `- ${drawingRuleFuel.value}L`;
};

const FilterList = () => {
  const { filterValues } = useListContext();

  return (
    <Filter>
      <ReferenceInput
        reference="chains/chainId/drawings"
        source="drawingId"
        sort={{ field: "name", order: "ASC" }}
        style={{ minWidth: 260 }}
        perPage={null}
        alwaysOn
      >
        <SelectInput
          label="Sorteio"
          optionText="name"
        />
      </ReferenceInput>
      <FormDataConsumer alwaysOn>
        {({ formData, ...rest }) =>
          formData.drawingId ?
            <ReferenceArrayInput
              source="drawingRuleFuelIds"
              reference="drawing-rule-fuels"
              filter={{ drawingId: formData.drawingId }}
              sort={{ field: "startDate", order: "ASC" }}
              perPage={null}
              {...rest}
            >
              <SelectArrayInput
                label="Regra - Combustível - Valor"
                style={{ minWidth: 260 }}
                optionText={record => `${record.drawingRule.label} - ${record.fuel?.name || 'Todos'} ${formatValueToDrawingRule(record)}`}
                helperText={false}
              />
            </ReferenceArrayInput>
            : null
        }
      </FormDataConsumer>
      <ReferenceArrayInput
        source="placeIds"
        reference="stations"
        style={{ minWidth: 260 }}
        sort={{ field: "name", order: "ASC" }}
        perPage={null}
        alwaysOn
      >
        <SelectArrayInput
          label="Postos"
          optionText="name"
          disabled={!filterValues.drawingId}
        />
      </ReferenceArrayInput>
      {
        Permission({
          permission: PERMISSIONS.TAG,
          children: (
            <ReferenceArrayInput
              source="tagIds"
              reference="chains/chainId/tag"
              sort={{ field: "name", order: "ASC" }}
              filter={{ type: TagType.client }}
              style={{ minWidth: 260 }}
              perPage={null}
              alwaysOn
            >
              <SelectArrayInput
                label="Etiqueta"
                optionText="name"
                disabled={!filterValues.drawingId} />
            </ReferenceArrayInput>
          )
        })
      }
    </Filter>
  );
};

const ReportDrawing = (props) => {
  return (
    <Permission permission={PERMISSIONS.DRAWING_REPORT}>
      <RequiredChain>
        <ReportList
          {...props}
          basePath='report-drawing'
          title='Sorteios'
          resource='chains/chainId/reports/drawing'
          sort={{ field: 'cpf', order: 'DESC' }}
          perPage={25}
          filters={<FilterList />}
          filterDefaultValues={{
            from: moment().startOf("day").subtract(1, "month").toISOString(),
            to: moment().endOf("day").toISOString(),
          }}
          pagination={<CustomPagination />}
          bulkActionButtons={false}
          customExporter={DrawingExporter}
          titleOnPrint='Relatório de Sorteios'
          filterTablePrint={<FilterTablePrint />}
          landscape
        >
          <DrawingList />
        </ReportList>
      </RequiredChain>
    </Permission >
  );
};

export default ReportDrawing;