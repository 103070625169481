import React from 'react'
import {
	SimpleForm,
	TextInput,
	usePermissions,
	SelectInput,
	ReferenceInput,
	Create,
	NumberInput,
	maxLength,
	required,
} from 'react-admin'
import { PERMISSIONS } from '../../constants'
import { makeStyles } from '@mui/styles';
import Permission from '../../components/Permission'
import { getChainId } from '../../lib';

const useStyles = makeStyles((theme) => ({
	chain: {
		width: 255,
	}
}));

export default props => {
	const classes = useStyles();
	const { permissions } = usePermissions();

	return (
		<Permission permission={PERMISSIONS.CREATE_PRODUCT}>
			<Create {...props} title="Criar Produto">
				<SimpleForm>
					<NumberInput
						source='code'
						label="Código do Produto"
						min={1}
						validate={[required(), maxLength(200)]}
					/>
					<TextInput
						source="name"
						label="Nome"
						InputProps={{ autoComplete: 'off' }}
						validate={[required(), maxLength(255)]}
					/>
					<ReferenceInput
						source="groupId"
						reference='chains/chainId/product-categories'
						sort={{ field: 'name', order: 'ASC' }} >
						<SelectInput optionText="name" label="Categoria do Produto" />
					</ReferenceInput>
					{
						permissions && permissions.includes(PERMISSIONS.ADMIN)
							? <ReferenceInput
								className={classes.chain}
								source="chainId"
								reference="chains"
								perPage={null}
								defaultValue={getChainId()}
								sort={{ field: 'name', order: 'ASC' }} >
								<SelectInput optionText="name" label="Rede" />
							</ReferenceInput>
							: null
					}
					<TextInput
						source="pointsPerMoney"
						label="Pontos por real"
						InputProps={{ autoComplete: 'off' }}
						format={(value) => {
							if (!value || value === 0) {
								return "0,00";
							}
							return `${Number(value).toFixed(2).replace('.', ',')}`;
						}}
						parse={(value) => {
							if (!value || value === '') {
								return 0;
							}
							const onlyNumbers = +value.replace(/[^\d]/g, '');
							return onlyNumbers / Math.pow(10, 2);
						}}
					/>
				</SimpleForm>
			</Create>
		</Permission>
	)
}
