import React from 'react'
import {
  List,
  Filter,
  Datagrid,
  TextField,
  BooleanField,
  SearchInput,
} from 'react-admin'
import ListPagination from '../../components/ListPagination'
import Permission from '../../components/Permission'
import { PERMISSIONS } from '../../constants'

const UserFilter = ({ ...props }) => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn />
  </Filter>
)

export default ({ permissions, ...props }) => (
  <Permission permission={PERMISSIONS.CREATE_PROFILE}>
    <List
      {...props}
      perPage={25}
      pagination={<ListPagination />}
      exporter={false}
      bulkActionButtons={false}
      title="Perfis"
      filters={<UserFilter {...props} />}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" label="Nome" />
        <BooleanField source="fullAccess" label="Acesso total" />
      </Datagrid>
    </List>
  </Permission >
)
