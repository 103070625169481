import React from 'react';
import { List, Datagrid, DeleteButton, CreateButton, TopToolbar, TextField, Filter, SearchInput, EditButton } from 'react-admin';
import { CNPJField } from '../../components/Fields';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { getChainId } from '../../lib';

const ListActions = () => (
    <TopToolbar>
        <CreateButton />
    </TopToolbar>
);

const CompaniesWithoutPointsFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
    </Filter>
);

const CompaniesWithoutPointsList = (props) => {
    return (
        <Permission permission={PERMISSIONS.MANAGE_COMPANY_WITHOUT_POINT}>
            <List
                {...props}
                title="CNPJs excluídos"
                exporter={false}
                bulkActionButtons={false}
                actions={<ListActions />}
                filters={<CompaniesWithoutPointsFilter />}
                filter={{ chainId: getChainId() }}
            >
                <Datagrid bulkActionButtons={false}>
                    <CNPJField source="cnpj" label="CNPJ" />
                    <TextField source="name" label="Nome" />
                    <EditButton />
                    <DeleteButton />
                </Datagrid>
            </List>
        </Permission>
    );
};

export default CompaniesWithoutPointsList;
