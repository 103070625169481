import React from 'react';
import { SimpleForm, Edit, FileInput, FileField, DateInput, TextInput } from 'react-admin';
import { Permission } from '../../components';
import { MAX_DATE, PERMISSIONS } from '../../constants';
import dayjs from 'dayjs';

export default props => {
  const transform = (data) => ({...data, expiresAt: dayjs(data.expiresAt).toISOString() })
  return (
    <Permission permission={PERMISSIONS.BILLING}>
      <Edit {...props} title="Editar Fatura" transform={transform}>
        <SimpleForm>
          <DateInput
            source="expiresAt"
            type="date"
            label="Data de vencimento"
            inputProps={{ max: MAX_DATE }}
            options={{ format: "DD/MM/YYYY" }}
          />
          <FileInput
            source="files"
            label="Incluir NFe (Não adicionar boletos! A PontuaX não se responsabiliza pelos arquivos enviados.)"
            multiple
          >
            <FileField source="src" title="title" />
          </FileInput>
          <TextInput
            inputProps={{ autoComplete: 'off' }}
            source="partnerNote"
            label="Observação"
            multiline
            fullWidth
          />
        </SimpleForm>
      </Edit>
    </Permission>
  );
}
