
import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  SearchInput,
  ImageField,
} from "react-admin";
import Permission from "../../components/Permission";
import { PERMISSIONS } from "../../constants";
import ListPagination from "../../components/ListPagination";
import { makeStyles } from '@mui/styles'

const listStyles = makeStyles({
  image: {
    '& img': {
      maxWidth: '4rem',
    },
  },
})

const PartnerFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn />
  </Filter>
);

export default (props) => {
  const classes = listStyles();
  
  return (
    <Permission permission={PERMISSIONS.CREATE_PARTNER}>
      <List
        {...props}
        perPage={25}
        pagination={<ListPagination />}
        exporter={false}
        bulkActionButtons={false}
        title="Parceiros"
        filters={<PartnerFilter />}
      >
        <Datagrid rowClick="edit">
          <ImageField className={classes.image} source="imagePath"  title="Imagem" />
          <TextField source="name" label="Nome" />
          <TextField source="city" label="Cidade" />
          <TextField source="state" label="Estado" />
          <TextField source="cep" label="CEP" />
          <TextField source="neighbourhood" label="Bairro" />
          <TextField source="street" label="Rua" />
          <TextField source="streetNumber" label="Número" />
          <TextField source="cnpj" label="CNPJ" />
          <TextField source="phone" label="Telefone" />
          <TextField source="contact" label="Contato" />
        </Datagrid>
      </List>
    </Permission>
  );
}