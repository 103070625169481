import { ArrayField, BooleanField, ChipField, Datagrid, DateField, FunctionField, ImageField, List, NumberField, ReferenceArrayField, ReferenceField, RichTextField, SearchInput, SelectField, Show, SimpleShowLayout, SingleFieldList, Tab, TabbedShowLayout, TextField, useGetOne, useListContext, usePermissions, useRecordContext } from 'react-admin';
import Permission from '../../components/Permission';
import { PERMISSIONS, daysOfTheWeek, frequencyChoices } from '../../constants';

import { Box, Typography, styled } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { uniq } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { CheckChainUsesModule, GiftDisableButton, ModuleKey } from '../../components';
import { PartiallyHiddenCpfField } from '../../components/Fields';
import { getChainId, getUserId } from '../../lib';
import { DispatchMessageType, GiftExpirationType, GiftRuleType } from '../../models';
import { DispatchMessageStatus } from '../../models/dispatchMessageStatus';
import CloneGiftButton from './CloneGiftButton';

const SingleFieldChips = (props) => {
    const { data } = useListContext();
    return (
        !data || !data.length ?
            <FunctionField render={() => props.emptyText || 'Sem informações'} />
            :
            <SingleFieldList>
                <ChipField source={props.source} />
            </SingleFieldList>
    );
};

const RuleList = (props) => {
    const classes = useStyles();
    const record = useRecordContext();
    if (!record) { return null; }

    return (
        <SimpleShowLayout className={classes.container}>
            <ArrayField source="giftRules" label="">
                <Datagrid bulkActionButtons={false} style={{ padding: 0 }}>
                    <SelectField
                        choices={GiftRuleType.giftRuleTypeChoices}
                        source="type"
                        label="Tipo"
                    />
                    <ReferenceField source="fuelId" reference="fuels" label="Combustíveis" emptyText="Todos" textAlign="center">
                        <TextField source="name" />
                    </ReferenceField>
                    <SelectField
                        choices={[{ id: "volume", name: "Litros" }, { id: "cents", name: "Valor" }]}
                        source="valueType"
                        label="Tipo de valor"
                        textAlign="center"
                    />
                    <FunctionField
                        label="Valor"
                        textAlign="center"
                        sortable={false}
                        render={data => (data && data.valueType === 'volume') ?
                            <NumberField
                                source="value"
                                emptyText="0,00"
                                options={{
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }}
                                textAlign="center"
                            />
                            :
                            <NumberField
                                source="value"
                                emptyText="R$ 0,00"
                                options={{
                                    style: 'currency',
                                    currency: 'BRL',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }}
                                textAlign="center"
                            />
                        }
                    />
                    <ReferenceArrayField label="Postos" reference="stations" source="stationIds">
                        <SingleFieldList>
                            <ChipField source="name" />
                        </SingleFieldList>
                    </ReferenceArrayField>
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    );
}

const PlaceListField = (props) => {
    const record = useRecordContext();
    const classes = useStyles();
    if (!record) { return null; }

    return (
        <SimpleShowLayout className={classes.container} record={{ ...record, stationIds: uniq(record.placeDiscounts?.map(fd => fd.placeId)) }}>
            <ReferenceArrayField label="Locais de troca" reference="places" source="placeIds">
                <SingleFieldChips source="name" emptyText="Sem locais" />
            </ReferenceArrayField>
        </SimpleShowLayout>
    );
}

const TagListField = (props) => {
    const classes = useStyles();
    const record = useRecordContext();
    if (!record) { return null; }

    return (
        <SimpleShowLayout className={classes.container} record={{ ...record, customerGroupIds: uniq(record.groupDiscounts?.map(fd => fd.groupId)) }}>
            <ReferenceArrayField label="Tags" reference="chains/chainId/tag" source="tagIds">
                <SingleFieldChips source="name" emptyText="Sem tags" />
            </ReferenceArrayField>
        </SimpleShowLayout>
    );
}

const CustomerList = (props) => {
    const record = useRecordContext();
    if (!record) { return null; }

    const listFilters = [
        <SearchInput source="search" alwaysOn />
    ];

    const CustomerDatagrid = () => (
        <Datagrid bulkActionButtons={false}>
            <TextField source="name" label="Nome" />
            <PartiallyHiddenCpfField source="cpf" label="CPF" />
        </Datagrid>
    );

    return (
        <List
            resource="customers"
            title=" - Clientes"
            filterDefaultValues={{ ...(record.tagIds?.length ? { tagIds: record.tagIds } : {}), chainId: record.chainId }}
            filters={listFilters}
            exporter={false}
        >
            <CustomerDatagrid />
        </List>
    );
}

const NotificationList = (props) => {
    const classes = useStyles();

    const Panel = () => (
        <ArrayField source="dispatchMessages" label="">
            <Datagrid bulkActionButtons={false}>
                <FunctionField render={record => record && DispatchMessageType.translateType(record.type)} label="Tipo" />
                <NumberField source="cardinality" label="Total" textAlign="center" />
                <FunctionField render={record => record && DispatchMessageStatus.translateType(record.status)} label="Status" textAlign="center" />
            </Datagrid>
        </ArrayField>
    );

    const NotificationDatagrid = () => (
        <Datagrid
            expand={<Panel />}
            empty={<Typography textAlign="center">Sem notificações</Typography>}
            bulkActionButtons={false}
        >
            <TextField source="title" label="Título" />
            <RichTextField source="body" label="Mensagem" className={classes.richText} />
            <TextField source="pushMessage" label="Mensagem Push" />
            {/* <TextField source="smsMessage" label="Messagem sms" /> */}
            <DateField source="sendAt" label="Envio às" showTime />
            <FunctionField render={record => record && record.status} />
        </Datagrid>
    );

    return (
        <ArrayField source="giftMarketingCampaigns" label="Notificações" emptyText="Sem notificações">
            <NotificationDatagrid />
        </ArrayField>
    );
}

const ProductBox = () => {
    const record = useRecordContext();

    return (
        <Box component="fieldset" sx={{ border: '1px dashed grey', marginTop: 2 }}>
            <Legend>Produto</Legend>
            {
                record.productId ?
                    <SimpleShowLayout sx={{ padding: 0 }}>
                        <ReferenceField source="productId" label="Nome" reference="chains/chainId/products">
                            <TextField source="name" emptyText="--" />
                        </ReferenceField>
                    </SimpleShowLayout>
                    :
                    <SimpleShowLayout sx={{ padding: 0 }}>
                        <TextField source="productName" label="Nome" />
                        <TextField source="productDescription" label="Descrição" />
                        <NumberField
                            source="productValue"
                            label="Preço"
                            options={{
                                style: 'currency',
                                currency: 'BRL',
                                minimumFractionDigits: 2,
                            }}
                        />
                        <ImageField source="productImagePath" label="Imagem" />
                    </SimpleShowLayout>
            }
        </Box>
    );
}

const PlaceAndLimitBox = () => {
    const record = useRecordContext();

    return (
        <Box component="fieldset" sx={{ border: '1px dashed grey', marginTop: 2 }}>
            <Legend>Locais de troca</Legend>
            <SimpleShowLayout sx={{ padding: 0 }}>
                <ReferenceArrayField label="Locais de troca" reference="places" source="placeIds">
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <ArrayField source="placeGifts" label="Códigos RTI">
                    <Datagrid bulkActionButtons={false} style={{ padding: 0 }}>
                        <ReferenceField source="placeId" reference="places" label="Local de troca">
                            <TextField source="name" />
                        </ReferenceField>
                        <TextField source="rtiCode" label="Código RTI" emptyText="--" />
                    </Datagrid>
                </ArrayField>
                <NumberField source="limitQuantity" label="Limite de resgate" emptyText="--" />
                <NumberField source="limitIntervalInHours" label="Intervalo de resgate (horas)" emptyText="--" />
                <SelectField choices={GiftExpirationType.giftExpirationTypeChoices} source="expirationType" label="Tipo de expiração" emptyText="--" />
                {
                    record.expirationType === GiftExpirationType.fixed &&
                    <DateField source="expirationTime" label="Data de expiração" emptyText="--" showTime />
                }
                {
                    record.expirationType === GiftExpirationType.time &&
                    <NumberField source="expirationInHours" label="Intervalo de expiração" emptyText="--" />
                }
            </SimpleShowLayout>
        </Box>
    );
}

const ScheduledBox = () => {
    const record = useRecordContext();

    record.isSchedule = !!record.frequency;

    return (
        <Box component="fieldset" sx={{ border: '1px dashed grey', marginTop: 2 }}>
            <Legend>Agendamento</Legend>
            <SimpleShowLayout record={record} sx={{ padding: 0 }}>
                <BooleanField source="isSchedule" label="Agendamento ativo" />
                <FunctionField render={record => (record && record.startAt) ? moment(record.startAt).format('DD/MM/YYYY HH:mm') : '--'} label="Data inicial" />
                <FunctionField render={record => (record && record.endAt) ? moment(record.endAt).format('DD/MM/YYYY HH:mm') : '--'} label="Data final" />
                <FunctionField render={record => (record && record.frequency) ? frequencyChoices.find(dt => dt.id === record.frequency)?.name : '--'} label="Frequência" />
                {(record.frequency === 'weekly') &&
                    <ArrayField source="dayScheduledGifts" label="Horários">
                        <Datagrid bulkActionButtons={false}>
                            <FunctionField render={record => record && daysOfTheWeek.find(wd => wd.id === record.dayOfTheWeek)?.name} label="Dia" />
                            <FunctionField render={record => record && record.startTime ? moment(record.startTime).format('HH:mm') : '--'} label="Inicio" />
                            <FunctionField render={record => record && record.endTime ? moment(record.endTime).format('HH:mm') : '--'} label="Fim" />
                        </Datagrid>
                    </ArrayField>
                }
                {
                    (record.frequency === 'daily') &&
                    <FunctionField render={record => (record && record.dayScheduledGifts && record.dayScheduledGifts[0].startTime) ? moment(record.dayScheduledGifts[0].startTime).format('HH:mm') : '--'} label="Hora de inicio" />
                }
                {
                    (record.frequency === 'daily') &&
                    <FunctionField render={record => (record && record.dayScheduledGifts && record.dayScheduledGifts[0].endTime) ? moment(record.dayScheduledGifts[0].endTime).format('HH:mm') : '--'} label="Hora de fim" />
                }
            </SimpleShowLayout>
        </Box>
    );
}

const GiftShow = (props) => {

    const [chainId] = useState(getChainId());
    if (!chainId) { return <Typography>Selecione uma Rede</Typography>; }

    const { data: chain } = useGetOne('chains', { id: chainId });
    const { permissions } = usePermissions();

    return (
        <CheckChainUsesModule module={ModuleKey.GIFT}>
            <Permission permission={PERMISSIONS.GIFT}>
                <Show title='Brinde' {...props} sx={{ marginBottom: 5 }}>
                    <TabbedShowLayout >
                        <Tab label="Resumo">
                            <GiftDisableButton style={{ marginTop: 10 }} isOutlined />
                            <CloneGiftButton style={{ marginBottom: 10 }} />
                            <TextField source="identify" label="Código" />
                            <ReferenceField source="createdByEmployeeId" reference="employees" label="Criado por" link={permissions && permissions.includes(PERMISSIONS.UPDATE_USER)}>
                                <FunctionField render={record => record ? `${record.name} ${record.id === getUserId() ? '(você)' : ''}` : ''} />
                            </ReferenceField>
                            <TagListField />
                            <ProductBox />
                            <PlaceAndLimitBox />
                            <ScheduledBox />
                        </Tab>
                        <Tab label="Regras">
                            <RuleList chain={chain} />
                        </Tab>
                        <Tab label="Clientes">
                            <CustomerList />
                        </Tab>
                        <Tab label="Notificações">
                            <Box sx={{ paddingTop: 2, paddingBottom: 5 }}>
                                <NotificationList />
                            </Box>
                        </Tab>
                    </TabbedShowLayout>
                </Show>
            </Permission>
        </CheckChainUsesModule>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
    },
    richText: {
        '& img': {
            maxWidth: 300,
            maxHeght: 300,
        }
    },
}));

const Legend = styled('legend')({
    paddingLeft: 12,
    paddingRight: 12,
});

export default GiftShow;
