import {
  ChipField,
  CreateButton,
  Datagrid,
  DeleteButton,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextField,
  TopToolbar,
  usePermissions
} from "react-admin";
import ImportCSVButton from '../../components/ImportCSVButton';
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';

const ListActions = props => {
  const { className } = props;

  return (
    <TopToolbar className={className}>
      <CreateButton />
      <ImportCSVButton {...props} />
    </TopToolbar>
  );
};

const AttendantFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn />
    <ReferenceInput source="placeId" reference="stations" perPage={9999} sort={{ field: "name", order: "ASC" }} alwaysOn>
      <SelectInput label="Posto" optionText="name" emptyText="Todos" />
    </ReferenceInput>
  </Filter>
);

const StationField = (props: {}) => {
  const { data } = props as { data: { id: string, placeId: string }[] };

  return (
    <>
      {Object.values(data).map(station => (
        <ReferenceField source="placeId" reference="stations" record={station}>
          <ChipField source="name" />
        </ReferenceField>)
      )}
    </>);
}

const AttendantList = (props) => {
  const { permissions } = usePermissions();

  return (
    <Permission permission={PERMISSIONS.LIST_ATTENDANT}>
      <List
        {...props}
        perPage={25}
        pagination={<ListPagination />}
        title="Frentistas"
        filters={<AttendantFilter />}
        actions={<ListActions />}
        sort={{ field: 'name', order: 'ASC' }}
        empty={false}
      >
        <Datagrid rowClick="show" bulkActionButtons={permissions && permissions.includes(PERMISSIONS.DELETE_ATTENDANT)}>
          <TextField source="name" label="Nome" />
          <TextField source="identfid" label="Identfid" />
          {/* <ReferenceManyField
          label="Posto"
          reference="station-attendants"
          target="id"
          sortable={false}
          >
          <StationField />
        </ReferenceManyField> */}
          <TextField source="code" label="Código" />
          <Permission permission={PERMISSIONS.CREATE_ATTENDANT}>
            <EditButton />
          </Permission>
          <Permission permission={PERMISSIONS.DELETE_ATTENDANT}>
            <DeleteButton />
          </Permission>
        </Datagrid>
      </List>
    </Permission>
  );
};

export default AttendantList;