export enum TagType {
  station = 'station',
  product = 'product',
  client = 'client',
}

export namespace TagType {
  export const translateType = (type: TagType): string => {
    switch (type) {
      case TagType.station:
        return "Posto";
      case TagType.client:
        return "Cliente";
      case TagType.product:
        return "Produto";
      default:
        return "";
    }
  };

  export const tagTypeChoices = Object.values(TagType).filter(x => typeof x === "string")
    .map(type => {
      return ({
        id: type,
        name: TagType.translateType(type)
      });
    });
};