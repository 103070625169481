import React from 'react';
import {
  DeleteWithConfirmButton, useNotify, useRecordContext, useRefresh,
} from 'react-admin';
import Permission from '../Permission';
import { PERMISSIONS } from '../../constants';
import { CouponOrigin } from '../../models';

const RemoveCouponButton = () => {
  const record = useRecordContext();
  if (!record) { return null; }

  const refresh = useRefresh();
  const notify = useNotify();

  const onSuccess = () => {
    notify('ra.notification.deleted', {
      type: 'info',
      messageArgs: { smart_count: 1 }
    });
    refresh();
  };

  return (
    <>
      {([CouponOrigin.manuallyAdded].includes(record.systemOrigin) && !record.redeemed) &&
        <Permission permission={PERMISSIONS.DELETE_COUPON}>
          <DeleteWithConfirmButton
            resource={`customers/${record.customerId}/coupons`}
            mutationOptions={{ onSuccess }}
            confirmTitle="Remover Cupom"
            label="Remover cupom"
            confirmContent="Tem certeza disso? Essa ação não pode ser revertida"
          />
        </Permission>
      }
    </>
  );
}

export default RemoveCouponButton;