import React from 'react'
import { TextInput } from 'react-admin'

const PriceInput = ({ source, label = "", precision = 2, style = {}, ...props }) => {
    return (
        <TextInput
            style={style}
            source={source}
            label={label}
            format={(value) => {
                if (!value || value === 0) {
                    return "R$ 0," + "0".repeat(precision);
                }
                return `R$ ${Number(value).toFixed(precision)}`;
            }}
            parse={(value) => {
                if (!value || value === '') {
                    return 0;
                }
                const onlyNumbers = +value.replace(/[^\d]/g, '');
                return onlyNumbers / Math.pow(10, precision);
            }}
            {...props}
        />
    );
}

export default PriceInput;
