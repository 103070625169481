import {
  BooleanInput,
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin'
import { Permission } from '../../components'
import { PERMISSIONS } from '../../constants'

export default props => (
  <Create {...props} title="Cadastrar nova permissão">
    <SimpleForm>
      <TextInput source="name" label="Nome" InputProps={{ autoComplete: 'off' }} validate={required()} />
      <TextInput source="label" label="Descrição" InputProps={{ autoComplete: 'off' }} validate={required()} />
      <ReferenceInput
        source="groupId"
        reference="permission-groups"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={null}>
        <SelectInput
          label="Grupo"
          optionText="name" />
      </ReferenceInput>
      <Permission permission={PERMISSIONS.ADMIN_PROFILE}>
        <BooleanInput source="isAdmin" label="Apenas para usuário Admin" />
      </Permission>
    </SimpleForm>
  </Create>
)
