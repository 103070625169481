import { BooleanInput, Edit, SelectInput, SimpleForm, TextInput, required } from 'react-admin';
import { ColorInput, Permission } from '../../components';
import { PERMISSIONS } from '../../constants';
import { TagType } from '../../models';


const EditTag = (props) => {
  return (
    <Permission permission={PERMISSIONS.TAG}>
      <Edit {...props} title="Editar Etiqueta">
        <SimpleForm>
          <TextInput source="name" label="Nome" validate={[required()]} />
          <SelectInput
            label="Tipo"
            source="type"
            choices={TagType.tagTypeChoices}
            validate={[required()]}
          />
          <ColorInput source="color" label="Cor principal" />
          <Permission permission={PERMISSIONS.DRAWING}>
            <BooleanInput source="canRedeemLuckyNumber" label="Pode participar de sorteios" defaultValue />
          </Permission>
        </SimpleForm>
      </Edit>
    </Permission>
  );
};

export default EditTag;