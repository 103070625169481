import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import React from "react";
import { Datagrid, DateField, Filter, FormDataConsumer, FunctionField, NumberField, ReferenceField, ReferenceInput, SelectInput, TextField, TextInput, useListContext } from "react-admin";
import { PartiallyHiddenCpfField, PhoneField } from "../../components/Fields";
import ListPagination from "../../components/ListPagination";
import Permission from "../../components/Permission";
import ReportList from "../../components/ReportList";
import { PERMISSIONS } from "../../constants";
import { formatReal } from "../../lib";

const useStyles = makeStyles(theme => ({
  title: {
    margin: ".5rem 0 .5rem 0",
    textAlign: "center",
    fontWeight: "bold"
  },
  align: {
    textAlign: "center",
  },
}));

const CustomersWithoutDrawingList = () => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.title}>Relatório de Clientes sem Número da Sorte</Typography>
      <Datagrid bulkActionButtons={false} bulkActionButtons={false}>
        <TextField label="Nome" source="name" />
        <PartiallyHiddenCpfField label="CPF" source="cpf" />
        <PhoneField label="Telefone" source="cellphone" />
        <NumberField label="Total abastecido" source="amount" textAlign="center" options={{ maximumFractionDigits: 2 }} />
        <NumberField label="Números" source="numbers" textAlign="center" emptyText="-" />
        <NumberField label="Total restante" source="unusedAmount" textAlign="center" emptyText="-" options={{ maximumFractionDigits: 2 }} sortable={false} />
      </Datagrid>
    </>
  );
};

const CustomWithoutExporter = async (data) => {
  return data.map(({ name, cpf, cellphone, amount, numbers, unusedAmount }) => ({ Nome: name, CPF: cpf, Telefone: cellphone, totalAbastecido: amount, numeros: numbers, totalRestante: unusedAmount }));
};

const formatValueToDrawingRule = (drawingRuleFuel) => {
  if (!drawingRuleFuel.value) { return ''; }

  if (drawingRuleFuel.drawingRule && drawingRuleFuel.drawingRule.name === 'fillin-value') {
    return `- ${formatReal(drawingRuleFuel.value)}`;
  }

  return `- ${drawingRuleFuel.value}L`;
}

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  return (
    <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
      <ReferenceField
        label="Sorteio"
        source="drawingId"
        reference="chains/chainId/drawings"
      >
        <TextField
          source="name"
        />
      </ReferenceField>
      <ReferenceField
        label="Regra - Combustível - Valor"
        source="drawingRuleFuelId"
        reference="drawing-rule-fuels"
      >
        <FunctionField
          render={record => `${record.drawingRule.label} - ${record.fuel?.name || 'Todos'} ${formatValueToDrawingRule(record.value)}`}
        />
      </ReferenceField>
      <NumberField source="amountFrom" label="Litros Min." />
      <NumberField source="amountTo" label="Litros Máx." />
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
    </Datagrid>
  );
};

const FilterReportList = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput
        source="drawingId"
        reference="chains/chainId/drawings"
        sort={{ field: "name", order: "ASC" }}
        filter={{ status: 'not-finished' }}
        perPage={false}
        alwaysOn
        allowEmpty={false}
      >
        <SelectInput
          allowEmpty={false}
          label="Sorteio"
          style={{ minWidth: 180 }}
          optionText={'name'}
        />
      </ReferenceInput>
      <FormDataConsumer alwaysOn>
        {({ formData, ...rest }) =>
          formData.drawingId ?
            <ReferenceInput
              source="drawingRuleFuelId"
              reference="drawing-rule-fuels"
              filter={{ drawingId: formData.drawingId, accumulated: true }}
              sort={{ field: "startDate", order: "ASC" }}
              perPage={false}
              {...rest}
            >
              <SelectInput
                allowEmpty={false}
                label="Regra - Combustível - Valor"
                style={{ width: 300, marginTop: 0 }}
                optionText={record => `${record.drawingRule.label} - ${record.fuel?.name || 'Todos'} ${formatValueToDrawingRule(record)}`}
              />
            </ReferenceInput>
            : null
        }
      </FormDataConsumer>
      <TextInput
        style={{ width: 150 }}
        source="amountFrom"
        label="Litros Min."
        format={value => !value ? '' : `${Number(value).toFixed(2)}`}
        parse={value => !value ? 0 : +value.replace(/[^\d]/g, '') / 100}
        alwaysOn
      />
      <TextInput
        style={{ width: 150 }}
        source="amountTo"
        label="Litros Máx."
        format={value => !value ? '' : `${Number(value).toFixed(2)}`}
        parse={value => !value ? 0 : +value.replace(/[^\d]/g, '') / 100}
        alwaysOn
      />
    </Filter>
  );
};

const CustomersWithoutDrawing = (props) => {

  return (
    <Permission permission={PERMISSIONS.CUSTOMERS_WITHOUT_DRAWING_REPORT}>
      <ReportList
        {...props}
        basePath="report-customers-without-drawing"
        title="Clientes sem número da sorte"
        resource="chains/chainId/reports/customers-without-drawing"
        sort={{ field: "name", order: "ASC" }}
        perPage={25}
        filters={<FilterReportList />}
        pagination={<ListPagination />}
        bulkActionButtons={false}
        customExporter={CustomWithoutExporter}
        titleOnPrint="Relatório de clientes sem número da sorte"
        filterTablePrint={<FilterTablePrint />}
        landscape
      >
        <CustomersWithoutDrawingList />
      </ReportList>
    </Permission >
  );
};

export default CustomersWithoutDrawing;