import React from 'react';
import { Datagrid } from 'react-admin';
import { PartiallyHiddenCpfField } from '../../components/Fields';
import ListPagination from '../../components/ListPagination';
import { Permission, ReportList } from '../../components';
import { PERMISSIONS } from '../../constants';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
}));

const ReportCLientDeleted = (props) => {
  const classes = useStyles();
  return (
    <Permission permission={PERMISSIONS.CUSTOMERS_DELETED} >
      <ReportList
        {...props}
        exporter={false}
        bulkActionButtons={false}
        basePath='customers-deleted'
        resource='chains/chainId/reports/customers-deleted'
        title="Clientes excluídos"
        sort={{ field: 'createdAt', order: 'DESC' }}
        titleOnPrint="Clientes excluídos"
        perPage={25}
        pagination={<ListPagination />}
        landscape
      >
        <Datagrid bulkActionButtons={false}>
          <PartiallyHiddenCpfField source="cpf" label="CPF" textAlign="center" />
        </Datagrid>
      </ReportList>
    </Permission>
  );
};
export default ReportCLientDeleted;