import React from 'react';
import { Create, SimpleForm, required, TextInput } from 'react-admin';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { CNPJInput } from '../../components/Inputs';

const CompanyWithoutPointCreate = (props) => {
    return (
        <Permission permission={PERMISSIONS.MANAGE_COMPANY_WITHOUT_POINT}>
            <Create title='Adicionar CNPJ na exclusão' {...props}>
                <SimpleForm>
                    <TextInput source="name" label="Nome" />
                    <CNPJInput source="cnpj" label="CNPJ" validate={required()} />
                </SimpleForm>
            </Create>
        </Permission>
    );
};

export default CompanyWithoutPointCreate;
