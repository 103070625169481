import React from 'react';
import { Edit, SimpleForm, TextInput, required } from 'react-admin';

export default props => (
  <Edit {...props} title="Editar regra de sorteio">
    <SimpleForm>
      <TextInput source="name" label="Nome" InputProps={{ autoComplete: 'off' }} validate={required()} />
      <TextInput source="label" label="Descrição" InputProps={{ autoComplete: 'off' }} validate={required()} />
    </SimpleForm>
  </Edit>
)
