import StarsIcon from '@mui/icons-material/Stars';
import { PERMISSIONS } from '../../constants';
import list from './storesRatingList';

export default {
  list,
  name: 'stores-rating',
  permissions: [PERMISSIONS.LIST_RATINGS],
  options: { label: 'Avaliação nas lojas' },
  icon: StarsIcon,
};