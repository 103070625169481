import { CircularProgress } from '@mui/material';
import React, { MouseEventHandler, useCallback } from 'react';
import { Button, ButtonProps } from 'react-admin';
import { useFormContext, useFormState } from 'react-hook-form';

interface WizardButtonProps extends Omit<ButtonProps, 'onClick'> {
  onClick: (value: any) => void,
  isLoading?: boolean | null,
}

const WizardButton: React.FC<WizardButtonProps> = (props) => {
  const {
    color = 'primary',
    onClick,
    variant = 'contained',
    ...rest
  } = props;

  const form = useFormContext();
  const formState = useFormState(props as any);

  const handleSubmit = useCallback(
    async values => {
      if (onClick) {
        onClick(values);
      }
    },
    [onClick]
  );

  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    async event => {
      if (event.defaultPrevented) {
        return;
      }

      event.stopPropagation();
      await handleSubmit(form.getValues());
    },
    [form, handleSubmit]
  );

  return (
    <Button
      {...rest}
      disabled={props.isLoading || props.disabled}
      startIcon={props.isLoading ? <CircularProgress size={20} /> : props.startIcon}
      variant={variant}
      color={color}
      onClick={handleClick}
    />
  );
}

export default WizardButton;