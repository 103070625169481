import React from 'react'
import {
  Button, useDataProvider, useListContext, useRecordContext, useRefresh, useShowContext
} from 'react-admin';
import RemoveRedEye from '@mui/icons-material/RemoveRedEye';

const SupportTicketIsReadedButton = (props) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const record = useRecordContext();
  if (!record) { return null; }
  const { resource: resourceShow } = useShowContext();
  const { resource: resourceList } = useListContext();

  return (
    <Button
      style={{ marginTop: 15, marginBottom: 15 }}
      startIcon={props.enablesIcon ? <RemoveRedEye /> : null}
      label={record.isRead ? "Marcar como não lida" : "Marcar como lida"}
      variant={props.isOutlined ? "outlined" : undefined}
      onClick={() => {
        dataProvider.update(resourceShow || resourceList, { id: record.id, data: { isRead: !record.isRead }, previousData: { ...record } })
          .then(() => {
            refresh();
          })
          .catch(() => console.log("Erro na mensagem"));
      }}
    />
  );
};

export default SupportTicketIsReadedButton;