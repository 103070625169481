import React from 'react';
import {
  List,
  Filter,
  Datagrid,
  TextField,
  SearchInput,
  ImageField,
  EditButton,
  DeleteButton,
} from 'react-admin';
import { PERMISSIONS } from '../../constants';
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import { makeStyles } from '@mui/styles';

const ServiceFilter = props => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn />
  </Filter>
);

const listStyles = makeStyles({
  image: {
    '& img': {
      maxWidth: '4rem',
    },
  },
});

export default ({ permissions, ...props }) => {
  const classes = listStyles();

  return (
    <Permission permission={PERMISSIONS.ADMIN}>
      <List
        {...props}
        perPage={25}
        pagination={<ListPagination />}
        exporter={false}
        title="Serviços"
        filters={<ServiceFilter />}
        sort={{ field: 'createdAt', order: 'DESC' }}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source="name" label="Nome" />
          <TextField source="description" label="Descrição" />
          <ImageField className={classes.image} source="imagePath" label="Imagem" />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      </List>
    </Permission>
  );
}
