import {
  CreateButton,
  Datagrid,
  Filter,
  List,
  ReferenceField,
  SearchInput,
  TextField,
  TopToolbar
} from 'react-admin';
import ListPagination from '../../components/ListPagination';

const ListActions = props => {
  return (
    <TopToolbar>
      <CreateButton {...props} resource="system-users" />
    </TopToolbar>
  );
};

const UserFilter = props => {
  return (
    <Filter {...props}>
      <SearchInput source="search" alwaysOn />
    </Filter>
  );
};

export default props => (
  <List
    {...props}
    resource="employees"
    perPage={25}
    pagination={<ListPagination />}
    actions={<ListActions />}
    exporter={false}
    title="Usuários do sistema"
    filter={{ type: 'admin' }}
    filters={<UserFilter {...props} />}
  >
    <Datagrid rowClick={id => `/system-users/${id}`} bulkActionButtons={false}>
      <TextField source="name" label="Nome" />
      <TextField source="login" label="Login" />
      <ReferenceField
        source="profileId"
        reference="profiles"
        label="Perfil"
      >
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);
