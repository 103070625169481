import Contacts from '@mui/icons-material/Contacts';
import { PERMISSIONS } from '../../constants';
import show from './ClientShow';
import list from './ClientList';
import edit from './ClientEdit';
import create from './ClientCreate';

export default {
  show,
  list,
  edit,
  create,
  name: 'customers',
  permissions: [PERMISSIONS.LIST_CUSTOMER, PERMISSIONS.UPDATE_CUSTOMER, PERMISSIONS.CREATE_CUSTOMER],
  options: { label: 'Clientes' },
  icon: Contacts,
  hasShow: true,
  hasEdit: true,
}