import { makeStyles } from '@mui/styles';
import dayjs from "dayjs";
import { Datagrid, DateField, Filter, ReferenceInput, SelectInput, TextField, useListContext } from "react-admin";
import { Permission, ReportList } from "../../components";
import { BetweenDatesInput } from '../../components/BetweenDatesInput';
import ListPagination from '../../components/ListPagination';
import { PERMISSIONS } from "../../constants";
import { getChainId } from "../../lib";
import { Typography } from '@mui/material';
import RequiredChain from '../../components/RequiredChain';

const useStyles = makeStyles({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
});

const FilterTablePrint = () => {
  const { filterValues } = useListContext();
  return (
    <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: dayjs().toISOString() }]} total={1}>
      <DateField source="from" textAlign="center" label="De" sortable={false} />
      <DateField source="to" textAlign="center" label="Até" sortable={false} />
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
    </Datagrid>
  );
};

const ListFilters = () => (
  <Filter>
    {Permission({
      permission: PERMISSIONS.ADMIN,
      children: (
        <ReferenceInput
          reference="chains"
          source="chainId"
          style={{ minWidth: 220 }}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={false}
          alwaysOn
        >
          <SelectInput
            label="Rede"
            emptyText="Todos"
            optionText="name" />
        </ReferenceInput>
      )
    })}
    <BetweenDatesInput alwaysOn />
  </Filter>
)

const ReportDeviceManufacturer = (props) => {
  const classes = useStyles();
  return (
    <Permission permission={PERMISSIONS.DEVICE_MANUFACTURER_REPORT}>
      <RequiredChain>
        <ReportList
          {...props}
          basePath="report-device-manufacturer"
          title="Dispositivos por marca"
          resource="chains/chainId/reports/device-manufacturer"
          filters={<ListFilters />}
          pagination={<ListPagination />}
          filterTablePrint={<FilterTablePrint />}
          sort={{ field: 'devices', order: 'DESC' }}
          perPage={25}
          filterDefaultValues={{
            chainId: getChainId(),
            from: dayjs().startOf('day').subtract(30, 'days').toISOString(),
            to: dayjs().endOf('day').toISOString()
          }}
        >
          <>
            <Typography className={classes.title}>Dispositivos por marca</Typography>
            <Datagrid
              bulkActionButtons={false}
            >
              <TextField source="manufacturer" label="Marca" textAlign="center" />
              <TextField source="devices" label="Dispositivos" textAlign="center" />
            </Datagrid>
          </>
        </ReportList>
      </RequiredChain>
    </Permission>
  );
};

export default ReportDeviceManufacturer;