import { PERMISSIONS } from '../../constants';
import edit from './DrawingRuleEdit';
import list from './DrawingRuleList';
import create from './DrawingRuleCreate';
import Casino from '@mui/icons-material/Casino';

export default {
  create,
  list,
  edit,
  name: 'drawing-rules',
  permissions: [PERMISSIONS.ADMIN],
  options: { label: 'Regras de Sorteio' },
  icon: Casino,
}
