import { PERMISSIONS } from '../../constants'
import edit from './StationGroupEdit'
import list from './StationGroupList'
import create from './StationGroupCreate'

export default {
  create,
  list,
  edit,
  name: 'chains/chainId/station-groups',
  permissions: [PERMISSIONS.STATION_GROUPS],
}
