import React from 'react';
import { Button } from 'react-admin';
import DownloadIcon from '@mui/icons-material/GetApp';


interface Props extends React.HTMLAttributes<HTMLDivElement> {

    onExport: () => void;
  }

const CustomGenericButton = (props: Props) => {
  return (
    <Button label="EXPORTAR" onClick={props.onExport}>
        <DownloadIcon>EXPORTAR</DownloadIcon>
    </Button>
  );
}


export default CustomGenericButton;
