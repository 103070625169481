import { makeStyles } from '@mui/styles';
import DownloadIcon from '@mui/icons-material/GetApp';
import { saveAs } from 'file-saver';
import moment from 'moment';
import React from 'react';
import {
  Button, CreateButton, Datagrid, DateField, DeleteButton, EditButton, Filter, FunctionField, List, NumberField, SelectField,
  SelectInput,
  TextField,
  TopToolbar, useNotify, useRecordContext
} from 'react-admin';
import { BetweenDatesInput } from '../../components/BetweenDatesInput';
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { BillingStatus } from '../../models';
import { downloadBlob } from '../../utils';

const useStyles = makeStyles({
  buttonsDiv: {
    display: "flex",
    justifyContent: "space-around"
  }
});

const DownloadPaymentVoucherButton = (props) => {
  const record = useRecordContext(props);
  const notify = useNotify();

  const handleDownloadFile = async () => {
    try {
      await downloadBlob(record.paymentVoucherPath, `paymentVoucher-${record.id}`)
    } catch (_) {
      notify("Erro ao baixar arquivo", { type: "warning" });
    }
  };

  return (
    <Button label={props.label} startIcon={<DownloadIcon />} onClick={handleDownloadFile} />
  );
}

const ListActions = props => {
  const { className } = props;
  return (
    <TopToolbar className={className}>
      <CreateButton />
    </TopToolbar>
  );
}

const FilterList = ({ ...props }) => (
  <Filter {...props}>
    <BetweenDatesInput alwaysOn />
    <SelectInput
      source="status"
      label="Status"
      choices={BillingStatus.BillingStatusChoices}
      emptyText="Todos"
      alwaysOn
    />
  </Filter>
);

const OpenInvoiceButtons = props => {
  const record = useRecordContext();
  if (!record) { return null; }
  const classes = useStyles();

  return record.status === 'open' ? (
    <div className={classes.buttonsDiv}>
      <DeleteButton {...props} label="Remover" />
      <EditButton {...props} label="Alterar" />
    </div>
  ) : null;
};

export default ({ permissions, ...props }) => (
  <Permission permission={PERMISSIONS.BILLING}>
    <List
      {...props}
      perPage={25}
      pagination={<ListPagination />}
      exporter={false}
      bulkActionButtons={false}
      actions={<ListActions />}
      title="Faturas"
      filters={<FilterList {...props} />}
      filterDefaultValues={{
        from: moment().startOf("day").subtract(1, "month").toISOString(),
        to: moment().endOf("day").toISOString(),
      }}
    >
      <Datagrid
        rowClick={(_id, _resource, record) => record.status === BillingStatus.open ? "edit" : false}
        bulkActionButtons={false}
      >
        <NumberField source="number" label="Número da fatura" textAlign="center" />
        <NumberField
          source="totalValue"
          label="Valor total"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <DateField source="expiresAt" label="Data de vencimento" textAlign="center" />
        <SelectField
          source="status"
          label="Status"
          textAlign="center"
          choices={BillingStatus.BillingStatusChoices}
        />
        <FunctionField
          label="Comprovante"
          render={(record) => record && record.paymentVoucherPath ? <DownloadPaymentVoucherButton label="PDF" /> : "Sem registro"}
          textAlign="center"
        />
        <TextField
          source="chainNote"
          label="Observação da Rede"
          textAlign="left"
          emptyText="-"
          sortable={false}
          multiline
        />
        <OpenInvoiceButtons />
      </Datagrid>
    </List>
  </Permission >
);
