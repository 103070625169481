import moment from 'moment';
import { useEffect, useState } from 'react';
import {
    SearchInput,
    SimpleForm,
    useDataProvider,
    useNotify,
    usePermissions
} from 'react-admin';
import { Wrapper } from '../../components';
import { PERMISSIONS } from '../../constants';
import TransferTable from './TransferTable';

export default props => {
    const { isLoading: isLoadingPermissions, permissions } = usePermissions();
    const [search, setSearch] = useState(null);
    const [products, setProducts] = useState();
    const [loading, setLoading] = useState(false);
    const [places, setPlaces] = useState(null);
    const [originPlace, setOriginPlace] = useState(null);
    const [destinationPlace, setDestinationPlace] = useState(null);
    const [originDeposit, setOriginDeposit] = useState('warehouse');
    const [destinationDeposit, setDestinationDeposit] = useState('warehouse');
    const [date, setDate] = useState(moment());
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const fetchPlaces = async () => {
        setLoading(true)

        dataProvider.getList(`chains/chainId/places`, { sort: { field: 'name', order: 'ASC' } }).then(response => {
            const formattedResponse = response.data
                .map(({ id, name }) => ({ id, name }))
                .sort((a, b) => { return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0; });

            if (formattedResponse.length === 0) {
                notify('Nenhum Ponto de Troca encontrado', { type: 'warning' })
                setLoading(false)
                return
            }
            setLoading(false)
            setOriginPlace(formattedResponse[0].id)
            setDestinationPlace(formattedResponse[0].id)
            setPlaces(formattedResponse);
            return formattedResponse
        })
    };

    const fetchData = async () => {
        setLoading(true)
        const filter = { endDate: date }
        if (search) { filter.search = search }

        dataProvider.getList(`chains/chainId/places/${originPlace}/stock-balance`, { filter }).then(response => {
            setProducts(response.data);
            setLoading(false)
        })
    }

    const postData = async (date, transferredProducts) => {
        setLoading(true);
        dataProvider.create(`chains/chainId/places/${originPlace}/transfer-products`,
            {
                data: {
                    date,
                    transfers: transferredProducts,
                    transferPlaceId: destinationPlace,
                    transferStockType: destinationDeposit
                }
            }).then(() => {
                notify('Transferência de produtos realizada com sucesso');
                fetchData();
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                notify('Erro ao salvar transferência de produtos', { type: 'warning' })
            });
    }

    useEffect(() => {
        if (permissions && permissions.includes(PERMISSIONS.STOCK_TRANSFER)) {
            fetchPlaces();
        }
    }, [isLoadingPermissions])

    useEffect(() => {
        if (permissions && permissions.includes(PERMISSIONS.STOCK_TRANSFER) && originPlace) {
            fetchData();
        }
    }, [search, originPlace, date])

    return (
        <Wrapper>
            <SimpleForm toolbar={null}>
                <SearchInput
                    source="search"
                    variant="outlined"
                    onChange={event => {
                        if (event.target) { setSearch(event.target.value) }
                        else { setSearch(null) }
                    }}
                />
                {products &&
                    <TransferTable
                        products={products}
                        places={places}
                        origin={originPlace}
                        destination={destinationPlace}
                        originDeposit={originDeposit}
                        destinationDeposit={destinationDeposit}
                        saveCallback={({ date, transferredProducts }) => postData(date, transferredProducts)}
                        setOriginPlaceCallback={setOriginPlace}
                        setDestinationPlaceCallback={setDestinationPlace}
                        setOriginDeposit={setOriginDeposit}
                        setDestinationDeposit={setDestinationDeposit}
                        loading={loading}
                        date={date}
                        setDateCallback={date => setDate(date)}
                    />
                }
            </SimpleForm>
        </Wrapper>
    );
};