import RefreshIcon from '@mui/icons-material/Refresh';
import { ArrayField, Button, Datagrid, DateField, ExportButton, List, NumberField, ReferenceField, SelectField, SelectInput, Show, Tab, TabbedShowLayout, TextField, TopToolbar } from 'ra-ui-materialui';
import React from 'react';
import { BulkSendStatus } from '../../models';
import { useDataProvider, useGetRecordId, useNotify, useRecordContext } from 'react-admin';
import ListPagination from '../../components/ListPagination';
import ResendToAllButton from './ResendToAllButton';
import ResendButton from './ResendButton';

const ListActions = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  if (!record) { return null; }

  const hasScheduled = record.totalCustomerByStatus.some((cs) => cs.status === BulkSendStatus.scheduled && cs.total > 0)

  const updateStatus = () => {
    dataProvider.update('bulk-sends-status', { id: record.id, data: { chainId: record.chainId }, previousData: { type: record.type } })
      .then(() => {
        notify('O status está sendo atualizado');
      })
      .catch((error) => {
        notify(error.message, { type: 'error' });
      })
  }

  return (
    <TopToolbar>
      <Button
        label="Atualizar Status"
        startIcon={<RefreshIcon />}
        disabled={!hasScheduled}
        onClick={(e) => {
          e.stopPropagation();
          updateStatus();
        }}
      />
      <ResendToAllButton />
      <ExportButton />
    </TopToolbar>
  );
}

const ShowBulkSend: React.FC = (props) => {
  const id = useGetRecordId();

  const filters = [
    <SelectInput
      source="status"
      label="Status"
      choices={BulkSendStatus.bulkSendStatusChoices}
      emptyText="Todos"
      alwaysOn
    />,
  ];

  return (
    <Show title="Envio em massa" {...props}>
      <TabbedShowLayout>
        <Tab label="Resumo">
          <SelectField
            source="type"
            label="Tipo"
            choices={[
              { id: 'sms', name: 'SMS' },
            ]}
          />
          <TextField source="message" label="Mensagem" />
          <DateField source="createdAt" label="Data de criação" showTime />
          <ArrayField source="totalCustomerByStatus" label="Status de envio" >
            <Datagrid bulkActionButtons={false} style={{ maxWidth: 350 }}>
              <SelectField
                source="status"
                label="Status"
                choices={[
                  { id: 'all', name: 'Todos' },
                  ...(BulkSendStatus.bulkSendStatusChoices),
                ]}
              />
              <NumberField source="total" label="Total" />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Clientes">
          <List
            title=' '
            resource="bulk-send-customers"
            filter={{ bulkSendId: id }}
            filters={filters}
            actions={<ListActions />}
            perPage={25}
            pagination={<ListPagination />}
          >
            <Datagrid bulkActionButtons={false}>
              <ReferenceField source="customerId" label="Cliente" reference="customers">
                <TextField source="name" />
              </ReferenceField>
              <SelectField
                source="status"
                label="Status"
                choices={BulkSendStatus.bulkSendStatusChoices}
                textAlign="center"
              />
              <NumberField
                source="sendCount"
                label="Número de envios"
                textAlign="center"
              />
              <ResendButton />
            </Datagrid>
          </List>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

export default ShowBulkSend;