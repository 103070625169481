import TextField from '@mui/material/TextField'
import { FieldTitle, useInput, Validator } from 'ra-core'
import { useState } from 'react'
import * as ReactColor from 'react-color'
import './ColorInput.css'

interface ColorInputProps {
  resource?: string
  label?: string
  options?: {
    disableAlpha: true
  }
  source: string
  helperText?: string
  className?: string
  isRequired?: boolean
  validate?: Validator | Validator[] | undefined
  variant?: 'filled' | 'outlined' | 'standard'
  picker?:
  | 'Alpha'
  | 'Block'
  | 'Check'
  | 'Chrome'
  | 'Circle'
  | 'Compact'
  | 'Github'
  | 'Hue'
  | 'Material'
  | 'Photoshop'
  | 'Sketch'
  | 'Slider'
  | 'Swatches'
  | 'Twitter'
  | 'Custom'
}

export default (props: ColorInputProps) => {
  const [show, setShow] = useState(false)

  const {
    label,
    source,
    className,
    options,
    picker = 'Chrome',
    resource,
    helperText,
    validate,
    variant = 'standard',
  } = props

  const {
    field,
    fieldState: { isTouched, error },
    isRequired,
  } = useInput({
    ...props,
    validate,
  })

  const handleOpen = () => setShow(true)
  const handleClose = () => setShow(false)
  const handleChange = ({ hex }: any) => {
    field.onChange(hex)
  }

  const Picker = ReactColor[`${picker}Picker` as keyof typeof ReactColor]

  return (
    <div>
      <TextField
        {...field}
        margin="normal"
        variant={variant}
        onFocus={handleOpen}
        label={<FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />}
        error={!!(isTouched && error)}
        helperText={helperText}
        className={className}
      />
      {show ? (
        <div className="ColorInput-popup">
          {/* eslint-disable-next-line */}
          <div className="ColorInput-cover" onClick={handleClose} />
          <Picker {...options} color={field.value} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  )
}