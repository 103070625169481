import React from "react";
import {
  Datagrid,
  TextField,
  useListContext,
  Filter,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  ReferenceField,
  FunctionField,
  NumberField,
  BooleanField,
  ReferenceArrayField,
  DateField,
  SelectField,
  SingleFieldList,
  ChipField,
  ArrayField,
} from "react-admin";
import { DATE_FORMATER, PERMISSIONS } from "../../constants";
import { ReportList, Permission } from "../../components";
import { getChainId } from "../../lib";
import { BetweenDatesInput } from "../../components/BetweenDatesInput";
import { makeStyles } from "@mui/styles";
import { formatPoints } from "../../utils";
import moment from 'moment';

const discountChoices = [
  { id: "scheduledDiscount", name: "Agendado" },
  { id: "fixedDiscount", name: "Fixo" }
];

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold',
  },
}));

const FilterList = () => {
  const { filterValues } = useListContext();
  return (
    <Filter>
      <ReferenceArrayInput
        label="Posto"
        source="placeIds"
        reference="stations"
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
        perPage={null}
        style={{ minWidth: 160 }}
        alwaysOn
      >
        <SelectArrayInput
          optionText="name"
        />
      </ReferenceArrayInput>
      <SelectInput
        style={{ minWidth: 180 }}
        choices={discountChoices}
        label="Tipo de Desconto"
        source="typeDiscount"
        emptyText="Todos"
        alwaysOn
      />
      <ReferenceArrayInput
        label="Combustível"
        source="fuelIds"
        reference="fuels"
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
        alwaysOn
      >
        <SelectArrayInput
          style={{ minWidth: 180 }}
          optionText={'name'}
        />
      </ReferenceArrayInput>
      {
        (filterValues.typeDiscount === 'scheduledDiscount' &&
          <BetweenDatesInput alwaysOn />
        )
      }
    </Filter>
  );
};

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  return (
    <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
      <ReferenceArrayField label="Posto" source="placeIds" textAlign="center" reference="stations" link={false}>
        <SingleFieldList>
          <ChipField source="name" label="Posto" />
        </SingleFieldList>
      </ReferenceArrayField>
      <SelectField
        label="Tipo de Desconto"
        source="typeDiscount"
        textAlign="center"
        emptyText="Todos"
        choices={discountChoices}
      />
      <ReferenceArrayField reference="fuels" source="fuelIds" label="Combustível" link={false} sortable={false} >
        <SingleFieldList>
          <ChipField source="name" label="Posto" />
        </SingleFieldList>
      </ReferenceArrayField>
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
    </Datagrid>
  );
};


const ListRegisteredDiscounts = () => {
  const { data, resource, ids } = useListContext();
  const classes = useStyles();

  return (
    <Datagrid
      data={data}
      resource={resource}

      isRowExpandable={row => row.placeFuels.length > 0}
    >
      <TextField source="name" label="Posto" />

      <ArrayField source="placeFuels" label="Descontos Cadastrados" textAlign="center" sortable={false}>
        <Datagrid classes={{ table: classes.table }}>
          <ReferenceField source="fuelId" reference="fuels" label="Combustível" link={false} textAlign="center">
            <TextField source="name" textAlign="center" />
          </ReferenceField>
          <NumberField
            source="discount"
            label="Valor"
            options={{ style: 'currency', currency: 'BRL' }}
            emptyText="R$ 0,00"
            textAlign="center"
          />
          <FunctionField
            source="points"
            label="Pontos"
            render={record => formatPoints(+record?.points)}
            textAlign="center"
          />
          {/* <NumberField source="amount" label="Quantidade" textAlign="center" emptyText="0" /> */}
          {/* <BooleanField source="uniqueDiscountValue" label="Desconto Único" /> */}
          <FunctionField
            label="Tipo do desconto"
            textAlign="center"
            render={record => record && (record.type === 'default' ? "Padrão" : "Especial")}
          />
          <DateField source="startAt" options={DATE_FORMATER} />
          <DateField source="endAt" options={DATE_FORMATER} />
        </Datagrid>
      </ArrayField>
    </Datagrid>
  );
};

const ReportRegisteredDiscounts = (props) => {
  return (
    <Permission permission={PERMISSIONS.REGISTERED_DISCOUNTS_REPORT}>
      <ReportList
        {...props}
        title="Descontos Cadastrados"
        basePath="report-registered-discounts"
        resource="chains/chainId/reports/registered-discounts"
        filters={<FilterList />}
        filter={getChainId() ? { chainId: getChainId() } : null}
        sort={{ field: 'name', order: 'ASC' }}
        filterDefaultValues={{
          from: moment().startOf('day').subtract(1, "month").toISOString(),
          to: moment().endOf('day').toISOString()
        }}
        bulkActionButtons={false}
        titleOnPrint="Descontos Cadastrados"
        filterTablePrint={<FilterTablePrint />}
        landscape
        exporter={false}
      >
        <ListRegisteredDiscounts />
      </ReportList>
    </Permission>
  );
};

export default ReportRegisteredDiscounts;