import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  BulkDeleteButton,
  Filter,
  SearchInput
} from 'react-admin';
import ListPagination from '../../components/ListPagination';
import { PERMISSIONS } from '../../constants';
import Permission from '../../components/Permission';

const Filters = props => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn />
  </Filter>
);

const BulkActionButtons = props => (
  <BulkDeleteButton {...props} label="Inativar" />
);

export default props => (
  <Permission permission={PERMISSIONS.STATION_GROUPS}>
    <List {...props}
      perPage={25}
      pagination={<ListPagination />}
      exporter={false}
      filters={<Filters />}
      bulkActionButtons={<BulkActionButtons />}
      title="Grupos de Postos"
    >
      <Datagrid rowClick="edit">
        <TextField source="name" label="Nome" />
        <TextField source="description" label="Descrição" />
      </Datagrid>
    </List>
  </Permission>
);
