import SaveIcon from '@mui/icons-material/Save';
import IconCancel from '@mui/icons-material/Cancel';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { BooleanInput, Button, DateInput, FileField, FileInput, FormDataConsumer, SimpleForm, TextInput, Toolbar, required, useDataProvider, useListFilterContext, useNotify, useRedirect } from 'react-admin';
import { useFormState } from 'react-hook-form';
import GroupCoupon from '../GroupCoupon';
import { MAX_DATE } from '../../constants';
import dayjs from 'dayjs';

const SaveConfirmButton = ({ formData: { selectAllCoupons, ...fields }, ...props }) => {
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState();
  const { dirtyFields, isValidating, } = useFormState();
  const isDirty = Object.keys(dirtyFields).length > 0;

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect()

  const handleClick = () => {
    if (!fields.expiresAt) {
      notify('Data de vencimento é obrigatório', { type: 'warning' })
      return;
    }
    setConfirmDialog(true);
  };

  const handleCloseDialog = () => setConfirmDialog(false);

  const handleConfirmDialog = async (e) => {
    e.stopPropagation();

    setIsSubmitting(true);
    dataProvider.create("billings", {
      data: {
        ...fields,
        expiresAt: dayjs(fields.expiresAt).toISOString(),
        couponIds: props.selectedIds,
        totalValue: props.totalValue
      }
    })
      .then(() => {
        setIsSubmitting(false);
        notify("Fatura criada com sucesso.");
        redirect("list", "billings");
      })
      .catch((error) => {
        notify(error.message, { type: 'warning' });
        setIsSubmitting(false);
        handleCloseDialog();
      })
  }

  const checkFields = () => {
    return props.selectedIds.length === 0;
  }

  return (
    <>
      <Button
        onClick={handleClick}
        disabled={props.loading || !isDirty || isValidating || checkFields()}
        size="medium"
        variant="contained"
        startIcon={props.loading ? <CircularProgress size={20} /> : <SaveIcon />}
        label="Salvar"
      />
      <Dialog
        open={confirmDialog}
      >
        <DialogTitle>Cupons Resgatados</DialogTitle>
        <DialogContent>
          <GroupCoupon totalValue={props.totalValue} coupons={props.coupons} />
        </DialogContent>
        <DialogActions>
          <Button startIcon={<IconCancel />} disabled={isSubmitting} label="ra.action.cancel" onClick={handleCloseDialog} />
          <Button startIcon={isSubmitting ? <CircularProgress size={20} /> : <SaveIcon />} disabled={isSubmitting} label="ra.action.confirm" onClick={handleConfirmDialog} variant="contained" />
        </DialogActions>
      </Dialog>
    </>
  );
}

const CreateBillingBulkAction = ({ selectedIds, totalValue, coupons, ...props }) => {
  const { filterValues } = useListFilterContext();
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const ToolbarCustom = () => {
    return (
      <Toolbar sx={{ backgroundColor: 'white' }} >
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return <SaveConfirmButton
              {...rest}
              formData={formData}
              selectedIds={selectedIds}
              totalValue={totalValue}
              coupons={coupons}
              loading={loading}
            />
          }}
        </FormDataConsumer>
      </Toolbar>
    )
  }

  const markAllCoupons = ({ target: { checked } }) => {
    if (checked) {
      setLoading(true);
      dataProvider.getList("coupons", { filter: { ...filterValues }, pagination: { page: 1, perPage: false } })
        .then(({ data }) => {
          props.setSelectedIds(() => Array.from(new Set(data.map(({ id }) => id))));
          props.setTotalValue(data.reduce((acc, curr) => {
            return acc += curr.partnerValue;
          }, 0));
          props.setCoupons([...data]);
        }).catch(() => notify("Erro ao carregar cupons", { type: "error" }))
        .finally(() => setLoading(false));
    } else {
      props.setSelectedIds([]);
      props.setCoupons([]);
      props.setTotalValue(0);
    }
  }

  return (
    <SimpleForm
      {...props}
      style={{ padding: 15 }}
      toolbar={<ToolbarCustom />}
    >
      <DateInput
        source="expiresAt"
        type="date"
        label="Data de vencimento"
        options={{ format: "DD/MM/YYYY" }}
        inputProps={{ max: MAX_DATE }}
        validate={required()}
      />
      <FileInput
        source="files"
        label="Upload de NFe (Não adicionar boletos! A PontuaX não se responsabiliza pelos arquivos enviados.)"
        multiple
      >
        <FileField source="src" title="title" />
      </FileInput>
      <TextInput
        inputProps={{ autoComplete: 'off' }}
        source="partnerNote"
        label="Observação"
        multiline
        fullWidth
      />
      <BooleanInput disabled={loading} source="selectAllCoupons" label="Selecionar todos cupons" defaultValue={false} onChange={(e) => markAllCoupons(e)} />
    </SimpleForm>
  );
}

export default CreateBillingBulkAction;