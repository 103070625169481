import { PERMISSIONS } from '../../constants';
import Permission from '../../components/Permission';
import React from 'react'
import {
  Edit,
  TextInput,
  SimpleForm,
  required,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import { getChainId } from '../../lib';
import { CPFInput } from '../../components/Inputs';

const AttendantEdit = (props) => (
  <Permission permission={PERMISSIONS.UPDATE_ATTENDANT}>
    <Edit title='Editar frentista' {...props}>
      <SimpleForm defaultValues={{ chainId: getChainId() }}>
        <TextInput source="name" label="Nome" validate={required("Nome é obrigatório")} />
        <TextInput source="identfid" label="Identificador" validate={required("Identificador é obrigatório")} />
        <Permission permission={PERMISSIONS.ADMIN}>
          <ReferenceInput label="Rede" source="chainId" reference="chains">
            <SelectInput optionText="name" />
          </ReferenceInput>
        </Permission>
        <CPFInput source="cpf" label="CPF" isRequired />
      </SimpleForm>
    </Edit>
  </Permission>
);

export default AttendantEdit;