import React, { useState } from 'react';
import { getChainId } from '../../lib'
import {
    Button, useDataProvider, useRecordContext, useRefresh,
} from 'react-admin';
import { useNotify } from 'react-admin';
import Permission from '../Permission';
import { PERMISSIONS } from '../../constants';
import { TransactionType } from '../../models';

const RemovePointsButton = () => {
    const record = useRecordContext();
    if (!record) { return null; }

    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();

    const [isSubmitting, setIsSubmitting] = useState()

    const handleSubmit = () => {
        setIsSubmitting(true)

        dataProvider.delete(`chains/${getChainId()}/transactions/remove-added-points`, { id: record.id })
            .then(() => {
                notify('Pontos removidos com sucesso');
                setIsSubmitting(false);
                refresh();
            })
            .catch((e) => {
                console.error(e);
                setIsSubmitting(false)
                notify('Erro ao remover pontos');
            })
            .finally(() => { setIsSubmitting(false); });
    };

    return (
        <>
            {([TransactionType.adition, TransactionType.manualAddition].includes(record.type) && !record.is_canceled) &&
                <Permission permission={PERMISSIONS.REMOVE_ADDED_POINTS}>
                    <Button disabled={isSubmitting} onClick={handleSubmit} label="Remover pontos" />
                </Permission>
            }
        </>
    );
}

export default RemovePointsButton;