import React from "react";
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';
import { PartiallyHiddenCpfField } from "../../components/Fields";
import { IgnoreCustomerFraudButton } from "../../components/PotencialFraudActions";
import { Permission } from "../../components";
import { PERMISSIONS } from "../../constants";
import ListPagination from "../../components/ListPagination";

const CustomerFraudIgnored = () => {
  return (
    <Permission permission={PERMISSIONS.LIST_CUSTOMERS_IGNORED}>
      <List
        title="Clientes Ignorados"
        resource="customers"
        filter={{ ignoreFraud: true }}
        sort={{ field: 'ignoredFraudAt', order: 'DESC' }}
        perPage={25}
        pagination={<ListPagination />}
        exporter={false}
      >
        <Datagrid bulkActionButtons={false}>
          <ReferenceField
            label="Nome"
            reference="customers"
            source="id"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <PartiallyHiddenCpfField source="cpf" />
          <DateField source="ignoredFraudAt" label="Data" emptyText="--" textAlign="center" />
          <ReferenceField source="ignoredByEmployeeId" reference="employees" label="Ignorado por" emptyText="Não identificado" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <Permission permission={PERMISSIONS.IGNORE_CUSTOMER_FRAUD_LOG}>
            <IgnoreCustomerFraudButton />
          </Permission>
        </Datagrid>
      </List>
    </Permission>
  );
}

export default CustomerFraudIgnored;
