import { Typography } from '@mui/material';
import React from 'react';
import { ReferenceArrayInput, SelectArrayInput } from 'react-admin';

const FormCustomerGroup: React.FC<any> = ({ formData }) => (
  <>
    <ReferenceArrayInput
      sort={{ field: 'name', order: 'ASC' }}
      perPage={null}
      source="customerGroupIds"
      reference="chains/chainId/customer-groups"
    >
      <SelectArrayInput style={{ minWidth: 260 }} label="Grupos de clientes" optionText="name" disabled={!!formData.customerIds} />
    </ReferenceArrayInput>

    {
      formData.customerIds &&
      <Typography>Há {formData.customerIds.length} clientes selecionados pra esse desconto</Typography>
    }
  </>
);

export default FormCustomerGroup;