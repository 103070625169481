import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import { DashboardWrapper, RotatedAxisTick } from '../../components';
import { PERMISSIONS } from '../../constants';
import useDashboardContext from '../../hooks/useDashboardContext';
import { getChainId } from '../../lib';
import { getDatesInRange } from '../../utils';

export const AditionPointsChart = () => {
  const { permissions } = usePermissions();

  const [chartData, setChartData] = useState(null)
  const dataProvider = useDataProvider();
  const { from, to, chain, stationIds } = useDashboardContext();

  const notify = useNotify();
  const chainId = permissions && permissions.includes(PERMISSIONS.ADMIN) ? chain : getChainId();

  useEffect(() => {
    const filter = {
      from: moment.utc(from),
      to: moment.utc(to),
    };
    if (stationIds && stationIds.length > 0) { filter.placeIds = stationIds; }

    const data = [];
    getDatesInRange(from, to).map(date =>
      data.push(
        { id: date, aditionPoints: 0 }
      ));

    if (permissions && permissions.includes(PERMISSIONS.ADITION_POINTS)) {
      dataProvider.getList('dashboard/adition-points', { chainId, filter })
        .then(response => {
          const datesWithPoints = response.data;

          datesWithPoints.forEach(date => {
            const currDate = data.find(chartDate => chartDate.id === date.date);
            if (currDate) {
              currDate.aditionPoints = date.aditionpoints;
            }
          });
          setChartData(data);
        }).catch((e) => { notify('Erro ao carregar pontos gerados manualmente', { type: 'warning' }); });
    }
  }, [permissions, from, to, chain, stationIds]);

  return (
    <DashboardWrapper
      permission={PERMISSIONS.ADITION_POINTS}
      title='Pontos Adicionados Manualmente'
      width='45%'
      link='/report-added-points'
      content={
        <BarChart data={chartData}>
          <XAxis dataKey="id" tick={<RotatedAxisTick fontSize={12} />} height={60} />
          <YAxis width={40} domain={['auto', 'auto']} type='number' />
          <Tooltip />
          <CartesianGrid strokeDasharray="3 3" stroke="#DFE2E6" />
          <Bar dataKey="aditionPoints" fill="#82ca9d" barSize={50} name='Pontos Adicionados Manualmente' />
        </BarChart>
      }
    />
  )
}