import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RichTextInput } from 'ra-input-rich-text';
import React, { useEffect } from 'react';
import {
    BooleanInput,
    Create,
    DateTimeInput,
    FormDataConsumer,
    GetListParams,
    Labeled,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    maxLength,
    minValue,
    required,
    useDataProvider,
} from 'react-admin';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { addMinuteToDate, getChainId } from '../../lib';
import { RichTextInputCustom } from '../../components/RichTextInputCustom';

const editStyles = makeStyles({
    Toolbar: {
        justifyContent: 'space-between'
    },
    richText: {
        width: 'auto',
        '& .ql-editor': {
            minHeight: 200
        }
    },
    labeledWidth: {
        width: '70%'
    },
    charCounter: {
        top: '35%',
        right: '1%',
        width: 'auto',
        heigth: '100%',
        fontSize: 12,
        opacity: .75,
        fontFamily: 'Helvetica',
        textAlign: 'end',
    },
})

const CreateUnifiedSend: React.FC = ({ ...props }) => {
    const nowDatePlus10Minutes = () => addMinuteToDate(new Date(), 10)
    const classes = editStyles();

    const [clientCategories, setClientCategories] = React.useState<any[]>([]);
    const [clientGroup, setClientGroup] = React.useState<any[]>([]);
    const [stationGroup, setStationGroup] = React.useState<any[]>([]);
    const [inputUsed, setInputUsed] = React.useState<string>("")

    const [counterCharSms, setCounterCharSms] = React.useState(0);
    const [counterCharPush, setCounterCharPush] = React.useState(0);

    const MAX_LENGTH_SMS = 160;
    const MAX_LENGTH_PUSH = 130;

    const dataProvider = useDataProvider();

    useEffect(() => {
        dataProvider.getList('client-categories', { filter: {}, sort: { field: 'label', order: 'ASC' } } as GetListParams).then(({ data }) => {
            setClientCategories(data);
        });
        dataProvider.getList(`chains/${getChainId()}/customer-groups`, { filter: {}, sort: { field: 'name', order: 'ASC' } } as GetListParams).then(({ data }) => {
            setClientGroup(data);
        });
        dataProvider.getList(`chains/${getChainId()}/station-groups`, { filter: {}, sort: { field: 'name', order: 'ASC' } } as GetListParams).then(({ data }) => {
            setStationGroup(data);
        });
    }, []);

    const counterCharInput = (inputName: string, valueInput: string) => {
        const valueFormater = valueInput.length
        switch (inputName) {
            case 'sms':
                setCounterCharSms(valueFormater);
                break;

            case 'push':
                setCounterCharPush(valueFormater);
                break;

            default:
                break;
        }
    }

    const changeInputUsed = (inputNameUsed, value) => {
        value ? setInputUsed(inputNameUsed) : setInputUsed("");
    }

    const isDisabled = (value) => {
        return inputUsed === value || inputUsed === "" ? false : true;
    }

    const CreateToolbar = (props) => {
        return (
            <Toolbar {...props} className={classes.Toolbar}>
                <SaveButton />
            </Toolbar>
        );
    }

    return (
        <Permission permission={PERMISSIONS.CREATE_PRODUCT}>
            <Create
                {...props}
                title="Cadastrar novo envio único"
            >
                <SimpleForm toolbar={<CreateToolbar />}>
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }} >
                        <SelectInput
                            label='Categoria de Cliente'
                            source="clientCategoryId"
                            optionText="label"
                            optionValue="id"
                            choices={clientCategories}
                            style={{ width: '25%' }}
                            disabled={isDisabled('category')}
                            onChange={(e) => changeInputUsed('category', e.target.value)}
                        />
                        <p style={{ marginLeft: '1rem', marginRight: '1rem' }}>Ou</p>
                        <SelectInput
                            label="Grupo de Cliente"
                            source="customerGroupId"
                            optionText="name"
                            optionValue="id"
                            choices={clientGroup}
                            style={{ width: '25%' }}
                            disabled={isDisabled('group')}
                            onChange={(e) => changeInputUsed('group', e.target.value)}
                        />
                        <p style={{ marginLeft: '1rem', marginRight: '1rem' }}>Ou</p>
                        <SelectInput
                            label="Grupo de Postos"
                            source="stationGroupId"
                            optionText="name"
                            optionValue="id"
                            choices={stationGroup}
                            style={{ width: '25%' }}
                            disabled={isDisabled('station')}
                            onChange={(e) => changeInputUsed('station', e.target.value)}
                        />
                    </div>
                    <SelectInput
                        source="gender"
                        label="Sexo do Cliente"
                        choices={[
                            { id: 'F', name: 'Feminino' },
                            { id: 'M', name: 'Masculino' },
                        ]}
                        style={{ width: '25%' }}
                        emptyText="Todos"
                    />
                    <BooleanInput source="sendNow" label="Enviar agora" />
                    <FormDataConsumer>
                        {({ formData }) => {
                            if (formData.sendNow) {
                                return (
                                    <Typography variant="subtitle1" gutterBottom>
                                        A notifição pode demorar até 10 minutos para ser enviada.
                                    </Typography>
                                )
                            }
                        }}
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData }) => {
                            if (!formData.sendNow) {
                                return <DateTimeInput
                                    source={"sendAt"}
                                    label="Data de Envio"
                                    validate={[required(), minValue(
                                        nowDatePlus10Minutes(),
                                        'A hora de envio deve ser no mínimo 10 minutos apartir de agora',
                                    )]}
                                />
                            }
                        }}
                    </FormDataConsumer>
                    <TextInput source="title" label="Título" validate={[required(), maxLength(65)]} InputProps={{ autoComplete: 'off' }} />
                    <div className={classes.richText}>
                        <Labeled label="Descrição: ">
                            <RichTextInputCustom
                                source="body"
                                label="Essa mensagem pode conter formatações e será exibida no aplicativo, na tela de notificação. Ela NÃO será enviada na notificação PUSH e no SMS"
                                validate={required()}
                            />
                        </Labeled>
                    </div>
                    <Labeled label="Enviar via: ">
                        <>
                            {/* <BooleanInput source="sms" label="SMS" /> */}
                            <BooleanInput source="pushNotification" label="Notificação no aplicativo" />
                        </>
                    </Labeled>
                    <FormDataConsumer>
                        {({ formData }) => {
                            if (formData.sms) {
                                return (
                                    <Labeled label="Esta mensagem será enviada no SMS: " className={classes.labeledWidth}>
                                        <>
                                            <TextInput
                                                style={{ width: '100%' }}
                                                source="smsMessage"
                                                label="Mensagem SMS"
                                                validate={[required(), maxLength(MAX_LENGTH_SMS)]}
                                                inputProps={{ autoComplete: 'off' }}
                                                onChange={(e) => counterCharInput('sms', e.target.value)}
                                            />
                                            <span className={classes.charCounter}>{counterCharSms}/{MAX_LENGTH_SMS}</span>
                                        </>
                                    </Labeled>
                                );
                            }
                        }}
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData }) => {
                            if (formData.pushNotification) {
                                return (
                                    <Labeled label="Esta mensagem será enviada na notificação Push:" className={classes.labeledWidth}>
                                        <>
                                            <TextInput
                                                style={{ width: '100%' }}
                                                source="pushMessage"
                                                label="Mensagem Push"
                                                validate={[required(), maxLength(MAX_LENGTH_PUSH)]}
                                                inputProps={{ autoComplete: 'off' }}
                                                onChange={(e) => counterCharInput('push', e.target.value)}
                                                helperText={false}
                                            />
                                            <span className={classes.charCounter}>{counterCharPush}/{MAX_LENGTH_PUSH}</span>
                                        </>
                                    </Labeled>
                                );
                            }
                        }}
                    </FormDataConsumer>
                </SimpleForm>
            </Create>
        </Permission>
    )
}

export default CreateUnifiedSend
