export enum DispatchMessageOrigin {
  unifiedSend = "unified-send",
  chainMarketingCampaign = "chain-marketing-campaign",
  discountMarketingCampaign = "discount-marketing-campaign",
}

export namespace DispatchMessageOrigin {
  export const translateOrigin = (origin: DispatchMessageOrigin): string => {
    switch (origin) {
      case DispatchMessageOrigin.chainMarketingCampaign:
        return "Campanha";
      case DispatchMessageOrigin.discountMarketingCampaign:
        return "Desconto";
      case DispatchMessageOrigin.unifiedSend:
        return "Envio Único";
      default: return "";
    };
  };

  export const DispatchMessageOriginChoices = Object.values(DispatchMessageOrigin).filter(x => typeof x === "string")
    .map(type => {
      return ({
        id: type,
        name: DispatchMessageOrigin.translateOrigin(type)
      });
    });
};
