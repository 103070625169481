import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import ImageIcon from '@mui/icons-material/Image';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Button, GetListParams, RaRecord, useDataProvider } from 'react-admin';
import { USER_KEY } from '../../constants';
import getChainName from '../../lib/getChainName';

const useStyles = makeStyles({
  button: {
    color: 'white'
  },
  listItem: {
    padding: 0,
    paddingTop: 5,
    paddingBottom: 5,
  }
});

const ChainButton: React.FC = () => {

  const classes = useStyles();
  const dataProvider = useDataProvider();

  const [open, setOpen] = React.useState(false);
  const [chains, setChains] = useState<RaRecord[]>([]);
  const [search, setSearch] = useState<string>('');
  const [chainName, setChainName] = useState<string | null>(getChainName());

  useEffect(() => {
    dataProvider.getList('chains', { filter: {}, sort: { field: 'name', order: 'ASC' } } as GetListParams)
      .then(({ data }) => {
        if (data) {
          setChains(data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChainClick = (chain) => {
    const userLogged = JSON.parse(localStorage.getItem(USER_KEY) as string);
    userLogged.chainId = chain.id;
    userLogged.chainName = chain.name;
    userLogged.useQrcode = chain.useQrcode;
    localStorage.setItem(USER_KEY, JSON.stringify(userLogged));
    setChainName(chain.name);
    handleClose();
    window.location.reload();
  };

  const handleDefaultChainClick = () => {
    const userLogged = JSON.parse(localStorage.getItem(USER_KEY) as string);
    userLogged.chainId = null;
    userLogged.chainName = null;
    delete userLogged.useQrcode
    localStorage.setItem(USER_KEY, JSON.stringify(userLogged));
    setChainName("")
    handleClose();
    window.location.reload();
  }

  const getChains = () => {
    return chains.filter((chain) => chain.name.toLowerCase().includes(search));
  };

  return (
    <>
      <Button sx={{ color: 'white', padding: 1.5 }} label={chainName || 'Selecione uma rede'} className={classes.button} onClick={handleClickOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <TextField
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            label="Pesquisar rede"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <List>
            <ListItem className={classes.listItem} button onClick={handleDefaultChainClick}>
              <ListItemText style={{ marginLeft: 10 }} primary="TODAS" />
            </ListItem>
            {
              getChains().map((chain, index) => (
                <ListItem key={index} className={classes.listItem} button onClick={() => handleChainClick(chain)}>
                  {
                    chain.logoPath ?
                      <Avatar alt="Remy Sharp" src={chain.logoPath} />
                      :
                      <Avatar>
                        <ImageIcon />
                      </Avatar>
                  }
                  <ListItemText style={{ marginLeft: 10 }} primary={chain.name} />
                </ListItem>
              ))
            }
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ChainButton;