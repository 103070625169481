import { makeStyles } from '@mui/styles';
import { Fragment } from 'react';
import {
  ArrayInput,
  BooleanInput,
  Create,
  FormDataConsumer,
  FormTab,
  ImageField,
  ImageInput,
  Labeled,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  TimeInput,
  email,
  maxLength,
  usePermissions
} from 'react-admin';
import { CEPInput, CNPJInput, PhoneInput, RtiTokenInput, StateInput } from '../../components/Inputs';
import Permissions from '../../components/Permissions';
import { PERMISSIONS, daysOfTheWeek, minimumValueCashbackToolTip } from '../../constants';
import { TagType, ConcentratorBrandType } from '../../models';
import { FinancesStation } from './EditStation';
import { Permission, PriceInput, Tooltip } from '../../components';
import { Grid } from '@mui/material';

const useStyles = makeStyles(theme => ({
  credentialField: {
    display: 'block',
  },
  inputWrapperStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 0 20px 0',
  },
  input: {
    margin: '0 20px 0 0',
  },
  day: {
    fontSize: 16,
    margin: '20px 0'
  }
}));

const choices = [
  { id: 'company-tec', name: 'CompanyTec' },
  { id: 'ez-tec', name: 'EZTec' },
  { id: 'auto-system', name: 'AutoSystem' },
];

const required = (message = 'Required') => value =>
  value ? undefined : message;

const createStationEmailValidate = [email(), required()];

export const CreateStation = ({ ...props }) => {
  const { permissions } = usePermissions();
  const classes = useStyles();

  return (
    <Create title="Novo Posto" {...props}>
      <TabbedForm sanitizeEmptyValues>
        <FormTab label="Identificação">
          <ArrayInput source="numericIds" label="IDs Numéricos">
            <SimpleFormIterator
              disableReordering
              getItemLabel={() => null}

            >
              <NumberInput label={false} />
            </SimpleFormIterator>
          </ArrayInput>
          <Grid container lg={8} md={12} spacing={2}>
            <Grid item xs={12} md={6}>
              <TextInput source="name" label="Nome" validate={required()} fullWidth />
            </Grid>
            <Grid item xs={12} md={4}>
              <CNPJInput source="cnpj" label="CNPJ" validate={required()} fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                source="email"
                label="Email"
                type="email"
                validate={createStationEmailValidate}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PhoneInput source="phone" label="Telefone" fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput source="corporateName" label="Razão Social" validate={[maxLength(120)]} fullWidth />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInput source="stateRegistration" label="Incrição Estadual" validate={[maxLength(12)]} fullWidth />
            </Grid>
            {permissions && permissions.includes(PERMISSIONS.ADMIN) && (
              <Grid item xs={12} md={10}>
                <ReferenceInput
                  source="chainId"
                  reference="chains"
                  perPage={null}
                  sort={{ field: 'name', order: 'ASC' }}
                  validate={required()}
                >
                  <SelectInput label="Rede" optionText="name" fullWidth />
                </ReferenceInput>
              </Grid>
            )}
            <Grid item xs={12} md={5}>
              <ReferenceArrayInput
                sort={{ field: 'name', order: 'ASC' }}
                perPage={null}
                source="fuelIds"
                reference="fuels"
              >
                <SelectArrayInput label="Combustível" optionText="name" fullWidth />
              </ReferenceArrayInput>
            </Grid>
            <Grid item xs={12} md={5}>
              <ReferenceArrayInput
                sort={{ field: 'name', order: 'ASC' }}
                perPage={null}
                source="serviceIds"
                reference="services"
              >
                <SelectArrayInput label="Serviços" optionText="name" fullWidth />
              </ReferenceArrayInput>
            </Grid>
            <Grid item xs={12} md={5}>
              <FormDataConsumer>
                {({ formData }) => (
                  <ReferenceArrayInput
                    sort={{ field: 'name', order: 'ASC' }}
                    filter={{ type: TagType.station, chainId: formData.chainId }}
                    perPage={null}
                    source="tagIds"
                    reference="chains/chainId/tag"
                  >
                    <SelectArrayInput label="Etiquetas" optionText="name" fullWidth />
                  </ReferenceArrayInput>
                )}
              </FormDataConsumer>
            </Grid>
            {permissions && permissions.includes(PERMISSIONS.ADMIN) && (
              <Grid item xs={12} md={5}>
                <ReferenceArrayInput
                  sort={{ field: 'name', order: 'ASC' }}
                  perPage={null}
                  source="appPaymentMethodIds"
                  reference="app-payment-methods"
                >
                  <SelectArrayInput label="Método de pagamento" optionText="name" fullWidth />
                </ReferenceArrayInput>
              </Grid>
            )}
            <Grid item xs={12} md={5}>
              <RtiTokenInput
                label="Token RTI"
                source="rtiToken"
                fullWidth
              />
            </Grid>
            <Permissions permissions={[PERMISSIONS.ADMIN]}>
              <Grid item xs={12} md={5}>
                <TextInput
                  label="Token Fixpay"
                  source="fixpayToken"
                  fullWidth
                />
              </Grid>
            </Permissions>
          </Grid>
          <Permissions permissions={[PERMISSIONS.ADMIN]}>
            <BooleanInput
              label="Preset por Valor (Encher tanque)"
              source="presetUsingValue"
              defaultValue={false}
            />
          </Permissions>
          <Grid container lg={8} md={12} spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <BooleanInput label="Aceita cashback?" source="acceptsCashback" defaultValue={false} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Tooltip title={minimumValueCashbackToolTip} placement="top-start" sxContainer={{ display: "-webkit-box" }}>
                <PriceInput source="minimumValueToRedeemCashback" label="Mínimo para resgate de cashback" sx={{ minWidth: 220 }} />
              </Tooltip>
            </Grid>
          </Grid>
          <BooleanInput label="Usar preset no abastecimento" source="useFillinPreset" defaultValue />
          <BooleanInput label="Usar níveis de preço" source="usePriceTiers" defaultValue={false} />
          <BooleanInput label="Usar integração com Software House" source="useIntegrationSoftwareHouse" defaultValue={false} />
          <BooleanInput label="Usar Acompanhamento de Abastecimento" source="useFillinFollowUp" defaultValue />
          <ImageInput source="imagefile" label="Imagem" accept="image/*">
            <ImageField source="src" label="Imagem" />
          </ImageInput>
        </FormTab>
        <Permission permission={PERMISSIONS.ADMIN}>
          <FormTab label="Concetradores">
            <ArrayInput source="concentrators" label="IDs Numéricos">
              <SimpleFormIterator
                disableReordering
                getItemLabel={() => null}
              >
                <NumberInput source="numericId" label="Id numérico" style={{ width: '260px' }} />
                <SelectInput
                  validate={[required()]}
                  label="Sistema"
                  source="brandType"
                  choices={ConcentratorBrandType.concentratorBrandChoices}
                  style={{ width: '260px' }}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>
        </Permission>
        <FormTab label="Endereço">
          <Grid container lg={8} md={12} spacing={2}>
            <Grid item xs={12} md={8}>
              <TextInput source="street" label="Rua" validate={required()} fullWidth />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInput source="streetNumber" label="Número" validate={required()} fullWidth />
            </Grid>
          </Grid>
          <Grid container lg={8} md={12} spacing={2}>
            <Grid item xs={12} md={3}>
              <CEPInput source="cep" label="CEP" validate={required()} fullWidth />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextInput source="neighbourhood" label="Bairro" validate={required()} fullWidth />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextInput source="city" label="Cidade" validate={required()} fullWidth />
            </Grid>
            <Grid item xs={12} md={3}>
              <StateInput source="state" label="Estado" validate={required()} fullWidth />
            </Grid>
          </Grid>
          <Grid container lg={8} md={12} spacing={2}>
            <Grid item xs={12} md={3}>
              <NumberInput source="latitude" label="Latitude" validate={required()} fullWidth />
            </Grid>
            <Grid item xs={12} md={3}>
              <NumberInput source="longitude" label="Longitude" validate={required()} fullWidth />
            </Grid>
          </Grid>
        </FormTab>
        {permissions && permissions.includes(PERMISSIONS.ADMIN) && (
          <FormTab label='GPS'>
            <Labeled fullWidth label="Informe as coordenadas em 4 pontos que formam um retângulo" className={classes.title}>
              <FormDataConsumer>
                {({ formData: { coordinates } }) =>
                  <ArrayInput source='coordinates' label=''>
                    <SimpleFormIterator disableReordering disableAdd={coordinates && coordinates.length === 4}>
                      <NumberInput source="latitude" label="Latitude" validate={required()} />
                      <NumberInput source="longitude" label="Longitude" validate={required()} />
                    </SimpleFormIterator>
                  </ArrayInput>
                }
              </FormDataConsumer>
            </Labeled>
          </FormTab>)}
        {permissions && permissions.includes(PERMISSIONS.ADMIN) && (
          <FormTab label="Sincronização">
            <SelectInput
              label="Sistema"
              source="syncType"
              choices={choices}
              validate={[required()]}
              style={{ width: '260px' }}
            />
            <FormDataConsumer>
              {({ formData: { syncType } }) =>
                syncType === 'auto-system' && (
                  <>
                    <TextInput
                      source="credentials.host"
                      label="Host ou ip"
                      InputProps={{ autoComplete: 'off' }}
                      className={classes.credentialField}
                    />
                    <NumberInput
                      source="credentials.port"
                      label="Porta"
                      InputProps={{ autoComplete: 'off' }}
                      className={classes.credentialField}
                    />
                    <TextInput
                      source="credentials.user"
                      label="Usuário"
                      InputProps={{ autoComplete: 'nope' }}
                      className={classes.credentialField}
                    />
                    <TextInput
                      source="credentials.password"
                      label="Senha"
                      type="password"
                      InputProps={{ autoComplete: 'new-password' }}
                      className={classes.credentialField}
                    />
                    <TextInput
                      source="credentials.database"
                      label="Banco"
                      InputProps={{ autoComplete: 'off' }}
                      className={classes.itens}
                    />
                  </>
                )
              }
            </FormDataConsumer>
          </FormTab>)}
        <FormTab label="Horários">
          <ArrayInput source="openingHours" label={false} defaultValue={daysOfTheWeek.map(wd => ({ closed: true, dayOfTheWeek: wd.id }))}>
            <SimpleFormIterator disableAdd disableRemove disableReordering >
              <FormDataConsumer>
                {({ scopedFormData, getSource }) => {
                  return (
                    <p className={classes.day}>{scopedFormData && getSource && scopedFormData && daysOfTheWeek.find(wd => wd.id === scopedFormData.dayOfTheWeek).name}</p>
                  );
                }}
              </FormDataConsumer>
              <FormDataConsumer>
                {({ scopedFormData, getSource }) => {
                  return (
                    <div className={classes.inputWrapperStyle}>
                      <BooleanInput
                        source={getSource && getSource("closed")}
                        label='Fechado'
                        className={classes.input}
                      />
                      {scopedFormData && !scopedFormData.closed &&
                        <BooleanInput
                          source={getSource && getSource("open24hrs")}
                          label='Aberto 24 horas'
                          className={classes.input}
                        />
                      }
                      {scopedFormData && !scopedFormData.closed && !scopedFormData.open24hrs &&
                        <Fragment>
                          <TimeInput
                            record={scopedFormData}
                            variant='filled'
                            label='Hora de abertura'
                            source={getSource && getSource("startTime")}
                            style={{ margin: '0 20px 0 0' }}
                            fullDate
                          />
                          <TimeInput
                            record={scopedFormData}
                            variant='filled'
                            label='Hora de fechamento'
                            source={getSource && getSource("endTime")}
                            fullDate
                          />
                        </Fragment>
                      }
                    </div>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <Permission permission={PERMISSIONS.ADMIN}>
          <FormTab label="Financeiro">
            <FinancesStation />
          </FormTab>
        </Permission>
      </TabbedForm>
    </Create>
  );
}

export default CreateStation;
