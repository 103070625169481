import React from 'react';
import moment from 'moment';
import {
  List,
  Filter,
  Datagrid,
  NumberField,
  DateField,
  SelectField,
  SelectInput,
  ReferenceInput,
  ReferenceField,
  TextField,
  useRecordContext,
  useNotify,
  Button,
  FunctionField,
  EditButton,
  Labeled,
  useListContext,
} from 'react-admin';
import { BetweenDatesInput } from '../../components/BetweenDatesInput';
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { saveAs } from 'file-saver';
import DownloadIcon from '@mui/icons-material/GetApp';
import { makeStyles } from '@mui/styles';
import { downloadBlob, formatPoints } from '../../utils';
import { BillingStatus } from '../../models'

const useStyles = makeStyles({
  removeMargin: {
    marginTop: 0
  },
  filesList: {
    display: "flex",
    flexDirection: "column"
  }
});

const PaymentEditButton = () => {
  const record = useRecordContext();
  if (!record) { return null; }
  return (
    <EditButton resource="billings-payment" label='Pagar' record={record} disabled={record.status === BillingStatus.closed} />
  )
}

const DownloadFilesButton = (props) => {
  const record = useRecordContext(props);
  if (!record) { return null; }
  const notify = useNotify();

  const handleDownloadFile = async () => {
    try {
      await downloadBlob(props.path, `invoice-${props.index + 1}-${record.id}`);
    } catch (error) {
      notify(error.message || "Erro ao baixar arquivo", { type: "warning" });
    }
  };

  return (
    <Button label={props.label} startIcon={<DownloadIcon />} onClick={handleDownloadFile} />
  );
}

const CouponsPanel = () => {
  const record = useRecordContext();
  if (!record) { return null; }

  return (
    <List
      title=" "
      resource="coupons"
      basePath="coupons"
      sort={{ field: 'redeemedAt', order: 'ASC' }}
      filter={{ billingId: record.id }}
      pagination={false}
      perPage={0}
      actions={false}
      bulkActionButtons={false}
      disableSyncWithLocation
    >
      <Datagrid bulkActionButtons={false}>
        <FunctionField label="Produto" textAlign="center" render={record => record.isCashback ? "Cashback" : record.product.name || 'Não identificado'} />
        <FunctionField
          source="value"
          label="Pontos"
          textAlign="center"
          render={record => formatPoints(+record?.value)}
        />
        <NumberField
          source="partnerValue"
          label="Valor"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <DateField source="redeemedAt" label="Data do resgate" textAlign="center" showTime />
        <DateField source="createdAt" label="Data de geração" textAlign="center" showTime />
      </Datagrid>
    </List>
  );
};

const FilterList = ({ ...props }) => (
  <Filter {...props}>
    <BetweenDatesInput alwaysOn />
    <SelectInput
      source="status"
      label="Status"
      choices={BillingStatus.BillingStatusChoices}
      emptyText="Todos"
      alwaysOn
    />
    <ReferenceInput
      source="placeId"
      reference="chains/chainId/partners"
      sort={{ field: "name", order: "ASC" }}
      alwaysOn
      perPage={null}
    >
      <SelectInput
        label="Parceiro"
        emptyText="Todos"
        style={{ minWidth: 180 }}
        optionText={'name'}
      />
    </ReferenceInput>
  </Filter>
);

const BillingsDatagrid = () => {
  const { data, ids, resource } = useListContext();
  const classes = useStyles();

  return (
    <Datagrid
      resource={resource}
      data={data}
      bulkActionButtons={false}
      style={{ marginTop: 40 }}
      expand={<CouponsPanel />}
    >
      <NumberField source="number" label="Número da fatura" textAlign="center" />
      <NumberField
        source="totalValue"
        label="Valor total"
        textAlign="center"
        emptyText="R$ 0,00"
        options={{
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }}
      />
      <DateField source="expiresAt" label="Data de vencimento" textAlign="center" />
      <SelectField
        source="status"
        label="Status"
        textAlign="center"
        choices={BillingStatus.BillingStatusChoices}
      />
      <ReferenceField
        source="placeId"
        reference="chains/chainId/partners"
        label="Parceiro"
        textAlign="center"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField
        source="partnerNote"
        label="Observação do Parceiro"
        textAlign="center"
        emptyText="-"
        sortable={false}
      />
      <FunctionField
        label="Arquivos de NFe"
        textAlign="center"
        className={classes.filesList}
        render={(record) =>
          record.filePath && record.filePath.length ?
            record.filePath.map((path, i) => (
              <DownloadFilesButton label={`PDF ${i + 1}`} path={path} index={i} />
            )) :
            "Sem registro"
        }
      />
      <Labeled label=" " className={classes.removeMargin} >
        <PaymentEditButton />
      </Labeled>
    </Datagrid>
  );
}

export default ({ permissions, ...props }) => (
  <Permission permission={PERMISSIONS.BILLING_PAYMENT}>
    <List
      {...props}
      perPage={25}
      pagination={<ListPagination />}
      exporter={false}
      title="Contas a pagar"
      resource="billings"
      filters={<FilterList {...props} />}
      hasCreate={false}
      filterDefaultValues={{
        from: moment().startOf("day").subtract(1, "month").toISOString(),
        to: moment().endOf("day").toISOString(),
      }}
    >
      <BillingsDatagrid />
    </List>
  </Permission >
);
