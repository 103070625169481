import React from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Permission from '../Permission';
import { ResponsiveContainer } from 'recharts';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  filterRow: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: '18px',
    marginBottom: '10px',
  }
});

const FilterRow = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.filterRow}>
      {React.Children.map(children, child => (
        <div>{child}</div>
      ))}
    </div>
  );
};

const DashBoardChild: React.FC<{ title: string, filters?: any, content: any, aspectRatio: number, count?: number }> = ({ filters, title, content, aspectRatio, count = 1 }) => {
  const classes = useStyles();

  return (
    <Card variant="outlined" sx={{ borderRadius: 3 }}>
      <CardContent>
        {title && (
          <Typography className={classes.title} variant="h5" component="h2">
            {title}
          </Typography>
        )}
        <FilterRow>{filters}</FilterRow>
        {
          count > 0 ?
          <ResponsiveContainer aspect={aspectRatio ? aspectRatio : 1.0 / 1.0} >
          {content}
        </ResponsiveContainer>
          : <Typography>Ainda não há dados processados para essa visualização.</Typography>
        }

      </CardContent>
    </Card>
  )
};

const DashBoardWrapper: React.FC<{ title: string, permission: string, filters?: any, content: any, width?: string, link?: string, aspectRatio: number, count?: number }> = ({ filters, permission, title, content, link, aspectRatio, count }) => {
  const classes = useStyles();
  return (
    <Permission permission={permission}>
      {link ? (
        <Link to={link}>
          <DashBoardChild title={title} content={content} filters={filters} aspectRatio={aspectRatio}  count={count} />
        </Link>
      ) : (
        <DashBoardChild title={title} content={content} filters={filters} aspectRatio={aspectRatio} count={count} />
      )}
    </Permission>
  );
};

export default DashBoardWrapper;
