import { withStyles } from '@mui/styles';
import moment from "moment";
import * as React from "react";
import Barcode from "react-barcode";
import { getChainName } from '../../lib';
import { formatPartiallyHiddenCpf, formatPoints } from "../../utils";

const styles = () => ({
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0.5rem 0.3rem',
    fontSize: '1.4rem',
    border: '2px solid black',
    flexDirection: 'column',
    width: '10.13cm',
    backgroundColor: '#FFFFFF',
    fontFamily: 'Helvetica',
    fontWeight: 'bold',
    paddingBottom: '30px',
  },
  barcode: {
    width: '90%',
    margin: '0.6rem',
    display: 'flex',
    justifyContent: 'center',
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleLeft: {
    textAlign: 'left',
    paddingTop: '2rem',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    marginLeft: '30px',
  },
  titleRight: {
    textAlign: 'right',
    paddingTop: '2rem',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    marginRight: '30px',
  },
  centeredMessage: {
    width: '80%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '1.2rem',
    fontSize: '1.2rem',
    textAlign: 'center',
  },
  centeredInfo: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '0.6rem',
    fontSize: '1.7rem',
  },
  adress: {
    width: '80%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0.8rem',
    fontSize: '0.8rem',
    textAlign: 'center',
  },
  dateInfo: {
    width: '80%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0.8rem',
    fontSize: '0.9rem',
    textAlign: 'center',
  }
});

class Coupon extends React.PureComponent {
  render() {
    const { coupon, classes } = this.props;
    if (coupon) {
      const price = formatPoints(coupon.value);
      const place = coupon.place;
      const address = place ? `${place.street}, ${place.streetNumber} - ${place.city}-${place.state}` : '';

      return (
        <div className={classes.content}>
          <div className={classes.titleContainer}>
            <div className={classes.titleLeft}>PontuaX</div>
            <div className={classes.titleRight}>{getChainName()}</div>
          </div>
          <div className={classes.centeredMessage}>Produto Resgatado</div>
          <div className={classes.centeredInfo}>{coupon.isCashback ? `Cashback: ${Number(coupon.partnerValue).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}` : coupon.product?.isCash ? `Voucher no valor de ${Number(coupon.product.name).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}` : coupon.title || coupon.product?.name }</div>
          <div className={classes.centeredInfo} style={{ fontSize: '1.2rem', width: '90%' }}>{coupon.product ? coupon.product.description : coupon.description}</div>
          <div className={classes.centeredInfo}>{coupon.isGift ? 'Brinde' : `${price} pontos`}</div>
          <div className={classes.barcode}>
            <Barcode value={coupon.barcode} fontSize={18} width={2} />
          </div>
          {coupon.customer.name &&
            <div className={classes.centeredMessage}>Nome do cliente: {coupon.customer.name}</div>
          }
          {coupon.customer.cpf &&
            <div className={classes.centeredMessage}>CPF do cliente: {formatPartiallyHiddenCpf(coupon.customer.cpf)}</div>
          }
          <div className={classes.centeredMessage}>{coupon.place ? `Local de troca: ${place.name}` : ''}</div>
          <div className={classes.adress}>{coupon.place ? `${address}` : ''}</div>
          {
            coupon.redeemedAt &&
            <div className={classes.dateInfo}>Validado em: {moment(coupon.redeemedAt).format('DD/MM/YYYY - HH:mm')}</div>
          }
        </div>
      );
    } else {
      return null;
    }
  }
}

export default withStyles(styles)(Coupon);