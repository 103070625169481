import React from 'react'
import {
  Button, useDataProvider, useListContext, useRecordContext, useRefresh, useShowContext
} from 'react-admin';
import ImageEye from '@mui/icons-material/CheckBox';

const SupporTicketIsResolvedButton = (props) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const record = useRecordContext();
  if (!record) { return null; }
  const { resource: resourceShow } = useShowContext();
  const { resource: resourceList } = useListContext();

  return (
    <Button
      startIcon={props.enablesIcon ? <ImageEye /> : null}
      label={record.isResolved ? "Marcar como não resolvida" : "Marcar como resolvida"}
      variant={props.isOutlined ? "outlined" : undefined}
      onClick={() => {
        dataProvider.update(resourceShow || resourceList, { id: record.id, data: { isResolved: !record.isResolved }, previousData: { ...record } })
          .then(() => {
            refresh();
          })
          .catch(() => console.log("Erro na mensagem"));
      }}
    />
  );
};

export default SupporTicketIsResolvedButton;