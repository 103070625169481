import {
  Button, useDataProvider,
  useNotify,
  useRecordContext, useRefresh,
  useResourceContext
} from 'react-admin';

const DeleteRuleButton = (props) => {
  const dataProvider = useDataProvider();
  const resource = useResourceContext();
  const refresh = useRefresh();
  const notify = useNotify()

  const record = useRecordContext();
  if (!record) { return null; }

  return (
    <Button
      disabled={record?.isDeleted}
      label={record?.isDeleted ? "Limite deletado" : "Deletar limite"}
      variant={"outlined"}
      onClick={() => {
        dataProvider.delete(resource, { id: record.id })
          .then(() => {
            refresh();
          })
          .catch(() => notify("Erro ao deletar limite"))
      }}
    />
  );
};

export default DeleteRuleButton;