import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import {
	ArrayField,
	ChipField,
	Datagrid,
	DateField,
	FunctionField,
	NumberField,
	ReferenceArrayField,
	ReferenceArrayInput,
	ReferenceField,
	ReferenceInput,
	SelectArrayInput,
	SelectInput,
	SingleFieldList,
	TextField,
	useListContext,
	usePermissions
} from 'react-admin';
import { CustomizableDatagrid } from '../../components';
import { BetweenDatesInput } from '../../components/BetweenDatesInput';
import { CPFField } from '../../components/Fields';
import Permission from '../../components/Permission';
import ReportList from '../../components/ReportList';
import { PERMISSIONS } from '../../constants';
import { getChainId, getPlaceId } from '../../lib';
import { TransactionType } from '../../models';
import { formatPoints } from '../../utils';

const useStyles = makeStyles(theme => ({
	title: {
		margin: '10px 0 10px 0',
		textAlign: 'center',
		fontWeight: 'bold'
	},
}));

const transactionTypeChoices = Object.values(TransactionType).filter(x => typeof x === "string")
	.map(type => {
		return ({
			id: type,
			name: TransactionType.transalateType(type)
		})
	}).sort((a, b) => { return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0; });

const FillinsList = () => {
	const classes = useStyles();
	const props = useListContext();

	let transactionsData = [];
	let summaryData = [];

	if (props.data) {
		const fillinInfo = props.data[0];

		if (fillinInfo) {
			transactionsData = fillinInfo.data;
			summaryData = fillinInfo.summaryData;
		}
	}

	return (
		<>
			<Typography className={classes.title}>Totais</Typography>
			<Datagrid bulkActionButtons={false} style={{ marginBottom: 60 }} data={summaryData} total={1} hover={false}>
				<FunctionField
					textAlign="center"
					label="Tipo de Transação"
					sortable={false}
					render={record => record.id === "0" ? "Todos" : TransactionType.transalateType(record.transaction_type)}
				/>
				<FunctionField
					label="Total de Pontos"
					emptyText="0"
					textAlign="center"
					sortable={false}
					render={record => formatPoints(record.total_value)}
				/>
				<NumberField
					source="total_transactions"
					label="Total de Transações"
					textAlign="center"
					emptyText="0"
					sortable={false}
				/>
			</Datagrid>

			<Typography className={classes.title}>Lista Detalhada</Typography>
			<CustomizableDatagrid
				bulkActionButtons={false}
				resource={props.resource}
				data={transactionsData}
				hover={false}
			>
				<TextField label="Nome" source="customer_name" textAlign="center" sortable={false} />
				<CPFField
					source="customer_cpf"
					label="CPF"
					textAlign="center"
					sortable={false}
				/>
				<FunctionField
					label="Pontos"
					emptyText="0"
					textAlign="center"
					sortable={false}
					render={record => formatPoints(record.value)}
				/>
				<FunctionField
					textAlign="center"
					label="Tipo de Transação"
					render={record => TransactionType.transalateType(record.type)}
					sortable={false}
				/>
				<ReferenceField
					label="Local"
					textAlign="center"
					emptyText="Não identificado"
					source="place_id"
					reference="places"
					link="show"
					perPage={null}
					sortable={false}
				>
					<TextField source="name" />
				</ReferenceField>
				<ReferenceField
					label="Funcionário"
					textAlign="center"
					emptyText="Não identificado"
					source="employee_id"
					basePath="employees"
					reference="employees"
					perPage={null}
					sortable={false}
				>
					<TextField source="name" />
				</ReferenceField>
				<DateField source="created_at" label="Data" showTime sortable={false} />
			</CustomizableDatagrid>
		</>
	);
}

const transactionsExporter = async (data, fetchRelatedRecords) => {
	let transactionInfo = data[0].data;

	const relations = [
		{ field: 'place_id', resource: 'places' },
		{ field: 'employee_id', resource: 'employees' },
	]

	const fetchTransaction = await fetchRelatedRecords(relations, transactionInfo);

	const getData = (resource) => {
		return fetchTransaction.find(({ resource: res }) => res === resource)?.results || [];
	}

	transactionInfo = transactionInfo.map(transaction => {
		const { place_id, id, employee_id, chain_id, ...transactionRest } = transaction;
		const place = getData('places').find((place) => place.id === place_id);
		const employee = getData('employees').find((employee) => employee.id === employee_id);

		return {
			...transactionRest,
			place_name: place ? place?.name : 'Não identificado',
			employee_name: employee ? employee?.name : 'Não identificado',
		}
	});

	return transactionInfo;
}

const FilterTablePrint = () => {
	const classes = useStyles();
	const { filterValues } = useListContext();
	return (
		<Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
			<DateField source="from" textAlign="center" label="De" sortable={false} />
			<DateField source="to" textAlign="center" label="Até" sortable={false} />
			<ReferenceArrayField filter={{ chainId: getChainId() }} label="Locais" emptyText="Todos" source="stationIds" textAlign="center" reference="places" link={false}>
				<SingleFieldList>
					<ChipField source="name" />
				</SingleFieldList>
			</ReferenceArrayField>
			<ArrayField label="Tipo de Transação" source="transactionTypes" textAlign="center" link={false} >
				<Datagrid bulkActionButtons={false}>
					<FunctionField render={record => TransactionType.transalateType(record)} />
				</Datagrid>
			</ArrayField>
			<DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
		</Datagrid>
	);
}

const ReportFillins = (props) => {
	const { permissions } = usePermissions();

	const listFilters = [
		<BetweenDatesInput alwaysOn />,
		<ReferenceArrayInput
			filter={{ chainId: getChainId() }}
			source="stationIds"
			reference="places"
			perPage={null}
			sort={{ field: "name", order: "ASC" }}
			alwaysOn
		>
			<SelectArrayInput
				label="Local"
				style={{ minWidth: 180 }}
				optionText={'name'}
			/>
		</ReferenceArrayInput>,
		<SelectArrayInput
			style={{ minWidth: 180 }}
			source={"transactionTypes"}
			label="Tipo"
			choices={transactionTypeChoices}
			alwaysOn
		/>
	];

	const stationsIndex = listFilters.findIndex(({ props }) => props.reference === 'places')
	const placeId = getPlaceId();

	if (placeId && stationsIndex > -1) {
		listFilters.splice(stationsIndex, 1)
	}

	if (permissions && permissions.includes(PERMISSIONS.ADMIN) && stationsIndex > -1) {
		listFilters.splice(stationsIndex, 0,
			<ReferenceInput
				source="chainId"
				reference="chains"
				perPage={null}
				sort={{ field: "name", order: "ASC" }}
				alwaysOn
			>
				<SelectInput
					emptyText="Todos"
					style={{ minWidth: 180 }}
					optionText={'name'}
					label="Rede"
				/>
			</ReferenceInput>)
	}

	return (
		<Permission permission={PERMISSIONS.DETAILED_POINTS_REPORT}>
			<ReportList
				{...props}
				basePath="transactions"
				title="Pontos Detalhados"
				resource="chains/chainId/reports/detailed-points"
				filters={listFilters}
				sort={{ field: 'created_at', order: 'DESC' }}
				filterDefaultValues={{
					stationIds: placeId ? [placeId] : null,
					chainId: getChainId(),
					from: moment().subtract(30, 'd').startOf('day').toISOString(),
					to: moment().endOf('day').toISOString()
				}}
				bulkActionButtons={false}
				customExporter={transactionsExporter}
				titleOnPrint="Relatório de Pontos Detalhados"
				filterTablePrint={<FilterTablePrint />}
				landscape
			>
				<FillinsList />
			</ReportList>
		</Permission >
	);
};

export default ReportFillins;