import styled from "@emotion/styled";
import CalendarIcon from '@mui/icons-material/Event';
import { IconButton, InputAdornment } from "@mui/material";
import { DateTimeField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useInput, useNotify } from "react-admin";
import { CustomProvider, DateRangePicker } from 'rsuite';
import { addMonths, endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek, subDays } from "rsuite/esm/utils/dateUtils";
import * as locales from 'rsuite/locales';
import 'rsuite/dist/rsuite-no-reset.min.css';
import './DateRangePicker.css';

const BetweenInput = ({ BetweenComponent, dateTime, ...props }) => {
  const { style, options } = props;
  const fromField = useInput({ source: 'from' });
  const toField = useInput({ source: 'to' });
  const notify = useNotify();

  const format = dateTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy';

  const handleClick = () => {
    const input = document.querySelector('.rs-between-input.rs-picker');
    if (input) {
      input.click();
    }
  }

  const ranges = [
    {
      label: 'today',
      value: [startOfDay(new Date()), endOfDay(new Date())]
    },
    {
      label: 'Esta semana',
      value: [startOfWeek(new Date()), endOfWeek(new Date())]
    },
    {
      label: 'Última semana',
      value: [startOfWeek(subDays(new Date(), 7)), endOfWeek(subDays(new Date(), 7))]
    },
    {
      label: 'last7Days',
      value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())]
    },
    {
      label: 'Este mês',
      value: [startOfMonth(new Date()), endOfMonth(new Date())]
    },
    {
      label: 'Último mês',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))]
    },
    {
      label: 'Últimos 30 dias',
      value: [startOfDay(subDays(new Date(), 29)), endOfDay(new Date())]
    },
  ];

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="pt-br"
    >
      <CustomProvider locale={locales.ptBR}>
        <Container style={style}>
          <DateRangePicker
            className="rs-between-input"
            size="lg"
            format={format}
            value={[new Date(fromField.field.value), new Date(toField.field.value)]}
            shouldDisableDate={options?.disableFuture && DateRangePicker.afterToday()}
            onChange={([from, to]) => {
              if (dateTime) {
                if (from < to) {
                  fromField.field.onChange(dayjs(from).toISOString());
                  toField.field.onChange(dayjs(to).toISOString());
                } else {
                  fromField.field.onChange(dayjs(to).toISOString());
                  toField.field.onChange(dayjs(from).toISOString());
                }
              } else {
                fromField.field.onChange(dayjs(from).startOf('day').toISOString());
                toField.field.onChange(dayjs(to).endOf('day').toISOString());
              }
            }}
            ranges={ranges}
          />
        </Container>

        <Row>
          <DateTimeField
            label="De"
            value={dayjs(fromField.field.value)}
            format={dateTime ? 'L, HH:mm' : 'L'}
            onChange={(value) => {
              const year = value.get('year').toString();
              if (year.length < 4) {
                return;
              }
              if (value.toISOString() > toField.field.value) {
                notify(`A Data deve ser menor que ${dayjs(toField.field.value).format(dateTime ? 'L, HH:mm' : 'L')}`, { type: 'warning' });
                return;
              }
              if (dateTime) {
                fromField.field.onChange(value.toISOString());
              } else {
                fromField.field.onChange(value.startOf('day').toISOString());
              }
            }}
            maxDate={dayjs(toField.field.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClick}
                    edge="end"
                  >
                    <CalendarIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              )
            }}
            sx={{ width: dateTime ? 200 : 150 }}
          />
          <DateTimeField
            label="Até"
            value={dayjs(toField.field.value)}
            format={dateTime ? 'L, HH:mm' : 'L'}
            onChange={(value) => {
              const year = value.get('year').toString();
              if (year.length < 4) {
                return;
              }
              if (value.toISOString() < fromField.field.value) {
                notify(`A Data deve ser maior que ${dayjs(fromField.field.value).format(dateTime ? 'L, HH:mm' : 'L')}`, { type: 'warning' });
                return;
              }
              if (dateTime) {
                toField.field.onChange(value.toISOString());
              } else {
                toField.field.onChange(value.endOf('day').toISOString());
              }
            }}
            minDate={dayjs(fromField.field.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClick}
                    edge="end"
                  >
                    <CalendarIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              )
            }}
            sx={{ width: dateTime ? 200 : 150 }}
          />
        </Row>
      </CustomProvider>
    </LocalizationProvider>
  );
};

const Container = styled('div')({
  '& .rs-between-input>.rs-input-group-lg': {
    display: 'none',
  },
});

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: 16,
});

export const BetweenDatesTimesInput = props => <BetweenInput dateTime {...props} />;
export const BetweenDatesInput = props => <BetweenInput dateTime={false} {...props} />;
