import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import ListPagination from '../../components/ListPagination';

export default props => (
  <List {...props}
    perPage={25}
    pagination={<ListPagination />}
    exporter={false}
    bulkActionButtons={false}
    title="Regras de Sorteio"
  >
    <Datagrid rowClick="edit">
      <TextField source="name" label="Nome" />
      <TextField source="label" label="Descrição" />
    </Datagrid>
  </List>
);
