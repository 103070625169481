import { Box } from '@mui/material';
import React from 'react';
import { BooleanInput, ReferenceArrayInput, SelectArrayInput } from 'react-admin';

const FormPlaceAndFuel: React.FC<{ formData?: any }> = ({ formData }) => (
  <Box sx={{ display: 'inline-grid' }}>
    <BooleanInput source="selectsAllPlaces" label="Selecionar todos os postos" />
    {
      !formData.selectsAllPlaces &&
      <ReferenceArrayInput
        style={{ minWidth: 260 }}
        sort={{ field: 'name', order: 'ASC' }}
        perPage={null}
        source="stationIds"
        reference="chains/chainId/stations"
      >
        <SelectArrayInput label="Postos" optionText="name" />
      </ReferenceArrayInput>
    }

    <BooleanInput source="selectsAllFuels" label="Selecionar todos os combustíveis" />
    {
      !formData.selectsAllFuels &&
      <ReferenceArrayInput
        style={{ minWidth: 260 }}
        sort={{ field: 'name', order: 'ASC' }}
        perPage={null}
        source="fuelIds"
        reference="fuels"
      >
        <SelectArrayInput label="Combustíveis" optionText="name" />
      </ReferenceArrayInput>
    }
  </Box>
);

export default FormPlaceAndFuel;