export enum DispatchMessageStatus {
  scheduled = 'scheduled',
  canceled = 'canceled',
  dispatched = 'dispatched',
  dispatchedWithError = 'dispatched-with-error',
  failed = 'failed',
}

export namespace DispatchMessageStatus {
  export const translateType = (type: DispatchMessageStatus): string => {
    switch (type) {
      case DispatchMessageStatus.scheduled:
        return "Agendado";
      case DispatchMessageStatus.dispatched:
        return "Enviado";
      case DispatchMessageStatus.dispatchedWithError:
        return "Enviado com erro";
      case DispatchMessageStatus.failed:
        return "Falha";
      case DispatchMessageStatus.canceled:
        return "Cancelado";
      default:
        return "";
    }
  };
}
