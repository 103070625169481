import { ArrayField, ChipField, Datagrid, DateField, Filter, FormDataConsumer, FunctionField, List, ReferenceArrayInput, ReferenceField, ReferenceInput, SelectArrayInput, SelectInput, TextField, useListContext, useRecordContext } from 'react-admin';
import { CheckChainUsesModule, ModuleKey } from '../../components';
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import { PERMISSIONS, frequencyChoices } from '../../constants';
import { getChainId } from '../../lib';
import CloneGiftButton from './CloneGiftButton';


const PlaceField = () => {
    const { data } = useListContext();
    const values = Object.values(data);

    return (
        <>
            {values.slice(0, 4).map(place => (
                <ReferenceField source="placeId" reference="places" record={place} >
                    <ChipField sx={{ margin: '2px' }} source="name" />
                </ReferenceField>
            )
            )}
            <>{values.length > 4 ? <> mais {values.length - 4} locais{values.length - 4 > 1 ? 's' : ''}</> : ''}</>
        </>);
};

const TagField = (props: {}) => {
    const { data } = useListContext();
    const values = Object.values(data);

    return (
        <>
            {values.slice(0, 4).map((key, index) => (
                <ReferenceField source="tagId" reference="chains/chainId/tag" record={{ id: index, tagId: key }}>
                    <ChipField sx={{ margin: '2px' }} source="name" />
                </ReferenceField>)
            )}
            <>{values.length > 4 ? <> mais {values.length - 4} tag{values.length - 4 > 1 ? 's' : ''}</> : ''}</>
        </>
    );
};

const ProductField = (props) => {
    const record = useRecordContext(props);

    return (
        <>
            {
                record.productId ?
                    <ReferenceField source="productId" reference="chains/chainId/products" record={record}>
                        <TextField source="name" />
                    </ReferenceField>
                    :
                    <TextField source="productName" record={record} />
            }
        </>
    );
};

const GiftList = (props) => {

    const chainId = getChainId();

    const ListFilters = (props) => (
        <Filter {...props}>
            <SelectInput
                label="Status"
                source="isEnabled"
                choices={[{ label: 'Ativos', value: true }, { label: 'Inativos', value: false }]}
                optionText="label"
                optionValue="value"
                emptyText="Todos"
            />
            {
                Permission({
                    permission: PERMISSIONS.ADMIN, children:
                        <ReferenceInput
                            reference="chains"
                            source="chainId"
                            style={{ minWidth: 220 }}
                            defaultValue={getChainId()}
                            sort={{ field: 'name', order: 'ASC' }}
                            perPage={9999}
                        >
                            <SelectInput label="Rede" optionText="name" />
                        </ReferenceInput>
                })
            }
            <FormDataConsumer alwaysOn>
                {({ formData }) => (
                    <ReferenceArrayInput
                        reference="places"
                        source="placeIds"
                        sort={{ field: 'name', order: 'ASC' }}
                        perPage={9999}
                        style={{ minWidth: 220, marginBottom: -16 }}
                        filter={formData.chainId ? { chainId: formData.chainId } : {}}
                    >
                        <SelectArrayInput label="Locais de troca" optionText="name" />
                    </ReferenceArrayInput>
                )}
            </FormDataConsumer>
            <ReferenceArrayInput
                reference="fuels"
                source="fuelIds"
                style={{ minWidth: 220 }}
                sort={{ field: "name", order: "ASC" }}
                alwaysOn
            >
                <SelectArrayInput label="Combustíveis" optionText="name" />
            </ReferenceArrayInput>
        </Filter>
    );

    const filterDefaultValues: any = { isEnabled: true };
    if (chainId) {
        filterDefaultValues.chainId = chainId;
    }

    return (
        <CheckChainUsesModule module={ModuleKey.GIFT}>
            <Permission permission={PERMISSIONS.GIFT}>
                <List
                    {...props}
                    title="Brindes"
                    sort={{ field: 'createdAt', order: 'DESC' }}
                    filterDefaultValues={filterDefaultValues}
                    filters={<ListFilters />}
                    exporter={false}
                    pagination={<ListPagination />}
                    perPage={25}
                >
                    <Datagrid bulkActionButtons={false} rowClick="show">
                        <TextField source="identify" label="Código" />
                        <ProductField label="Produto" />
                        <DateField source="startAt" label="Data de início" showTime textAlign="center" emptyText="--" />
                        <DateField source="endAt" label="Data de fim" showTime textAlign="center" emptyText="--" />
                        <FunctionField
                            render={record => record && record.frequency ? frequencyChoices.find(dt => dt.id === record.frequency)?.name : '--'}
                            label="Frequência"
                            textAlign="center"
                        />
                        <FunctionField
                            render={record => record && record.isEnabled ? "Ativo" : "Inativo"}
                            label="Status"
                            textAlign="center"
                        />
                        <DateField source="disabledAt" label="Desativado em" showTime textAlign="center" emptyText="--" />
                        <TextField source="description" label="Descrição" emptyText="--" textAlign="center" />
                        <ArrayField label="Tags" source="tagIds" sortable={false}>
                            <TagField />
                        </ArrayField>
                        <ArrayField label="Locais de troca" source="placeGifts" sortable={false}>
                            <PlaceField />
                        </ArrayField>
                        <CloneGiftButton />
                    </Datagrid>
                </List>
            </Permission>
        </CheckChainUsesModule>
    );
};

export default GiftList;
