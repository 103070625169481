import React, { useState, useEffect } from 'react';
import { Wrapper } from '../../components';
import {
  useNotify,
  usePermissions,
  NumberInput,
  useDataProvider,
  FormDataConsumer,
  SimpleForm,
  ArrayInput,
  SimpleFormIterator,
  TextField,
  BooleanInput,
} from 'react-admin';
import { PERMISSIONS } from '../../constants';
import Permission from '../../components/Permission';

export default () => {
  const { isLoading: isLoadingPermissions, permissions } = usePermissions();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const patchRules = data => {
    setLoading(true);
    dataProvider
      .UPDATE(`chains/chainId/rules`, { data, previousData: initialValues })
      .then(() => notify('Regras salvas com sucesso'))
      .catch(() => notify('Erro ao salvar regras', { type: 'warning' }))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!isLoadingPermissions && permissions.includes(PERMISSIONS.SPECIAL_RULES)) {
      setLoading(true);
      dataProvider
        .getList(`chains/chainId/rules`, {})
        .then(({ data }) => setInitialValues({ rules: data }))
        .catch((err) => notify('Erro ao buscar regras', { type: 'warning' }))
        .finally(() => setLoading(false));
    }
  }, [isLoadingPermissions]);

  return (
    <Wrapper>
      <Permission permission={PERMISSIONS.SPECIAL_RULES}>
        <SimpleForm onSubmit={patchRules} saving={loading} defaultValues={initialValues}>
          <ArrayInput source="rules" label="">
            <SimpleFormIterator disableReordering disableAdd disableRemove>
              <BooleanInput label="Habilitar" source={"isActive"} />
              <FormDataConsumer>
                {({ formData, scopedFormData, getSource, ...rest }) => {
                  getSource && getSource('');
                  return (
                    <>
                      <TextField
                        source={"label"}
                        record={scopedFormData}
                      />
                    </>
                  );
                }}
              </FormDataConsumer>
              <NumberInput
                source="chainRules[0].value"
                label="Valor"
              />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Permission>
    </Wrapper>
  );
};

