import ConfirmationIcon from '@mui/icons-material/ConfirmationNumber'
import { PERMISSIONS } from '../../constants'
import list from './CouponSearch'

export default {
  list,
  name: 'refund-coupon',
  permissions: [PERMISSIONS.REFUND_COUPON],
  options: { label: 'Estornar Cupom' },
  icon: ConfirmationIcon,
}
