import React, { FC } from 'react';
import Icon from '@mui/icons-material/Stars';
import { makeStyles } from '@mui/styles';
import { FieldProps, useRecordContext } from 'react-admin';

const useStyles = makeStyles({
    root: {
        opacity: 0.87,
        whiteSpace: 'nowrap',
        display: 'flex',
    },
    large: {
        width: 20,
        height: 20,
    },
    small: {
        width: 15,
        height: 15,
    },
});

interface OwnProps {
    size?: 'large' | 'small';
}

const StarRatingField: FC<FieldProps & OwnProps> = ({
    source = 'rating',
    size = 'small',
}) => {
    const record = useRecordContext();
    const classes = useStyles();

    return record && record[source] ? (
        <span className={classes.root}>
            {Array(record[source])
                .fill(true)
                .map((_, i) => (
                    <Icon
                        key={i}
                        className={
                            size === 'large' ? classes.large : classes.small
                        }
                    />
                ))}
        </span>
    ) : <span>-</span>
};

export default StarRatingField;