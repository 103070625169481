import { makeStyles } from '@mui/styles';
import React, { Fragment } from 'react';
import { ArrayInput, BooleanInput, DateTimeInput, FormDataConsumer, SelectInput, SimpleFormIterator, TimeInput, required } from 'react-admin';
import { daysOfTheWeek, frequencyChoices } from '../../../constants';

const FormScheduleDiscount = () => {
  const classes = useStyles();

  return (
    <>
      <BooleanInput source="isSchedule" label="Ativar agendamento" defaultValue={false} />
      <FormDataConsumer>
        {({ formData }) => (
          <>
            <div className={classes.dateTimePicker}>
              <DateTimeInput
                source="startAt"
                label="Data inicial"
                disabled={!formData.isSchedule}
                validate={formData.isSchedule ? [required()] : []}
              />
              <DateTimeInput
                source="endAt"
                label="Data final"
                disabled={!formData.isSchedule}
                validate={formData.isSchedule ? [required()] : []}
              />
            </div>
            <SelectInput
              label="Frequência"
              source="frequency"
              alwaysOn
              style={{ marginTop: 10 }}
              choices={frequencyChoices}
              // onChange={({ target: { value } }) => setFrequency(value)}
              disabled={!formData.isSchedule}
              validate={formData.isSchedule ? [required()] : []}
            />
          </>
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) => {

          if (!formData.isSchedule) {
            return <></>;
          }

          if (formData.frequency === 'daily') {
            return (
              <div className={classes.dailyContainer}>
                <TimeInput
                  source="startTime"
                  label="Horário inicial"
                  style={{ width: 200 }}
                  isRequired
                  validate={[required()]}
                />
                <TimeInput
                  source="endTime"
                  label="Horário final"
                  style={{ width: 200 }}
                  isRequired
                  validate={[required()]}
                />
              </div>
            );
          };

          if (formData.frequency === 'weekly') {
            return (
              <ArrayInput source="weekSchedual" label="Defina os dias e horários">
                <SimpleFormIterator disableAdd disableRemove disableReordering >
                  <FormDataConsumer>
                    {({ scopedFormData, getSource }) => {
                      if (!scopedFormData) { return <></>; }

                      const day = daysOfTheWeek.find(wd => wd.id === scopedFormData.dayOfTheWeek);
                      return (
                        <>
                          <p className={classes.day}>{day?.name}</p>
                          <div className={classes.inputWrapperStyle}>
                            <BooleanInput
                              source={getSource ? getSource("isActive") : ''}
                              label={false}
                              className={classes.input}
                            />
                            {scopedFormData && scopedFormData.isActive &&
                              <Fragment>
                                <TimeInput
                                  label='Horário inicial'
                                  source={getSource && getSource("startTime")}
                                  style={{ marginRight: 20, width: 200 }}
                                  isRequired
                                />
                                <TimeInput
                                  label='Horário final'
                                  source={getSource && getSource("endTime")}
                                  style={{ marginRight: 20, width: 200 }}
                                  isRequired
                                />
                              </Fragment>
                            }
                          </div>
                        </>
                      );
                    }}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            );
          }
        }}
      </FormDataConsumer>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  dailyContainer: {
    display: 'flex',
    gap: 30,
    marginBottom: 20
  },
  dateTimePicker: {
    display: 'flex',
    gap: 20,
  },
  inputWrapperStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 0 20px 0',
  },
  input: {
    margin: '0 20px 0 0',
  },
  selectsAllPlacesButton: {
    marginTop: '20px',
  },
  day: {
    fontSize: 16,
    margin: '20px 0'
  },
  containerFuelDiscount: {
    padding: 0,
  }
}));

export default React.memo(FormScheduleDiscount);