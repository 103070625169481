import { useEffect, useState } from 'react';
import {
    SearchInput,
    SimpleForm,
    useDataProvider,
    useNotify,
    usePermissions
} from 'react-admin';
import { Wrapper } from '../../components';
import { getPlaceId } from '../../lib';
import InventoryTable from './InventoryTable';

export default ({ actionType }) => {
    const { isLoading: isLoadingPermissions, permissions } = usePermissions();
    const [search, setSearch] = useState(null);
    const [products, setProducts] = useState();
    const [loading, setLoading] = useState(false);
    const [places, setPlaces] = useState(null);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const fetchPlaces = async () => {
        setLoading(true)

        dataProvider.getList(`chains/chainId/places`, { sort: { field: 'name', order: 'ASC' } }).then(response => {
            const formattedResponse = response.data
                .map(({ id, name }) => ({ id, name }))
                .sort((a, b) => { return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0; });

            if (formattedResponse.length === 0) {
                notify('Nenhum Ponto de Troca encontrado', { type: 'warning' })
                setLoading(false);
                return
            }

            setLoading(false);
            setSelectedPlace(formattedResponse[0].id);
            setPlaces(formattedResponse);
        });
    }

    const fetchData = async () => {
        setLoading(true)
        const filter = { placeId: selectedPlace, stock: true }
        if (search) { filter.search = search }
        dataProvider.getList(`chains/chainId/products`, { filter }).then(response => {
            const products = response.data;
            if (selectedPlace) {
                products.forEach((p) => {
                    p.productPlaceInfo = p.productPlaces.find((pp) => pp.placeId === selectedPlace)
                })
            }
            setProducts(products);
            setLoading(false)
        });
    }

    const postData = async (date, changedProducts, reason) => {
        setLoading(true);
        const variables = {};

        if (actionType === 'entry') {
            variables.url = 'add-products';
            variables.errorMessage = 'Erro ao salvar entrada de produtos';
            variables.succesMessage = 'Entrada de produtos realizada com sucesso';
            variables.params = { date, additions: changedProducts }
        } else {
            variables.url = 'reduce-products';
            variables.errorMessage = 'Erro ao salvar baixa de produtos';
            variables.succesMessage = 'Baixa de produtos realizada com sucesso';
            variables.params = { date, reducions: changedProducts, reason }
        }

        dataProvider.create(`chains/chainId/places/${selectedPlace}/${variables.url}`, { data: variables.params }).then(response => {
            notify(variables.succesMessage);
            fetchData();
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            notify(variables.errorMessage, { type: 'warning' })
        })
    }

    useEffect(() => {
        if (!getPlaceId()) {
            fetchPlaces();
        } else if (permissions) {
            setSelectedPlace(getPlaceId())
        }
    }, [isLoadingPermissions])

    useEffect(() => {
        if (selectedPlace) {
            fetchData();
        }
    }, [search, selectedPlace])

    return (
        <Wrapper>
            <SimpleForm toolbar={null}>
                <SearchInput
                    source="search"
                    variant="outlined"
                    onChange={event => {
                        if (event.target) { setSearch(event.target.value) }
                        else { setSearch(null) }
                    }}
                />
                {products &&
                    <InventoryTable
                        products={products}
                        places={places}
                        placeId={selectedPlace}
                        saveCallback={({ date, changedProducts, reason }) => postData(date, changedProducts, reason)}
                        setSelectedPlaceCallback={setSelectedPlace}
                        loading={loading}
                        writeOff={actionType === 'writeOff'}
                        entry={actionType === 'entry'}
                    />
                }
            </SimpleForm>
        </Wrapper>
    );
};