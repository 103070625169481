import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React from 'react';
import { Datagrid, DateField, Filter, FunctionField, ReferenceArrayInput, ReferenceField, ReferenceInput, SelectArrayInput, SelectInput, TextField, useListContext, useListFilterContext } from 'react-admin';
import { CPFField, PhoneField } from "../../components/Fields";
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import ReportList from '../../components/ReportList';
import { PERMISSIONS } from '../../constants';
import { getChainId } from '../../lib';
import { formatPoints } from '../../utils';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
}));

const CustomersList = () => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.title}>Clientes</Typography>
      <Datagrid bulkActionButtons={false}>
        <TextField label="Nome" source="name" />
        <CPFField label="CPF" source="cpf" />
        <ReferenceField
          label="Saldo"
          source="id"
          reference="customer-balance"
          emptyText="0"
          sortable={false}
          link={false}
        >
          <FunctionField
            source="balance"
            render={record => formatPoints(+record?.balance)}
          />
        </ReferenceField>
        <PhoneField label="Telefone" source="cellphone" sortable={false} />
      </Datagrid>
    </>
  )
};

const customersExporter = async (data, fetchRelatedRecords) => {
  const relations = [
    { field: 'id', resource: 'customer-balance' },
  ]

  const fetchData = await fetchRelatedRecords(relations);

  const getData = (resource) => {
    const results = fetchData.find(({ resource: res }) => res === resource)?.results
    if (results) {
      return results.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
    }
    return []
  }
  const balances = getData('customer-balance');

  return data.map(({ id, name, cpf, cellphone, createdAt }) => ({
    id,
    nome: name,
    cpf,
    saldo: balances[id]?.balance || 0,
    telefone: cellphone,
    dataDeCriacao: moment(createdAt).format('DD/MM/YYYY HH:mm'),
  }));
}

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  return (
    <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
    </Datagrid>
  );
}

const ReportCustomers = (props) => {

  const ListFilters = () => {
    const { filterValues } = useListFilterContext();
    return (
      <Filter>
        <Permission permission={PERMISSIONS.ADMIN} alwaysOn>
          <ReferenceInput source="chainId" reference="chains">
            <SelectInput label="Rede" optionText="name" />
          </ReferenceInput>
        </Permission>
        {
          filterValues.chainId &&
          <ReferenceArrayInput
            source="customerGroupIds"
            reference="chains/chainId/customer-groups"
            perPage={null}
            sort={{ field: "name", order: "ASC" }}
            filter={{ chainId: filterValues.chainId }}
            alwaysOn
          >
            <SelectArrayInput
              label="Grupos de clientes"
              emptyText="Todos"
              style={{ minWidth: 180 }}
              optionText={'name'}
            />
          </ReferenceArrayInput>
        }
      </Filter>
    );
  }

  return (
    <Permission permission={PERMISSIONS.INACTIVE_CUSTOMERS_REPORT}>
      <ReportList
        {...props}
        basePath="report-customers"
        title="Clientes"
        resource="chains/chainId/reports/customers"
        filters={<ListFilters />}
        sort={{ field: 'created_at', order: 'DESC' }}
        perPage={25}
        filterDefaultValues={{
          chainId: getChainId(),
        }}
        pagination={<ListPagination />}
        bulkActionButtons={false}
        customExporter={customersExporter}
        titleOnPrint="Relatório de clientes"
        filterTablePrint={<FilterTablePrint />}
        isReport={false}
        landscape
        discountButton
      >
        <CustomersList />
      </ReportList>
    </Permission >
  );
};

export default ReportCustomers;