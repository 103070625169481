import React from 'react';
import { Grid } from '@mui/material';
import { ArrayInput, FormDataConsumer, ReferenceArrayInput, SelectArrayInput, SelectInput, SimpleFormIterator, TextInput, minValue, required, usePermissions } from 'react-admin';
import { PriceInput } from '../../../components';
import { getChainId } from '../../../lib';
import { GiftRuleType } from '../../../models';

const FormCondition: any = ({ fuels, chain, formData }: any) => {
  const chainId = getChainId();
  const { permissions } = usePermissions();

  return (
    <>
      <Grid container xs={12} md={12} lg={12} spacing={2}>
        <Grid item xs={12} md={12} display="flex" alignItems="center">
          <ArrayInput source="giftRules" label="Regras" defaultValue={{}} fullWidth>
            <SimpleFormIterator sx={{ '& section': { width: '100%' } }} disableReordering>
              <FormDataConsumer>
                {({ formData, scopedFormData, getSource }) => {
                  if (!scopedFormData) { return null; }

                  const fuelChoices = [
                    { id: 'all', name: 'Todos' },
                    ...fuels
                  ];

                  return (
                    <Grid container sx={{ marginTop: 1 }} xs={12} md={12} lg={10} spacing={1}>
                      <Grid item xs={12} md={3} lg={3}>
                        <SelectInput
                          label="Regra"
                          choices={GiftRuleType.giftRuleTypeChoices}
                          optionText="name"
                          defaultValue={GiftRuleType.fillin}
                          source={getSource('type')}
                          validate={[required()]}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={3} lg={3}>
                        <SelectInput
                          label="Combustível"
                          choices={fuelChoices}
                          source={getSource('fuelId')}
                          defaultValue={null}
                          format={value => value !== null ? value : 'all'}
                          parse={value => value !== 'all' ? value : null}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={3} lg={3}>
                        <SelectInput
                          label="Tipo do valor"
                          source={getSource('valueType')}
                          defaultValue={"volume"}
                          choices={[{ id: "volume", name: "Litros" }, { id: "cents", name: "Valor" }]}
                          validate={[required()]}
                          fullWidth
                        />
                      </Grid>
                      {
                        scopedFormData.valueType === 'cents' ?
                          <Grid item xs={12} md={3} lg={3}>
                            <PriceInput
                              source={getSource('value')}
                              label='Valor'
                              validate={[minValue(0)]}
                              fullWidth
                            />
                          </Grid>
                          :
                          <Grid item xs={12} md={3} lg={3}>
                            <TextInput
                              source={getSource('value')}
                              label='Litros'
                              defaultValue={0}
                              validate={[minValue(0)]}
                              format={value => (!value ? '0,00' : (+value).toFixed(2))}
                              parse={value => (!value ? 0 : +value.replace(/\D/g, '') / 100)}
                              required
                              fullWidth
                            />
                          </Grid>
                      }
                      <Grid item xs={12} md={12}>
                        <ReferenceArrayInput
                          sort={{ field: 'name', order: 'ASC' }}
                          //@ts-ignore
                          perPage={null}
                          source={getSource('stationIds')}
                          reference="stations"
                          isRequired
                          validate={[required()]}
                        >
                          <SelectArrayInput label="Postos" optionText="name" sx={{ width: '100%' }} fullWidth />
                        </ReferenceArrayInput>
                      </Grid>
                    </Grid>
                  )
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(FormCondition);