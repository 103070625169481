export enum BillingStatus {
  open = 'open',
  closed = 'closed'
}

export namespace BillingStatus {
  export const translateStatus = (type: BillingStatus): string => {
    switch (type) {
      case BillingStatus.open:
        return "Em Aberto";
      case BillingStatus.closed:
        return "Pago";
      default:
        return "";
    }
  };

  export const BillingStatusChoices = Object.values(BillingStatus).filter(x => typeof x === "string")
  .map(status => {
    return ({
      id: status,
      name: BillingStatus.translateStatus(status)
    });
  });
}