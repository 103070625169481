export default (data, keysToUpload) => {
  const body = new FormData()

  appendFiles(data, keysToUpload, body);

  Object.keys(data).map(key => {
    const value = data[key]
    if (value === null || value === undefined) {
      body.append(key, "");
    } else if (Array.isArray(value) || typeof value === 'object') {
      appendFiles(value, keysToUpload, body);
      body.append(key, JSON.stringify(value));
    } else {
      body.append(key, value);
    }
  });
  return body;
}

const appendFiles = (data, keysToUpload: string[], body: FormData) => {
  if (data && typeof data === 'object') {
    if (Array.isArray(data)) {
      data.forEach((item) => appendFiles(item, keysToUpload, body));
    } else {
      const dataKeys = Object.keys(data);
      const filtered = dataKeys.filter(_key =>
        keysToUpload.find(_keyToUpload => _keyToUpload === _key));
      for (const key of filtered) {
        const file = data[key];
        delete data[key];

        if (Array.isArray(file)) {
          file.forEach((_file) => {
            if ((_file.rawFile || _file) instanceof File) {
              body.append(key, (_file.rawFile || _file));
            }
          });
        }

        if (file && (file.rawFile || file) instanceof File) {
          body.append(key, (file.rawFile || file));
        }
      }

      dataKeys.forEach((_key) => {
        const value = data[_key];
        if (Array.isArray(value) || typeof value === 'object') {
          appendFiles(value, keysToUpload, body);
        }
      });
    }
  }
}