import React from 'react';
import { Edit, TextInput, required, SimpleForm, ImageInput, ImageField, NumberInput } from 'react-admin';
import { PERMISSIONS } from '../../constants';
import Permission from '../../components/Permission';
import { ImageInformations } from '../../components';

const ProductsCategoryEdit = props =>
    <Permission permission={PERMISSIONS.CREATE_PRODUCT_CATEGORIES}>
        <Edit {...props} title="Editar categoria de produto">
            <SimpleForm>
                <TextInput source="name" label="Nome" InputProps={{ autoComplete: 'off' }} validate={required()} />
                <ImageInput source="imagefile" label="Imagem" accept="image/*">
                    <ImageField source="src" label="Imagem" />
                </ImageInput>
                <ImageInformations position={-20}/>
                <NumberInput label="Posição na tela inicial do App" source="appIndex" />
            </SimpleForm>
        </Edit>
    </Permission>

export default ProductsCategoryEdit;
