import { makeStyles } from "@mui/styles";
import moment from "moment";
import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  Filter,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  SearchInput,
  SelectArrayInput,
  SingleFieldList,
  TextField,
  useListContext,
} from 'react-admin';
import { Permission, ReportList } from "../../components";
import ListPagination from "../../components/ListPagination";
import { PERMISSIONS } from "../../constants";

const useStyles = makeStyles(theme => ({
}));

const AttendantFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn />
    <ReferenceArrayInput style={{ minWidth: 180 }} source="placeIds" reference="stations" perPage={9999} sort={{ field: "name", order: "ASC" }} emptyText="Todos" alwaysOn>
      <SelectArrayInput optionText="name" label="Posto" />
    </ReferenceArrayInput>
  </Filter>
);

const StationField = (props) => {
  if (!props.data) { return <></>; }
  const { data } = props;

  return (
    <>
      {Object.values(data).map(station => (
        <ReferenceField source="placeId" reference="stations" record={station}>
          <ChipField source="name" />
        </ReferenceField>)
      )}
    </>);
};

const FilterTablePrint = () => {
  const { filterValues } = useListContext();
  return (
    <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
      <ReferenceArrayField source="placeIds" reference="stations" emptyText="Todos" textAlign="center" label="Posto" sortable={false} >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
    </Datagrid>
  );
};

const ReportAttendant = (props) => {
  return (
    <Permission permission={PERMISSIONS.ATTENDANT_REPORT}>
      <ReportList
        {...props}
        basePath="report-attendant"
        title="Frentistas"
        resource="chains/chainId/reports/attendant"
        perPage={25}
        pagination={<ListPagination />}
        filters={<AttendantFilter />}
        filterTablePrint={<FilterTablePrint />}
        bulkActionButtons={false}
        titleOnPrint="Relatório de Frentistas"
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source="name" label="Nome" textAlign="center" />
          <TextField source="identfid" label="Identfid" textAlign="center" />
          <ArrayField label="Postos" source="stationAttendants" sortable={false}>
            <StationField />
          </ArrayField>
        </Datagrid>
      </ReportList>
    </Permission>
  );
};

export default ReportAttendant;