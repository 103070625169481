import React from 'react'
import {
	Edit,
	SimpleForm,
	TextInput,
	RadioButtonGroupInput,
	minLength,
	ReferenceInput,
	SelectInput,
	required,
	email,
	BooleanInput,
	FormDataConsumer,
} from 'react-admin'

import Permission from '../../components/Permission';
import { makeStyles } from '@mui/styles';
import { PERMISSIONS } from '../../constants';
import { CPFInput } from '../../components/Inputs';

const useStyles = makeStyles((theme) => ({
	profiles: {
		width: 220,
	}
}));

const validatePassword = [minLength(6)];
const validateEmail = [required(), email()];

export default props => {
	const classes = useStyles();
	const transform = data => {
		delete data.profile;
		return data;
	};
	return (
		<Permission permission={PERMISSIONS.UPDATE_PROMOTER}>
			<Edit {...props} transform={transform} title="Editar promoter">
				<SimpleForm>
					<Permission permission={PERMISSIONS.ADMIN} >
						<ReferenceInput {...props} label="Rede" source="chainId" reference="chains" sort={{ field: 'name', order: 'ASC' }} perPage={null} validate={[required()]}>
							<SelectInput optionText="name" InputProps={{ autoComplete: 'off' }} />
						</ReferenceInput>
					</Permission>
					<TextInput source="name" label="Nome" validate={[required()]} InputProps={{ autoComplete: 'off' }} />
					<CPFInput source="cpf" label="CPF" validate={[required()]} isRequired />
					<TextInput source="login" label="Login" validate={validateEmail} InputProps={{ autoComplete: 'off' }} />
					<TextInput source="password" label="Senha" validate={validatePassword} InputProps={{ autoComplete: 'new-password' }} />
					<TextInput
						source="code"
						label="Código"
						inputProps={{
							maxLength: 32,
						}}
						InputProps={{ autoComplete: 'off' }}
						parse={value => value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()}
					/>
					<FormDataConsumer>
						{({ formData }) => (
							<ReferenceInput
								className={classes.profiles}
								label="Perfil"
								source="profileId"
								reference={'profiles'}
								filter={{ chainId: formData.chainId }}
								sort={{ field: 'name', order: 'ASC' }}
								// @ts-ignore
								perPage={null}
								validate={[required()]}
							>
								<RadioButtonGroupInput label="Perfil" optionText="name" />
							</ReferenceInput>
						)}
					</FormDataConsumer>
					<BooleanInput source="mustChangePassword" label="Forçar usuário a trocar senha no primeiro acesso" />
				</SimpleForm>
			</Edit>
		</Permission>
	);
}
