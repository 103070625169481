import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React from 'react';
import { Datagrid, DateField, FunctionField, ReferenceArrayInput, ReferenceField, SelectArrayInput, TextField, useListContext, usePermissions } from 'react-admin';
import { BetweenDatesInput } from '../../components/BetweenDatesInput';
import { CPFField, PhoneField } from "../../components/Fields";
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import ReportList from '../../components/ReportList';
import { PERMISSIONS } from '../../constants';
import { formatPoints } from '../../utils';
import RequiredChain from '../../components/RequiredChain';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
}));

const ReportMessage = ({ title }) => (
  <Box sx={{ padding: 2 }}>
    <Typography variant="subtitle1" textAlign="center">{title}</Typography>
  </Box>
);

const InactiveCustomersList = () => {
  const { filterValues } = useListContext();
  const classes = useStyles();

  if (!filterValues.transactionTypes) {
    return (<ReportMessage title="É necessário definir um filtro de Tipo de transação para exibir o relátorio." />);
  }

  const { permissions } = usePermissions();
  return (
    <>
      <Typography className={classes.title}>Clientes</Typography>
      <Datagrid bulkActionButtons={false}>
        <ReferenceField
          source="id"
          reference="customers"
          label="Nome"
          emptyText='--'
          link={permissions?.includes(PERMISSIONS.LIST_CUSTOMER) && "show"}
        >
          <TextField source="name" />
        </ReferenceField>
        <CPFField label="CPF" source="cpf" />
        <ReferenceField
          label="Saldo"
          source="id"
          reference="customer-balance"
          emptyText="0"
          sortable={false}
          link={false}
        >
          <FunctionField
            source="balance"
            render={record => formatPoints(+record?.balance)}
          />
        </ReferenceField>
        <PhoneField label="Telefone" source="cellphone" sortable={false} />
        <ReferenceField
          label="Último Abastecimento"
          source="id"
          reference="customer-last-fillin"
          emptyText="-"
          link={false}
        >
          <DateField source="createdAt" showTime />
        </ReferenceField>
      </Datagrid>
    </>
  )
};

const inactiveCustomersExporter = async (data, fetchRelatedRecords) => {
  const relations = [
    { field: 'id', resource: 'customer-balance' },
    { field: 'id', resource: 'customer-last-fillin' }
  ]

  const fetchData = await fetchRelatedRecords(relations);

  const getData = (resource) => {
    const results = fetchData.find(({ resource: res }) => res === resource)?.results
    if (results) {
      return results.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
    }
    return []
  }
  const balances = getData('customer-balance');
  const lastFillins = getData('customer-last-fillin');

  return data.map(({ id, name, cpf, cellphone, created_at }) => ({ id, name, cpf, balance: balances[id]?.balance || 0, lastFillin: lastFillins[id]?.createdAt || '-', cellphone, created_at }));

}

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  return (
    <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
      <DateField source="from" textAlign="center" label="De" sortable={false} />
      <DateField source="to" textAlign="center" label="Até" sortable={false} />
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
    </Datagrid>
  );
}

const ReportInactiveCustomers = (props) => {

  const { permissions } = usePermissions();

  const listFilters = [
    <BetweenDatesInput alwaysOn />,
    <SelectArrayInput
      style={{ minWidth: 180 }}
      label="Tipo de transação"
      source="transactionTypes"
      choices={[
        { name: 'Não Abasteceu', type: 'refuel' },
        { name: 'Não Resgatou', type: 'redemption' },
      ]}
      optionValue="type"
      optionText="name"
      alwaysOn
    />,
    <ReferenceArrayInput source="stationIds" reference="stations" perPage={null} sort={{ field: "name", order: "ASC" }} alwaysOn>
      <SelectArrayInput
        label="Posto"
        emptyText="Todos"
        style={{ minWidth: 180 }}
        optionText={'name'}
      />
    </ReferenceArrayInput>,
  ];

  return (
    <Permission permission={PERMISSIONS.INACTIVE_CUSTOMERS_REPORT}>
      <RequiredChain>
        <ReportList
          {...props}
          basePath="report-inactive-customers"
          title="Clientes inativos"
          resource="chains/chainId/reports/inactive-customers"
          filters={listFilters}
          sort={{ field: 'created_at', order: 'DESC' }}
          perPage={25}
          filterDefaultValues={{
            from: moment().subtract(30, 'd').startOf('day').toISOString(),
            to: moment().endOf('day').toISOString(),
            transactionTypes: ['refuel', 'redemption']
          }
          }
          pagination={<ListPagination />}
          bulkActionButtons={false}
          customExporter={inactiveCustomersExporter}
          titleOnPrint="Relatório de clientes inativos"
          filterTablePrint={<FilterTablePrint />}
          isReport={false}
          bulkSendButton={permissions && permissions.includes(PERMISSIONS.BULK_SEND)}
          discountButton
          landscape
        >
          <InactiveCustomersList />
        </ReportList>
      </RequiredChain>
    </Permission >
  );
};

export default ReportInactiveCustomers;