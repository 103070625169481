import { httpClientReactAdmin, makePayloadWithFile } from '../../lib'
import {
  DEFAULT_API_ADMIN,
  CUSTOMERS,
  mapPath
} from '../../constants'
import { getChainId, clearMask } from '../../lib'
import { pickBy, isEqual } from 'lodash'

export default async (resourceName, params) => {
  const filteredParams = pickBy(
    params.data,
    (obj, key) => {
      if (Array.isArray(obj) && Array.isArray(params.previousData[key])) {
        return !isEqual(obj, params.previousData[key]);
      }
      return !params.previousData || !(key in params.previousData) || obj !== params.previousData[key];
    },
  )

  const path = mapPath[resourceName] || resourceName;
  const URL = `${DEFAULT_API_ADMIN}/${(path || '').replace('chainId', getChainId())}${params.id ? `/${params.id}` : ''}`;

  const hasUploadFile = ['chains', 'chains/chainId/products', 'chains-config', 'stations', 'services', 'chains/chainId/product-categories', 'chains/chainId/partners', 'billings', 'billings-payment', 'retailers', 'news'].find(
    _resourceName => _resourceName === path,
  )

  let payload =
    resourceName === 'chains' || 'chains/chainId/partners' || 'customers'
      ? clearMask(filteredParams, ['phone', 'cnpj', 'cellphone'])
      : filteredParams

  if (payload.places) {
    payload.places = payload.places.map(({ placeId, price, defaultStock, partnerPrice, limit, minimumStock, minimumAmount, isGift, discountValue, daysToExpire, rtiCode, productPlaceGiftFuels }) => {
      const place = {
        placeId, price, defaultStock, minimumStock, minimumAmount, isGift, discountValue, daysToExpire, rtiCode,
        partnerPrice: partnerPrice ? +partnerPrice : null,
        limit: limit ? +limit : null,
        productPlaceGiftFuels: (productPlaceGiftFuels || []),
      }
      if (!defaultStock) {
        delete place.defaultStock;
      }
      return place;
    })
  }

  if (payload.productPlaces) {
    delete payload.productPlaces
  }

  if (resourceName === CUSTOMERS) {
    delete payload.balance
  }

  const body = hasUploadFile ? makePayloadWithFile(payload, ['logo', 'image', 'imagefile', 'serviceAccount', 'files', 'file', 'paymentVoucher']) : JSON.stringify(payload)
  const { json } = await httpClientReactAdmin(URL, { method: 'PATCH', body }, !!hasUploadFile)

  return json;
}
