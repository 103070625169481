import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { PERMISSIONS } from '../../constants';
import list from './BillingsPaymentList';
import edit from './BillingsPaymentEdit';
import ComponentWithPermissions from '../ComponentWithPermissions';
import { UserRole } from '../../models';

export default {
    list: new ComponentWithPermissions({ component: list as React.ComponentType, permissions: [PERMISSIONS.BILLING_PAYMENT], userRoles: [UserRole.chain] }),
    edit: new ComponentWithPermissions({ component: edit, permissions: [PERMISSIONS.BILLING_PAYMENT], userRoles: [UserRole.chain] }),
    name: 'billings-payment',
    permissions: [PERMISSIONS.BILLING_PAYMENT],
    options: { label: 'Contas a pagar' },
    icon: MonetizationOnIcon,
}