import React, { useEffect, useState } from 'react';
import {
  ArrayInput,
  BooleanInput,
  Create,
  DateInput,
  FormDataConsumer,
  FormTab,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  maxValue,
  minValue,
  required,
  useDataProvider,
} from 'react-admin';

import Button from '@mui/material/Button';

import { makeStyles } from '@mui/styles';
import { ImageInformations, LazyLoadAutoCompleteInput, PriceInput } from '../../components';
import Permission from '../../components/Permission';
import { MAX_DATE, PERMISSIONS } from '../../constants';
import { getChainId } from '../../lib';
import { TagType } from '../../models';
import { dateInputFormatter } from '../../utils';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';

const editStyles = makeStyles({
  arrayInput: {
    '& p': {
      color: 'grey',
      'margin-right': '1em',
      'font-weight': 'bold',
    },
  },
  placeName: {
    padding: '1em 0 0.5em 0',
    width: '100%',
  },
  stockSwitch: {
    margin: '2em 2em 0em 1em'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '10px 0 0 0',
  }
})

const create = ({ ...props }) => {
  const [opts, setOpts] = useState([]);
  const [fuels, setFuels] = useState([]);

  const classes = editStyles();
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider.getList(`chains/${getChainId()}/places`, { filter: {}, sort: { field: 'name', order: 'ASC' } })
      .then(({ data }) => {
        setOpts(data);
      });
    dataProvider.getList('fuels', { filter: {}, sort: { field: 'name', order: 'ASC' } })
      .then(({ data }) => {
        setFuels(data);
      });
  }, [])

  const [addAllPlaces, setAddAllPlaces] = React.useState(false);

  const customAction = () => {
    setAddAllPlaces(true);
  }

  const validate = [required()]
  const optionRenderer = choice => `${choice.code} - ${choice.name}`

  const CreateToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton />
      </Toolbar>
    );
  }

  const transform = data => {
    if (data.saleProductIds) {
      data.saleProductIds = data.saleProductIds.map(p => p && p.saleProductId);
      console.log("🚀 ~ file: ProductCreate.jsx:99 ~ transform ~ data.saleProductIds:", data.saleProductIds)
    }
    return data;
  };

  return (
    <Permission permission={PERMISSIONS.CREATE_PRODUCT}>
      <Create
        {...props}
        title="Cadastrar novo produto"
        transform={transform}
      >
        <TabbedForm toolbar={<CreateToolbar />} sanitizeEmptyValues>
          <FormTab label="Produto">
            <Grid container lg={8} md={12} spacing={2}>
              <Grid item xs={12} md={12}>
                <TextInput source="name" label="Nome" validate={validate} InputProps={{ autoComplete: 'off' }} fullWidth />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextInput
                  source="description"
                  label="Descrição"
                  validate={validate}
                  InputProps={{ autoComplete: 'off' }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <ReferenceInput
                  source="categoryId"
                  reference="chains/chainId/product-categories"
                  perPage={null}
                  sort={{ field: 'name', order: 'ASC' }}>
                  <SelectInput
                    label="Categoria"
                    optionText="name"
                    emptyText="Nenhuma"
                    fullWidth
                  />
                </ReferenceInput>
              </Grid>
              <Grid item xs={12} md={8}>
                <ReferenceArrayInput
                  sort={{ field: 'name', order: 'ASC' }}
                  filter={{ type: TagType.product }}
                  perPage={null}
                  source="tagIds"
                  reference="chains/chainId/tag"
                >
                  <SelectArrayInput
                    label="Etiquetas"
                    optionText="name"
                    fullWidth
                  />
                </ReferenceArrayInput>
              </Grid>
              <Grid item xs={12} md={12}>
                <ArrayInput
                  label="Produtos de Venda"
                  source="saleProductIds"
                  className={classes.arrayInput}
                >
                  <SimpleFormIterator fullWidth disableReordering>
                    <FormDataConsumer>
                      {({ getSource }) => {
                        if (!getSource) { return <></>; }

                        return (
                          <Grid container lg={6} md={12} spacing={2}>
                            <Grid item xs={12} md={12}>
                              <LazyLoadAutoCompleteInput
                                label="Produto de Venda"
                                source={getSource('saleProductId')}
                                format={optionRenderer}
                                style={{ margin: 15 }}
                                reference="chains/chainId/sale-products"
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        )
                      }}
                    </FormDataConsumer>
                  </SimpleFormIterator>
                </ArrayInput>
              </Grid>
              <Grid item xs={12} md={12}>
                <ImageInput source="imagefile" label="Imagem" accept="image/*">
                  <ImageField source="src" label="Imagem" />
                </ImageInput>
                <ImageInformations />
              </Grid>
            </Grid>
          </FormTab>
          <FormTab label="Resgate">
            <NumberInput
              source="defaultPrice"
              label="Pontos"
              id="defaultPriceInput"
              InputProps={{ autoComplete: 'off' }}
              style={{ marginRight: 10 }}
            />
            <PriceInput
              source="defaultPartnerPrice"
              label="Valor"
              InputProps={{ autoComplete: 'off' }}
              style={{ marginRight: 10 }}
            />
            <TextInput
              source="defaultDiscountValue"
              label="Valor do desconto(%)"
              format={(value) => value && Number(value * 100).toFixed(0)}
              parse={(value) => value && +value.replace(/[^\d]/g, '') / 100}
              defaultValue={1}
              validate={[required(), minValue(0.01, 'Deve ser no mínimo 1'), maxValue(1, 'Deve ser no máximo 100')]}
              InputProps={{ autoComplete: 'off' }}
              style={{ marginRight: 10 }}
            />
            <NumberInput
              source="defaultLimit"
              label="Resgates por dia"
              min={1}
              validate={minValue(1)}
              InputProps={{ autoComplete: 'off' }}
              style={{ marginRight: 10 }}
            />
            <FormDataConsumer>
              {({ formData }) => {
                const disabled = formData.places ? formData.places.length === opts.length : false;
                return (
                  <Button color="primary" disabled={disabled || !formData.defaultPrice} onClick={customAction}>Adicionar Restantes</Button>
                )
              }}
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData }) => {
                if (addAllPlaces) {
                  let places;
                  if (formData.places) {
                    const formDataPlaces = formData.places.filter((p) => p);
                    const insertedIds = formDataPlaces.map((p) => p.placeId)
                    const bulkPlaces = opts.map(({ id, name }) => ({
                      price: formData.defaultPrice,
                      partnerPrice: formData.defaultPartnerPrice,
                      discountValue: formData.defaultDiscountValue,
                      limit: formData.defaultLimit,
                      placeId: id,
                      name
                    }));
                    places = formDataPlaces.concat(bulkPlaces.filter(({ placeId }) => !insertedIds.includes(placeId)));
                  } else {
                    places = opts.map(({ id, name }) => ({
                      price: formData.defaultPrice,
                      partnerPrice: formData.defaultPartnerPrice,
                      discountValue: formData.defaultDiscountValue,
                      limit: formData.defaultLimit,
                      placeId: id,
                      name
                    }));
                  }
                  formData.places = places;
                  const defaultPriceInputRef = document['getElementById'](
                    'defaultPriceInput',
                  )
                  setAddAllPlaces(false);
                  defaultPriceInputRef.focus()
                }
                return (
                  <FormDataConsumer>
                    {({ formData: upperFormData, ...upperRest }) => {
                      const disableRemove = upperFormData.places
                        ? upperFormData.places.length === 1
                        : false
                      const disableAdd = upperFormData.places ? upperFormData.places.length === opts.length : false;
                      return (
                        <ArrayInput
                          validate={validate}
                          label=""
                          source="places"
                          className={classes.arrayInput}
                          style={{ marginRight: 10 }}
                        >
                          <SimpleFormIterator
                            disableReordering
                            disableRemove={disableRemove}
                            disableAdd={disableAdd}

                            {...upperRest}
                          >
                            <FormDataConsumer>
                              {({ formData, scopedFormData, getSource }) => {
                                const selectedPlacesIds = formData.places ? formData.places.filter((p) => p).map(
                                  p => p.placeId,
                                ) : [];

                                const availablePlaces = opts.filter(
                                  ({ id }) => !selectedPlacesIds.includes(id) ||
                                    (scopedFormData ? scopedFormData.placeId === id : false),
                                );
                                return getSource && (
                                  <>
                                    <div className={classes.row}>
                                      {formData.places && (
                                        <SelectInput
                                          source={getSource('placeId')}
                                          choices={availablePlaces}
                                          label="Ponto de troca"
                                          style={{ width: 160, marginRight: 10 }}
                                        />
                                      )}
                                      <NumberInput
                                        source={getSource('price')}
                                        label="Valor em pontos"
                                        validate={[required(), minValue(0)]}
                                        min={0}
                                        style={{ width: 160, marginRight: 10 }}
                                      />
                                      <PriceInput
                                        source={getSource('partnerPrice')}
                                        label="Valor em reais"
                                        style={{ width: 160, marginRight: 10 }}
                                      />
                                      <TextInput
                                        source={getSource('discountValue')}
                                        label="Valor do Desconto(%)"
                                        format={(value) => value && Number(value * 100).toFixed(0)}
                                        parse={(value) => value && +value.replace(/[^\d]/g, '') / 100}
                                        defaultValue={1}
                                        validate={[required(), minValue(0.01, 'Deve ser no mínimo 1'), maxValue(1, 'Deve ser no máximo 100')]}
                                        style={{ width: 175, marginRight: 10 }}
                                      />
                                      <NumberInput
                                        source={getSource('limit')}
                                        label="Resgates por dia"
                                        min={1}
                                        validate={minValue(1)}
                                        style={{ width: 160, marginRight: 10 }}
                                      />
                                      <FormDataConsumer>
                                        {() => {
                                          if (scopedFormData && opts.find((place) => place.id === scopedFormData.placeId && place.rtiToken != null)) {
                                            return (
                                              <NumberInput
                                                source={getSource("rtiCode")}
                                                label="Código RTI"
                                                style={{ width: 160, marginRight: 10 }}
                                                validate={[minValue(0)]}
                                                min={0}
                                              />
                                            );
                                          } else if (scopedFormData && opts.length) {
                                            scopedFormData.rtiCode = null;
                                          }
                                        }}
                                      </FormDataConsumer>
                                    </div>
                                  </>
                                );
                              }}
                            </FormDataConsumer>
                            <BooleanInput
                              label="Brinde"
                              source="isGift"
                              style={{ marginRight: 10 }}
                            />
                            <FormDataConsumer>
                              {({ scopedFormData, getSource }) => {

                                const disableAddFuel = scopedFormData?.productPlaceGiftFuels ? scopedFormData.productPlaceGiftFuels.length === fuels.length : false

                                return scopedFormData && scopedFormData.isGift && getSource &&
                                  <ArrayInput source={getSource('productPlaceGiftFuels')} label="Combustíveis">
                                    <SimpleFormIterator disableAdd={disableAddFuel} disableReordering>
                                      <FormDataConsumer>
                                        {({ scopedFormData: placeProductScoped, getSource }) => {
                                          const selectedfuelIds = scopedFormData.productPlaceGiftFuels ? scopedFormData.productPlaceGiftFuels.filter((p) => p).map(
                                            p => p.fuelId,
                                          ) : [];
                                          const availableFuels = fuels.filter(
                                            ({ id }) => !selectedfuelIds.includes(id) ||
                                              (placeProductScoped ? placeProductScoped.fuelId === id : false),
                                          );

                                          return getSource && (
                                            <div className={classes.row}>
                                              <NumberInput
                                                label={"Quantidade mínima (litros)"}
                                                source={getSource('minimumAmount')}
                                                style={{ width: 220, marginRight: 10 }}
                                                validate={minValue(0)}
                                              />
                                              <SelectInput
                                                label="Combustível"
                                                source={getSource("fuelId")}
                                                choices={availableFuels}
                                                optionText="name"
                                                style={{ marginLeft: 10, marginRight: 10 }}
                                              />
                                              <DateInput label="Data inicial" source={getSource("startDate")} format={dateInputFormatter} style={{ marginRight: 10 }} inputProps={{ max: MAX_DATE }} />
                                              <DateInput label="Data final" source={getSource("endDate")} format={dateInputFormatter} style={{ marginRight: 10 }} inputProps={{ max: MAX_DATE }} validate={minValue(placeProductScoped.startDate, `Deve ser no mínimo ${dayjs(placeProductScoped.startDate).format("DD/MM/YYYY")}`)} />
                                              <NumberInput
                                                label="Limite de resgate"
                                                source={getSource("limitQuantity")}
                                                style={{ width: 160, marginRight: 10 }}
                                                parse={v => {
                                                  if (v < 0) { return 0; }
                                                  return !v || v === '' ? null : Math.floor(v);
                                                }}
                                              />
                                              <BooleanInput label="Validar em outro local" source={getSource("redeemAnywhere")} style={{ marginRight: 10 }} />
                                            </div>
                                          )
                                        }}
                                      </FormDataConsumer>
                                    </SimpleFormIterator>
                                  </ArrayInput>
                              }}
                            </FormDataConsumer>
                          </SimpleFormIterator>
                        </ArrayInput>
                      )
                    }}
                  </FormDataConsumer>
                )
              }}
            </FormDataConsumer>
          </FormTab>
          <FormTab label="Estoque">
            <BooleanInput
              label="Controlar estoque"
              source="stock"
              className={classes.stockSwitch}
            />
            <FormDataConsumer>
              {({
                formData,
              }) => {
                if (formData.stock) {
                  return (
                    <ArrayInput
                      source="places"
                      label=""
                      className={classes.arrayInput}>
                      <SimpleFormIterator
                        disableReordering
                        disableRemove
                        disableAdd
                      >
                        <FormDataConsumer>
                          {({
                            scopedFormData,
                            getSource,
                          }) => {
                            if (scopedFormData && scopedFormData.placeId) {
                              if (scopedFormData.place) {
                                scopedFormData.name = scopedFormData.place.name
                              } else {
                                const place = opts.find((p) => p.id === scopedFormData.placeId);
                                if (place) {
                                  scopedFormData.name = place.name
                                }
                              }
                              getSource()

                              return (
                                <>
                                  <TextField
                                    source={'name'}
                                    record={scopedFormData}
                                    component="p"
                                    variant='subtitle1'
                                    className={classes.placeName}
                                  />
                                </>
                              )
                            } else {
                              return null;
                            }
                          }
                          }
                        </FormDataConsumer>
                        <SelectInput
                          source={'defaultStock'}
                          choices={[
                            { id: 'showcase', name: 'Mostruário' },
                            { id: 'warehouse', name: 'Depósito' },
                          ]}
                          label="Estoque padrão"
                          validate={validate}
                        />
                        <NumberInput
                          source='minimumStock'
                          label='Estoque mínimo'
                          min={0}
                        />
                      </SimpleFormIterator>
                    </ArrayInput>
                  )
                } else {
                  return null;
                }
              }
              }
            </FormDataConsumer>
          </FormTab>
        </TabbedForm>
      </Create>
    </Permission>
  )
}

export default create
