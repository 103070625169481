import React from 'react';
import { Datagrid, DateField, Filter, List, TextField } from 'react-admin';
import { PartiallyHiddenCpfField } from '../../components/Fields';
import { BetweenDatesInput } from '../../components/BetweenDatesInput';
import ListPagination from '../../components/ListPagination';
import { Permission } from '../../components';
import { PERMISSIONS } from '../../constants';
import moment from 'moment';


const TermsOfUseFilter = ({ ...props }) => (
  <Filter {...props}>
    <BetweenDatesInput alwaysOn />
  </Filter>
);

const TermsOfUseList = (props) => (
  <Permission permission={PERMISSIONS.TERMS_OF_USE} >
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      basePath='customers'
      resource='customers'
      title="Termos de Uso"
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={<TermsOfUseFilter />}
      perPage={25}
      pagination={<ListPagination />}
      filterDefaultValues={{
        hasPassword: true,
        from: moment().startOf('day').subtract(1, "month").toISOString(),
        to: moment().endOf('day').toISOString()
      }}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" label="Nome" />
        <PartiallyHiddenCpfField source="cpf" label="CPF" />
        <DateField source="createdAt" label="Data" showTime />
        <TextField source="ipAddress" label="IP" emptyText="-" textAlign='center' />
      </Datagrid>
    </List>
  </Permission>
);

export default TermsOfUseList;