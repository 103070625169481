import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React from 'react';
import { ArrayField, BooleanField, ChipField, Datagrid, FunctionField, ReferenceArrayField, SimpleShowLayout, SingleFieldList, TextField } from 'react-admin';
import { daysOfTheWeek, frequencyChoices } from '../../../constants';
import { Alert, AlertTitle } from '@mui/material';

const FormSummary: any = ({ formData, chain }) => {
  const classes = useStyles();

  return (
    <>
      <SimpleShowLayout record={formData}>
        <TextField source="name" label="Nome" />
        <TextField source="priority" label="Prioridade" emptyText="--" />
        <TextField source="duration" label="Duração (segundos)" />
        {
          formData.selectsAllPlaces ?
            <FunctionField render={() => 'Todos'} label="Postos" />
            :
            <ReferenceArrayField label="Postos" reference="stations" source="stationIds">
              <SingleFieldList>
                <ChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>
        }
        {
          formData.selectsAllFuels ?
            <FunctionField render={() => 'Todos'} label="Combustíveis" />
            :
            <ReferenceArrayField label="Combustíveis" reference="fuels" source="fuelIds">
              <SingleFieldList>
                <ChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>
        }
        <BooleanField source="isSchedule" label="Agendamento ativo" />
        {
          (formData.isSchedule) &&
          <FunctionField render={record => record && moment(record.startAt).format('DD/MM/YYYY HH:mm')} label="Data inicial" />
        }
        {
          (formData.isSchedule) &&
          <FunctionField render={record => record && moment(record.endAt).format('DD/MM/YYYY HH:mm')} label="Data final" />
        }
        {
          (formData.isSchedule) &&
          <FunctionField render={record => record && frequencyChoices.find(dt => dt.id === record.frequency)?.name} label="Frequência" />
        }
        {
          (formData.isSchedule && formData.frequency === 'weekly') &&
          <ArrayField source="weekSchedual" label="Horários">
            <Datagrid bulkActionButtons={false}>
              <FunctionField render={record => record && daysOfTheWeek.find(wd => wd.id === record.dayOfTheWeek)?.name} label="Dia" />
              <FunctionField render={record => record && record.startTime ? moment(record.startTime).format('HH:mm') : '--'} label="Inicio" />
              <FunctionField render={record => record && record.endTime ? moment(record.endTime).format('HH:mm') : '--'} label="Fim" />
              <BooleanField source="isActive" label="Ativo?" />
            </Datagrid>
          </ArrayField>
        }
        {
          (formData.isSchedule && formData.frequency === 'daily') &&
          <FunctionField render={record => (record && record.startTime) ? moment(record.startTime).format('HH:mm') : '--'} label="Hora de inicio" />
        }
        {
          (formData.isSchedule && formData.frequency === 'daily') &&
          <FunctionField render={record => (record && record.endTime) ? moment(record.endTime).format('HH:mm') : '--'} label="Hora de fim" />
        }
        <Alert variant="outlined" severity="info">
          <AlertTitle>Informação: </AlertTitle>
          1- O envio do arquivo pode demorar até 2 minutos, a depender da conexão de internet.
          <br />
          2- Após o envio, processaremos o vídeo para deixá-lo otimizado para ser reproduzido nos dispositivos dos seus clientes. Esta etapa pode levar até 5 minutos.
        </Alert>
      </SimpleShowLayout>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  containerFuelDiscount: {
    padding: 0,
    paddingTop: '0px !important',
  }
}));

export default FormSummary;