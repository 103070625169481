import moment from "moment";
import { Dispatch, SetStateAction, createContext, useContext, useState } from "react";
import { getChainId, getPlaceId } from "../lib";

interface DashboardContextValue {
  from: moment.Moment;
  to: moment.Moment;
  chain: string;
  origin: string;
  fillinsSummary?: object | null;
  stationIds: Array<string>;
  setFrom: Dispatch<SetStateAction<moment.Moment>>;
  setTo: Dispatch<SetStateAction<moment.Moment>>;
  setChain: Dispatch<SetStateAction<string>>;
  setOrigin: Dispatch<SetStateAction<string>>;
  setFillinsSummary: Dispatch<SetStateAction<object>>;
  setStationIds: Dispatch<SetStateAction<string>>;
};

const DashboardContext = createContext({} as DashboardContextValue);

export const DashboardProvider = ({ children }) => {
  const initalStation = getPlaceId() ? [getPlaceId()] : undefined

  const [from, setFrom] = useState(moment().startOf('day').subtract(1, 'month'));
  const [to, setTo] = useState(moment().endOf('day'));
  const [chain, setChain] = useState(getChainId());
  const [origin, setOrigin] = useState("");
  const [stationIds, setStationIds] = useState(initalStation);
  const [fillinsSummary, setFillinsSummary] = useState({});

  const value = {
    from,
    to,
    chain,
    origin,
    fillinsSummary,
    stationIds,
    setFrom,
    setTo,
    setChain,
    setOrigin,
    setFillinsSummary,
    setStationIds,
  };

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};

const useDashboardContext = () => useContext(DashboardContext);

export default useDashboardContext;