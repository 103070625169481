import Group from '@mui/icons-material/Group';
import { PERMISSIONS } from '../../constants';
import list from './PlacesUsersList';
import create from './PlacesUsersCreate';
import edit from './PlacesUsersEdit';

export default {
  list,
  create,
  edit,
  name: '_employees',
  permissions: [PERMISSIONS.CREATE_LOCAL_USER],
  options: { label: 'Usuários de locais' },
  icon: Group,
}