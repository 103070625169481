import DateRangeIcon from '@mui/icons-material/DateRange'
import { PERMISSIONS } from '../../constants'
import list from './FuelScheduledDiscountsList'
import create from './FuelScheduledDiscountsCreate'
import edit from './FuelScheduledDiscountsEdit'

export default {
  list,
  create,
  edit,
  name: 'fuel-scheduled-discounts',
  permissions: [PERMISSIONS.FUEL_DISCOUNTS],
  options: { label: 'Descontos' },
  icon: DateRangeIcon,
}
