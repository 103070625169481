import LocalGasStation from '@mui/icons-material/LocalGasStation';
import { Collapse } from '@mui/material';
import { FilterList, ReferenceArrayInput, SelectArrayInput } from 'react-admin';
import useDashboardContext from '../../hooks/useDashboardContext';

const DashboardStationInput = ({ isOpen }) => {
  const { setStationIds } = useDashboardContext();

  return (
    <>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <FilterList
          label="Postos"
          icon={<LocalGasStation titleAccess="Postos" />}
        />
      </Collapse>
      <ReferenceArrayInput
        source="stationIds"
        reference="stations"
        perPage={null}
        sort={{ field: "name", order: "ASC" }}
      >
        <SelectArrayInput
          sx={{ width: 160, padding: 0 }}
          onChange={({ target }) => setStationIds(target.value)}
          label={!isOpen && 'Postos'}
          optionText="name" />
      </ReferenceArrayInput>
    </>);
}

export default DashboardStationInput;
