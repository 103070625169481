import { DayScheduledDiscount } from "./dayScheduledDiscount";

export enum AdvertisementFrequency {
  Daily = 'daily',
  Never = "never",
  Weekly = "weekly",
}

export class Advertisement {

  id: string | undefined;
  name: string | undefined;
  chainId: string | undefined;
  startAt: string | undefined;
  endAt: string | undefined;
  frequency: AdvertisementFrequency | undefined;
  isEnabled: boolean | undefined;
  createdAt: string | undefined;
  dayScheduledAdvertisements: DayScheduledDiscount[] = [];
  weekSchedual: DayScheduledDiscount[] = [];
  stationIds: Array<string> = [];
  fuelIds: Array<string> = [];
  startTime: Date | undefined;
  endTime: Date | undefined;
  isSchedule: boolean = false;
  file: any;
  fileType: string | undefined;

  static createAdvertisement(value: Partial<Advertisement>) {
    const advertisement: Partial<Advertisement> = {
      name: value.name,
      chainId: value.chainId,
      dayScheduledAdvertisements: value.dayScheduledAdvertisements,
      stationIds: value.stationIds,
      fuelIds: value.fuelIds,
      isSchedule: !!(value.startAt || value.endAt || value.frequency),
      startAt: value.startAt,
      endAt: value.endAt,
      frequency: value.frequency,
      file: value.file,
      fileType: value.fileType,
    };

    if (value.frequency === AdvertisementFrequency.Daily) {
      advertisement.startTime = value.dayScheduledAdvertisements && value.dayScheduledAdvertisements[0].startTime;
      advertisement.endTime = value.dayScheduledAdvertisements && value.dayScheduledAdvertisements[0].endTime;
    }

    return advertisement;
  }

  constructor(init?: Partial<Advertisement>) {
    Object.assign(this, init);
  }
}