import React from 'react';
import { List, Datagrid, CreateButton, TopToolbar, TextField, Filter, SearchInput, SelectField, FunctionField, SelectInput } from 'react-admin';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { TagType } from '../../models';

const ListActions = () => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

const TagNameFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn />
    <SelectInput
      label="Tipo"
      source="type"
      choices={TagType.tagTypeChoices}
      alwaysOn
    />
  </Filter>
);

const ColorTag = ({ color }) => <div style={{ width: 160, backgroundColor: color, height: 16 }} />;

const ListTag = (props) => {
  return (
    <Permission permission={PERMISSIONS.TAG}>
      <List
        {...props}
        title="Etiquetas"
        exporter={false}
        bulkActionButtons={false}
        actions={<ListActions />}
        filters={<TagNameFilter />}
      >
        <Datagrid rowClick="edit">
          <TextField source="name" label="Nome" />
          <FunctionField
            source="type"
            label="Tipo"
            textAlign="center"
            render={record => TagType.translateType(record ? record.type : null)}
          />
          <FunctionField
            source="color"
            label="Cor"
            textAlign="center"
            style={{ display: "inline-table" }}
            render={record => (record && record.color) && <ColorTag color={record.color} />} />
        </Datagrid>
      </List>
    </Permission>
  );
};

export default ListTag;
