import React from 'react';
import { List, Datagrid, TextField, SearchInput, DateField, BooleanField, ReferenceField, RichTextField, SelectInput, FunctionField } from 'react-admin';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { makeStyles } from '@mui/styles';
import ListPagination from '../../components/ListPagination';

const useStyles = makeStyles({
  richText: {
    '& img': {
      maxWidth: 300,
      maxHeght: 300,
    }
  },
});

const listMarketingCampaigns: React.FC = props => {
  const classes = useStyles();

  const listFilters = [
    <SearchInput source="search" alwaysOn />,
    <SelectInput
      source="status"
      label="Status"
      choices={[{ id: true, name: 'Ativa' }, { id: false, name: 'Inativa' }]}
      alwaysOn
    />
  ];

  return (
    <Permission permission={PERMISSIONS.MARKETING_CAMPAIGNS}>
      <List
        {...props}
        perPage={25}
        pagination={<ListPagination />}
        exporter={false}
        bulkActionButtons={false}
        title="Campanhas de marketing"
        filters={listFilters}
        filterDefaultValues={{ status: true }}
        empty={false}
      >
        <Datagrid rowClick={(_id, _basePath, record) => record.isActive && "edit"}>
          <TextField source="title" label="Título" />
          <DateField source="startDate" label="Início da campanha" />
          <FunctionField label="Fim da campanha" render={record => record.alwaysActive ? <DateField source="endDate" label="Fim da campanha" emptyText="Não definido" /> : "--"} />
          <BooleanField source="alwaysActive" label="Sempre ativa" />
          <RichTextField source="body" label="Mensagem" className={classes.richText} />
          {/* <BooleanField source="sms" label="SMS" /> */}
          <BooleanField source="pushNotification" label="Notificação" />
          <ReferenceField source="marketingCampaignId" reference="chains/chainId/marketing-campaigns" label="Campanha" sortable={false} link={false}>
            <TextField source="label" />
          </ReferenceField>
        </Datagrid>
      </List>
    </Permission>
  );
};

export default listMarketingCampaigns;
