import React from 'react';
import { Datagrid, DateField, List, ReferenceField, SearchInput, TextField } from 'react-admin';
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { getChainId } from '../../lib';

const ListBulkSend: React.FC = (props) => {

    const filters = [
        <SearchInput source="search" alwaysOn />,
    ];

    return (
        <Permission permission={PERMISSIONS.BULK_SEND}>
            <List
                {...props}
                perPage={25}
                pagination={<ListPagination />}
                exporter={false}
                sort={{ field: 'createdAt', order: 'DESC' }}
                title="Envios em massa"
                filter={{ chainId: getChainId() }}
                filters={filters}
                hasCreate={false}
            >
                <Datagrid bulkActionButtons={false} rowClick="show">
                    {
                        Permission({
                            permission: PERMISSIONS.ADMIN,
                            children: (
                                <ReferenceField source="chainId" label="Rede" reference="chains">
                                    <TextField source="name" />
                                </ReferenceField>
                            ),
                        })
                    }
                    {
                        Permission({
                            permission: PERMISSIONS.LIST_CUSTOMER,
                            children: (
                                <ReferenceField source="employeeId" label="Usuário" reference="employees">
                                    <TextField source="name" />
                                </ReferenceField>
                            ),
                        })
                    }
                    <TextField source="message" label="Mensagem" />
                    <DateField source="createdAt" label="Data de criação" showTime />
                </Datagrid>
            </List>
        </Permission>
    );
};

export default ListBulkSend;
