import React from 'react';
import { List, TextField, Datagrid, EditButton, DeleteButton, CreateButton, DateField, FunctionField, SelectInput, DatagridProps } from 'react-admin';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import RequiredChain from '../../components/RequiredChain';

interface DrawingDatagridProps extends DatagridProps {
    disableEditButton?: boolean | null;
    disableDeleteButton?: boolean | null;
};

enum DrawingStatus {
    NOT_STARTED = 'not-started',
    IN_PROGRESS = 'in-progress',
    FINISHED = 'finished',
    DRAWN = 'drawn',
}

const statusChoices = [
    { id: DrawingStatus.NOT_STARTED, label: 'Não iniciado' },
    { id: DrawingStatus.IN_PROGRESS, label: 'Em progresso' },
    { id: DrawingStatus.FINISHED, label: 'Finalizado' },
    { id: DrawingStatus.DRAWN, label: 'Sorteado' },
];

export const formatDrawingStatus = (status: string | null) => {
    switch (status) {
        case DrawingStatus.NOT_STARTED:
            return 'Não iniciado';
        case DrawingStatus.IN_PROGRESS:
            return 'Em progresso';
        case DrawingStatus.FINISHED:
            return 'Finalizado';
        case DrawingStatus.DRAWN:
            return 'Sorteado';
        default:
            return 'Desconhecido';
    }
};

export const DrawingDatagrid: React.FC<DrawingDatagridProps> = ({ disableEditButton, disableDeleteButton, ...props }) => (
    <Datagrid {...props} rowClick="show">
        <TextField source="name" label="Nome" />
        <DateField source="startDate" label="Data inicial" />
        <DateField source="endDate" label="Data final" />
        {/* <FunctionField render={record => formatDrawingStatus(record ? record.status : null)} label="Status" /> */}
        <DateField source="drawingDate" label="Data e hora do sorteio" showTime locales={"pt-BR"} />
        {disableEditButton !== true &&
            <EditButton />}
        {disableDeleteButton !== true &&
            <DeleteButton />}
    </Datagrid>
);

const DrawingList: React.FC = props => {

    const drawingFilters = [
        <SelectInput
            source="status"
            label="Status"
            choices={statusChoices}
            optionText="label"
            alwaysOn
        />,
    ];

    return (
        <Permission permission={PERMISSIONS.DRAWING}>
            <RequiredChain>
                <List
                    {...props}
                    title="Sorteios"
                    exporter={false}
                    bulkActionButtons={false}
                    actions={<CreateButton />}
                    filters={drawingFilters}
                    sort={{ field: 'createdAt', order: 'DESC' }}
                >
                    <DrawingDatagrid />
                </List>
            </RequiredChain>
        </Permission>
    );
};

export default DrawingList;
