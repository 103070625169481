import React, { useCallback, useEffect, useState } from 'react';
import { Create, useDataProvider, useGetOne, useNotify, useRedirect } from 'react-admin';
import Permission from '../../components/Permission';
import WizardForm from '../../components/WizardForm';
import { PERMISSIONS } from '../../constants';

import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { getChainId } from '../../lib';
import FormSchedule from './wizardForms/FormSchedule';
import FormSummary from './wizardForms/FormSummary';

import { Advertisement } from '../../models/advertisement';
import { DayScheduledAdvertisement } from '../../models/dayScheduledAdvertisement';
import { DayScheduledDiscount } from '../../models/dayScheduledDiscount';
import FormBasicInfo from './wizardForms/FormBasicInfo';
import FormPlaceAndFuel from './wizardForms/FormPlaceAndFuel';
import FormUpload from './wizardForms/FormUpload';
import { Typography } from '@mui/material';

const AdvertisementCreate = (props) => {

    const { state: params }: { state: Object } = useLocation();
    const [chainId] = useState(getChainId());
    if (!chainId) { return <Typography>Selecione uma Rede</Typography>; }

    const { data: chain } = useGetOne('chains', { id: chainId });
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    const [isLoading, setIsloading] = useState<boolean>(false);
    const [fuels, setFuels] = useState<{ [k: string]: any }[]>([]);
    const [completedSteps, setCompletedSteps] = React.useState<{
        [k: number]: boolean;
    }>({});
    const [lastPriority, setLastPriority] = useState<number | null>(null);

    const getLastPriority = async () => {
        //@ts-ignore
        const { data } = await dataProvider.getList('advertisements', { filter: { priorityRequired: true, isEnabled: true }, sort: { field: 'priority', order: 'DESC' }, pagination: { perPage: 1, page: 1 } });
        setLastPriority(data && data.length > 0 ? data[0].priority : null);
    }

    useEffect(() => {
        //@ts-ignore
        dataProvider.getList('fuels', { sort: { field: 'name', order: 'ASC' }, pagination: { page: 1, perPage: 9999 } })
            .then(({ data }) => {
                setFuels(data as unknown as { [k: string]: any; }[]);
            });

        getLastPriority();
    }, []);

    const validateBasicInfo = async (formData: any) => {
        if (!formData.name) {
            return { error: true, message: 'Campo nome está vazio' };
        }
        if (formData.priority && (formData.priority < 1 || formData.priority > 1000)) {
            return { error: true, message: 'Prioridade inválida' };
        }
        return { error: false };
    }

    const handleBasicInfo = (formData: any, nextStep: (index?: number) => void) => {
        nextStep();
    }

    const validatePlaceAndFuel = async (formData: any) => {
        if (!formData.selectsAllPlaces && !(formData.stationIds?.length > 0)) {
            return { error: true, message: 'Nenhum posto selecionado' };
        }
        if (!formData.selectsAllFuels && !(formData.fuelIds?.length > 0)) {
            return { error: true, message: 'Nenhum combustível selecionado' };
        }
        return { error: false };
    }

    const handlePlaceAndFuel = (formData: any, nextStep: (index?: number) => void) => {
        nextStep();
    }

    const validateSchedule = (formData: any) => {
        if (formData.isSchedule) {
            if (!formData.startAt || !formData.endAt) {
                return { error: true, message: 'Defina uma data de início e fim' };
            } else if (!moment(formData.startAt).isValid() || !moment(formData.endAt).isValid()) {
                return { error: true, message: 'Defina uma data válida' };
            } else if (moment(formData.endAt) <= moment(formData.startAt)) {
                return { error: true, message: 'Data final não pode ser inferior a data inicial' };
            }

            if (!formData.frequency) {
                return { error: true, message: 'Defina o tipo de frequência' };
            }

            if (formData.frequency === 'daily' && (!formData.startTime || !formData.endTime)) {
                return { error: true, message: 'Defina um horário de início e fim' };
            } else if (formData.frequency === 'daily' && (formData.startTime && formData.endTime) && moment(formData.endTime) <= moment(formData.startTime)) {
                return { error: true, message: 'Horário de fim não pode ser inferior ao horário de início' };
            }

            if (formData.frequency === 'weekly' && (!formData.weekSchedual || formData.weekSchedual.filter(ws => ws.isActive && (!ws.startTime || !ws.endTime)).length)) {
                return { error: true, message: 'Defina um horário de início e fim' };
            } else if (formData.frequency === 'weekly' && formData.weekSchedual && formData.weekSchedual.filter(ws => ws.isActive && moment(ws.endTime) <= moment(ws.startTime)).length) {
                return { error: true, message: 'Horário de fim não pode ser inferior ao horário de início' };
            }
        }

        return { error: false };
    }

    const handleSchedule = (formData: any, nextStep: () => void) => {
        nextStep();
    }

    const validateUpload = (formData: any) => {
        if (!formData.file || !formData.file?.rawFile) {
            return { error: true, message: 'Selecione um arquivo' };
        }
        if (!formData.file.rawFile?.type || (!formData.file.rawFile.type.includes('image') && !formData.file.rawFile.type.includes('video'))) {
            return { error: true, message: 'Arquivo inválido' };
        }

        if (!formData.duration) {
            return { error: true, message: 'Duração inválida' };
        }

        return { error: false };
    }

    const handleUpload = (formData: any, nextStep: () => void) => {
        nextStep();
    }

    const handleConcluedStep = (index) => {
        setCompletedSteps(value => ({ ...value, [index]: true }));
    }

    const handleCreateAdvertisement = (formData: any) => {
        const data = {
            ...formData,
            fileType: formData.file.rawFile.type,
        };

        if (formData.startAt && formData.endAt) {
            data.startAt = formData.startAt.toISOString();
            data.endAt = formData.endAt.toISOString();
        }

        if (formData.startTime) {
            data.startTime = formData.startTime.toISOString();
        }

        if (formData.endTime) {
            data.endTime = formData.endTime.toISOString();
        }

        if (formData.weekSchedual && formData.weekSchedual.length) {
            formData.weekSchedual = formData.weekSchedual.map(ws => ws.isEnabled ? ({ ...ws, startTime: ws.startTime.toISOString(), endTime: ws.endTime.toISOString() }) : ws);
        }

        setIsloading(true);
        dataProvider.create('advertisements', { data }).then(response => {
            notify('Anúncio criado com sucesso');
            redirect('/advertisements');
        }).catch(error => {
            notify(error.message || 'Erro ao criar anúncio', { type: 'warning' });
        }).finally(() => {
            setIsloading(false);
        });
    }

    const getInitialValues = useCallback(() => {
        const { placeDiscounts: paramsPlaceDiscounts, dayScheduledAdvertisements: paramsDayScheduledAdvertisements, _scrollToTop, ...restParams } = params as { dayScheduledAdvertisements: DayScheduledDiscount[], [k: string]: any } || {};

        const dayScheduledAdvertisements = Array(7).fill({}).map((_, index) => (
            paramsDayScheduledAdvertisements?.map(dayS => DayScheduledAdvertisement.createDayScheduledAdvertisement(dayS, true)).find(dayS => dayS.dayOfTheWeek === index) || DayScheduledAdvertisement.createDayScheduledAdvertisement({ dayOfTheWeek: index }, false)
        ));

        const { dayScheduledAdvertisements: advertisementDayScheduledAdvertisements, ...rest } = Advertisement.createAdvertisement({ chainId: getChainId(), dayScheduledAdvertisements, ...restParams });

        return { ...rest, weekSchedual: advertisementDayScheduledAdvertisements };
    }, [fuels]);

    return (
        <Permission permission={PERMISSIONS.ADVERTISEMENT}>
            <Create title='Cadastrar anúncio' {...props}>
                <WizardForm
                    isLoading={isLoading}
                    completedSteps={completedSteps}
                    defaultValues={getInitialValues()}
                    handleConcluedStep={handleConcluedStep}
                    formSteps={[
                        {
                            label: "Informações",
                            component: <FormBasicInfo lastPriority={lastPriority} />,
                            validate: validateBasicInfo,
                            onNextFunction: handleBasicInfo,
                        },
                        {
                            label: "Postos e Combústivel",
                            component: <FormPlaceAndFuel />,
                            validate: validatePlaceAndFuel,
                            onNextFunction: handlePlaceAndFuel,
                        },
                        {
                            label: "Agendamento",
                            component: <FormSchedule />,
                            validate: validateSchedule,
                            onNextFunction: handleSchedule,
                        },
                        {
                            label: "Upload",
                            component: <FormUpload />,
                            validate: validateUpload,
                            onNextFunction: handleUpload,
                        },
                        {
                            label: "Confirmação",
                            component: <FormSummary chain={chain} />,
                            onNextFunction: handleCreateAdvertisement,
                            validate: async (formData) => {
                                for (const validate of [validateBasicInfo, validatePlaceAndFuel, validateSchedule, validateUpload]) {
                                    const result = await validate(formData);
                                    if (result.error === true) {
                                        return result;
                                    }
                                }
                                return { error: false };
                            },
                            disableStep: (data, completedSteps) => {
                                return [0, 1, 2, 3].some(index => !completedSteps[index] || completedSteps[index] === false);
                            }
                        },
                    ]}
                />
            </Create>
        </Permission>
    );
};

export default AdvertisementCreate;