import RoomServiceIcon from '@mui/icons-material/RoomService';
import { PERMISSIONS } from '../../constants';
import list from './ServiceList';
import create from './ServiceCreate';
import edit from './ServiceEdit';

export default {
  list,
  create,
  edit,
  name: 'services',
  permissions: [PERMISSIONS.ADMIN],
  options: { label: 'Serviços' },
  icon: RoomServiceIcon,
}
