import React from "react";
import {
  Datagrid,
  DateField,
  NumberField,
  ReferenceField,
  TextField,
  useListContext,
  SelectArrayInput,
  ChipField,
  ArrayField,
  SingleFieldList,
  FunctionField
} from 'react-admin';
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import moment from 'moment';
import { BetweenDatesInput } from "../../components/BetweenDatesInput";
import { FillinOrigin } from "../../models/fillinOrigin";
import { CPFField } from "../../components/Fields";
import { makeStyles } from '@mui/styles';
import { PERMISSIONS } from "../../constants";
import { ReportList } from '../../components';
import { getChainId } from "../../lib";

const useStyles = makeStyles(theme => ({
}));

const RedemptionXPointsList = () => {
  const classes = useStyles();

  return (
    <Datagrid defaultColumns={['cpf, customer_id, positive_points, negative_points']}>
      <ReferenceField label="CPF" basePath="customers" reference="customers" source="id" link='show' emptyText="Não Identificado">
        <CPFField source="cpf" emptyText="Não Identificado" />
      </ReferenceField>
      <ReferenceField label="Nome" basePath="customers" reference="customers" source="id" link={false} emptyText="Não Identificado">
        <TextField source="name" />
      </ReferenceField>
      <NumberField
        source="positive_points"
        label="Soma dos pontos positivos"
        textAlign="center"
        emptyText="Não identificado"
        sortable
        options={{
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }}
      />
      <NumberField
        source="negative_points"
        label="Soma dos pontos negativos"
        textAlign="center"
        emptyText="Não identificado"
        sortable
        options={{
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }}
      />
    </Datagrid>
  );
};

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();

  return (
    <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
      <DateField source="from" textAlign="center" label="De" sortable={false} />
      <DateField source="to" textAlign="center" label="Até" sortable={false} />
      <ArrayField source="origin">
        <SingleFieldList>
          <FunctionField render={(record) => (
            <ChipField record={{ name: FillinOrigin.translateOrigin(record) }} source="name" />
          )} />
        </SingleFieldList>
      </ArrayField>
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
    </Datagrid>
  );
}

const ReportRedemptionXPoints = (props) => {
  const listFilters = [
    <BetweenDatesInput alwaysOn />,
    <SelectArrayInput
      label="Origem"
      source="origin"
      choices={FillinOrigin.fillinOriginChoices}
      emptyText="Todos"
      style={{ minWidth: 180 }}
      alwaysOn
    />
  ];

  return (
    <Permission permission={PERMISSIONS.REDEMPTION_X_POINTS}>
      <ReportList
        {...props}
        basePath="report-redemption-x-points"
        title="Resgate X Pontos"
        resource="chains/chainId/reports/redemption-x-points"
        filters={listFilters}
        filter={getChainId() ? { chainId: getChainId() } : null}
        sort={{ field: 'positive_points', order: 'DESC' }}
        perPage={25}
        filterDefaultValues={{
          from: moment().startOf('day').toISOString(),
          to: moment().endOf('day').toISOString()
        }}
        pagination={<ListPagination />}
        bulkActionButtons={false}
        titleOnPrint="Relatório de Resgate X Pontos"
        filterTablePrint={<FilterTablePrint />}
        landscape
        showPagination
      >
        <RedemptionXPointsList />
      </ReportList>
    </Permission>
  )
};

export default ReportRedemptionXPoints;