import { PERMISSIONS } from '../../constants'
import list from './FuelList'
import create from './FuelCreate'
import edit from './FuelEdit'

export default {
  list,
  create,
  edit,
  name: 'fuels',
  permissions: [PERMISSIONS.ADMIN]
}