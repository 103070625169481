import React from 'react'
import CardContent from '@mui/material/CardContent'
import CircularProgress from '@mui/material/CircularProgress'
import AddIcon from '@mui/icons-material/Add'
import { makeStyles } from '@mui/styles';
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
  CardActions,
} from '@mui/material'
import { formatNumber, formatPoints } from '../../utils';

import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 160,
    marginBottom: 0,
  },
  rowFilter: {
    alignSelf: 'center',
    alignItems: 'flex-end',
    display: 'flex',
  },
  rowChild: {
    marginRight: '20px'
  }
}));

const divStyle = {
  padding: '20px 20px 20px 20px',
}

const simpleTextStyle = {
  marginTop: '10px',
  fontSize: '15px',
}

const rowStyle = {
  alignSelf: 'center',
  justifyContent: 'space-between',
  display: 'flex',
  width: '450px',
}

const innerRowStyle = {
  alignSelf: 'flex-end',
  justifyContent: 'space-between',
  display: 'flex',
  width: '93%',
}

const loaderStyle = {
  padding: '20px 20px 20px 20px',
  justifyContent: 'center',
  display: 'flex',
}

const titleStyle = {
  marginTop: '30px',
  fontSize: '18px',
  color: 'grey',
}

const menuTextStyle = {
  fontSize: '17px',
  marginLeft: '54px',
  paddingTop: '30px',
  paddingBottom: '10px',
  color: 'rgba(0, 0, 0, 0.54)',
}

const rowContainerStyle = {
  justifyContent: 'space-between',
  display: 'flex',
  marginTop: '20px',
}

const tableTitleStyle = {
  paddingTop: '20px',
  paddingLeft: '20px',
  paddingBottom: '20px',
  fontSize: '23px',
  textAlign: 'center' as 'center',
}

export const Wrapper = props => (
  <CardContent>
    <Paper>
      <div style={divStyle}>{props.children}</div>
    </Paper>
  </CardContent>
)

export const SimpleText = ({ text }) => <pre style={simpleTextStyle}>{text}</pre>

export const RowDate = props => <div style={rowStyle}>{props.children}</div>

export const RowFilter = props => {
  const classes = useStyles();
  return (
    <div className={classes.rowFilter} style={props.style}>{
      React.Children.map(props.children, (child) => {
        return child && (
          <div className={classes.rowChild}>
            {child}
          </div>
        );
      })}</div>
  );
}

export const InnerRow = props => (
  <div style={innerRowStyle}>{props.children}</div>
)

export const Loader = props => (
  <div style={loaderStyle}>
    <CircularProgress />
  </div>
)

export const Title = ({ text }) => <p style={titleStyle}>{text}</p>

export const MenuText = ({ text }) => <p style={menuTextStyle}>{text}</p>

export const RowContainer = props => (
  <div style={rowContainerStyle}>{props.children}</div>
)

export const TableTitle = ({ text }) => <p style={tableTitleStyle}>{text}</p>

export const TableGeneric = ({ headCells, arrayData, title, style, table = {}, cells = null, ...other }) => {
  return (
    <Paper style={style} {...other}>
      <TableTitle text={title} />
      <Table {...table}>
        <TableHead>
          <TableRow>
            {headCells.map(cell => (
              <TableCell key={`${cell}-${Math.random()}`} align="left" style={cells ? { width: `calc(100% / ${cells})` } : {}} >
                {cell}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {arrayData.length > 0 ? (
            arrayData.map((row, index) => (
              <TableRow key={index}>
                {Object.keys(row).map(key => {
                  if (key === 'created_at' || key === 'createdAt' || key === 'redeemedAt') {
                    return (
                      <TableCell key={key}>
                        {moment(row[key]).isValid() ? moment(row[key]).format('DD/MM/YYYY HH:mm') : '-'}
                      </TableCell>
                    )
                  }
                  if (key === 'redeemedValue' || key === 'notRedeemedValue' || key === 'totalValue' || key === 'partnerValue') {
                    return (
                      <TableCell key={key}>{formatNumber(row[key], { style: 'currency', currency: 'BRL' })}</TableCell>
                    )
                  }
                  if (key === 'amount' || key === 'totalAmount') {
                    return (
                      <TableCell key={key}>{formatNumber(row[key], { maximumFractionDigits: 2 })}</TableCell>
                    )
                  }
                  if (key === 'redeemedPoints' || key === 'notRedeemedPoints' || key === 'totalPoints') {
                    return (
                      <TableCell key={key}>{formatPoints(row[key])}</TableCell>
                    )
                  }
                  return <TableCell key={key}>{row[key]}</TableCell>
                })}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell>Nenhum registro</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  )
}

export const DropDownPartners = ({
  label,
  defaultValue = null,
  value,
  setValue,
  arrayData,
  allowEmpty = false,
  style = {}
}) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl} style={style} variant="filled">
      <InputLabel id="drop-down-label">
        {label}
      </InputLabel>
      <Select
        defaultValue={defaultValue}
        value={value}
        onChange={event => setValue(event.target.value)}
        labelId="drop-down-label"
        id="drop-down"
      >
        {allowEmpty &&
          <MenuItem value={undefined}><em>Todos</em></MenuItem>
        }
        {arrayData
          ? arrayData.map(partner => (
            <MenuItem value={partner.id} key={partner.id}>{partner.name}</MenuItem>
          ))
          : null}
      </Select>
    </FormControl >
  );
}

export const CustomActionBar: any = ({
  children,
  bulkActions,
  basePath,
  displayedFilters,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    {children}
  </CardActions>
)