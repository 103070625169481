import moment from 'moment';
import { Fragment, useState } from 'react';
import {
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  regex,
  required,
  useDataProvider,
  useNotify
} from 'react-admin';

import Button from '@mui/material/Button';
import { formatPartiallyHiddenCpf, formatPoints } from '../../utils';
import styles from '../validateCoupon/CouponSearch.module.scss';

const EMPTY_COUPON = {
  id: '',
  createdAt: '',
  value: 0,
  refunded: false,
  product: {
    id: '',
    name: '',
    description: '',
    imagePath: '',
  },
  transaction: {
    customer: {
      name: '',
      email: '',
      cpf: '',
    },
  },
};

const validate = [required(), regex(/^(.{8}|^.{10})$/, 'O código deve ter 8 ou 10 dígitos')];

const CustomToolbar = props => {
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.invalid} label="Buscar" submitOnEnter />
    </Toolbar>
  )
};

const CouponSearch = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [coupon, setCoupon] = useState(EMPTY_COUPON);
  const [loading, setLoading] = useState(false);
  const [couponBarcode, setCouponBarcode] = useState("");

  const executeSearch = async ({ id }) => {
    setLoading(true);
    setCoupon(EMPTY_COUPON);

    dataProvider.getOne(`coupons`, { id })
      .then(({ data }) => {
        setLoading(false);
        if (data.redeemed) {
          notify("Cupom já resgatado!", { type: 'warning' });
        } else if (data.refunded) {
          notify("Cupom já foi estornado!", { type: 'warning' });
        } else {
          setCoupon(data);
          setCouponBarcode("");
        }
      })
      .catch((error) => {
        setLoading(false);
        notify(error.message, { type: 'warning' })
      });
  }

  const refundCoupon = async (id, customerId) => {
    setLoading(true);
    dataProvider.delete(`chains/chainId/customers/${customerId}/coupons`, { id })
      .then(() => {
        notify('Cupom estornado com sucesso!');
        setLoading(false);
        setCoupon(EMPTY_COUPON);
      })
      .catch((error) => {
        setLoading(false);
        notify(error.message, { type: 'warning' })
      });
  };

  const { customer } = coupon;

  return (
    <Fragment>
      <SimpleForm toolbar={<CustomToolbar invalid={loading} />} onSubmit={executeSearch} >
        <div className={styles.inputBox} >
          <TextInput
            source="id"
            resettable
            label="Identificador do cupom"
            validate={validate}
            value={couponBarcode}
            onChange={(event) => setCouponBarcode(event.target ? event.target.value : "")}
          />
        </div>
      </SimpleForm>
      {Boolean(coupon.id.length) && (
        <div className={styles.container}>
          {coupon.isCashback ?
            <div></div> :
            <div className={styles.productImage}>
              <img src={coupon.imagePath || coupon.product.imagePath} alt="" />
            </div>
          }
          <div className={styles.couponInfo}>
            <div>
              <span className={styles.info}>
                <b>Cupom: </b>
                <i>{coupon.id}</i>
              </span>
              <span className={styles.info}>
                <b>Data: </b>
                {moment(coupon.createdAt).format('DD/MM/YYYY')}
              </span>
            </div>
            <div>
              <span className={styles.info}>
                <b>Produto: </b>
                {coupon.isCashback ? "Cashback" : coupon.title || coupon.product.name}
              </span>
              <span className={styles.info}>
                <b>Valor: </b>
                {`${formatPoints(coupon.value)} pontos`}
              </span>
            </div>
            <span className={styles.info}>
              <b>Descrição: </b>
              {coupon.isCashback ? "Desconto de valor em compra" : coupon.description || coupon.product.description}
            </span>
            {Boolean(coupon.refunded) ?
              <span className={styles.info}>
                <b>Cupom estornado</b>
              </span> :
              <Button
                className={styles.submit}
                disabled={loading}
                variant="contained"
                color="primary"
                onClick={() => refundCoupon(coupon.id, coupon.customerId)}>
                Estornar
              </Button>
            }
          </div>
          <div className={styles.customerInfo}>
            <span className={styles.info}>
              <b>Cliente que efetuou a troca: </b>
            </span>
            <span className={styles.info}>
              <b>Nome: </b>
              {`${customer.name} ${customer.email ? `(${customer.email})` : ''}`}
            </span>
            <span className={styles.info}>
              <b>CPF: </b>
              {formatPartiallyHiddenCpf(customer.cpf)}
            </span>
            <span className={styles.info}>
              <b>Data de Troca: </b>
              {moment(coupon.createdAt).format('DD/MM/YYYY - HH:mm')}
            </span>
          </div>
        </div>
      )}
    </Fragment>
  )
};
export default CouponSearch;
