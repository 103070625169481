import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { default as React, useEffect, useState } from 'react';
import { ArrayInput, BooleanInput, DateInput, DateTimeInput, Edit, FormDataConsumer, Labeled, NumberInput, ReferenceArrayInput, SelectArrayInput, SelectInput, SimpleForm, SimpleFormIterator, TextInput, minValue, required, useDataProvider } from 'react-admin';
import { getDrawingRuleName, getLabelValue } from './DrawingCreate';
import { PriceInput, Tooltip } from '../../components';
import { AlterValueDrawing } from '../../models';
import AddCustomItemButton from '../../components/AddCustomItemButton';
import { MAX_DATE } from '../../constants';

const DrawingEdit = (props) => {
  const [drawingRules, setDrawingRules] = useState<any[]>([]);
  const [fuels, setFuels] = useState<any[]>([]);
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider.getList('fuels', { sort: { field: "name", order: "ASC" }, filter: null, pagination: { page: 1, perPage: 50 } })
      .then(({ data }) => setFuels(data));
  }, []);

  useEffect(() => {
    dataProvider.getList('drawing-rules', {
      sort: { field: "label", order: "ASC" },
      filter: null,
      pagination: { page: 1, perPage: 50 },
    })
      .then(({ data }) => setDrawingRules(data));
  }, []);

  return (
    <Edit
      {...props}
      title='Editar Sorteio'
      redirect="list"
      transform={({ drawingRuleFuels, ...data }) => {
        return { ...data, drawingRuleFuels: drawingRuleFuels.map(({ drawingRule, ...rest }) => rest) };
      }}
    >
      <SimpleForm>
        <TextInput label="Nome" source="name" />
        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
          <DateInput
            style={{ minWidth: 240, marginRight: 20 }}
            source="startDate"
            label="Data inicial"
            locales="pt-BR"
            inputProps={{ max: MAX_DATE }}
            parse={value => moment(value).startOf('day').toISOString()}
          />
          <DateInput
            style={{ minWidth: 240, marginRight: 20 }}
            source="endDate"
            label="Data final"
            locales="pt-BR"
            inputProps={{ max: MAX_DATE }}
            parse={value => moment(value).endOf('day').toISOString()}
          />
          <DateTimeInput
            style={{ minWidth: 256 }}
            source="drawingDate"
            label="Data e hora do sorteio"
            locales="pt-BR"
            validate={[required()]}
          />
        </Box>

        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
          <NumberInput
            style={{ minWidth: 240, marginRight: 20 }}
            source="startNumber"
            label="Número inicial"
            min={0}
            validate={[minValue(0)]}
            disabled
          />
          <NumberInput
            style={{ minWidth: 240 }}
            source="endNumber"
            label="Número final"
            min={1}
            validate={[minValue(1)]}
            disabled
          />
        </Box>

        <NumberInput
          style={{ minWidth: 240 }}
          source="winningNumber"
          label="Número Sorteado"
          min={0}
          validate={[minValue(0)]}
        />

        <TextInput
          source="drawingRegulationUrl"
          label="Url do regulamento"
        />

        <ArrayInput source="drawingRuleFuels" label="Regras do sorteio" style={{ marginTop: 50 }}>
          <SimpleFormIterator fullWidth disableReordering disableRemove={(record) => !!(record && record.id)} addButton={<AddCustomItemButton name="drawingRuleFuels" resetFields={{ luckyNumberAmount: 1 }} />}>
            <FormDataConsumer>
              {({ scopedFormData, getSource }) => {
                if (!scopedFormData) { return; }

                const fuelChoices = [
                  { id: 'all', name: 'Todos' },
                  ...fuels
                ];

                const ruleId = scopedFormData.drawingRuleId;
                const drawingRuleName = getDrawingRuleName(ruleId, drawingRules);

                if (!getSource) { return <></>; }
                if (drawingRuleName === 'fillin-count') {
                  scopedFormData.accumulated = true;
                } else if (drawingRuleName.includes('register-customer') && scopedFormData) {
                  scopedFormData.accumulated = false;
                  scopedFormData.value = 0;
                } else if (drawingRules.length > 0 && !drawingRuleName.includes('fillin') && !drawingRuleName.includes('points-exchange')) {
                  scopedFormData.accumulated = null;
                  scopedFormData.value = 0;
                } else if (drawingRuleName.includes('complete-customer-registration')) {
                  scopedFormData.accumulated = null;
                  scopedFormData.value = 0;
                } else if (drawingRuleName.includes("fillin") && (scopedFormData.type === AlterValueDrawing.point || !scopedFormData.type)) {
                  scopedFormData.type = AlterValueDrawing.amount;
                } else if (drawingRuleName.includes("fillin") && scopedFormData.type === AlterValueDrawing.value) {
                  scopedFormData.accumulated = false;
                } else if (drawingRuleName.includes('points-exchange')) {
                  scopedFormData.type = AlterValueDrawing.point;
                }

                return (
                  <Box sx={{ marginTop: 2 }}>
                    <SelectInput
                      label="Regra"
                      style={{ minWidth: 220, marginRight: 10, marginTop: 2 }}
                      choices={drawingRules}
                      optionText="label"
                      source={getSource('drawingRuleId')}
                      validate={[required()]}
                      record={scopedFormData}
                    />
                    {(scopedFormData?.drawingRuleId && drawingRuleName.includes('points-exchange')) &&
                      <>
                        <TextInput
                          style={{ width: 150, marginTop: 2, marginRight: 10 }}
                          source={getSource('value')}
                          label="Valor em pontos"
                          defaultValue={0}
                          record={scopedFormData}
                          format={value => (!value ? '0,00' : (+value).toFixed(2))}
                          parse={value => (!value ? 0 : +value.replace(/\D/g, '') / 100)}
                        />
                      </>
                    }
                    {scopedFormData?.drawingRuleId
                      && drawingRuleName.includes('fillin') && <>
                        <SelectInput
                          label="Combustível"
                          style={{ minWidth: 220, marginRight: 10, marginTop: 2 }}
                          choices={fuelChoices}
                          source={getSource('fuelId')}
                          record={scopedFormData}
                          defaultValue={null}
                          format={value => value !== null ? value : 'all'}
                          parse={value => value !== 'all' ? value : null}
                        />
                        <SelectInput
                          label="Tipo do valor"
                          source={getSource('type')}
                          defaultValue={"amount"}
                          style={{ minWidth: 220, marginRight: 10, marginTop: 2 }}
                          choices={[{ id: "amount", name: "Litros" }, { id: "value", name: "Valor" }]}
                          validate={[required()]}
                        />
                        {
                          scopedFormData.type === AlterValueDrawing.value ?
                            <PriceInput
                              source={getSource('value')}
                              label='Valor'
                              style={{ width: 150, marginTop: 2, marginRight: 10 }}
                              validate={[minValue(0)]}
                            />
                            :
                            <TextInput
                              style={{ width: 150, marginTop: 2, marginRight: 10 }}
                              source={getSource('value')}
                              label={getLabelValue(ruleId, drawingRules)}
                              record={scopedFormData}
                              defaultValue={0}
                              validate={[minValue(0)]}
                              format={value => drawingRuleName === 'fillin-count'
                                ? (!value ? '1' : (+value).toFixed(0))
                                : (!value ? '0,00' : (+value).toFixed(2))
                              }
                              parse={value => drawingRuleName === 'fillin-count'
                                ? (!value ? 1 : +value.replace(/\D/g, ''))
                                : (!value ? 0 : +value.replace(/\D/g, '') / 100)
                              }
                              required
                            />
                        }
                      </>
                    }
                    {
                      (scopedFormData?.drawingRuleId && !drawingRuleName.includes('points-exchange')) &&
                      <TextInput
                        source={getSource("luckyNumberAmount")}
                        label="Quantidade de cupons"
                        locales="pt-BR"
                        textAlign="center"
                        style={{ width: 180, marginTop: 2, marginRight: 10 }}
                        defaultValue={1}
                        validate={[minValue(1)]}
                        format={value => (+value).toFixed(0)}
                        parse={value => +value.replace(/\D/g, '')}
                        required
                      />
                    }
                    <DateInput
                      style={{ minWidth: 220, marginRight: 10, marginTop: 2 }}
                      source={getSource("startDate")}
                      label="Data inicial"
                      locales="pt-BR"
                      inputProps={{ max: MAX_DATE }}
                      parse={value => moment(value).startOf('day').toISOString()}
                    />
                    <DateInput
                      style={{ minWidth: 220, marginRight: 10, marginTop: 2 }}
                      source={getSource("endDate")}
                      label="Data final"
                      locales="pt-BR"
                      inputProps={{ max: MAX_DATE }}
                      parse={value => moment(value).endOf('day').toISOString()}
                    />
                    <Grid container alignItems="center" spacing={2}>
                      {
                        scopedFormData?.drawingRuleId
                        && drawingRuleName.includes('fillin') &&
                        <Grid item xs={12} md={6} lg={6} xl={8}>
                          <Tooltip sxContainer={{ alignItems: 'flex-start' }} placement="top-start" title="Você pode indicar quais postos serão abrangidos pela regra. Se deixar em branco, todos os postos serão considerados válidos.">
                            <ReferenceArrayInput
                              reference="stations"
                              source={getSource('stationIds')}
                              perPage={null}
                              sort={{ field: 'name', order: 'ASC' }}
                            >
                              <SelectArrayInput
                                label="Postos (Opcional)"
                                optionText="name"
                                placeholder="Todos"
                                fullWidth
                              />
                            </ReferenceArrayInput>
                          </Tooltip>
                        </Grid>
                      }
                      {scopedFormData?.drawingRuleId
                        && drawingRuleName.includes('fillin') && scopedFormData.type !== AlterValueDrawing.value &&
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                          <Tooltip sxContainer={{ alignItems: 'baseline' }} placement="top-start" title="Se esta opção estiver ativada, o cliente ganhará os números levando em conta todos os abastecimentos do perído do sorteio.">
                            <BooleanInput source={getSource('accumulated')} label='Acumulado' defaultValue
                              sx={{
                                '& .css-j204z7-MuiFormControlLabel-root': {
                                  mr: 1
                                }
                              }} disabled={drawingRuleName === 'fillin-count'} />
                          </Tooltip>
                        </Grid>
                      }
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <BooleanInput source={getSource("isEnabled")} defaultValue label="Regra ativada" sx={{ minWidth: 180 }} />
                      </Grid>
                    </Grid>
                  </Box>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
        <Typography variant="subtitle1" color="textSecondary" style={{ marginTop: 16, width: '100%' }}>
          * Os sorteios devem ser registrados na Caixa Econômica Federal e o registro é de responsabilidade do cliente.
        </Typography>
      </SimpleForm>
    </Edit>
  );
};

export default DrawingEdit;
