import React from "react";
import { Grid } from "@mui/material";
import { BooleanInput, Edit, ImageField, ImageInput, SimpleForm, TextInput } from "react-admin"

const NewsEdit = () => {
  return (
    <Edit title="Editar Novidade">
      <SimpleForm>
        <Grid container spacing={2}>
          <Grid item xs={12} md={10} lg={8}>
            <TextInput source="title" label="Título" fullWidth />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={10} lg={8}>
            <ImageInput source="imagefile" label="Imagem">
              <ImageField source="src" />
            </ImageInput>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={10} lg={8}>
            <TextInput source="link" label="Link" fullWidth />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={4}>
            <BooleanInput source="isShowOnScreen" label="Mostrar na tela inicial" />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  )
}

export default NewsEdit;