import React from 'react';
import { Edit, FormDataConsumer, FormTab, SaveButton, TabbedForm, TextInput, Toolbar, useNotify } from 'react-admin';
import { getChainId } from '../../lib';
import { Grid, Typography } from '@mui/material';
import { Permission, Wrapper } from '../../components';
import { useNavigate } from 'react-router-dom';
import AvailableOfferOnMobile from './availableOfferOnMobile';
import { PERMISSIONS } from '../../constants';

const AppLabelEdit: React.FC = () => {

  const notify = useNotify();
  const navigate = useNavigate();

  const onSuccess = () => {
    notify('ra.notification.updated', { messageArgs: { smart_count: 1 } });
    navigate('/app-labels');
  }

  const onError = () => {
    notify('ra.notification.data_provider_error', { type: 'error' });
    navigate('/app-labels');
  }

  const ToolbarCustom = props => (
    <Toolbar {...props} >
      <SaveButton />
    </Toolbar>
  );

  return (
    <Permission permission={PERMISSIONS.APP_LABEL}>
      {
        getChainId() ?
          <Edit
            id={getChainId()}
            resource="app-labels"
            title="Configuração de textos no aplicativo"
            mutationMode='pessimistic'
            mutationOptions={{ onSuccess, onError }}
            transform={(data: any) => {
              if (data.appLabel?.chainId) { delete data.appLabel.chainId; }
              return data;
            }}
          >
            <TabbedForm toolbar={<ToolbarCustom />}>
              <FormTab label="Tela de Descontos">
                <Grid container md={12} lg={10}>
                  <Grid item xs={12} md={8}>
                    <Grid item xs={12} md={6}>
                      <TextInput source="appLabel.discountTitleLabel" label="Titulo" fullWidth />
                      <TextInput source="appLabel.discountSubtitleLabel" label="Descrição" fullWidth />
                      <TextInput source="appLabel.discountDisclaimerLabel" label="Descrição da regra" fullWidth />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormDataConsumer>
                      {({ formData }) => (
                        <AvailableOfferOnMobile data={formData} />
                      )}
                    </FormDataConsumer>
                  </Grid>
                </Grid>
              </FormTab>
            </TabbedForm>
          </Edit>
          :
          <Wrapper>
            <Typography>Selecione uma rede para fazer alterações</Typography>
          </Wrapper>
      }
    </Permission>
  );
}

export default AppLabelEdit;