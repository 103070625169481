import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import {
  BooleanInput,
  Button,
  CheckboxGroupInput,
  Create,
  FormDataConsumer,
  Labeled,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useChoicesContext,
  usePermissions
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';

import { Box, Grid } from '@mui/material';
import { getChainId } from '../../lib';

const useStyles = makeStyles(() => ({
  nestedPermission: {
    marginLeft: 30,
  },
  checkbox: {
    width: '100%',
  },
}));

const MarkAllPermissions = ({ allPermissions }) => {
  const form = useFormContext();
  const allIdsPermissions = allPermissions.flatMap(({ permissions }) => permissions.map(({ id }) => id));
  const permissions = form.getValues("permissions");

  return (
    <Button
      type="button"
      label="Marcar todas permissões"
      disabled={permissions?.length === allIdsPermissions.length || allIdsPermissions.length === 0}
      color="primary"
      sx={{ height: 50, marginBottom: 2 }}
      variant="contained"
      onClick={() => {
        form.setValue('permissions', allIdsPermissions)
      }}
    />
  )
}

const ProfileCreate = props => {
  const [allPermissions, setAllPermissions] = useState([]);
  const { permissions } = usePermissions();

  return (
    <Permission permission={PERMISSIONS.CREATE_PROFILE}>
      <Create {...props} title="Cadastrar novo usuário">
        <SimpleForm defaultValues={{ chainId: getChainId() }}>
          <Grid container width={{ xs: '100%', xl: 800 }} spacing={2}>
            <Grid item xs={12} md={8}>
              <Permission permission={PERMISSIONS.ADMIN}>
                <ReferenceInput source="chainId" reference="chains" sort={{ field: 'name', order: 'ASC' }} pagination={null} perPage={null}>
                  <SelectInput optionText="name" label="Rede(deixe vazio para usuário Admin)" required={permissions && !permissions.includes(PERMISSIONS.ADMIN_PROFILE)} fullWidth />
                </ReferenceInput>
              </Permission>
              <Box display={{ xs: 'block', sm: 'flex' }}>
                <TextInput source="name" label="Nome" InputProps={{ autoComplete: 'off' }} validate={required()} isRequired fullWidth />
              </Box>
              <Box display={{ xs: 'block', sm: 'flex' }}>
                <BooleanInput source="fullAccess" label="Todas as permissões" defaultValue={false} />
              </Box>
              <Box display={{ xs: 'block', sm: 'flex' }}>
                <MarkAllPermissions allPermissions={allPermissions} />
              </Box>
              <Box display={{ xs: 'block', sm: 'flex' }}>
                <FormDataConsumer>
                  {({ formData }) => !formData.fullAccess &&
                    <Labeled label="Permissões">
                      <ReferenceArrayInput
                        sort={{ field: 'name', order: 'ASC' }}
                        perPage={null}
                        label=""
                        source="permissions"
                        reference="permission-groups"
                        filter={(permissions && !permissions.includes(PERMISSIONS.ADMIN_PROFILE)) && { permissions: { isAdmin: false } }}
                      >
                        <CheckboxGroup setAllPermissions={setAllPermissions} />
                      </ReferenceArrayInput>
                    </Labeled>
                  }
                </FormDataConsumer>
              </Box>
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
    </Permission >
  )
}

const CheckboxGroup = ({ setAllPermissions }) => {
  const classes = useStyles();
  const choices = useChoicesContext();
  const groups = choices && choices.allChoices;

  useEffect(() => {
    if (Array.isArray(groups)) {
      setAllPermissions(groups);
    }
  }, [JSON.stringify(groups)]);

  if (groups) {
    return groups.map(group => {
      if (group.permissions.length > 0) {
        const permissions = group.permissions
          .sort((a, b) => { return (a.label < b.label) ? -1 : (a.label > b.label) ? 1 : 0; });
        return (
          <>
            <SelectAllBtn group={group} />
            <CheckboxGroupInput
              className={classes.nestedPermission}
              source={`permissions`}
              choices={permissions}
              optionText="label"
              row={false}
              format={permissions => {
                return permissions ? permissions.map(p => typeof p === 'object' ? p.id : p) : []
              }}
              label=""
            />
          </>
        )
      }
      return <div />
    })
  }

  return <div />
};

const SelectAllBtn = props => {
  const { group } = props
  const form = useFormContext();
  const classes = useStyles();
  const permissions = form.getValues("permissions");
  let allAllowed = false;

  if (permissions) {
    const allowedPermissions = permissions.map(p => typeof p === 'object' ? p.id : p);
    allAllowed = group.permissions.map(p => p.id).every(id => allowedPermissions.includes(id));
  }

  return (
    <FormControlLabel
      className={classes.checkbox}
      htmlFor={group.id}
      key={group.id}
      onChange={event => {
        const checked = event.target.checked;
        const previousPermissions = (form.getValues("permissions") || []).map(p => typeof p === 'object' ? p.id : p);

        group.permissions.forEach(p => {
          const index = previousPermissions.indexOf(p.id);
          const contains = index > -1;

          if (checked && !contains) {
            previousPermissions.push(p.id);
          } else if (!checked && contains) {
            previousPermissions.splice(index, 1);
          }
        });

        form.setValue('permissions', previousPermissions)
      }}
      control={
        <Checkbox
          id={group.id}
          color="primary"
          checked={allAllowed}
        />
      }
      label={group.name}
    />
  )
};

export default ProfileCreate;