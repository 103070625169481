import React from 'react';
import { ExportButton, useListContext, useRedirect } from 'react-admin';
import DiscountIcon from '@mui/icons-material/Discount';

const CreateBulkSendButton: React.FC<any> = (props) => {

  const redirect = useRedirect();
  const { resource } = useListContext();

  const createDiscount = (records: any[]) => {
    redirect('/bulk-sends/create', undefined, undefined, undefined, { customerIds: records.map(record => record.id) });
  }

  return (
    <ExportButton
      resource={resource}
      icon={<DiscountIcon style={{ fontSize: 18 }} />}
      label="Enviar SMS"
      exporter={createDiscount}
      variant='outlined'
      sx={{ height: 32, marginBottom: '3px' }}
      //@ts-ignore
      maxResults={null} //unlimited
    />
  );
}

export default CreateBulkSendButton;