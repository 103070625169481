import React from 'react';
import {
    TextField,
    useListContext,
    ReferenceField,
    NumberField,
    DateField,
    Datagrid,
    downloadCSV,
    FunctionField,
} from 'react-admin';
import ListPagination from '../../components/ListPagination';
import ReportList from '../../components/ReportList';
import jsonExport from 'jsonexport/dist';
import moment from 'moment';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PhoneField, PartiallyHiddenCpfField } from '../../components/Fields';
import { BetweenDatesInput } from '../../components/BetweenDatesInput';
import { PERMISSIONS } from '../../constants';
import Permission from '../../components/Permission';
import { formatPoints } from '../../utils';

const useStyles = makeStyles(theme => ({
    title: {
        margin: '10px 0 10px 0',
        textAlign: 'center',
        fontWeight: 'bold'
    },
}));

const RegistrationCustomerList = () => {
    const classes = useStyles();
    const props = useListContext();
    return (
        <>
            <Datagrid bulkActionButtons={false} style={{ marginBottom: 60 }} data={{ 'summary': { total: props.total } }} total={1}>
                <NumberField
                    source="total"
                    label="Total de clientes"
                    textAlign="center"
                    emptyText="0"
                    sortable={false}
                />
            </Datagrid>

            <Typography className={classes.title}>Clientes Cadastrados</Typography>
            <Datagrid
                resource={props.resource}
                data={props.data}
                ids={props.ids}
                rowClick={false}
            >
                <ReferenceField
                    label="Nome"
                    emptyText="Nome"
                    source="id"
                    reference="customers"
                    link={false}
                >
                    <TextField source="name" />
                </ReferenceField>
                <PartiallyHiddenCpfField source="cpf" label="CPF" />
                <PhoneField label="Telefone" source="cellphone" emptyText="Telefone não encontrado" />
                <DateField source="birth_date" label="Data de nascimento" textAlign='center' />
                <TextField source='group' label="Grupo" textAlign='center' />
                <ReferenceField
                    label="Saldo"
                    source="id"
                    reference="customer-balance"
                    emptyText="0"
                    sortable={false}
                    link={false}
                >
                    <FunctionField
                        source="balance"
                        render={record => formatPoints(+record?.balance)}
                    />
                </ReferenceField>
            </Datagrid>
        </>
    );
}

const listFilters = [
    <BetweenDatesInput alwaysOn />,
];

const fillinsExporter = async (data, fetchRelatedRecords) => {
    jsonExport(data, (err, csv) => {
        downloadCSV(csv, 'registration-customers');
    });
}

const FilterTablePrint = () => {
    const classes = useStyles();
    const { filterValues } = useListContext();
    return (
        <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
            <DateField source="from" textAlign="center" label="De" sortable={false} />
            <DateField source="to" textAlign="center" label="Até" sortable={false} />
        </Datagrid>
    );
}

const ReportRegistrationCustomer = (props) => {
    return (
        <Permission permission={PERMISSIONS.REGISTRATION_CUSTOMERS_REPORT}>
            <ReportList
                {...props}
                basePath='report-registration-customers'
                title="Cadastro de clientes"
                resource="chains/chainId/reports/registration-customers"
                filters={listFilters}
                sort={{ field: 'created_at', order: 'DESC' }}
                perPage={25}
                filterDefaultValues={{
                    from: moment().startOf('day').subtract(1, 'month').toISOString(),
                    to: moment().endOf('day').toISOString()
                }}
                pagination={<ListPagination />}
                bulkActionButtons={false}
                exporter={fillinsExporter}
                titleOnPrint="Relatório de cadastro de clientes"
                filterTablePrint={<FilterTablePrint />}
                landscape
            >
                <RegistrationCustomerList />
            </ReportList>
        </Permission >
    );
};

export default ReportRegistrationCustomer;