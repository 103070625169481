import { PERMISSIONS } from '../../constants';
import list from './externalPartnersList';
import create from './externalPartnersCreate';
import edit from './externalPartnersEdit';

export default {
  list,
  create,
  edit,
  name: 'external-partners',
  permissions: [PERMISSIONS.ADMIN]
};