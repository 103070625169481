import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {
  BooleanField,
  Button,
  DateField,
  EmailField,
  RichTextField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin';
import { SupportTicketIsReadedButton } from '../../components';
import Permission from '../../components/Permission';
import SupporTicketIsResolvedButton from '../../components/SupportTicketIsResolvedButton';
import { DATE_FORMATER, PERMISSIONS } from '../../constants';

const WhatsappLink = (data) => {
  const record = useRecordContext();
  if (!record) { return null; }

  return (
    <Button
      label="Responder (WhatsApp)"
      startIcon={<WhatsAppIcon />}
      onClick={() => window.open(`https://wa.me/55${record.customer.cellphone}`)}
      variant="outlined"
      style={{ marginTop: 16 }}
    />
  );
}

export default props => (
  <Permission permission={PERMISSIONS.LIST_MESSAGES}>
    <Show {...props} title="Mensagem">
      <SimpleShowLayout>
        <DateField source="createdAt" label="Data" options={DATE_FORMATER} />
        <TextField
          source="subject.name"
          label="Assunto"
          emptyText="Sem Assunto"
        />
        <TextField
          source="place.name"
          label="Nome do Posto"
          emptyText="Todos os Postos"
        />
        <TextField source="customer.name" label="Nome do Cliente" />
        <TextField source="customer.cellphone" label="Celular" />
        <EmailField source="customer.email" label="E-mail" />
        <RichTextField source="message" label="Mensagem" />
        <BooleanField source="isResolved" label="Resolvida" />
        <SupportTicketIsReadedButton isOutlined enablesIcon />
        <SupporTicketIsResolvedButton isOutlined enablesIcon />
        <WhatsappLink />
      </SimpleShowLayout>
    </Show>
  </Permission>
)
