import Store from '@mui/icons-material/Store'
import { PERMISSIONS } from '../../constants'
import list from './ExternalPaymentMethodList'
import create from './CreateExternalPaymentMethod'
import edit from './EditExternalPaymentMethod'

export default {
  list,
  create,
  edit,
  name: 'chains/chainId/external-payment-methods',
  permissions: [PERMISSIONS.MANAGE_EXTERNAL_PAYMENT_METHOD],
  options: { label: 'Formas de pagamento por parceiros' },
  icon: Store,
}
