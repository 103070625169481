import { useEffect, useState } from 'react';
import {
  ArrayInput,
  BooleanInput,
  Button,
  Datagrid,
  DateInput,
  DeleteButton,
  DeleteWithConfirmButton,
  Edit,
  FormDataConsumer,
  FormTab,
  FunctionField,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  maxValue,
  minValue,
  required,
  useDataProvider,
  useEditContext,
  useGetList,
  useGetOne,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
  useSaveContext
} from 'react-admin';

import { AddCircle as AddCircleIcon, Cancel as CancelIcon, ListAlt as ReadjustIcon, RemoveCircle as RemoveCircleIcon, Save as SaveIcon } from '@mui/icons-material';
import { Alert, AlertTitle, Box, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, Modal, Radio, RadioGroup, Switch, TextField as TextFieldMui, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';
import { useFormState } from 'react-hook-form';
import { ImageInformations, LazyLoadAutoCompleteInput, PriceInput } from '../../components';
import AddCustomItemButton from '../../components/AddCustomItemButton';
import CustomSelect from '../../components/CustomSelect';
import Permission from '../../components/Permission';
import { MAX_DATE, PERMISSIONS } from '../../constants';
import { getChainId } from '../../lib';
import { TagType } from '../../models';
import { dateInputFormatter } from '../../utils';
import ProductStatement from './ProductStatement';

const editStyles = makeStyles({
  arrayInput: {
    '& p': {
      color: 'grey',
      'margin-right': '1em',
      'font-weight': 'bold',
    },
  },
  placeName: {
    padding: '1em 0 0.5em 0',
    width: '100%',
  },
  stockSwitch: {
    margin: '2em 2em 0em 1em'
  },
  Toolbar: {
    justifyContent: 'space-between'
  },
  ToolbarButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '250px'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 8,
    paddingTop: 8,
  },
  table: {
    border: '1px solid #ccc',
  },
  boxModal: {
    backgroundColor: 'white',
    border: '1px solid #ccc',
    borderRadius: 4,
    boxShadow: 24,
    left: '50%',
    maxHeight: '75%',
    padding: '20px 24px',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
  },
});

const GiftAlertCard = (props) => (
  <Alert variant="outlined" severity="info" {...props}>
    <AlertTitle>Brindes</AlertTitle>
    Há um brinde vinculado a esse produto. Certifique que suas alterações sejam feitas no cadastro do brinde também.
  </Alert>
);

const CustomToolbar = ({ hasReadjustment, setShowModal, setHasGift, ...props }) => {
  const classes = editStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const { resource } = useEditContext();
  const record = useRecordContext();
  const { save } = useSaveContext();
  const { dirtyFields, isSubmitting } = useFormState();
  const isDirty = Object.keys(dirtyFields).length > 0;

  const { data: chain } = useGetOne('chains', { id: getChainId() });
  const { data: gifts } = useGetList('gifts', { filter: { productId: record.id } })

  if (!record) { return null; }

  const handleSaveButton = async (formData) => {
    let hasGift = false;
    if (chain && chain.useGift && gifts) {
      hasGift = gifts.length > 0;
    }

    setHasGift(hasGift);

    if (hasGift || hasReadjustment) {
      setShowModal(true);
    } else {
      save(formData);
    }
  }

  return (
    <Toolbar className={classes.Toolbar}>
      <FormDataConsumer>
        {({ formData }) =>
          <Button
            startIcon={isSubmitting ? <CircularProgress size={20} /> : hasReadjustment ? <ReadjustIcon /> : <SaveIcon />}
            disabled={isSubmitting || (!isDirty && !hasReadjustment)}
            label={hasReadjustment ? 'Verificar reajustes' : 'Salvar'}
            size="medium"
            variant="contained"
            onClick={() => handleSaveButton(formData)}
          />
        }
      </FormDataConsumer>
      <Permission permission={PERMISSIONS.DISABLE_PRODUCT}>
        <div className={classes.ToolbarButtonWrapper}>
          <Button
            startIcon={record.isDisabled ? <AddCircleIcon /> : <RemoveCircleIcon />}
            label={record.isDisabled ? 'Ativar' : 'Desativar'}
            onClick={() => {
              dataProvider.update(resource, { id: record.id, data: { isDisabled: !record.isDisabled }, previousData: { ...record } })
                .then(() => {
                  notify(`Produto ${record.isDisabled ? 'ativado' : 'desativado'} com sucesso`)
                }).catch(() => {
                  notify(`Erro ao ${record.isDisabled ? 'ativar' : 'desativar'} produto`, { type: 'warning' })
                });

              refresh();
            }}
          />

          {
            gifts?.length > 0 ?
              <DeleteWithConfirmButton
                confirmTitle="Aviso"
                confirmContent={
                  <GiftAlertCard />
                }
              />
              :
              <DeleteButton />
          }
        </div>
      </Permission>
    </Toolbar>
  );
}

const getCurrentValue = (prev, payload) => {
  switch (payload.action) {
    case 'increase':
      return +prev[payload.type] + (+payload.value || 0);
    case 'decrease':
      return +prev[payload.type] - (+payload.value || 0);
    case 'fixed':
      return payload.value;
    default:
      break;
  }
}

const AlertModal = ({ open, onClose, data, ids, state: { type, action, value }, formData, hasReadjustment, hasGift }) => {
  const [loading, setLoading] = useState(false);
  const classes = editStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const record = useRecordContext();
  const { save } = useSaveContext();

  const handleSave = () => {

    if (!hasReadjustment) {
      onClose();
      save(formData);
      return;
    }

    setLoading(true);
    const newProductPlaces = formData.places.map((item) => {
      if (ids.includes(item.placeId)) {
        return {
          ...item,
          [type]: getCurrentValue(item, { type, action, value })
        };
      }

      return item;
    });
    const newData = { ...formData, places: newProductPlaces };

    const hasNegativeValues = newProductPlaces.some((item) => item[type] < 0);

    if (hasNegativeValues) {
      notify('Os valores atualizados devem ser maior que 0', { type: 'warning' });
      onClose();
    } else {
      formData.places = newProductPlaces;
      dataProvider.update('chains/chainId/products', {
        id: record.id, data: newData, previousData: { ...record }
      })
        .then(() => {
          onClose();
          notify('Reajuste realizado com sucesso');
        }).catch(() => {
          onClose();
          notify('Erro ao realizar reajuste', { type: 'warning' });
        })
        .finally(() => {
          redirect('/chains/chainId/products');
          setLoading(false);
        });
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.boxModal}>
        <Typography variant="h6">
          Avisos
        </Typography>
        {
          hasReadjustment &&
          <>
            <Typography variant="subtitle1">
              Estes serão os valores atualizados. Deseja continuar?
            </Typography>
            <div style={{ overflowY: 'auto', maxHeight: '65vh', marginTop: 8 }}>
              <Datagrid
                sort={{ field: 'name', order: 'ASC' }}
                bulkActionButtons={false}
                data={data}
                classes={{ table: classes.table }}
              >
                <TextField label="Ponto de Troca" source="name" />
                <FunctionField
                  label="Valor Anterior"
                  render={(record) => type === 'partnerPrice' ? `R$ ${record[type]}` : record[type]}
                />
                <FunctionField
                  label="Valor Atualizado"
                  render={(record) => type === 'partnerPrice' ? `R$ ${getCurrentValue(record, { type, action, value })}` : getCurrentValue(record, { type, action, value })}
                />
              </Datagrid>
            </div>
          </>
        }

        {
          hasGift &&
          <GiftAlertCard sx={{ marginTop: 2, marginBottom: 2 }} />
        }

        <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between', padding: 0 }}>
          <Button
            startIcon={<CancelIcon />}
            variant="outlined"
            onClick={onClose}
            label="Cancelar"
            size="medium"
            disabled={loading}
          />
          <Button
            startIcon={<SaveIcon />}
            variant="contained"
            onClick={handleSave}
            label="Salvar"
            size="medium"
            disabled={loading}
          />
        </div>
      </Box>
    </Modal>
  );
}

const ReadjustmentTab = ({ showModal, setShowModal, hasReadjustment, hasGift, setHasReadjustment, data, placeOpts }) => {
  const [selectsAllPlaces, setselectsAllPlaces] = useState(false);
  const [productPlaces, setProductPlaces] = useState([]);
  const [selectedPlaceIds, setSelectedPlaceIds] = useState([]);
  const [action, setAction] = useState();
  const [type, setType] = useState();
  const [value, setvalue] = useState();

  const formatCurrency = (v) => {
    const onlyNumbers = v.replace(/[^\d]/g, '');
    return onlyNumbers ? (onlyNumbers / Math.pow(10, 2)) : '';
  }

  const validation = (v, max) => (typeof v === 'number' && v < max);

  useEffect(() => {
    const currProductPlaces = data.places?.map(p => {
      const placeName = placeOpts.find(({ id }) => id === p?.placeId);
      return placeName && { ...p, ...placeName };
    }).filter(item => item);

    setProductPlaces(currProductPlaces || []);
  }, [data.places, placeOpts]);

  useEffect(() => {
    if (type && action && value && selectedPlaceIds?.length) {
      setHasReadjustment(true);
    } else {
      setHasReadjustment(false);
    }
  }, [type, action, value, selectedPlaceIds]);

  useEffect(() => {
    if (selectsAllPlaces) {
      setSelectedPlaceIds(productPlaces.map(({ id }) => id));
    } else {
      setSelectedPlaceIds([]);
    }
  }, [selectsAllPlaces]);

  const dataResult = [];

  productPlaces.forEach(({ id, name, price, partnerPrice }) => {
    dataResult.push({ id, name, price, partnerPrice });
  });

  return (
    productPlaces.length ? <>
      {!selectsAllPlaces &&
        <CustomSelect
          label="Pontos de Troca"
          choices={productPlaces}
          value={selectedPlaceIds}
          onChange={setSelectedPlaceIds}
          multiple
        />}
      <FormControlLabel
        label="Selecionar todos os locais"
        style={{ display: 'block', marginBottom: 16 }}
        control={<Switch onChange={({ target }) => setselectsAllPlaces(target.checked)} />}
      />
      <FormControl>
        <FormLabel>Tipo de valor</FormLabel>
        <RadioGroup row onChange={({ target }) => setType(target.value)}>
          <FormControlLabel value="price" control={<Radio />} label="Pontos" />
          <FormControlLabel value="partnerPrice" control={<Radio />} label="Reais" />
        </RadioGroup>
      </FormControl>
      <CustomSelect
        label="Tipo de ação"
        value={action}
        onChange={setAction}
        choices={[{ id: "increase", name: "Aumentar" }, { id: "decrease", name: "Diminuir" }, { id: "fixed", name: "Fixo" }]}
        style={{ marginRight: 16, heigth: 16 }}
        variant="filled"
        small
      />
      {type === 'price' &&
        <TextFieldMui
          label="Valor em pontos"
          value={value}
          onChange={({ target }) => setvalue(target.value.replace(/[^\d]/g, ''))}
          error={value && value < 1}
          helperText={value && value < 1 && "Deve ser no mínimo 1"}
          variant="filled"
          size="small"
          style={{ width: 160 }}
        />}
      {type === 'partnerPrice' &&
        <TextFieldMui
          label="Valor em reais"
          value={value && `R$ ${(+value).toFixed(2)}`}
          onChange={({ target }) => setvalue(formatCurrency(target.value))}
          error={validation(value, 0.01)}
          helperText={validation(value, 0.01) && "Deve ser no mínimo R$ 0.01"}
          variant="filled"
          size="small"
          style={{ width: 160 }}
        />}
      <Typography variant="subtitle2" style={{ marginTop: 16 }}>
        * Todos os campos precisam ser preenchidos para realizar as alterações.
      </Typography>
      <FormDataConsumer>
        {({ formData }) =>
          <AlertModal
            data={dataResult}
            ids={selectedPlaceIds}
            open={showModal}
            onClose={() => setShowModal(false)}
            state={{ type, action, value }}
            formData={formData}
            hasReadjustment={hasReadjustment}
            hasGift={hasGift}
          />
        }
      </FormDataConsumer>
    </>
      : <Typography variant="subtitle2">Nenhum local cadastrado para este produto.</Typography>
  );
}

const edit = () => {
  const [hasReadjustment, setHasReadjustment] = useState(false);
  const [hasGift, setHasGift] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const classes = editStyles();
  const [fuels, setFuels] = useState([]);
  const [placeOpts, setPlaceOpts] = useState([]);

  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider.getList(`chains/${getChainId()}/places`, { filter: {}, sort: { field: 'name', order: 'ASC' } })
      .then(({ data }) => {
        setPlaceOpts(data);
      });

    dataProvider.getList('fuels', { filter: {}, sort: { field: 'name', order: 'ASC' } })
      .then(({ data }) => {
        setFuels(data);
      });
  }, [])

  const validate = [required()];

  const saleProductOptionRenderer = choice => `${choice.code} - ${choice.name}`;

  const transform = data => {
    data.productSaleProducts = data.productSaleProducts.map(p => p && (p.productSaleProducts || p.saleProductId));
    data.places = data.places.map(place => ({
      ...place,
      productPlaceGiftFuels: (place.productPlaceGiftFuels || []).map(ppgf => ({
        ...ppgf,
        startDate: ppgf.startDate ? dayjs(ppgf.startDate).startOf('day') : null,
        endDate: ppgf.endDate ? dayjs(ppgf.endDate).endOf('day') : null,
      })),
    }));

    if (data.imagePath?.rawFile) {
      data.imagefile = data.imagePath;
    }

    return data;
  };

  return (
    <Permission permission={PERMISSIONS.UPDATE_PRODUCT}>
      <Edit title="Editar produto"
        transform={transform}
        queryOptions={{
          select: (data) => {
            if (typeof data.imagePath === 'string') {
              data.imagePath = {
                src: data.imagePath,
              }
            }
            return data;
          }
        }}
      >
        <TabbedForm
          sanitizeEmptyValues
          toolbar={<CustomToolbar
            setShowModal={setShowModal}
            hasReadjustment={hasReadjustment}
            setHasGift={setHasGift}
          />}
        >
          <FormTab label="Produto">
            <Grid container lg={8} md={12} spacing={2}>
              <Grid item xs={12} md={12}>
                <TextInput source="name" label="Nome" validate={validate} InputProps={{ autoComplete: 'off' }} fullWidth />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextInput
                  source="description"
                  label="Descrição"
                  validate={validate}
                  InputProps={{ autoComplete: 'off' }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <ReferenceInput
                  source="categoryId"
                  reference="chains/chainId/product-categories"
                  perPage={null}
                  sort={{ field: 'name', order: 'ASC' }}>
                  <SelectInput
                    label="Categoria"
                    optionText="name"
                    emptyText="Nenhuma"
                    fullWidth
                  />
                </ReferenceInput>
              </Grid>
              <Grid item xs={12} md={8}>
                <ReferenceArrayInput
                  sort={{ field: 'name', order: 'ASC' }}
                  filter={{ type: TagType.product }}
                  perPage={null}
                  source="tagIds"
                  reference="chains/chainId/tag"
                >
                  <SelectArrayInput
                    label="Etiquetas"
                    optionText="name"
                    fullWidth
                  />
                </ReferenceArrayInput>
              </Grid>
              <Grid item xs={12} md={12}>
                <ArrayInput
                  label="Produtos de Venda"
                  source="productSaleProducts"
                  className={classes.arrayInput}
                >
                  <SimpleFormIterator fullWidth disableReordering>
                    <LazyLoadAutoCompleteInput
                      label="Produto de Venda"
                      source="saleProductId"
                      format={saleProductOptionRenderer}
                      style={{ margin: 15 }}
                      reference="chains/chainId/sale-products"
                      fullWidth
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              </Grid>
              <Grid item xs={12} md={12}>
                <ImageInput source="imagePath" label="Imagem" accept="image/*">
                  <ImageField source="src" label="Imagem" />
                </ImageInput>
                <ImageInformations />
              </Grid>
            </Grid>
          </FormTab>
          <FormTab label="Resgate">
            <FormDataConsumer>
              {({ formData: upperFormData, ...upperRest }) => {
                const disableRemove = upperFormData.places
                  ? upperFormData.places.length === 1
                  : false
                const disableAdd = upperFormData.places ? upperFormData.places.length === placeOpts.length : false;
                return (
                  <ArrayInput source="places" label="" className={classes.arrayInput}>
                    <SimpleFormIterator
                      disableReordering
                      disableRemove={disableRemove}
                      disableAdd={disableAdd}
                    >
                      <FormDataConsumer>
                        {({ formData, scopedFormData, getSource }) => {
                          // getSource();
                          const selectedPlacesIds = formData.places ? formData.places.filter((p) => p).map(
                            p => p.placeId,
                          ) : [];

                          const availablePlaces = placeOpts
                            .filter(
                              ({ id }) => !selectedPlacesIds.includes(id) ||
                                (scopedFormData ? scopedFormData.placeId === id : false),
                            );
                          return getSource && (
                            <div className={classes.row}>
                              {formData.places && (
                                <SelectInput
                                  source={getSource("placeId")}
                                  choices={availablePlaces}
                                  label="Ponto de troca"
                                  emptyText="Nenhum"
                                  optionText="name"
                                  style={{ width: 280 }}
                                />
                              )}
                              <NumberInput
                                source={getSource("price")}
                                label="Valor em pontos"
                                validate={[required(), minValue(0)]}
                                min={0}
                                style={{ width: 140 }}
                              />
                              <PriceInput style={{ width: 120 }} source={getSource("partnerPrice")} label="Valor em reais" />
                              <NumberInput
                                source={getSource("limit")}
                                label="Resgates por dia"
                                min={1}
                                validate={minValue(1)}
                                style={{ width: 160 }}
                              />
                              <TextInput
                                source={getSource("discountValue")}
                                label="Valor do Desconto(%)"
                                format={(value) => value && (+value * 100).toFixed(0)}
                                parse={(value) => value && +value.replace(/[^\d]/g, '') / 100}
                                defaultValue={1}
                                validate={[required(), minValue(0.01, 'Deve ser no mínimo 1'), maxValue(1, 'Deve ser no máximo 100')]}
                                style={{ width: 160 }}
                              />
                              <FormDataConsumer>
                                {() => {
                                  if (scopedFormData && placeOpts.find((place) => place.id === scopedFormData.placeId && place.rtiToken != null)) {
                                    return (
                                      <NumberInput
                                        source={getSource("rtiCode")}
                                        label="Código RTI"
                                        style={{ width: 140 }}
                                        validate={[minValue(0)]}
                                        min={0}
                                      />
                                    );
                                  } else if (scopedFormData && placeOpts.length) {
                                    scopedFormData.rtiCode = null;
                                  }
                                }}
                              </FormDataConsumer>
                            </div>
                          );
                        }}
                      </FormDataConsumer>
                      <BooleanInput
                        label="Brinde"
                        source="isGift"
                      />
                      <FormDataConsumer>
                        {({ scopedFormData, getSource }) => {
                          const disableAddFuel = scopedFormData?.productPlaceGiftFuels ? scopedFormData.productPlaceGiftFuels.length === fuels.length : false;

                          return scopedFormData && scopedFormData.isGift && getSource &&
                            <ArrayInput source={getSource('productPlaceGiftFuels')} label="Combustíveis">
                              <SimpleFormIterator
                                disableAdd={disableAddFuel}
                                disableReordering
                                addButton={<AddCustomItemButton name={getSource('productPlaceGiftFuels')} resetFields={{}} />}
                              >
                                <FormDataConsumer>
                                  {({ scopedFormData: placeProductScoped, getSource }) => {
                                    const selectedfuelIds = scopedFormData.productPlaceGiftFuels ? scopedFormData.productPlaceGiftFuels.filter((p) => p).map(
                                      p => p.fuelId,
                                    ) : [];

                                    const availableFuels = fuels.filter(
                                      ({ id }) => !selectedfuelIds.includes(id) ||
                                        (placeProductScoped ? placeProductScoped.fuelId === id : false),
                                    );

                                    return getSource && (
                                      <div className={classes.row}>
                                        <NumberInput
                                          label={"Quantidade mínima (litros)"}
                                          source={getSource("minimumAmount")}
                                          style={{ width: 200 }}
                                          validate={minValue(0)}
                                        />
                                        <SelectInput
                                          label="Combustível"
                                          emptyText="Todos"
                                          source={getSource("fuelId")}
                                          choices={availableFuels}
                                          optionText="name"
                                        />
                                        <DateInput label="Data inicial" source={getSource("startDate")} format={dateInputFormatter} inputProps={{ max: MAX_DATE }} />
                                        <DateInput label="Data final" source={getSource("endDate")} format={dateInputFormatter} inputProps={{ max: MAX_DATE }} validate={minValue(placeProductScoped.startDate, `Deve ser no mínimo ${dayjs(placeProductScoped.startDate).format("DD/MM/YYYY")}`)} />
                                        <NumberInput
                                          label="Limite de resgate"
                                          source={getSource("limitQuantity")}
                                          style={{ width: 160 }}
                                          parse={v => {
                                            if (v < 0) { return 0; }
                                            return !v || v === '' ? null : Math.floor(v);
                                          }}
                                        />
                                        <BooleanInput label="Validar em outro local" source={getSource("redeemAnywhere")} />
                                      </div>
                                    )
                                  }}
                                </FormDataConsumer>
                              </SimpleFormIterator>
                            </ArrayInput>
                        }}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                )
              }}
            </FormDataConsumer>
          </FormTab>
          <FormTab label="Reajuste">
            <FormDataConsumer>
              {({ formData }) =>
                <ReadjustmentTab
                  showModal={showModal}
                  setShowModal={setShowModal}
                  hasReadjustment={hasReadjustment}
                  hasGift={hasGift}
                  setHasReadjustment={setHasReadjustment}
                  data={formData}
                  placeOpts={placeOpts}
                />
              }
            </FormDataConsumer>
          </FormTab>
          <FormTab label="Estoque">
            <BooleanInput
              label="Controlar estoque"
              source="stock"
              className={classes.stockSwitch}
            />
            <FormDataConsumer>
              {({
                formData,
              }) => {
                if (formData.stock) {
                  return (
                    <ArrayInput
                      source="places"
                      label=""
                      className={classes.arrayInput}>
                      <SimpleFormIterator
                        disableReordering
                        disableRemove
                        disableAdd
                      >
                        <FormDataConsumer>
                          {({
                            scopedFormData,
                            getSource,
                          }) => {
                            if (scopedFormData && scopedFormData.placeId) {
                              if (scopedFormData.place) {
                                scopedFormData.name = scopedFormData.place.name
                              } else {
                                const place = placeOpts.find((p) => p.id === scopedFormData.placeId);
                                if (place) {
                                  scopedFormData.name = place.name
                                }
                              }
                              getSource && getSource('')

                              return (
                                <>
                                  <TextField
                                    source={'name'}
                                    record={scopedFormData}
                                    component="p"
                                    variant='subtitle1'
                                    className={classes.placeName}
                                  />
                                </>
                              )
                            } else {
                              return null;
                            }
                          }
                          }
                        </FormDataConsumer>
                        <SelectInput
                          source={'defaultStock'}
                          choices={[
                            { id: 'showcase', name: 'Mostruário' },
                            { id: 'warehouse', name: 'Depósito' },
                          ]}
                          label="Estoque padrão"
                          validate={validate}
                        />
                        <NumberInput
                          source='minimumStock'
                          label='Estoque mínimo'
                          min={0}
                        />
                        <TextInput
                          source={'showcase'}
                          label="Mostruário"
                          disabled
                        />
                        <TextInput
                          source={'warehouse'}
                          label="Depósito"
                          disabled
                        />
                      </SimpleFormIterator>
                    </ArrayInput>
                  )
                } else {
                  return null;
                }
              }
              }
            </FormDataConsumer>
          </FormTab>
          <Permission permission={PERMISSIONS.STOCK_CHANGES_REPORT}>
            <FormTab label="Extrato" path="transactions">
              <FormDataConsumer>
                {({ formData }) => {
                  return <ProductStatement id={formData.id} />
                }}
              </FormDataConsumer>
            </FormTab>
          </Permission>
        </TabbedForm>
      </Edit>
    </Permission >
  )
}

export default edit
