export enum StoresType {
  appStore = 'app-store',
  playStore = 'play-store',
}

export namespace StoresType {
  export const translateType = (type: StoresType): string => {
    switch (type) {
      case StoresType.appStore:
        return "Apple Store";
      case StoresType.playStore:
        return "Google Store";
      default:
        return "";
    }
  };
};