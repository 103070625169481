import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  RadioButtonGroupInput,
  ReferenceInput,
  SelectInput,
  required,
  minLength,
  email,
  BooleanInput,
  FormDataConsumer,
} from 'react-admin'
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { makeStyles } from '@mui/styles';
import { CPFInput } from '../../components/Inputs';
import { EmployeeType } from '../../models';
import { getChainId } from '../../lib';

const useStyles = makeStyles((theme) => ({
  profiles: {
    width: 220,
  }
}));

const validateEmail = [required(), email()];
const validatePassword = [required(), minLength(6)];

export default props => {
  const classes = useStyles();
  return (
    <Permission permission={PERMISSIONS.CREATE_PROMOTER}>
      <Create {...props} title="Cadastrar novo promoter">
        <SimpleForm defaultValues={{ chainId: getChainId(), type: EmployeeType.promoter }}>
          <Permission permission={PERMISSIONS.ADMIN} >
            <ReferenceInput {...props} label="Rede" source="chainId" reference="chains" sort={{ field: 'name', order: 'ASC' }} perPage={null} validate={[required()]}>
              <SelectInput optionText="name" InputProps={{ autoComplete: 'off' }} />
            </ReferenceInput>
          </Permission>
          <TextInput source="name" label="Nome" validate={[required()]} InputProps={{ autoComplete: 'off' }} />
          <CPFInput source="cpf" label="CPF" validate={[required()]} isRequired />
          <TextInput source="login" label="Login" validate={validateEmail} InputProps={{ autoComplete: 'off' }} />
          <TextInput source="password" label="Senha" validate={validatePassword} InputProps={{ autoComplete: 'new-password' }} />
          <TextInput
            source="code"
            label="Código"
            inputProps={{
              maxLength: 32,
            }}
            InputProps={{ autoComplete: 'off' }}
            parse={value => value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()}
          />
          <FormDataConsumer>
            {({ formData }) => (
              <ReferenceInput
                className={classes.profiles}
                label="Perfil"
                source="profileId"
                reference={'profiles'}
                filter={{ chainId: formData.chainId }}
                sort={{ field: 'name', order: 'ASC' }}
                // @ts-ignore
                perPage={null}
                validate={[required()]}
              >
                <RadioButtonGroupInput label="Perfil" optionText="name" />
              </ReferenceInput>
            )}
          </FormDataConsumer>
          <BooleanInput source="mustChangePassword" label="Forçar usuário a trocar senha no primeiro acesso" />
        </SimpleForm>
      </Create>
    </Permission>
  );
}