export enum TransactionGroupBy {
  employee = 'employee',
  attendant = 'attendant',
};

export const TransactionGroupByChoices = [
  {
    name: 'Funcionário',
    value: TransactionGroupBy.employee,
  },
  {
    name: 'Frentista',
    value: TransactionGroupBy.attendant,
  },
]