import { useState } from 'react';
import {
  Button,
  CreateButton,
  Datagrid,
  Filter,
  FormDataConsumer,
  FunctionField,
  List,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TextField,
  Toolbar,
  TopToolbar,
  useListContext,
} from 'react-admin';

import ClearIcon from '@mui/icons-material/Clear';
import { Box, FilledInput, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { ThemeProvider, makeStyles } from '@mui/styles';
import { CheckChainUsesModule, CustomExportButton, ModuleKey, Permission } from '../../components';
import { PartiallyHiddenCpfField, PhoneField } from "../../components/Fields";
import ListPagination from '../../components/ListPagination';
import { PERMISSIONS } from '../../constants';
import { getEmployeeType, getUserId } from '../../lib';
import { EmployeeType, TagType } from '../../models';
import { theme } from '../../theme';
import { formatPoints } from '../../utils';

const useStyles = makeStyles({
  toolbar: {
    marginTop: 0,
    background: '#ffffff',
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'left'
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    '& #rectangle': {
      backgroundColor: '#ff9d85',
      width: '2rem',
      height: '1.2rem',
      marginLeft: '0.8rem'
    }
  },
  topToolBar: {
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    marginTop: 0,
    minHeight: 0,
  }
});

type DataType = Array<{ [key: string]: any }>;
type RelationType = { field: string, resource: string };

const CustomPagination = () => {
  const classes = useStyles();
  const { total } = useListContext();
  return (
    total >= 1 ?
      <Toolbar className={classes.toolbar}>
        <Box className={classes.box}>
          <Typography>Clientes Bloqueados</Typography>
          <Box id="rectangle" />
        </Box>
        <ListPagination />
      </Toolbar>
      : <Toolbar className={classes.toolbar}>
        <Typography>Nenhum resultado encontrado</Typography>
      </Toolbar>
  );
}

const blockedCustomer = (record) => ({
  backgroundColor: record?.isBlocked ? '#ff9d85' : '#ffffff'
})

const ClientFilter = props => {
  const { filterValues } = useListContext();
  const classes = useStyles();

  return (
    <Filter {...props} classes={{ form: classes.form }}>
      <ReferenceInput source="groupId" reference="chains/chainId/customer-groups" sort={{ field: "name", order: "ASC" }} alwaysOn>
        <SelectInput label="Grupo" optionText="name" emptyText="Todos" />
      </ReferenceInput>
      <SelectInput
        source="isBlocked"
        label="Status"
        choices={[{ id: true, name: "Bloqueados" }, { id: false, name: "Não Bloqueados" }]}
        emptyText="Todos"
        alwaysOn
      />
      <Permission permission={PERMISSIONS.ADMIN} alwaysOn>
        <ReferenceInput
          source="chainId"
          reference="chains"
          sort={{ field: "name", order: "ASC" }}
        >
          <SelectInput
            optionText="name"
            label="Rede"
            emptyText="Todos"
          />
        </ReferenceInput>
      </Permission>
      {
        [EmployeeType.admin, EmployeeType.chain, EmployeeType.place].includes(getEmployeeType()) &&
        <CheckChainUsesModule module={ModuleKey.PROMOTER} alwaysOn>
          <FormDataConsumer>
            {({ formData }) => (
              <ReferenceArrayInput
                source="promoterIds"
                reference="promoters"
                sort={{ field: "name", order: "ASC" }}
                filter={formData.chainId ? { chainId: formData.chainId } : {}}
              >
                <SelectArrayInput
                  label="Promoter"
                  style={{ minWidth: 180 }}
                  optionText={'name'}
                />
              </ReferenceArrayInput>
            )}
          </FormDataConsumer>
        </CheckChainUsesModule>
      }
      <Permission permission={PERMISSIONS.TAG} alwaysOn>
        <ReferenceArrayInput
          source="tagIds"
          reference="chains/chainId/tag"
          perPage={null}
          sort={{ field: "name", order: "ASC" }}
          filter={{ type: TagType.client, chainId: filterValues.chainId }}
          style={{ minWidth: 180 }}
        >
          <SelectArrayInput
            optionText="name"
            label="Etiqueta" />
        </ReferenceArrayInput>
      </Permission>
    </Filter>
  )
};

const ClientsList = props => (
  <Datagrid {...props} rowClick="show" rowSx={blockedCustomer} bulkActionButtons={false}>
    <TextField source="name" label="Nome" />
    <PartiallyHiddenCpfField source="cpf" label="CPF" />
    {
      Permission({
        permission: PERMISSIONS.CUSTOMER_STATEMENT,
        children: (
          <ReferenceField
            label="Pontos"
            source="id"
            reference="customer-balance"
            emptyText="0"
            sortable={false}
            link={false}
          >
            <FunctionField
              source="balance"
              render={record => formatPoints(+record?.balance)}
            />
          </ReferenceField>
        )
      })
    }
    <FunctionField
      render={record => record?.signupCode || record?.recoveryCode}
      label="Código"
    />
    <PhoneField source="cellphone" label="Telefone" />
  </Datagrid>
);

const ListActions = () => {
  const classes = useStyles();

  const preExporter = async (
    customers: DataType,
    fetchRelatedRecords: (relations: Array<RelationType>) => Promise<(RelationType & { results: DataType; })[]>
  ) => {
    const relations = [{ field: 'id', resource: 'customer-balance' }];
    const [data] = await fetchRelatedRecords(relations);
    const { results: balances } = data;

    const convertBalances = balances.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});

    customers.forEach((c) => {
      c.balance = convertBalances[c.id]?.balance || 0
    })

    return customers;
  };

  return (
    <TopToolbar className={classes.topToolBar}>
      <Box sx={{ display: 'flex' }}>
        <Permission permission={PERMISSIONS.CREATE_CUSTOMER}>
          <CreateButton />
        </Permission>
        <CustomExportButton fileName='Clientes' preExport={preExporter} isReport={false} />
      </Box>
    </TopToolbar>
  );
};

export default props => {
  const [searchText, setSearchText] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>();
  const [searchField, setSearchField] = useState<string>('cpf');

  const onSearchTextChanged = (event) => {
    setSearchText(event.target.value);
  };

  const onButtonClick = () => {
    setSearchFilter(searchText);
  };

  const onClear = () => {
    setSearchFilter('');
    setSearchText('');
  };

  const filterValues: any = { search: searchFilter, searchFields: [searchField] };
  if (getEmployeeType() === EmployeeType.promoter) {
    filterValues.promoterId = getUserId();
  }

  return (
    <ThemeProvider theme={theme}>
      <Box style={{ alignItems: 'center' }}>
        <FormControl variant="filled" style={{ minWidth: 200, marginRight: 10 }}>
          <InputLabel htmlFor="filled-adornment-password">Campos</InputLabel>
          <Select
            value={searchField}
            onChange={(event: any) => setSearchField(event.target.value)}
          >
            <MenuItem value={'cpf'}>CPF</MenuItem>
            <MenuItem value={'name'}>Nome</MenuItem>
            <MenuItem value={'cellphone'}>Telefone</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="filled">
          <InputLabel htmlFor="filled-adornment-password">Pesquisar</InputLabel>
          <FilledInput
            id="filled-adornment-password"
            value={searchText}
            onChange={onSearchTextChanged}
            onKeyUp={(e) => e.key === "Enter" ? onButtonClick() : undefined}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={onClear}
                  edge="end"
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Button variant="contained" style={{ marginLeft: 12, height: 40, width: 100, marginTop: 12 }} color="primary" label="Buscar" onClick={onButtonClick} />
      </Box>
      <List
        {...props}
        actions={<ListActions />}
        perPage={25}
        pagination={<CustomPagination />}
        title="Clientes da rede"
        filter={filterValues}
        filters={<ClientFilter />}
      >
        <ClientsList />
      </List>
    </ThemeProvider>
  );
}
