import { makeStyles } from '@mui/styles';
import { Fragment, useEffect, useState } from 'react';
import {
  ArrayInput,
  BooleanInput,
  DateTimeInput,
  Edit,
  FormDataConsumer,
  FormTab,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TimeInput,
  required,
  useDataProvider,
} from 'react-admin';
import { FuelAccordion } from '../../components/FuelAccordion';
import Permission from '../../components/Permission';
import { PERMISSIONS, daysOfTheWeek, frequencyChoices } from '../../constants';
import { getChainId } from '../../lib';

const FuelScheduledDiscountEdit = (props) => {
  const classes = useStyles();

  const dataProvider = useDataProvider();
  const [chainId, setChainId] = useState(getChainId());
  const [placeIds, setPlaceIds] = useState();
  const [placeChoices, setPlaceChoices] = useState([]);
  const [selectsAllPlaces, setselectsAllPlaces] = useState(false);
  const [fuels, setFuels] = useState();
  const [frequency, setFrequency] = useState();

  useEffect(() => {
    if (chainId) {
      if (placeChoices && placeChoices.length) {
        if (placeIds && placeIds.length || selectsAllPlaces) {
          dataProvider.getList('fuels', {
            sort: {
              field: 'name',
              order: 'ASC'
            },
            filter: { placeIds: selectsAllPlaces ? placeChoices.map(place => place.id) : placeIds }
          })
            .then(res => {
              setFuels(res && res.data);
            });
        } else {
          setFuels([]);
        }
      }
    }
  }, [placeChoices, placeIds, selectsAllPlaces]);

  useEffect(() => {
    if (chainId) {
      dataProvider.getList('stations', { sort: { field: 'name', order: 'ASC' }, filter: { chainId } }).then(res => {
        setPlaceChoices(res.data);
      });
    }
  }, [chainId]);

  const transform = data => {
    data.fuelScheduledDiscounts = data.fuelScheduledDiscounts.filter(f => f && f.fuelId);
    return data;
  };

  return (
    <Permission permission={PERMISSIONS.FUEL_DISCOUNTS}>
      <Edit {...props} title="Editar desconto agendado" transform={transform} >
        <TabbedForm>
          <FormTab label="Descontos">

            {!getChainId() &&
              <ReferenceInput label="Rede" source="chainId" reference="chains" onChange={({ target: { value } }) => setChainId(value)}>
                <SelectInput resettable optionText="name" />
              </ReferenceInput>
            }
            {chainId && (
              <FormDataConsumer>
                {({ formData }) => {
                  if (formData.selectsAllPlaces) {
                    setselectsAllPlaces(formData.selectsAllPlaces);
                  } else if (formData.placeIds) {
                    setPlaceIds(formData.placeIds);
                  }
                  setFrequency(formData.frequency);

                  return (
                    <>
                      <div style={{ display: 'flex' }}>
                        {
                          !selectsAllPlaces &&
                          <SelectArrayInput
                            label="Postos"
                            style={{ minWidth: 180, marginRight: 20 }}
                            optionText={'name'}
                            source={"placeIds"}
                            onChange={({ target: { value } }) => setPlaceIds(value)}
                            choices={placeChoices}
                          />
                        }
                        <BooleanInput
                          source="selectsAllPlaces"
                          label="Selecionar todos os postos"
                          className={classes.selectsAllPlacesButton}
                          onChange={value => setselectsAllPlaces(value)}
                        />
                      </div>
                      <BooleanInput label="Sobrepor desconto de grupo" source="overlapsGroupDiscount" />
                    </>
                  )
                }}
              </FormDataConsumer>
            )}
            {chainId && fuels &&
              <FormDataConsumer>
                {({ formData: { fuelScheduledDiscounts } }) => {
                  return fuels.map((fuel) => {
                    let index;
                    if (fuelScheduledDiscounts && fuelScheduledDiscounts.length) {
                      index = fuelScheduledDiscounts.findIndex(fsd => fsd.fuelId === fuel.id);
                      if (index === -1) {
                        fuelScheduledDiscounts.push({
                          fuelId: fuel.id,
                          scheduledDiscountId: props.id,
                        });
                        index = fuelScheduledDiscounts.length;
                      }
                    }
                    return (
                      <FuelAccordion
                        name={fuel.name}
                        source={`fuelScheduledDiscounts[${index}]`}
                        hideAmount={
                          fuelScheduledDiscounts && fuelScheduledDiscounts[index] ? fuelScheduledDiscounts[index].uniqueDiscountValue : true
                        }
                      />
                    )
                  });
                }}
              </FormDataConsumer>
            }
          </FormTab>
          <FormTab label="Agendamento">
            <>
              <div className={classes.dateTimePicker}>
                <DateTimeInput
                  source="startDate"
                  label="Data de Início"
                  validate={[required()]}
                />
                <DateTimeInput
                  source="endDate"
                  label="Data Final"
                  validate={[required()]}
                />
              </div>
              <SelectInput
                label="Frequência"
                source="frequency"
                alwaysOn
                style={{ marginTop: 10 }}
                choices={frequencyChoices}
                onChange={({ target: { value } }) => setFrequency(value)}
                validate={[required()]}
              />

              {frequency === 'daily' &&
                <div className={classes.dailyContainer}>
                  <TimeInput
                    source="dayScheduledDiscounts[0].startTime"
                    label="Horário inicial"
                    style={{ width: 200 }}
                    isRequired
                    validate={[required()]}
                    parse={value => value.toString()}
                  />
                  <TimeInput
                    source="dayScheduledDiscounts[0].endTime"
                    label="Horário final"
                    style={{ width: 200 }}
                    isRequired
                    validate={[required()]}
                    parse={value => value.toString()}
                  />
                </div>
              }
              {frequency === 'weekly' &&
                <FormDataConsumer>
                  {({ formData }) => {
                    if (formData.dayScheduledDiscounts) {
                      if (formData.dayScheduledDiscounts.length !== 7) {
                        const registeredDays = formData.dayScheduledDiscounts.map(record => {
                          record.isActive = true;
                          return record.dayOfTheWeek;
                        });
                        const notRegisteredDays = [...daysOfTheWeek].filter(wd => !registeredDays.includes(wd.id));
                        notRegisteredDays.forEach(day => {
                          formData.dayScheduledDiscounts.push({
                            dayOfTheWeek: day.id,
                            isActive: false
                          });
                        });
                      }
                      formData.dayScheduledDiscounts.sort((a, b) => {
                        if (a.dayOfTheWeek > b.dayOfTheWeek) {
                          return 1;
                        } else if (a.dayOfTheWeek < b.dayOfTheWeek) {
                          return -1;
                        }
                        return 0;
                      });
                    }

                    return (
                      <ArrayInput source="dayScheduledDiscounts" label="Defina os dias e horários">
                        <SimpleFormIterator disableAdd disableRemove disableReordering>
                          <FormDataConsumer>
                            {({ scopedFormData, getSource }) => {
                              const day = daysOfTheWeek.find(day => scopedFormData && day.id === scopedFormData.dayOfTheWeek);
                              return (
                                <>
                                  {
                                    <p className={classes.day}>{day && day.name}</p>
                                  }
                                  <div className={classes.inputWrapperStyle}>
                                    <BooleanInput
                                      source={getSource && getSource("isActive")}
                                      label={false}
                                      className={classes.input}
                                    />
                                    {scopedFormData && scopedFormData.isActive &&
                                      <Fragment>
                                        <TimeInput
                                          record={scopedFormData}
                                          label='Horário inicial'
                                          source={getSource && getSource("startTime")}
                                          style={{ marginRight: 20, width: 200 }}
                                          isRequired
                                          parse={value => value.toString()}
                                        />
                                        <TimeInput
                                          record={scopedFormData}
                                          label='Horário final'
                                          source={getSource && getSource("endTime")}
                                          style={{ marginRight: 20, width: 200 }}
                                          isRequired
                                          parse={value => value.toString()}
                                        />
                                      </Fragment>
                                    }
                                  </div>
                                </>
                              );
                            }}
                          </FormDataConsumer>
                        </SimpleFormIterator>
                      </ArrayInput>
                    );
                  }}
                </FormDataConsumer>
              }
            </>
          </FormTab>
        </TabbedForm>
      </Edit>
    </Permission >
  );
}

const useStyles = makeStyles((theme) => ({
  dailyContainer: {
    display: 'flex',
    gap: 30,
    marginBottom: 20
  },
  dateTimePicker: {
    display: 'flex',
    gap: 20,
  },
  inputWrapperStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 0 20px 0',
  },
  input: {
    margin: '0 20px 0 0',
  },
  selectsAllPlacesButton: {
    marginTop: '20px',
  },
  day: {
    fontSize: 16,
    margin: '20px 0'
  },
}));

export default FuelScheduledDiscountEdit;