import { ArrayField, BooleanField, ChipField, Datagrid, DateField, Filter, FunctionField, List, NumberField, ReferenceArrayField, ReferenceArrayInput, ReferenceField, RichTextField, SearchInput, SelectArrayInput, Show, SimpleShowLayout, SingleFieldList, Tab, TabbedShowLayout, TextField, TopToolbar, useGetOne, useListContext, usePermissions, useRecordContext } from 'react-admin';
import Permission from '../../components/Permission';
import { PERMISSIONS, alterPriceTypes, alterPriceValueTypes, daysOfTheWeek, frequencyChoices } from '../../constants';

import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { uniq, uniqBy } from 'lodash';
import moment from 'moment';
import { useRef, useState } from 'react';
import { DiscountDisableButton, FillinsDatagrids, PrintButton, ReportPrint } from '../../components';
import { PartiallyHiddenCpfField } from '../../components/Fields';
import { getChainId, getUserId } from '../../lib';
import { DispatchMessageType } from '../../models';
import { DispatchMessageStatus } from '../../models/dispatchMessageStatus';
import CloneDiscountButton from './CloneDiscountButton';
import { BetweenDatesInput } from '../../components/BetweenDatesInput';

const SingleFieldChips = (props) => {
    const { data } = useListContext();
    return (
        !data || !data.length ?
            <FunctionField render={() => props.emptyText || 'Sem informações'} />
            :
            <SingleFieldList>
                <ChipField source={props.source} />
            </SingleFieldList>
    );
};

const PriceValueField = (props) => {
    const record = useRecordContext();
    if (!record) { return null; }

    return (
        record.alterPriceValueType === 'percentage' ?
            <FunctionField
                render={data => data && data.alterPriceValue ? `${data.alterPriceValue}%` : '0%'}
            />
            :
            <NumberField
                source={props.source}
                emptyText="R$ 0,00"
                options={{
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }}
            />
    );
}

const AmountField = (props) => {
    const record = useRecordContext();
    if (!record || record.uniqueDiscountValue) { return null; }

    return (
        <NumberField
            {...props}
            label="Volume"
            textAlign="center"
            sortable={false}
            source="amount"
            emptyText="0"
            options={{
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }}
        />
    )
}

const FuelDiscountsList = (props) => {
    const classes = useStyles();
    const record = useRecordContext();
    if (!record) { return null; }

    return (
        <SimpleShowLayout
            className={classes.containerFuelDiscount}
            record={{ ...record, fuelDiscounts: uniqBy(record.fuelDiscounts, v => [v.fuelId, v.alterPriceValue, v.alterPriceType, v.alterPriceValueType, v.points].join()) }}
        >
            <ArrayField source="fuelDiscounts" label="Descontos por combustíveis">
                <Datagrid style={{ padding: 0 }} bulkActionButtons={false}>
                    <ReferenceField source="fuelId" reference="fuels" label="Combustível" link={false} sortable={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    {
                        props.chain && props.chain.isPriceIncreaseEnabled &&
                        <FunctionField
                            render={data => data && data.alterPriceType ? alterPriceTypes[data.alterPriceType] : 'Desconto'}
                            label="Tipo"
                        />
                    }
                    {
                        props.chain && props.chain.isPriceIncreaseEnabled &&
                        <FunctionField
                            render={data => data && data.alterPriceValueType ? alterPriceValueTypes[data.alterPriceValueType] : 'Centavos'}
                            label="Tipo de valor"
                            textAlign="center"
                        />
                    }
                    <PriceValueField source="alterPriceValue" label="Valor" textAlign="center" />
                    {/* <AmountField label="Volume" /> */}
                    <NumberField
                        source="points"
                        label="Pontos"
                        textAlign="center"
                        emptyText="0"
                        options={{
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }}
                    />
                    {/* <BooleanField source="uniqueDiscountValue" label="Valor do desconto único" /> */}
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    );
}

const StationListField = (props) => {
    const record = useRecordContext();
    const classes = useStyles();
    if (!record) { return null; }

    return (
        <SimpleShowLayout className={classes.containerFuelDiscount} record={{ ...record, stationIds: uniq(record.placeDiscounts?.map(fd => fd.placeId)) }}>
            <ReferenceArrayField label="Postos" reference="stations" source="stationIds">
                <SingleFieldChips source="name" emptyText="Sem postos" />
            </ReferenceArrayField>
        </SimpleShowLayout>
    );
}
const GroupListField = (props) => {
    const classes = useStyles();
    const record = useRecordContext();
    if (!record) { return null; }

    return (
        <SimpleShowLayout className={classes.containerFuelDiscount} record={{ ...record, customerGroupIds: uniq(record.groupDiscounts?.map(fd => fd.groupId)) }}>
            <ReferenceArrayField label="Grupos" reference="chains/chainId/customer-groups" source="customerGroupIds">
                <SingleFieldChips source="name" emptyText="Sem Grupos" />
            </ReferenceArrayField>
        </SimpleShowLayout>
    );
}

const DayScheduledDiscountsField = ({ data }: any) => (
    data?.dayScheduledDiscounts ?
        <ArrayField source="dayScheduledDiscounts">
            <Datagrid bulkActionButtons={false}>
                <FunctionField render={record => record && daysOfTheWeek.find(wd => wd.id === record.dayOfTheWeek)?.name} label="Dia" />
                <FunctionField render={record => record && record.startTime ? moment(record.startTime).format('HH:mm') : '--'} label="Inicio" />
                <FunctionField render={record => record && record.endTime ? moment(record.endTime).format('HH:mm') : '--'} label="Fim" />
            </Datagrid>
        </ArrayField>
        :
        <></>
);

const ScheduleField = () => {
    const record = useRecordContext();
    if (!record) { return null; }

    return (
        (record.startAt || record.endAt || record.frequency) ?
            <Box component="div" sx={{ p: 2, border: '1px dashed grey', marginTop: 2, marginBottom: 2 }}>
                <Typography variant="body1" style={{ marginBottom: 10 }}>Agendamento</Typography>
                <SimpleShowLayout>
                    <FunctionField render={record => record && record.startAt ? moment(record.startAt).format('DD/MM/YYYY HH:mm') : '--'} label="Data inicial" />
                    <FunctionField render={record => record && record.endAt ? moment(record.endAt).format('DD/MM/YYYY HH:mm') : '--'} label="Data final" />
                    <FunctionField render={record => record && record.frequency ? frequencyChoices.find(dt => dt.id === record.frequency)?.name : '--'} label="Frequência" />
                    <DayScheduledDiscountsField data={record} label="Horários" />
                </SimpleShowLayout>
            </Box>
            :
            <></>
    );
}

const CustomerList = (props) => {
    const record = useRecordContext();
    if (!record) { return null; }

    const listFilters = [
        <SearchInput source="search" alwaysOn />
    ];

    const CustomerDatagrid = () => (
        <Datagrid bulkActionButtons={false}>
            <TextField source="name" label="Nome" />
            <PartiallyHiddenCpfField source="cpf" label="CPF" />
        </Datagrid>
    );

    return (
        record.customerGroupIds.length ?
            <List
                resource="customers"
                title=" - Clientes"
                filterDefaultValues={{ groupIds: record.customerGroupIds }}
                filters={listFilters}
                exporter={false}
            >
                <CustomerDatagrid />
            </List>
            :
            <List
                resource="customer-discounts"
                title=" - Clientes"
                filterDefaultValues={{ discountId: record.id }}
                filters={listFilters}
                exporter={false}
            >
                <CustomerDatagrid />
            </List>
    );
}

const NotificationList = (props) => {
    const classes = useStyles();

    const Panel = () => (
        <ArrayField source="dispatchMessages" label="">
            <Datagrid bulkActionButtons={false}>
                <FunctionField render={record => record && DispatchMessageType.translateType(record.type)} label="Tipo" />
                <NumberField source="cardinality" label="Total" textAlign="center" />
                <FunctionField render={record => record && DispatchMessageStatus.translateType(record.status)} label="Status" textAlign="center" />
            </Datagrid>
        </ArrayField>
    )

    const NotificationDatagrid = () => (
        <Datagrid
            expand={<Panel />}
            empty={<Typography textAlign="center">Sem notificações</Typography>}
        >
            <TextField source="title" label="Título" />
            <RichTextField source="body" label="Mensagem" className={classes.richText} />
            <TextField source="pushMessage" label="Mensagem Push" />
            {/* <TextField source="smsMessage" label="Messagem sms" /> */}
            <DateField source="sendAt" label="Envio às" showTime />
            <FunctionField render={record => record && record.status} />
        </Datagrid>
    );

    return (
        <ArrayField source="discountMarketingCampaigns" label="Notificações" emptyText="Sem notificações">
            <NotificationDatagrid />
        </ArrayField>
    );
}

const FillinList = () => {
    const record = useRecordContext();
    const classes = useStyles();
    const fillinsRef = useRef();

    if (!record) { return null; }

    const FilterFillin = (props) => (
        <Filter>
            <BetweenDatesInput alwaysOn />
            <ReferenceArrayInput
                source="fuelIds"
                reference="fuels"
                sort={{ field: "name", order: "ASC" }}
                perPage={null}
                alwaysOn
            >
                <SelectArrayInput
                    style={{ minWidth: 180 }}
                    label="Combustíveis"
                    optionText={"name"}
                />
            </ReferenceArrayInput>
        </Filter>
    );

    const ListFillinsActions = () => (
        <TopToolbar>
            <PrintButton
                printRef={fillinsRef}
            />
        </TopToolbar>
    );

    const FilterTablePrint = ({ identify }) => {
        const { filterValues } = useListContext();

        return (
            <Datagrid bulkActionButtons={false} data={[{ ...filterValues, identify }]} total={1}>
                <DateField source="from" textAlign="center" label="De" sortable={false} />
                <DateField source="to" textAlign="center" label="Até" sortable={false} />
                <ReferenceArrayField label="Combustíveis" source="fuelIds" textAlign="center" reference="fuels">
                    <SingleFieldList linkType={false}>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <TextField label="Código do desconto" source="identify" textAlign="center" />
            </Datagrid>
        );
    };

    return (
        <List
            title=" "
            resource="fillins"
            sort={{ field: "createdAt", order: "DESC" }}
            filterDefaultValues={{
                from: moment().startOf("day").subtract(1, "month").toISOString(),
                to: moment().endOf("day").toISOString(),
            }}
            actions={<ListFillinsActions />}
            filter={{ discountId: record.id }}
            filters={<FilterFillin />}
            exporter={false}
            disableSyncWithLocation
        >
            <ReportPrint ref={fillinsRef} title="Abastecimentos" landscape>
                <div className={classes.filterTablePrint}>
                    <Typography className={classes.titleCard}>Filtros</Typography>
                    <FilterTablePrint identify={record && record.identify} />
                </div>
                <FillinsDatagrids />
            </ReportPrint>
        </List>
    )
}

const DiscountShow = (props) => {

    const [chainId] = useState(getChainId());
    if (!chainId) { return <Typography>Selecione uma Rede</Typography>; }

    const { data: chain } = useGetOne('chains', { id: chainId });
    const { permissions } = usePermissions();

    return (
        <Permission permission={PERMISSIONS.FUEL_DISCOUNTS}>
            <Show title='Desconto' {...props}>
                <TabbedShowLayout>
                    <Tab label="Resumo">
                        <DiscountDisableButton style={{ marginTop: 20, marginBottom: 20 }} isOutlined />
                        <CloneDiscountButton variant="outlined" style={{ marginBottom: 20 }} />
                        <TextField source="identify" label="Código" />
                        <ReferenceField source="createdByEmployeeId" reference="employees" label="Criado por" link={permissions && permissions.includes(PERMISSIONS.UPDATE_USER)}>
                            <FunctionField render={record => record ? `${record.name} ${record.id === getUserId() ? '(você)' : ''}` : ''} />
                        </ReferenceField>
                        <TextField source="description" label="Descrição" emptyText="--" />
                        <GroupListField />
                        <StationListField />
                        <TextField source="usageLimit" label="Limite de uso" emptyText="--" />
                        <TextField source="intervalInHours" label="Intervalo de uso (horas)" emptyText="--" />
                        <DateField source="disabledAt" label="Desativado em" showTime textAlign="center" emptyText="--" />
                        <ScheduleField />
                    </Tab>
                    <Tab label="Descontos">
                        <BooleanField source="overlapsStandardDiscount" label="Sobrepor o desconto padrão" />
                        <FuelDiscountsList chain={chain} />
                    </Tab>
                    <Tab label="Clientes">
                        <CustomerList />
                    </Tab>
                    <Tab label="Notificações">
                        <Box sx={{ paddingTop: 2, paddingBottom: 5 }}>
                            <NotificationList />
                        </Box>
                    </Tab>
                    <Tab label="Abastecimentos">
                        <FillinList />
                    </Tab>
                </TabbedShowLayout>
            </Show>
        </Permission>
    )
};

const useStyles = makeStyles((theme) => ({
    containerFuelDiscount: {
        padding: 0,
    },
    richText: {
        '& img': {
            maxWidth: 300,
            maxHeght: 300,
        }
    },
    filterTablePrint: {
        display: 'none',
        '@media print': {
            display: 'block'
        },
        marginBottom: 10,
    },
    titleCard: {
        textAlign: 'center',
        fontWeight: 'bold',
    },
}));

export default DiscountShow;
