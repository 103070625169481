import { findDOMNode } from 'react-dom';
import { maxValue } from 'react-admin';
import moment from 'moment';
import { onlyNumbers } from '@brazilian-utils/brazilian-utils';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const strip = (str) => {
  var regex = /[^\d]/g;
  return (str || "").toString().replace(regex, "");
}

export const formatCellphone = (num) => {
  if (!num) return num;
  return num.length === 10 ?
    `(${num.substring(0, 2)}) ${num.substring(2, 6)}-${num.substring(6, 10)}` :
    `(${num.substring(0, 2)}) ${num.substring(2, 7)}-${num.substring(7, 11)}`;
}

export const formatInternationalCellphone = (num) => {
  if (!num) return num;
  if (num.length <= 2 && num.substring(0, 2) === "55") return num = '';
  if (num.length < 3) return num = "55" + num;
  return num.length === 12 ?
    `+${num.substring(0, 2)} (${num.substring(2, 4)}) ${num.substring(4, 8)}-${num.substring(8, 12)}` :
    `+${num.substring(0, 2)} (${num.substring(2, 4)}) ${num.substring(4, 9)}-${num.substring(9, 13)}`;
}

export const formatCnpj = (num) => {
  if (!num) return '';
  return `${num.substring(0, 2)}.${num.substring(2, 5)}.${num.substring(5, 8)}/${num.substring(8, 12)}-${num.substring(12, 14)}`
}

export const formatPartiallyHiddenCpf = (num) => {
  if (!num) return '';
  const cpfNumbers = onlyNumbers(num);
  return `***.***.${cpfNumbers.substring(6, 9)}-${cpfNumbers.substring(9, 11)}`
}

export const formatCpf = (num) => {
  if (!num) return '';
  if (num.length < 4)
    return `${num.substring(0, 3)}`;
  if (num.length < 7)
    return `${num.substring(0, 3)}.${num.substring(3, 6)}`;
  if (num.length < 10)
    return `${num.substring(0, 3)}.${num.substring(3, 6)}.${num.substring(6, 9)}`;
  return `${num.substring(0, 3)}.${num.substring(3, 6)}.${num.substring(6, 9)}-${num.substring(9, 11)}`
}

export const formatCep = (num) => {
  if (!num) return '';
  if (num.length < 6)
    return `${num.substring(0, 5)}`;
  return `${num.substring(0, 5)}-${num.substring(5, 8)}`;
}

export const formatId = (num) => {
  var id = '0000' + num;
  return `${id.substring(id.length - 4)} `;
}

export const formatPumpId = (station, num) => {
  var stationId = '0000' + station;
  if (!num) return `${stationId.substring(stationId.length - 4)}-`;
  var id = '000' + num;
  return `${stationId.substring(stationId.length - 4)}-${id.substring(id.length - 3)}`;
}

export const formatNumber = (number, options) => {
  const { unit, style, currency, minimumFractionDigits, maximumFractionDigits } = options || {};
  return new Intl.NumberFormat('pt-BR', { unit, style: style || "decimal", currency, minimumFractionDigits, maximumFractionDigits }).format(number);
}

export const formatDiscount = ({ oldPricePerLiter, pricePerLiter, amount = 0 }) => {
  if (!oldPricePerLiter || !pricePerLiter || oldPricePerLiter <= pricePerLiter) return "R$ 0,00";
  return formatNumber((oldPricePerLiter - pricePerLiter) * amount, { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export const formatPoints = (number) => {
  return number ? number.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : 0;
}

export const formatDecimal = value => {
  return `${value}`.replaceAll('.', '').replace(',', '.');
}

export const cleanNumberAndForceDecimal = (value) => {
  return (+value.replace(/[^\d]/g, "") / 100).toFixed(2);
};

// needed to overwrite DateInput formatter
const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
const convertDateToString = (value) => {
  if (!(value instanceof Date) || isNaN(value.getDate())) return null;
  const pad = '00';
  const yyyy = value.getUTCFullYear().toString();
  const MM = (value.getUTCMonth() + 1).toString();
  const dd = value.getUTCDate().toString();
  return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`;
}

export const dateInputFormatter = (value) => {
  if (value == null || value === '') {
    return null;
  }
  if (value instanceof Date) {
    return convertDateToString(value);
  }
  // valid dates should not be converted
  if (dateRegex.test(value)) {
    return value;
  }
  return convertDateToString(new Date(value));
};

export const validateFurtherDate = () => {
  const date = new Date().toJSON().slice(0, 10);
  return maxValue(date, "A data não pode ser futura");
}

const triggerPrint = (target) => {
  setTimeout(() => {
    target.contentWindow.focus();
    target.contentWindow.print();
    removeWindow(target);

  }, 500);
}

const removeWindow = (target) => {
  setTimeout(() => {
    target.parentNode.removeChild(target);
  }, 500);
}

export const printElement = (component) => {
  const contentEl = component();

  if (contentEl === undefined) {
    console.error("Refs are not available for stateless components. For 'react-to-print' to work only Class based components can be printed");
    return false;
  }

  let printWindow = document.createElement('iframe');
  //printWindow.setAttribute("id", shortid.generate());
  printWindow.style.position = 'absolute';
  printWindow.style.top = '-1000px';
  printWindow.style.left = '-1000px';

  const contentNodes = findDOMNode(contentEl);
  const linkNodes = document.querySelectorAll('link[rel="stylesheet"]');

  const linkTotal = linkNodes.length || 0;
  let linkLoaded = 0;

  const markLoaded = (type) => {
    linkLoaded++;

    if (linkLoaded === linkTotal) {
      triggerPrint(printWindow);
    }

  };

  printWindow.onload = () => {
    /* IE11 support */
    if (window.navigator && window.navigator.userAgent.indexOf('Trident/7.0') > -1) {
      printWindow.onload = null;
    }

    let domDoc = printWindow.contentDocument || printWindow.contentWindow.document;
    const srcCanvasEls = [...contentNodes.querySelectorAll('canvas')];

    domDoc.open();
    domDoc.write(contentNodes.outerHTML);
    domDoc.close();

    /* remove date/time from top */
    const defaultPageStyle = "@page { size: auto;  margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; } }"

    let styleEl = domDoc.createElement('style');
    styleEl.appendChild(domDoc.createTextNode(defaultPageStyle));
    domDoc.head.appendChild(styleEl);

    const canvasEls = domDoc.querySelectorAll('canvas');
    [...canvasEls].forEach((node, index) => {
      node.getContext('2d').drawImage(srcCanvasEls[index], 0, 0);
    });

    const headEls = document.querySelectorAll('style, link[rel="stylesheet"]');

    [...headEls].forEach((node, index) => {

      let newHeadEl = domDoc.createElement(node.tagName);
      let styleCSS = "";

      if (node.tagName === 'STYLE') {

        if (node.sheet) {
          for (let i = 0; i < node.sheet.cssRules.length; i++) {
            styleCSS += node.sheet.cssRules[i].cssText + "\r\n";
          }

          newHeadEl.setAttribute('id', `react-to-print-${index}`);
          newHeadEl.appendChild(domDoc.createTextNode(styleCSS));

        }

      } else {

        let attributes = [...node.attributes];
        attributes.forEach(attr => {
          newHeadEl.setAttribute(attr.nodeName, attr.nodeValue);
        });

        newHeadEl.onload = markLoaded.bind(null, 'link');
        newHeadEl.onerror = markLoaded.bind(null, 'link');

      }

      domDoc.head.appendChild(newHeadEl);

    });

    if (linkTotal === 0) {
      triggerPrint(printWindow);
    }

  };

  document.body.appendChild(printWindow);
};

export const getInitialChart = (from, to, initialData) => {
  const diffDate = moment(to).diff(moment(from), 'd');
  // fix chart initial animation
  return Array.from({ length: diffDate }, (_, i) => ({
    ...initialData,
    date: moment().subtract((diffDate - (i + 1)), 'd').format('DD/MM/YYYY'),
  }));
};

export const getDatesInRange = (from, to) => {
  const dates = [];

  const currDate = moment.utc(from);
  const finalDate = moment.utc(to);

  while (currDate <= finalDate) {
    dates.push(currDate.format('DD/MM/YYYY'));
    currDate.add(1, 'days');
  }
  return dates;
};

export const downloadBlob = async (url, name) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const blobURL = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = blobURL;
  a.style = "display: none";
  a.download = name;
  document.body.appendChild(a);
  a.click();

  URL.revokeObjectURL(a.href);
}

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

export const exportToXLSX = (csvData, fileName) => {
  const sheet = XLSX.utils.json_to_sheet(csvData);
  const workBook = { SheetNames: ['data'], Sheets: { 'data': sheet } };

  const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
  const file = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(file, fileName + fileExtension);
}

export const forceTwoDigits = (value) => {
  return value < 10 ? `0${value}` : value;
}

export const hexToRGBA = (hex, alpha) => {
  hex = '0x' + hex.replace('#', '');
  const r = hex >> 16 & 0xFF;
  const g = hex >> 8 & 0xFF;
  const b = hex & 0xFF;

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export const linkPlaceType = (record) => {
  if (record.type === 'station') {
    return `/stations/${record.id}/show`
  }
  return `/chains/chainId/partners/${record.id}`
}