import { ComponentType } from "react";
import { UserRole } from "../models";
import { getChainId, getPlaceId } from "../lib";
import { PERMISSIONS } from "../constants";

export default class ComponentWithPermissions {
    permissions?: Array<string>;
    component?: ComponentType;
    userRoles?: Array<UserRole>;

    getComponent(permissions: Array<string>) {
        const isAdmin = permissions && permissions.includes(PERMISSIONS.ADMIN);

        const role = getPlaceId() ? UserRole.place : getChainId() && !isAdmin ? UserRole.chain : UserRole.admin;

        if (this.userRoles && !this.userRoles.includes(role)) { return undefined; }

        if (this.permissions && !this.permissions.some(r => permissions.includes(r))) { return undefined; }

        return this.component;
    }

    constructor(init?: Partial<ComponentWithPermissions>) {
        Object.assign(this, init);
    }
}