import { Box, Typography } from "@mui/material";
import { default as React } from "react";
import { ArrayField, BooleanField, ChipField, Datagrid, DateField, FunctionField, Labeled, NumberField, ReferenceArrayField, ReferenceField, SelectField, Show, SimpleShowLayout, SingleFieldList, TextField, UrlField, useListContext } from "react-admin";
import { formatDrawingStatus } from "./DrawingList";
import { AlterValueDrawing } from "../../models";

const StationField = (props) => {
    const { data } = useListContext();

    return (
        data && data.length ?
            <SingleFieldList>
                <ChipField source="name" emptyText="Todos" />
            </SingleFieldList>
            :
            <Typography variant="body2">Todos</Typography>
    );
}

const DrawingShow = (props) => (
    <Show {...props} title="Detalhes do sorteio">
        <SimpleShowLayout>
            <TextField label="Nome" source="name" />

            <Box display="flex" style={{ gap: 32 }}>
                <Labeled label="Data inicial">
                    <DateField source="startDate" />
                </Labeled>
                <Labeled label="Data final">
                    <DateField source="endDate" />
                </Labeled>
                <Labeled label="Data do sorteio">
                    <DateField source="drawingDate" showTime />
                </Labeled>
            </Box>

            <Box display="flex" style={{ gap: 32 }}>
                <Labeled label="Número inicial">
                    <NumberField source="startNumber" />
                </Labeled>
                <Labeled label="Número final">
                    <NumberField source="endNumber" />
                </Labeled>
            </Box>

            <NumberField
                source="winningNumber"
                label="Número Sorteado"
                emptyText="Nenhum"
            />

            <ReferenceField
                label="Vencedor"
                source="winningCustomerId"
                reference="customers"
                link="show"
                emptyText="Nenhum"
            >
                <TextField source="name" />
            </ReferenceField>

            <FunctionField
                source="status"
                label="Status"
                render={record => formatDrawingStatus(record ? record.status : null)}
            />

            <UrlField source="drawingRegulationUrl" label="Url do regulamento" />

            <ArrayField source="drawingRuleFuels" label="Regras do sorteio">
                <Datagrid bulkActionButtons={false}>
                    <ReferenceField
                        source="drawingRuleId"
                        reference="drawing-rules"
                        label="Regra"
                        link={false}
                    >
                        <TextField source="label" />
                    </ReferenceField>
                    <ReferenceField
                        source="fuelId"
                        reference="fuels"
                        label="Combustível"
                        emptyText="Todos"
                        link={false}
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <SelectField
                        label="Tipo"
                        source="type"
                        choices={AlterValueDrawing.alterValueDrawingChoices}
                        emptyText="-"
                        textAlign="center"
                    />
                    <FunctionField
                        label="Quantidade"
                        textAlign="center"
                        render={(record) => {
                            if (record.type === AlterValueDrawing.value) {
                                return (
                                    <NumberField
                                        source="value"
                                        emptyText="R$ 0,00"
                                        options={{ style: 'currency', currency: 'BRL' }}
                                    />
                                )
                            } else {
                                return (
                                    <NumberField
                                        source="value"
                                        emptyText="0,00"
                                        options={{
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        }}
                                    />
                                )
                            }
                        }}
                    />
                    <NumberField source="luckyNumberAmount" label="Quantidade de cupons" textAlign="center" />
                    <BooleanField label="Acumulado" source="accumulated" textAlign="center" emptyText="-" />
                    <DateField source="startDate" label="Data inicial" emptyText="-" textAlign="center" />
                    <DateField source="endDate" label="Data final" emptyText="-" textAlign="center" />
                    <ReferenceArrayField reference="stations" label="Postos" source="stationIds" emptyText="Todos">
                        <StationField />
                    </ReferenceArrayField>
                    <BooleanField label="Ativo" source="isEnabled" />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);

export default DrawingShow;