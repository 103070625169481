// Based on https://gist.github.com/albertein/4496103

const move = (array, element, delta): any[] => {
  const newArray = [...array]
  const index = newArray.indexOf(element)
  const newIndex = index + delta
  // Already at the top or bottom.
  if (newIndex < 0 || newIndex == newArray.length) return array
  // Sort the indixes
  const indexes = [index, newIndex].sort((a, b) => a - b)
  // Replace from lowest index, two elements, reverting the order
  newArray.splice(indexes[0], 2, newArray[indexes[1]], newArray[indexes[0]])

  return newArray
}

const moveUp = (array, element): any[] => {
  return move(array, element, -1)
}

const moveDown = (array, element): any[] => {
  return move(array, element, 1)
}

export default { move, moveUp, moveDown }
