import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { DateTimeInput, NumberInput, SaveButton } from 'react-admin';
import { DropDownPartners } from '../../components';
import moment from 'moment';

const useStyles = makeStyles({
    imageWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        height: 100,
        width: 120,
    },
    button: {
        margin: '25px auto'
    },
    row: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        margin: '0 0 10px 0'
    },
    selectWrapper: {
        width: 180,
        height: 120,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    filterRow: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    filterWrapper: {
        margin: '0 15px 0 0',
    },
});

const Filters = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.filterRow}>
            {React.Children.map(children, child =>
                <div className={classes.filterWrapper}>
                    {child}
                </div>
            )}
        </div>
    )
}

export default ({
    products,
    origin,
    destination,
    saveCallback,
    places,
    originDeposit,
    destinationDeposit,
    setOriginPlaceCallback,
    setDestinationPlaceCallback,
    setOriginDeposit,
    setDestinationDeposit,
    loading,
    date,
    setDateCallback
}) => {
    const classes = useStyles();
    const [transferredProducts, setTransferredProducts] = useState([]);

    const inputOnChange = ({ productId, value }) => {
        const transferredProductIndex = transferredProducts.findIndex((p) => p.productId === productId)
        if (transferredProductIndex === -1) {
            transferredProducts.push({ productId, value, stockType: originDeposit });
        } else {
            transferredProducts[transferredProductIndex].value = value;
        }
        setTransferredProducts([...transferredProducts]);
    }

    useEffect(() => {
        setTransferredProducts([])
    }, [origin, originDeposit])

    return (
        <>
            <div className={classes.row}>
                <Filters>
                    <div className={classes.selectWrapper}>
                        <DropDownPartners
                            label="Unidade de origem"
                            value={origin}
                            setValue={setOriginPlaceCallback}
                            arrayData={places}
                            disabled={(loading)}
                        />
                        <DropDownPartners
                            label="Unidade de destino"
                            value={destination}
                            setValue={setDestinationPlaceCallback}
                            arrayData={places}
                            disabled={(loading)}
                        />
                    </div>
                    <div className={classes.selectWrapper}>
                        <DropDownPartners
                            label="Depósito de origem"
                            value={originDeposit}
                            setValue={setOriginDeposit}
                            arrayData={[{ id: 'warehouse', name: 'Depósito central' }, { id: 'showcase', name: 'Mostruário' }]}
                            disabled={(loading)}
                        />
                        <DropDownPartners
                            label="Depósito de destino"
                            value={destinationDeposit}
                            setValue={setDestinationDeposit}
                            arrayData={[{ id: 'warehouse', name: 'Depósito central' }, { id: 'showcase', name: 'Mostruário' }]}
                            disabled={(loading)}
                        />
                    </div>
                    <DateTimeInput
                        label='Data da transferência'
                        ampm={false}
                        name="date"
                        source="date"
                        disableFuture
                        parse={(value) => moment(value).toISOString()}
                        defaultValue={moment().startOf('day').toISOString()}
                        onChange={date => setDateCallback(date)}
                        sx={{ marginBottom: 0 }}
                        helperText={false}
                    />,
                </Filters>
                <SaveButton
                    disabled={(loading || (!transferredProducts || transferredProducts.length === 0))}
                    onClick={
                        () => {
                            saveCallback({ date: date.utc().toISOString(), transferredProducts })
                            setTransferredProducts([])
                        }}
                />
            </div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Produto</TableCell>
                            <TableCell align="center">Nome</TableCell>
                            <TableCell align="center">Quantidade Atual</TableCell>
                            <TableCell align="center">Quantidade Transferida</TableCell>
                            <TableCell align="center">Quantidade Final</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(products && products.length > 0) ?
                            products.map(row => {
                                const product = transferredProducts.find((p) => p.productId === row.id);
                                const originValue = row[originDeposit] ? +row[originDeposit] : 0;
                                const productValue = product ? +product.value : 0;

                                return (
                                    <TableRow key={row.id}>
                                        <TableCell align="center">
                                            {row.image_path ?
                                                <div className={classes.imageWrapper}>
                                                    <img src={row.image_path} style={{ width: '100%' }} alt='Imagem do produto' />
                                                </div>
                                                :
                                                <p>Imagem indisponível</p>
                                            }
                                        </TableCell>
                                        <TableCell align="center">{row.name}</TableCell>
                                        <TableCell align="center">{originValue}</TableCell>
                                        <TableCell align="center">
                                            <NumberInput
                                                label="Transferência"
                                                min={0}
                                                value={product ? product.value || "" : ""}
                                                variant="outlined"
                                                source={`trasnferredProduct-${row.id}`}
                                                onChange={event => inputOnChange({ value: event.target.value, productId: row.id })}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            {originValue - productValue}
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                            :
                            <Typography>Nenhum produto no inventário</Typography>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}