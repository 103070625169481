import React from 'react';
import { Edit, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';
import { Wrapper } from '../../components';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';

const FuelEdit = (props) => {
    return (
        <Permission permission={PERMISSIONS.ADMIN}>
            <Wrapper>
                <Edit title='Editar combustível filho' {...props}>
                    <SimpleForm redirect={`/parent-fuels`}>
                        <TextInput disabled label="ID" source="id" />
                        <TextInput source="acronym" label="Sigla" maxlength="3" />
                        <TextInput source="name" label="Nome" />
                        <TextInput source="anpCode" label="Código ANP" />
                        <NumberInput source="fuelType" label='Tipo do combustível (ConectTec)' />
                        <ReferenceInput label="Combustível pai" source="parentFuelId" reference="parent-fuels">
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                    </SimpleForm>
                </Edit>
            </Wrapper>
        </Permission>
    )
};

export default FuelEdit;
