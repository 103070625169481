import { PERMISSIONS } from '../../constants'
import list from './ListCustomerGroup'
import create from './CreateCustomerGroup'
import edit from './EditCustomerGroup'

export default {
  list,
  create,
  edit,
  name: 'chains/chainId/customer-groups',
  permissions: [PERMISSIONS.CUSTOMER_GROUPS]
}
