import { PERMISSIONS } from '../../constants';
import { CPFInput, PhoneInput } from '../../components/Inputs';
import Permission from '../../components/Permission';
import getChainId from '../../lib/getChainId';
import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    required,
} from 'react-admin';

export default ({ permissions, ...props }) => {
    return (
        <Permission permission={PERMISSIONS.CREATE_CUSTOMER}>
            <Create {...props} title="Cadastrar novo cliente">
                <SimpleForm defaultValues={{ chainId: getChainId() }} redirect="show">
                    <TextInput source="name" label="Nome" InputProps={{ autoComplete: 'off' }} validate={required()} />
                    <CPFInput source="cpf" label="CPF" InputProps={{ autoComplete: 'off' }} isRequired />
                    <PhoneInput source="cellphone" label="Celular" InputProps={{ autoComplete: 'off' }} validate={required()} />
                    <TextInput source="password" label="Senha" InputProps={{ autoComplete: 'new-password' }} validate={required()} />
                    <TextInput source="confirmPassword" label="Confirmar Senha" InputProps={{ autoComplete: 'new-password' }} validate={required()} />
                </SimpleForm>
            </Create>
        </Permission>
    )
};
