import { useEffect, useState } from 'react';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import { DashboardWrapper } from '../../components';
import { PERMISSIONS } from '../../constants';
import useDashboardContext from '../../hooks/useDashboardContext';
import { getChainId } from '../../lib';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { TableHead } from '@mui/material';

const RANK_ATTENDANT_LIMIT = 10;

const useStyles = makeStyles({
  table: {
    minWidth: 280,
  },
  tableRow: {
    textDecoration: 'none',
  },
});

export const RankAttendants = () => {
  const [attendantsData, setAttendantsData] = useState(null)
  const classes = useStyles();
  const { permissions } = usePermissions();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { chain, from, to, origin, stationIds } = useDashboardContext();

  const chainId = permissions && permissions.includes(PERMISSIONS.ADMIN) ? chain : getChainId();

  useEffect(() => {
    const filter = {
      limit: RANK_ATTENDANT_LIMIT,
      from: moment.utc(from),
      to: moment.utc(to),
    };

    if (stationIds && stationIds.length > 0) { filter.placeIds = stationIds; }

    if (origin) { filter.origin = origin; }

    if (permissions && permissions.includes(PERMISSIONS.RANK_ATTENDANTS)) {
      dataProvider.getList('dashboard/rank-attendants', { filter, chainId })
        .then(response => {
          const attendants = response.data;
          attendants.forEach((attendant, index) => {
            attendant.rank = index + 1;
          });
          setAttendantsData(attendants);
        })
        .catch((e) => { notify('Erro ao carregar o ranking de frentistas', { type: 'warning' }); });
    }
  }, [permissions, from, to, chain, stationIds, origin]);

  return (
    <DashboardWrapper
      permission={PERMISSIONS.RANK_ATTENDANTS}
      title='Top Frentistas'
      width='50%'
      content={
        <TableContainer>
          {attendantsData && attendantsData.length > 0 ?
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: 20 }}>#</TableCell>
                  <TableCell>Frentista</TableCell>
                  <TableCell align="right">Abastecimentos</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {attendantsData.map(item => (
                  <TableRow
                    className={classes.tableRow}
                    key={item.id} hover>
                    <TableCell>{item.rank}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell align="right">{item.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            : null}
        </TableContainer>
      }
    />
  );
}
