import React from "react";
import { Grid } from "@mui/material";
import { List, useListContext, RecordContextProvider, SearchInput, Filter } from "react-admin";
import ListPagination from "../../components/ListPagination";
import { NewsCard } from "../../components";

const FilterNews = (props) => {
  return (
    <Filter {...props}>
      <SearchInput source="search" alwaysOn />
    </Filter>
  );
}

const NewsList = () => {
  return (
    <List
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={25}
      pagination={<ListPagination />}
      component="div"
      title="Novidades"
      exporter={false}
      filters={<FilterNews />}
    >
      <NewsGrid />
    </List>
  )
}

const NewsGrid = () => {
  const { data, isLoading } = useListContext();

  if (isLoading) {
    return null;
  }

  return (
    <Grid container direction="row" spacing={2} paddingBottom={2}>
      {data.map((record) => (
        <RecordContextProvider key={record.id} value={record}>
            <NewsCard news={record} hasEdit />
        </RecordContextProvider>
      ))}
    </Grid>
  );
}

export default NewsList;
