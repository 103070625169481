import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  TextField,
  useListContext,
  useRecordContext,
} from "react-admin";
import { ReportList } from "../../components";
import { BetweenDatesInput } from "../../components/BetweenDatesInput";
import ListPagination from "../../components/ListPagination";
import Permission from "../../components/Permission";
import { PERMISSIONS } from "../../constants";
import { getPlaceId } from "../../lib";
import { formatPoints } from "../../utils";

const useStyles = makeStyles({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
});

const billingStatusChoices = [
  { id: "open", name: "Em aberto" },
  { id: "closed", name: "Fechado" }
];

const CouponsPanel = () => {
  const classes = useStyles();
  const record = useRecordContext();
  if (!record) { return null; }

  return (
    <List
      title=" "
      resource="coupons"
      basePath="coupons"
      sort={{ field: 'redeemedAt', order: 'ASC' }}
      filter={{ billingId: record.id }}
      pagination={false}
      perPage={0}
      actions={false}
      bulkActionButtons={false}
    >
      <>
        <Typography className={classes.title}>Cupons</Typography>
        <Datagrid bulkActionButtons={false}>
          <FunctionField label="Produto" textAlign="center" render={record => record.isCashback ? "Cashback" : record.product.name || 'Não identificado'} />
          <TextField source="customer.cpf" label="Cliente" textAlign="center" sortable={false} />
          <DateField source="redeemedAt" label="Data do resgate" textAlign="center" showTime />
          <DateField source="createdAt" label="Data de geração" textAlign="center" showTime />
          <FunctionField
            source="value"
            label="Pontos"
            textAlign="center"
            render={record => formatPoints(+record?.value)}
          />
          <NumberField
            source="partnerValue"
            label="Valor"
            textAlign="center"
            emptyText="R$ 0,00"
            options={{
              style: "currency",
              currency: "BRL",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }}
          />
          <ReferenceField
            source="redemptionEmployeeId"
            reference="employees"
            label="Funcionário"
            textAlign="center"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
        </Datagrid>
      </>
    </List>
  );
}

const Billings = () => {
  const { data, resource } = useListContext();

  return (
    <Datagrid
      resource={resource}
      data={data}
      style={{ marginTop: 40 }}
      expand={<CouponsPanel />}
    >
      {!getPlaceId() &&
        <ReferenceField
          source="placeId"
          reference="chains/chainId/partners"
          label="Parceiro"
          textAlign="center"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
      }
      <NumberField source="number" label="Número da fatura" textAlign="center" />
      <NumberField
        source="totalValue"
        label="Valor total"
        textAlign="center"
        emptyText="R$ 0,00"
        options={{
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }}
      />
      <SelectField
        source="status"
        label="Status"
        textAlign="center"
        choices={billingStatusChoices}
      />
      <DateField source="expiresAt" label="Data de vencimento" textAlign="center" />
      <DateField source="paidAt" label="Data de pagamento" textAlign="center" emptyText="Sem registro" />
    </Datagrid>
  );
};

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  return (
    <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
      <DateField source="from" textAlign="center" label="De" sortable={false} />
      <DateField source="to" textAlign="center" label="Até" sortable={false} />
      <ReferenceField source="placeId" reference="places" emptyText="Todos" textAlign="center" label="Parceiro" sortable={false} >
        <TextField source="name" />
      </ReferenceField>
      <SelectField
        source="status"
        label="Status"
        textAlign="center"
        sortable={false}
        emptyText="Todos"
        choices={billingStatusChoices}
      />
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
    </Datagrid>
  );
}

const ReportBillings = (props) => {

  const listFilters = [
    <BetweenDatesInput alwaysOn />,
    <ReferenceInput
      source="placeId"
      reference="chains/chainId/partners"
      perPage={null}
      sort={{ field: "name", order: "ASC" }}
      alwaysOn
    >
      <SelectInput
        style={{ minWidth: 180 }}
        label="Parceiro"
        emptyText="Todos"
        optionText={'name'}
      />
    </ReferenceInput>,
    <SelectInput
      source="status"
      label="Status"
      choices={billingStatusChoices}
      emptyText="Todos"
      alwaysOn
    />
  ];

  const placesIndex = listFilters.findIndex(({ props }) => props.reference === "chains/chainId/partners");
  const placeId = getPlaceId();

  if (placeId && placesIndex > -1) {
    listFilters.splice(placesIndex, 1);
  }

  return (
    <Permission permission={PERMISSIONS.BILLINGS_REPORT}>
      <ReportList
        {...props}
        basePath="report-billings"
        title="Faturas"
        resource="chains/chainId/reports/billings"
        filters={listFilters}
        sort={{ field: 'createdAt', order: 'DESC' }}
        perPage={25}
        pagination={<ListPagination />}
        filterDefaultValues={{
          placeId: placeId && placeId,
          from: moment().startOf('day').subtract(1, "month").toISOString(),
          to: moment().endOf('day').toISOString()
        }}
        bulkActionButtons={false}
        exporter={false}
        landscape
        titleOnPrint="Faturas"
        filterTablePrint={<FilterTablePrint />}
      >
        <Billings />
      </ReportList>
    </Permission >
  );
};

export default ReportBillings;