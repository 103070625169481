import { Box, Divider, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { DateTimeInput, FormDataConsumer, Labeled, NumberInput, ReferenceField, SelectInput, SimpleShowLayout, TextField, TextInput, useDataProvider, usePermissions } from 'react-admin';
import { PERMISSIONS } from '../../../constants';
import { getChainId } from '../../../lib';
import { GiftExpirationType } from '../../../models';
import { useFormContext, useFieldArray } from "react-hook-form";
import { AutoCompleteSelectArrayInput, Tooltip } from '../../../components';

const FormPlaceAndLimit: any = ({ formData, productInfo }: any) => {
  const chainId = getChainId();
  const { permissions } = usePermissions();
  const { resetField } = useFormContext();
  const { fields, replace, update } = useFieldArray({ name: 'rtiPlaces' });
  const dataProvider = useDataProvider();

  useEffect(() => {
    fetchPlaces(formData);
  }, [formData.placeIds]);

  const fetchPlaces = async (formData: any) => {
    let placeIds: string[] = [];

    if (formData.placeIds && formData.placeIds.length > 0) {
      //@ts-ignore
      const { data } = await dataProvider.getList('places', { filter: { ids: formData.placeIds } });
      placeIds = formData.placeIds.filter(placeId => data.find(place => place.id === placeId && place.rtiToken));
    }

    replace(placeIds.map((placeId, index) => {
      const productPlaceRti = productInfo ? productInfo.places.find(place => place.placeId === placeId) : null;
      const rtiCode = (fields as any[]).find(field => field.placeId === placeId)?.rtiCode || (productPlaceRti ? productPlaceRti.rtiCode : null);
      resetField(`rtiPlaces[${index}].rtiCode`, { defaultValue: rtiCode });
      return { rtiCode, placeId };
    }));
  }

  return (
    <>
      {
        (!formData.chainId && (permissions && permissions.includes(PERMISSIONS.ADMIN))) &&
        <Typography>* Selecione uma rede na barra de ferramentas acima</Typography>
      }
      <Labeled
        label={
          <Tooltip
            title="Defina quantas vezes um brinde pode ser resgatado por usuário e o intervalo em horas para esse limite."
            sxTooltipContainer={{ zIndex: 9 }}
          >
            Limite de resgate
          </Tooltip>
        }
        fullWidth
      >
        <Grid container md={8} spacing={2}>
          <Grid item xs={12} md={6}>
            <NumberInput
              source="limitQuantity"
              label="Limite de resgate"
              min={1}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NumberInput
              source="limitIntervalInHours"
              label="Intervalo de resgate (horas)"
              min={1}
              fullWidth
            />
          </Grid>
        </Grid>
      </Labeled>
      <Labeled
        label={
          <Tooltip
            title="Defina um prazo após o qual os brindes resgatados não poderão mais ser utilizados."
            sxTooltipContainer={{ zIndex: 9 }}
          >
            Expiração
          </Tooltip>
        }
        fullWidth
      >
        <Grid container md={8} spacing={2}>
          <Grid item xs={12} md={6}>
            <SelectInput
              source="expirationType"
              label="Tipo de expiração"
              choices={GiftExpirationType.giftExpirationTypeChoices}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormDataConsumer>
              {({ formData }) => (
                formData.expirationType === GiftExpirationType.fixed ?
                  <DateTimeInput
                    source="expirationTime"
                    label="Data de expiração"
                    isRequired
                    fullWidth
                  />
                  : formData.expirationType === GiftExpirationType.time ?
                    <NumberInput
                      source="expirationInHours"
                      label="Intervalo de expiração (horas)"
                      min={1}
                      isRequired
                      fullWidth
                    />
                    : <></>
              )}
            </FormDataConsumer>
          </Grid>
        </Grid>
      </Labeled>
      <Grid container md={8} spacing={2}>
        <Grid item xs={12} md={12}>
          <AutoCompleteSelectArrayInput
            source="placeIds"
            references="chains/chainId/places"
            label="Locais de troca"
            optionText="name"
            defaultFilter={{ chainId }}
            sort={{ field: 'name', order: 'ASC' }}
            fullWidth
            isRequired
          />
        </Grid>
      </Grid>
      <Grid container md={8} spacing={2}>
        <Grid item xs={12} md={12}>
          {
            fields.map((field: any, index: number) => (
              <Box>
                <SimpleShowLayout sx={{ padding: 0 }} record={formData}>
                  <ReferenceField source={`rtiPlaces[${index}].placeId`} reference="places" label="Local de troca" link={false}>
                    <TextField source="name" />
                  </ReferenceField>
                </SimpleShowLayout>
                <TextInput
                  source={`rtiPlaces[${index}].rtiCode`}
                  onChange={(e) => {
                    update(index, { ...field, rtiCode: e.target.value })
                  }}
                  label="Código RTI"
                />
                <Divider sx={{ marginBottom: 2 }} />
              </Box>
            ))
          }
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(FormPlaceAndLimit);