import { PERMISSIONS } from '../../constants';
import list from './AdvertisementList';
import create from './AdvertisementCreate';
import show from './AdvertisementShow';

export default {
  list,
  create,
  show,
  name: 'advertisements',
  permissions: [PERMISSIONS.ADVERTISEMENT]
}