import React from 'react';
import { SelectInput, TextInput, required, minLength, maxLength, useInput } from 'react-admin';
import { isValidCPF, isValidCNPJ, isValidIE, isValidPhone } from '@brazilian-utils/brazilian-utils';
import { formatCellphone, formatInternationalCellphone, formatCep, formatCpf, formatCnpj } from '../utils';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

const strip = str => str.replace(/[^\d]/g, '')

export const StateInput = (props) => {
    return (
        <SelectInput
            {...props}
            choices={[
                { id: 'AC', name: "Acre" },
                { id: 'AL', name: "Alagoas" },
                { id: 'AP', name: "Amapá" },
                { id: 'AM', name: "Amazonas" },
                { id: 'BA', name: "Bahia" },
                { id: 'CE', name: "Ceará" },
                { id: 'DF', name: "Distrito Federal" },
                { id: 'ES', name: "Espírito Santo" },
                { id: 'GO', name: "Goiás" },
                { id: 'MA', name: "Maranhão" },
                { id: 'MT', name: "Mato Grosso" },
                { id: 'MS', name: "Mato Grosso do Sul" },
                { id: 'MG', name: "Minas Gerais" },
                { id: 'PA', name: "Pará" },
                { id: 'PB', name: "Paraíba" },
                { id: 'PR', name: "Paraná" },
                { id: 'PE', name: "Pernambuco" },
                { id: 'PI', name: "Piauí" },
                { id: 'RJ', name: "Rio de Janeiro" },
                { id: 'RN', name: "Rio Grande do Norte" },
                { id: 'RS', name: "Rio Grande do Sul" },
                { id: 'RO', name: "Rondônia" },
                { id: 'RR', name: "Roraima" },
                { id: 'SC', name: "Santa Catarina" },
                { id: 'SP', name: "Sâo Paulo" },
                { id: 'SE', name: "Sergipe" },
                { id: 'TO', name: "Tocantins" }
            ]} />
    );
};

export const CEPInput = (props) => {
    return (
        <TextInput
            {...props}
            format={v => formatCep(v)}
            parse={v => strip(v).substring(0, 8)}
        />
    );
};

export const CPFInput = ({ isRequired, label, source, ...props }) => {

    const validateCpf = (value) => {
        return !isValidCPF(value) ? 'CPF Inválido' : undefined;
    }

    return (
        <TextInput
            label={label}
            source={source}
            validate={isRequired ? [required('CPF é obrigatório'), validateCpf] : [validateCpf]}
            format={v => formatCpf(v)}
            parse={v => strip(v)}
            {...props}
        />
    );
};

export const HexColorInput = (props) => {

    const validateHex = (value) => {
        return value && !(/^#([0-9a-f]{3}){1,2}$/i).test(value) ? 'Inválido' : undefined;
    }

    const formatHexColor = (value) => {
        if (value) {
            return value[0] === '#' ? value : `#${value}`;
        }
        return '';
    }

    const parseHexColor = str => {
        if (str === '#') { return str; }
        const value = str.replace(/[^a-f0-9]/gi, '').substring(0, 6);
        return value ? `#${value}` : value;
    };

    return (
        <TextInput
            {...props}
            validate={props.isRequired ? [required('Campo obrigatório'), validateHex] : [validateHex]}
            format={formatHexColor}
            parse={parseHexColor}
        />
    );
};

export const CNPJInput = (props) => {

    const validateCnpj = (value) => {
        return !isValidCNPJ(value) ? 'CNPJ Inválido' : undefined;
    }

    return (
        <TextInput
            {...props}
            validate={props.isRequired ? [required('CNPJ é obrigatório'), validateCnpj] : [validateCnpj]}
            format={v => formatCnpj(v)}
            parse={v => strip(v).substring(0, 14)}
        />
    );
};

export const PhoneInput = (props) => {
    const validate = props.landline ? [minLength(10), maxLength(10)] : [minLength(10), maxLength(11)];
    const parse = (v) => props.landline ? strip(v).substring(0, 10) : strip(v).substring(0, 11)

    return (
        <TextInput
            {...props}
            format={v => formatCellphone(v)}
            parse={v => parse(v)}
            validate={props.isRequired ? [required('Campo obrigatório'), ...validate] : [...validate]}
        />
    );
};

export const InternationalPhoneInput = (props) => {
    return (
        <TextInput
            {...props}
            format={v => formatInternationalCellphone(v)}
            parse={v => strip(v).substring(0, 13)}
            validate={props.isRequired ? [required('Telefone é obrigatório'), minLength(12), maxLength(13)] : [minLength(12), maxLength(13)]}
        />
    );
};

export const IEInput = (props) => {

    const validateIE = (value = '', allValues = {}) => {
        // state required to validate
        if (!allValues.state || !value) {
            return undefined;
        }
        return !isValidIE(allValues.state, value) ? 'Inscrição Estadual Inválida' : undefined;
    };

    return (
        <TextInput
            {...props}
            validate={props.isRequired ? [required('Incrição Estadual obrigatório'), validateIE] : [validateIE]}
            format={value => value && strip(value.toString())}
            parse={(value) => value && strip(value)}
        />
    );
};


export const PixInput = (props) => {
    return (
        <TextInput
            {...props}
            format={value => {
                if (value && isValidCNPJ(value)) {
                    return formatCnpj(value);
                } else if (value && isValidCPF(value)) {
                    return formatCpf(value);
                } else if (value && isValidPhone(value)) {
                    return formatCellphone(value);
                }
                return value;
            }}
            parse={(value) => {
                const v = value && strip(value)
                if (v && (isValidCPF(v) || isValidCNPJ(v)) || isValidPhone(v)) {
                    return v;
                }
                return value;
            }
            }
        />
    );

};

export const MonthYearInput = ({ source, ...props }) => {
    const input = useInput({ source });
    const { format, ...restProps } = props;

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                {...restProps}
                value={moment(input.field.value)}
                source={source}
                views={['year', 'month']}
                format="MM/YYYY"
                onChange={(value) => {
                    const date = format ? format(value.toISOString()) : value.toISOString();
                    input.field.onChange(date);
                }}
                sx={{ maxWidth: 180 }}
            />
        </LocalizationProvider>
    );
}

export const RtiTokenInput = (props) => {
    const validateToken = (input) => {
        if (input && input.trim().indexOf(' ') !== -1) {
            return 'O campo não pode conter espaços em banco';
        } if (input && input.trim().length !== 50) {
            return "Deve conter 50 caracteres";
        }
        return undefined;
    }

    return (
        <TextInput
            {...props}
            validate={props.isRequired ? [required('Campo obrigatório'), validateToken] : [validateToken]}
            parse={v => v && v.trim()}
            format={v => v && v.trim()}
        />
    );
};