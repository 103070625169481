import { Box, Typography, styled } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { ArrayField, BooleanField, ChipField, Datagrid, DateField, FormDataConsumer, FunctionField, ImageField, NumberField, ReferenceArrayField, ReferenceField, RichTextField, SelectField, SimpleShowLayout, SingleFieldList, TextField, useRecordContext } from 'react-admin';
import { daysOfTheWeek, frequencyChoices } from '../../../constants';
import { GiftExpirationType, GiftRuleType } from '../../../models';

const FormSummary: any = ({ formData, chain, fuels }) => {
  const classes = useStyles();

  const AmountField = (props) => {
    const record = useRecordContext();
    if (!record || record.uniqueDiscountValue) { return null; }

    return (
      <NumberField
        {...props}
        label="Volume"
        textAlign="center"
        sortable={false}
        source="amount"
        emptyText="0"
        options={{
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }}
      />
    )
  }

  return (
    <>
      <SimpleShowLayout record={formData}>
        <Box component="fieldset" sx={{ border: '1px dashed grey', marginTop: 2 }}>
          <Legend>Produto</Legend>
          {
            formData.hasProduct ?
              <SimpleShowLayout sx={{ padding: 0 }}>
                <ReferenceField source="productId" label="Nome" reference="chains/chainId/products">
                  <TextField source="name" emptyText="--" />
                </ReferenceField>
              </SimpleShowLayout>
              :
              <SimpleShowLayout sx={{ padding: 0 }}>
                <TextField source="productName" label="Nome" />
                <TextField source="productDescription" label="Descrição" />
                <NumberField
                  source="productValue"
                  label="Preço"
                  options={{
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                  }}
                />
                <ImageField source="productImageFile.src" label="Imagem" emptyText="--" />
              </SimpleShowLayout>
          }
        </Box>
        <Box component="fieldset" sx={{ border: '1px dashed grey', marginTop: 2 }}>
          <Legend>Locais e limites</Legend>
          <SimpleShowLayout sx={{ padding: 0 }}>
            <ReferenceArrayField label="Locais de troca" reference="places" source="placeIds">
              <SingleFieldList>
                <ChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>
            <ArrayField source="rtiPlaces" label="Códigos RTI">
              <Datagrid bulkActionButtons={false} style={{ padding: 0 }}>
                <ReferenceField source="placeId" reference="places" label="Local de troca">
                  <TextField source="name" />
                </ReferenceField>
                <TextField source="rtiCode" label="Código RTI" emptyText="--" />
              </Datagrid>
            </ArrayField>
            <NumberField source="limitQuantity" label="Limite de resgate" emptyText="--" />
            <NumberField source="limitIntervalInHours" label="Intervalo de resgate (horas)" emptyText="--" />
            <SelectField choices={GiftExpirationType.giftExpirationTypeChoices} source="expirationType" label="Tipo de expiração" emptyText="--" />
            {
              formData.expirationType === GiftExpirationType.fixed &&
              <DateField source="expirationTime" label="Data de expiração" emptyText="--" showTime />
            }
            {
              formData.expirationType === GiftExpirationType.time &&
              <NumberField source="expirationInHours" label="Intervalo de expiração" emptyText="--" />
            }
          </SimpleShowLayout>
        </Box>
        <Box component="fieldset" sx={{ border: '1px dashed grey', marginTop: 2 }}>
          <Legend>Clientes</Legend>
          <SimpleShowLayout sx={{ padding: 0 }}>
            <ReferenceArrayField label="Etiquetas de clientes" reference="chains/chainId/tag" source="tagIds" emptyText="Sem tag">
              <FormDataConsumer>
                {({ formData }: any) => {
                  if (!Object.keys(formData).length) { return <Typography variant="body2">Sem tag</Typography>; }
                  return (
                    <SingleFieldList>
                      <ChipField source="name" />
                    </SingleFieldList>
                  )
                }}
              </FormDataConsumer>
            </ReferenceArrayField>
          </SimpleShowLayout>
        </Box>
        <Box component="fieldset" sx={{ border: '1px dashed grey', marginTop: 2 }}>
          <Legend>Agendamento</Legend>
          <SimpleShowLayout sx={{ padding: 0 }}>
            <BooleanField source="isSchedule" label="Agendamento ativo" />
            {
              (formData.isSchedule) &&
              <FunctionField render={record => record && moment(record.startAt).format('DD/MM/YYYY HH:mm')} label="Data inicial" />
            }
            {
              (formData.isSchedule) &&
              <FunctionField render={record => record && moment(record.endAt).format('DD/MM/YYYY HH:mm')} label="Data final" />
            }
            {
              (formData.isSchedule) &&
              <FunctionField render={record => record && frequencyChoices.find(dt => dt.id === record.frequency)?.name} label="Frequência" />
            }
            {
              (formData.isSchedule && formData.frequency === 'weekly') &&
              <ArrayField source="weekSchedual" label="Horários">
                <Datagrid bulkActionButtons={false}>
                  <FunctionField render={record => record && daysOfTheWeek.find(wd => wd.id === record.dayOfTheWeek)?.name} label="Dia" />
                  <FunctionField render={record => record && record.startTime ? moment(record.startTime).format('HH:mm') : '--'} label="Inicio" />
                  <FunctionField render={record => record && record.endTime ? moment(record.endTime).format('HH:mm') : '--'} label="Fim" />
                  <BooleanField source="isActive" label="Ativo?" />
                </Datagrid>
              </ArrayField>
            }
            {
              (formData.isSchedule && formData.frequency === 'daily') &&
              <FunctionField render={record => (record && record.startTime) ? moment(record.startTime).format('HH:mm') : '--'} label="Hora de inicio" />
            }
            {
              (formData.isSchedule && formData.frequency === 'daily') &&
              <FunctionField render={record => (record && record.endTime) ? moment(record.endTime).format('HH:mm') : '--'} label="Hora de fim" />
            }
          </SimpleShowLayout>
        </Box>
        <Box component="fieldset" sx={{ border: '1px dashed grey', marginTop: 2 }}>
          <Legend>Regras</Legend>
          {
            <SimpleShowLayout className={classes.containerFuelDiscount}>
              <ArrayField source="giftRules" label="">
                <Datagrid bulkActionButtons={false} style={{ padding: 0 }}>
                  <SelectField
                    choices={GiftRuleType.giftRuleTypeChoices}
                    source="type"
                    label="Tipo"
                  />
                  <SelectField source="fuelId" choices={[{ id: null, name: 'Todos' }, ...fuels]} label="Combustível" textAlign="center" />
                  <SelectField
                    choices={[{ id: "volume", name: "Litros" }, { id: "cents", name: "Valor" }]}
                    source="valueType"
                    label="Tipo de valor"
                    textAlign="center"
                  />
                  <FunctionField
                    label="Valor"
                    textAlign="center"
                    sortable={false}
                    render={data => (data && data.valueType === 'volume') ?
                      <NumberField
                        source="value"
                        emptyText="0,00"
                        options={{
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }}
                        textAlign="center"
                      />
                      :
                      <NumberField
                        source="value"
                        emptyText="R$ 0,00"
                        options={{
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }}
                        textAlign="center"
                      />
                    }
                  />
                  <ReferenceArrayField label="Postos" reference="stations" source="stationIds">
                    <SingleFieldList>
                      <ChipField source="name" />
                    </SingleFieldList>
                  </ReferenceArrayField>
                </Datagrid>
              </ArrayField>
            </SimpleShowLayout>
          }
        </Box>
        {
          formData.hasNotification &&
          <Box component="fieldset" sx={{ border: '1px dashed grey', marginTop: 2 }}>
            <Legend>Notificação</Legend>
            <SimpleShowLayout className={classes.noPadding} record={formData}>
              <TextField source="notification.title" label="Título" />
              <RichTextField source="notification.body" label="Mensagem" />
              <BooleanField source="notification.pushNotification" label="Push" />
              {/* <BooleanField source="notification.sms" label="sms"/> */}
              {
                formData.notification?.pushNotification &&
                <TextField source="notification.pushMessage" label="Messagem Push" />
              }
              {
                formData.notification?.smsNotification &&
                <TextField source="notification.pushMessage" label="Messagem SMS" />
              }
              <BooleanField source="notification.sendNow" label="Envio agora" />
              {
                !formData.notification?.sendNow &&
                <DateField source="notification.sendAt" label="Envio às" showTime />
              }
            </SimpleShowLayout>
          </Box>
        }
      </SimpleShowLayout>
    </>
  );
}

const Legend = styled('legend')({
  paddingLeft: 12,
  paddingRight: 12,
});

const useStyles = makeStyles((theme) => ({
  containerFuelDiscount: {
    padding: 0,
    paddingTop: '0px !important',
  },
  noPadding: {
    padding: 0,
  }
}));

export default FormSummary;