import React, { useCallback } from 'react'
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import {
  Edit,
  TextInput,
  required,
  minLength,
  maxLength,
  TabbedForm,
  FormTab,
  ReferenceField,
  useRecordContext,
  SimpleShowLayout,
} from 'react-admin';
import { CopyToChipboardField } from '../../components';
import QrCode from 'qrcode.react';

const GroupLinkButton: React.FC<any> = ({ label }) => {

  const { id } = useRecordContext();

  const ButtonLink = () => {
    const record = useRecordContext();

    const getGroupLink = useCallback(() => {
      const params = {
        link: `https://${record.code}.pontuax.com.br?customerGroupId=${id}`,
        apn: record.googlePackageName,
        isi: record.appleAppId,
        ibi: record.applePackageName,
      };
      return `https://${record.code}.pontuax.com.br/?${Object.entries(params).filter(([_, value]) => value).map(([key, value]) => `${key}=${value}`).join('&')}`;
    }, [record]);

    return (
      <>
        <SimpleShowLayout sx={{ padding: 0 }} record={{ ...record, groupLink: getGroupLink() }}>
          <QrCode
            renderAs="svg"
            value={getGroupLink()}
            bgColor="#ffffff"
            fgColor="#000000"
            width={256}
            height={256}
          />
          <CopyToChipboardField source="groupLink" label={label} showClipButton />
        </SimpleShowLayout>
      </>
    );
  }

  return (
    <ReferenceField source="chainId" reference="chains" link={false}>
      <ButtonLink />
    </ReferenceField>
  );
}

const EditCustomerGroup = (props) => {
  return (
    <Permission permission={PERMISSIONS.CUSTOMER_GROUPS}>
      <Edit {...props} title='Editar grupo de clientes'>
        <TabbedForm>
          <FormTab label="Identificação">
            <TextInput source="name" label="Nome" validate={[required(), minLength(3), maxLength(240)]} InputProps={{ autoComplete: 'off' }} />
            <TextInput source="description" label="Descrição" validate={[required(), minLength(3), maxLength(240)]} InputProps={{ autoComplete: 'off' }} />
            <GroupLinkButton label="Link de convite" />
          </FormTab>
        </TabbedForm>
      </Edit>
    </Permission>
  );
};

export default EditCustomerGroup;
