import { makeStyles } from '@mui/styles';
import { Fragment, useEffect, useState } from 'react';
import {
  ArrayInput,
  BooleanInput,
  Create,
  DateTimeInput,
  FormDataConsumer,
  FormTab,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TimeInput,
  required,
  useDataProvider
} from 'react-admin';
import { FuelAccordion } from '../../components/FuelAccordion';
import Permission from '../../components/Permission';
import { PERMISSIONS, daysOfTheWeek, frequencyChoices } from '../../constants';
import { getChainId } from '../../lib';

const FuelSchedualedDiscountCreate = (props) => {
  const classes = useStyles();

  const dataProvider = useDataProvider();
  const [chainId, setChainId] = useState(getChainId());
  const [placeIds, setPlaceIds] = useState();
  const [selectsAllPlaces, setselectsAllPlaces] = useState(false);
  const [placeChoices, setPlaceChoices] = useState([]);
  const [fuels, setFuels] = useState();
  const [frequency, setFrequency] = useState('');

  useEffect(() => {
    if (chainId) {
      if (placeChoices && placeChoices.length) {
        if (placeIds && placeIds.length || selectsAllPlaces) {
          dataProvider.getList('fuels', {
            sort: {
              field: 'name',
              order: 'ASC'
            },
            filter: { placeIds: selectsAllPlaces ? placeChoices.map(place => place.id) : placeIds }
          })
            .then(res => {
              setFuels(res && res.data);
            });
        } else {
          setFuels([]);
        }
      }
    }
  }, [placeChoices, placeIds, selectsAllPlaces]);

  useEffect(() => {
    if (chainId) {
      dataProvider.getList('stations', { sort: { field: 'name', order: 'ASC' }, filter: { chainId } }).then(res => {
        setPlaceChoices(res.data);
      });
    }
  }, [chainId]);

  return (
    <Permission permission={PERMISSIONS.FUEL_DISCOUNTS}>
      <Create {...props} title="Criar desconto agendado" redirect="list">
        <TabbedForm>
          <FormTab label="Descontos">
            {!getChainId() &&
              <ReferenceInput label="Rede" source="chainId" reference="chains" onChange={({ target: { value } }) => setChainId(value)}>
                <SelectInput resettable optionText="name" />
              </ReferenceInput>
            }
            {chainId && (
              <FormDataConsumer>
                {({ formData }) => {
                  formData.chainId = chainId;
                  return (
                    <>
                      <div style={{ display: 'flex' }}>
                        {!selectsAllPlaces &&
                          <SelectArrayInput
                            label="Postos"
                            style={{ minWidth: 180, marginRight: 20 }}
                            optionText={'name'}
                            source={"placeIds"}
                            onChange={({ target: { value } }) => setPlaceIds(value)}
                            choices={placeChoices}
                            validate={required()}
                          />
                        }
                        <BooleanInput
                          source="selectsAllPlaces"
                          label="Selecionar todos os postos"
                          className={classes.selectsAllPlacesButton}
                          onChange={value => setselectsAllPlaces(value)}
                        />
                      </div>
                      <BooleanInput label="Sobrepor desconto de grupo" source="overlapsGroupDiscount" />
                    </>
                  )
                }}
              </FormDataConsumer>
            )}
            {chainId && fuels &&
              <FormDataConsumer>
                {({ formData: { fuelDiscounts } }) => {
                  return fuels.map((fuel, index) => {
                    if (fuelDiscounts) {
                      const i = fuelDiscounts.findIndex(f => f.fuelId === fuel.id);
                      if (i === -1) {
                        fuelDiscounts[index] = {
                          ...fuelDiscounts[index],
                          fuelId: fuel.id,
                        }
                      } else {
                        fuelDiscounts[i] = fuelDiscounts[index];
                      }
                    }
                    return (
                      <FuelAccordion
                        name={fuel.name}
                        source={`fuelDiscounts[${index}]`}
                        hideAmount={
                          fuelDiscounts && fuelDiscounts[index] ? fuelDiscounts[index].uniqueDiscountValue : true
                        }
                      />
                    )
                  });

                }}
              </FormDataConsumer>
            }
          </FormTab>
          <FormTab label="Agendamento">
            <>
              <div className={classes.dateTimePicker}>
                <DateTimeInput
                  source="startDate"
                  label="Data inicial"
                  validate={[required()]}
                />
                <DateTimeInput
                  source="endDate"
                  label="Data final"
                  validate={[required()]}
                />
              </div>
              <SelectInput
                label="Frequência"
                source="frequency"
                alwaysOn
                style={{ marginTop: 10 }}
                choices={frequencyChoices}
                onChange={({ target: { value } }) => setFrequency(value)}
                validate={[required()]}
              />
              {frequency === 'daily' &&
                <div className={classes.dailyContainer}>
                  <TimeInput
                    source="startTime"
                    label="Horário inicial"
                    style={{ width: 200 }}
                    isRequired
                    validate={[required()]}
                    parse={value => value.toString()}
                  />
                  <TimeInput
                    source="endTime"
                    label="Horário final"
                    style={{ width: 200 }}
                    isRequired
                    validate={[required()]}
                    parse={value => value.toString()}
                  />
                </div>
              }
              {frequency === 'weekly' &&
                <FormDataConsumer>
                  {({ formData }) => {
                    if (!formData.weekSchedual) {
                      formData.weekSchedual = daysOfTheWeek.map(day => ({
                        dayOfTheWeek: day.id,
                        isActive: false
                      }));
                    } else {
                      formData.weekSchedual.forEach(ws => {
                        if (!ws.isActive) {
                          delete ws.startTime;
                          delete ws.endTime;
                        }
                      })
                    }
                    return (
                      <ArrayInput source="weekSchedual" label="Defina os dias e horários">
                        <SimpleFormIterator disableAdd disableRemove disableReordering>
                          <FormDataConsumer>
                            {({ scopedFormData, getSource }) => {
                              const day = daysOfTheWeek.find(wd => wd.id === scopedFormData.dayOfTheWeek).name;
                              return (
                                <>
                                  <p className={classes.day}>{day}</p>
                                  <div className={classes.inputWrapperStyle}>
                                    <BooleanInput
                                      source={getSource && getSource("isActive")}
                                      label={false}
                                      className={classes.input}
                                    />
                                    {scopedFormData && scopedFormData.isActive &&
                                      <Fragment>
                                        <TimeInput
                                          label='Horário inicial'
                                          record={scopedFormData}
                                          source={getSource && getSource("startTime")}
                                          style={{ marginRight: 20, width: 200 }}
                                          isRequired
                                          parse={value => value.toString()}
                                        />
                                        <TimeInput
                                          record={scopedFormData}
                                          label='Horário final'
                                          source={getSource && getSource("endTime")}
                                          style={{ marginRight: 20, width: 200 }}
                                          isRequired
                                          parse={value => value.toString()}
                                        />
                                      </Fragment>
                                    }
                                  </div>
                                </>
                              );
                            }}
                          </FormDataConsumer>
                        </SimpleFormIterator>
                      </ArrayInput>
                    );
                  }}
                </FormDataConsumer>
              }
            </>
          </FormTab>
        </TabbedForm>
      </Create>
    </Permission>
  );
}

const useStyles = makeStyles((theme) => ({
  dailyContainer: {
    display: 'flex',
    gap: 30,
    marginBottom: 20
  },
  dateTimePicker: {
    display: 'flex',
    gap: 20,
  },
  inputWrapperStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 0 20px 0',
  },
  input: {
    margin: '0 20px 0 0',
  },
  selectsAllPlacesButton: {
    marginTop: '20px',
  },
  day: {
    fontSize: 16,
    margin: '20px 0'
  },
}));

export default FuelSchedualedDiscountCreate;