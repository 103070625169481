import React from 'react';
import {
  List,
  Datagrid,
  TextField,
} from 'react-admin';
import ListPagination from '../../components/ListPagination';

export default props => (
  <List
    {...props}
    perPage={25}
    pagination={<ListPagination />}
    exporter={false}
    title="Parceiros Externos"
  >
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" label="Nome" />
    </Datagrid>
  </List>
)
