export enum DispatchMessageType {
  pushNotification = "push-notification",
  sms = "sms",
}

export namespace DispatchMessageType {
  export const translateType = (type: DispatchMessageType): string => {
    switch (type) {
      case DispatchMessageType.pushNotification:
        return "Push";
      case DispatchMessageType.sms:
        return "SMS";
      default:
        return "";
    }
  };
}
