import getAll from './getAll'
import getOne from './getOne'
import create from './create'
import createMany from './createMany'
import update from './update'
import destroy from './delete'
import getMany from './getMany'
import getManyReference from './getManyReference'
import deleteMany from './deleteMany'
import updateMany from './updateMany'

export default (type, resourceName, params) => {
  const actions = {
    GET_LIST: getAll,
    GET_ONE: getOne,
    GET_MANY_REFERENCE: getManyReference,
    GET_MANY: getMany,
    CREATE: create,
    "createMany": createMany,
    UPDATE: update,
    DELETE: destroy,
    DELETE_MANY: deleteMany,
    UPDATE_MANY: updateMany,
  }

  return actions[type] ? actions[type](resourceName, params) : void 0
}
