import Group from '@mui/icons-material/Group';
import { PERMISSIONS } from '../../../constants';
import list from './pointFillinIntervalList';
import create from './pointFillinIntervalCreate';
import show from './pointFillinIntervalShow';
import ComponentWithPermissions from '../../ComponentWithPermissions';

export default {
  list,
  show,
  create: new ComponentWithPermissions({ component: create as React.ComponentType, permissions: [PERMISSIONS.POINT_RULES] }),
  name: 'chains/chainId/point-fillin-interval',
  options: { label: 'Intervalo entre Abastecimentos' },
  icon: Group,
}