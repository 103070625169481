export enum FillinPresetType {
  cash = 'cash',
  volume = 'volume',
  completely = 'completely',
};

export namespace FillinPresetType {
  export const transalateType = (type: FillinPresetType): string => {
    switch (type) {
      case FillinPresetType.cash:
        return "Dinheiro"
      case FillinPresetType.volume:
        return "Volume";
      case FillinPresetType.completely:
        return "Encher tanque";
      default: return "";
    };
  };

  export const fillinPresetTypeChoices: Array<{ id: string, name: string }> = [
    { id: FillinPresetType.cash, name: FillinPresetType.transalateType(FillinPresetType.cash) },
    { id: FillinPresetType.volume, name: FillinPresetType.transalateType(FillinPresetType.volume) },
    { id: FillinPresetType.completely, name: FillinPresetType.transalateType(FillinPresetType.completely) },
  ];
};