import { stringify } from 'query-string';
import { getChainId, getWithFilter, httpClientReactAdmin } from '../../lib';
import { DEFAULT_API_ADMIN, mapPath } from '../../constants';

const getResourcePath = (path, resourceName) => {
  return `${(path || resourceName || '').replace('chainId', getChainId())}`;
};

export default async (resourceName, params) => {
  const query = {};
  if (params.pagination) {
    const { page, perPage } = params.pagination;
    query.skip = (page - 1) * perPage;
    perPage
      ? query.take = perPage
      : null;
  }
  if (params.sort) {
    const { field, order } = params.sort;
    query.order = JSON.stringify({ [field]: order });
  }
  if (params.groupBy) {
    query.groupBy = params.groupBy;
  }
  if (params.chainId || getChainId()) {
    query.chainId = `"${params.chainId || getChainId()}"`;
  }

  const _params = getWithFilter(params, resourceName);

  if (_params.filter) {
    query.filter = JSON.stringify(_params.filter);
  }

  const path = mapPath[resourceName];
  const URL = `${DEFAULT_API_ADMIN}/${getResourcePath(path, resourceName)}?${stringify(
    query,
  )}`;

  const { json } = await httpClientReactAdmin(URL, { method: 'GET' });
  return json;
};
