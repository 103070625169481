import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import {
  FormDataConsumer,
  Labeled,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useDataProvider,
  useNotify,
  usePermissions,
} from 'react-admin';
import { Wrapper } from '../../components';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { getChainId } from '../../lib';
import RequiredChain from '../../components/RequiredChain';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 300,
    marginTop: 40,
  },
}));

const FuelInput = ({ placeId, fuelId, label, placeFuels }) => {
  const placeIndex = placeFuels.findIndex(item => item.placeId === placeId);
  const fuelIndex = placeFuels[placeIndex].fuels.findIndex(item => item.fuelId === fuelId);

  return (
    <Labeled label={label}>
      <TextInput
        source={`placeFuels[${placeIndex}].fuels[${fuelIndex}].cashbackFactor`}
        label="Fator Valor/Litro"
        format={value => !value ? 'R$ 0,00' : `R$ ${Number(value).toFixed(2)}`}
        parse={value => !value ? 0 : +value.replace(/[^\d]/g, '') / 100}
      />
    </Labeled>
  );
};

const StationFuels = ({ placeFuels, placeId }) => {
  const [fuels, setFuels] = useState([]);
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();

  useEffect(() => {
    setLoading(true);
    dataProvider.getList('fuels', { sort: { field: 'name', order: 'ASC' }, pagination: { page: 1, perPage: false }, filter: { placeId } })
      .then(({ data }) => {
        setFuels(data);
      }).finally(() => setLoading(false));
  }, [placeId]);

  if (loading) { return null; }

  return (
    fuels.map(({ id, name }) => (
      <FuelInput placeFuels={placeFuels} placeId={placeId} fuelId={id} label={name} />
    ))
  );
}

const FillinCashback = () => {
  const { isLoading: isLoadingPermissions, permissions } = usePermissions();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const classes = useStyles();

  const patchFillinCashback = values => {
    values.placeFuels = values.placeFuels.map(({ fuels, ...rest }) => ({
      ...rest, fuels: fuels.map(({ fuelId, placeId, cashbackFactor }) => ({ fuelId, placeId, cashbackFactor }))
    }));

    setLoading(true);
    dataProvider
      .UPDATE(`chains/chainId/fillin-cashback`, { data: values, previousData: initialValues })
      .then(() => notify('Configurações salvas com sucesso'))
      .catch(() => notify('Erro ao salvar configurações', { type: 'warning' }))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!isLoadingPermissions && permissions.includes(PERMISSIONS.FILLIN_CASHBACK)) {
      setLoading(true);
      dataProvider
        .getOne(`chains/chainId/fillin-cashback`, {})
        .then(({ data }) => setInitialValues(data))
        .catch(() => notify('Erro ao buscar configurações atuais', { type: 'warning' }))
        .finally(() => setLoading(false));
    }
  }, [isLoadingPermissions]);

  return (
    <Wrapper>
      <Permission permission={PERMISSIONS.FILLIN_CASHBACK}>
        <SimpleForm onSubmit={patchFillinCashback} saving={loading} defaultValues={initialValues}>
          <div className={classes.container}>
            <ReferenceInput
              filter={{ chainId: getChainId(), type: 'station' }}
              sort={{ field: 'name', order: 'ASC' }}
              source="placeId"
              reference="places"
              perPage={null}
            >
              <SelectInput
                label="Local"
                optionText="name"
              />
            </ReferenceInput>
            <FormDataConsumer>
              {({ formData: { placeId, placeFuels } }) =>
                placeId && ( <StationFuels placeFuels={placeFuels} placeId={placeId} /> )
              }
            </FormDataConsumer>
          </div>
        </SimpleForm>
      </Permission>
    </Wrapper>
  );
};

export default (props) => (
  <RequiredChain>
    <FillinCashback {...props} />
  </RequiredChain>
);