import React from "react";
import { Datagrid, DateField, Filter, FunctionField, ReferenceField, RichTextField, SearchInput, SelectField, SelectInput, TextField, useListContext } from 'react-admin';
import { Permission, ReportList } from "../../components";
import ListPagination from '../../components/ListPagination';
import { PERMISSIONS } from "../../constants";
import { BetweenDatesInput } from "../../components/BetweenDatesInput";
import { makeStyles } from '@mui/styles';
import moment from "moment";
import { DispatchMessageOrigin } from "../../models";

const useStyles = makeStyles({
  richText: {
    '& img': {
      maxWidth: 300,
      maxHeight: 300,
    }
  }
});

const statuses = [
  { id: 'scheduled', name: 'Agendada' },
  { id: 'canceled', name: 'Cancelada' },
  { id: 'dispatched', name: 'Enviada' },
];

const types = [
  { id: 'sms', name: 'SMS' },
  { id: 'push-notification', name: 'Notificação Push' },
];

const ListNotifications = () => {
  const classes = useStyles();

  return (
    <Datagrid bulkActionButtons={false}>
      <DateField source="sendAt" label="Data de envio" showTime />
      <ReferenceField source="messageId" label="Título" reference="chains/chainId/notification-messages" link={false}>
        <TextField source="title" sortable={false} />
      </ReferenceField>
      <ReferenceField source="messageId" label="Mensagem" reference="chains/chainId/notification-messages" link={false}>
        <RichTextField source="body" className={classes.richText} sortable={false} />
      </ReferenceField>
      <SelectField source="type" label="Tipo" choices={types} />
      <SelectField source="status" label="Status" choices={statuses} />
      <FunctionField source="origin" label="Origem" sortable={false} render={record => DispatchMessageOrigin.translateOrigin(record ? record.origin : null)} />
    </Datagrid>
  );
};


const FilterTablePrint = () => {
  const { filterValues } = useListContext();
  const classes = useStyles();

  return (
    <Datagrid
      data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]}
      total={1}>
      <DateField source='from' label='De' locales='pt-BR' />
      <DateField source='to' label='Até' locales='pt-BR' />
      <DateField source='generatedReportAt' label='Gerado às' showTime sortable={false} locales='pt-BR' />
      <SelectField source="type" label="Tipo" choices={types} />
      <SelectField source="status" label="Status" choices={statuses} />
    </Datagrid>
  );
}

const ListFilters = props => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn />
    <BetweenDatesInput alwaysOn />
    <SelectInput
      source="status"
      label="Status"
      choices={statuses}
      emptyText="Todos"
      alwaysOn
    />
    <SelectInput
      source="type"
      label="Tipo"
      choices={types}
      emptyText="Todos"
      alwaysOn
    />
  </Filter>
);

const ReportNotifications = props => (
  <Permission permission={PERMISSIONS.NOTIFICATIONS_REPORT}>
    <ReportList
      {...props}
      basePath="report-notifications"
      resource="chains/chainId/reports/notifications"
      title="Relatório de Notificações"
      filters={<ListFilters />}
      filterDefaultValues={{ from: moment().startOf('day').subtract(1, 'month').toISOString(), to: moment().endOf('day').toISOString() }}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={25}
      pagination={<ListPagination />}
      titleOnPrint="Relatório de Notificações"
      filterTablePrint={<FilterTablePrint />}
      landscape={false}
    >
      <ListNotifications />
    </ReportList>
  </Permission>
);

export default ReportNotifications;
