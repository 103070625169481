import { PERMISSIONS } from '../../constants'
import list from './ParentFuelList'
import create from './ParentFuelCreate'
import edit from './ParentFuelEdit'

export default {
  list,
  create,
  edit,
  name: 'parent-fuels',
  permissions: [PERMISSIONS.ADMIN]
}