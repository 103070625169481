import {
  Edit,
  SimpleForm,
  TextInput,
  minLength,
  required
} from 'react-admin';
import { getUserId } from '../../lib';

const passwordValidation = (value, allValues) => {
  if (!value) {
    return 'Senha obrigatória';
  }
  if (value !== allValues.passwordConfirmation) {
    return 'Senhas não conferem';
  }
  return undefined;
};

const passwordConfirmationValidation = (value, allValues) => {
  if (!value) {
    return 'Senha obrigatória';
  }
  if (value !== allValues.password) {
    return 'Senhas não conferem';
  }
  return undefined;
};

export default props => {
  return (
    <Edit {...props} title="Nova Senha" redirect={false} resource={`employees`} id={getUserId()}>
      <SimpleForm redirect={false}>
        <TextInput
          source="password"
          label="Alterar Senha"
          type="password"
          InputProps={{ autoComplete: 'new-password' }}
          validate={[required(), minLength(6), passwordValidation]}
        />
        <TextInput
          source="passwordConfirmation"
          label="Confirmar Nova Senha"
          type="password"
          InputProps={{ autoComplete: 'new-password' }}
          validate={[required(), minLength(6), passwordConfirmationValidation]}
        />
      </SimpleForm>
    </Edit>
  );
};
