import React from 'react'
import { List, Datagrid, TextField, Filter, SearchInput, ReferenceInput, SelectInput } from 'react-admin'
import Permission from '../../components/Permission'
import { PERMISSIONS } from '../../constants'
import ListPagination from '../../components/ListPagination'
import { getChainId } from '../../lib'
import RequiredChain from '../../components/RequiredChain'

const CustomerGroupFilter = (props) => (
  <Filter {...props}>
    {Permission({
      permission: PERMISSIONS.ADMIN,
      children: (
        <ReferenceInput
          alwaysOn
          reference="chains"
          source="chainId"
          sort={{ field: 'name', order: 'ASC' }}
          //@ts-ignore
          perPage={null}
        >
          <SelectInput label="Rede" optionText="name" style={{ minWidth: 220 }} />
        </ReferenceInput>
      )
    })}
    <SearchInput source="search" alwaysOn />
  </Filter>

);

const ListCustomerGroup = (props) => {
  return (
    <Permission permission={PERMISSIONS.CUSTOMER_GROUPS}>
      <RequiredChain>
        <List
          {...props}
          perPage={25}
          pagination={<ListPagination />}
          exporter={false}
          bulkActionButtons={false}
          filters={<CustomerGroupFilter />}
          filterDefaultValues={{ chainId: getChainId() }}
          sort={{ field: 'name', order: 'ASC' }}
          title="Grupos de clientes"
        >
          <Datagrid rowClick="edit">
            <TextField source="name" label="Nome" />
            <TextField source="description" label="Descrição" />
          </Datagrid>
        </List>
      </RequiredChain>
    </Permission>
  );
};

export default ListCustomerGroup;
