import LockResetIcon from '@mui/icons-material/LockReset';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { AppBar, Logout, MenuItemLink, UserMenu, usePermissions } from 'react-admin';
import { ChainButton, NotificationPanel } from '../../components';
import { PERMISSIONS } from '../../constants';
import { getChainId, getEmployeeType } from '../../lib';
import getChainName from '../../lib/getChainName';
import getPlaceName from '../../lib/getPlaceName';
import { EmployeeType } from '../../models';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    userPlace: {
        marginRight: 20,
    }
});

const ConfigurationMenu = React.forwardRef<any, any>((props, ref) => {
    return (
        <>
            <MenuItemLink
                ref={ref}
                primaryText="Alterar Senha"
                to="/change-password"
                leftIcon={<LockResetIcon sx={{ width: '1em', height: '1em' }} />}
                sx={{ paddingLeft: '10px' }}
                onClick={props.onClick}
            />
            <Logout />
        </>
    );
});

const CustomUserMenu = (props: any) => (
    <UserMenu {...props}>
        <ConfigurationMenu />
    </UserMenu>
);

const MyAppBar = props => {
    const classes = useStyles();
    const { permissions } = usePermissions();

    return (
        <AppBar {...props} color="primary" userMenu={<CustomUserMenu />}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />

            {(getEmployeeType() === EmployeeType.chain && getChainId()) &&
                <NotificationPanel />
            }

            <Typography className={classes.userPlace}>{getPlaceName()}</Typography>
            {
                permissions && permissions.includes(PERMISSIONS.ADMIN) ?
                    <ChainButton />
                    :
                    <Typography>{getChainName()}</Typography>
            }
        </AppBar>
    );
};

export default MyAppBar;