import { PERMISSIONS } from '../../constants';
import list from './CompaniesWithoutPointsList';
import create from './CompanyWithoutPointCreate';
import edit from './CompanyWithoutPointEdit';

export default {
  list,
  create,
  edit,
  name: 'chains/chainId/companies-without-points',
  permissions: [PERMISSIONS.MANAGE_COMPANY_WITHOUT_POINT]
}