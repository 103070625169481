import { PERMISSIONS } from '../../constants';
import { UserRole } from '../../models';
import ComponentWithPermissions from '../ComponentWithPermissions';
import show from './FillinShow';
import edit from './FillinEdit';

export default {
  show,
  edit: new ComponentWithPermissions({ component: edit, permissions: [PERMISSIONS.EDIT_FILLIN], userRoles: [UserRole.admin] }),
  name: 'fillins',
  permissions: [PERMISSIONS.LIST_FILLINS]
};