import React from 'react';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import {
    Edit,
    TextInput,
    required,
    SimpleForm,
    ImageInput,
    ImageField,
} from 'react-admin';
import { ImageInformations } from '../../components';

export default props => (
    <Permission permission={PERMISSIONS.ADMIN}>
        <Edit {...props} title="Editar serviço" >
            <SimpleForm>
                <TextInput source="name" label="Nome" validate={required("Nome é obrigatório")} />
                <TextInput source="description" label="Descrição" />
                <ImageInput source="imagefile" label="Imagem" accept="image/*">
                    <ImageField source="src" label="Imagem" />
                </ImageInput>
                <ImageInformations position={-20}/>
            </SimpleForm>
        </Edit>
    </Permission>
);

