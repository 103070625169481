import React, { useEffect, useState } from 'react';
import { useDataProvider, usePermissions } from 'react-admin';
import { AreaChart, XAxis, YAxis, Tooltip, Area } from 'recharts';
import { formatNumber, getInitialChart } from '../../utils';
import { PERMISSIONS } from '../../constants';
import { getChainId, getEmployeeType, getAttendantId } from '../../lib';
import useDashboardContext from '../../hooks/useDashboardContext';
import { DashboardWrapper, RotatedAxisTick } from '../../components';
import moment from 'moment';
import { EmployeeType } from '../../models';

const colors = {
    area01: "#82ca9d",
    area02: "#8884d8",
};

export const FillingCompletelyXfillingLimit = () => {
    const dataProvider = useDataProvider();
    const { permissions } = usePermissions();
    const { from, to, chain, origin, stationIds } = useDashboardContext();

    const initialChart = getInitialChart(from, to, { id: 0 });
    const [chartData, setChartData] = useState(initialChart);

    const chainId = permissions && permissions.includes(PERMISSIONS.ADMIN) ? chain : getChainId();

    const formatToPercentage = (value, total) => {
        return ((value * 100) / total).toFixed(2);
    }

    useEffect(() => {
        const filter = {
            from: moment.utc(from),
            to: moment.utc(to),
            chainId,
            origin,
        };

        if (stationIds && stationIds.length > 0) { filter.placeIds = stationIds; }

        if (getEmployeeType() === EmployeeType.attendant) { filter.attendantId = getAttendantId(); }

        dataProvider.getList('dashboard/filling-completely', {
            filter,
            chainId,
        }).then(({ data }) => {
            data.forEach((element) => {
                element.date = moment(element.id, 'YYYY-MM-DD').format('DD/MM/YYYY');
                element.filling_completely = formatToPercentage(element.filling_completely, element.total);
                element.filling_limit = formatToPercentage(element.filling_limit, element.total);
            });
            setChartData(data);
        });
    }, [from, to, chain, origin, stationIds]);

    return (
        <DashboardWrapper
            title='Encher tanque vs Não encher tanque'
            permission={PERMISSIONS.FILLINS_CHART}
            aspectRatio={6.0 / 2.5}
            stackOffset="expand"
            content={
                <AreaChart data={chartData}>
                    <YAxis domain={[0, 100]} tickFormatter={(value) => `${value}%`} />
                    <XAxis dataKey="date" tick={<RotatedAxisTick fontSize={12} />} height={60} />
                    <Tooltip formatter={(value) => `${formatNumber(value)}% dos abastecimentos`} />
                    <Area
                        type="monotone"
                        name='Encher Tanque'
                        dataKey="filling_completely"
                        stackId="1"
                        stroke={colors.area01}
                        fill={colors.area01}
                    />
                    <Area
                        type="monotone"
                        name="Não encher tanque"
                        dataKey="filling_limit"
                        stackId="1"
                        stroke={colors.area02}
                        fill={colors.area02}
                    />
                </AreaChart>
            }
        />
    );
}