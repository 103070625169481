import React from 'react';
import { Button, useDataProvider, useNotify, useRecordContext, useRedirect, useResourceContext } from 'react-admin';

interface ClonGiftButtonProp {
  record?: any;
  variant?: 'text' | 'outlined' | 'contained';
  style?: any;
}

const CloneGiftButton: React.FC<ClonGiftButtonProp> = (props) => {
  const record = useRecordContext();
  if (!record) { return null; }
  const resource = useResourceContext();

  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handleOnClick = (event: any) => {
    event.stopPropagation();

    dataProvider.getOne(resource, { id: record.id })
      .then(({ data }) => {
        redirect(`/${resource}/create`, undefined, undefined, undefined, data);
      })
      .catch((err) => {
        notify('Não foi possível realizar a operação, tente novamente');
      });
  }

  return (
    <>
      <Button {...props} onClick={handleOnClick} variant='outlined' label="Clonar" />
    </>
  );
}

export default CloneGiftButton;