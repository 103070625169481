import { Alert, AlertTitle, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import {
    Create,
    FormDataConsumer,
    Labeled,
    RadioButtonGroupInput,
    SimpleForm,
    TextInput,
    Toolbar,
    maxLength,
    required
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { getChainId } from '../../lib';
import { SaveConfirmButton } from '../../components';
import MoneyIcon from '@mui/icons-material/AttachMoney';

const editStyles = makeStyles({
    Toolbar: {
        justifyContent: 'space-between'
    },
    charCounter: {
        top: '35%',
        right: '1%',
        width: 'auto',
        heigth: '100%',
        fontSize: 12,
        opacity: .75,
        fontFamily: 'Helvetica',
        textAlign: 'end',
    },
})

const CreateBulkSend: React.FC = ({ ...props }) => {
    const { state: params }: { state: any } = useLocation();
    const classes = editStyles();

    const [counterCharSms, setCounterCharSms] = React.useState(0);

    const MAX_LENGTH_SMS = 160;

    const counterCharInput = (valueInput: string) => {
        setCounterCharSms(valueInput.length);
    }

    const CreateToolbar = (props) => {

        return (
            <Toolbar {...props} className={classes.Toolbar}>
                <FormDataConsumer>
                    {({ formData, ...rest }) => (
                        <SaveConfirmButton
                            {...rest}
                            formData={formData}
                            contentMessage={
                                <Alert
                                    variant="outlined"
                                    severity="warning"
                                    iconMapping={{
                                        warning: <MoneyIcon />,
                                    }}
                                >
                                    O envio de SMS está sujeito a <strong>cobranças</strong>. O <strong>custo aumenta</strong> proporcionalmente ao <strong>número de clientes</strong> selecionados. Verifique a lista de destinatários e esteja ciente dos custos adicionais.
                                </Alert>
                            }
                        />)}
                </FormDataConsumer>
            </Toolbar>
        );
    }
    return (
        <Permission permission={PERMISSIONS.BULK_SEND}>
            <Create
                {...props}
                title="Cadastrar envio em massa"
            >
                <SimpleForm
                    toolbar={<CreateToolbar />}
                    defaultValues={{
                        type: 'sms',
                        chainId: getChainId(),
                        customerIds: params?.customerIds || [],
                    }}
                >
                    <Grid container>
                        <Grid item sm={12} md={8} lg={6} display="flex" flexDirection="column" alignItems="flex-end">
                            <TextInput
                                source="message"
                                label="Mensagem"
                                validate={[required(), maxLength(MAX_LENGTH_SMS)]}
                                inputProps={{ autoComplete: 'off', maxlength: MAX_LENGTH_SMS }}
                                onChange={(e) => counterCharInput(e.target.value)}
                                helperText={false}
                                fullWidth
                            />
                            <span className={classes.charCounter}>{counterCharSms}/{MAX_LENGTH_SMS}</span>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sm={12} md={8} lg={6}>
                            <Labeled label="Clientes">
                                <Typography>{params?.customerIds?.length || 0} clientes selecionados</Typography>
                            </Labeled>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sm={12} md={8} lg={6}>
                            <RadioButtonGroupInput
                                source="type"
                                label="Tipo" choices={[
                                    { id: 'sms', name: 'SMS' },
                                    // { id: 'whatsapp', name: 'Whatsapp' },
                                ]}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sm={12} md={8} lg={6}>
                            <Alert variant="outlined" severity="info" sx={{ width: '100%' }}>
                                <AlertTitle>Atenção</AlertTitle>
                                Você está prestes a enviar mensagens em massa. Este envio será realizado instantaneamente e não poderá ser desfeito. Por favor, verifique as informações cuidadosamente antes de prosseguir.
                            </Alert>
                        </Grid>
                    </Grid>
                </SimpleForm>
            </Create>
        </Permission>
    );
}

export default CreateBulkSend
