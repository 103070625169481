import { orderBy } from 'lodash';
import {
  ArrayField,
  ChipField,
  CreateButton,
  Datagrid,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectField,
  SelectInput,
  TextField,
  TopToolbar,
  useRecordContext
} from 'react-admin';
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { RatingPollItemType } from '../../models';
import { getChainId } from '../../lib';
import EmojiArrayInput, { EmojiRating } from '../../components/EmojiArrayInput';

const ListActions = props => {
  return (
    <TopToolbar>
      <CreateButton {...props} />
    </TopToolbar>
  );
};

const RatingPollFilter = props => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn />
    {
      Permission({
        permission: PERMISSIONS.ADMIN,
        children: (
          <ReferenceInput
            source="chainId"
            reference="chains"
            //@ts-ignore
            perPage={null}
            sort={{ field: 'name', order: 'ASC' }}
            alwaysOn
          >
            <SelectInput optionText="name" label="Rede" />
          </ReferenceInput>
        )
      })
    }
    <SelectInput
      source="type"
      label="Tipo"
      choices={RatingPollItemType.ratingPollItemTypeChoices}
      emptyText="Todos"
      alwaysOn
    />
    <EmojiArrayInput
      source="ratings"
      label="Notas"
      alwaysOn
    />
  </Filter>
);

const RatingField = () => {
  const record = useRecordContext();
  if (!record) { return null; }

  record.ratings = orderBy(record.ratings);

  return (
    <div style={{ display: 'flex', gap: 5 }}>
      {
        record.ratings?.map((n:number, index: number) => (
          <ChipField record={{ ...record, ratings: record.ratings.map(rating => `${EmojiRating[rating - 1]} (${n})`) }} source={`ratings[${index}]`} sx={{ fontSize: 18 }} />
        ))
      }
    </div>
  );
};

export default props => (
  <List
    {...props}
    perPage={25}
    pagination={<ListPagination />}
    actions={<ListActions />}
    exporter={false}
    title="Lista de enquete de avaliação"
    filters={<RatingPollFilter {...props} />}
    sort={{ field: 'name', order: 'ASC' }}
    filterDefaultValues={getChainId() ? { chainId: getChainId() } : {}}
  >
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      {
        Permission({
          permission: PERMISSIONS.ADMIN,
          children: (
            <ReferenceField source="chainId" reference="chains" label="Rede">
              <TextField source="name" />
            </ReferenceField>
          )
        })
      }
      <TextField source="name" label="Nome" />
      <SelectField source="type" label="Tipo" choices={RatingPollItemType.ratingPollItemTypeChoices} />
      <ArrayField source="ratings" label="Nota">
        <RatingField />
      </ArrayField>
    </Datagrid>
  </List>
);
