import { Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import React from "react";
import { Datagrid, DateField, FunctionField, NumberField, ReferenceField, TextField, useListContext } from "react-admin";
import { FillinOrigin } from "../../models";
import { PERMISSIONS } from "../../constants";
import Permission from "../Permission";

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
}));

const FillinsDatagrids = ({ screenType = '' }) => {
  const classes = useStyles();
  const infos = useListContext();

  let data: Array<{ id: string, [key: string]: string | number }> = [];
  let summaryData: Array<{ id: string, [key: string]: string | number }> = [];

  if (infos.data) {
    const fillinInfo = infos.data[0];

    if (fillinInfo) {
      data = fillinInfo.data;
      const keys = ['total_value', 'total_amount', 'total_fillins', 'total_discounts'];
      let total = { id: 'total' };

      keys.forEach(key => {
        total[key] = fillinInfo.fillinsByFuel.reduce((acc, value) => {
          return acc + Number(value[key]);
        }, 0);
      });

      if (total['total_fillins']) {
        total['average_amount'] = total['total_amount'] / total['total_fillins'];
        total['average_ticket'] = total['total_value'] / total['total_fillins'];
      }

      summaryData = [total, ...fillinInfo.fillinsByFuel.sort((a, b) => (b.total_amount || 0) - (a.total_amount || 0))]
    }
  }

  return (
    <>
      <Typography className={classes.title}>Totais</Typography>
      <Datagrid
        bulkActionButtons={false}
        style={{ marginBottom: 30 }}
        data={summaryData}
        rowClick={false}
      >
        <TextField
          source="name"
          label="Combustível"
          emptyText="Todos"
          textAlign="center"
          sortable={false}
        />
        <NumberField
          source="total_value"
          label="Total em Dinheiro"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
        <NumberField
          source="total_amount"
          label="Total em Litros"
          textAlign="center"
          emptyText="0"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
          sortable={false}
        />
        <TextField source="total_fillins" textAlign="center" label="Total de abastecimentos" sortable={false} />
        <NumberField
          source="total_discounts"
          label="Total de descontos"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
        <NumberField
          source="average_ticket"
          label="Ticket Médio"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
        <NumberField
          source="average_amount"
          label="Volume Médio"
          textAlign="center"
          emptyText="0"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
          sortable={false}
        />
      </Datagrid>
      <Typography className={classes.title}>Abastecimentos</Typography>
      <Datagrid
        bulkActionButtons={false}
        resource={infos.resource}
        data={data}
        rowClick="show"
      >
        <NumberField source="amount" label="Quantidade em litros" textAlign="center" />
        <NumberField source="value" label="Valor pago" textAlign="center" options={{ style: 'currency', currency: 'BRL' }} />
        <ReferenceField
          label="Combustível"
          reference="fuels"
          source="fuelId"
          textAlign="center"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Posto"
          source="placeId"
          reference="stations"
          textAlign="center"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" label="Data" textAlign="center" showTime />
        <FunctionField label="Desconto" textAlign="center" render={record => record && `${(record.oldPricePerLiter && record.pricePerLiter ? (record.oldPricePerLiter - record.pricePerLiter) * (record.amount || 0) : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`} />
        <ReferenceField source="discountId" reference="discounts" label="Código do desconto" textAlign="center" emptyText="--">
          <TextField source="identify" />
        </ReferenceField>
        <FunctionField
          source="origin"
          label="Origem"
          render={record => FillinOrigin.translateOrigin(record ? record.origin : null)}
        />
        {Permission({
          permission: PERMISSIONS.LIST_ATTENDANT,
          children: (
            <ReferenceField
              label="Frentista"
              source="attendantId"
              reference="attendants"
              textAlign="center"
              emptyText="Não identificado"
              link={false}
            >
              <TextField source="name" />
            </ReferenceField>
          )
        })}
      </Datagrid>
    </>
  );
};

export default FillinsDatagrids;