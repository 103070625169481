import React from 'react';
import ProductInventory from './ProductInventory';
import InventoryAdjustment from './InventoryAdjustment'
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';

export { default as ProductTransfer } from './ProductTransfer';
export const ProductEntry = () => (
    <Permission permission={PERMISSIONS.PRODUCT_ENTRY}>
        <ProductInventory actionType="entry" />
    </Permission>
)
export const ProductWriteOff = () => (
    <Permission permission={PERMISSIONS.PRODUCT_WRITEOFF}>
        <ProductInventory actionType="writeOff" />
    </Permission>
)
export const ProductAdjustment = () => (
    <Permission permission={PERMISSIONS.STOCK_ADJUSTMENT}>
        <InventoryAdjustment />
    </Permission>
)
