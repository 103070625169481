import React from 'react'
import { List, Datagrid, TextField, Filter, SearchInput, SelectInput, BooleanField } from 'react-admin'
import Permission from '../../components/Permission'
import { PERMISSIONS } from '../../constants'
import ListPagination from '../../components/ListPagination'
import RequiredChain from '../../components/RequiredChain'

const Filters = props => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn />
    <SelectInput
      label="Status"
      source="isDisabled"
      choices={[{ label: 'Todos', value: 'all' }, { label: 'Ativos', value: 'activated' }, { label: 'Inativos', value: 'disabled' }]}
      optionText="label"
      optionValue="value"
      alwaysOn
    />
  </Filter>
)

const SaleProductsList = props =>
  <Permission permission={PERMISSIONS.CREATE_PRODUCT}>
    <RequiredChain>
      <List
        {...props}
        perPage={25}
        pagination={<ListPagination />}
        exporter={false}
        bulkActionButtons={false}
        title="Produtos de Venda"
        filters={<Filters />}
        filterDefaultValues={{ isDisabled: 'all' }}
      >
        <Datagrid rowClick="edit">
          <TextField source="code" label="Código" />
          <TextField source="name" label="Nome" />
          <BooleanField source="isDisabled" label="Desativado" textAlign="center" style={{ flexDirection: "column" }} />
        </Datagrid>
      </List>
    </RequiredChain>
  </Permission>

export default SaleProductsList;