import React, { useCallback, useEffect, useState } from 'react';
import { Create, useDataProvider, useGetOne, useNotify, useRedirect, useResourceContext } from 'react-admin';
import Permission from '../../components/Permission';
import WizardForm from '../../components/WizardForm';
import { PERMISSIONS } from '../../constants';

import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { getChainId } from '../../lib';
import FormSchedule from './wizardForms/FormSchedule';
import FormSummary from './wizardForms/FormSummary';

import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { uniq } from 'lodash';
import { Gift, GiftExpirationType } from '../../models';
import FormCondition from './wizardForms/FormCondition';
import FormCustomer from './wizardForms/FormCustomer';
import FormNotification from './wizardForms/FormNotification';
import FormPlaceAndLimit from './wizardForms/FormPlaceAndLimit';
import FormProduct from './wizardForms/FormProduct';
import { CheckChainUsesModule, ModuleKey } from '../../components';

const GiftCreate: React.FC = () => {
    const { state: params }: { state: Object } = useLocation();
    const [chainId] = useState(getChainId());
    const [productInfo, setProductInfo] = useState(null);
    const resource = useResourceContext();
    if (!chainId) { return <Typography>Selecione uma Rede</Typography>; }

    const { data: chain } = useGetOne('chains', { id: chainId });

    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    const [isLoading, setIsloading] = useState<boolean>(false);
    const [fuels, setFuels] = useState<{ [k: string]: any }[]>([]);
    const [completedSteps, setCompletedSteps] = React.useState<{
        [k: number]: boolean;
    }>({});

    useEffect(() => {
        //@ts-ignore
        dataProvider.getList('fuels', { sort: { field: 'name', order: 'ASC' }, pagination: { page: 1, perPage: 9999 } })
            .then(({ data }) => {
                setFuels(data as unknown as { [k: string]: any; }[]);
            });
    }, [])

    const validateProduct = (formData: any) => {
        if (formData.hasProduct && !formData.productId) {
            return { error: true, message: 'O campo de produto está vazio' };
        }
        if (!formData.hasProduct && !formData.productName) {
            return { error: true, message: 'O campo de nome do produto está vazio' };
        }
        if (!formData.hasProduct && !formData.productDescription) {
            return { error: true, message: 'O campo de descrição do produto está vazio.' };
        }
        if (!formData.hasProduct && !formData.productValue) {
            return { error: true, message: 'O campo de preço do produto está vazio' };
        }

        return { error: false };
    }

    const handleProduct = (formData: any, nextStep: (index?: number) => void) => {
        nextStep();
    }

    const validatePlaceAndLimit = (formData: any) => {
        if (!formData.placeIds || !formData.placeIds.length) {
            return { error: true, message: 'Selecione um ou mais locais de troca' };
        }
        if (typeof formData.limitQuantity === 'number' && formData.limitQuantity < 1) {
            return { error: true, message: 'O campo de limite de resgate deve ser maior que 1' };
        }
        if (formData.expirationType === GiftExpirationType.fixed && !formData.expirationTime) {
            return { error: true, message: 'O campo de data de expiração está vazio' };
        }
        if (formData.expirationType === GiftExpirationType.time && !formData.expirationInHours) {
            return { error: true, message: 'O campo de intervalo expiração está vazio' };
        }
        return { error: false };
    }

    const handlePlaceAndLimit = (formData: any, nextStep: (index?: number) => void) => {
        nextStep();
    }

    const validateRules = (formData: any) => {
        if (!formData.giftRules) {
            return { error: true, message: 'Regras não definidas' };
        }
        if (formData.giftRules.filter(gr => !gr.stationIds || !gr.stationIds.length).length) {
            return { error: true, message: 'Uma ou mais regras não possui postos definidos' };
        }
        const checkStartTimeAndEndTime = formData.giftRules.reduce((acc, gr) => {
            if (gr.startDate && !gr.endDate) {
                return { error: true, message: 'A data final deve ser uma data válida' };
            }
            if (!gr.startDate && gr.endDate) {
                return { error: true, message: 'A data inicial deve ser uma data válida' };
            }
            if (dayjs(gr.startDate) > dayjs(gr.endDate)) {
                return { error: true, message: 'A data final deve ser maior que a data de inicio' };
            }
            return acc;
        }, null);
        if (checkStartTimeAndEndTime) { return checkStartTimeAndEndTime; }

        return { error: false };
    }

    const handleRules = (formData: any, nextStep: (index?: number) => void) => {
        nextStep();
    }

    const validateSchedule = (formData: any) => {
        if (formData.isSchedule) {
            if (!formData.startAt || !formData.endAt) {
                return { error: true, message: 'Defina uma data de início e fim' };
            } else if (!moment(formData.startAt).isValid() || !moment(formData.endAt).isValid()) {
                return { error: true, message: 'Defina uma data válida' };
            } else if (moment(formData.endAt) <= moment(formData.startAt)) {
                return { error: true, message: 'Data final não pode ser inferior a data inicial' };
            }

            if (!formData.frequency) {
                return { error: true, message: 'Defina o tipo de frequência' };
            }

            if (formData.frequency === 'daily' && (!formData.startTime || !formData.endTime)) {
                return { error: true, message: 'Defina um horário de início e fim' };
            } else if (formData.frequency === 'daily' && (formData.startTime && formData.endTime) && moment(formData.endTime) <= moment(formData.startTime)) {
                return { error: true, message: 'Horário de fim não pode ser inferior ao horário de início' };
            }

            if (formData.frequency === 'weekly' && (!formData.weekSchedual || formData.weekSchedual.filter(ws => ws.isActive && (!ws.startTime || !ws.endTime)).length)) {
                return { error: true, message: 'Defina um horário de início e fim' };
            } else if (formData.frequency === 'weekly' && formData.weekSchedual && formData.weekSchedual.filter(ws => ws.isActive && moment(ws.endTime) <= moment(ws.startTime)).length) {
                return { error: true, message: 'Horário de fim não pode ser inferior ao horário de início' };
            }
        }

        return { error: false };
    }

    const handleSchedule = (formData: any, nextStep: () => void) => {
        nextStep();
    }

    const validateCustomer = (formData: any) => {
        return { error: false };
    }

    const handleCustomer = (formData: any, nextStep: () => void) => {
        nextStep();
    }

    const validateNotification = (formData: any) => {
        if (formData.type === 'special' && formData.hasNotification) {
            if (!formData.notification?.title) {
                return { error: true, message: 'Defina um título para notificação' };
            }
            if (!formData.notification?.body) {
                return { error: true, message: 'Defina uma descrição para notificação' };
            }
            if (!formData.notification?.pushNotification && !formData.notification?.sms) {
                return { error: true, message: 'Defina um método de notificação' };
            }
            if (formData.notification.pushNotification && !formData.notification?.pushMessage) {
                return { error: true, message: 'Defina uma messagem push' };
            }
            if (formData.notification.sms && !formData.notification?.smsMessage) {
                return { error: true, message: 'Defina uma messagem sms' };
            }
            if (formData.notification?.sendNow !== true && !(moment(formData.notification?.sendAt) > moment(Date.now()).add(10, 'minutes'))) {
                return { error: true, message: 'Defina um horário de envio válido' };
            }
        }
        return { error: false };
    }

    const handleNotification = (formData: any, nextStep: () => void) => {
        nextStep();
    }

    const handleConcluedStep = (index) => {
        setCompletedSteps(value => ({ ...value, [index]: true }));
    }

    const handleCreate = (formData: any) => {
        const { hasProduct, productId, productName, productDescription, productValue, productImageFile, productImagePath, placeIds, rtiPlaces, expirationType, expirationTime, expirationInHours, hasNotification, notification, ...restFormData } = formData;

        setIsloading(true);

        const data: any = {
            hasProduct,
            hasNotification,
            expirationType,
            ...restFormData
        }

        if (hasProduct) {
            data.productId = productId;
        } else {
            data.productName = productName;
            data.productDescription = productDescription;
            data.productValue = productValue;
            data.productImageFile = productImageFile;
        }

        data.placeGifts = placeIds.map(placeId => ({ placeId, rtiCode: (rtiPlaces || []).find(rtiPlace => rtiPlace.placeId === placeId)?.rtiCode }));

        if (hasNotification) {
            data.notification = notification;
        }

        if (expirationType === GiftExpirationType.fixed) {
            data.expirationTime = expirationTime instanceof Date ? expirationTime.toISOString() : expirationTime;
        } else if (expirationType === GiftExpirationType.time) {
            data.expirationInHours = expirationInHours;
        }

        if (data.startAt && data.startAt instanceof Date) {
            data.startAt = data.startAt.toISOString();
        }
        if (data.endAt && data.endAt instanceof Date) {
            data.endAt = data.endAt.toISOString();
        }
        if (data.startTime && data.startTime instanceof Date) {
            data.startTime = data.startTime.toISOString();
        }
        if (data.endTime && data.endTime instanceof Date) {
            data.endTime = data.endTime.toISOString();
        }

        dataProvider.create(resource, {
            data: Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null)),
        }).then(response => {
            notify('brinde criado com sucesso');
            redirect(`/${resource}`);
        }).catch(error => {
            notify(error.message || 'Erro ao criar brinde', { type: 'warning' });
        }).finally(() => {
            setIsloading(false);
        });
    }

    const getInitialValues = useCallback(() => {
        const { placeGifts, dayScheduledGifts: paramsDayScheduledGifts, _scrollToTop, ...restParams } = params as { [k: string]: any } || {};

        const placeIds: string[] = uniq(placeGifts?.map(fd => fd.placeId));

        const dayScheduledGifts = Array(7).fill({}).map((_, index) => (
            paramsDayScheduledGifts?.map(({ giftId, ...dayS }) => ({ ...dayS, isActive: true })).find(dayS => dayS.dayOfTheWeek === index) || { dayOfTheWeek: index, startTime: null, endTime: null, isActive: false })
        );

        const { dayScheduledGifts: giftDayScheduledGifts, ...rest } = Gift.createGift({ chainId, placeIds, dayScheduledGifts, placeGifts, ...restParams });

        return { ...rest, weekSchedual: giftDayScheduledGifts, chainId };
    }, [fuels, chainId]);

    return (
        <CheckChainUsesModule module={ModuleKey.GIFT}>
            <Permission permission={PERMISSIONS.GIFT}>
                <Create title='Cadastrar Brinde'>
                    <WizardForm
                        sanitizeEmptyValues
                        isLoading={isLoading}
                        completedSteps={completedSteps}
                        defaultValues={getInitialValues()}
                        handleConcluedStep={handleConcluedStep}
                        formSteps={[
                            {
                                label: "Produto",
                                component: <FormProduct setProductInfo={setProductInfo} />,
                                validate: validateProduct,
                                onNextFunction: handleProduct,
                            },
                            {
                                label: "Regras",
                                component: <FormCondition fuels={fuels} />,
                                validate: validateRules,
                                onNextFunction: handleRules,
                            },
                            {
                                label: "Agendamento",
                                component: <FormSchedule />,
                                validate: validateSchedule,
                                onNextFunction: handleSchedule,
                            },
                            {
                                label: "Clientes",
                                component: <FormCustomer />,
                                validate: validateCustomer,
                                onNextFunction: handleCustomer,
                            },
                            {
                                label: "Notificação",
                                component: <FormNotification />,
                                validate: validateNotification,
                                onNextFunction: handleNotification,
                            },
                            {
                                label: "Locais de troca",
                                component: <FormPlaceAndLimit chain={chain} productInfo={productInfo} />,
                                validate: validatePlaceAndLimit,
                                onNextFunction: handlePlaceAndLimit,
                            },
                            {
                                label: "Confirmação",
                                component: <FormSummary chain={chain} fuels={fuels} />,
                                onNextFunction: handleCreate,
                                validate: (formData) => {
                                    for (const validate of [validateProduct, validateRules, validateSchedule, validateCustomer, validateNotification, validatePlaceAndLimit]) {
                                        const result = validate(formData);
                                        if (result.error === true) {
                                            return result;
                                        }
                                    }
                                    return { error: false };
                                },
                                disableStep: (data, completedSteps) => {
                                    return [validateProduct, validatePlaceAndLimit, validateRules].map(validate => validate(data)).some((item) => item.error);
                                }
                            },
                        ]}
                    />
                </Create>
            </Permission>
        </CheckChainUsesModule>
    );
};

export default GiftCreate;