export enum FinancePaymentMethodType {
  pix = 'pix',
  boleto = 'boleto',
  deposit = 'deposit',
};

export namespace FinancePaymentMethodType {
  export const translateType = (type: FinancePaymentMethodType): string => {
    switch (type) {
      case FinancePaymentMethodType.pix:
        return "Pix"
      case FinancePaymentMethodType.boleto:
        return "Boleto";
      case FinancePaymentMethodType.deposit:
        return "Depósito";
      default: return "";
    };
  };

  export const financePaymentMethodChoices = Object.values(FinancePaymentMethodType).filter(x => typeof x === "string")
    .map(type => {
      return ({
        id: type,
        name: FinancePaymentMethodType.translateType(type)
      })
    });
};
