import { ArrayField, ChipField, CreateButton, Datagrid, DateField, Filter, FormDataConsumer, FunctionField, List, ReferenceArrayInput, ReferenceField, ReferenceInput, SelectArrayInput, SelectInput, TextField, useListContext } from 'react-admin';
import Permission from '../../components/Permission';
import { PERMISSIONS, frequencyChoices } from '../../constants';
import { getChainId } from '../../lib';
import CloneDiscountButton from './CloneDiscountButton';


const StationField = () => {
    const { data } = useListContext();
    const values = Object.values(data);

    return (
        <>
            {values.slice(0, 4).map(station => (
                <ReferenceField source="placeId" reference="stations" record={station} >
                    <ChipField source="name" />
                </ReferenceField>
            )
            )}
            <>{values.length > 4 ? <> mais {values.length - 4} posto{values.length - 4 > 1 ? 's' : ''}</> : ''}</>
        </>);
};

const GroupField = (props: {}) => {
    const { data } = useListContext();
    const values = Object.values(data);

    return (
        <>
            {values.slice(0, 4).map((key, index) => (
                <ReferenceField source="groupCustomerId" reference="chains/chainId/customer-groups" record={{ id: index, groupCustomerId: key }}>
                    <ChipField source="name" />
                </ReferenceField>)
            )}
            <>{values.length > 4 ? <> mais {values.length - 4} grupo{values.length - 4 > 1 ? 's' : ''}</> : ''}</>
        </>
    );
};

const FuelList = (props) => {

    const chainId = getChainId();

    const ListFilters = () => (
        <Filter>
            <SelectInput
                label="Status"
                source="isEnabled"
                choices={[{ label: 'Ativos', value: true }, { label: 'Inativos', value: false }]}
                optionText="label"
                optionValue="value"
                emptyText="Todos"
            />
            {
                Permission({
                    permission: PERMISSIONS.ADMIN, children:
                        <ReferenceInput
                            reference="chains"
                            source="chainId"
                            style={{ minWidth: 220 }}
                            defaultValue={getChainId()}
                            sort={{ field: 'name', order: 'ASC' }}
                            perPage={9999}
                        >
                            <SelectInput label="Rede" optionText="name" />
                        </ReferenceInput>
                })
            }
            <FormDataConsumer alwaysOn>
                {({ formData }) => (
                    <ReferenceArrayInput
                        reference="stations"
                        source="placeIds"
                        sort={{ field: 'name', order: 'ASC' }}
                        perPage={9999}
                        style={{ minWidth: 220, marginBottom: -16 }}
                        filter={formData.chainId ? { chainId: formData.chainId } : {}}
                    >
                        <SelectArrayInput label="Postos" optionText="name" />
                    </ReferenceArrayInput>
                )}
            </FormDataConsumer>
            <ReferenceArrayInput
                reference="fuels"
                source="fuelIds"
                style={{ minWidth: 220 }}
                sort={{ field: "name", order: "ASC" }}
                alwaysOn
            >
                <SelectArrayInput label="Combustíveis" optionText="name" />
            </ReferenceArrayInput>
            <SelectInput
                label="Tipo de desconto"
                source="type"
                choices={[{ id: "default", name: "Padrão" }, { id: "special", name: "Especial" }]}
                style={{ minWidth: 220 }}
                emptyText="Todos"
                alwaysOn
            />

        </Filter>
    );

    const filterDefaultValues: any = { isEnabled: true };
    if (chainId) {
        filterDefaultValues.chainId = chainId;
    }

    return (
        <Permission permission={PERMISSIONS.FUEL_DISCOUNTS}>
            <List
                {...props}
                title="Descontos"
                sort={{ field: 'createdAt', order: 'DESC' }}
                filterDefaultValues={filterDefaultValues}
                filters={<ListFilters />}
                exporter={false}
                actions={<CreateButton />}
            >
                <Datagrid bulkActionButtons={false} rowClick="show">
                    <TextField source="identify" label="Código" />
                    <FunctionField label="Tipo" render={record => record && record.isDefault ? 'Padrão' : 'Especial'} textAlign="center" />
                    <DateField source="startAt" label="Data de início" showTime textAlign="center" emptyText="--" />
                    <DateField source="endAt" label="Data de fim" showTime textAlign="center" emptyText="--" />
                    <FunctionField
                        render={record => record && record.frequency ? frequencyChoices.find(dt => dt.id === record.frequency)?.name : '--'}
                        label="Frequência"
                        textAlign="center"
                    />
                    <FunctionField
                        render={record => record && record.isEnabled ? "Ativo" : "Inativo"}
                        label="Status"
                        textAlign="center"
                    />
                    <DateField source="disabledAt" label="Desativado em" showTime textAlign="center" emptyText="--" />
                    <TextField source="description" label="Descrição" emptyText="--" textAlign="center" />
                    <ArrayField label="Grupos" source="customerGroupIds" sortable={false}>
                        <GroupField />
                    </ArrayField>
                    <ArrayField label="Postos" source="placeDiscounts" sortable={false}>
                        <StationField />
                    </ArrayField>
                    <CloneDiscountButton />
                </Datagrid>
            </List>
        </Permission>
    );
};

export default FuelList;
