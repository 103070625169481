import {
  DefaultEditorOptions,
  RichTextInput,
  RichTextInputToolbar,
  LevelSelect,
  FormatButtons,
  AlignmentButtons,
  ListButtons,
  LinkButtons,
  QuoteButtons,
  ClearButtons,
  useTiptapEditor,
  ColorButtons,
  RichTextInputProps,
} from 'ra-input-rich-text';
import ImageIcon from '@mui/icons-material/Image';
import { useTranslate } from 'ra-core';
import { ToggleButton, ToggleButtonProps } from '@mui/material';
import React, { useRef } from 'react';
import Image from '@tiptap/extension-image';

const ImageButtons = (props: Omit<ToggleButtonProps, 'value'>) => {
  const translate = useTranslate();
  const editor = useTiptapEditor();
  const inputRef = useRef<any>(null);

  const label = translate('ra.tiptap.image', { _: 'Image' });

  const handleClick = React.useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, [editor, translate]);

  const fileChange = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        editor.chain().focus().setImage({ src: base64String as string }).run();
      };

      reader.readAsDataURL(file);
    }
  }

  return (
    <>
      <input
        type="file"
        accept="image/*"
        onChange={fileChange}
        style={{ display: 'none' }}
        ref={inputRef}
      />
      <ToggleButton
        aria-label={label}
        title={label}
        {...props}
        disabled={!editor || !editor.isEditable}
        value="image"
        onClick={handleClick}
      >
        <ImageIcon fontSize="inherit" />
      </ToggleButton>
    </>
  );
};

export const RichTextInputCustom: React.FC<RichTextInputProps> = ({ size, ...props }) => (
  <RichTextInput
    editorOptions={RichTextInputOptions}
    toolbar={
      <RichTextInputToolbar>
        <LevelSelect size={size} />
        <FormatButtons size={size} />
        <ColorButtons size={size} />
        <AlignmentButtons size={size} />
        <ListButtons size={size} />
        <LinkButtons size={size} />
        <QuoteButtons size={size} />
        <ClearButtons size={size} />
        <ImageButtons size={size} />
      </RichTextInputToolbar>
    }
    {...props}
  />
);

export const RichTextInputOptions = {
  ...DefaultEditorOptions,
  extensions: [
    ...(DefaultEditorOptions.extensions || []),
    Image.configure({
      inline: false,
      allowBase64: true,
    })
  ],
};