import Store from '@mui/icons-material/Store'
import { PERMISSIONS } from '../../constants'
import list from './PartnerList'
import create from './CreatePartner'
import edit from './EditPartner'

export default {
  list,
  create,
  edit,
  name: 'chains/chainId/partners',
  permissions: [PERMISSIONS.CREATE_PARTNER],
  options: { label: 'Parceiros' },
  icon: Store,
}
