import React, { useEffect, useState } from 'react';
import { useDataProvider, usePermissions } from 'react-admin';
import { Area, AreaChart, XAxis, YAxis, Tooltip } from 'recharts';
import { formatNumber, getInitialChart } from '../../utils';
import { PERMISSIONS } from '../../constants';
import { getChainId } from '../../lib';
import useDashboardContext from '../../hooks/useDashboardContext';
import { DashboardWrapper, RotatedAxisTick } from '../../components';
import moment from 'moment';

const color = "#03c8ff";

const sortData = (a, b) => {
    return new Date(a.day) - new Date(b.day);
}

export const NewCustomersChart = () => {
    const dataProvider = useDataProvider();
    const { permissions } = usePermissions();
    const { from, to, chain } = useDashboardContext();

    const initialChart = getInitialChart(from, to, { id: 0 });
    const [chartData, setChartData] = useState(initialChart);

    const chainId = permissions && permissions.includes(PERMISSIONS.ADMIN) ? chain : getChainId();

    useEffect(() => {
        dataProvider.getList('dashboard/new-customers', {
            filter: { from, to, },
            chainId,
        }).then(({ data }) => {
            data.sort(sortData);
            data.forEach((element) => {
                element.day = moment(element.day).format('DD/MM/YYYY');
                element.id = Number(element.id);
            });
            setChartData(data);
        });
    }, [from, to, chain]);

    return (
        <DashboardWrapper
            title="Gráfico de Novos Clientes"
            permission={PERMISSIONS.NEW_CUSTOMERS_CHART}
            aspectRatio={6.0 / 2.5}
            content={
                <AreaChart data={chartData}>
                    <YAxis type="number" domain={[0, 'auto']} />
                    <XAxis dataKey="day" tick={<RotatedAxisTick fontSize={12} />} height={60} />
                    <Tooltip formatter={(value) => `${formatNumber(value)} Clientes`} />
                    <defs>
                        <linearGradient id={color} x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={color} stopOpacity={0.75} />
                            <stop offset="95%" stopColor={color} stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <Area
                        stroke={color}
                        fillOpacity={1}
                        fill={`url(#${color})`}
                        dataKey={"id"}
                        name='Total'
                        type="monotone"
                    />
                </AreaChart>
            }
        />
    );
}