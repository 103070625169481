import DownloadIcon from '@mui/icons-material/GetApp';
import { Box, LinearProgress, MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import jsonExport from 'jsonexport/dist';
import React, { useEffect, useState } from 'react';
import { downloadCSV, useListContext, useNotify, useTheme } from 'react-admin';
import { exportToXLSX } from '../../utils';
import ExportButton from './ExportButton';
import { theme } from '../../theme';
import { IDS_MAX_LENGTH } from '../../store/providers/getMany';
import { MAX_IDS_PER_REQUEST } from '../../constants';

const useStyles = makeStyles(() => {
  return ({
    menuItem: {
      alignItems: 'center',
      // color: theme.palette.primary.main,
      color: theme.palette.primary.main,
      // color: 'primary',
      display: 'flex',
      fontSize: '0.8125rem',
      fontWeight: 500,
      gap: 4,
    },
    menuOption: {
      padding: 0,
      '& button': {
        width: '100%',
      },
    },
  })
});

type RelationType = {
  field: string,
  resource: string,
};

type DataType = Array<{ [key: string]: string }>;

type FRType = (
  data: DataType,
  field: string,
  resource: string
) => Promise<DataType>;

type ProgressType = {
  setProgress: React.Dispatch<React.SetStateAction<number>>,
  sizeRelation: number,
}

const filterUniques = (data: DataType, field: string) => {
  const values = Array.from(
    new Set(
      data.filter(record => record[field]).map(record => record[field])
    )
  );

  return values.map(value => ({ [field]: value }));
}

const bulkFetchRelatedRecords = async (data: DataType, relation: RelationType, fetcher: FRType, progress: ProgressType) => {
  const arrLength = Math.ceil(data.length / MAX_IDS_PER_REQUEST);
  const { field, resource } = relation;
  const { sizeRelation, setProgress } = progress;
  const filteredData = filterUniques(data, field);
  const results: Array<{ [key: string]: string }> = []
  const QUEUE_SIZE = 5;
  const queue: Array<{ field: string, start: number, end: number, resource: string }> = []

  const percen = sizeRelation / arrLength
  const processQueue = async (queue) => {
    const processResults: Array<{ [key: string]: string; }> = [];

    while (queue.length > 0) {
      const dataFetchs: Promise<Array<{ [key: string]: string }>>[] = [];

      for (let i = 0; i < QUEUE_SIZE && queue.length > 0; i++) {
        const { start, end, field, resource } = queue.shift();
        dataFetchs.push(fetcher(filteredData.slice(start, end), field, resource));
      }

      const fetchResults = (await Promise.all(dataFetchs)).flatMap(item => Object.values(item));
      processResults.push(...fetchResults);
    }

    return processResults;
  }

  for (let i = 0; i < arrLength; i++) {
    const start = i * MAX_IDS_PER_REQUEST; //   0, 150, 300, ...
    const end = (i + 1) * MAX_IDS_PER_REQUEST; // 150, 300, ... slice é exclusivo
    queue.push({ start, end, field, resource });

    const shouldProcessQueue = (i + 1) % QUEUE_SIZE === 0 || i === arrLength - 1;
    if (shouldProcessQueue) {
      const partialResults = await processQueue(queue);
      results.push(...partialResults);
      queue.length = 0;
    }
    setProgress((prev: number) => prev + percen);
  }

  return { ...relation, results };
};

const CustomExportButton = ({ fileName, preExport, isReport = true }: {
  fileName?: string,
  isReport?: boolean,
  preExport?: (
    data: DataType,
    preExporter: (relations: any) => Promise<any[]>,
  ) => Promise<DataType>,
}) => {
  const [exportType, setExportType] = useState<string | null>(null);
  const [exportData, setExportData] = useState<DataType | null>(null);
  const [progress, setProgress] = useState<number>(0);

  const { resource } = useListContext();
  const classes = useStyles();
  const notify = useNotify();

  const renderOption = (value) => (
    <span className={classes.menuItem}>
      <DownloadIcon /> {`EXPORTAR ${value || ''}`}
    </span>
  );

  const exporter = async (data: DataType, defaultFetcher: FRType) => {

    const fetchRelatedRecords = async (relations: Array<RelationType>, customData?: DataType) => {
      try {
        const dataFetchs: Promise<RelationType & { results: DataType }>[] = [];
        const sizeRelation = Math.ceil(100 / relations.length);

        relations.forEach(relation => {
          dataFetchs.push(bulkFetchRelatedRecords(customData || data, relation, defaultFetcher, { sizeRelation, setProgress }));
        });

        const bulkRelatedRecords = await Promise.all(dataFetchs);

        return bulkRelatedRecords;
      } catch (error) {
        setExportType(null);
        setExportData(null);
        setProgress(0);
        notify('Erro ao exportar dados!', { type: 'warning' });
        return [];
      }
    };

    if (preExport) {
      data = await preExport(data, fetchRelatedRecords);
    }

    setExportData(data);
  }

  useEffect(() => {
    if (exportData && exportType) {
      if (exportType === 'CSV') {
        jsonExport(exportData, (_err, csv) => downloadCSV(csv, fileName || resource));
      }
      if (exportType === 'XLSX') {
        exportToXLSX(exportData, fileName || resource);
      }

      setExportType(null);
      setExportData(null);
      setProgress(0);
    }
  }, [exportData, exportType]);

  return (
    <Box>
      <Select
        value={exportType}
        onChange={({ target }) => setExportType(target.value as string)}
        style={{ marginLeft: 16 }}
        renderValue={renderOption}
        disabled={!!exportType}
        sx={{ maxHeight: 32 }}
        displayEmpty
      >
        <MenuItem value="CSV" className={classes.menuOption}>
          <ExportButton resource={resource} label="CSV" maxResults={50000} setProgress={setProgress} exporter={exporter} isReport={isReport} />
        </MenuItem>
        <MenuItem value="XLSX" className={classes.menuOption}>
          <ExportButton resource={resource} label="XLSX" maxResults={50000} setProgress={setProgress} exporter={exporter} isReport={isReport} />
        </MenuItem>
      </Select>
      {
        progress ?
          <LinearProgress sx={{ marginLeft: 2, minWidth: 150 }} variant={'determinate'} value={progress} />
          : <></>
      }
    </Box>
  );
}

export default CustomExportButton;
