import React from "react";
import { Datagrid, Filter, SearchInput, TextField } from 'react-admin';
import { Permission, ReportList } from "../../components";
import ListPagination from '../../components/ListPagination';
import { PERMISSIONS } from "../../constants";

const ListExternalPartners = () => (
  <Datagrid bulkActionButtons={false}>
    <TextField source="name" label="Nome" />
  </Datagrid>
);

const ListFilters = props => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn />
  </Filter>
);

const ReportExternalPartners = props => (
  <Permission permission={PERMISSIONS.ADMIN}>
    <ReportList
      {...props}
      basePath="report-external-partners"
      resource="reports/external-partners"
      title="Relatório de Parceiros Externos"
      filters={<ListFilters />}
      perPage={25}
      pagination={<ListPagination />}
      titleOnPrint="Relatório de Parceiros Externos"
    >
      <ListExternalPartners />
    </ReportList>
  </Permission>
);

export default ReportExternalPartners;
