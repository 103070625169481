import React, { Fragment, useState } from 'react';
import moment from 'moment';
import {
  TextInput,
  SimpleForm,
  Toolbar,
  SaveButton,
  required,
  useNotify,
  regex,
} from 'react-admin';

import Button from '@mui/material/Button';
import styles from '../validateCoupon/CouponSearch.module.scss';
import { formatCpf } from '../../lib';
import { useDataProvider } from 'react-admin';
import { formatPoints } from '../../utils';

const EMPTY_COUPON = {
  id: '',
  createdAt: '',
  value: 0,
  refunded: false,
  redeemed: false,
  product: {
    id: '',
    name: '',
    description: '',
    imagePath: '',
  },
  transaction: {
    customer: {
      name: '',
      email: '',
      cpf: '',
    },
  },
};

const validate = [required(), regex(/^(.{8}|^.{10})$/, 'O código deve ter 8 ou 10 dígitos')];

const CustomToolbar = props => {
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.invalid} label="Buscar" submitOnEnter />
    </Toolbar>
  )
};

const CouponDisallow = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [coupon, setCoupon] = useState(EMPTY_COUPON);
  const [couponBarcode, setCouponBarcode] = useState("");

  const executeSearch = async ({ id }) => {
    setCoupon(EMPTY_COUPON);

    dataProvider.getOne(`coupons`, { id })
      .then(({ data }) => {
        if (data.refunded) {
          notify("Cupom já foi estornado!", { type: 'warning' });
        } else {
          setCoupon(data)
          setCouponBarcode("");
        }
      }).catch(error => notify(error.message, { type: 'warning' }));
  };

  const unredeemCoupon = async id => {
    dataProvider.update(`coupons/${id}/unredeem-coupon`, {})
      .then(() => {
        notify('Cupom desvalidado com sucesso!');
        setCoupon(EMPTY_COUPON);
      })
      .catch((error) => notify(error.message, { type: 'warning' }));
  };

  const { customer } = coupon;

  return (
    <Fragment>
      <SimpleForm toolbar={<CustomToolbar />} save={executeSearch}>
        <TextInput
          source="id"
          resettable
          label="Identificador do cupom"
          validate={validate}
          value={couponBarcode}
          onChange={(event) => setCouponBarcode(event.target ? event.target.value : "")}
        />
      </SimpleForm>
      {Boolean(coupon.id.length) && (
        <div className={styles.container}>
          <div className={styles.productImage}>
            <img src={coupon.product.imagePath} alt="" />
          </div>
          <div className={styles.couponInfo}>
            <div>
              <span className={styles.info}>
                <b>Cupom: </b>
                <i>{coupon.id}</i>
              </span>
              <span className={styles.info}>
                <b>Data: </b>
                {moment(coupon.createdAt).format('DD/MM/YYYY')}
              </span>
            </div>
            <div>
              <span className={styles.info}>
                <b>Produto: </b>
                {coupon.product.name}
              </span>
              <span className={styles.info}>
                <b>Valor: </b>
                {`${formatPoints(coupon.value)} pontos`}
              </span>
            </div>
            <span className={styles.info}>
              <b>Descrição: </b>
              {coupon.product.description}
            </span>
            {Boolean(coupon.refunded) ?
              <span className={styles.info}>
                <b>Cupom estornado</b>
              </span> :
              Boolean(coupon.redeemed) ?
                <Button
                  className={styles.submit}
                  variant="contained"
                  color="primary"
                  onClick={() => unredeemCoupon(coupon.id)}>
                  Desvalidar
                </Button> :
                <span className={styles.info}>
                  <b>Cupom ainda não validado</b>
                </span>
            }
          </div>
          <div className={styles.customerInfo}>
            <span className={styles.info}>
              <b>Cliente que efetuou a troca: </b>
            </span>
            <span className={styles.info}>
              <b>Nome: </b>
              {`${customer.name} ${customer.email ? `(${customer.email})` : ''}`}
            </span>
            <span className={styles.info}>
              <b>CPF: </b>
              {formatCpf(customer.cpf)}
            </span>
            <span className={styles.info}>
              <b>Data de Troca: </b>
              {moment(coupon.createdAt).format('DD/MM/YYYY - HH:mm')}
            </span>
          </div>
        </div>
      )}
    </Fragment>
  )
};

export default CouponDisallow;
