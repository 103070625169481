export enum GiftExpirationType {
  fixed = 'fixed',
  time = 'time',
}

export namespace GiftExpirationType {
  export const translateType = (type: GiftExpirationType): string => {
    switch (type) {
      case GiftExpirationType.fixed:
        return "Data fixa";
      case GiftExpirationType.time:
        return "Horas";
      default:
        return "";
    }
  };

  export const giftExpirationTypeChoices = Object.values(GiftExpirationType)
    .filter(x => typeof x === "string")
    .map((type) => ({
      id: type,
      name: GiftExpirationType.translateType(type),
    }));
}