export enum PendingTransactionOriginType {
  linkedFillin = "linked-fillin",
  outOfRule = "out-of-rule",
}

export namespace PendingTransactionOriginType {
  export const translateType = (type: PendingTransactionOriginType): string => {
    switch (type) {
      case PendingTransactionOriginType.linkedFillin:
        return "Adição manual de abastecimento";
      case PendingTransactionOriginType.outOfRule:
        return "Fora da regra";
      default:
        return "";
    }
  };
}
