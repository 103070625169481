import React from 'react';
import { Create, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput, required } from 'react-admin';
import { Wrapper } from '../../components';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';

const FuelCreate = (props) => {
    return (
        <Permission permission={PERMISSIONS.ADMIN}>
            <Wrapper>
                <Create title='Adicionar combustível' {...props}>
                    <SimpleForm >
                        <TextInput source="acronym" label="Sigla" maxLength="3" validate={required()} />
                        <TextInput source="name" label="Nome" validate={required()} />
                        <TextInput source="anpCode" label="Código ANP" />
                        <NumberInput source="fuelType" label='Tipo do combustível (ConectTec)' />
                        <ReferenceInput label="Combustível pai" source="parentFuelId" reference="parent-fuels">
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                    </SimpleForm>
                </Create>
            </Wrapper>
        </Permission>
    )
};

export default FuelCreate;
