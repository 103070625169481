import PrintIcon from '@mui/icons-material/Print';
import { Button } from 'react-admin';
import ReactToPrint from 'react-to-print';

const PrintButton = (props) => (
    <ReactToPrint
        onBeforeGetContent={props.onClick}
        trigger={() => <Button variant="outlined" sx={{ height: 32, marginBottom: '3px' }} label="Imprimir" startIcon={<PrintIcon />} />}
        content={() => props.printRef.current}
    />
);

export default PrintButton;