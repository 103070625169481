import {
  BooleanInput,
  Create,
  FormTab,
  ImageField,
  ImageInput,
  NumberInput,
  TabbedForm,
  TextInput,
  minLength,
  required
} from 'react-admin';
import { ImageInformations, PriceInput, Tooltip } from '../../components';
import { CEPInput, CNPJInput, PhoneInput, RtiTokenInput, StateInput } from '../../components/Inputs';
import Permission from '../../components/Permission';
import { PERMISSIONS, minimumValueCashbackToolTip } from '../../constants';
import { Grid } from '@mui/material';

const validateCredentials = credentials =>
  credentials &&
  credentials.host &&
  credentials.port &&
  credentials.database &&
  credentials.user &&
  credentials.password

const validateName = [required(), minLength(2)]
const validateRequired = [required()]

const CreatePartner = (props) =>
  <Permission permission={PERMISSIONS.CREATE_PARTNER}>
    <Create {...props} title="Cadastrar novo parceiro">
      <TabbedForm sanitizeEmptyValues>
        <FormTab label="Geral">
          <Grid container lg={8} md={12} spacing={2}>
            <Grid item xs={12} md={12}>
              <TextInput source="name" label="Nome" validate={validateName} InputProps={{ autoComplete: 'off' }} fullWidth />
            </Grid>
          </Grid>
          <Grid container lg={8} md={12} spacing={2}>
            <Grid item xs={12} md={4}>
              <CNPJInput source="cnpj" label="CNPJ" validate={validateRequired} fullWidth />
            </Grid>
            <Grid item xs={12} md={4}>
              <PhoneInput
                InputProps={{ autoComplete: 'off' }}
                source="phone"
                label="Telefone"
                isRequired
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInput source="contact" label="Contato" InputProps={{ autoComplete: 'off' }} fullWidth />
            </Grid>
          </Grid>
          <ImageInput source="imagefile" label="Imagem">
            <ImageField source="src" />
          </ImageInput>
          <ImageInformations />
          <Grid container lg={8} md={12} spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <BooleanInput label="Aceita cashback?" source="acceptsCashback" defaultValue={false} />
            </Grid>
            <Grid item xs={12} md={4} alignSelf="center">
              <Tooltip title={minimumValueCashbackToolTip} placement="top-start" sxContainer={{ display: "-webkit-box" }}>
                <PriceInput source="minimumValueToRedeemCashback" label="Mínimo para resgate de cashback" sx={{ minWidth: 220 }} />
              </Tooltip>
            </Grid>
          </Grid>
          <BooleanInput label="Possui comanda eletrônica?" source="hasOrderSheet" defaultValue={false} />
          <Permission permission={PERMISSIONS.ADMIN}>
            <RtiTokenInput
              label="Token RTI"
              source="rtiToken"
              fullWidth
            />
          </Permission>
        </FormTab>
        <FormTab label="Endereço">
          <Grid container lg={8} md={12} spacing={2}>
            <Grid item xs={12} md={8}>
              <TextInput source="street" label="Rua" validate={validateRequired} fullWidth />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInput
                source="streetNumber"
                label="Número"
                validate={validateRequired}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container lg={8} md={12} spacing={2}>
            <Grid item xs={12} md={3}>
              <CEPInput source="cep" label="CEP" validate={validateRequired} fullWidth />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextInput
                source="neighbourhood"
                label="Bairro"
                validate={validateRequired}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextInput source="city" label="Cidade" validate={validateRequired} fullWidth />
            </Grid>
            <Grid item xs={12} md={3}>
              <StateInput source="state" label="Estado" validate={validateRequired} fullWidth />
            </Grid>
          </Grid>
          <Grid container lg={8} md={12} spacing={2}>
            <Grid item xs={12} md={3}>
              <NumberInput source="latitude" label="Latitude" validate={validateRequired} fullWidth />
            </Grid>
            <Grid item xs={12} md={3}>
              <NumberInput source="longitude" label="Longitude" validate={validateRequired} fullWidth />
            </Grid>
          </Grid>
        </FormTab>
        <Permission permission={PERMISSIONS.ADMIN}>
          <FormTab label="Credenciais">
            <TextInput source="credentials.host" label="Host ou ip" InputProps={{ autoComplete: 'off' }} />
            <NumberInput source="credentials.port" label="Porta" InputProps={{ autoComplete: 'off' }} />
            <TextInput source="credentials.user" label="Usuário" InputProps={{ autoComplete: 'off' }} />
            <TextInput source="credentials.password" label="Senha" type="password" InputProps={{ autoComplete: 'off' }} />
            <TextInput source="credentials.database" label="Banco" InputProps={{ autoComplete: 'off' }} />
          </FormTab>
        </Permission>
      </TabbedForm>
    </Create>
  </Permission>

export default CreatePartner;