import RecoveryIcon from '@mui/icons-material/Restore';
import {
  Button,
  CreateButton,
  Datagrid,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextField,
  TopToolbar,
  useRedirect,
} from 'react-admin';
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { EmployeeType } from '../../models';

const ListActions = props => {
  const redirect = useRedirect();
  return (
    <TopToolbar>
      <CreateButton {...props} />
      <Button
        label="Recuperar"
        onClick={() => redirect('/attendant-employees/recovery')}
        startIcon={<RecoveryIcon />}
        alignIcon="right"
        style={{ alignSelf: 'end', marginLeft: 16 }}
      />
    </TopToolbar>
  );
};

const UserFilter = props => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn />
    {props.permissions && props.permissions.includes(PERMISSIONS.ADMIN) &&
      <ReferenceInput
        label="Rede"
        source="chainId"
        reference="chains"
        perPage={9999}
        sort={{ field: 'name', order: 'ASC' }}
        alwaysOn
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    }
  </Filter>
);

export default props => (
  <List
    {...props}
    resource="employees"
    filter={{ type: EmployeeType.attendant }}
    perPage={25}
    pagination={<ListPagination />}
    actions={<ListActions />}
    exporter={false}
    title="Usuários frentistas"
    hasCreate
    filters={<UserFilter {...props} />}
  >
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <Permission permission={PERMISSIONS.ADMIN}>
        <ReferenceField source="chainId" reference="chains" label="Rede">
          <TextField source="name" />
        </ReferenceField>
      </Permission>
      <TextField source="name" label="Nome" />
      <TextField source="login" label="Login" />
      <Permission permission={PERMISSIONS.ADMIN}>
        <ReferenceField
          source="profileId"
          reference="profiles"
          label="Perfil"
        >
          <TextField source="name" />
        </ReferenceField>
      </Permission>
    </Datagrid>
  </List>
);
