import { PERMISSIONS } from '../../constants'
import list from './ProfileList'
import create from './ProfileCreate'
import edit from './ProfileEdit'

export default {
  edit,
  list,
  create,
  name: 'profiles',
  permissions: [PERMISSIONS.CREATE_PROFILE],
  options: { label: 'Perfis' }
}
