import {
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextField,
  usePermissions
} from "react-admin";
import { Permission, StarRatingField } from '../../components';
import { PERMISSIONS } from '../../constants';
import { useLocation } from 'react-router-dom';


const RatingFilter = ({ ...props }) => {
  const { permissions } = usePermissions();

  return (
    <Filter {...props}>
      <SearchInput source="search" alwaysOn />
      {permissions && permissions.includes(PERMISSIONS.ADMIN) &&
        <ReferenceInput source="chainId" reference="chains" sort={{ field: 'name', order: 'ASC' }} perPage={null} alwaysOn>
          <SelectInput resettable label="Rede" optionText="name" />
        </ReferenceInput>}
    </Filter>
  )
}

export const RatingDatagrid: React.FC<{ showCustomerColumn?: boolean }> = ({ showCustomerColumn = true }) => (
  <Datagrid bulkActionButtons={false} rowClick="show">
    {
      showCustomerColumn &&
      <ReferenceField label="Cliente" reference="customers" source="customerId" link='show'>
        <TextField source="name" />
      </ReferenceField>
    }
    <StarRatingField source="attendantRating" label="Avaliação do frentista" />
    <StarRatingField source="stationRating" label="Avaliação do Posto" />
    <DateField source='createdAt' label='Data' showTime />
    {
      Permission({
        permission: PERMISSIONS.LIST_FILLINS,
        children: (
          <ReferenceField label="Abastecimento" source="fillinId" reference="fillins" link='show' sortable={false}>
            <FunctionField render={() => 'Abastecimento'} />
          </ReferenceField>
        )
      })
    }
    <TextField source="comment" label="Comentário" />
  </Datagrid>
);

const RatingList = ({ permissions, ...props }) => {
  const { state: params } = useLocation();
  const redirectFilter = params?.filter ?? {};

  return (
    <List
      {...props}
      exporter={false}
      title="Avaliações"
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={<RatingFilter />}
      filter={{ ...redirectFilter }}
    >
      <RatingDatagrid />
    </List>
  )

}

export default RatingList;
