import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import {
  BooleanInput,
  Create,
  DateInput,
  FormDataConsumer,
  Labeled,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  TimeInput,
  maxLength,
  maxValue,
  minValue,
  required
} from 'react-admin';
import Permission from '../../components/Permission';
import { RichTextInputCustom } from '../../components/RichTextInputCustom';
import { MAX_DATE, PERMISSIONS } from '../../constants';
import dayjs from 'dayjs';

const useStyles = makeStyles({
  richText: {
    marginTop: 16,
    width: 'auto',
    '& .ql-editor': {
      minHeight: 200
    },
  },
  charCounter: {
    right: '1%',
    width: 'auto',
    heigth: '100%',
    fontSize: 12,
    opacity: .75,
    fontFamily: 'Helvetica',
    textAlign: 'end',
  },
  labeledWidth: {
    width: '70%'
  },
});

const createMarketingCampaigns: React.FC = props => {
  const now = dayjs().toISOString();
  const nowDate = dayjs().toJSON().slice(0, 10);
  const MAX_LENGTH_PUSH = 130;
  const classes = useStyles();
  const transform = (data) => ({
    ...data,
    startDate: dayjs(data.startDate).toISOString(),
    endDate: dayjs(data.endDate).toISOString()
  })

  return (
    <Permission permission={PERMISSIONS.MARKETING_CAMPAIGNS}>
      <Create {...props} title="Criar campanha de marketing" transform={transform}>
        <SimpleForm shouldUnregister>
          <TextInput source="title" label="Título" sx={{ minWidth: 400 }} />
          <ReferenceInput source="marketingCampaignId" reference="chains/chainId/marketing-campaigns" label="Campanha">
            <SelectInput
              label="Campanha"
              source="marketingCampaignId"
              optionText="label"
              validate={required()}
              sx={{ minWidth: 400 }}
            />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData }) => {
              return (
                <Box>
                  <DateInput
                    source="startDate"
                    label="Início da campanha"
                    defaultValue={dayjs().startOf('day').toISOString()}
                    validate={[
                      required(),
                      minValue(nowDate, 'A data de início da campanha deve ser no mínimo a data atual'),
                      maxValue(formData.endDate, 'A data de início da campanha deve ser no máximo a data de término')
                    ]}
                    inputProps={{ max: MAX_DATE }}
                    style={{ marginRight: 16 }}
                  />
                  {!formData.alwaysActive &&
                    <DateInput
                      source="endDate"
                      label="Fim da campanha"
                      inputProps={{ max: MAX_DATE }}
                      validate={[required(), minValue(
                        formData.startDate,
                        'A data de término da campanha deve ser no mínimo a data de início',
                      )]}
                    />}
                </Box>
              )
            }}
          </FormDataConsumer>
          <BooleanInput
            label="Sempre ativa"
            source="alwaysActive"
            defaultValue={false}
          />
          <FormDataConsumer>
            {({ formData }) => formData.alwaysActive &&
              <Typography variant="subtitle2" gutterBottom style={{ marginTop: -16, marginBottom: 16 }}>
                *A campanha não terá uma data de término.
              </Typography>
            }
          </FormDataConsumer>
          <TimeInput
            label="Horário de Envio"
            source="time"
            variant="filled"
            defaultValue={now}
            sx={{ width: 160, contain: 'content' }}
            required
          />
          <div className={classes.richText}>
            <Labeled label="Descrição: ">
              <RichTextInputCustom
                source="body"
                label="Essa mensagem pode conter formatações e será exibida no aplicativo, na tela de notificações. Ela NÃO será enviada na notificação PUSH"
                validate={required()}
              />
            </Labeled>
          </div>
          <Labeled label="Enviar via: ">
            <>
              {/* <BooleanInput source="sms" label="SMS" /> */}
              <BooleanInput defaultValue source="pushNotification" label="Notificação no aplicativo" />
            </>
          </Labeled>
          <FormDataConsumer>
            {({ formData }) => formData.pushNotification &&
              <Labeled label="Esta mensagem será enviada na notificação Push:" className={classes.labeledWidth}>
                <>
                  <TextInput
                    style={{ width: '100%' }}
                    source="pushMessage"
                    label="Mensagem Push"
                    validate={[required(), maxLength(MAX_LENGTH_PUSH)]}
                    inputProps={{ autoComplete: 'off' }}
                    helperText={false}
                  />
                  <span className={classes.charCounter}>
                    {formData.pushMessage?.length || 0}/{MAX_LENGTH_PUSH}
                  </span>
                </>
              </Labeled>
            }
          </FormDataConsumer>
          <Typography variant="subtitle2" gutterBottom style={{ marginTop: 16, width: '100%' }}>
            * As campanhas criadas e iniciadas HOJE serão enviadas a partir do dia seguinte.
          </Typography>
        </SimpleForm>
      </Create>
    </Permission >
  )
}

export default createMarketingCampaigns;