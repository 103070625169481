import React from 'react';
import { makeStyles } from '@mui/styles';

interface IImageInformations {
    position?: number;
}

const useStyles = makeStyles({
    spanRequired: {
        color: 'red'
    },
    information: position => ({
        position: 'relative',
        top: `${position ? position : -15}px`,
        fontSize: 12,
        opacity: .75,
        fontFamily: 'Helvetica',
    })
});


const ImageInformations: React.FC<IImageInformations> = ({ position }) => {
    const classes = useStyles(position);
    return (
        <>
            <p className={classes.information}>
                <span className={classes.spanRequired}>* </span>
                A imagem deve ter o tamanho máximo de 10MB. Os formatos aceitos são .jpeg e .png.
            </p>
        </>
    );
}

export default ImageInformations;