import React from 'react';
import { PERMISSIONS } from '../../constants';
import { ReportList, Permission } from '../../components';
import { Datagrid, ReferenceField, TextField } from 'react-admin';
import { PartiallyHiddenCpfField, PhoneField } from '../../components/Fields';
import ListPagination from "../../components/ListPagination";
import { formatPartiallyHiddenCpf } from '../../utils';


const BlockedCustomersReport = () => (
  <Datagrid bulkActionButtons={false}>
    <ReferenceField
      basePath="customers"
      reference="customers"
      source="id"
      label="Nome"
      link={"show"}
    >
      <TextField
        source="name"
      />
    </ReferenceField>
    <PhoneField
      source="cellphone"
      label="Telefone"
      textAlign="center"
    />
    <PartiallyHiddenCpfField
      source="cpf"
      label="CPF"
      textAlign="center"
    />
  </Datagrid>
);

const reportExporter = async (data, fetchRelatedRecords) => {

  const fetchData = await fetchRelatedRecords([{ field: 'id', resource: 'customers' }], data);

  const getData = (resource) => {
    return fetchData.find(({ resource: res }) => res === resource)?.results || [];
  }

  data = data.map(customerBlocked => {
    const customer = getData('customers').find(({ id }) => id === customerBlocked.id);

    return {
      nome: customer && customer.name ? customer.name : 'Não identificado',
      telefone: customerBlocked ? customerBlocked.cellphone : 'Não identificado',
      cpf: customerBlocked ? formatPartiallyHiddenCpf(customerBlocked.cpf) : 'Não identificado',
    };
  });

  return data;
}

const ReportBlockedCustomers = (props) => {
  return (
    <Permission permission={PERMISSIONS.BLOCKED_CUSTOMERS_REPORT} >
      <ReportList
        {...props}
        basePath="report-blocked-customers"
        title="Clientes Bloqueados"
        resource="chains/chainId/reports/blocked-customers"
        sort={{ field: 'createdAt', order: 'DESC' }}
        titleOnPrint="Relatório de Clientes Bloqueados"
        perPage={25}
        pagination={<ListPagination />}
        customExporter={reportExporter}
      >
        <BlockedCustomersReport />
      </ReportList>
    </Permission>
  );
};

export default ReportBlockedCustomers;