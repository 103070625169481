import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { Datagrid, DateField, Filter, NumberField, ReferenceInput, SelectInput, useListContext } from 'react-admin';
import { CPFField } from "../../components/Fields";
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import ReportList from '../../components/ReportList';
import { PERMISSIONS } from '../../constants';
import { getChainId } from '../../lib';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
}));

const CustomersList = () => {
  const classes = useStyles();
  const props = useListContext();

  let data = [];
  let summaryData = [];

  if (props.data) {
    const reportInfo = props.data[0];

    if (reportInfo) {
      data = reportInfo.data;
      summaryData = reportInfo.summaryData;
    }
  }

  return (
    <>
      <Typography className={classes.title}>Total</Typography>
      <Datagrid bulkActionButtons={false} style={{ marginBottom: 60 }} data={summaryData} total={1}>
        <NumberField source="total" textAlign="center" label="Clientes" sortable={false} />
        <NumberField source="points" textAlign="center" label="Saldo Vitalício Restante" sortable={false} />
      </Datagrid>
      <Typography className={classes.title}>Clientes</Typography>
      <Datagrid bulkActionButtons={false} data={data}>
        <CPFField label="CPF" source="customer_cpf" />
        <NumberField label="Saldo Vitalício Restante" source="points" />
      </Datagrid>
    </>
  )
};

const customersExporter = async (data) => {
  return data[0].data.map(({ id, cpf, points }) => ({
    id,
    cpf,
    points
  }));
}

const FilterTablePrint = () => {
  const { filterValues } = useListContext();
  return (
    <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
    </Datagrid>
  );
}

const ReportLifePoints = (props) => {

  const ListFilters = () => (
    <Filter>
      <Permission permission={PERMISSIONS.ADMIN} alwaysOn>
        <ReferenceInput source="chainId" reference="chains" perPage={9999} sort={{ field: 'name', order: 'ASC' }}>
          <SelectInput label="Rede" optionText="name" />
        </ReferenceInput>
      </Permission>
    </Filter>
  );

  return (
    <Permission permission={PERMISSIONS.INACTIVE_CUSTOMERS_REPORT}>
      <ReportList
        {...props}
        basePath="report-life-points"
        title="Pontos Vitalícios"
        resource="chains/chainId/reports/life-points"
        filters={<ListFilters />}
        sort={{ field: 'created_at', order: 'DESC' }}
        perPage={25}
        filterDefaultValues={{
          chainId: getChainId(),
        }}
        pagination={<ListPagination />}
        bulkActionButtons={false}
        customExporter={customersExporter}
        titleOnPrint="Relatório de Pontos Vitalícios"
        filterTablePrint={<FilterTablePrint />}
        landscape
      >
        <CustomersList />
      </ReportList>
    </Permission >
  );
};

export default ReportLifePoints;