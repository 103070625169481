import { stringify } from 'query-string';
import { DEFAULT_API_ADMIN, MAX_IDS_PER_REQUEST } from '../../constants';
import { getChainId, httpClientReactAdmin } from '../../lib';

const getMany = async (resourceName, params) => {
  const URL = `${DEFAULT_API_ADMIN}/${(resourceName || '').replace('chainId', getChainId())}`;
  const query: { [key: string]: any } = {};
  if (getChainId()) {
    query.chainId = `"${getChainId()}"`;
  }

  if (params.ids) {
    params.ids = params.ids.filter(id => id !== "null");
    query.filter = JSON.stringify({ ids: params.ids });
    const folds = Math.ceil(params.ids.length / MAX_IDS_PER_REQUEST);
    const requests: Array<Promise<{ status: number; headers: Headers; body: string; json: any; }>> = [];

    for (let i = 0; i < folds; i++) {
      const start = i * MAX_IDS_PER_REQUEST; //   0, 150, 300, ...
      const end = (i + 1) * MAX_IDS_PER_REQUEST; // 150, 300, ... slice é exclusivo

      const ids = params.ids
        .slice(start, end);
      const itemQuery = structuredClone(query);
      itemQuery.filter = JSON.stringify({ ids, listDeleted: true });
      requests.push(httpClientReactAdmin(`${URL}?${stringify(itemQuery)}`, { method: 'GET' }));
    }

    const responses: Array<{ status: number; headers: Headers; body: string; json: any; }> = [];
    for (let i = 0; i < requests.length; i++) {
      responses.push(await requests[i]);
    }

    const responsesReduced: { json: { data: Array<{}> } } = responses.reduce((acc, cur) => ({
      json: { data: acc.json.data.concat(cur.json.data) }
    }), { json: { data: [] } });

    return responsesReduced.json;
  } else {
    if (params.pagination) {
      const { page, perPage } = params.pagination;
      if (page && perPage) {
        query.skip = (page - 1) * perPage;
        query.take = perPage;
      }
    };

    const { json } = await httpClientReactAdmin(`${URL}?${stringify(query)}`, { method: 'GET' });
    return json;
  }
};

export default getMany;