import { PERMISSIONS } from '../../constants';
import list from './ProductsCategoryList';
import create from './ProductsCategoryCreate';
import edit from './ProductsCategoryEdit';

export default {
    list,
    create,
    edit,
    name: `chains/chainId/product-categories`,
    permissions: [PERMISSIONS.CREATE_PRODUCT_CATEGORIES],
}