import React, { useState, useEffect } from 'react';
import { useDataProvider, usePermissions } from 'react-admin';
import { PERMISSIONS } from '../../constants';
import { getAttendantId, getChainId, getEmployeeType } from '../../lib';
import useDashboardContext from '../../hooks/useDashboardContext';
import Permission from '../../components/Permission';
import moment from 'moment';

import AvTimerIcon from '@mui/icons-material/AvTimer';
import { CardWithIcon } from './CardWithIcon';
import { EmployeeType } from '../../models';

export const FillinDuration = () => {
  const [time, setTime] = useState('--:--:--');

  const dataProvider = useDataProvider();
  const { permissions } = usePermissions();
  const { from, to, chain, fillinsSummary, stationIds } = useDashboardContext();

  const chainId = permissions && permissions.includes(PERMISSIONS.ADMIN) ? chain : getChainId();

  useEffect(() => {
    const filter = { from, to };
    if (stationIds && stationIds.length > 0) { filter.placeIds = stationIds; }
    if (getEmployeeType() === EmployeeType.attendant) { filter.attendantId = getAttendantId(); }

    dataProvider.getList('dashboard/fillin-duration', { filter, chainId })
      .then(({ data }) => {
        const { 0: res } = data;
        const formatedTime = res.time
          ? moment('0:0:0', 'H:m:s').add(res.time.toFixed(2) * 60000, 'milliseconds').format('HH:mm:ss') : '--:--:--';
        setTime(formatedTime);
      }).catch(err => console.error(err));
  }, [from, to, chain, stationIds]);

  return (
    <Permission permission={PERMISSIONS.FILLIN_DURATION}>
      <CardWithIcon
        icon={AvTimerIcon}
        title={'Duração de um abastecimento'}
        subtitle={time}
      />
    </Permission>
  );
};
