import { Box, Card, Grid, Typography } from "@mui/material";
import { ArrayField, Datagrid, NumberField, SimpleShowLayout, TextField } from "react-admin";
import { formatNumber } from "../../utils";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
}));

const GroupCoupon = ({ coupons, totalValue }) => {
  const classes = useStyles();

  const groupCoupons = (coupons): { [key: string]: { name: string, count: number, value: number } } => {
    const data = {};

    (coupons || []).forEach((coupon) => {
      if (coupon.isCashback) {
        if (data['cashback']) {
          data['cashback'].partnerValue += coupon.partnerValue || 0;
          data['cashback'].count += 1;
        } else {
          data['cashback'] = {
            name: 'cashback',
            partnerValue: coupon.partnerValue || 0,
            count: 1,
          }
        }
      } else if (coupon.product?.isCash) {
        if (data['cash']) {
          data['cash'].partnerValue += coupon.partnerValue || 0;
          data['cash'].count += 1;
        } else {
          data['cash'] = {
            name: 'Voucher',
            partnerValue: coupon.partnerValue || 0,
            count: 1,
          }
        }
      } else if (coupon.productId) {
        if (data[coupon.productId]) {
          data[coupon.productId].partnerValue += coupon.partnerValue || 0;
          data[coupon.productId].count += 1;
        } else {
          data[coupon.productId] = {
            name: coupon.product.name,
            partnerValue: coupon.partnerValue || 0,
            count: 1,
          }
        }
      } else if (coupon.title) {
        if (data[coupon.title]) {
          data[coupon.title].partnerValue += coupon.partnerValue || 0;
          data[coupon.title].count += 1;
        } else {
          data[coupon.title] = {
            name: coupon.title,
            partnerValue: coupon.partnerValue || 0,
            count: 1,
          }
        }
      }
    }
    )
    return data;
  }

  return (
    <Box>
      <Grid item sx={{ position: 'sticky', top: 50, maxHeight: '100vh', overflowY: 'auto' }}>
        <Card>
          <SimpleShowLayout record={{ summary: Object.values(groupCoupons(coupons)).sort((a, b) => b.count - a.count) }}>
            <ArrayField source="summary" label={false}>
              <>
                <Typography className={classes.title}>{"Valor Total:  "}
                  <span>{formatNumber(totalValue, { style: "currency", currency: "BRL" })}</span>
                </Typography>
                <Datagrid bulkActionButtons={false} empty={false}>
                  <TextField
                    source="name"
                    label="Produto"
                    textAlign="center"
                  />
                  <NumberField
                    source="partnerValue"
                    label="Valor"
                    textAlign="center"
                    emptyText="R$ 0,00"
                    options={{
                      style: "currency",
                      currency: "BRL",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }} />
                  <TextField
                    source="count"
                    label="Quantidade"
                    textAlign="center"
                  />
                </Datagrid>
              </>
            </ArrayField>
          </SimpleShowLayout>
        </Card >
      </Grid >
    </Box>
  )
}

export default GroupCoupon