import { DEFAULT_API_ADMIN } from '../../constants';
import { getChainId, httpClientReactAdmin } from '../../lib';
import { stringify } from 'query-string';


export default async (resourceName, params) => {
  const { field, order } = params.sort;
  const query: { [key: string]: any } = {
    order: JSON.stringify({ [field]: order }),
    filter: JSON.stringify({
      ...params.filter,
      [params.target]: params.id,
    }),
  };

  if (params.pagination) {
    const { page, perPage } = params.pagination;
    if (page && perPage) {
      query.skip = (page - 1) * perPage;
      query.take = perPage;
    }
  };

  if (getChainId()) {
    query.chainId = `"${getChainId()}"`;
  }

  const URL = `${DEFAULT_API_ADMIN}/${`${(resourceName || '').replace('chainId', getChainId()).replace('customerId', params.id)}`}?${stringify(query)}`;
  const { json } = await httpClientReactAdmin(URL, { method: 'GET' });

  const data = resourceName === 'chains/chainId/customers/customerId/transactions' ? json.data.transactions : json.data;
  // const total = resourceName === 'chains/chainId/customers/customerId/transactions' ? json.data.transactions.length : json.total;
  return { data, total: json.total };
}
