import { GiftRuleType } from "./giftRuleType";

export class GiftRule {

  id: string | undefined;
  type: GiftRuleType | undefined;
  fuelId: string | undefined;
  valueType: string | undefined;
  value: number | undefined;
  stationIds: string[] | undefined;
  startDate: string | undefined;
  endDate: string | undefined;

  static createGiftRule(value: Partial<GiftRule>) {
    const giftRule: Partial<GiftRule> = {
      type: value.type,
      fuelId: value.fuelId,
      valueType: value.valueType,
      value: value.value,
      startDate: value.startDate,
      endDate: value.endDate,
      stationIds: value.stationIds,
    };

    return giftRule;
  }

  constructor(init?: Partial<GiftRule>) {
    Object.assign(this, init);
  }
}