import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import { default as TableBody, default as TableContainer } from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { Fragment, useEffect, useState } from "react";
import { Button, Loading, useDataProvider, useNotify } from 'react-admin';
import { useNavigate } from 'react-router';
import { DropDownPartners } from '../../components';
import Permission from "../../components/Permission";
import { PERMISSIONS } from "../../constants";
import { getChainId, getPlaceId } from '../../lib';

const TransactionType = {
    adition: 'Entrada',
    reducion: 'Redução',
    transfer: 'Transferência',
    redemption: 'Resgate',
    refund: 'Estorno',
    adjustment: 'Ajuste'
}

const StockType = {
    showcase: 'Mostruário',
    warehouse: 'Depósito',
}

const useStyles = makeStyles({
    table: {
        marginTop: 20,
        marginBottom: 20,
        width: '100%',
    },
    filter: {
        marginBottom: '10px'
    }
});

const ProductStatement = ({ id }) => {
    const notify = useNotify();
    const navigate = useNavigate();
    const dataProvider = useDataProvider();
    const classes = useStyles();
    const [loading, setLoading] = useState(true)
    const [transactions, setTransactions] = useState()
    const [placeId, setPlaceId] = useState(getPlaceId() || undefined);
    const [places, setPlaces] = useState(null);

    const getPlaces = async () => {
        dataProvider.getList('chains/chainId/places', { sort: { field: 'name', order: 'ASC' } })
            .then(({ data }) => {
                setPlaces(data);
                setPlaceId(data[0].id);
            })
            .catch((error) => { notify(error.message, { type: 'warning' }); })
    }

    useEffect(() => {
        if (!getPlaceId()) {
            getPlaces();
        }
    }, []);

    useEffect(() => {
        setLoading(true);

        if (placeId) {
            dataProvider.getList(`chains/chainId/stock-statement`, { filter: { productId: id, placeId } })
                .then(response => {
                    const transactions = response.data

                    setLoading(false);
                    setTransactions(transactions);
                })
                .catch((error) => {
                    setLoading(false);
                    notify("Erro ao buscar extrato", { type: 'warning' })
                });
        }
    }, [placeId]);

    return (
        <Fragment>
            {loading && <Loading />}
            <div className={classes.filter}>
                {!getPlaceId() && placeId &&
                    <DropDownPartners
                        label="Local"
                        defaultValue={placeId}
                        setValue={setPlaceId}
                        arrayData={places}
                    />
                }
            </div>
            <TableContainer className={classes.table} component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Data</TableCell>
                            <TableCell align="center">Quantidade Movimentada</TableCell>
                            <TableCell align="center">Saldo Parcial</TableCell>
                            <TableCell align="center">Tipo</TableCell>
                            <TableCell align="center">Estoque</TableCell>
                            <TableCell align="center">Local</TableCell>
                            <TableCell align="center">Descrição</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>

                    {transactions && transactions.length > 0 ?
                        <TableBody>
                            {transactions.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell align="center">{moment(row.reference_date).format('DD/MM/YYYY HH:mm')}</TableCell>
                                    <TableCell align="center">{row.value}</TableCell>
                                    <TableCell align="center">{row.balance}</TableCell>
                                    <TableCell align="center">{TransactionType[row.type]}</TableCell>
                                    <TableCell align="center">{StockType[row.stock_type]}</TableCell>
                                    <TableCell align="center">{row.placename}</TableCell>
                                    <TableCell align="center">{row.description}</TableCell>
                                    <TableCell align="center">{
                                        (row.type === "adition" || row.type === "reducion" || row.type === "transfer" || row.type === "adjustment") &&
                                        <Permission permission={PERMISSIONS.DELETE_STOCK_TRANSACTION}>
                                            <Button
                                                label="Remover"
                                                startIcon={<DeleteIcon />}
                                                onClick={() => {
                                                    dataProvider.delete(`chains/${getChainId()}/stock-transactions`, { id: row.id })
                                                        .then(() => {
                                                            notify('Transação removida');
                                                            navigate('/');
                                                            navigate(`/chains/chainId/products/${row.productid}/transactions`);
                                                        })
                                                        .catch(() => {
                                                            setLoading(false);
                                                            notify("Erro ao deletar transação", { type: 'warning' })
                                                        })
                                                }}
                                            />

                                        </Permission>
                                    }</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        :
                        <TableCell>
                            <Typography>Nenhuma movimentação do produto</Typography>
                        </TableCell>
                    }
                </Table>
            </TableContainer>
        </Fragment >
    );
}

export default ProductStatement;