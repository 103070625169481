import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateTimeInput, useDataProvider, useNotify } from 'react-admin';
import { DropDownPartners, Loader, ReportLayout, TableGeneric } from '../../components';
import { PERMISSIONS } from '../../constants';
import { getChainId, getPlaceId } from '../../lib';

const SyntheticProductsStockReport = ({ data }) =>
    <TableGeneric
        headCells={[
            'Produto',
            'Ponto de troca',
            'Saldo inicial',
            'Valor',
            'Saldo final'
        ]}
        arrayData={data}
        title="Movimentação de estoque - Resumo"
        style={{ width: '100%' }}
    />

export default function ReportSyntheticProductsStock() {
    const [dateFrom, setDateFrom] = useState(moment().startOf('days'));
    const [dateTo, setDateTo] = useState(moment().endOf('days'));
    const [allPlaces, setAllPlaces] = useState();
    const [selectedPlace, setSelectedPlace] = useState(getPlaceId());
    const [allProducts, setAllProducts] = useState();
    const [dropDownProducts, setDropDownProducts] = useState();
    const [selectedProduct, setSelectedProduct] = useState();
    const [stockChanges, setStockChanges] = useState();
    const [loading, setLoading] = useState();
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const fetchPlaces = async () => {
        const filter = { chainId: getChainId() }
        const placeId = getPlaceId();
        const resource = placeId ? `places/${placeId}` : 'places'

        dataProvider.getList(resource, { filter, sort: { field: 'name', order: 'ASC' } })
            .then(response => {
                setAllPlaces(response.data);
            }).catch(() => {
                notify('Erro ao carregar locais da rede', { type: 'warning' });
            })
    }

    const fetchProducts = async () => {
        const filter = { stock: true, placeId: getPlaceId() || undefined }

        dataProvider.getList(`chains/${getChainId()}/products`, { filter })
            .then(response => {
                setAllProducts(response.data);
                setDropDownProducts(response.data);
            }).catch(() => {
                notify('Erro ao carregar produtos da rede', { type: 'warning' });
            })
    }

    const fetchData = async () => {
        const filter = {
            from: moment.utc(dateFrom),
            to: moment.utc(dateTo),
            placeId: selectedPlace || undefined,
            productId: selectedProduct || undefined
        }

        dataProvider.getList(`chains/${getChainId()}/stock-statement`, { filter })
            .then(response => {
                const tableData = response.data.map(t => ({
                    product: t.productname,
                    place: t.placename,
                    initialBalance: t.balance - t.value,
                    value: t.value,
                    finalBalance: t.balance
                }));

                setStockChanges(tableData);
            }).catch(() => {
                notify('Erro ao carregar produtos em estoque', { type: 'warning' })
            })
    }

    const filters = [{
        from: dateFrom.format('DD/MM/YYYY'),
        to: dateTo.format('DD/MM/YYYY'),
        place: Array.isArray(allPlaces) ? selectedPlace ? allPlaces.find(p => p.id === selectedPlace).name : 'Todos' : allPlaces ? allPlaces.name : 'Todos',
        product: selectedProduct ? allProducts.find(p => p.id === selectedProduct).name : 'Todos'
    }]

    useEffect(() => {
        const doFetch = async () => {
            setLoading(true);
            await fetchData();
            setLoading(false);
        }
        doFetch();
    }, [selectedPlace, selectedProduct, dateFrom, dateTo])

    useEffect(() => {
        setSelectedProduct(null);
        if (allProducts) {
            if (selectedPlace) {
                setDropDownProducts(allProducts.filter(p => p.productPlaces.find(pp => pp.placeId === selectedPlace)));
            } else {
                setDropDownProducts(allProducts);
            }
        }
    }, [selectedPlace])

    useEffect(() => {
        const onInit = async () => {
            setLoading(true);
            await fetchProducts();
            await fetchPlaces();
            await fetchData();
            setLoading(false);
        }
        onInit();
    }, []);

    return (
        <ReportLayout
            permission={PERMISSIONS.STOCK_CHANGES_REPORT}
            title='Relatório de movimentação de estoque - Resumo'
            filterTablePrint={
                <TableGeneric
                    headCells={[
                        'De',
                        'Até',
                        'Local',
                        'Produto'
                    ]}
                    arrayData={filters}
                    title="Filtros"
                    style={{ width: '100%', margin: '1em 0' }}
                />
            }
            filters={[
                <DateTimeInput
                  label='De'
                  ampm={false}
                  disableFuture
                  maxDate={dateTo}
                  parse={(value) => moment(value).toISOString()}
                  defaultValue={moment().startOf('day').toISOString()}
                  onChange={date => setDateFrom(date)}
                />,
                <DateTimeInput
                  label='Até'
                  ampm={false}
                  disableFuture
                  minDate={dateFrom}
                  parse={(value) => moment(value).toISOString()}
                  defaultValue={moment().endOf('day').toISOString()}
                  onChange={date => setDateTo(date)}
                />,
                <>{!getPlaceId() &&
                    <DropDownPartners
                        style={{ width: '150px' }}
                        label="Ponto de troca"
                        value={selectedPlace}
                        setValue={setSelectedPlace}
                        arrayData={allPlaces}
                    />
                }
                </>,
                <DropDownPartners
                    style={{ width: '150px' }}
                    label="Produto"
                    value={selectedProduct || null}
                    setValue={setSelectedProduct}
                    arrayData={dropDownProducts}
                />
            ]}
            content={loading ? <Loader /> : stockChanges ? <SyntheticProductsStockReport data={stockChanges} /> : null}
        />
    );
}