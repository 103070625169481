import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import {
	Datagrid,
	DateField,
	ReferenceArrayInput,
	ReferenceField,
	SelectArrayInput,
	SelectInput,
	TextField,
	useListContext,
} from 'react-admin';
import { BetweenDatesInput } from '../../components/BetweenDatesInput';
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import ReportList from '../../components/ReportList';
import { PERMISSIONS } from '../../constants';
import { getPlaceId } from '../../lib';
import { PhoneField } from '../../components/Fields';

const useStyles = makeStyles(theme => ({
	title: {
		margin: '10px 0 10px 0',
		textAlign: 'center',
		fontWeight: 'bold'
	},
}));

const RecurringCustomersList = () => {
	const classes = useStyles();
	const props = useListContext();

	let data = [], summaryData = [], totalData = [];

	if (props.data) {
		const reportInfo = props.data[0];

		if (reportInfo) {
			data = reportInfo.data;
			summaryData = [reportInfo.summaryData];
			totalData = [reportInfo.total];
		}
	}

	return (
		<>
			<Typography className={classes.title}>Clientes que Abasteceram</Typography>
			<Datagrid bulkActionButtons={false} style={{ marginBottom: 60 }} data={summaryData} total={1}>
				<TextField source="one" label="Uma vez" textAlign="center" emptyText="0" sortable={false} />
				<TextField source="two" label="Duas vezes" textAlign="center" emptyText="0" sortable={false} />
				<TextField source="three" label="Três vezes" textAlign="center" emptyText="0" sortable={false} />
				<TextField source="four" label="Quatro vezes" textAlign="center" emptyText="0" sortable={false} />
				<TextField source="five" label="Cinco ou mais vezes" textAlign="center" emptyText="0" sortable={false} />
			</Datagrid>

			<Typography className={classes.title}>Clientes e Reabastecimentos</Typography>
			<Datagrid bulkActionButtons={false} resource={props.resource} data={data}>
				<ReferenceField label="Cliente" reference="customers" basePath="customers" source="id" link='show'>
					<TextField source="name" />
				</ReferenceField>
				<ReferenceField label="CPF do Cliente" reference="customers" basePath="customers" source="id" link='show'>
					<TextField source="cpf" />
				</ReferenceField>
				<ReferenceField label="Telefone" reference="customers" source="id" link={false}>
					<PhoneField source="cellphone" />
				</ReferenceField>
				<TextField source="refuels" label="Total de Abastecimentos" textAlign="center" />
			</Datagrid>
		</>
	);
};

const amountChoices = [
	{ id: 1, name: 'Uma vez' },
	{ id: 2, name: 'Duas vezes' },
	{ id: 3, name: 'Três vezes' },
	{ id: 4, name: 'Quatro vezes' },
	{ id: 5, name: 'Cinco ou mais vezes' },
];

const listFilters = [
	<BetweenDatesInput alwaysOn />,
	<ReferenceArrayInput source="stationIds" reference="stations" perPage={null} sort={{ field: "name", order: "ASC" }} alwaysOn>
		<SelectArrayInput
			label="Posto"
			emptyText="Todos"
			style={{ minWidth: 180 }}
			optionText={'name'}
		/>
	</ReferenceArrayInput>,
	<SelectInput
		label="Quantidade"
		source="amount"
		emptyText="Todos"
		alwaysOn
		choices={amountChoices}
	/>
];

const recurringCustomersExporter = async (data, fetchRelatedRecords) => {
	const customersData = data[0].data;
	const relations = [{ field: 'id', resource: 'customers' }];

	const [fetchCustomers] = await fetchRelatedRecords(relations, customersData);

	const getCustomer = (id) => {
		return fetchCustomers?.results?.find(customer => customer.id === id);
	}

	customersData.forEach(customer => {
		const currCustomer = getCustomer(customer.id);
		customer.cliente = currCustomer?.name || 'Não definido';
		customer.cpf = currCustomer?.cpf || 'Não definido';
		customer.telefone = currCustomer?.cellphone || 'Não definido';
	});

	return customersData;
}

const FilterTablePrint = () => {
	const classes = useStyles();
	const { filterValues } = useListContext();
	return (
		<Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
			<DateField source="from" textAlign="center" label="De" sortable={false} />
			<DateField source="to" textAlign="center" label="Até" sortable={false} />
			<ReferenceField label="Posto" emptyText="Todos" source="stationId" textAlign="center" basePath="stations" reference="stations" link={false}>
				<TextField source="name" />
			</ReferenceField>
			<DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
		</Datagrid>
	);
};

const ReportRecurringCustomers = (props) => {
	const placeId = getPlaceId();
	const stationsIndex = listFilters.findIndex(({ props }) => props.reference === 'stations')
	if (placeId && stationsIndex > -1) {
		listFilters.splice(stationsIndex, 1)
	}

	return (
		<Permission permission={PERMISSIONS.RECURRING_CUSTOMERS_REPORT}>
			<ReportList
				{...props}
				basePath="fillins"
				title="Clientes Recorrentes"
				resource="chains/chainId/reports/recurring-customers"
				filters={listFilters}
				sort={{ field: 'refuels', order: 'DESC' }}
				perPage={25}
				filterDefaultValues={{
					stationId: placeId,
					from: moment().startOf('day').subtract(1, "month").toISOString(),
					to: moment().endOf('day').toISOString()
				}}
				pagination={<ListPagination />}
				customExporter={recurringCustomersExporter}
				bulkActionButtons={false}
				titleOnPrint="Relatório de clientes recorrentes"
				filterTablePrint={<FilterTablePrint />}
				landscape
			>
				<RecurringCustomersList />
			</ReportList>
		</Permission >
	);
};

export default ReportRecurringCustomers;