
import React from "react";
import {
  List,
  Datagrid,
  TextField,
  SingleFieldList,
  ChipField,
  useListContext,
  ArrayField,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceField,
  DeleteButton,
} from "react-admin";
import Permission from "../../components/Permission";
import { PERMISSIONS } from "../../constants";
import ListPagination from "../../components/ListPagination";
import { makeStyles } from '@mui/styles';

const listStyles = makeStyles({
  image: {
    '& img': {
      maxWidth: '4rem',
    },
  },
})

const listFilter = [
  <ReferenceArrayInput
    style={{ minWidth: 220 }}
    source="placeIds"
    reference="stations"
    label="Postos"
    perPage={null}
    alwaysOn
  >
    <SelectArrayInput source="name" />
  </ReferenceArrayInput>,
  <ReferenceArrayInput
    style={{ minWidth: 220 }}
    source="paymentMethodIds"
    reference="app-payment-methods"
    label="Métodos de pagamento"
    perPage={null}
    alwaysOn
  >
    <SelectArrayInput source="name" />
  </ReferenceArrayInput>,
];

export default (props) => {
  const classes = listStyles();

  const ListExternalPaymentMethods = () => {
    const props = useListContext();
    const { data } = props;

    return (
      <Datagrid data={data} rowClick="edit">
        <ReferenceField label="Método de pagamento" source="internalPaymentMethodId" reference="app-payment-methods" sortable={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Postos" source="placeId" reference="stations" sortable={false}>
          <TextField source="name" />
        </ReferenceField>
        <ArrayField label="Tipos externos de pagamentos" source="externalPaymentMethods" sortable={false}>
          <SingleFieldList linkType={false}>
            <ChipField source="type" />
          </SingleFieldList>
        </ArrayField>
        <DeleteButton />
      </Datagrid>
    );
  }

  return (
    <Permission permission={PERMISSIONS.MANAGE_EXTERNAL_PAYMENT_METHOD}>
      <List
        {...props}
        perPage={25}
        pagination={<ListPagination />}
        exporter={false}
        bulkActionButtons={false}
        title="Métodos de pagamentos externos"
        filters={listFilter}
        sort={false}
      >
        <ListExternalPaymentMethods />
      </List>
    </Permission>
  );
}