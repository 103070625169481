import React from 'react';
import {
  List,
  Filter,
  Datagrid,
  SearchInput,
  TextField,
  SelectInput,
  ReferenceInput,
  ReferenceField,
  useRedirect,
  TopToolbar,
  CreateButton,
  Button,
} from 'react-admin';
import ListPagination from '../../components/ListPagination';
import { Permission } from '../../components';
import { PERMISSIONS } from '../../constants';
import { getChainId } from '../../lib';
import RecoveryIcon from '@mui/icons-material/Restore';

const ListActions = props => {
  const redirect = useRedirect();
  return (
    <TopToolbar>
      <CreateButton {...props} />
      <Button
        label="Recuperar"
        onClick={() => redirect('/_employees/recovery')}
        startIcon={<RecoveryIcon />}
        alignIcon="right"
        style={{ alignSelf: 'end', marginLeft: 16 }}
      />
    </TopToolbar>
  );
};

const PlacesUsersFilter = ({ permissions, ...props }) => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn />
    {permissions && !permissions.includes(PERMISSIONS.ADMIN) && (
      <ReferenceInput
        label="Parceiro"
        source="placeId"
        reference="places"
        filter={{ chainId: getChainId() }}
        sort={{ field: 'name', order: 'ASC' }}
        perPage={9999}
        alwaysOn
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    )}
  </Filter>
);

export default props => (
  <List
    {...props}
    perPage={25}
    pagination={<ListPagination />}
    exporter={false}
    bulkActionButtons={false}
    filters={<PlacesUsersFilter permissions={props.permissions} />}
    title="Usuários de Parceiros"
    actions={<ListActions />}
  >
    <Datagrid rowClick="edit">
      <ReferenceField
        label="Parceiro"
        reference="places"
        source="placeId"
        link={false}
        emptyText=""
      >
        <TextField emptyText="" source="name" />
      </ReferenceField>
      <TextField source="name" label="Nome" />
      <Permission permission={PERMISSIONS.ADMIN}>
        <ReferenceField
          source="profileId"
          reference="profiles"
          label="Perfil"
        >
          <TextField source="name" />
        </ReferenceField>
      </Permission>
    </Datagrid>
  </List>
)
