import React from "react";
import { Datagrid, DateField, Link, List, ReferenceField, TextField, useRecordContext } from 'react-admin';
import ListPagination from "../../components/ListPagination";
import { CPFField } from "../../components/Fields";

const CustomerWithLink = (props) => {
  const record = useRecordContext();
  if (!record || !record.customer) { return <CPFField {...props} />; }

  return (
    <Link
      to={`/customers/${record.customer.id}/show/transactions`}
    >
      <CPFField {...props} />
    </Link>
  )
}

const ExpirationAlertList = (props) => {
  return (
    <List
      {...props}
      title="Alertas de expiração"
      resource="expiration-alerts"
      perPage={25}
      sort={{ field: 'createdAt', order: 'DESC' }}
      pagination={<ListPagination />}
      exporter={false}
    >
      <Datagrid bulkActionButtons={false}>
        <CustomerWithLink label="CPF" source="customerCpf" />
        <ReferenceField
          label="Rede"
          source="chainId"
          reference="chains"
          textAlign="center"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="description" label="Descrição" textAlign="center" />
        <DateField source="createdAt" label="Data" textAlign="center" />
      </Datagrid>
    </List>
  )
};

export default ExpirationAlertList;