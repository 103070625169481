import React from 'react'
import {
	Edit,
	SimpleForm,
	TextInput,
	RadioButtonGroupInput,
	minLength,
	ReferenceInput,
	SelectInput,
	required,
	email,
	BooleanInput,
	FormDataConsumer,
} from 'react-admin'

import Permission from '../../components/Permission';
import { makeStyles } from '@mui/styles';
import { PERMISSIONS } from '../../constants';

const useStyles = makeStyles((theme) => ({
	profiles: {
		width: 220,
	}
}));

const validatePassword = [minLength(6)];
const validateEmail = [required(), email()]

export default props => {
	const classes = useStyles();
	const transform = data => {
		delete data.profile;
		return data;
	};
	return (
		<Permission permission={PERMISSIONS.MANAGE_ATTENDANT_USER}>
			<Edit {...props} resource="employees" transform={transform} title="Editar usuário frentista">
				<SimpleForm>
					<Permission permission={PERMISSIONS.ADMIN} >
						<ReferenceInput {...props} label="Rede" source="chainId" reference="chains" sort={{ field: 'name', order: 'ASC' }} perPage={null}>
							<SelectInput optionText="name" InputProps={{ autoComplete: 'off' }} />
						</ReferenceInput>
					</Permission>
					<TextInput source="name" label="Nome" validate={[required()]} InputProps={{ autoComplete: 'off' }} />
					<TextInput source="login" label="Login" validate={validateEmail} InputProps={{ autoComplete: 'off' }} />
					<TextInput source="password" label="Senha" validate={validatePassword} InputProps={{ autoComplete: 'new-password' }} />
					<FormDataConsumer>
						{({ formData }) => (
							<ReferenceInput
								label="Frentista"
								source="attendantId"
								reference="attendants"
								filter={{ chainId: formData.chainId }}
								//@ts-ignore
								perPage={null}
								style={{ width: 256 }}
								validate={[required()]}
							>
								<SelectInput source="name" />
							</ReferenceInput>
						)}
					</FormDataConsumer>
					<FormDataConsumer>
						{({ formData }) => (
							<ReferenceInput
								className={classes.profiles}
								label="Perfil"
								source="profileId"
								reference="profiles"
								sort={{ field: 'name', order: 'ASC' }}
								perPage={9999}
								filter={{ chainId: formData.chainId }}
								validate={[required()]} >
								<RadioButtonGroupInput optionText="name" />
							</ReferenceInput>
						)}
					</FormDataConsumer>
					<BooleanInput source="mustChangePassword" label="Forçar usuário a trocar senha no primeiro acesso" />
				</SimpleForm>
			</Edit>
		</Permission>
	)
}
