import React from 'react'
import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    FormDataConsumer,
    Labeled,
    TextField,
    ReferenceField,
    Toolbar,
    useChoicesContext,
} from 'react-admin'
import Permission from '../../components/Permission'
import { makeStyles } from '@mui/styles';
import { PERMISSIONS } from '../../constants';
import SaveConfirmButton from '../../components/SaveConfirmButton';


const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: 300,
    },
}));

const FuelGroupInput = ({ formData }) => {
    const { allChoices } = useChoicesContext();

    return allChoices && allChoices.map(({ id, name }) => {
        let fuelIndex = formData.groupRuleFuels.findIndex(item => item.fuelId === id);
        if (fuelIndex === -1) {
            fuelIndex = formData.groupRuleFuels.length;
            formData.groupRuleFuels[fuelIndex] = { fuelId: id }
        }

        return (
            <Labeled label={name}>
                <TextInput
                    source={`groupRuleFuels[${fuelIndex}].factor`}
                    label="Fator Pontos/Litro"
                    defaultValue={0}
                    format={value => (!value ? '0,00' : `${Number(value).toFixed(2)}`)}
                    parse={value => (!value ? 0 : +value.replace(/[^\d]/g, '') / 100)}
                />
            </Labeled>
        );
    });
}

const GroupRulesEdit = (props) => {
    const classes = useStyles();

    const ToolbarCustom = (props) => {
        return (
            <Toolbar {...props}>
                <FormDataConsumer>
                    {({ formData, ...rest }) => (
                        <SaveConfirmButton
                            {...rest}
                            formData={formData}
                            validate={(data) => {
                                return data.groupRuleFuels && data.groupRuleFuels.some(grf => grf.factor > 2);
                            }}
                            contentMessage="A pontuação parece estar muito alta. Deseja confirmar?"
                        />)}
                </FormDataConsumer>
            </Toolbar>
        );
    }

    const sanitize = (data) => {
        return { ...data, groupRuleFuels: data.groupRuleFuels.filter(f => 'factor' in f && f.factor !== undefined) };
    }

    return (
        <Permission permission={PERMISSIONS.RULES_BY_GROUP}>
            <Edit {...props} title='Editar regra para grupo de clientes' transform={sanitize}>
                {/* <SimpleForm toolbar={<ToolbarCustom />}> */}
                <SimpleForm>
                    <ReferenceField source="groupId" reference="chains/chainId/customer-groups" label="Grupo" link={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    <div className={classes.container}>
                        <FormDataConsumer>
                            {({ formData }) =>
                                <ReferenceInput
                                    sort={{ field: 'name', order: 'ASC' }}
                                    label=""
                                    source="id"
                                    reference="fuels"
                                >
                                    <FuelGroupInput formData={formData} />
                                </ReferenceInput>
                            }
                        </FormDataConsumer>
                    </div>
                </SimpleForm>
            </Edit>
        </Permission>
    );
};

export default GroupRulesEdit;