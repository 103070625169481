import React, { useState } from "react";
import {
    Datagrid,
    List,
    TextField,
    ReferenceField,
    Filter,
    FunctionField,
    DateField,
    NumberField,
    Button,
    BooleanInput,
    useRefresh,
    useNotify,
    useDataProvider,
    SelectArrayInput,
} from "react-admin";
import Permission from "../../components/Permission";
import { PERMISSIONS } from "../../constants";
import { getPlaceId } from "../../lib";

export default ({ permissions, ...props }) => {

    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();

    const [loading, setLoading] = useState(false);

    const onClickPaymentVerified = (record) => {
        setLoading(true);
        dataProvider.update(props.resource, { id: record.id, data: { verifiedByEmployee: true }, previousData: record })
            .then(() => {
                notify('Atualizado com sucesso.');
                refresh();
            })
            .catch(() => { notify('Erro ao atualizar.'); })
            .finally(() => setLoading(false))
    };

    const PaymentsFilter = (props) => (
        <Filter {...props}>
            <SelectArrayInput source="status" label="Estado" choices={[
                { id: 'ATIVA', name: 'Pendente' },
                { id: 'EXPIRADA', name: 'Expirado' },
                { id: 'CONCLUIDA', name: 'Aprovado' },
            ]} alwaysOn />
            <BooleanInput source={'verifiedByEmployee'} label="Mostrar concluídos" alwaysOn />
        </Filter>
    );

    return (
        <List {...props}
            queryOptions={{ refetchInterval: 5000 }}
            title="Pagamentos"
            filters={<PaymentsFilter />}
            sort={{ field: 'createdAt', order: 'DESC' }}
            filter={{ placeId: getPlaceId() }}
            filterDefaultValues={{ verifiedByEmployee: false, status: ['ATIVA', 'CONCLUIDA'] }}
        >
            <Datagrid bulkActionButtons={false}>
                <DateField source="createdAt" label="Data" locales={"pt-BR"} showTime />
                <ReferenceField source="fillinId" reference="fillins" label="Bico" sortable={false}>
                    <TextField source="hoseNumber" textAlign="center" />
                </ReferenceField>
                <ReferenceField source="fillinId" reference="fillins" label="Valor" sortable={false}>
                    <NumberField
                        sortable={false}
                        source="value"
                        textAlign="center"
                        emptyText="R$ 0,00"
                        options={{
                            style: 'currency',
                            currency: 'BRL',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }}
                    />
                </ReferenceField>
                <ReferenceField source="fillinId" reference="fillins" label="Volume" sortable={false}>
                    <NumberField
                        sortable={false}
                        source="amount"
                        textAlign="center"
                        emptyText="0.0"
                        options={{
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }}
                    />
                </ReferenceField>
                <ReferenceField source="fillinId" reference="fillins" label="Combustível" sortable={false}>
                    <ReferenceField source="fuelId" reference="fuels" link={false}>
                        <TextField source="name" />
                    </ReferenceField>
                </ReferenceField>
                <FunctionField label="Status" render={record => {
                    switch (record.status) {
                        case "ATIVA": return "Pendente";
                        case "CONCLUIDA": return "Aprovado";
                        case "EXPIRADA": return "Expirado";
                        default: return "";
                    }
                }} />
                <Permission permission={PERMISSIONS.VERIFY_PAYMENTS}>
                    <FunctionField sortable={false} render={record => {
                        if (record.verifiedByEmployee === false) {
                            return <Button variant="contained" disabled={loading} label='Concluído' onClick={() => onClickPaymentVerified(record)} />
                        }
                    }} />
                </Permission>
            </Datagrid>
        </List>
    );
};