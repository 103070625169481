import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { Avatar, Box, Grid, Typography, styled } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useGetList } from "react-admin";
import StationInfoSideBar from "./stationInfoSideBar";

const MyMapComponent = React.memo(({
  data,
  center,
  zoom,
  handleMarkerClick,
}: {
  data: any[];
  center: google.maps.LatLngLiteral;
  zoom: number;
  handleMarkerClick: (stationId: string) => void;
}) => {
  const [map, setMap] = useState<google.maps.Map>();
  const ref = useRef<any>();

  useEffect(() => {
    const mapInstance = new window.google.maps.Map(ref.current, {
      center,
      zoom,
      mapId: process.env.REACT_APP_MAP_ID || 'd06eb9fab6b219cd'
    });
    setMap(mapInstance);

    const bounds = new google.maps.LatLngBounds();
    data.forEach(({ position }) => {
      bounds.extend(new google.maps.LatLng(position.lat, position.lng));
    });
    mapInstance.fitBounds(bounds, 200);

  }, []);
  return (
    <>
      <div ref={ref} id="map" style={{ height: '100%', width: '100%', overflow: 'hidden' }} />
      {map && <MarkerStations data={data} map={map} onClickMarker={handleMarkerClick} />}
    </>
  );
});



const LoadingScreen: React.FC<({ text?: string; })> = ({ text }) => (
  <Grid
    flex={1}
    container
    direction="row"
    justifyContent="center"
    alignItems="center"
    sx={{ bgcolor: grey[100] }}
  >
    <Box>
      <Typography variant="h6">
        {text || 'Carregando'}
      </Typography>
    </Box>
  </Grid>
);

const render = (status: Status): ReactElement => {
  if (status === Status.LOADING) return <LoadingScreen text="Gerando mapa" />;
  return <LoadingScreen />;
};

const MarkerContainer = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  backgroundColor: theme.palette.primary.main,
  padding: 5,
  borderRadius: '50%'
}));

const MarkerArrow = styled(Box)(({ theme }) => ({
  borderWidth: 15,
  borderStyle: 'solid',
  borderColor: `${theme.palette.primary.main} transparent transparent transparent`,
  marginTop: -5,
}));

const MarkerStations = ({ data, map, ...props }) => {
  return (
    <>
      {data.map((station, index) => (
        <Marker key={`marker-${index}`} position={station?.position} map={map} onClick={() => props.onClickMarker(station?.id)}>
          <div
            style={{
              display: 'inline-flex',
              flexDirection: 'column',
              alignItems: 'center',
              transform: 'translateY(25%)',
              cursor: 'pointer'
            }}>
            <MarkerContainer>
              {
                station.imagePath ?
                  <Avatar src={station.imagePath} />
                  :
                  <Avatar>{station.name.charAt(0).toUpperCase()}</Avatar>
              }
            </MarkerContainer>
            <MarkerArrow />
          </div>
        </Marker>
      ))}
    </>
  );
};

const Marker = ({ map, children, position, onClick }) => {
  const markerRef = useRef<any>();
  const rootRef = useRef<any>();
  const container = document.createElement('div');

  useEffect(() => {
    if (!rootRef.current) {

      markerRef.current = new google.maps.marker.AdvancedMarkerElement({
        position,
        content: container,
      });

      const listenerClick = markerRef.current.addListener('click', onClick);

      return () => {
        listenerClick.remove();
      };
    }
  }, []);

  useEffect(() => {
    rootRef.current = ReactDOM.render(children, container);
    markerRef.current.position = position;
    markerRef.current.map = map;
  }, [map, position, children]);

  return <></>;
};

const MapStations = () => {
  const center = { lat: -15.792487, lng: -47.893526 }; // epicenter of brazil
  const zoom = 4.8;
  const { data: stations, isLoading } = useGetList('stations', { pagination: { page: 1, perPage: null } });
  const [selectedStation, setSelectedStation] = useState<string | null>();
  const [stationInfoSideBar, setStationInfoSideBar] = useState<boolean>(false);

  const toggleDrawer = (open: boolean) => {
    setStationInfoSideBar(open);
  };

  const getStationLocations = useCallback(() => {
    return (Object.values(stations) || []).filter(station => station.latitude && station.longitude).map(station => ({
      id: station.id,
      imagePath: station.imagePath,
      name: station.name,
      position: { lat: station.latitude, lng: station.longitude }
    }));
  }, [stations]);

  const handleMarkerClick = (stationId: string) => {
    toggleDrawer(true);
    setSelectedStation(stationId);
  };

  const getStationInfo = (stationId: string) => {
    return stations?.find(s => s.id === stationId);
  };

  return (
    <React.Fragment>
      {
        selectedStation &&
        <StationInfoSideBar data={getStationInfo(selectedStation)} isVisible={stationInfoSideBar} togglerDrawer={toggleDrawer} />
      }
      {
        (isLoading === false && stations) ?
          <Wrapper
            apiKey={process.env.REACT_APP_MAP_API_KEY || 'AIzaSyA2-gtjUXGbaFwbFy8pmjTPgoV4pTLbx2g'}
            libraries={['marker']}
            render={render}
          >
            <MyMapComponent
              data={getStationLocations()}
              center={center}
              zoom={zoom}
              handleMarkerClick={handleMarkerClick}
            />
          </Wrapper>
          :
          <LoadingScreen text="Buscando postos" />
      }
    </React.Fragment>
  );
};
export default React.memo(MapStations);