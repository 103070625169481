import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useRef } from 'react';
import { ChipField, Datagrid, DateField, FunctionField, List, NumberField, ReferenceArrayField, ReferenceArrayInput, ReferenceField, ReferenceManyField, SelectArrayInput, Show, SingleFieldList, Tab, TabbedShowLayout, TextField, TopToolbar, useListContext, useRecordContext } from 'react-admin';
import { PrintButton, ReportPrint } from '../../components';
import { BetweenDatesInput } from '../../components/BetweenDatesInput';
import ListPagination from '../../components/ListPagination';
import { FillinOrigin } from '../../models';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  filterTablePrint: {
    display: 'none',
    '@media print': {
      display: 'block'
    },
    marginBottom: 10,
  },
  titleCard: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
}));


const FillinsDatagrids = (props) => {
  const classes = useStyles();
  const infos = useListContext();

  let data = [];
  let summaryData = [];

  if (infos.data) {
    const fillinInfo = infos.data[0];

    if (fillinInfo) {
      data = fillinInfo.data;
      const keys = ['total_value', 'total_amount', 'total_fillins', 'average_ticket', 'average_amount'];
      let total = { id: 'total' };

      keys.forEach(key => {
        total[key] = fillinInfo.fillinsByFuel.reduce((acc, value) => {
          return acc + Number(value[key]);
        }, 0);
      });

      summaryData = [total, ...fillinInfo.fillinsByFuel.sort((a, b) => (b.total_amount || 0) - (a.total_amount || 0))]
    }
  }

  return (
    <>
      <Typography className={classes.title}>Totais</Typography>
      <Datagrid
        bulkActionButtons={false}
        style={{ marginBottom: 30 }}
        data={summaryData}
        rowClick={false}
      >
        <TextField
          source="name"
          label="Combustível"
          emptyText="Todos"
          textAlign="center"
          sortable={false}
        />
        <NumberField
          source="total_value"
          label="Total em Dinheiro"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
        <NumberField
          source="total_amount"
          label="Total em Litros"
          textAlign="center"
          emptyText="0"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
          sortable={false}
        />
        <TextField source="total_fillins" textAlign="center" label="Total de abastecimentos" sortable={false} />
        <NumberField
          source="total_discounts"
          label="Total de descontos"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
        <NumberField
          source="average_ticket"
          label="Ticket Médio"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
        <NumberField
          source="average_amount"
          label="Volume Médio"
          textAlign="center"
          emptyText="0"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
          sortable={false}
        />
      </Datagrid>
      <Typography className={classes.title}>Abastecimentos</Typography>
      <Datagrid
        bulkActionButtons={false}
        resource={infos.resource}
        data={data}
        rowClick={false}
      >
        <NumberField source="amount" label="Quantidade em litros" textAlign="center" />
        <NumberField source="value" label="Valor pago" textAlign="center" options={{ style: 'currency', currency: 'BRL' }} />
        <ReferenceField
          label="Combustível"
          reference="fuels"
          source="fuelId"
          textAlign="center"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceManyField
          label="Posto"
          reference="station-attendants"
          target="id"
          sortable={false}
        >
          <StationField />
        </ReferenceManyField>
        <DateField source="createdAt" label="Data" textAlign="center" showTime />
        <FunctionField label="Desconto" textAlign="center" render={record => record && `${(record.oldPricePerLiter && record.pricePerLiter ? (record.oldPricePerLiter - record.pricePerLiter) * (record.amount || 0) : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`} />
        <FunctionField
          source="origin"
          label="Origem"
          render={record => FillinOrigin.translateOrigin(record ? record.origin : null)}
        />
        <ReferenceField
          label="Frentista"
          source="attendantId"
          reference="attendants"
          textAlign="center"
          emptyText="Não identificado"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </>
  );
};

const FilterTablePrint = ({ attendantName }) => {
  const classes = useStyles();
  const { filterValues } = useListContext();

  return (
    <Datagrid bulkActionButtons={false} data={[{ filters: { ...filterValues, attendantName } }]} total={1}>
      <DateField source="from" textAlign="center" label="De" sortable={false} />
      <DateField source="to" textAlign="center" label="Até" sortable={false} />
      <ReferenceArrayField label="Combustíveis" source="fuelIds" textAlign="center" basePath="fuels" reference="fuels">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField label="Postos" source="stationIds" textAlign="center" basePath="stations" reference="stations">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source="attendantName" label="Frentista" />
    </Datagrid>
  );
};

const ListFillins = (props) => {
  const attendant = useRecordContext();
  const classes = useStyles();
  const fillinsRef = useRef();

  const ListFillinsActions = () => (
    <TopToolbar>
      <PrintButton
        printRef={fillinsRef}
      />
    </TopToolbar>
  );

  const filterFillin = [
    <BetweenDatesInput alwaysOn />,
    <ReferenceArrayInput
      source="stationIds"
      reference="stations"
      perPage={null}
      sort={{ field: "name", order: "ASC" }}
      alwaysOn
    >
      <SelectArrayInput
        optionText={"name"}
        style={{ minWidth: 180 }}
        label="Postos"
      />
    </ReferenceArrayInput>,
    <ReferenceArrayInput
      source="fuelIds"
      reference="fuels"
      perPage={null}
      sort={{ field: "name", order: "ASC" }}
      alwaysOn
    >
      <SelectArrayInput
        optionText={"name"}
        style={{ minWidth: 180 }}
        label="Combustíveis"
      />
    </ReferenceArrayInput>
  ];

  return (
    <List
      title=" "
      basePath="fillins"
      resource="fillins"
      sort={{ field: "createdAt", order: "DESC" }}
      filterDefaultValues={{
        from: moment().startOf("day").subtract(1, "month").toISOString(),
        to: moment().endOf("day").toISOString(),
        attendantId: attendant.id,
      }}
      perPage={25}
      pagination={<ListPagination />}
      exporter={false}
      actions={<ListFillinsActions />}
      filters={filterFillin}
    >
      <ReportPrint ref={fillinsRef} title="Abastecimentos" landscape>
        <div className={classes.filterTablePrint}>
          <Typography className={classes.titleCard}>Filtros</Typography>
          <FilterTablePrint attendantName={attendant.name} />
        </div>

        <FillinsDatagrids {...props} bulkActionButtons={false} />
      </ReportPrint>
    </List>
  );
};


const AttendantShow = (props) => (
  <Show title="Frentista" {...props}>
    <TabbedShowLayout>
      <Tab label="Resumo">
        <TextField label="ID" source="id" />
        <TextField label="Nome" source="name" />
        <TextField label="Identifid" source="identfid" />
        <TextField label="Código" source="code" />
        <ReferenceManyField
          label="Posto"
          reference="station-attendants"
          target="id"
          sortable={false}
        >
          <Datagrid bulkActionButtons={false}>
            <ReferenceField label="Posto" source="placeId" reference="stations">
              <TextField source="name" />
            </ReferenceField>
            <DateField label="Último Abastecimento" source="lastFillin" showTime />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Abastecimentos" path="fillins">
        <ListFillins />
      </Tab>
    </TabbedShowLayout>

  </Show>
);

export default AttendantShow;
