import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Fragment, useState } from 'react';
import {
  ArrayInput,
  BooleanInput,
  Button,
  DeleteButton,
  Edit,
  FormDataConsumer,
  FormTab,
  ImageField,
  ImageInput,
  Labeled,
  maxValue,
  minValue,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  TimeInput,
  Toolbar,
  email,
  useDataProvider,
  useListContext,
  useNotify,
  usePermissions,
  useRecordContext,
  useRedirect,
  maxLength,
  DateInput,
  LoadingIndicator,
  useResourceContext,
  useRefresh,
} from 'react-admin';
import { Permission, PriceInput, Tooltip, Permissions } from '../../components';
import { CEPInput, CNPJInput, PhoneInput, RtiTokenInput, StateInput } from '../../components/Inputs';
import { MAX_DATE, PERMISSIONS, daysOfTheWeek, minimumValueCashbackToolTip } from '../../constants';
import { TagType, PaymentGatewayType, ConcentratorBrandType, FinancePaymentMethodType } from '../../models';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles(theme => ({
  credentialField: {
    display: 'block',
  },
  inputWrapperStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 0 20px 0',
  },
  input: {
    margin: '0 20px 0 0',
  },
  day: {
    fontSize: 16,
    margin: '20px 0'
  }
}));

const choices = [
  { id: 'company-tec', name: 'CompanyTec' },
  { id: 'ez-tec', name: 'EZTec' },
  { id: 'auto-system', name: 'AutoSystem' },
];

const required = (message = 'Required') => value =>
  value ? undefined : message;

export const FinancesStation = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const form = useFormContext();

  const handleChange = (retailerId) => {
    dataProvider.getOne("retailers", { id: retailerId })
      .then(({ data: retailer }) => {
        form.setValue('commissioningType', retailer.commissioningType);
        form.setValue('commissioningValue', retailer.commissioningValue);
      })
      .catch((err) => {
        notify('Não foi possível realizar a operação, tente novamente', { type: 'warning' });
      });
  };

  return (
    <>
      <Grid container lg={8} spacing={2}>
        <Grid item xs={12} md={6}>
          <PriceInput source="monthlyPrice" label="Valor da mensalidade" fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateInput source="billingStartDate" label="Data de início do faturamento" inputProps={{ max: MAX_DATE }} fullWidth />
        </Grid>
      </Grid>
      <Grid container lg={8} spacing={2}>
        <Grid item xs={12} md={4}>
          <ReferenceInput
            source="retailerId"
            reference="retailers"
            perPage={null}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <SelectInput
              label="Revenda"
              optionText={"name"}
              onChange={e => handleChange(e.target.value)}
              fullWidth
            />
          </ReferenceInput>
        </Grid>
        <FormDataConsumer>
          {({ formData }) => formData.retailerId &&
            <Grid item xs={12} md={4}>
              <SelectInput
                source="commissioningType"
                label="Tipo de comissão"
                choices={[{ id: 'fixed', name: 'Fixada' }, { id: 'percentage', name: 'Porcentagem' }]}
                validate={[required()]}
                fullWidth
              />
            </Grid>
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) => <>
            {formData.commissioningType === 'percentage' &&
              <Grid item xs={12} md={4}>
                <NumberInput
                  source="commissioningValue"
                  label="Valor da comissão"
                  min={0.01}
                  max={100}
                  step={0.01}
                  validate={[minValue(0.01), maxValue(100), required()]}
                  placeholder="%"
                  fullWidth
                />
              </Grid>
            }
            {formData.commissioningType === 'fixed' &&
              <Grid item xs={12} md={4}>
                <PriceInput
                  source="commissioningValue"
                  label="Valor da comissão"
                  validate={[minValue(0.01), required()]}
                  fullWidth
                />
              </Grid>
            }
          </>}
        </FormDataConsumer>
      </Grid>
      <Grid container lg={8} spacing={2}>
        <Grid item xs={12} md={6}>
          <TextInput source="emailInvoice" label="E-mail para envio da nota fiscal" validate={email()} fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput source="emailBill" label="E-mail para envio do boleto" validate={email()} fullWidth />
        </Grid>
      </Grid>
      <Grid container lg={8} spacing={2}>
        <Grid item xs={12} md={4}>
          <PriceInput
            source="installationCost"
            label="Valor da instalação"
            validate={[minValue(0.01)]}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <NumberInput source="monthlyDueDate" label="Vencimento da mensalidade (dia)" validate={[minValue(1), maxValue(31)]} fullWidth />
        </Grid>
        <Grid item xs={12} md={4}>
          <NumberInput source="gracePeriod" label="Período de carência (mês)" validate={[minValue(1)]} fullWidth />
        </Grid>
      </Grid>
      <Grid container lg={8} spacing={2}>
        <Grid item xs={12} md={4}>
          <SelectInput source="financePaymentMethod" label="Forma de pagamento" choices={FinancePaymentMethodType.financePaymentMethodChoices} fullWidth />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source="responsible" label="Responsável" fullWidth />
        </Grid>
        <Grid item xs={12} md={4}>
          <PhoneInput source="contactResponsible" label="Contato" fullWidth />
        </Grid>
      </Grid>
    </>
  );
};

export const EditStation = () => {
  const { permissions } = usePermissions();

  const classes = useStyles();

  const StationEditToolbar = props => {
    const [updating, setUpdating] = useState(false);
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const dataProvider = useDataProvider();
    const record = useRecordContext();
    if (!record) { return null; }
    const resource = useResourceContext();

    const saveInGash = () => {
      setUpdating(true);
      dataProvider.update(`${resource}/gash`, { id: record.id, data: {}, previousData: record })
        .then(() => notify("Posto Cadastrado com sucesso"))
        .catch((error) => notify(error.message, { type: 'warning' }))
        .finally(() => redirect('list', '/stations'));
    };

    const handleClick = () => {
      setUpdating(true);
      dataProvider.update(resource, {
        id: record.id,
        data: { isActive: !record?.isActive },
        previousData: { ...record }
      })
        .then(() => {
          notify(`Posto ${record?.isActive ? "desativado" : "ativado"} com sucesso`);
        }).catch(() => {
          notify(`Erro ao ${record?.isActive ? "desativar" : "ativar"} posto`, { type: 'warning' });
        }).finally(() => {
          setUpdating(false);
          refresh();
        });
    };

    return (
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
        <SaveButton />
        <Permission permission={PERMISSIONS.ADMIN}>
          <Button
            color='primary'
            startIcon={<EditIcon />}
            label="Cadastrar Posto no Gash"
            onClick={saveInGash}
            disabled={updating}
          />
        </Permission>
        <Permission permission={PERMISSIONS.ADMIN}>
          <Button
            startIcon={updating ? <LoadingIndicator /> : record?.isActive ? <RemoveCircleIcon /> : <AddCircleIcon />}
            label={record?.isActive ? 'Desativar' : 'Ativar'}
            onClick={handleClick}
            disabled={updating}
          />
        </Permission>
        {permissions && permissions.includes(PERMISSIONS.ADMIN) && (
          <DeleteButton />
        )}
      </Toolbar>
    );
  };

  const transform = (data) => {
    if (data.concentrators) {
      data.concentrators = data.concentrators.map(concentrator => ({ numericId: concentrator.numericId, brandType: concentrator.brandType }));
    }
    return data;
  }

  return (
    <Edit title="Editar posto" transform={transform}>
      <TabbedForm toolbar={<StationEditToolbar />}>
        {Permissions({
          permissions: [PERMISSIONS.REGISTER_STATION_IDENTIFICATION, PERMISSIONS.UPDATE_STATION],
          children: (
            <FormTab label="Identificação">
              <TextInput disabled label="ID" source="id" />
              <Permission permission={PERMISSIONS.REGISTER_STATION_IDENTIFICATION}>
                <ArrayInput source="numericIds" label="IDs Numéricos">
                  <SimpleFormIterator
                    disableReordering
                    getItemLabel={() => null}

                  >
                    <NumberInput label={''} />
                  </SimpleFormIterator>
                </ArrayInput>
              </Permission>
              <Grid container lg={8} md={12} spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextInput source="name" label="Nome" validate={required()} fullWidth />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CNPJInput source="cnpj" label="CNPJ" validate={required()} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    source="email"
                    label="Email"
                    type="email"
                    validate={email()}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <PhoneInput source="phone" label="Telefone" fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput source="corporateName" label="Razão Social" validate={[maxLength(120)]} fullWidth />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextInput source="stateRegistration" label="Incrição Estadual" validate={[maxLength(12)]} fullWidth />
                </Grid>
                {permissions && permissions.includes(PERMISSIONS.REGISTER_STATION_IDENTIFICATION) && (
                  <Grid item xs={12} md={10}>
                    <ReferenceInput
                      source="chainId"
                      reference="chains"
                      perPage={null}
                      sort={{ field: 'name', order: 'ASC' }}
                      validate={required()}
                    >
                      <SelectInput label="Rede" optionText="name" fullWidth />
                    </ReferenceInput>
                  </Grid>
                )}
                <Grid item xs={12} md={5}>
                  <ReferenceArrayInput
                    sort={{ field: 'name', order: 'ASC' }}
                    perPage={null}
                    source="fuelIds"
                    reference="fuels"
                  >
                    <SelectArrayInput label="Combustível" optionText="name" fullWidth />
                  </ReferenceArrayInput>
                </Grid>
                <Grid item xs={12} md={5}>
                  <ReferenceArrayInput
                    sort={{ field: 'name', order: 'ASC' }}
                    perPage={null}
                    source="serviceIds"
                    reference="services"
                  >
                    <SelectArrayInput label="Serviços" optionText="name" fullWidth />
                  </ReferenceArrayInput>
                </Grid>
                <Grid item xs={12} md={5}>
                  <FormDataConsumer>
                    {({ formData }) => (
                      <ReferenceArrayInput
                        sort={{ field: 'name', order: 'ASC' }}
                        filter={{ type: TagType.station, chainId: formData.chainId }}
                        perPage={null}
                        source="tagIds"
                        reference="chains/chainId/tag"
                      >
                        <SelectArrayInput label="Etiquetas" optionText="name" fullWidth />
                      </ReferenceArrayInput>
                    )}
                  </FormDataConsumer>
                </Grid>
                <Permission permission={PERMISSIONS.REGISTER_STATION_IDENTIFICATION}>
                  <Grid item xs={12} md={5}>
                    <RtiTokenInput
                      label="Token RTI"
                      source="rtiToken"
                      fullWidth
                    />
                  </Grid>
                </Permission>
              </Grid>
              <Permission permission={PERMISSIONS.REGISTER_STATION_IDENTIFICATION}>
                <BooleanInput
                  label="Preset por Valor (Encher tanque)"
                  source="presetUsingValue"
                />
              </Permission>
              <Grid container lg={8} md={12} spacing={2} alignItems="center">
                <Grid item xs={12} md={4}>
                  <BooleanInput label="Aceita cashback?" source="acceptsCashback" defaultValue={false} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Tooltip title={minimumValueCashbackToolTip} placement="top-start" sxContainer={{ display: "-webkit-box" }}>
                    <PriceInput source="minimumValueToRedeemCashback" label="Mínimo para resgate de cashback" sx={{ minWidth: 220 }} />
                  </Tooltip>
                </Grid>
              </Grid>
              <BooleanInput label="Usar preset no abastecimento" source="useFillinPreset" />
              <BooleanInput label="Usar níveis de preço" source="usePriceTiers" />
              <BooleanInput label="Usar integração com Software House" source="useIntegrationSoftwareHouse" />
              <BooleanInput label="Usar Acompanhamento de Abastecimento" source="useFillinFollowUp" />
              <BooleanInput label="Encher Tanque (999 litros)" source="usePresetLessThan1000" />
              <ImageInput source="imagefile" label="Imagem" accept="image/*">
                <ImageField source="src" label="Imagem" />
              </ImageInput>
            </FormTab>
          )
        })}
        {
          Permissions({
            permissions: [PERMISSIONS.REGISTER_STATION_CONCENTRATOR],
            children: (
              <FormTab label="Concentradores">
                <ArrayInput source="concentrators" label="IDs Numéricos">
                  <SimpleFormIterator
                    disableReordering
                    getItemLabel={() => null}
                  >
                    <NumberInput source="numericId" label="Id numérico" style={{ width: '260px' }} />
                    <SelectInput
                      validate={[required()]}
                      label="Sistema"
                      source="brandType"
                      choices={ConcentratorBrandType.concentratorBrandChoices}
                      style={{ width: '260px' }}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              </FormTab>
            )
          })
        }
        {Permissions({
          permissions: [PERMISSIONS.REGISTER_STATION_ADDRESS],
          children: (
            <FormTab label="Endereço">
              <Grid container lg={8} md={12} spacing={2}>
                <Grid item xs={12} md={8}>
                  <TextInput source="street" label="Rua" fullWidth />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextInput source="streetNumber" label="Número" fullWidth />
                </Grid>
              </Grid>
              <Grid container lg={8} md={12} spacing={2}>
                <Grid item xs={12} md={3}>
                  <CEPInput source="cep" label="CEP" fullWidth />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextInput source="neighbourhood" label="Bairro" fullWidth />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextInput source="city" label="Cidade" fullWidth />
                </Grid>
                <Grid item xs={12} md={3}>
                  <StateInput source="state" label="Estado" fullWidth />
                </Grid>
              </Grid>
              <Grid container lg={8} md={12} spacing={2}>
                <Grid item xs={12} md={3}>
                  <NumberInput source="latitude" label="Latitude" fullWidth />
                </Grid>
                <Grid item xs={12} md={3}>
                  <NumberInput source="longitude" label="Longitude" fullWidth />
                </Grid>
              </Grid>
            </FormTab>
          )
        })}
        {permissions && permissions.includes(PERMISSIONS.REGISTER_STATION_PAYMENT) && (
          <FormTab label='Pagamento'>
            <Grid container>
              <Grid item sm={4}>
                <ReferenceArrayInput
                  sort={{ field: 'name', order: 'ASC' }}
                  perPage={null}
                  source="appPaymentMethodIds"
                  reference="app-payment-methods"
                  fullWidth
                >
                  <SelectArrayInput
                    label="Método de pagamento"
                    optionText="name" />
                </ReferenceArrayInput>
                <SelectInput
                  source="paymentGateway"
                  label="Gateway de pagamento"
                  choices={PaymentGatewayType.paymentGatewayChoices}
                  fullWidth
                />
                <FormDataConsumer>
                  {({ formData: { paymentGateway } }) => {
                    switch (paymentGateway) {
                      case PaymentGatewayType.fixpay:
                        return <TextInput label="Token Fixpay" source="fixpayToken" fullWidth />;
                      case PaymentGatewayType.quality:
                        return (
                          <>
                            <TextInput style={{ display: "block" }} label="Conta da Quality" source="qualityAccount" fullWidth />
                            <TextInput style={{ display: "block" }} label="Chave da Quality" source="qualityKey" fullWidth />
                          </>
                        );
                      case PaymentGatewayType.conferePix:
                        return <TextInput label="Token Confere Pix" source="conferePixToken" fullWidth />;
                      default:
                        return <></>;
                    }
                  }}
                </FormDataConsumer>
              </Grid>
            </Grid>
          </FormTab>
        )}
        {Permissions({
          permissions: [PERMISSIONS.REGISTER_STATION_GPS, PERMISSIONS.UPDATE_STATION],
          children: (
            <FormTab label='GPS'>
              <Labeled label="Informe as coordenadas em 4 pontos que formam um retângulo">
                <FormDataConsumer>
                  {({ formData: { coordinates } }) =>
                    <ArrayInput source='coordinates' label=''>
                      <SimpleFormIterator disableReordering disableAdd={coordinates && coordinates.length === 4}>
                        <NumberInput source="latitude" label="Latitude" />
                        <NumberInput source="longitude" label="Longitude" />
                      </SimpleFormIterator>
                    </ArrayInput>
                  }
                </FormDataConsumer>
              </Labeled>
            </FormTab>
          )
        })}
        {permissions && permissions.includes(PERMISSIONS.REGISTER_STATION_SYNCHONIZATION) && (
          <FormTab label="Sincronização">
            <SelectInput
              validate={[required()]}
              label="Sistema"
              source="syncType"
              choices={choices}
              style={{ width: '260px' }}
            />
            <FormDataConsumer>
              {({ formData: { syncType } }) =>
                syncType === 'auto-system' && (
                  <>
                    <TextInput
                      source="credentials.host"
                      label="Host ou ip"
                      InputProps={{ autoComplete: 'off' }}
                      className={classes.credentialField}
                    />
                    <NumberInput
                      source="credentials.port"
                      label="Porta"
                      InputProps={{ autoComplete: 'off' }}
                      className={classes.credentialField}
                    />
                    <TextInput
                      source="credentials.user"
                      label="Usuário"
                      InputProps={{ autoComplete: 'nope' }}
                      className={classes.credentialField}
                    />
                    <TextInput
                      source="credentials.password"
                      label="Senha"
                      type="password"
                      InputProps={{ autoComplete: 'new-password' }}
                      className={classes.credentialField}
                    />
                    <TextInput
                      source="credentials.database"
                      label="Banco"
                      InputProps={{ autoComplete: 'off' }}
                      className={classes.credentialField}
                    />
                  </>
                )
              }
            </FormDataConsumer>
            {permissions && permissions.includes(PERMISSIONS.ADMIN) && (
              <FormDataConsumer>
                {({ formData: { syncType } }) =>
                  syncType === 'auto-system' &&
                  <TextInput
                    label="Último Ponto da Sincronização"
                    source="lastInsertedTransaction"
                    style={{ width: '260px' }}
                  />
                }
              </FormDataConsumer>
            )}
          </FormTab>)}
        {Permissions({
          permissions: [PERMISSIONS.REGISTER_STATION_OPENING_HOURS, PERMISSIONS.UPDATE_STATION],
          children: (
            <FormTab label="Horários">
              <FormDataConsumer>
                {({ formData }) => {
                  if (formData.openingHours && formData.openingHours.length !== 7) {
                    const registeredDays = formData.openingHours.map(record => record.dayOfTheWeek);
                    const notRegisteredDays = [...daysOfTheWeek].filter(wd => !registeredDays.includes(wd.id));
                    const openingHours = [...formData.openingHours];
                    notRegisteredDays.forEach(nrd => {
                      openingHours.push({ dayOfTheWeek: nrd.id, closed: true });
                    });
                    formData.openingHours = openingHours.sort((a, b) => {
                      if (a.dayOfTheWeek > b.dayOfTheWeek) {
                        return 1;
                      } else if (a.dayOfTheWeek < b.dayOfTheWeek) {
                        return -1;
                      }
                      return 0;
                    });
                  }
                  return (
                    <ArrayInput source="openingHours" label="">
                      <SimpleFormIterator disableAdd disableRemove disableReordering>
                        <FormDataConsumer>
                          {({ scopedFormData }) => {
                            const day = daysOfTheWeek.find(wd => wd.id === scopedFormData.dayOfTheWeek).name;
                            return (
                              <p className={classes.day}>{day}</p>
                            );
                          }}
                        </FormDataConsumer>
                        <FormDataConsumer>
                          {({ scopedFormData, getSource }) => {
                            return (
                              <div className={classes.inputWrapperStyle}>
                                <BooleanInput
                                  source={getSource && getSource("closed")}
                                  label='Fechado'
                                  className={classes.input}
                                />
                                {scopedFormData && !scopedFormData.closed &&
                                  <BooleanInput
                                    source={getSource && getSource("open24hrs")}
                                    label='Aberto 24 horas'
                                    className={classes.input}
                                  />
                                }
                                {scopedFormData && !scopedFormData.closed && !scopedFormData.open24hrs &&
                                  <Fragment>
                                    <TimeInput
                                      record={scopedFormData}
                                      variant='filled'
                                      label='Hora de abertura'
                                      source={getSource && getSource("startTime")}
                                      style={{ margin: '0 20px 0 0' }}
                                      fullDate
                                    />
                                    <TimeInput
                                      record={scopedFormData}
                                      variant='filled'
                                      label='Hora de fechamento'
                                      source={getSource && getSource("endTime")}
                                      fullDate
                                    />
                                  </Fragment>
                                }
                              </div>
                            );
                          }}
                        </FormDataConsumer>
                      </SimpleFormIterator>
                    </ArrayInput>
                  );
                }}
              </FormDataConsumer>
            </FormTab>
          )
        })}
        <Permission permission={PERMISSIONS.REGISTER_STATION_FINANCIAL}>
          <FormTab label="Financeiro">
            <FinancesStation />
          </FormTab>
        </Permission>
      </TabbedForm>
    </Edit>
  );
};

export default EditStation;
