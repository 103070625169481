export enum TransactionType {
  adition = 'adition',
  manualAddition = 'manual_addition',
  refuel = 'refuel',
  refuelNSale = 'refuel_n_sale',
  manualRefuel = 'manual_refuel',
  canceled = 'canceled',
  discount = 'discount',
  refund = 'refund',
  expiration = 'expiration',
  referral = 'referral',
  marketing = 'marketing',
  deletion = 'deletion',
  redemption = 'redemption',
  drawing = 'drawing',
  sale = 'sale',
};

export namespace TransactionType {
  export const transalateType = (type: TransactionType): string => {
    switch (type) {
      case TransactionType.expiration:
        return "Expiração"
      case TransactionType.adition:
        return "Adição";
      case TransactionType.refuel:
        return "Abastecimento";
      case TransactionType.redemption:
        return "Resgate";
      case TransactionType.refund:
        return "Estorno";
      case TransactionType.canceled:
        return "Cancelamento";
      case TransactionType.sale:
        return "Venda de Produto";
      case TransactionType.refuelNSale:
        return "Abastecimento e Compra";
      case TransactionType.discount:
        return "Desconto";
      case TransactionType.marketing:
        return "Recompensa";
      case TransactionType.deletion:
        return "Remoção";
      case TransactionType.drawing:
        return "Sorteio";
      case TransactionType.manualAddition:
        return "Adição Manual";
      case TransactionType.manualRefuel:
        return "Abastecimento Manual";
      case TransactionType.referral:
        return "Indicação";
      default: return "";
    };
  };

  export const transactionTypeChoices = Object.values(TransactionType).filter(x => typeof x === 'string')
    .map(type => ({ id: type, name: TransactionType.transalateType(type) }))
};

