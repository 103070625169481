export enum RatingPollItemType {
  attendant = 'attendant',
  station = 'station',
}

export namespace RatingPollItemType {
  export const translateType = (type: RatingPollItemType): string => {
    switch (type) {
      case RatingPollItemType.attendant:
        return "Frentista";
      case RatingPollItemType.station:
        return "Posto";
      default:
        return "";
    }
  };

  export const ratingPollItemTypeChoices = Object.values(RatingPollItemType)
    .filter(x => typeof x === "string")
    .map((type) => ({
      id: type,
      name: RatingPollItemType.translateType(type),
    }));
}