import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { DateTimeInput, useDataProvider, useNotify } from 'react-admin';
import { DropDownPartners, RowFilter, TableGeneric, Wrapper } from '../../components';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { getPlaceId } from '../../lib';


const useStyles = makeStyles({
    scrollbar: {
        width: '160vw',
        height: 20
    },
    scrollbarWrapper: {
        width: '75vw',
        overflowX: 'scroll',
        overflowY: "hidden",
        height: 20,
        margin: '30px auto 0 auto'
    }
});

const handleScroll = props => {
    const scrollbar = document.getElementById('productsStockReportScrollbarTop')
    const table = document.getElementById('productsStockReportScrollableTable')
    if (props === 'scrollbar') {
        table.scrollLeft = scrollbar.scrollLeft;
    } else {
        scrollbar.scrollLeft = table.scrollLeft;
    }
}

const StockTable = ({ data }) => {
    const classes = useStyles();
    return (
        <>
            <TableGeneric
                headCells={[
                    'Total',
                    'Entrada',
                    'Baixa',
                    'Transferência',
                    'Resgate',
                    'Ajuste',
                    'Estorno'
                ]}
                arrayData={data.resume}
                style={{ width: '75vw', margin: '20px auto 0 auto' }}
                cells={6}
                title="Totais"
            />
            <div className={classes.scrollbarWrapper} onScroll={() => handleScroll('scrollbar')} id="productsStockReportScrollbarTop">
                <div className={classes.scrollbar} />
            </div>
            <TableGeneric
                headCells={[
                    'Produto',
                    'Local',
                    'Estoque',
                    'Data',
                    'Valor',
                    'Quantidade',
                    'Tipo',
                    'Funcionário',
                    'Descrição',
                    'Destino',
                    'Est. Destino',
                    'Cliente'
                ]}
                arrayData={data.tableData}
                style={{ width: '75vw', overflowX: 'scroll', margin: '0 auto', maxHeight: '85vh' }}
                table={{
                    style: { width: '160vw' },
                    stickyHeader: true
                }}
                cells={11}
                title="Movimentações do Estoque"
                id="productsStockReportScrollableTable"
                onScroll={handleScroll}
            />
        </>
    )
}

export default props => {
    const [dateFrom, setDateFrom] = useState(moment().startOf('days'))
    const [dateTo, setDateTo] = useState(moment().endOf('days'))
    const [places, setPlaces] = useState(null)
    const [products, setProducts] = useState(null)
    const [placeProducts, setPlaceProducts] = useState(null)
    const [data, setData] = useState(null)
    const [selectedPlace, setSelectedPlace] = useState(getPlaceId() || null)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [transactionType, setTransactionType] = useState(null)
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const fetchPlaces = async () => {
        dataProvider.getList('chains/chainId/places').then(response => {
            const formattedResponse = response.data
                .map(({ id, name }) => ({ id, name }))
                .sort((a, b) => { return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0; })
            setPlaces(formattedResponse);
        })
    }

    const fetchProducts = async () => {
        const filter = { stock: true }

        dataProvider.getList('chains/chainId/products', { filter }).then((response) => {
            const formattedResponse = response.data
                .map(({ id, name }) => ({ id, name }))
                .sort((a, b) => { return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0; })
            setProducts(response.data);
            setPlaceProducts(formattedResponse)
        });
    }

    const fetchData = async () => {
        const filter = {
            from: moment.utc(dateFrom),
            to: moment.utc(dateTo),
            placeId: selectedPlace || undefined,
            productId: selectedProduct || undefined,
            transactionType: transactionType || undefined
        }

        dataProvider.getList('chains/chainId/stock-statement', { filter }).then((response) => {
            const tableData = [];

            var total = 0, addtion = 0, reducion = 0, transfer = 0, redemption = 0, refund = 0, adjustment = 0;

            response.data.forEach(transaction => {
                total += 1;
                let type;

                if (transaction.type === 'adition') {
                    addtion += 1;
                    type = 'Entrada';
                } else if (transaction.type === 'reducion') {
                    reducion += 1;
                    type = 'Baixa';
                } else if (transaction.type === 'transfer') {
                    transfer += 1;
                    type = 'Transferência';
                } else if (transaction.type === 'redemption') {
                    redemption += 1;
                    type = 'Resgate';
                } else if (transaction.type === 'refund') {
                    refund += 1;
                    type = 'Estorno';
                } else {
                    adjustment += 1;
                    type = 'Ajuste';
                }

                tableData.push({
                    product: transaction.productname,
                    place: transaction.placename,
                    stock: transaction.stock_type === 'warehouse' ? 'Depósito central' : 'Mostruário',
                    date: moment(transaction.reference_date).format('DD/MM/YYYY HH:mm'),
                    value: transaction.value,
                    quantity: transaction.balance,
                    type,
                    employee: transaction.employeename ? transaction.employeename : '-',
                    description: transaction.description ? transaction.description : '-',
                    transferPlace: transaction.transferplacename ? transaction.transferplacename : '-',
                    transferStock: transaction.transfer_stock_type ? transaction.transfer_stock_type === 'warehouse' ? 'Depósito central' : 'Mostruário' : '-',
                    customer: transaction.cpf ? transaction.cpf : '-',
                })
            })

            const resume = [{ total, addtion, reducion, transfer, redemption, adjustment, refund }]

            setData({ tableData, resume })
        });
    }

    useEffect(() => {
        if (getPlaceId()) {
            setSelectedPlace(getPlaceId())
        } else {
            fetchPlaces();
        }
        fetchProducts();
    }, []);


    useEffect(() => {
        if (products) {
            if (selectedPlace) {
                setPlaceProducts(
                    products
                        .filter(p => p.productPlaces.find(pp => pp.placeId === selectedPlace))
                        .map(({ id, name }) => ({ id, name }))
                )
            } else {
                setPlaceProducts(products.map(({ id, name }) => ({ id, name })))
            }
        }
    }, [selectedPlace])

    useEffect(() => {
        fetchData();
    }, [dateTo, dateFrom, selectedPlace, selectedProduct, transactionType])

    return (
        <Permission permission={PERMISSIONS.STOCK_CHANGES_REPORT}>
            <Wrapper>
                <RowFilter style={{ marginLeft: '40px' }}>
                    <DateTimeInput
                        label='De'
                        ampm={false}
                        disableFuture
                        maxDate={dateTo}
                        parse={(value) => moment(value).toISOString()}
                        defaultValue={moment().startOf('day').toISOString()}
                        onChange={date => setDateFrom(date)}
                    />,
                    <DateTimeInput
                        label='Até'
                        ampm={false}
                        disableFuture
                        minDate={dateFrom}
                        parse={(value) => moment(value).toISOString()}
                        defaultValue={moment().endOf('day').toISOString()}
                        onChange={date => setDateTo(date)}
                    />,
                    <>{!getPlaceId() &&
                        <DropDownPartners
                            style={{ width: '150px' }}
                            label="Ponto de troca"
                            value={selectedPlace}
                            setValue={setSelectedPlace}
                            arrayData={places}
                        />
                    }
                    </>
                    <DropDownPartners
                        style={{ width: '150px' }}
                        label="Produto"
                        value={selectedProduct}
                        setValue={setSelectedProduct}
                        arrayData={placeProducts}
                    />
                    <DropDownPartners
                        style={{ width: '150px' }}
                        label="Tipo"
                        value={transactionType}
                        setValue={setTransactionType}
                        arrayData={[
                            { id: 'adition', name: 'Entrada' },
                            { id: 'reducion', name: 'Baixa' },
                            { id: 'transfer', name: 'Transferência' },
                            { id: 'redemption', name: 'Resgate' },
                            { id: 'adjustment', name: 'Ajuste' },
                            { id: 'refund', name: 'Estorno' },
                        ]}
                    />
                </RowFilter>
                {data && <StockTable data={data} />}
            </Wrapper>
        </Permission>
    );
}