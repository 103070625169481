import { DayScheduledDiscount } from "./dayScheduledDiscount";

export class FuelDiscount {
  overlapsStandardDiscount: boolean | undefined;
  fuelId: string | undefined;
  discount: number | undefined;
  points: number | undefined;
  amount: number | undefined;
  uniqueDiscountValue: boolean | undefined;

  dayScheduledDiscounts: DayScheduledDiscount[] = [];
  overlapsGroupDiscount: boolean | undefined;

  alterPriceType: string | undefined;
  alterPriceValueType: string | undefined;
  alterPriceValue: number | undefined;

  usageLimit: number | undefined;
  usageCount: number | undefined;
  intervalInHours: number | undefined;

  static createFuelDiscount(value: FuelDiscount) {
    return {
      fuelId: value.fuelId,
      alterPriceValue: value.alterPriceValue,
      alterPriceType: value.alterPriceType,
      alterPriceValueType: value.alterPriceValueType,
      points: value.points,
      uniqueDiscountValue: value.uniqueDiscountValue,
      amount: value.amount,
    };
  }

  constructor(init?: Partial<FuelDiscount>) {
    Object.assign(this, init);
  }
}