import ListIcon from '@mui/icons-material/List';
import { PERMISSIONS } from '../../constants';
import list from './BillingsList';
import create from './BillingsCreate';
import edit from './BillingsEdit';

export default {
    list,
    create,
    edit,
    name: 'billings',
    permissions: [PERMISSIONS.BILLING],
    options: { label: 'Faturamento' },
    icon: ListIcon,
}