import React from 'react'
import {
  List,
  Filter,
  Datagrid,
  TextField,
  ImageField,
  SearchInput,
  BulkDeleteButton,
  BooleanInput,
  ReferenceField
} from 'react-admin'
import { makeStyles } from '@mui/styles'
import ListPagination from '../../components/ListPagination'
import RequiredChain from '../../components/RequiredChain'

const Filters = props => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn />
    <BooleanInput label="Desativados" source="isDisabled" sx={{ height: 36 }} alwaysOn />
  </Filter>
)

const listStyles = makeStyles({
  image: {
    '& img': {
      maxWidth: '4rem',
    },
  },
})

const BulkActionButtons = props => (
  <BulkDeleteButton {...props} label="Inativar" />
)

const list = ({ ...props }) => {
  const classes = listStyles();

  return (
    <RequiredChain>
      <List
        {...props}
        perPage={25}
        hasCreate
        pagination={<ListPagination />}
        exporter={false}
        title="Produtos"
        filters={<Filters />}
        filterDefaultValues={{ isDisabled: false }}
      >
        <Datagrid rowClick="edit" bulkActionButtons={<BulkActionButtons />}>
          <ImageField className={classes.image} source="imagePath" label="Imagem" />
          <TextField source="name" label="Nome" />
          <TextField source="description" label="Descrição" />
          <ReferenceField
            label="Categoria"
            reference="chains/chainId/product-categories"
            source="categoryId">
            <TextField source="name" />
          </ReferenceField>
        </Datagrid>
      </List>
    </RequiredChain>
  )
}

export default list
