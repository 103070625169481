export enum PendingTransanctionStatus {
  aproved = 'aproved',
  pending = 'pending',
  reproved = 'reproved',
}

export namespace PendingTransanctionStatus {
  export const translateType = (type: PendingTransanctionStatus): string => {
    switch (type) {
      case PendingTransanctionStatus.aproved:
        return "Aprovado";
      case PendingTransanctionStatus.pending:
        return "Pendente";
      case PendingTransanctionStatus.reproved:
        return "Reprovado";
      default:
        return "";
    }
  };

  export const pendingTransanctionChoices = Object.values(PendingTransanctionStatus)
    .filter(x => typeof x === "string")
    .map((type) => ({
      id: type,
      name: PendingTransanctionStatus.translateType(type),
    }));
}