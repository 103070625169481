import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateTimeInput, useDataProvider, useNotify } from 'react-admin';
import { DropDownPartners, Loader, ReportLayout, TableGeneric } from '../../components';
import { PERMISSIONS } from '../../constants';


const ReportRealPrice = () => {
    const [from, setFrom] = useState(moment().startOf('days'));
    const [to, setTo] = useState(moment().endOf('days'));
    const [groupBy, setGroupBy] = useState('company');
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState();
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const filters = [{
        from: from.format('DD/MM/YYYY HH:mm'),
        to: to.format('DD/MM/YYYY HH:mm'),
        group: groupBy === 'company' ? 'Empresa' : 'Produto'
    }];

    useEffect(() => {
        setLoading(true);
        dataProvider.getList('chains/chainId/reports/real-price', { filter: { from, to, groupBy } })
            .then((res) => { setResponse(res); })
            .catch((e) => {
                notify('Erro ao buscar relatório de preço real', { type: 'warning' });
                throw Error(e.message ?? e);
            })
            .finally(() => { setLoading(false); });
    }, [from, to, groupBy]);

    return (
        <ReportLayout
            permission={PERMISSIONS.REAL_PRICE_REPORT}
            title='Relatório de Preço Real'
            filterTablePrint={
                <TableGeneric
                    headCells={[
                        'De',
                        'Até',
                        'Agrupar por'
                    ]}
                    arrayData={filters}
                    title="Filtros"
                    style={{ width: '100%', margin: '1em 0' }}
                />
            }
            filters={[
                <DateTimeInput
                    label='De'
                    ampm={false}
                    disableFuture
                    maxDate={to}
                    parse={(value) => moment(value).toISOString()}
                    defaultValue={moment().startOf('day').toISOString()}
                    onChange={date => setFrom(date)}
                />,
                <DateTimeInput
                    label='Até'
                    ampm={false}
                    disableFuture
                    minDate={from}
                    parse={(value) => moment(value).toISOString()}
                    defaultValue={moment().endOf('day').toISOString()}
                    onChange={date => setTo(date)}
                />,
                <DropDownPartners
                    label="Agrupar por:"
                    value={groupBy}
                    setValue={setGroupBy}
                    arrayData={[{ id: 'company', name: 'Empresa' }, { id: 'product', name: 'Produto' }]}
                />
            ]}
            content={loading ? <Loader /> : response && response.data.map(record => {
                return (
                    <TableGeneric
                        title={record.name}
                        headCells={['', '']}
                        style={{ width: '100%', margin: '1em 0' }}
                        arrayData={record.data.map(({ name, realPrice }) => ({ name, totalValue: realPrice }))}
                    />
                )
            })}
        />
    );
};

export default ReportRealPrice;
