import * as React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const CustomSelect = ({ choices, label, value, onChange, multiple, variant, small, style = {} }) => {
  const handleChange = ({ target }: { [key: string]: any }) => onChange(target.value);

  return (
    <FormControl style={{ minWidth: multiple ? 300 : 160, ...style }} size={small ? "small" : "medium"}>
      <InputLabel style={{ marginLeft: 12, marginTop: -4 }}>{label}</InputLabel>
      <Select
        variant={variant || 'outlined'}
        multiple={multiple}
        value={value}
        onChange={handleChange}
        input={multiple && <OutlinedInput label={label} />}
        renderValue={(selected: any) => {
          if (multiple) {
            return (
              <Box style={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((optionId) => {
                  const { name } = choices.find(({ id }) => id === optionId);
                  return (
                    <Chip key={optionId} label={name} />
                  );
                })}
              </Box>);
          } else {
            const { name } = choices.find(({ id }) => id === selected);
            return name;
          }
        }}
      >
        {choices.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CustomSelect;
