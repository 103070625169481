export enum CouponOrigin {
  app = 'app',
  manuallyAdded = 'manually_added',
  redemptionSystem = 'redemption_system',
};

export namespace CouponOrigin {
  export const translateOrigin = (origin: CouponOrigin): string => {
    switch (origin) {
      case CouponOrigin.app:
        return "Aplicativo";
      case CouponOrigin.manuallyAdded:
        return "Adicionado Manualmente";
      case CouponOrigin.redemptionSystem:
        return "Sistema de Resgate";
      default: return "";
    };
  };

  export const CouponOriginChoices = Object.values(CouponOrigin).filter(x => typeof x === "string")
    .map(type => {
      return ({
        id: type,
        name: CouponOrigin.translateOrigin(type)
      });
    });
};
