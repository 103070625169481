import React, { useState, useEffect } from 'react'
import { PERMISSIONS, DEFAULT_API_ADMIN } from '../../constants'
import { TextField } from '@mui/material';
import {
    Loader,
    TableGeneric,
    DropDownPartners,
    ReportLayout
} from '../../components'
import { to, getChainId, getPlaceId, get } from '../../lib'
import { useNotify, Button } from 'react-admin';

const PurchaseSuggestion = ({ data }) => {
    return (
        <TableGeneric
            headCells={[
                'Produto',
                'Local',
                'Estoque atual',
                'Sugestão de compra'
            ]}
            arrayData={data}
            title="Sugestão de compra"
            style={{ width: '100%' }}
        />
    )
}

export default function ReportPurchaseSuggestion() {
    const [products, setProducts] = useState();
    const [placeProducts, setPlaceProducts] = useState();
    const [selectedProduct, setSelectedProduct] = useState();
    const [places, setPlaces] = useState();
    const [selectedPlace, setSelectedPlace] = useState(getPlaceId());
    const [productPlaces, setProductPlaces] = useState();
    const [purchaseSuggestion, setPurchaseSuggestion] = useState()
    const [previousInterval, setPreviousInterval] = useState(30)
    const [nextInterval, setNextInterval] = useState(30)
    const [loading, setLoading] = useState()
    const notify = useNotify();

    const fetchProducts = async () => {
        const filter = { stock: true, hasMinimumStock: true }

        const [error, response] = await to(get(`${DEFAULT_API_ADMIN}/chains/${getChainId()}/products?filter=${JSON.stringify(filter)}`))

        if (error) {
            notify('Erro ao carregar produtos da rede', { type: 'warning' })
            return
        }

        setProducts(response.data);

        if (selectedPlace) {
            setPlaceProducts(response.data.filter(p => p.productPlaces.find(pp => pp.placeId === selectedPlace)))
        } else {
            setPlaceProducts(response.data);
        }
        return
    }

    const fetchPlaces = async () => {
        const filter = { chainId: getChainId() }
        const order = { name: "ASC" }

        const [error, response] = await to(get(`${DEFAULT_API_ADMIN}/places?filter=${JSON.stringify(filter)}&order=${JSON.stringify(order)}`))

        if (error) {
            notify('Erro ao carregar locais da rede', { type: 'warning' })
            return
        }

        setPlaces(response.data);
        setProductPlaces(response.data);
        return
    }

    const fetchData = async () => {
        const filter = {
            previousInterval: +previousInterval || 0,
            nextInterval: +nextInterval || 0,
            placeId: selectedPlace || undefined,
            productId: selectedProduct || undefined
        }

        const [error, response] = await to(get(`${DEFAULT_API_ADMIN}/chains/${getChainId()}/reports/purchase-suggestion?filter=${JSON.stringify(filter)}`))

        if (error) {
            notify('Erro ao carregar dados do relatório', { type: 'warning' })
            return
        }

        setPurchaseSuggestion(response.productResponse)
        return
    }

    const onFetchData = async () => {
        setLoading(true)
        await fetchData()
        setLoading(false)
    }

    const onPlaceChange = newValue => {
        setLoading(true)
        setSelectedProduct(null)
        setSelectedPlace(newValue)
        const productsPlace = newValue ? products.filter(p => p.productPlaces.find(pp => pp.placeId === newValue)) : products;
        setPlaceProducts(productsPlace)
        setLoading(false)
    }

    useEffect(() => {
        const onInit = async () => {
            setLoading(true)
            if (!selectedPlace) { await fetchPlaces(); }
            await fetchProducts();
            setLoading(false)
        }
        onInit()
    }, [])

    const filters = [{
        previousInterval: `${previousInterval} dias`,
        nextInterval: `${nextInterval} dias`,
        place: selectedPlace && places ? places.find(p => p.id === selectedPlace).name : 'Todos',
        product: selectedProduct ? placeProducts.find(p => p.id === selectedProduct).name : 'Todos'
    }]

    return (
        <ReportLayout
            permission={PERMISSIONS.PURCHASE_SUGGESTION_REPORT}
            title='Relatório de sugestão de compra'
            actions={[
                <Button
                    variant="contained"
                    onClick={onFetchData}
                    label="Pesquisar"
                />
            ]}
            filterTablePrint={
                <TableGeneric
                    headCells={[
                        'Considerar últimos',
                        'Durar por mais',
                        'Local',
                        'Produto'
                    ]}
                    arrayData={filters}
                    title="Filtros"
                    style={{ width: '100%', margin: '1em 0' }}
                />
            }
            filters={[
                <TextField
                    type="number"
                    label={`Basear nos últimos ${previousInterval} dias`}
                    value={previousInterval}
                    onChange={event => setPreviousInterval(event.target.value)}
                    variant='filled'
                />,
                <TextField
                    type="number"
                    label={`Durar pelos próximos ${nextInterval} dias`}
                    value={nextInterval}
                    onChange={event => setNextInterval(event.target.value)}
                    variant='filled'
                />,
                <>
                    {!getPlaceId() &&
                        <DropDownPartners
                            label="Local de troca"
                            value={selectedPlace}
                            setValue={onPlaceChange}
                            arrayData={productPlaces}
                        />}
                </>,
                <DropDownPartners
                    label="Produto"
                    value={selectedProduct || null}
                    setValue={setSelectedProduct}
                    arrayData={placeProducts}
                />
            ]}
            content={loading ? <Loader /> : purchaseSuggestion ? <PurchaseSuggestion data={purchaseSuggestion} /> : null}
        />
    )
}