import React from 'react';

interface GenericDatagridFieldProps {
    label: string | undefined;
    source: string | undefined;
    render: Function | undefined; 
    unsortable: Boolean | undefined;
    emptyText: string | undefined;
};

const GenericDatagridField = ({ label, source, render, unsortable, emptyText}: GenericDatagridFieldProps) =>
    React.cloneElement(<div />, { label, source, render, unsortable, emptyText });

export default GenericDatagridField;