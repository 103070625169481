import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FileField, FileInput, NumberInput, required, useInput } from 'react-admin';
import AdPreviewOnMobile from '../adPreviewOnMobile';

const FormUpload: React.FC<{ formData?: any }> = ({ formData }) => {

  const MAX_SIZE = 10000000; // 10MB

  const durationInput = useInput({ source: 'duration' });
  const fileInput = useInput({ source: 'file' });

  const [fileError, setFileError] = useState<string | null>(null);

  const convertBitToMb = (value: number) => {
    return `${value / 1000000}MB`;
  }

  const getVideoDuration = (file: File) => new Promise((resolve, reject) => {
    try {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = () => {
        const duration = video.duration;

        //clear
        video.remove();
        URL.revokeObjectURL(video.src);

        resolve(duration);
      };

      video.src = URL.createObjectURL(file);
    } catch (e) {
      resolve(null);
    }
  });

  const handleUploadFile = async (file: File) => {
    setFileError(null);

    if (!file) {
      durationInput.field.onChange(null);
    } else if (file.type.startsWith('video/')) {
      if (file.size > MAX_SIZE) {
        fileInput.field.onChange(null);
        setFileError('Tamanho do arquivo excede 10 MB. Por favor, escolha um arquivo menor.');
        return null;
      }

      const duration = await getVideoDuration(file);
      durationInput.field.onChange(duration);
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <FileInput
            source="file"
            label="Arquivo"
            accept="video/*,image/*"
            onChange={handleUploadFile}
          >
            <FileField source="src" title="title" />
          </FileInput>
          {
            fileError &&
            <Typography sx={{ marginBottom: 4, color: 'red' }}>{fileError}</Typography>
          }
          <Typography sx={{ marginBottom: 4 }}>Os vídeos serão reproduzidos sem áudio no aplicativo</Typography>
          <Typography sx={{ marginBottom: 4 }}>{`Permitido arquivos de até ${convertBitToMb(MAX_SIZE)}`}</Typography>

          {
            formData.file &&
            <NumberInput
              source="duration"
              label="Duração (segundos)"
              disabled={formData.file.rawFile.type?.startsWith('video/')}
              format={(val: number) => Math.floor(val)}
              isRequired
              validate={required()}
            />
          }
        </Grid>
        <Grid item xs={4}>
          <AdPreviewOnMobile file={formData.file} />
        </Grid>
      </Grid>
    </>
  );
}

export default FormUpload;