import SaveIcon from '@mui/icons-material/Save';
import React, { MouseEventHandler, useCallback, useState } from 'react';
import { Button, Confirm, useSaveContext } from 'react-admin';
import { useFormContext, useFormState } from 'react-hook-form';

interface SaveConfirmButtonProps {
  formData: any,
  validate?: (data: any) => boolean,
  contentMessage: React.ReactNode | string,
  [k: string]: any,
}

const SaveConfirmButton: React.FC<SaveConfirmButtonProps> = ({ formData, validate, ...props }) => {

  const [confirmDialog, setConfirmDialog] = useState(false);
  const saveContext = useSaveContext();
  const form = useFormContext();
  const { dirtyFields, isValidating, isSubmitting } = useFormState();
  const isDirty = Object.keys(dirtyFields).length > 0;
  const [isSaving, setIsSaving] = useState(false);

  const disabled = !isDirty || isValidating || isSubmitting || isSaving;

  const handleSubmit = useCallback(
    async (values: any) => {
      if (saveContext?.save) {
        setIsSaving(true);
        await saveContext.save(values);
        setIsSaving(false);
      }
    },
    [saveContext]
  );

  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    async event => {
      if (event.defaultPrevented) {
        return;
      }

      event.stopPropagation();

      await form.handleSubmit((validate && validate(formData) || !validate) ? handleOpenDialog : handleSubmit)(event);
    },
    [form, handleSubmit]
  );

  const handleOpenDialog = () => {
    setConfirmDialog(true);
  }

  const handleCloseDialog = () => {
    setConfirmDialog(false);
  }

  const handleConfirmDialog = async () => {
    handleCloseDialog();
    handleSubmit(formData);
  }

  return (
    <>
      <Button
        onClick={handleClick}
        disabled={disabled}
        size="medium"
        variant="contained"
        startIcon={<SaveIcon />}
        label="Salvar"
      />
      <Confirm
        isOpen={confirmDialog}
        title="Atenção"
        content={props.contentMessage}
        onConfirm={handleConfirmDialog}
        onClose={handleCloseDialog}
      />
    </>
  );
}

export default SaveConfirmButton;