import { Button, Confirm } from 'ra-ui-materialui';
import React, { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import { Typography } from '@mui/material';
import { useDataProvider, useNotify, useRecordContext, useRefresh } from 'react-admin';
import { BulkSendStatus } from '../../models';

const ResendButton: React.FC = () => {

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const record = useRecordContext();
  const refresh = useRefresh();

  const [open, setOpen] = useState(false);
  const [isSendLoading, setIsSendLoading] = useState(false);

  const handleDialogClose = () => {
    setOpen(false);
  }

  const handleConfirm = () => {
    handleDialogClose();
    setIsSendLoading(true);

    dataProvider.create('bulk-sends/resend', { data: { bulkSendId: record.bulkSendId, customerIds: [record.customerId], customerStatus: BulkSendStatus.failed } })
      .then(() => {
        notify('Reenvio realizado.');
        refresh();
      })
      .catch(() => {
        notify('Erro ao realizar o reenvio', { type: 'error' });
      })
      .finally(() => {
        setIsSendLoading(false);
      });
  }

  const handleClick = () => {
    setOpen(true);
  }

  return (
    record.status === BulkSendStatus.failed ?
      <>
        <Confirm
          isOpen={open}
          title="Reenvio"
          content={
            <Typography variant="body1" color="rgba(0,0,0,0.6)">
              Ao confirmar, será realizado um novo envio para este cliente.
            </Typography>
          }
          onConfirm={handleConfirm}
          onClose={handleDialogClose}
        />
        <Button
          label="Reenviar"
          startIcon={<SendIcon />}
          onClick={handleClick}
          disabled={isSendLoading}
        />
      </>
      :
      <></>
  );
}

export default ResendButton;