import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Permission from "../Permission";
import { PERMISSIONS } from "../../constants";
import { EditButton, useRedirect } from "react-admin";

dayjs.extend(relativeTime);

const useStyles = makeStyles({
  media: {
    height: 140,
  },
  noPhoto: {
    height: 140,
    padding: 0,
    backgroundColor: '#E2E2E2'
  },
  title: {
    paddingBottom: '0.5em',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
});
export default ({ news, hasEdit = false }) => {
  const classes = useStyles();
  const redirect = useRedirect();

  const displayDate = () => {
    const createdAt = dayjs(news.createdAt);
    const now = dayjs();
    const diffInDays = now.diff(createdAt, 'day');
    return diffInDays < 6 ? createdAt.fromNow() : createdAt.format('DD/MM/YYYY');
  }
  return (
    <Grid key={news.id} xs={12} md={4} lg={3} xl={2} item>
      <CardActionArea onClick={() => redirect('show', 'news', news.id)}>
        <Card>
          {
            news.imagePath ?
              <CardMedia
                image={news.imagePath}
                className={classes.media}
              />
              :
              <CardContent className={classes.noPhoto} />
          }
          <CardContent className={classes.title}>
            <Typography
              variant="h6"
              component="h4"
              align="center"
              noWrap={true}
            >
              {news.title}
            </Typography>
            <Typography
              variant="subtitle1"
              component="h3"
              align="center"
              noWrap={true}
            >{displayDate()}
            </Typography>
          </CardContent>
          {hasEdit &&
            <Permission permission={PERMISSIONS.NEWS}>
              <EditButton sx={{ width: '100%', justifyContent: 'flex-end' }} />
            </Permission>
          }
        </Card>
      </CardActionArea>
    </Grid>
  );
}
