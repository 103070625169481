import React, { useState } from "react";
import { Button, ChipField, Datagrid, DateField, ReferenceArrayField, ReferenceArrayInput, ReferenceField, ReferenceInput, SelectArrayInput, SelectInput, SingleFieldList, TextField, useDataProvider, useListContext, useRecordContext, useRefresh } from "react-admin";
import { BetweenDatesInput } from "../../components/BetweenDatesInput";
import { ReportList, Permission } from "../../components";
import ListPagination from "../../components/ListPagination";
import { PERMISSIONS } from "../../constants";
import { CircularProgress } from "@mui/material";
import { makeStyles } from '@mui/styles';
import moment from "moment";
import { getChainId } from "../../lib";

const useStyles = makeStyles(theme => ({
  headerCell: {
    backgroundColor: '#E5E5E5',
  }
}));

const ConcentratorErrorList = () => {
  const ResolveError = () => {
    const [updating, setUpdating] = useState(false);
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    const refresh = useRefresh();

    const handleClick = () => {
      setUpdating(true);
      dataProvider.update('concetrator-error', {
        id: record.id,
        data: { isResolved: !record.isResolved },
        previousData: { ...record }
      })
        .then(() => {
          notify('Erro resolvido com sucesso');
        }).catch(() => {
          notify('Erro ao resolver Erro', { type: 'warning' })
        }).finally(() => {
          setUpdating(false);
          refresh();
        });
    };

    return (
      <Button
        startIcon={updating ? <CircularProgress size={20} /> : undefined}
        disabled={record.isResolved || updating}
        label={record.isResolved ? "Resolvido" : "Resolver"}
        onClick={e => {
          e.stopPropagation();
          handleClick();
        }}
        color="primary"
        variant="contained"
        size="medium"
        style={{ width: 120 }}
      />
    );
  };

  return (
    <Datagrid bulkActionButtons={false} rowClick={false}>
      <ReferenceField
        label="Rede"
        source="chainId"
        reference="chains"
        textAlign="center"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Posto"
        source="stationId"
        reference="stations"
        textAlign="center"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" label="Data atual" textAlign="center" locales="pt-BR" showTime />
      <DateField source="concentratorAt" label="Data do concentrador" textAlign="center" locales="pt-BR" showTime />
      <ResolveError />
    </Datagrid>
  );
};

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();

  return (
    <Datagrid bulkActionButtons={false} classes={{ headerCell: classes.headerCell }} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} locales="pt-BR" />
      <DateField source="from" textAlign="center" label="De" sortable={false} locales="pt-BR" />
      <DateField source="to" textAlign="center" label="Até" sortable={false} locales="pt-BR" />
      <ReferenceField source="chainId" emptyText="Todas" reference="chains" label="Rede" sortable={false} link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceArrayField source="stationIds" emptyText="Todos" reference="stations" label="Postos" sortable={false}>
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
  );
};

const ConcentratorError = (props) => {
  const ListFilters = [
    <BetweenDatesInput alwaysOn />,
    <ReferenceInput
      source="chainId"
      reference="chains"
      perPage={null}
      sort={{ field: "name", order: "ASC" }}
      alwaysOn
    >
      <SelectInput
        label="Rede"
        emptyText="Todos"
        style={{ minWidth: 180 }}
        optionText="name"
      />
    </ReferenceInput>,
    <ReferenceArrayInput
      source="stationIds"
      reference="stations"
      perPage={null}
      sort={{ field: "name", order: "ASC" }}
      alwaysOn
    >
      <SelectArrayInput
        emptyText="Todos"
        label="Postos"
        style={{ minWidth: 180 }}
        optionText={"name"}
      />
    </ReferenceArrayInput>,
    <SelectInput
      source="isResolved"
      label="status"
      emptyText="Todos"
      alwaysOn
      choices={[{ id: true, name: 'Resolvidas' }, { id: false, name: 'Não Resolvidas' }]}
    />
  ];

  return (
    <Permission permission={PERMISSIONS.ADMIN} >
      <ReportList
        {...props}
        basePath="report-concentrator-error"
        title="Postos com horário errado"
        resource="reports/concentrator-error"
        sort={{ field: 'createdAt', order: 'DESC' }}
        perPage={25}
        pagination={<ListPagination />}
        filterTablePrint={<FilterTablePrint />}
        filters={ListFilters}
        filterDefaultValues={{
          isResolved: false,
          chainId: getChainId(),
          from: moment().startOf('day').subtract(1, "month").toISOString(),
          to: moment().endOf('day').toISOString()
        }}
      >
        <ConcentratorErrorList />
      </ReportList>
    </Permission>
  );
};

export default ConcentratorError;