import MapIcon from '@mui/icons-material/Map';
import { makeStyles } from '@mui/styles';
import {
  ArrayField,
  Button,
  ChipField,
  CreateButton,
  Datagrid,
  DateField,
  DeleteButton,
  EditButton,
  Filter,
  ImageField,
  List,
  ReferenceInput,
  SelectInput,
  SingleFieldList,
  TextField,
  TextInput,
  TopToolbar,
  usePermissions,
  useRecordContext,
  useRedirect,
} from 'react-admin';
import { Permission } from '../../components';
import { CNPJField, PhoneField } from '../../components/Fields';
import { PERMISSIONS } from '../../constants';

const listStyles = makeStyles({
  image: {
    '& img': {
      maxWidth: '4rem',
    },
  },
})

const StationFilter = ({ permissions, ...props }) => (
  <Filter {...props}>
    <TextInput label="Pesquisar" source="search" alwaysOn />
    {permissions && permissions.includes(PERMISSIONS.ADMIN) && (
      <ReferenceInput label="Rede" source="chainId" reference="chains" sort={{ field: 'name', order: 'ASC' }} perPage={null} alwaysOn>
        <SelectInput resettable optionText="name" />
      </ReferenceInput>
    )}
  </Filter>
);

const NumericIdField = (props) => {
  const record = useRecordContext();
  if (!record) { return null; }

  return record.numericIds?.map((_, ind) => (
    <ChipField source={`numericIds[${ind}]`} />
  ));
};

export const StationList = () => {
  const classes = listStyles();
  const { permissions } = usePermissions();

  return (
    <Datagrid rowClick="show" bulkActionButtons={false} >
      <ImageField className={classes.image} source="imagePath" label="Imagem" />
      <TextField source="name" label="Nome" />
      <ArrayField source="numericIds" label="IDs Numéricos">
        <NumericIdField />
      </ArrayField>
      <CNPJField source="cnpj" label="CNPJ" />
      <PhoneField source="phone" label="Telefone" sortable={false} />
      <DateField source="createdAt" label="Adicionado em" />
      {permissions && [
        PERMISSIONS.UPDATE_STATION,
        PERMISSIONS.REGISTER_STATION_OPENING_HOURS,
        PERMISSIONS.REGISTER_STATION_ADDRESS,
        PERMISSIONS.REGISTER_STATION_FINANCIAL,
        PERMISSIONS.REGISTER_STATION_PAYMENT,
        PERMISSIONS.REGISTER_STATION_GPS,
        PERMISSIONS.REGISTER_STATION_CONCENTRATOR,
        PERMISSIONS.REGISTER_STATION_SYNCHONIZATION,
        PERMISSIONS.REGISTER_STATION_IDENTIFICATION,
      ].some(((permission) => permissions.includes(permission))) && (
          <EditButton />
        )}
      {permissions && permissions.includes(PERMISSIONS.ADMIN) && (
        <DeleteButton />
      )}
    </Datagrid>
  )
};

const ListActions = () => {
  const redirect = useRedirect();
  return (
    <TopToolbar>
      <Permission permission={PERMISSIONS.ADMIN}>
        <CreateButton />
      </Permission>
      <Button
        onClick={() => redirect('/map-stations')}
        label='Mostrar mapa'
      >
        <MapIcon />
      </Button>
    </TopToolbar>
  );
}

export default ({ permissions, ...props }) => {
  return (
    <List
      actions={<ListActions />}
      exporter={false}
      {...props}
      title="Postos"
      filter={
        localStorage.getItem('chainId')
          ? { chainId: localStorage.getItem('chainId') }
          : null
      }
      filters={<StationFilter permissions={permissions} />}
    >
      <StationList />
    </List>
  )
};
