import React from 'react';
import {
  List,
  Filter,
  Datagrid,
  TextField,
  SearchInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TopToolbar,
  CreateButton,
} from 'react-admin';
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { PartiallyHiddenCpfField } from '../../components/Fields';

const ListActions = props => {
  return (
    <TopToolbar>
      <CreateButton {...props} />
    </TopToolbar>
  );
};

const PromoterFilter = props => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn />
    {props.permissions && props.permissions.includes(PERMISSIONS.ADMIN) &&
      <ReferenceInput
        label="Rede"
        source="chainId"
        reference="chains"
        perPage={9999}
        sort={{ field: 'name', order: 'ASC' }}
        alwaysOn
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    }
  </Filter>
);

export default props => (
  <List
    {...props}
    perPage={25}
    pagination={<ListPagination />}
    actions={<ListActions />}
    exporter={false}
    bulkActionButtons={false}
    title="Lista de Promoters"
    filters={<PromoterFilter {...props} />}
  >
    <Datagrid rowClick="edit">
      <Permission permission={PERMISSIONS.ADMIN}>
        <ReferenceField source="chainId" reference="chains" label="Rede">
          <TextField source="name" />
        </ReferenceField>
      </Permission>
      <TextField source="name" label="Nome" />
      <PartiallyHiddenCpfField source="cpf" label="CPF" />
      <TextField source="login" label="Login" />
      <TextField source="code" label="Código" />
      <Permission permission={PERMISSIONS.ADMIN}>
        <ReferenceField
          source="profileId"
          reference="profiles"
          label="Perfil"
        >
          <TextField source="name" />
        </ReferenceField>
      </Permission>
    </Datagrid>
  </List>
);
