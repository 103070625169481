import { Resource } from '../index'
import Message from '@mui/icons-material/Message'
import { PERMISSIONS } from '../../constants'
import list from './MessageList'
import show from './MessageShow'

export default {
  list,
  show,
  name: 'chains/chainId/support-tickets',
  permissions: [PERMISSIONS.LIST_MESSAGES],
  options: { label: 'Mensagens' },
  icon: Message,
}
