import {
	Edit,
	ReferenceInput,
	SelectInput,
	SimpleForm,
	TextInput,
	maxLength,
	required
} from 'react-admin';

import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { RatingPollItemType } from '../../models';
import { EmojiArrayInput } from '../../components';

export default props => {
	return (
		<Permission permission={PERMISSIONS.RATING_POLL_ITEMS}>
			<Edit {...props} title="Editar enquete de avaliação">
				<SimpleForm>
					<Permission permission={PERMISSIONS.ADMIN}>
						<ReferenceInput {...props} source="chainId" reference="chains" sort={{ field: 'name', order: 'ASC' }} perPage={null} validate={[required()]}>
							<SelectInput style={{ minWidth: 300 }} optionText="name" label="Rede" InputProps={{ autoComplete: 'off' }} validate={[required()]} />
						</ReferenceInput>
					</Permission>
					<TextInput
						style={{ minWidth: 300 }}
						source="name"
						label="Nome"
						validate={[required(), maxLength(140)]}
						InputProps={{ autoComplete: 'off' }}
					/>
					<SelectInput
						style={{ minWidth: 300 }}
						source="type"
						label="Tipo"
						choices={RatingPollItemType.ratingPollItemTypeChoices}
						validate={[required()]}
					/>
					<EmojiArrayInput
						style={{ minWidth: 300 }}
						source="ratings"
						label="Notas"
						validate={[required()]}
					/>
				</SimpleForm>
			</Edit>
		</Permission >
	);
}
