import RecoveryIcon from '@mui/icons-material/Restore';
import {
  Button,
  CreateButton,
  Datagrid,
  Filter,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextField,
  TopToolbar,
  usePermissions,
  useRedirect,
} from 'react-admin';
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { formatPoints } from '../../utils';

const ListActions = props => {
  const redirect = useRedirect();
  return (
    <TopToolbar>
      <CreateButton {...props} />
      <Button
        label="Recuperar"
        onClick={() => redirect('/employees/recovery')}
        startIcon={<RecoveryIcon />}
        alignIcon="right"
        style={{ alignSelf: 'end', marginLeft: 16 }}
      />
    </TopToolbar>
  );
};

const UserFilter = props => {
  const { permissions } = usePermissions();
  return (
    <Filter {...props}>
      <SearchInput source="search" alwaysOn />
      {permissions && permissions.includes(PERMISSIONS.ADMIN) &&
        <ReferenceInput
          source="chainId"
          reference="chains"
          perPage={9999}
          sort={{ field: 'name', order: 'ASC' }}
          alwaysOn
        >
          <SelectInput label="Rede" optionText="name" />
        </ReferenceInput>
      }
    </Filter>
  );
};

export default props => (
  <List
    {...props}
    perPage={25}
    pagination={<ListPagination />}
    actions={<ListActions />}
    exporter={false}
    title="Usuários da rede"
    filters={<UserFilter {...props} />}
  >
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <Permission permission={PERMISSIONS.ADMIN}>
        <ReferenceField source="chainId" reference="chains" label="Rede">
          <TextField source="name" />
        </ReferenceField>
      </Permission>
      <TextField source="name" label="Nome" />
      <TextField source="login" label="Login" />
      <Permission permission={PERMISSIONS.ADMIN}>
        <ReferenceField
          source="profileId"
          reference="profiles"
          label="Perfil"
        >
          <TextField source="name" />
        </ReferenceField>
      </Permission>
      <FunctionField
        emptyText="0"
        label="Pontos por CPF"
        render={record => formatPoints(record?.maxPoints)}
      />
    </Datagrid>
  </List>
);
