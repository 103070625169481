import Redeem from '@mui/icons-material/Redeem'
import { PERMISSIONS } from '../../constants'
import list from './ProductList'
import create from './ProductCreate'
import edit from './ProductEdit'

export default {
  list,
  create,
  edit,
  name: 'chains/chainId/products',
  permissions: [PERMISSIONS.CREATE_PRODUCT],
  options: { label: 'Produtos' },
  icon: Redeem,
}
