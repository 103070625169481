import { Typography } from '@mui/material';
import React from 'react';
import { Labeled, NumberInput, TextInput, maxValue, minValue } from 'react-admin';

const FormBasicInfo: React.FC<{ lastPriority: number | null }> = ({ lastPriority }) => {

  const MAX_PRIORITY = 1000;
  const MIN_PRIORITY = 1;

  return (
    <>
      <TextInput source="name" label="Nome" required fullWidth />
      <Labeled label={`Escolha um número de prioridade na faixa de ${MIN_PRIORITY} a ${MAX_PRIORITY}, onde valores mais baixos indicam prioridades mais elevadas. Lembre-se que cada número de prioridade é destinado a um único anúncio ativo.`}>
        <>
          {
            lastPriority &&
            <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>{`O valor ${lastPriority} é o mais alto em uso no momento.`}</Typography>
          }
          <NumberInput
            source="priority"
            label="Prioridade"
            validate={[minValue(MIN_PRIORITY), maxValue(MAX_PRIORITY)]}
            format={(val: number) => val ? (val < MIN_PRIORITY ? MIN_PRIORITY : val > MAX_PRIORITY ? MAX_PRIORITY : val) : null}
            parse={(val: string) => val ? (parseInt(val) < MIN_PRIORITY ? MIN_PRIORITY : parseInt(val) > MAX_PRIORITY ? MAX_PRIORITY : parseInt(val)) : null}
          />
        </>
      </Labeled>
    </>
  );
}

export default FormBasicInfo;