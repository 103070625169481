import { DEFAULT_API_ADMIN } from '../../constants'
import { httpClientReactAdmin } from '../../lib'
import { getChainId } from '../../lib'


export default async (resourceName, params) => {

  const URL = `${DEFAULT_API_ADMIN}/${(resourceName || '').replace('chainId', getChainId())}`;
  const dataArray = JSON.stringify(params.data);
  const body = dataArray;

  const { json } = await httpClientReactAdmin(URL, { method: 'POST', body });
  return json;
}
