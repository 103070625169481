import { PERMISSIONS } from '../../constants'
import list from './GroupRulesList'
import create from './GroupRulesCreate'
import edit from './GroupRulesEdit'

export default {
  list,
  create,
  edit,
  name: 'chains/chainId/group-rules',
  permissions: [PERMISSIONS.RULES_BY_GROUP]
}