import moment from 'moment';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import {
  NumberInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  regex,
  required,
  useDataProvider,
  useNotify
} from 'react-admin';

import Button from '@mui/material/Button';
import { Coupon } from '../../components';
import { formatReal, hasOrderSheet } from '../../lib';
import { formatPartiallyHiddenCpf, formatPoints, printElement } from '../../utils';
import styles from '../validateCoupon/CouponSearch.module.scss';

const validate = [required(), regex(/^(.{8}|^.{10})$/, 'O código deve ter 8 ou 10 dígitos')];

const CustomToolbar = props => {
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.invalid} label="Buscar" submitOnEnter />
    </Toolbar>
  )
};

const CouponSearch = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [coupon, setCoupon] = useState(null);
  const [couponBarcode, setCouponBarcode] = useState("");
  const [orderSheetCode, setOrderSheetCode] = useState("");
  const [printCoupon, setPrintCoupon] = useState();
  const couponRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (printCoupon && couponRef.current) {
      printElement(() => couponRef.current);
      couponRef.current = null;
    }
  }, [printCoupon]);

  const executeSearch = async ({ id }) => {
    setCoupon(null);
    setLoading(true);

    dataProvider.getOne(`coupons`, { id })
      .then(({ data }) => {
        setLoading(false);
        if (data.redeemed) {
          notify("Cupom já resgatado!", { type: 'warning' });
        } else if (data.refunded) {
          notify("Cupom já foi estornado!", { type: 'warning' });
        } else if (!data.place.isActive) {
          notify("Não é possivel o fazer resgate!", { type: 'warning' });
        } else {
          setCoupon(data);
          setCouponBarcode("");
        }
      })
      .catch((error) => {
        setLoading(false);
        notify(error.message, { type: 'warning' });
      });
  }

  const redeemCoupon = useCallback((id, print) => {
    setLoading(true);
    dataProvider.update(`coupons/${id}`, { data: { orderSheetCode } })
      .then((response) => {
        setLoading(false);
        notify('Cupom resgatado com sucesso!');
        if (print) {
          setPrintCoupon(response.data);
        }
        setCoupon(null);
      })
      .catch((error) => {
        setLoading(false);
        notify(error.message, { type: 'warning' })
      });
  }, [orderSheetCode, coupon]);

  return (
    <Fragment>
      <SimpleForm toolbar={<CustomToolbar invalid={loading} />} onSubmit={executeSearch} >
        <div className={styles.inputBox} >
          <TextInput
            source="id"
            resettable
            label="Identificador do cupom"
            validate={validate}
            value={couponBarcode}
            onChange={(event) => setCouponBarcode(event.target ? event.target.value : "")}
          />
          {hasOrderSheet() ?
            <NumberInput
              source="orderSheetCode"
              label="Código da comanda"
              value={orderSheetCode}
              onChange={(event) => setOrderSheetCode(event.target ? event.target.value : "")}
              style={{ marginLeft: '20px' }}
            /> : ""}
        </div>
      </SimpleForm>
      {coupon && Boolean(coupon.id.length) && (
        <div className={styles.container}>
          {coupon.isCashback ?
            <div></div> :
            <div className={styles.productImage}>
              <img src={coupon.product ? coupon.product.imagePath : coupon.imagePath} alt="" />
            </div>
          }
          <div className={styles.couponInfo}>
            <div>
              <span className={styles.info}>
                <b>Cupom: </b>
                <i>{coupon.id}</i>
              </span>
              <span className={styles.info}>
                <b>Data: </b>
                {moment(coupon.createdAt).format('DD/MM/YYYY')}
              </span>
            </div>
            <div>
              <span className={styles.info}>
                <b>Produto: </b>
                {coupon.isCashback ? "Cashback" : coupon.title || coupon.product?.name }
              </span>
              <span className={styles.info}>
                <b>Valor: </b>
                {coupon.isCashback ? `${formatReal(coupon.partnerValue)}` : `${formatPoints(coupon.value)} pontos`}
              </span>
            </div>
            <div>
              <span className={styles.info}>
                <b>Descrição: </b>
                {coupon.isCashback ? "Desconto de valor em compra" : coupon.product ? coupon.product.description : coupon.description}
              </span>
              {coupon.place &&
                <span className={styles.info}>
                  <b>Local de troca: </b>
                  <i>{coupon.redeemAnywhere ? "Todos" : coupon.place.name}</i>
                </span>}
            </div>
            {Boolean(coupon.refunded) ?
              <span className={styles.info}>
                <b>Cupom estornado</b>
              </span> :
              <>
                <Button
                  className={styles.submit}
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  onClick={() => redeemCoupon(coupon.id)}>
                  Resgatar
                </Button>
                <Button
                  className={styles.submit}
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  onClick={() => {
                    redeemCoupon(coupon.id, true);
                  }}
                >
                  Resgatar e Imprimir
                </Button>
              </>
            }
          </div>
          {coupon && coupon.customer &&
            <div className={styles.customerInfo}>
              <span className={styles.info}>
                <b>Cliente que efetuou a troca: </b>
              </span>
              <span className={styles.info}>
                <b>Nome: </b>
                {`${coupon.customer.name} ${coupon.customer.email ? `(${coupon.customer.email})` : ''}`}
              </span>
              <span className={styles.info}>
                <b>CPF: </b>
                {formatPartiallyHiddenCpf(coupon.customer.cpf)}
              </span>
              <span className={styles.info}>
                <b>Data de Troca: </b>
                {moment(coupon.createdAt).format('DD/MM/YYYY - HH:mm')}
              </span>
            </div>
          }
        </div>
      )}
      <div style={{ display: "none" }}>
        {printCoupon &&
          <Coupon
            coupon={printCoupon}
            ref={el => couponRef.current = el}
          />}
      </div>
    </Fragment>
  )
};
export default CouponSearch;
