import { useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';

import { DEFAULT_API_ADMIN, PERMISSIONS } from '../../constants';
import { get, getChainId, getPlaceId, to } from '../../lib';

import {
  DropDownPartners,
  Loader,
  ReportLayout,
  TableGeneric
} from '../../components';

import { FormControlLabel, Switch } from '@mui/material';

const ProductsReport = ({ data }) =>
  <TableGeneric
    headCells={['Produto', 'Local', 'Depósito', 'Mostruário', 'Estoque Padrão']}
    arrayData={data}
    title="Produtos em Estoque"
    style={{ width: '100%' }}
  />

export default props => {
  const [allProducts, setAllProducts] = useState([])
  const [remainingProducts, setRemainingProducts] = useState([])
  const [places, setPlaces] = useState(null)
  const [loading, setLoading] = useState(true)
  const [defaultPlaceId, setDefaultPlaceId] = useState(getPlaceId() || undefined)
  const [placeId, setPlaceId] = useState(getPlaceId() || undefined)
  const [productsSoldOff, setProductsSoldOff] = useState(true)
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const filters = [{
    place: placeId && places ? places.find(p => p.id === placeId).name : 'Todos',
    soldOff: productsSoldOff ? 'Sim' : 'Não'
  }]

  const getPlaces = async () => {
    dataProvider.getList('chains/chainId/places', { sort: { field: 'name', order: 'ASC' } })
      .then(({ data }) => {
        setPlaces(data);
        setPlaceId(data[0].id);
        setDefaultPlaceId(data[0].id);
      })
      .catch((error) => { notify(error.message, { type: 'warning' }); });
  }

  const doFetch = async () => {
    setLoading(true)
    const filter = { placeId, stock: true }
    const URL = `${DEFAULT_API_ADMIN}/chains/${getChainId()}/products?filter=${JSON.stringify(
      filter,
    )}`
    const [error, response] = await to(get(URL))

    if (error) {
      notify(error.message, { type: 'warning' });
      setLoading(false);
      return;
    }

    const allProducts = []
    const remainingProducts = []

    response.data.forEach(p => {
      const pp = p.productPlaces[0];
      const product = {
        name: p.name,
        placeName: pp.place.name,
        warehouse: pp.warehouse,
        showcase: pp.showcase,
        defaultStock: pp.defaultStock === 'warehouse' ? 'Depósito' : 'Mostruário',
      }

      if (+pp.warehouse > 0 || +pp.showcase > 0) {
        remainingProducts.push(product)
      }
      allProducts.push(product)
    });

    setAllProducts(allProducts);
    setRemainingProducts(remainingProducts)
    setLoading(false)
  }

  useEffect(() => {
    if (!getPlaceId()) {
      getPlaces();
    }
  }, []);

  useEffect(() => {
    if (placeId) {
      doFetch()
    }
  }, [placeId]);

  return (
    <ReportLayout
      permission={PERMISSIONS.STOCK_STATEMENT_REPORT}
      title='Relatório de Posição de Estoque'
      filterTablePrint={
        <TableGeneric
          headCells={[
            'Local',
            'Mostrar produtos esgotados'
          ]}
          arrayData={filters}
          title="Filtros"
          style={{ width: '100%', margin: '1em 0' }}
        />
      }
      filters={[
        <>
          {!getPlaceId() && placeId &&
            <DropDownPartners
              label="Ponto de troca"
              defaultValue={defaultPlaceId}
              setValue={setPlaceId}
              arrayData={places}
            />
          }
        </>,
        <FormControlLabel
          control={
            <Switch
              checked={productsSoldOff}
              onChange={() => setProductsSoldOff(!productsSoldOff)}
            />
          }
          label='Mostrar produtos esgotados'
        />
      ]}
      content={loading ? <Loader /> : <ProductsReport data={productsSoldOff ? allProducts : remainingProducts} />}
    />
  )
}
