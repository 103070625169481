import { makeStyles } from "@mui/styles";
import moment from "moment";
import { ArrayField, ChipField, Datagrid, DateField, NumberField, ReferenceArrayField, ReferenceArrayInput, ReferenceField, ReferenceInput, SearchInput, SelectArrayInput, SelectInput, SingleFieldList, TextField, useListContext } from "react-admin";
import { Permission, ReportList } from "../../components";
import ListPagination from "../../components/ListPagination";
import { PERMISSIONS } from "../../constants";

const useStyles = makeStyles(theme => ({
}));

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();

  return (
    <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
      <ReferenceField source="id" emptyText="Todas" reference="retailers" label="Revenda" sortable={false} link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceArrayField source="chainIds" emptyText="Todas" reference="chains" label="Redes" sortable={false}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
  );
}

const ReportRetailer = props => {
  const classes = useStyles();

  const listFilters = [
    <SearchInput source="search" alwaysOn />,
    <ReferenceInput
      reference="retailers"
      source="id"
      emptyText="Todas"
      alwaysOn
    >
      <SelectInput
        label="Revenda"
        optionText="name" />
    </ReferenceInput>,
    <ReferenceArrayInput
      reference="chains"
      source="chainIds"
      style={{ minWidth: 160 }}
      sort={{ field: "name", order: "ASC" }}
      perPage={9999}
      alwaysOn
    >
      <SelectArrayInput
        label="Redes"
        optionText="name" />
    </ReferenceArrayInput>,
  ];

  return (
    <Permission permission={PERMISSIONS.ADMIN}>
      <ReportList
        {...props}
        basePath="report-retailers"
        title="Relatório de Produtos"
        resource="retailers"
        filters={listFilters}
        sort={{ field: 'name', order: 'ASC' }}
        perPage={25}
        pagination={<ListPagination />}
        filterDefaultValues={{ status: true }}
        bulkActionButtons={false}
        exporter={false}
        titleOnPrint="Relatório de Revendas"
        filterTablePrint={<FilterTablePrint />}
        landscape
      >
        <Datagrid bulkActionButtons={false} rowClick={false}>
          <TextField source="name" label="Nome" />
          <TextField source="answerableEmployee" label="Responsável" />
          <ArrayField source="stationChains" label="Redes" sortable={false}>
            <Datagrid bulkActionButtons={false}>
              <TextField source="name" label="Nome" />
              <NumberField source="stationsCount" label="Quantidade de Postos" textAlign="center" />
            </Datagrid>
          </ArrayField>
        </Datagrid>
      </ReportList>
    </Permission>
  );
}

export default ReportRetailer;
