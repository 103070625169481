import { formatCPF } from '@brazilian-utils/brazilian-utils';
import { Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { linkToRecord } from 'ra-core';
import { Children } from "react";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    title: {
        margin: '20px 0 20px 0',
        textAlign: 'center'
    },
    tableContainer: {
        margin: '0 2%'
    },
    tableWidth: {
        width: '96%'
    },
    tableCell: {
        padding: 6,
    },
    clickableRow: {
        cursor: 'pointer',
    },
}));
interface GenericDatagridProps {
    children: any;
    data: object[];
    style: object;
    title: string | undefined;
    sort: { field: string, order: any } | undefined;
    setSort: Function | undefined;
    pagination: { page: number, perPage: number } | undefined;
    setPagination: Function | undefined;
    total: number;
    textAlign: "right" | "inherit" | "left" | "center" | "justify" | "end" | "initial" | "start" | "-moz-initial" | "revert" | "unset" | "match-parent" | undefined;
    rowClick: string | undefined;
};

const GenericDatagrid = ({
    children,
    data,
    style,
    title,
    sort,
    setSort,
    pagination,
    setPagination,
    total,
    textAlign,
    rowClick,
}: GenericDatagridProps) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleClick = (rowClick, record) => async event => {
        const id = record.id;
        if (!rowClick || !id) return;
        event.persist();
        navigate(linkToRecord(`/${rowClick}`, id, 'show'));
    };

    return (
        <>
            {title && <Typography className={classes.title}>{title}</Typography>}
            <TableContainer className={classes.tableContainer}>
                <Table className={classes.tableWidth} style={{ ...style }}>
                    <TableHead>
                        <TableRow>
                            {Children.map(children, ({ props: { label, source, unsortable } }) => {
                                let content = label ? label : source;

                                if (!unsortable && sort && setSort) {
                                    const isSortField = sort.field === source;
                                    content = (
                                        <TableSortLabel
                                            active={isSortField}
                                            direction={isSortField ? sort.order.toLowerCase() : 'desc'}
                                            onClick={() => setSort({
                                                field: source,
                                                order: !isSortField || sort.order === 'ASC' ? 'DESC' : 'ASC'
                                            })}
                                        >
                                            {content}
                                        </TableSortLabel>
                                    );
                                }
                                return (
                                    <TableCell style={{ width: `calc(100%/${children.length})`, textAlign }}>
                                        {content}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && (data.length === 0 ?
                            <TableRow>
                                <TableCell style={{ border: 'none' }}>Nenhum Registro</TableCell>
                            </TableRow> :
                            data.map((record, i) =>
                                <TableRow key={i} onClick={handleClick(rowClick, record)} hover={!!rowClick} className={rowClick && classes.clickableRow} >
                                    {Children.map(children, ({ props: { render, source, emptyText, fallbackSource } }, i) => {
                                        let content = '';
                                        if (render) {
                                            content = render(record);
                                        } else {
                                            if (!record[source]) { source = fallbackSource; }
                                            if (source === 'createdAt' || source === 'created_at') {
                                                content = moment(record[source]).format('DD/MM/YYYY   HH:mm');
                                            } else if (source === 'cpf') {
                                                content = formatCPF(record[source]);
                                            } else {
                                                content = record[source];
                                            }
                                        }
                                        content = content || emptyText || '';
                                        return (
                                            <TableCell key={i} className={classes.tableCell} style={{ textAlign }}>
                                                {content}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>))
                        }
                    </TableBody>
                </Table>
                {pagination && setPagination && pagination.page && pagination.perPage &&
                    <Pagination
                        count={total ? total : 0}
                        rowsPerPage={pagination.perPage}
                        defaultPage={(pagination.page - 1)}
                        backIconButtonText='Página Anterior'
                        nextIconButtonText='Próxima Página'
                        labelRowsPerPage='Linhas por Página'
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`}
                        component='div'
                        className={classes.tableWidth}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        onPageChange={(event, page) => setPagination({ perPage: pagination.perPage, page: page + 1 })}
                        onChangeRowsPerPage={({ target: { value } }) => setPagination({ perPage: value, page: 1 })}
                    />}
            </TableContainer >
        </>);
};

GenericDatagrid.defaultProps = {
    textAlign: 'left',
    data: [],
    style: {},
    total: 0
}

export default GenericDatagrid;