import {
  ChipField,
  DateField,
  FunctionField,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  usePermissions,
  useRecordContext
} from 'react-admin';
import { Permission } from '../../../components';
import CloneRuleButton from '../CloneRuleButton';
import DeleteRuleButton from '../DeleteRuleButton';
import { PERMISSIONS } from '../../../constants';
import { getUserId } from '../../../lib';

export default props => {
  const { permissions } = usePermissions();

  const DeletedFields = () => {
    const record = useRecordContext();

    return (
      record.isDeleted ?
        <SimpleShowLayout sx={{ padding: 0 }}>
          <DateField
            label="Deletado em"
            source="deletedAt"
            textAlign="center"
            emptyText="--"
            showTime
          />
          <ReferenceField source="deletedByEmployeeId" reference="employees" label="Deletado por" link={permissions && permissions.includes(PERMISSIONS.UPDATE_USER)}>
            <FunctionField render={record => record ? `${record.name} ${record.id === getUserId() ? '(você)' : ''}` : ''} />
          </ReferenceField>
        </SimpleShowLayout>
        : <></>
    );
  }

  return (
    <Show {...props}
      title="Detalhes da regra Intervalo entre Abastecimentos"
      queryOptions={{ meta: { listDeleted: true } }}
    >
      <SimpleShowLayout>
        <Permission permission={PERMISSIONS.POINT_RULES}>
          <DeleteRuleButton />
        </Permission>
        <Permission permission={PERMISSIONS.POINT_RULES}>
          <CloneRuleButton />
        </Permission>
        <TextField label="Código" source="identify" />
        <ReferenceField source="createdByEmployeeId" reference="employees" label="Criado por" link={permissions && permissions.includes(PERMISSIONS.UPDATE_USER)}>
          <FunctionField render={record => record ? `${record.name} ${record.id === getUserId() ? '(você)' : ''}` : ''} />
        </ReferenceField>
        <ReferenceArrayField
          source="stationIds"
          reference="stations"
          label="Posto"
        >
          <SingleFieldList linkType="show">
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceField
          label="Combustível"
          source="fuelId"
          reference="fuels"
        >
          <TextField source="name" />
        </ReferenceField>
        <NumberField
          source='interval'
          label="Intervalo em Horas"
        />
        <DateField
          source="createdAt"
          label="Criado em"
          showTime
        />
        <DeletedFields />
      </SimpleShowLayout>
    </Show>
  );
}
