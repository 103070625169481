import React from "react";
import { Datagrid, DateField, List, ReferenceField, TextField } from 'react-admin';
import ListPagination from "../../components/ListPagination";

const BillingAlertList = (props) => {
  return (
    <List
      {...props}
      title="Faturamento"
      resource="billing-alert"
      perPage={25}
      sort={{ field: 'createdAt', order: 'DESC' }}
      pagination={<ListPagination />}
      exporter={false}
    >
      <Datagrid bulkActionButtons={false}>
        <ReferenceField
          label="Posto"
          source="stationId"
          reference="stations"
          textAlign="center"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="description" label="Descrição" textAlign="center" />
        <DateField source="createdAt" label="Data" textAlign="center" />
      </Datagrid>
    </List>
  )
};

export default BillingAlertList;