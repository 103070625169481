import {
  ChipField,
  DateField,
  FunctionField,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  SelectField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  usePermissions,
  useRecordContext
} from 'react-admin';

import { PeriodPointType } from '../../../models/periodPointType';
import { Permission } from '../../../components';
import { PERMISSIONS } from '../../../constants';
import DeleteRuleButton from '../DeleteRuleButton';
import CloneRuleButton from '../CloneRuleButton';
import { getUserId } from '../../../lib';

export default props => {
  const { permissions } = usePermissions()
  const periodChoices = [
    { id: PeriodPointType.weekly, name: 'Semanalmente' },
    { id: PeriodPointType.monthly, name: 'Mensalmente' },
    { id: PeriodPointType.daily, name: 'Dias' },
  ];

  const DaysNumberField = () => {
    const record = useRecordContext();

    return (
      record.period === PeriodPointType.daily ?
        <SimpleShowLayout sx={{ padding: 0 }}>
          <NumberField
            source="days"
            label="Quantidade de dias"
          />
        </SimpleShowLayout>
        : <></>
    );
  }

  const DeletedFields = () => {
    const record = useRecordContext();

    return (
      record.isDeleted ?
        <SimpleShowLayout sx={{ padding: 0 }}>
          <DateField
            label="Deletado em"
            source="deletedAt"
            textAlign="center"
            emptyText="--"
            showTime
          />
          <ReferenceField source="deletedByEmployeeId" reference="employees" label="Deletado por" link={permissions && permissions.includes(PERMISSIONS.UPDATE_USER)}>
            <FunctionField render={record => record ? `${record.name} ${record.id === getUserId() ? '(você)' : ''}` : ''} />
          </ReferenceField>
        </SimpleShowLayout>
        : <></>
    );
  }

  return (
    <Show {...props}
      title="Detalhes da Regra de Limite de Número de Abastecimentos"
      queryOptions={{ meta: { listDeleted: true } }}
    >
      <SimpleShowLayout>
        <Permission permission={PERMISSIONS.POINT_RULES}>
          <DeleteRuleButton />
        </Permission>
        <Permission permission={PERMISSIONS.POINT_RULES}>
          <CloneRuleButton />
        </Permission>
        <TextField source="identify" label="Código" />
        <ReferenceField source="createdByEmployeeId" reference="employees" label="Criado por" link={permissions && permissions.includes(PERMISSIONS.UPDATE_USER)}>
          <FunctionField render={record => record ? `${record.name} ${record.id === getUserId() ? '(você)' : ''}` : ''} />
        </ReferenceField>
        <ReferenceArrayField
          source="stationIds"
          reference="stations"
          label="Posto"
        >
          <SingleFieldList linkType="show">
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <SelectField
          source="period"
          label="Periodo"
          choices={periodChoices}
        />
        <DaysNumberField />
        <NumberField
          source='amountFillins'
          label="Quantidade de abastecimentos"
        />
        <DateField
          source="createdAt"
          label="Criado em"
          showTime
        />
        <DeletedFields />
      </SimpleShowLayout>
    </Show>
  );
}
