export enum PeriodPointType {
  weekly = 'weekly',
  monthly = 'monthly',
  daily = 'daily',
};

export namespace PeriodPointType {
  export const translateType = (type: PeriodPointType): string => {
    switch (type) {
      case PeriodPointType.weekly:
        return "Semanalmente";
      case PeriodPointType.monthly:
        return "Mensalmente";
      case PeriodPointType.daily:
        return "Dias";
      default:
        return "";
    }
  };
};