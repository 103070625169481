import { DEFAULT_API_ADMIN } from '../../constants'
import { getChainId, httpClientReactAdmin } from '../../lib'

export default async (resourceName, params) => {
  const mapPath = {
    '_employees': 'employees',
  }
  const path = mapPath[resourceName]

  const URL = `${DEFAULT_API_ADMIN}/${(path || resourceName || '').replace('chainId', getChainId())}/${params.id}`

  const { json } = await httpClientReactAdmin(URL, { method: 'DELETE' })

  return json
}
