import React, { useCallback, useEffect, useState } from 'react'
import {
  Create,
  SimpleForm,
  SelectInput,
  required,
  useDataProvider,
  GetListParams,
  RaRecord,
  NumberInput,
  minValue,
  ReferenceArrayInput,
  SelectArrayInput,
  Toolbar,
  SaveButton,
  FormDataConsumer
} from 'react-admin'
import { PERMISSIONS } from '../../../constants'
import Permission from '../../../components/Permission'
import { PeriodPointType } from '../../../models/periodPointType';
import { useLocation } from 'react-router-dom';
import { PointRule } from '../../../models';

const validateInput = [required()]
const validateNumberInput = [required(), minValue(1)]

export default props => {
  const { state: params } = useLocation();

  const periodChoices = [
    { id: PeriodPointType.weekly, name: 'Semanalmente' },
    { id: PeriodPointType.monthly, name: 'Mensalmente' },
    { id: PeriodPointType.daily, name: 'Dias' },
  ];

  const getInitialValues = useCallback(() => {
    const { ...restParams } = params as { [key: string]: string }
    return PointRule.createLimitNumberFillin({ ...restParams });
  }, []);

  const CustomToolbar = () => {
    const enabled = params.id ? true : false;

    return (
      <Toolbar>
        <SaveButton alwaysEnable={enabled} />
      </Toolbar>
    );
  }

  return (
    <Permission permission={PERMISSIONS.POINT_RULES}>
      <Create {...props} title="Nova regra de Limite de Número de Abastecimentos">
        <SimpleForm defaultValues={getInitialValues()} toolbar={<CustomToolbar />}>
          <ReferenceArrayInput
            source="stationIds"
            reference="stations"
            perPage={null}
            sort={{ field: "name", order: "ASC" }}
            alwaysOn
          >
            <SelectArrayInput
              label="Posto"
              optionText="name"
              style={{ minWidth: 180 }}
            />
          </ReferenceArrayInput>
          <SelectInput
            source="period"
            label="Periodo"
            choices={periodChoices}
            validate={validateInput}
          />
          <FormDataConsumer>
            {({ formData }) => (
              formData.period === PeriodPointType.daily &&
              <NumberInput
                source='days'
                label="Quantidade de dias"
                validate={validateNumberInput}
              />
            )}
          </FormDataConsumer>
          <NumberInput
            source='amountFillins'
            label="Quantidade de abastecimentos"
            validate={validateNumberInput}
          />
        </SimpleForm>
      </Create>
    </Permission>
  )
}
