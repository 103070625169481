import styled from '@emotion/styled';
import React, { useState } from 'react';
import { FaRegUser } from "react-icons/fa";
import { MdOutlineCardGiftcard, MdOutlineHome, MdOutlineLocalGasStation } from "react-icons/md";
import { PiGasCan } from 'react-icons/pi';
import frameIphone from '../../../assets/images/frame-iphone-14.png';

import { Typography } from '@mui/material';
import { useGetOne } from 'react-admin';
import { getChainId } from '../../../lib';
import { hexToRGBA } from '../../../utils';
import { theme } from '../../../theme';

const WIDTH = 292;
const HEIGHT = 590;

const Container = styled('div')({
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  borderRadius: 10,
  margin: '20px 10px',
  aspectRatio: '1',
  backgroundColor: 'black',
  alignItems: 'center',
  '& img': {
    width: '100%',
  },
  '& video': {
    width: '100%',
  },
});

const MobileContainer = styled('div')({
  width: WIDTH - 40,
  height: HEIGHT - 90,
  backgroundColor: 'white',
  margin: '60px 20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: 10,
});

const MobileFrame = styled('img')({
  position: 'absolute',
  width: WIDTH,
  height: HEIGHT,
  top: -60,
});

const BottomBarContainer = styled('div')({
  position: 'absolute',
  height: 40,
  backgroundColor: 'white',
  width: WIDTH - 15,
  bottom: 5,
  boxShadow: '-1px -1px 5px -1px rgba(82,82,82,0.61)',
  padding: '5px 20px',
  display: 'flex',
  justifyContent: 'space-between'
});

const BottomBarItem = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const BottomBarLabel = styled(Typography)({
  fontSize: '8px',
});

const BottomBarCircleButton = styled('div')<{ color: string }>(({ color }) => ({
  minHeight: 40,
  width: 40,
  borderRadius: '50%',
  backgroundColor: color,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 5,
  marginTop: -25,
}));

const PumpView = styled('div')<{ colors: string[] }>(({ colors }) => ({
  width: '100%',
  height: 'calc(100% - 20px)',
  backgroundColor: 'blue',
  borderTopRightRadius: 20,
  borderTopLeftRadius: 20,
  background: `linear-gradient(180deg, ${colors[0]} 0%, ${colors[1]} 96%)`,
}));

const PumpInfo = styled('div')(() => ({
  backgroundColor: 'white',
  borderRadius: 10,
  padding: 10,
  margin: '0px 10px',
  minHeight: 100,
}));

const PumpInfoLabel = styled(Typography)({
  fontSize: 12,
});

const PumpInfoSmallLabel = styled(Typography)({
  fontSize: 10,
});

const PumpInfoDigits = styled(Typography)({
  fontSize: 22,
  fontFamily: 'Digital-7'
});

const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const Divider = styled('div')({
  height: 1,
  backgroundColor: 'black',
  margin: '5px 0px',
});

const AdPreviewOnMobile: React.FC<{ file: any }> = ({ file }) => {

  const [chainId] = useState(getChainId());
  if (!chainId) { return <Typography>Selecione uma Rede</Typography>; }

  const { data: chain } = useGetOne('chains', { id: chainId });

  const BottomBar = ({ color }) => (
    <BottomBarContainer>
      <BottomBarItem>
        <MdOutlineHome fontSize={18} />
        <BottomBarLabel>Início</BottomBarLabel>
      </BottomBarItem>
      <BottomBarItem>
        <MdOutlineCardGiftcard fontSize={18} />
        <BottomBarLabel>Resgatar</BottomBarLabel>
      </BottomBarItem>
      <BottomBarItem>
        <BottomBarCircleButton color={color}>
          <PiGasCan fontSize={18} color="white" />
        </BottomBarCircleButton>
        <BottomBarLabel>Abastecer</BottomBarLabel>
      </BottomBarItem>
      <BottomBarItem>
        <MdOutlineLocalGasStation fontSize={18} />
        <BottomBarLabel>Postos</BottomBarLabel>
      </BottomBarItem>
      <BottomBarItem>
        <FaRegUser fontSize={15} />
        <BottomBarLabel>Perfil</BottomBarLabel>
      </BottomBarItem>
    </BottomBarContainer>
  );

  const appColor = chain?.appColor || theme.palette.primary.main;

  return (
    !chain ? <></> :
      <div style={{ position: 'relative', width: WIDTH }}>
        <MobileContainer>
          <PumpView colors={[hexToRGBA(appColor, 0.2), hexToRGBA(appColor, 0.2)]} style={{ paddingTop: 8, width: 'calc(100% - 20px)' }}>
            <PumpView colors={[hexToRGBA(appColor, 0.2), hexToRGBA(appColor, 0.5)]} style={{ paddingTop: 10 }}>
              <PumpInfo>
                <Row>
                  <PumpInfoLabel sx={{ flex: 1 }}>Valor</PumpInfoLabel>
                  <PumpInfoDigits>99,99</PumpInfoDigits>
                </Row>
                <Row>
                  <PumpInfoLabel sx={{ flex: 1 }}>Litros</PumpInfoLabel>
                  <PumpInfoDigits>9,99</PumpInfoDigits>
                </Row>
                <Row>
                  <PumpInfoLabel sx={{ flex: 1 }}>Preço por litro</PumpInfoLabel>
                  <PumpInfoDigits style={{ fontSize: 16 }}>9,99</PumpInfoDigits>
                </Row>
                <Divider />
                <Row>
                  <PumpInfoSmallLabel sx={{ flex: 1 }}>Combustível</PumpInfoSmallLabel>
                  <PumpInfoSmallLabel>Gasolina Comum</PumpInfoSmallLabel>
                </Row>
                <Row>
                  <PumpInfoSmallLabel sx={{ flex: 1 }}>Frentista</PumpInfoSmallLabel>
                  <PumpInfoSmallLabel>João Silva</PumpInfoSmallLabel>
                </Row>
                <Row>
                  <PumpInfoSmallLabel sx={{ flex: 1 }}>Preset</PumpInfoSmallLabel>
                  <PumpInfoSmallLabel>R$ 9,99</PumpInfoSmallLabel>
                </Row>
              </PumpInfo>

              <Container>
                {
                  (file?.src && file?.rawFile?.type?.includes('image')) &&
                  <img src={file?.src} />
                }
                {
                  (file?.src && file?.rawFile?.type?.includes('video')) &&
                  <video src={file?.src} style={{ height: 'auto' }} controls={false} muted autoPlay loop />
                }
              </Container>
            </PumpView>
          </PumpView>
          <BottomBar color={appColor} />
        </MobileContainer>

        <MobileFrame src={frameIphone} style={{ zIndex: 9 }} draggable={false} />

      </div>
  );
}

export default AdPreviewOnMobile;