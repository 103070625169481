import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useState } from "react";
import {
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SingleFieldList,
  TextField,
  useListContext,
  usePermissions
} from "react-admin";
import { ReportList } from "../../components";
import { BetweenDatesInput } from "../../components/BetweenDatesInput";
import { PartiallyHiddenCpfField } from "../../components/Fields";
import ListPagination from "../../components/ListPagination";
import Permission from "../../components/Permission";
import { PERMISSIONS } from "../../constants";
import { getChainId } from "../../lib";
import { formatPoints } from "../../utils";

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
}));

const ApprovedTransactions = () => {
  const classes = useStyles();

  return (
    <Datagrid bulkActionButtons={false} style={{ marginTop: 40 }}>
      <ReferenceField
        source="authorizedBy"
        reference="employees"
        textAlign="center"
        label="Aprovado por"
        sortable={false}
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <PartiallyHiddenCpfField
        source="customerCpf"
        textAlign="center"
        label="CPF do Cliente"
      />
      <FunctionField
        label="Quantidade de pontos"
        textAlign="center"
        render={record => formatPoints(record.value)}
      />
      <DateField source="createdAt" label="Data da Aprovação" showTime />
    </Datagrid>
  );
};

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  return (
    <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
      <DateField source="from" textAlign="center" label="De" sortable={false} />
      <DateField source="to" textAlign="center" label="Até" sortable={false} />
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
      <ReferenceArrayField source="employeeIds" emptyText="Todos" reference="employees" label="Funcionários" sortable={false}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceField source="chainId" reference="chains" emptyText="Todas" textAlign="center" label="Rede" sortable={false} >
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  );
}

const ReportApprovedTransactions = (props) => {
  const [selectedChain, setSelectedChain] = useState();
  const { permissions } = usePermissions();

  const listFilters = [
    <BetweenDatesInput alwaysOn />,
    <ReferenceArrayInput
      source="employeeIds"
      reference="employees"
      perPage={null}
      sort={{ field: "name", order: "ASC" }}
      filter={{ chainId: selectedChain ? selectedChain : getChainId() }}
      style={{ minWidth: 160 }}
      alwaysOn
    >
      <SelectArrayInput label="Funcionários" emptyText="Todos" optionText="name" />
    </ReferenceArrayInput>,
  ];

  if (permissions && permissions.includes(PERMISSIONS.ADMIN)) {
    listFilters.push(
      <ReferenceInput
        source="chainId"
        reference="chains"
        perPage={null}
        sort={{ field: "name", order: "ASC" }}
        onChange={({ target }) => setSelectedChain(target.value)}
        alwaysOn
      >
        <SelectInput
          label="Rede"
          emptyText="Todas"
          optionText="name"
        />
      </ReferenceInput>
    );
  }

  return (
    <Permission permission={PERMISSIONS.APPROVED_TRANSACTIONS_REPORT}>
      <ReportList
        {...props}
        basePath="report-approved-transactions"
        title="Transações Aprovadas"
        resource="chains/chainId/reports/approved-transactions"
        filters={listFilters}
        sort={{ field: 'createdAt', order: 'DESC' }}
        perPage={25}
        pagination={<ListPagination />}
        filterDefaultValues={{
          chainId: getChainId(),
          from: moment().startOf('day').subtract(1, "month").toISOString(),
          to: moment().endOf('day').toISOString()
        }}
        bulkActionButtons={false}
        exporter={false}
        titleOnPrint="Transações Aprovadas"
        filterTablePrint={<FilterTablePrint />}
        landscape
      >
        <ApprovedTransactions />
      </ReportList>
    </Permission >
  );
};

export default ReportApprovedTransactions;