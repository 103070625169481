import React, { useState, useEffect } from 'react';
import { useNotify } from 'react-admin';
import { PERMISSIONS, DEFAULT_API_ADMIN } from '../../constants';
import { to, getChainId, getPlaceId, get } from '../../lib';
import {
    Loader,
    TableGeneric,
    DropDownPartners,
    ReportLayout
} from '../../components';
import { Switch, FormControlLabel } from '@mui/material';

const MinimumStockReport = ({ data }) =>
    <TableGeneric
        headCells={[
            'Produto',
            'Ponto de troca',
            'Estoque atual',
            'Estoque mínimo',
            'Diferença'
        ]}
        arrayData={data}
        title="Extrato de estoque mínimo"
        style={{ width: '100%' }}
    />

export default function ReportMinimumStock() {
    const [allProducts, setAllProducts] = useState();
    const [allPlaces, setAllPlaces] = useState();
    const [selectedProducts, setSelectedProducts] = useState();
    const [dropDownProducts, setDropDownProducts] = useState();
    const [selectedProduct, setSelectedProduct] = useState();
    const [selectedPlace, setSelectedPlace] = useState(getPlaceId());
    const [loading, setLoading] = useState();
    const [isBelowMinimumStock, setIsBelowMinimumStock] = useState(false);
    const notify = useNotify();

    const updateSelectedProducts = (products) => {
        const tableProducts = [];

        if (selectedPlace) {
            const placeProducts = products.filter(p => p.productPlaces.find(pp => pp.placeId === selectedPlace));
            setDropDownProducts(placeProducts);
        } else {
            setDropDownProducts(products);
        }
        products.forEach(p => {
            if ((selectedProduct && p.id === selectedProduct) || !selectedProduct) {
                p.productPlaces.forEach(pp => {
                    if ((selectedPlace && pp.placeId === selectedPlace) || !selectedPlace) {
                        tableProducts.push({
                            name: p.name,
                            placeName: pp.place.name,
                            currentStock: pp.currentStock,
                            minimumStock: pp.minimumStock,
                            difference: pp.difference
                        });
                    }
                })
            }
        })

        setSelectedProducts(tableProducts);
    }

    const fetchProducts = async () => {
        const filter = { stock: true, hasMinimumStock: true, placeId: getPlaceId() || undefined }

        const [error, response] = await to(get(`${DEFAULT_API_ADMIN}/chains/${getChainId()}/products?filter=${JSON.stringify(filter)}`));

        if (error) {
            notify('Erro ao carregar produtos da rede', { type: 'warning' });
            return;
        }
        response.data.forEach(p => {
            p.productPlaces.forEach(pp => {
                pp.currentStock = +pp.warehouse + +pp.showcase;
                pp.difference = pp.currentStock - pp.minimumStock;
            })
        })
        setAllProducts(response.data);
        updateSelectedProducts(response.data);
    }

    const fetchPlaces = async () => {
        const filter = { chainId: getChainId() }
        const order = { name: "ASC" }
        const placeId = getPlaceId();
        const placeIdPath = placeId ? `/${placeId}` : '';

        const [error, response] = await to(get(`${DEFAULT_API_ADMIN}/places${placeIdPath}?filter=${JSON.stringify(filter)}&order=${JSON.stringify(order)}`));

        if (error) {
            notify('Erro ao carregar locais da rede', { type: 'warning' });
            return;
        }

        setAllPlaces(response.data);
    }

    useEffect(() => {
        if (selectedProduct) {
            setSelectedProduct(null);
        }
    }, [selectedPlace]);

    useEffect(() => {
        if (allProducts) {
            updateSelectedProducts(allProducts);
        }
    }, [selectedPlace, selectedProduct]);

    useEffect(() => {
        const onInit = async () => {
            setLoading(true);
            await fetchProducts();
            if (!getPlaceId()) { await fetchPlaces(); }
            setLoading(false);
        }
        onInit();
    }, []);

    const filters = [{
        place: selectedPlace && allPlaces ? allPlaces.find(p => p.id === selectedPlace).name : 'Todos',
        product: selectedProduct ? allProducts.find(p => p.id === selectedProduct).name : 'Todos',
        redeemed: isBelowMinimumStock ? 'Abaixo do estoque mínimo' : 'Todos',
    }]

    return (
        <ReportLayout
            permission={PERMISSIONS.MINIMUM_STOCK_REPORT}
            title='Relatório de Estoque Mínimo'
            filterTablePrint={
                <TableGeneric
                    headCells={[
                        'Local',
                        'Produto',
                        'Estoque mínimo'
                    ]}
                    arrayData={filters}
                    title="Filtros"
                    style={{ width: '100%', margin: '1em 0' }}
                />
            }
            filters={[
                <>{!getPlaceId() &&
                    <DropDownPartners
                        label="Local de troca"
                        value={selectedPlace}
                        setValue={setSelectedPlace}
                        arrayData={allPlaces}
                    />
                }</>,
                <DropDownPartners
                    label="Produto"
                    value={selectedProduct || null}
                    setValue={setSelectedProduct}
                    arrayData={dropDownProducts}
                />,
                <FormControlLabel
                    control={
                        <Switch
                            checked={isBelowMinimumStock}
                            onChange={() => setIsBelowMinimumStock(!isBelowMinimumStock)}
                        />
                    }
                    label='Apenas produtos abaixo do estoque mínimo'
                />
            ]}
            content={loading ? <Loader /> : selectedProducts ? <MinimumStockReport data={isBelowMinimumStock ? selectedProducts.filter(p => p.difference < 0) : selectedProducts} /> : null} />
    )
}