import React from 'react';
import { Create, TextInput, required, SimpleForm, useNotify, useRedirect, ImageInput, ImageField, NumberInput } from 'react-admin';
import { PERMISSIONS } from '../../constants';
import Permission from '../../components/Permission';
import { ImageInformations } from '../../components';

const ProductsCategoryCreate = props => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = ({ data }) => {
        notify('ra.notification.created', 'info', { smart_count: 1 });
        redirect('list', '/chains/chainId/product-categories');
    };
    return (
        <Permission permission={PERMISSIONS.CREATE_PRODUCT_CATEGORIES}>
            <Create {...props} title="Cadastrar nova categoria de produtos" onSuccess={onSuccess}>
                <SimpleForm>
                    <TextInput source="name" label="Nome" InputProps={{ autoComplete: 'off' }} validate={required()} />
                    <ImageInput source="imagefile" label="Imagem" accept="image/*">
                        <ImageField source="src" label="Imagem" />
                    </ImageInput>
                    <ImageInformations position={-20}/>
                    <NumberInput source="appIndex" label="Posição na tela inicial do App" />
                </SimpleForm>
            </Create>
        </Permission>
    )
}

export default ProductsCategoryCreate;
