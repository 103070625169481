import { Chip } from '@mui/material';
import { orderBy } from 'lodash';
import { SelectArrayInput, SelectArrayInputProps } from 'ra-ui-materialui';
import React from 'react';

export const EmojiRating = ['\u{1F620}', '\u{1F641}', '\u{1F610}', '\u{1F603}', '\u{1F929}'];

const EmojiArrayInput: React.FC<SelectArrayInputProps & { emojiSize?: number }> = (props) => {

  const { source, label, choices, validate, emojiSize, ...rest } = props;

  return (
    <SelectArrayInput
      source={source}
      label={label || source}
      choices={[1, 2, 3, 4, 5].map(item => ({ id: item, name: EmojiRating[item - 1] }))}
      parse={value => orderBy(value)}
      options={{
        renderValue: (selectItems: any) => {
          return (
            <div style={{ display: 'flex', gap: 5 }}>
              {selectItems.map((item: string) => <Chip label={EmojiRating[Number(item) - 1]} size="medium" sx={{ fontSize: emojiSize || 20 }} />)}
            </div>
          );
        }
      }}
      {...rest}
    />
  );
}

export default EmojiArrayInput;