import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from "moment";
import {
  Datagrid,
  DateField,
  Filter,
  FormDataConsumer,
  FunctionField,
  List,
  NumberField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TextField,
  TopToolbar,
  useListContext,
  usePermissions,
} from "react-admin";
import { CheckChainUsesModule, CustomExportButton, CustomizableDatagrid, ModuleKey, Permission } from "../../components";
import { BetweenDatesInput } from "../../components/BetweenDatesInput";
import { PERMISSIONS } from "../../constants";
import { getChainId, getEmployeeType } from "../../lib";
import { EmployeeType, FillinOrigin, TagType } from "../../models";

const useStyles = makeStyles(theme => ({
  title: {
    margin: '8px 0 8px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  refArrayInput: {
    minWidth: 160,
    '& .MuiFormHelperText-marginDense': {
      height: 0
    }
  }
}));

const ListActions = () => {
  const preExporter = async (data, fetchRelatedRecords) => {
    const fillins = data[0].data;
    const relations = [
      { field: 'attendantId', resource: 'attendants' },
      { field: 'placeId', resource: 'stations' },
      { field: 'id', resource: 'customers' },
    ];
    const fetchData = await fetchRelatedRecords(relations, fillins);

    const getData = (resource) => {
      return fetchData.find(({ resource: res }) => res === resource)?.results || [];
    }

    fillins.forEach(fillin => {
      const { attendantId, placeId, id: customerId } = fillin;
      const attendant = getData('attendants').find(({ id }) => id === attendantId);
      const place = getData('stations').find(({ id }) => id === placeId);
      const customer = getData('customers').find(({ id }) => id === customerId);

      fillin.attendant_name = attendant?.name || 'Não identificado';
      fillin.place_name = place?.name || 'Não identificado';
      fillin.customer_name = customer?.name || 'Não identificado';
    });

    return fillins;
  };

  return (
    <TopToolbar>
      <CustomExportButton preExport={preExporter} />
    </TopToolbar>
  );
};

const FirstFillinList = () => {
  const classes = useStyles();
  const props = useListContext();

  let attendantSummaryData = [], stationSummaryData = [], totalCustomersSummaryData = [];
  const data = [];

  if (props.data) {
    const reportInfo = props.data[0];

    if (reportInfo) {
      reportInfo.data.forEach(({ id, ...item }) => {
        data.push({ ...item, customerId: id });
      });
      attendantSummaryData = reportInfo.summaryData.attendantSummaryData;
      stationSummaryData = reportInfo.summaryData.stationSummaryData;
      totalCustomersSummaryData = reportInfo.summaryData.totalCustomersSummaryData;
    }
  }

  return (
    <>
      <Typography className={classes.title}>Primeiro Abastecimento</Typography>
      <Datagrid bulkActionButtons={false} data={totalCustomersSummaryData} style={{ marginBottom: 60 }} total={1}>
        <NumberField
          source="totalCustomers"
          label="Total de Clientes"
          textAlign="center"
          emptyText="Não definido"
          sortable={false}
        />
      </Datagrid>
      <Typography className={classes.title}>Top 15 Frentistas</Typography>
      <Datagrid bulkActionButtons={false} data={attendantSummaryData} style={{ marginBottom: 50 }}>
        <ReferenceField source="attendant_id" reference="attendants" label="Frentista" basePath="attendants" sortable={false} emptyText="Não identificado">
          <FunctionField render={record => record && (record.name || record.identfid) ? record.name || record.identfid : 'Não identificado'} label='Nome/Identfid' emptyText="Não identificado" />
        </ReferenceField>
        <NumberField textAlign="center" source="count" label="Total de Clientes" sortable={false} />
      </Datagrid>

      <Typography className={classes.title}>Top Postos de Combustível</Typography>
      <Datagrid bulkActionButtons={false} data={stationSummaryData} style={{ marginBottom: 50 }}>
        <ReferenceField source="place_id" reference="stations" label="Posto" basePath="stations" sortable={false}>
          <TextField source="name" />
        </ReferenceField>
        <NumberField textAlign="center" source="count" label="Total de Clientes" sortable={false} />
      </Datagrid>

      <Typography className={classes.title}>Primeiro Abastecimento de Clientes</Typography>
      <Datagrid bulkActionButtons={false} resource={props.resource} rowClick={null} data={data}>
        <ReferenceField source="customerId" reference="customers" label="Cliente" basePath="customers" sortable={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="attendantId" reference="attendants" label="Frentista" basePath="attendants" emptyText="Não identificado" sortable={false}>
          <FunctionField render={record => record && (record.name || record.identfid) ? record.name || record.identfid : 'Não identificado'} label='Nome/Identfid' emptyText="Não identificado" />
        </ReferenceField>
        <ReferenceField source="placeId" reference="stations" label="Posto" basePath="stations" sortable={false}>
          <TextField source="name" />
        </ReferenceField>
        <CheckChainUsesModule module={ModuleKey.PROMOTER} label="Promoter" basePath="promoters" sortable={false}>
          <ReferenceField source="promoterId" reference="promoters" emptyText="Não definido">
            <TextField source="name" />
          </ReferenceField>
        </CheckChainUsesModule>
        <DateField source="createdAt" label="Data do Abastecimento" sortable={false} showTime />
      </Datagrid>
    </>
  );
}

const FilterFirstFillins = (props) => {
  const employeeType = getEmployeeType();
  const classes = useStyles();
  const { permissions } = usePermissions();

  return (
    <Filter {...props}>
      <BetweenDatesInput alwaysOn options={{ disableFuture: true }} />
      <FormDataConsumer alwaysOn>
        {({ formData }) => (
          <ReferenceInput
            source="placeId"
            reference="stations"
            sort={{ field: "name", order: "ASC" }}
            perPage={null}
            filter={permissions === PERMISSIONS.ADMIN ? {} : { chainId: getChainId(), type: 'station' }}
          >
            <SelectInput
              label="Posto"
              emptyText="Todos"
              optionText="name"
              disabled={formData.tagIds}
            />
          </ReferenceInput>
        )}
      </FormDataConsumer>
      <ReferenceArrayInput
        source="attendantsIds"
        reference="attendants"
        perPage={null}
        sort={{ field: "name", order: "ASC" }}
        filter={{ chainId: getChainId() }}
        style={{ minWidth: 160 }}
        alwaysOn
      >
        <SelectArrayInput
          label="Frentistas"
          emptyText="Todos"
          optionText="name"
        />
      </ReferenceArrayInput>
      <Permission permission={PERMISSIONS.TAG} alwaysOn>
        <FormDataConsumer >
          {({ formData }) => (
            <ReferenceArrayInput
              source="tagIds"
              reference="chains/chainId/tag"
              perPage={null}
              sort={{ field: "name", order: "ASC" }}
              filter={{ type: TagType.station }}
              className={classes.refArrayInput}
            >
              <SelectArrayInput
                label="Etiquetas"
                optionText="name"
                disabled={formData.placeId} />
            </ReferenceArrayInput>
          )}
        </FormDataConsumer>
      </Permission>
      <SelectArrayInput
        label="Origem"
        source="origin"
        choices={FillinOrigin.fillinOriginChoices}
        style={{ minWidth: 160 }}
        alwaysOn
      />
      {
        (employeeType === EmployeeType.admin || [EmployeeType.chain, EmployeeType.place].includes(employeeType)) &&
        <CheckChainUsesModule module={ModuleKey.PROMOTER} alwaysOn>
          <ReferenceArrayInput
            source="promoterIds"
            reference="promoters"
            sort={{ field: "name", order: "ASC" }}
            perPage={null}
            filter={{ chainId: getChainId() }}
          >
            <SelectArrayInput
              emptyText="Todos"
              label="Promoter"
              style={{ minWidth: 180 }}
              helperText={false}
              optionText={'name'}
            />
          </ReferenceArrayInput>
        </CheckChainUsesModule>
      }
    </Filter>
  );
}

const FirstFillinsReport = (props) => {
  const { permissions, isLoading } = usePermissions();

  return (
    <>
      {!isLoading &&
        <List
          {...props}
          title="Primeiro Abastecimento de Clientes"
          basePath="reports"
          resource="chains/chainId/reports/first-fillin"
          sort={{ field: "createdAt", order: "DESC" }}
          filters={<FilterFirstFillins />}
          filter={permissions === PERMISSIONS.ADMIN ? {} : { chainId: getChainId() }}
          filterDefaultValues={{ from: moment().startOf('day').toISOString(), to: moment().endOf('day').toISOString() }}
          actions={<ListActions />}
        >
          <FirstFillinList />
        </List>
      }
    </>
  );
}

export default FirstFillinsReport;