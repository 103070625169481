import { formatCPF } from '@brazilian-utils/brazilian-utils';
import PropTypes from 'prop-types';
import { FunctionField, useRecordContext } from 'react-admin';
import { PERMISSIONS } from '../constants';
import { formatCellphone, formatCnpj, formatPartiallyHiddenCpf } from "../utils";
import Permission from './Permission';

export const CNPJField = ({ source }) => {
  const record = useRecordContext();
  if (!record) { return null; }

  return <span>{formatCnpj(record[source])}</span>;
}

CNPJField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

CNPJField.defaultProps = {
  addLabel: true,
};

export const CPFField = ({ source }) => {
  const record = useRecordContext();
  if (!record) { return null; }

  return <span>{formatCPF(record[source])}</span>;
}

CPFField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

CPFField.defaultProps = {
  addLabel: true,
};

export const PartiallyHiddenCpfField = ({ source }) => {
  const record = useRecordContext();
  if (!record) { return null; }

  return (
    <Permission permission={PERMISSIONS.ADMIN} defaultElement={<CPFField source={source} record={record} />}>
      <span>{formatPartiallyHiddenCpf(record && record[source])}</span>
    </Permission>
  );
};

PartiallyHiddenCpfField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  textAlign: PropTypes.oneOf([
    'inherit',
    'left',
    'center',
    'right',
    'justify',
  ]),
};

PartiallyHiddenCpfField.defaultProps = {
  addLabel: true,
};

export const PhoneField = ({ source }) => {
  const record = useRecordContext();
  if (!record) { return null; }

  return <span>{formatCellphone(record[source])}</span>;
}

PhoneField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

PhoneField.defaultProps = {
  addLabel: true,
};

export const FullAdressField = ({ label }) => {
  const record = useRecordContext();
  if (!record) { return null; }

  const { street, streetNumber, street_number, complement, neighbourhood, city, state } = record;

  return (
    <FunctionField
      label={label}
      sortable={false}
      render={
        record => record &&
          (street ? street : '') +
          (streetNumber || street_number ? `, n° ${streetNumber || street_number}` : '') +
          (complement ? `, ${complement}` : '') +
          (neighbourhood ? ` - ${neighbourhood}` : '') +
          (city ? `, ${city}` : '') +
          (state ? ` - ${state}` : '')} />
  );
};
