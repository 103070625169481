import React from 'react';
import { List, Datagrid, TextField, ImageField, EditButton, DeleteButton } from 'react-admin';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import ListPagination from '../../components/ListPagination';
import { makeStyles } from '@mui/styles';
import RequiredChain from '../../components/RequiredChain';

const listStyles = makeStyles({
    image: {
        '& img': {
            maxWidth: '4rem',
        },
    },
});

const ProductsCategoryList = props => {
    const classes = listStyles();

    return (
        <Permission permission={PERMISSIONS.CREATE_PRODUCT_CATEGORIES}>
            <RequiredChain>
                <List
                    {...props}
                    perPage={25}
                    pagination={<ListPagination />}
                    exporter={false}
                    bulkActionButtons={false}
                    filter={{ grid: null }}
                    sort={{ field: "appIndex", order: "ASC" }}
                    title="Categorias de produtos"
                >
                    <Datagrid rowClick="edit">
                        <TextField source="name" label="Nome" />
                        <ImageField className={classes.image} source="imagePath" label="Imagem" />
                        <TextField label="Posição na tela inicial do App" source="appIndex" style={{ marginLeft: '25%' }} />
                        <EditButton />
                        <DeleteButton />
                    </Datagrid>
                </List>
            </RequiredChain>
        </Permission>
    );
}

export default ProductsCategoryList;
