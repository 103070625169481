import { useCallback } from 'react';
import { useAuthProvider } from 'react-admin';
import { useLocation, useNavigate } from 'react-router-dom';

interface UpdatePasswordProps {
  id: string;
  params: any;
  user: any;
  pathName?: string;
}

const useUpdatePassword = () => {
  const authProvider = useAuthProvider();
  const location = useLocation();
  const locationState = location.state as any;
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const nextPathName = locationState && locationState.nextPathname;
  const nextSearch = locationState && locationState.nextSearch;

  const updatePassword = useCallback(
    ({ id, params, user, pathName }: UpdatePasswordProps) =>
      authProvider.updatePassword(id, params || {}, user).then(ret => {
        // dispatch(resetNotification());
        const redirectUrl = pathName
          ? pathName
          : nextPathName + nextSearch;
        navigate(redirectUrl);
        return ret;
      }),
    // [authProvider, history, nextPathName, nextSearch, dispatch]
    [authProvider, history, nextPathName, nextSearch]
  );

  return updatePassword;
};

export default useUpdatePassword;
