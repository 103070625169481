import styled from '@emotion/styled';
import React, { useState } from 'react';
import { FaRegUser } from "react-icons/fa";
import { MdOutlineCardGiftcard, MdOutlineHome, MdOutlineLocalGasStation } from "react-icons/md";
import { PiGasCan } from 'react-icons/pi';
import frameIphone from '../../../assets/images/frame-iphone-14.png';

import { Typography } from '@mui/material';
import { useGetOne } from 'react-admin';
import { getChainId } from '../../../lib';
import { hexToRGBA } from '../../../utils';

const WIDTH = 292;
const HEIGHT = 590;

const Container = styled('div')({
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  borderRadius: 10,
  margin: '20px 10px',
  aspectRatio: '1',
  backgroundColor: 'black',
  alignItems: 'center',
  '& img': {
    width: '100%',
  },
  '& video': {
    width: '100%',
  },
});

const MobileContainer = styled('div')({
  width: WIDTH - 40,
  height: HEIGHT - 90,
  backgroundColor: 'white',
  margin: '60px 20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: 10,
});

const MobileFrame = styled('img')({
  position: 'absolute',
  width: WIDTH,
  height: HEIGHT,
  top: -60,
});

const BottomBarContainer = styled('div')({
  position: 'absolute',
  height: 40,
  backgroundColor: 'white',
  width: WIDTH - 15,
  bottom: 5,
  boxShadow: '-1px -1px 5px -1px rgba(82,82,82,0.61)',
  padding: '5px 20px',
  display: 'flex',
  justifyContent: 'space-between'
});

const BottomBarItem = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const BottomBarLabel = styled(Typography)({
  fontSize: '8px',
});

const BottomBarCircleButton = styled('div')<{ color: string }>(({ color }) => ({
  minHeight: 40,
  width: 40,
  borderRadius: '50%',
  backgroundColor: color,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 5,
  marginTop: -25,
}));

const HeaderContainer = styled('div')(() => ({
  width: '100%',
  marginTop: 20,
  alignItems: 'center',
  justifyContent: 'center',
}));

const HeaderTitleLabel = styled(Typography)({
  fontSize: 15,
  fontWeight: 'bold',
  textTransform: 'uppercase',
  textAlign: 'center',
});

const HeaderSubTitleLabel = styled(Typography)({
  fontSize: 12,
  textAlign: 'center',
  color: '#8e8e93',
  margin: '5px 10px',
});

const ContainerDiscount = styled('div')<{ color: string }>(({ color }) => ({
  borderWidth: 1,
  borderColor: color,
  borderStyle: 'solid',
  backgroundColor: hexToRGBA(color, 0.2),
  padding: 10,
  margin: 10,
  borderRadius: 4,
}));

const TitleContainer = styled(Typography)({
  fontSize: 11,
  fontWeight: 600,
  marginBottom: 2,
});

const DiscoutCard = styled('div')({
  backgroundColor: 'white',
  borderRadius: 4,
  padding: '10px 10px',
});

const TitleDiscountCard = styled(Typography)({
  fontSize: 12,
  fontWeight: 'bold',
  textTransform: 'uppercase',
});

const ValueDiscountCard = styled(Typography)({
  fontSize: 12,
  textTransform: 'uppercase',
});

const DescriptionDiscountCard = styled(Typography)({
  fontSize: 10,
  color: '#8e8e93',
  marginTop: 2,
});

const ContainerFooter = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 10,
  margin: 10,
  width: '100%',
  position: 'absolute',
  bottom: 60,
});

const DiscountDisclaimerLabel = styled(Typography)({
  fontSize: 9,
  margin: '5px 12px',
  textAlign: 'center',
});

const Button = styled('div')<{ color: string }>(({ color }) => ({
  backgroundColor: color,
  borderRadius: 8,
  padding: '8px 10px',
  width: '60%',
}));

const AvailableOfferOnMobile: React.FC<{ data: any }> = ({ data }) => {

  const [chainId] = useState(getChainId());
  if (!chainId) { return <Typography>Selecione uma Rede</Typography>; }

  const { data: chain } = useGetOne('chains', { id: chainId });

  const BottomBar = ({ color }) => (
    <BottomBarContainer>
      <BottomBarItem>
        <MdOutlineHome fontSize={18} />
        <BottomBarLabel>Início</BottomBarLabel>
      </BottomBarItem>
      <BottomBarItem>
        <MdOutlineCardGiftcard fontSize={18} />
        <BottomBarLabel>Resgatar</BottomBarLabel>
      </BottomBarItem>
      <BottomBarItem>
        <BottomBarCircleButton color={color}>
          <PiGasCan fontSize={18} color="white" />
        </BottomBarCircleButton>
        <BottomBarLabel>Abastecer</BottomBarLabel>
      </BottomBarItem>
      <BottomBarItem>
        <MdOutlineLocalGasStation fontSize={18} />
        <BottomBarLabel>Postos</BottomBarLabel>
      </BottomBarItem>
      <BottomBarItem>
        <FaRegUser fontSize={15} />
        <BottomBarLabel>Perfil</BottomBarLabel>
      </BottomBarItem>
    </BottomBarContainer>
  );

  return (
    !chain || !chain.appColor ? <></> :
      <div style={{ position: 'relative', width: WIDTH }}>
        <MobileContainer>
          <HeaderContainer>
            <HeaderTitleLabel>{data.appLabel.discountTitleLabel || 'Ofertas disponíveis'}</HeaderTitleLabel>
            <HeaderSubTitleLabel>{data.appLabel.discountSubtitleLabel || 'Você poderá aplicá-las no próximo passo'}</HeaderSubTitleLabel>
          </HeaderContainer>

          <ContainerDiscount color={chain.appColor}>
            <TitleContainer>Resgates ilimitados</TitleContainer>
            <DiscoutCard>
              <TitleDiscountCard>ETANOL</TitleDiscountCard>
              <ValueDiscountCard>R$ 0,10 DE DESCONTO</ValueDiscountCard>
              <DescriptionDiscountCard>Serão debitados 10 pontos ao aplicar essa oferta</DescriptionDiscountCard>
            </DiscoutCard>
            <Typography fontSize={10} align="center" sx={{ marginTop: '5px' }}>* Valores demonstrativos *</Typography>
          </ContainerDiscount>

          <ContainerFooter>
            {
              data.appLabel.discountDisclaimerLabel &&
              <DiscountDisclaimerLabel>{data.appLabel.discountDisclaimerLabel}</DiscountDisclaimerLabel>
            }
            <Button color={chain.appColor}>
              <Typography color="white" fontSize={14} align="center">Continuar</Typography>
            </Button>
          </ContainerFooter>

          <BottomBar color={chain.appColor} />
        </MobileContainer>

        <MobileFrame src={frameIphone} style={{ zIndex: 9 }} draggable={false} />
      </div>
  );
}

export default AvailableOfferOnMobile;