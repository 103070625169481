import React, { useState, useEffect } from 'react';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import { PERMISSIONS } from '../../constants';
import { Tooltip, PieChart, Pie, Cell, Legend } from "recharts";
import { formatReal, getChainId, getEmployeeType, getAttendantId } from '../../lib';
import useDashboardContext from '../../hooks/useDashboardContext';
import { DashboardWrapper } from '../../components';
import moment from 'moment';
import { EmployeeType } from '../../models';

const Tip = ({ setShowTooltip, ...rest }) => {
    const [payload, setPayload] = useState(rest.payload);

    useEffect(() => {
        rest.payload.length && setPayload(rest.payload);
    }, [rest.payload]);

    return payload.length ? (
        <div
            onMouseLeave={() => setShowTooltip(false)}
            onMouseMove={e => e.stopPropagation()}
            style={{
                background: "white",
                padding: "1em",
                borderRadius: "2px",
                boxShadow: "0 1px 4px rgba(0,0,0,0.3)",
            }}
        >
            {`${payload[0].name}: ${formatReal(payload[0].value)}`}
        </div>
    ) : null;
};

export const FuelChart = () => {
    const [chartData, setChartData] = useState([]);
    const [showTooltip, setShowTooltip] = useState(false);

    const { permissions } = usePermissions();
    const dataProvider = useDataProvider();
    const { from, to, chain, origin, stationIds } = useDashboardContext();

    const notify = useNotify();
    const chainId = permissions && permissions.includes(PERMISSIONS.ADMIN) ? chain : getChainId();

    const COLORS = ["#484A7F", "#EF6937", "#299B22", "#909090", "#EFB810"]

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
        cx, cy, midAngle, innerRadius, outerRadius, percent, index,
    }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    useEffect(() => {
        const filter = {
            from: moment.utc(from),
            to: moment.utc(to),
            origin,
        };
        if (stationIds && stationIds.length > 0) { filter.stationIds = stationIds; }
        if (getEmployeeType() === EmployeeType.attendant) { filter.attendantId = getAttendantId(); }

        let fuelData = [];
        if (permissions && permissions.includes(PERMISSIONS.FUEL_CHART)) {
            dataProvider.getList('dashboard/fillin-by-fuel', {
                filter,
                chainId,
                sort: { field: 'totalValue', order: 'DESC' },
                pagination: { page: 1, perPage: 5 },
            })
                .then(response => {
                    const data = response.data;
                    Object.keys(data).forEach((fuel) => {
                        if (data[fuel].totalValue === 0 || data[fuel].id === '0') {
                            delete data[fuel]
                        } else if (fuelData.length < 5) {
                            fuelData = [...fuelData, { name: data[fuel].name, value: data[fuel].totalValue }];
                        }
                        setChartData(fuelData);
                    });
                })
                .catch((e) => { notify('Erro ao carregar gráfico de combustíveis', { type: 'warning' }); });
        }
    }, [permissions, from, to, chain, origin, stationIds]);

    return (
        <DashboardWrapper
            permission={PERMISSIONS.FUEL_CHART}
            width='100%'
            link='/report-fillins'
            title='Venda de Combustíveis'
            aspectRatio={5.5 / 2.5}
            content={
                <PieChart onMouseLeave={() => setShowTooltip(false)}>
                    <Legend layout="vertical" verticalAlign="top" align="left" />
                    <Pie
                        onMouseEnter={() => setShowTooltip(true)}
                        isAnimationActive={false}
                        labelLine={false}
                        dataKey="value"
                        startAngle={360}
                        endAngle={0}
                        data={chartData}
                        cx="45%"
                        cy="50%"
                        outerRadius="90%"
                        label={renderCustomizedLabel}  >
                        {
                            chartData.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                        }
                    </Pie>
                    {showTooltip && (
                        <Tooltip
                            isAnimationActive={false}
                            content={<Tip setShowTooltip={setShowTooltip} />}
                            wrapperStyle={{ visibility: "visible", pointerEvents: "auto" }}
                        />
                    )}
                </PieChart>
            }
        />
    );
};