import SaveIcon from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { SimpleForm, Edit, FileInput, FileField, Toolbar, SaveButton, useNotify, useRedirect, DateInput, required, useUpdate, TextInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Permission } from '../../components';
import { MAX_DATE, PERMISSIONS } from '../../constants';
import dayjs from 'dayjs';

const BillingPaymentToolbar = ({ ...props }) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const { getValues } = useFormContext();
  const { id, ...data } = getValues();

  const [update, { isLoading }] = useUpdate('billings-payment', {
    id,
    data: {
      paidAt: data.paidAt,
      paymentVoucher: data.paymentVoucher,
      chainNote: data.chainNote,
    }
  }, {
    onSuccess: () => {
      notify("Registro de pagamento realizado com sucesso!");
      redirect("list", "/billings-payment");
    },
    onError: () => {
      notify("Falha no registro de pagamento!", { type: "warning" })
    }
  });

  const payBilling = (e) => {
    e.preventDefault();

    if (!data.paidAt) {
      notify("Data de pagamento é obrigatório", { type: "warning" });
      return;
    }

    update();
  }

  return (
    <Toolbar {...props}>
      <SaveButton
        {...props}
        label="Pagar"
        onClick={payBilling}
        icon={isLoading ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
        disabled={isLoading}
      />
    </Toolbar>
  );
}

export default props => {
  const transform = (data) => ({ ...data, paidAt: dayjs(data.paidAt).toISOString() })

  return (
    <Permission permission={PERMISSIONS.BILLING_PAYMENT}>
      <Edit {...props} title="Pagar Fatura" resource="billings" transform={transform}>
        <SimpleForm
          {...props}
          toolbar={<BillingPaymentToolbar />}
        >
          <DateInput
            source="paidAt"
            type="date"
            label="Data de pagamento"
            validate={[required()]}
            options={{ format: "DD/MM/YYYY" }}
            inputProps={{ max: MAX_DATE }}
          />
          <FileInput
            source="paymentVoucher"
            label="Comprovante de pagamento"
          >
            <FileField source="src" title="title" />
          </FileInput>
          <TextInput
            inputProps={{ autoComplete: 'off' }}
            source="chainNote"
            label="Observação"
            multiline
            fullWidth
          />
        </SimpleForm>
      </Edit>
    </Permission>
  );
}
