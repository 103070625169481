import { FilledInput, FormControl, InputAdornment, InputLabel } from '@mui/material';
import React, { useCallback } from 'react';
import { useInput } from 'react-admin';

export interface PhoneInputProps {
  source: string;
  validate: any;
  label: string;
  prefix?: string | null;
  disabledPrefix?: boolean | null;
  maxLength?: number | null;
}

const PhoneInput: React.FC<PhoneInputProps> = (props) => {

  const DEFAULT_PREFIX = '55';
  const DEFAULT_MAX_LENGTH = 15;

  const { source, validate, label, maxLength, disabledPrefix } = props;

  const { field } = useInput({ source, validate });

  const getPrefix = () => {
    if (disabledPrefix) { return ''; }
    return (props.prefix || DEFAULT_PREFIX).toString();
  }

  const formatValue = useCallback((value: string) => {
    return (getPrefix() + value).replace(/\D/g, "");
  }, [getPrefix]);

  const parseValue = (value: string) => {
    if (!disabledPrefix && value.length >= 2 && value.slice(0, 2) === getPrefix()) {
      value = value.slice(2);
    }

    return value
      .replace(/\D/g, "")
      .replace(/(\d{7})(\d)/, "$1-$2")
      .replace(/(\d{2})(\d)/, "($1) $2");
  }

  return (
    <FormControl fullWidth variant="filled" style={{ marginTop: 8, marginBottom: 20 }}>
      <InputLabel style={{ marginTop: -4 }}>{label || source}</InputLabel>
      <FilledInput
        style={{ height: 48 }}
        startAdornment={!disabledPrefix && <InputAdornment position="start">{`+${getPrefix()}`}</InputAdornment>}
        value={parseValue(field?.value || '')}
        onChange={(event) => {
          field.onChange(formatValue(event.target.value));
        }}
        inputProps={{ maxLength: maxLength || DEFAULT_MAX_LENGTH }}
      />
    </FormControl>
  );
}

export default PhoneInput;