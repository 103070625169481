import UserIcon from '@mui/icons-material/People'
import list from './UserList'
import create from './UserCreate'
import edit from './UserEdit'

export default {
  edit,
  list,
  create,
  name: 'employees',
  permissions: [],
  options: { label: 'Usuários da rede' },
  icon: UserIcon,
}
