import { Datagrid, Filter, List, ReferenceField, ReferenceInput, SearchInput, SelectInput, TextField, usePermissions } from 'react-admin';
import { Permission, RecoveryUserExpand } from '../../components';
import ListPagination from '../../components/ListPagination';
import { PERMISSIONS } from '../../constants';
import { getChainId } from '../../lib';

const PlaceDeletedUsersDatagrid = props => {
  const { permissions } = usePermissions();

  return (
    <Datagrid {...props} bulkActionButtons={false} rowClick="" expand={<RecoveryUserExpand />}>
      {permissions && permissions.includes(PERMISSIONS.ADMIN) &&
        <ReferenceField source="chainId" reference="chains" label="Rede">
          <TextField source="name" />
        </ReferenceField>}
      <ReferenceField
        label="Parceiro"
        reference="places"
        source="placeId"
        link={false}
        emptyText=""
      >
        <TextField emptyText="" source="name" />
      </ReferenceField>
      <TextField source="name" label="Nome" />
      <TextField source="login" label="Login" />
      {permissions && permissions.includes(PERMISSIONS.ADMIN) &&
        <ReferenceField
          source="profileId"
          reference="profiles"
          emptyText="Não definido"
          label="Perfil"
        >
          <TextField source="name" />
        </ReferenceField>}
    </Datagrid>
  );
};

const ListFilters = (props) => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn />
    <ReferenceInput
      source="placeId"
      reference="places"
      filter={{ chainId: getChainId() }}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={null}
      alwaysOn
    >
      <SelectInput label="Parceiro" optionText="name" emptyText="Todos" />
    </ReferenceInput>
    <Permission permission={PERMISSIONS.ADMIN} alwaysOn>
      <ReferenceInput
        source="chainId"
        reference="chains"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={null}
      >
        <SelectInput label="Rede" optionText="name" emptyText="Todos" />
      </ReferenceInput>
    </Permission>
  </Filter>
);

const PlaceDeletedUserList = props => (
  <List
    {...props}
    basePath="_employees/recovery"
    resource="employees/recovery"
    title="Usuários Deletados de Parceiros"
    pagination={<ListPagination />}
    filter={{ isPlace: true }}
    filters={<ListFilters />}
    perPage={25}
    exporter={false}
    actions={false}
  >
    <PlaceDeletedUsersDatagrid />
  </List>
)

export default PlaceDeletedUserList;
