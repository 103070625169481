import React from 'react';
import {
  TextInput,
  required,
  SimpleForm,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  SelectInput,
  Edit,
} from 'react-admin';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';

const EditExternalPaymentMethod = (props) => (
  <Permission permission={PERMISSIONS.MANAGE_EXTERNAL_PAYMENT_METHOD}>
    <Edit
      {...props}
      title="Editar método de pagamento externo"
      transform={data => {
        return { ...data, externalPaymentMethods: data.externalPaymentMethods.map(item => ({ type: item.type })) };
      }}
    >
      <SimpleForm>
        <ReferenceInput
          sort={{ field: 'name', order: 'ASC' }}
          perPage={null}
          label="Método de pagamento"
          source="internalPaymentMethodId"
          reference="app-payment-methods"
          style={{ minWidth: 260 }}
          validate={[required()]}
          disabled
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          sort={{ field: 'name', order: 'ASC' }}
          //@ts-ignore
          perPage={null}
          label="Posto"
          source="placeId"
          reference="stations"
          style={{ minWidth: 260 }}
          validate={[required()]}
          disabled
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ArrayInput source="externalPaymentMethods" label="Pagamentos externos" validate={[required()]}>
          <SimpleFormIterator
            disableReordering
          >
            <TextInput source="type" label="Tipo" validate={[required()]} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  </Permission>
);

export default EditExternalPaymentMethod;