import { DEFAULT_API_ADMIN } from '../../constants'
import { httpClientReactAdmin } from '../../lib'
import { getChainId } from '../../lib'


export default async (resourceName, params) => {
  const URL = `${DEFAULT_API_ADMIN}/${(resourceName || '').replace('chainId', getChainId())}`;
  const dataArray = JSON.stringify(params.data);
  const body = dataArray;
  const idsToUpdate = params.ids;

  const toUpdate = await Promise.all(idsToUpdate.map(async id => await httpClientReactAdmin(`${URL}/${id}`, { method: 'PATCH', body })))

  return { data: toUpdate.map((response) => response.json) }
}
