import React from 'react'
import {
  List,
  Filter,
  Datagrid,
  TextField,
  SearchInput,
  EmailField,
  SelectInput,
} from 'react-admin'
import ListPagination from '../../components/ListPagination'

const ChainFilter = props => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn />
    <SelectInput
      source="status"
      label="Status"
      choices={[{ id: true, name: 'Ativa' }, { id: false, name: 'Inativa' }]}
      alwaysOn
    />
  </Filter>
);

export default props => (
  <List {...props}
    hasCreate
    perPage={25}
    pagination={<ListPagination />}
    exporter={false}

    title="Redes"
    resource="chains"
    filters={<ChainFilter />}
    filterDefaultValues={{ status: true }}
  >
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" label="Nome da rede/posto" />
      <TextField source="phone" label="Telefone" />
      <EmailField source="email" label="Email" />
      <TextField source="contact" label="Contato" />
      <TextField source="code" label="Código da Rede" textAlign="center" />
    </Datagrid>
  </List>
)
