import { createTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { makeStyles, ThemeProvider } from '@mui/styles';
import moment from 'moment';
import React, { useRef } from 'react';
import { PrintButton, ReportPrint, Wrapper } from '../index';
import Permission from '../Permission';

const useStyles = makeStyles({
    filterRow: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    filterWrapper: {
        margin: '0 25px 15px 0',
    },
    actionRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '10px 0'
    },
    actionWrapper: {
        margin: '0 25px 0 0',
        marginRight: 'auto',
    },
    filterTablePrint: {
        display: 'none',
        '@media print': {
            display: 'block'
        }
    },
    footnoteStamp: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    }
});

const theme = createTheme({
    typography: {
        fontSize: 12,
    },
});

const FilterRow = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.filterRow}>
            {React.Children.map(children, child => child &&
                <div className={classes.filterWrapper}>
                    {child}
                </div>
            )}
        </div>
    )
}

const ActionRow = ({ children, printButton }) => {
    const classes = useStyles();

    return (
        <div className={classes.actionRow}>
            {React.Children.map(children, child =>
                <div className={classes.actionWrapper}>
                    {child}
                </div>
            )}
            {printButton}
        </div>
    )
}

export default function ReportLayout({ filters, permission, title, filterTablePrint, content, landscape, actions }) {
    const classes = useStyles();
    const reportRef = useRef();
    return (
        <Permission permission={permission}>
            <Wrapper>
                <FilterRow>
                    {filters}
                </FilterRow>
                <ActionRow printButton={<PrintButton printRef={reportRef} className={classes.printButton} />}>
                    {actions}
                </ActionRow>
                <ReportPrint ref={reportRef} title={title} landscape={landscape ? true : false}>
                    <div className={classes.filterTablePrint}>
                        {filterTablePrint}
                    </div>
                    {content}
                    <div className={classes.footnoteStamp}>
                        <ThemeProvider theme={theme}>
                            <Typography variant="subtitle1">Relatório gerado em: {moment().format('DD/MM/YYYY HH:mm:ss')}</Typography>
                        </ThemeProvider>
                    </div>
                </ReportPrint>
            </Wrapper>
        </Permission>
    )
}