import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Fragment, useState } from 'react';
import {
  BooleanInput,
  Create,
  ImageField,
  ImageInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useRecordContext
} from 'react-admin';
import { getChainId } from '../lib';

const AddCoupon = ({ onClose }) => {
  const notify = useNotify();
  const record = useRecordContext();
  const [displayModal, setDisplayModal] = useState(true);

  const onSuccess = () => {
    notify('ra.notification.created');
    setDisplayModal(false);
    onClose();
  };

  return (
    <Fragment>
      <Dialog
        open={displayModal}
        onClose={() => { setDisplayModal(false); onClose(); }}
        aria-label="Adicionar cupom para um cliente"
      >
        <DialogTitle>{'Adicionar cupom para o cliente'}</DialogTitle>
        <DialogContent>
          <Create resource={`customers/${record.id}/coupons`} mutationOptions={{ onSuccess }}>
            <SimpleForm>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TextInput
                  label="Nome"
                  source="title"
                />
                <TextInput
                  label="Descrição"
                  source="description"
                />
                <ReferenceInput
                  source="placeId"
                  filter={{ chainId: getChainId() }}
                  reference="places"
                  sort={{ field: 'name', order: 'ASC' }}
                  perPage={9999}
                  allowEmpty
                  validate={required()}
                >
                  <SelectInput
                    label="Posto"
                    optionText="name" />
                </ReferenceInput>
                <BooleanInput
                  label="Resgatar em qualquer local"
                  source="redeemAnywhere"
                />
                <ImageInput source="imagefile" label="Imagem" accept="image/*">
                  <ImageField source="src" label="Imagem" />
                </ImageInput>
              </div>
            </SimpleForm>
          </Create>
        </DialogContent>
      </Dialog>
    </Fragment >
  )
}

export default AddCoupon;
