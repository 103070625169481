import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { makeStyles } from "@mui/styles";
import moment from 'moment';
import React, { useState } from "react";
import {
  ChipField,
  Datagrid,
  DateField,
  Filter,
  FormDataConsumer,
  FunctionField,
  NumberField,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SingleFieldList,
  TextField,
  useListContext,
  usePermissions,
} from 'react-admin';
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { CheckChainUsesModule, ModuleKey, ReportList, RotatedAxisTick } from '../../components';
import { BetweenDatesInput } from "../../components/BetweenDatesInput";
import Permission from '../../components/Permission';
import { PERMISSIONS } from "../../constants";
import { getAttendantId, getChainId, getEmployeeType, getPlaceId, getUserId } from "../../lib";
import { EmployeeType, FillinOrigin, TagType } from "../../models";
import { formatNumber } from "../../utils";

const colors = ['#608DE0', '#E0348A', '#E0AD3F', '#34E034', '#17E0DC', '#9934E0', '#E0793F', '#E05C3F', '#4612E0', '#16E097', '#E0D134', '#E016CE'];

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
}));

const CustomTooltip = React.memo(({ active, payload, formatter }) => {
  if (active && payload && payload.length) {
    return (
      <>
        <span>{payload[0].payload.date}</span>
        {
          payload.map((record, i) => (
            <Box key={i}>
              <ReferenceField sx={{ color: record.color }} label={false} source="id" textAlign="center" record={record} reference="stations" emptyText="Não identificado" link={false}>
                <TextField source="name" />
              </ReferenceField>
              <span style={{ color: record.color, marginLeft: 4 }}>{formatter(record.value)}</span>
            </Box>
          ))
        }
      </>
    );
  }
  return null;
});

const FillinByStation = () => {
  const [chartType, setChartType] = useState('totalValue');

  const props = useListContext();
  const classes = useStyles();

  let stationData = [], chartData = [];

  if (props.data) {
    const reportInfo = props.data[0];

    if (reportInfo) {
      stationData = reportInfo.data;
      chartData = reportInfo.summaryData
    }
  }

  return (
    <>
      <Datagrid bulkActionButtons={false} style={{ marginTop: 40 }} data={stationData} total={1}>
        <FunctionField
          render={record => record.id !== "0" ? <ReferenceField label="Posto" source="id" record={record} textAlign="center" reference="stations" emptyText="Todos" link={false}>
            <TextField source="name" />
          </ReferenceField> : <TextField source="undefined" emptyText="Todos" />
          }
        />
        <NumberField
          source="totalValue"
          label="Total em Dinheiro"
          textAlign="center"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
        <NumberField
          source="totalAmount"
          label="Total em Litros"
          textAlign="center"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
          sortable={false}
        />
        <TextField
          source="totalFillins"
          textAlign="center"
          label="Total de abastecimentos"
          sortable={false}
        />
        <NumberField
          source="totalDiscounts"
          label="Total de descontos"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
        <FunctionField
          render={record => `${formatNumber(record.totalValue / record.totalFillins || 0, { style: 'currency', currency: 'BRL' })}`}
          label="Ticket Médio"
          textAlign="center"
        />
        <FunctionField
          render={record => `${record.totalAmount ? (record.totalAmount / record.totalFillins).toFixed(2) : 0}`}
          label="Volume Médio"
          textAlign="center"
        />
        <FunctionField
          render={record => `${record.totalCntRating ? (record.totalRating / record.totalCntRating).toFixed(2) : 0}`}
          label="Nota Média"
          textAlign="center"
        />
        <DateField label="Último abastecimento" source="lastFillin" showTime sortable={false} emptyText="--" textAlign="center" />
      </Datagrid>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 30 }}>
        <FormControl>
          <RadioGroup row defaultValue='totalValue' onChange={({ target: { value } }) => {
            setChartType(value);
            // // work around for line animation
            stationData = { ...stationData, chartData: [...chartData] };
          }}>
            <FormControlLabel value='totalValue' label='Dinheiro' control={<Radio />} />
            <FormControlLabel value='totalFillins' label='Número de Abastecimentos' control={<Radio />} />
            <FormControlLabel value='totalAmount' label='Volume' control={<Radio />} />
          </RadioGroup>
        </FormControl>
        <LineChart width={790} height={600} data={chartData ? chartData : []}>
          <XAxis dataKey="date" tick={<RotatedAxisTick fontSize={16} />} height={100} />
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis domain={['auto', 'auto']} type='number' />
          <Tooltip
            formatter={(value) => {
              switch (chartType) {
                case 'totalValue':
                  return formatNumber(value, { style: 'currency', currency: 'BRL' });
                case 'totalAmount':
                  return formatNumber(value, { style: 'decimal', unit: 'liter' });
                case 'totalFillins':
                  return `${formatNumber(value)} Abastecimentos`;
                default:
                  return ''
              }
            }}
            content={<CustomTooltip />}
          />
          {chartData.length && Object.values(stationData).map((station, i) =>
            chartData.some(point => point[`${station.id}-totalFillins`]) &&
            <Line
              connectNulls
              type='monotone'
              dataKey={`${station.id}-${chartType}`}
              stroke={i <= 11 ? colors[i] : colors[((i + 1) % 12) - 1]}
              activeDot={{ r: 5 }}
              key={i}
              id={station.id}
            />
          )}
        </LineChart>
      </div>
    </>
  );
};

const FilterTablePrint = () => {
  const { filterValues } = useListContext();
  return (
    <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
      <DateField source="from" textAlign="center" label="De" sortable={false} />
      <DateField source="to" textAlign="center" label="Até" sortable={false} />
      <ReferenceField label="Posto" emptyText="Todos" source="stationId" textAlign="center" basePath="stations" reference="stations" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <Permission permission={PERMISSIONS.TAG}>
        <ReferenceArrayField label="Etiquetas" emptyText="Todas" source="tagIds" textAlign="center" reference='chains/chainId/tag'>
          <SingleFieldList>
            <ChipField source='name' />
          </SingleFieldList>
        </ReferenceArrayField>
      </Permission>
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
    </Datagrid>
  );
}

const FiltersReportList = (props) => {
  const { permissions } = usePermissions();
  const { filterValues } = useListContext();
  const employeeType = getEmployeeType();

  return (
    <Filter {...props}>
      <BetweenDatesInput alwaysOn />
      {(permissions && permissions.includes(PERMISSIONS.ADMIN) &&
        <ReferenceInput
          source="chainId"
          reference="chains"
          sort={{ field: "name", order: "ASC" }}
          perPage={null}
          alwaysOn
        >
          <SelectInput
            label="Rede"
            emptyText="Todos"
            style={{ minWidth: 180 }}
            defaultValue={getChainId()}
            optionText={'name'}
          />
        </ReferenceInput>
      )}
      <ReferenceArrayInput
        source="stationIds"
        reference="stations"
        perPage={null}
        filter={{ chainId: filterValues.chainId, ...(getEmployeeType() === EmployeeType.attendant && { attendantId: getAttendantId() }) }}
        sort={{ field: "name", order: "ASC" }}
        alwaysOn
      >
        <SelectArrayInput
          label="Posto"
          emptyText="Todos"
          optionText={'name'}
          style={{ minWidth: 160 }}
          disabled={filterValues.tagIds} />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="fuelIds"
        reference="fuels"
        sort={{ field: "name", order: "ASC" }}
        alwaysOn
      >
        <SelectArrayInput
          emptyText="Todos"
          label="Produto"
          optionText={'name'}
          style={{ minWidth: 160 }} />
      </ReferenceArrayInput>
      <SelectArrayInput
        label="Origem"
        source="origin"
        choices={FillinOrigin.fillinOriginChoices}
        emptyText="Todos"
        style={{ minWidth: 160 }}
        alwaysOn
      />
      {
        (employeeType === EmployeeType.admin || [EmployeeType.chain, EmployeeType.place].includes(employeeType)) &&
        <CheckChainUsesModule module={ModuleKey.PROMOTER} alwaysOn>
          <FormDataConsumer>
            {({ formData }) => (
              <ReferenceArrayInput
                source="promoterIds"
                reference="promoters"
                sort={{ field: "name", order: "ASC" }}
                filter={formData.chainId ? { chainId: formData.chainId } : {}}
              >
                <SelectArrayInput
                  label="Promoter"
                  emptyText="Todos"
                  style={{ minWidth: 180 }}
                  helperText={false}
                  optionText={'name'}
                />
              </ReferenceArrayInput>
            )}
          </FormDataConsumer>
        </CheckChainUsesModule>
      }
      <Permission permission={PERMISSIONS.TAG} alwaysOn>
        <ReferenceArrayInput
          source="tagIds"
          reference="chains/chainId/tag"
          sort={{ field: "name", order: "ASC" }}
          filter={{ type: TagType.station, chainId: filterValues.chainId }}
        >
          <SelectArrayInput
            label="Etiqueta"
            style={{ minWidth: 160 }}
            optionText={'name'}
            disabled={filterValues.stationIds}
          />
        </ReferenceArrayInput>
      </Permission>
    </Filter>
  );
}

const fillinByStationExport = async (data, fetchRelatedRecords) => {
  let fillinByStationInfo = data[0].data;

  const relations = [
    { field: 'id', resource: 'stations' },
  ];

  const fetchData = await fetchRelatedRecords(relations, fillinByStationInfo.filter((station) => station.id !== '0'));

  const getData = (resource) => {
    return fetchData.find(({ resource: res }) => res === resource)?.results || [];
  }

  return fillinByStationInfo.map((fillinStation) => {
    const { id: stationId } = fillinStation;
    const station = getData('stations').find(({ id }) => id === stationId);

    return {
      posto: stationId === '0' ? fillinStation.name : station.name,
      total_em_dinheiro: fillinStation.totalValue,
      total_em_litros: fillinStation.totalAmount,
      total_de_abastecimentos: fillinStation.totalFillins,
      total_de_descontos: fillinStation.totalDiscounts,
      ticket_medio: formatNumber((fillinStation.totalValue / fillinStation.totalFillins) || 0, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      volume_medio: formatNumber(fillinStation.totalAmount ? (fillinStation.totalAmount / fillinStation.totalFillins) : 0, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      nota_media: formatNumber(fillinStation.totalCntRating ? (fillinStation.totalRating / fillinStation.totalCntRating) : 0, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      ultimo_abastecimento: fillinStation.lastFillin
    }
  });
}

const ReportFillinByStation = (props) => {
  const placeId = getPlaceId();

  const filterValues = {
    chainId: getChainId(),
    from: moment().startOf('day').subtract(1, "month").toISOString(),
    to: moment().endOf('day').toISOString()
  };

  if (placeId) {
    filterValues.stationIds = [placeId];
  }

  if (getEmployeeType() === EmployeeType.promoter) {
    filterValues.promoterIds = [getUserId()];
  }

  if (getEmployeeType() === EmployeeType.attendant) {
    filterValues.attendantIds = [getAttendantId()];
  }

  return (
    <Permission permission={PERMISSIONS.FILLIN_BY_STATION_REPORT}>
      <ReportList
        {...props}
        basePath="fillin-by-station"
        title="Abastecimentos por Posto"
        resource="chains/chainId/reports/fillin-by-station"
        filters={<FiltersReportList />}
        sort={{ field: 'created_at', order: 'DESC' }}
        filterDefaultValues={filterValues}
        showPagination={false}
        bulkActionButtons={false}
        customExporter={fillinByStationExport}
        titleOnPrint="Relatório de Abast. por Combustível"
        filterTablePrint={<FilterTablePrint />}
        landscape
      >
        <FillinByStation />
      </ReportList>
    </Permission >
  );
};

export default ReportFillinByStation;