import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateTimeInput, NumberInput, SaveButton } from 'react-admin';
import { DropDownPartners } from '../../components';
import { getPlaceId } from '../../lib/';

const useStyles = makeStyles({
    imageWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        height: 100,
        width: 120,
    },
    button: {
        margin: '25px auto'
    },
    row: {
        display: 'flex',
        alignItems: "flex-end",
        justifyContent: 'space-between',
        margin: '0 0 10px 0'
    },
    filterRow: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    filterWrapper: {
        margin: '0 12px 0 0',
    },
});

const Filters = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.filterRow}>
            {React.Children.map(children, child =>
                <div className={classes.filterWrapper}>
                    {child}
                </div>
            )}
        </div>
    )
}

export default ({ products, placeId, saveCallback, places, setSelectedPlaceCallback, loading, setDateCallback, date }) => {
    const classes = useStyles();
    const [adjustedProducts, setAdjustedProducts] = useState([]);

    const inputOnChange = ({ productId, value, deposit }) => {
        const adjustedProductIndex = adjustedProducts.findIndex((p) => p.productId === productId && p.stockType === deposit)
        if (adjustedProductIndex === -1) {
            adjustedProducts.push({
                productId, stockType: deposit, value
            });
            setAdjustedProducts([...adjustedProducts]);
        } else {
            adjustedProducts[adjustedProductIndex].value = value;
            setAdjustedProducts([...adjustedProducts]);
        }
    }

    useEffect(() => {
        setAdjustedProducts([])
    }, [placeId])

    return (
        <>
            <div className={classes.row}>
                <Filters>
                    <DateTimeInput
                        label='Data do ajuste'
                        ampm={false}
                        name="date"
                        source="date"
                        disableFuture
                        parse={(value) => moment(value).toISOString()}
                        defaultValue={moment().startOf('day').toISOString()}
                        onChange={date => setDateCallback(date)}
                        sx={{ marginBottom: 0 }}
                        helperText={false}
                    />
                    {!getPlaceId() &&
                        <DropDownPartners
                            label="Unidade"
                            value={placeId}
                            setValue={setSelectedPlaceCallback}
                            arrayData={places}
                        />
                    }
                </Filters>
                <SaveButton
                    disabled={loading || (!adjustedProducts || adjustedProducts.length === 0)}
                    onClick={
                        () => {
                            saveCallback(date.utc().toISOString(), adjustedProducts)
                            setAdjustedProducts([])
                        }}
                />
            </div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Produto</TableCell>
                            <TableCell align="center">Nome</TableCell>
                            <TableCell align="center">Depósito</TableCell>
                            <TableCell align="center">Mostruário</TableCell>
                            <TableCell align="center">Quantidade em Depósito</TableCell>
                            <TableCell align="center">Quantidade em Mostruário</TableCell>
                            <TableCell align="center">Diferença Depósito</TableCell>
                            <TableCell align="center">Diferença Mostruário</TableCell>
                        </TableRow>
                    </TableHead>
                    {products && products.length > 0 ?
                        <TableBody>
                            {products && products.map(row => {
                                const productWarehouse = adjustedProducts.find((p) => p.productId === row.id && p.stockType === 'warehouse')
                                const productShowcase = adjustedProducts.find((p) => p.productId === row.id && p.stockType === 'showcase')
                                const currentValueWarehouse = +row.productPlaceInfo.warehouse || 0;
                                const currentValueShowcase = +row.productPlaceInfo.showcase || 0;
                                return (
                                    <TableRow key={row.id}>
                                        <TableCell align="center">
                                            {row.image_path ?
                                                <div className={classes.imageWrapper}>
                                                    <img src={row.imagePath} style={{ width: '100%' }} alt='Imagem do produto' />
                                                </div>
                                                :
                                                <p>Imagem indisponível</p>
                                            }
                                        </TableCell>
                                        <TableCell align="center">{row.name}</TableCell>
                                        <TableCell align="center">
                                            {(productWarehouse ? +productWarehouse.value || 0 : +row.productPlaceInfo.warehouse || 0)}
                                        </TableCell>
                                        <TableCell align="center">
                                            {(productShowcase ? +productShowcase.value || 0 : +row.productPlaceInfo.showcase || 0)}
                                        </TableCell>
                                        <TableCell align="center">
                                            <NumberInput
                                                label='Ajuste'
                                                value={(productWarehouse ? productWarehouse.value || '' : '')}
                                                min={0}
                                                variant="outlined"
                                                source={`warehouseEntry-${row.id}`}
                                                onChange={event => inputOnChange({ value: event.target.value, productId: row.id, deposit: 'warehouse' })}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <NumberInput
                                                label='Ajuste'
                                                value={(productShowcase ? productShowcase.value || '' : '')}
                                                min={0}
                                                variant="outlined"
                                                source={`showcaseEntry-${row.id}`}
                                                onChange={event => inputOnChange({ value: event.target.value, productId: row.id, deposit: 'showcase' })}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            {productWarehouse ? +productWarehouse.value - currentValueWarehouse : 0}
                                        </TableCell>
                                        <TableCell align="center">
                                            {productShowcase ? +productShowcase.value - currentValueShowcase : 0}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                        :
                        <TableCell>
                            <Typography>Nenhum produto no inventário</Typography>
                        </TableCell>
                    }
                </Table>
            </TableContainer>
        </>
    );
}