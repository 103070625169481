import React from 'react';
import {
  Create,
  TextInput,
  SimpleForm,
  required,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import { PERMISSIONS } from '../../constants';
import Permission from '../../components/Permission';
import { getChainId } from '../../lib';
import { CPFInput } from '../../components/Inputs';

const AttendantCreate = (props) => (
  <Permission permission={PERMISSIONS.CREATE_ATTENDANT}>
    <Create title='Adicionar frentista' {...props}>
      <SimpleForm defaultValues={{ chainId: getChainId() }}>
        <TextInput source="name" label="Nome" validate={required("Nome é obrigatório")} />
        <TextInput source="identfid" label="Identificador" validate={required("Identificador é obrigatório")} />
        <Permission permission={PERMISSIONS.ADMIN}>
          <ReferenceInput label="Rede" source="chainId" reference="chains">
            <SelectInput optionText="name" />
          </ReferenceInput>
        </Permission>
        <CPFInput source="cpf" label="CPF" isRequired />
      </SimpleForm>
    </Create>
  </Permission>
);

export default AttendantCreate;