import SearchIcon from '@mui/icons-material/Search';
import { Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { Datagrid, DateField, DeleteButton, FunctionField, NumberField, ReferenceField, ReferenceInput, SelectInput, TextField, TextInput, required, useGetList, useGetOne, useListContext, useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';
import { CustomizableDatagrid } from '../../components';
import { BetweenDatesInput } from '../../components/BetweenDatesInput';
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import ReportList from '../../components/ReportList';
import { PERMISSIONS } from '../../constants';
import { getChainId, getPlaceId } from '../../lib';
import { formatPoints } from '../../utils';
import { TransactionGroupBy, TransactionGroupByChoices } from '../../models/transactionGroupBy';
import RequiredChain from '../../components/RequiredChain';


const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  empty: {
    fontSize: '0.875rem',
    margin: '10px 0 10px 0',
    textAlign: 'center',
  },
  hideRemoveButton: {
    '@media print': {
      display: 'none',
    },
  },
  linkField: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  }
}));

const CustomerField = (props) => {
  const record = useRecordContext();
  const classes = useStyles();

  if (!record || !record.customer_cpf) { return <></>; }

  const { data, isLoading } = useGetList('customers', { pagination: { perPage: 1, page: 1 }, sort: { field: 'name', order: 'DESC' }, filter: { cpf: record.customer_cpf, chainId: record.chain_id } });
  const customerShowPage = isLoading ? '' : `/customers/${Object.values(data)[0]?.id}/show`;

  return isLoading ? <></> : <FunctionField {...props} sortBy="customer_cpf" sortable render={record => <Link className={classes.linkField} to={customerShowPage}>{record.customer_cpf}</Link>} />;
};

const ReportAddedPoints = (props) => {
  const placeId = getPlaceId();
  const { data: chain } = useGetOne('chains', { id: getChainId() });

  const listFilters = [
    <BetweenDatesInput alwaysOn />,
    <ReferenceInput source="stationId" reference="stations" perPage={null} sort={{ field: "name", order: "ASC" }} alwaysOn>
      <SelectInput
        label="Posto"
        emptyText="Todos"
        optionText={'name'}
      />
    </ReferenceInput>,
    ...(chain && chain.useAttendantCode ?
      [
        <SelectInput
          label="Agrupado por"
          source="transactionGroupBy"
          choices={TransactionGroupByChoices}
          optionText="name"
          optionValue="value"
          validate={[required()]}
          alwaysOn
        />,
      ] : []
    ),
    <TextInput alwaysOn source="search" label="Digite o CPF"
      InputProps={{
        endAdornment: (
          <InputAdornment position="start" style={{ marginTop: 8 }}>
            <SearchIcon />
          </InputAdornment>
        ),
      }} />,
  ];

  const stationsIndex = listFilters.findIndex(({ props }) => props.reference === 'stations')
  if (placeId && stationsIndex > -1) {
    listFilters.splice(stationsIndex, 1)
  }

  const FilterTablePrint = () => {
    const { filterValues } = useListContext();
    return (
      <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
        <DateField source="from" textAlign="center" label="De" sortable={false} />
        <DateField source="to" textAlign="center" label="Até" sortable={false} />
        <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
      </Datagrid>
    );
  };

  const reportExporter = async (data) => {
    return data[0].data.map(item => (
      {
        cupom: item.coupon_invoice,
        serie: item.coupon_serial_number,
        cpf: item.customer_cpf,
        descricao: item.description,
        data: item.created_at,
        valor: item.value,
      }
    ));
  }

  const AddedPointList = () => {
    const classes = useStyles();
    const props = useListContext();
    let data = [];
    let summaryData = [];
    let totalData = [];

    if (props.data) {
      const addedPoints = props.data[0];

      if (addedPoints) {
        data = addedPoints.data;
        summaryData = addedPoints.pointsAddedPerEmployee;
        totalData = [{ addedPoints: addedPoints.addedPoints }];
      }
    }

    return (
      <>
        <Typography className={classes.title}>Pontos Distribuídos</Typography>
        <Datagrid bulkActionButtons={false} style={{ marginBottom: 30 }} data={totalData} empty={<Typography className={classes.empty}>Nenhum resultado encontrado</Typography>}>
          <FunctionField
            label="Total"
            emptyText="0"
            textAlign="center"
            sortable={false}
            render={record => formatPoints(record.addedPoints)}
          />
        </Datagrid>

        <Typography className={classes.title}>Pontos Distribuídos Por Usuário</Typography>
        <Datagrid bulkActionButtons={false} style={{ marginBottom: 30 }} data={summaryData} empty={<Typography className={classes.empty}>Nenhum resultado encontrado</Typography>}>
          {
            props.filterValues?.transactionGroupBy === TransactionGroupBy.attendant ?
              <ReferenceField label="Frentista" emptyText="Não identificado" source="id" reference="attendants" textAlign='left' sortable={false} link={false}>
                <TextField source="name" />
              </ReferenceField>
              :
              <ReferenceField label="Usuário" emptyText="Não identificado" source="id" basePath="employees" reference="employees" textAlign='left' sortable={false} link={false}>
                <TextField source="login" />
              </ReferenceField>
          }
          <FunctionField
            label="Quantidade de pontos"
            emptyText="0"
            textAlign="center"
            sortable={false}
            render={record => formatPoints(record.addedPoints)}
          />
        </Datagrid>

        <Typography className={classes.title}>Transações</Typography>
        <CustomizableDatagrid resource='chains/chainId/transactions/remove-added-points' data={data} bulkActionButtons={false} defaultColumns={['created_at', 'employee_id', 'value', 'customer_cpf', 'description']}>
          <DateField source="created_at" label="Data" showTime />
          <ReferenceField label="Posto" emptyText="Não identificado" source="place_id" basePath="stations" reference="stations" link="show">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Combustível" emptyText="Não identificado" source="fillin_id" reference="fillins" textAlign='center' link={false}>
            <ReferenceField label="Combustível" emptyText="Não identificado" source="fuelId" reference="fuels" textAlign='center' link={false}>
              <TextField source="name" />
            </ReferenceField>
          </ReferenceField>
          <ReferenceField label="Litros" emptyText="Não identificado" source="fillin_id" reference="fillins" textAlign='center' link={false}>
            <NumberField
              source="amount"
              label="Litros"
              textAlign="center"
              options={{
                maximumFractionDigits: 2
              }} />
          </ReferenceField>
          <ReferenceField label="Usuário" emptyText="Não identificado" source="employee_id" basePath="employees" reference="employees" textAlign='center' link={false}>
            <TextField source="login" />
          </ReferenceField>
          {
            (chain && chain.useAttendantCode) ?
              <ReferenceField label="Frentista" source="attendant_id" reference="attendants" emptyText="--" textAlign="center">
                <TextField source="name" />
              </ReferenceField>
              : <></>
          }
          <CustomerField label="Cliente CPF" sortable textAlign="center" />
          <FunctionField
            label="Quantidade de pontos"
            emptyText="0"
            textAlign="center"
            sortable={false}
            render={record => formatPoints(record.value)}
          />
          <TextField source="description" label="Descrição" textAlign="center" emptyText='[Sem descrição]' />
          <FunctionField
            source="coupon_series"
            label="Cupom/Série"
            render={record => record.coupon_invoice && record.coupon_serial_number ? `${record.coupon_invoice}/${record.coupon_serial_number}` : '[Sem cupom/série]'}
            textAlign='center'
            sortable={false} />
          <Permission permission={PERMISSIONS.REMOVE_ADDED_POINTS}>
            <DeleteButton className={classes.hideRemoveButton} label="Remover Pontos" />
          </Permission>
        </CustomizableDatagrid>
      </>
    );
  };

  return (
    <Permission permission={PERMISSIONS.ADDED_POINTS_REPORT}>
      <ReportList
        {...props}
        basePath="report-added-points"
        title="Adicionar Pontos"
        resource="chains/chainId/reports/added-points"
        filters={listFilters}
        sort={{ field: 'created_at', order: 'DESC' }}
        perPage={25}
        filterDefaultValues={{
          stationId: placeId,
          from: moment().startOf('day').subtract(1, "month").toISOString(),
          to: moment().endOf('day').toISOString(),
          transactionGroupBy: TransactionGroupBy.employee,
        }}
        pagination={<ListPagination />}
        bulkActionButtons={false}
        titleOnPrint="Relatório de pontos adicionados"
        filterTablePrint={<FilterTablePrint />}
        landscape
        customExporter={reportExporter}
      >
        <AddedPointList />
      </ReportList>
    </Permission>
  );
}

export default props => (
  <RequiredChain>
    <ReportAddedPoints {...props} />
  </RequiredChain>
);