import React from 'react';
import { Button, useDataProvider, useNotify, useRecordContext, useRedirect, useResourceContext } from 'react-admin';

interface CloneRuleButtonProp {
  record?: any;
  variant?: 'text' | 'outlined' | 'contained';
  stytle?: any;
}

const CloneRuleButton: React.FC<CloneRuleButtonProp> = () => {
  const record = useRecordContext();
  if (!record) { return null; }

  const dataProvider = useDataProvider();
  const resource = useResourceContext();
  const redirect = useRedirect();
  const notify = useNotify();

  const handleOnClick = (event: any) => {
    event.stopPropagation();

    dataProvider.getOne(resource, { id: record.id })
      .then(({ data }) => {
        redirect(`/${resource}/create`, undefined, undefined, undefined, data);
      }).catch(() => {
        notify('Não foi possível realizar a operação, tente novamente');
      });
  }
  return (
    <>
      <Button onClick={handleOnClick} variant="outlined" label="Clonar" />
    </>
  );
}

export default CloneRuleButton;