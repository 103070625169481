import { makeStyles } from "@mui/styles";
import moment from "moment";
import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  NumberField,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  SelectArrayInput,
  SingleFieldList,
  TextField,
  useListContext,
  usePermissions,
} from "react-admin";
import { ReportList } from "../../components";
import ListPagination from "../../components/ListPagination";
import Permission from "../../components/Permission";
import { PERMISSIONS } from "../../constants";
import { TagType } from "../../models";

const useStyles = makeStyles(theme => ({
}));

const ListDefaultPoints = (props) => {
  const classes = useStyles();

  return (
    <Datagrid bulkActionButtons={false} {...props}>
      <ReferenceField
        source="fuelId"
        reference="fuels"
        label="Combustivel"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="factor" label="Fator da Pontuação" textAlign="center" sortable={false} emptyText="0" />
    </Datagrid>
  );
};

const ListPlaces = () => {
  const classes = useStyles();
  const { data, ids, resource } = useListContext();

  return (
    <Datagrid
      resource={resource}
      data={data}
      bulkActionButtons={false}
      style={{ marginTop: 40 }}
    >
      <TextField source="name" textAlign="center" label="Posto" link={false} />
      <ArrayField source="placeFuels" label="Pontuação" sortable={false} textAlign="center">
        <ListDefaultPoints />
      </ArrayField>
    </Datagrid>
  );
};

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  const { permissions } = usePermissions();
  return (
    <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
      <ReferenceArrayField source="placeIds" emptyText="Todos" reference="places" label="Postos" sortable={false}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField source="fuelIds" emptyText="Todos" reference="fuels" label="Combustíveis" sortable={false}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      {
        permissions && permissions.includes(PERMISSIONS.TAG) &&
        <ReferenceArrayField label="Etiquetas" emptyText="Todas" source="tagIds" textAlign="center" reference='chains/chainId/tag'>
          <SingleFieldList>
            <ChipField source='name' />
          </SingleFieldList>
        </ReferenceArrayField>
      }
    </Datagrid>
  );
};

const ReportDefaultPoints = (props) => {

  const listFilters = [
    <ReferenceArrayInput
      source="placeIds"
      reference="stations"
      sort={{ field: "name", order: "ASC" }}
      perPage={null}
      style={{ minWidth: 160 }}
      alwaysOn
    >
      <SelectArrayInput
        label="Postos"
        optionText="name" />
    </ReferenceArrayInput>,
    <ReferenceArrayInput
      source="fuelIds"
      reference="fuels"
      perPage={null}
      sort={{ field: "name", order: "ASC" }}
      style={{ minWidth: 160 }}
      alwaysOn
    >
      <SelectArrayInput
        label="Combustíveis"
        optionText="name" />
    </ReferenceArrayInput>,
    <Permission permission={PERMISSIONS.TAG} alwaysOn>
      <ReferenceArrayInput
        style={{ minWidth: 160 }}
        source="tagIds"
        reference="chains/chainId/tag"
        perPage={null}
        sort={{ field: "name", order: "ASC" }}
        filter={{ type: TagType.station }}
      >
        <SelectArrayInput
          label="Etiqueta"
          optionText="name" />
      </ReferenceArrayInput>
    </Permission>
  ];

  return (
    <Permission permission={PERMISSIONS.DEFAULT_POINTS_REPORT}>
      <ReportList
        {...props}
        basePath="report-default-points"
        title="Relatório de Pontuação Padrão"
        resource="chains/chainId/reports/default-points"
        filters={listFilters}
        sort={{ field: 'name', order: 'ASC' }}
        perPage={25}
        pagination={<ListPagination />}
        bulkActionButtons={false}
        titleOnPrint="Relatório de Pontuação Padrão"
        filterTablePrint={<FilterTablePrint />}
        landscape
      >
        <ListPlaces />
      </ReportList>
    </Permission >
  );
};

export default ReportDefaultPoints;