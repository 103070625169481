export enum PotencialFraudRule {
  negativeBalance = 'negative-balance',
  multipleRefuelSameAttendant = 'multiple-refuel-same-attendant',
  multipleRefuelSameWeek = 'multiple-refuel-same-week',
  multipleDifferentFuelRefillsInLastWeek = 'multiple-different-fuel-refills-in-last-week',
  multipleRefuelSameDay = 'multiple-refuel-same-day',
  sequenceRefuel = 'sequence-refuel',
  redeemedGiftOnCanceledSale = 'redeemed-gift-on-canceled-sale',
};

export namespace PotencialFraudRule {
  export const description = (type: PotencialFraudRule): string => {
    const RULES_DESCRIPTION = {
      'negative-balance': 'Regra acionada quando o saldo atinge valores negativos, alertando sobre possíveis gastos descontrolados ou atividades suspeitas que demandam atenção imediata.',
      'multiple-refuel-same-attendant': 'Regra acionada quando múltiplos abastecimentos são realizados com o mesmo frentista em um curto período, sugerindo potencial conluio ou atividade fraudulenta. O limite mínimo de vezes que foram abastecidos no curto período pode ser customizado.',
      'multiple-refuel-same-week': 'Disparada quando ocorrem múltiplos abastecimentos em uma única semana, indicando possíveis padrões de consumo anormais ou uso indevido de recursos. O limite mínimo de vezes que foram abastecidos na semana pode ser customizado.',
      'multiple-different-fuel-refills-in-last-week': 'Regra acionada quando múltiplos tipos de combustíveis são abastecidos em um período de uma semana, possivelmente indicando erro operacional ou comportamento suspeito. O limite mínimo de vezes que foram abastecidos na semana pode ser customizado.',
      'multiple-refuel-same-day': 'Regra acionada quando ocorrem múltiplos abastecimentos em um único dia, indicando potencialmente atividades não usuais ou necessidade de investigação adicional. O limite mínimo de vezes que foram abastecidos no dia pode ser customizado',
      'sequence-refuel': 'Disparada quando há abastecimentos diários em sequência por um período de x dias consecutivos, possivelmente indicando atividades incomuns ou padrões suspeitos de consumo. O limite mínimo de dias pode ser customizado',
      'redeemed-gift-on-canceled-sale': 'Regra acionada quando uma venda é cancelada após o brinde ter sido utilizado/resgatado.'
    }
    return RULES_DESCRIPTION[type] || '';
  };
};