import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from "moment";
import { ArrayField, ChipField, Datagrid, DateField, Filter, FormDataConsumer, FunctionField, NumberField, ReferenceArrayField, ReferenceArrayInput, ReferenceField, ReferenceInput, ReferenceManyField, SearchInput, SelectArrayInput, SelectInput, SingleFieldList, TextField, useListContext } from "react-admin";
import { Permission, ReportList } from "../../components";
import { BetweenDatesInput } from "../../components/BetweenDatesInput";
import ListPagination from "../../components/ListPagination";
import { PERMISSIONS, discountTypes } from "../../constants";
import { getChainId } from "../../lib";

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
}));

const DiscountPanel = () => {
  return (
    <ReferenceManyField
      reference="discounts"
      target="id"
      filter={{ chainId: getChainId() }}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="description" label="Descrição" emptyText="--" sortable={false} textAlign="center" />
        <FunctionField source="type" label="Tipo" sortable={false} textAlign="center" render={(record) => discountTypes[record.type]} />
        <FunctionField source="isEnable" label="Status" sortable={false} textAlign="center" render={(record) => record.isEnabled ? "Ativo" : "Inativo"} />
        <ArrayField label="Postos" source="placeDiscounts">
          <SingleFieldList>
            <ReferenceField source="placeId" reference="stations">
              <ChipField source="name" />
            </ReferenceField>
          </SingleFieldList>
        </ArrayField>
        <ArrayField label="Combustíveis" source="fuelDiscounts">
          <SingleFieldList>
            <ReferenceField source="fuelId" reference="fuels">
              <ChipField source="name" />
            </ReferenceField>
          </SingleFieldList>
        </ArrayField>
      </Datagrid>
    </ReferenceManyField>
  );
};

const DiscountList = () => {
  const classes = useStyles();
  const props = useListContext();

  let data = [];
  let summaryData = [];

  if (props.data) {
    const reportInfo = props.data[0];

    if (reportInfo) {
      data = reportInfo.data;
      summaryData = [reportInfo.summaryData];
    }
  }

  return (
    <>
      <Typography className={classes.title}>Total</Typography>
      <Datagrid bulkActionButtons={false} style={{ marginBottom: 60 }} data={summaryData} total={1}>
        <NumberField source="total_fillins" textAlign="center" label="Abastecimentos" sortable={false} />
        <NumberField
          source="total_value"
          label="Valor dos Abastecimentos"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
        <NumberField
          source="total_amount"
          label="Volume dos Abastecimentos"
          textAlign="center"
          emptyText="0"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
          sortable={false}
        />
        <NumberField
          source="average_ticket"
          label="Ticket Médio"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
        <NumberField
          source="average_amount"
          label="Volume Médio"
          textAlign="center"
          emptyText="0"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
          sortable={false}
        />
        <NumberField
          source="discount"
          label="Desconto concedido"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
      </Datagrid>

      <Typography className={classes.title}>Descontos</Typography>
      <Datagrid
        resource={props.resource}
        data={data}
        bulkActionButtons={false}
        expand={<DiscountPanel />}
      >
        <ReferenceField
          label="Código do desconto"
          source="id"
          reference="discounts"
          textAlign="center"
          link={false}
          sortable={false}
        >
          <TextField source="identify" />
        </ReferenceField>
        <NumberField source="total_fillins" label="Quantidade de Abastecimentos" textAlign="center" />
        <NumberField
          source="total_value"
          label="Valor total dos abastecimentos"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <NumberField
          source="total_amount"
          label="Volume total dos abastecimentos"
          textAlign="center"
          emptyText="0"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
        />
        <NumberField
          source="average_ticket"
          label="Ticket Médio"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <NumberField
          source="average_amount"
          label="Volume Médio"
          textAlign="center"
          emptyText="0"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
        />
        <NumberField
          source="discount"
          label="Quantidade de desconto concedido"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
      </Datagrid>
    </>
  );
};

const FilterTablePrint = () => {
  const { filterValues } = useListContext();
  const classes = useStyles();

  return (
    <Datagrid
      data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]}
      bulkActionButtons={false}
      total={1}>
      <DateField source="from" textAlign="center" label="De" sortable={false} />
      <DateField source="to" textAlign="center" label="Até" sortable={false} />
      <ReferenceArrayField label="Postos" emptyText="Todos" source="placeIds" textAlign="center" basePath="stations" reference="stations" link={false}>
        <SingleFieldList style={{ flexDirection: 'column', gap: 2, margin: '2px 0' }}>
          <TextField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField label="Combustíveis" emptyText="Todos" source="fuelIds" textAlign="center" basePath="fuels" reference="fuels" link={false}>
        <SingleFieldList style={{ flexDirection: 'column', gap: 2, margin: '2px 0' }}>
          <TextField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField label="Buscar" source="search" sortable={false} />
      <DateField source='generatedReportAt' label='Gerado às' showTime sortable={false} locales='pt-BR' className={classes.align} />
    </Datagrid>
  );
};

const DiscountFilter = (props) => {
  return (
    <Filter {...props}>
      <BetweenDatesInput alwaysOn />
      <Permission permission={PERMISSIONS.ADMIN} alwaysOn>
        <ReferenceInput
          source="chainId"
          reference="chains"
          perPage={99999}
          sort={{ field: "name", order: "ASC" }}
          defaultValue={getChainId()}
        >
          <SelectInput
            emptyText="Todos"
            label="Rede"
            style={{ minWidth: 180 }}
            optionText={'name'}
          />
        </ReferenceInput>
      </Permission>
      <FormDataConsumer alwaysOn >
        {({ formData }) => (
          <ReferenceArrayInput
            source="placeIds"
            reference="stations"
            filter={formData.chainId ? { chainId: formData.chainId } : {}}
            sort={{ field: "name", order: "ASC" }}
            perPage={null}
          >
            <SelectArrayInput
              label="Posto"
              emptyText="Todos"
              optionText="name"
              style={{ minWidth: 180 }}
            />
          </ReferenceArrayInput>
        )}
      </FormDataConsumer>

      <ReferenceArrayInput
        source="fuelIds"
        reference="fuels"
        sort={{ field: "name", order: "ASC" }}
        perPage={null}
        style={{ minWidth: 180 }}
        alwaysOn
      >
        <SelectArrayInput
          label="Combustível"
          emptyText="Todos"
          optionText="name"
        />
      </ReferenceArrayInput>
      <SearchInput source="search" alwaysOn />
    </Filter>
  );
};

const ReportDiscount = (props) => {
  return (
    <Permission permission={PERMISSIONS.DISCOUNT_REPORT}>
      <ReportList
        {...props}
        filters={<DiscountFilter />}
        perPage={25}
        sort={{ field: 'total_fillins', order: 'DESC' }}
        filterDefaultValues={{
          chainId: getChainId(),
          from: moment().startOf("day").subtract(1, "month").toISOString(),
          to: moment().endOf('day').toISOString()
        }}
        basePath='discount'
        resource="chains/chainId/reports/discount"
        title="Descontos"
        filterTablePrint={<FilterTablePrint />}
        pagination={<ListPagination />}
      >
        <DiscountList />
      </ReportList>
    </Permission>
  );
};
export default ReportDiscount;