import React from 'react';
import {
  Create,
  TextInput,
  required,
  SimpleForm,
  ReferenceArrayInput,
  SelectArrayInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';

const CreateExternalPaymentMethod = (props) => (
  <Permission permission={PERMISSIONS.MANAGE_EXTERNAL_PAYMENT_METHOD}>
    <Create {...props} title="Cadastrar método de pagamento externo">
      <SimpleForm redirect="list">
        <ReferenceInput
          sort={{ field: 'name', order: 'ASC' }}
          //@ts-ignore
          perPage={null}
          label="Método de pagamento"
          source="paymentMethodId"
          reference="app-payment-methods"
          style={{ minWidth: 260 }}
          validate={[required()]}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceArrayInput
          sort={{ field: 'name', order: 'ASC' }}
          //@ts-ignore
          perPage={null}
          label="Postos"
          source="placeIds"
          reference="stations"
          style={{ minWidth: 260 }}
          validate={[required()]}
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ArrayInput source="externalPaymentMethods" label="Pagamentos externos" validate={[required()]}>
          <SimpleFormIterator
            disableReordering
          >
            <TextInput source="type" label="Tipo" validate={[required()]} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  </Permission>
);

export default CreateExternalPaymentMethod;