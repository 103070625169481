import { DEFAULT_API_ADMIN, mapPath } from '../../constants'
import { getChainId, httpClientReactAdmin } from '../../lib'
import { stringify } from 'query-string';

export default async (resourceName, params) => {
  const path = mapPath[resourceName];
  const encodedId = params.id ? `/${encodeURIComponent(params.id)}` : '';
  const URL = `${DEFAULT_API_ADMIN}/${(path || resourceName || '').replace('chainId', `${getChainId()}`)}${encodedId}`;

  const query: { [key: string]: any } = {};
  if (typeof params.meta?.listDeleted === 'boolean') {
    query.filter = JSON.stringify({ listDeleted: params.meta.listDeleted });
  }

  const { json } = await httpClientReactAdmin(`${URL}?${stringify(query)}`, { method: 'GET' });

  if (json.data.productPlaces) {
    json.data.places = json.data.productPlaces;
    delete json.data.productPlaces;
  }

  return json
}
