import Sale from '@mui/icons-material/MonetizationOn'
import { PERMISSIONS } from '../../constants'
import list from "./listRetailer";
import edit from "./editRetailer";
import create from "./createRetailer";

export default {
  list,
  edit,
  create,
  name: 'retailers',
  permissions: [PERMISSIONS.ADMIN],
  icon: Sale
};
