import { PERMISSIONS } from '../../constants';
import list from './GiftList';
import create from './GiftCreate';
import edit from './GiftEdit';
import show from './GiftShow';

export default {
  list,
  create,
  show,
  name: 'gifts',
  permissions: [PERMISSIONS.GIFT]
}