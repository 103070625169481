import React from 'react';
import { FilterList, SelectInput } from 'react-admin';
import { Collapse } from '@mui/material';
import LocalGasStation from '@mui/icons-material/LocalGasStation';
import useDashboardContext from '../../hooks/useDashboardContext';
import { FillinOrigin } from '../../models';


const DashboardSelectOrigin = ({ isOpen }) => {
  const { origin, setOrigin } = useDashboardContext();

  return (
    <>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <FilterList
          label="Origem"
          icon={<LocalGasStation titleAccess="Origem" />}
        />
      </Collapse>
      <SelectInput
        label={!isOpen && "Origem"}
        source="origin"
        emptyText="Todas"
        value={origin}
                style={{ minWidth: 160, marginTop: !isOpen && 8, padding: 0 }}
        onChange={({target}) => setOrigin(target.value)}
        choices={FillinOrigin.fillinOriginChoices}
      />
    </>);
};

export default DashboardSelectOrigin;
