import React from 'react';
import {
  Datagrid, DateField, DeleteButton, FunctionField, List
} from "react-admin";
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import { DATE_FORMATER, PERMISSIONS } from '../../constants';

const FuelScheduledDiscountList = props => {
  return (
    <Permission permission={PERMISSIONS.FUEL_DISCOUNTS}>
      <List
        {...props}
        perPage={25}
        pagination={<ListPagination />}
        exporter={false}
        bulkActionButtons={false}
        title="Descontos Agendados"
        sort={{ field: 'startDate', order: 'DESC' }}
      >
        <Datagrid rowClick="edit">
          <DateField
            source="startDate"
            label="Data de Início"
            options={DATE_FORMATER}
          />
          <DateField
            source="endDate"
            label="Data Final"
            options={DATE_FORMATER}
          />
          <FunctionField
            render={record => record.frequency === 'weekly' ? 'Semanal' : record.frequency === 'daily' ? 'Diária' : 'Não se repete'}
            label="Frequência"
          />
          <DeleteButton />
        </Datagrid>
      </List>
    </Permission>
  );
}

export default FuelScheduledDiscountList;