import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from "moment";
import {
  ChipField,
  Datagrid,
  DateField,
  Filter,
  FormDataConsumer,
  NumberField,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SingleFieldList,
  TextField,
  useListContext,
} from "react-admin";
import { ReportList } from "../../components";
import { BetweenDatesInput } from "../../components/BetweenDatesInput";
import ListPagination from "../../components/ListPagination";
import Permission from "../../components/Permission";
import { PERMISSIONS } from "../../constants";
import { getChainId } from "../../lib";

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
}));

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  return (
    <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
      <DateField source="from" textAlign="center" label="De" sortable={false} />
      <DateField source="to" textAlign="center" label="Até" sortable={false} />
      <ReferenceArrayField label="Posto" source="placeIds" textAlign="center" basePath="stations" reference="stations" link={false} >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
    </Datagrid>
  );
};

const ReferedCustomersList = () => {
  const classes = useStyles();
  const props = useListContext();

  let data = [], summaryData = [];

  if (props.data) {
    const reportInfo = props.data[0];

    if (reportInfo) {
      data = reportInfo.data;
      summaryData = [reportInfo.summaryData];
    }
  }

  return (
    <>
      <Typography className={classes.title}>Total</Typography>
      <Datagrid bulkActionButtons={false} style={{ marginBottom: 60 }} data={summaryData} total={1}>
        <NumberField source="cnt_rc" label="Clientes Indicadores" sortable={false} textAlign="center" />
        <NumberField source="cnt_customer" label="Número de Indicações" sortable={false} textAlign="center" />
      </Datagrid>
      <Typography className={classes.title}>Indicações</Typography>
      <Datagrid bulkActionButtons={false} style={{ marginTop: 40 }} data={data} total={1} resource={props.resource}>
        <ReferenceField
          reference="customers"
          source="referee_customer_id"
          label="Cliente que Indicou"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="name" label="Cliente Indicado" />
        <DateField source="refered_at" label="Data da Indicação" emptyText="-" textAlign="center" showTime />
        <DateField source="created_at" label="Data do Abastecimento" emptyText="-" textAlign="center" showTime />
      </Datagrid>
    </>
  );
};

const ReferedCustomersFilter = (props) => {
  const { filterValues } = useListContext();
  return (
    <Filter {...props}>
      <BetweenDatesInput alwaysOn />
      <Permission permission={PERMISSIONS.ADMIN} alwaysOn>
        <ReferenceInput
          source="chainId"
          reference="chains"
          perPage={99999}
          sort={{ field: "name", order: "ASC" }}
          defaultValue={getChainId()}
        >
          <SelectInput
            label="Rede"
            emptyText="Todos"
            style={{ minWidth: 180 }}
            optionText={'name'}
          />
        </ReferenceInput>
      </Permission>
      <ReferenceArrayInput
        source="placeIds"
        reference="stations"
        filter={filterValues.chainId ? { chainId: filterValues.chainId } : {}}
        sort={{ field: "name", order: "ASC" }}
        perPage={null}
        alwaysOn
      >
        <SelectArrayInput
          emptyText="Todos"
          label="Posto"
          optionText="name"
        />
      </ReferenceArrayInput>
    </Filter>
  );
};

const ReportReferedCustomers = (props) => {
  return (
    <Permission permission={PERMISSIONS.REFERED_CUSTOMERS_REPORT}>
      <ReportList
        {...props}
        basePath="report-refered-customers"
        title="Indicação"
        resource="chains/chainId/reports/refered-customers"
        filters={<ReferedCustomersFilter />}
        sort={{ field: "refered_at", order: "DESC" }}
        perPage={25}
        pagination={<ListPagination />}
        filterDefaultValues={{
          chainId: getChainId(),
          from: moment().subtract(1, 'month').startOf('day').toISOString(),
          to: moment().endOf('day').toISOString()
        }}
        bulkActionButtons={false}
        titleOnPrint="Relatório de Indicação"
        filterTablePrint={<FilterTablePrint />}
        landscape
      >
        <ReferedCustomersList />
      </ReportList>
    </Permission>
  );
};

export default ReportReferedCustomers;