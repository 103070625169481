import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  BooleanField,
  useRecordContext,
} from 'react-admin'
import { DATE_FORMATER, PERMISSIONS } from '../../constants';
import Permission from '../../components/Permission';
import { BlockCustomerButton, IgnoreCustomerFraudButton, IgnoreFraudButton } from '../../components/PotencialFraudActions';
import { Tooltip } from '../../components';
import { PotencialFraudRule } from '../../models';


const PotencialFraudRuleField: React.FC<{ label: string }> = ({ label }) => {
  const { fraud } = useRecordContext();
  return (
    <Tooltip title={PotencialFraudRule.description(fraud?.name)}>
      <TextField source="fraud.label" label={label} />
    </Tooltip>
  );
}

const PotencialFraudShow = props => (
  <Permission permission={PERMISSIONS.POTENCIAL_FRAUD_LIST}>
    <Show {...props} title="Potencial fraude">
      <SimpleShowLayout>
        <TextField source="customer.name" label="Cliente" />
        <TextField source="customer.cpf" label="CPF" />
        <PotencialFraudRuleField label="Motivo da suspeita" />
        <DateField source="createdAt" label="Data" options={DATE_FORMATER} />
        <BooleanField source="isIgnored" label="Ignorada" />

        <Permission permission={PERMISSIONS.IGNORE_FRAUD_LOG}>
          <IgnoreFraudButton variant="outlined" style={{ marginTop: 5, marginBottom: 5 }} />
        </Permission>
        <Permission permission={PERMISSIONS.IGNORE_CUSTOMER_FRAUD_LOG}>
          <IgnoreCustomerFraudButton variant="outlined" style={{ marginTop: 5, marginBottom: 5 }} />
        </Permission>
        <Permission permission={PERMISSIONS.BLOCK_CUSTOMER}>
          <BlockCustomerButton variant="outlined" style={{ marginTop: 5, marginBottom: 5 }} />
        </Permission>
      </SimpleShowLayout>
    </Show>
  </Permission>
);

export default PotencialFraudShow;