import { makeStyles } from '@mui/styles';
import moment from 'moment';
import {
    ChipField,
    Datagrid, DateField, DateInput, Filter, FunctionField, NumberField, NumberInput, ReferenceArrayField, ReferenceArrayInput, ReferenceField, SelectArrayInput, SingleFieldList, TextField, TextInput, useListContext, usePermissions
} from 'react-admin';
import { CheckChainUsesModule, ModuleKey, ReportList } from '../../components';
import { PartiallyHiddenCpfField, PhoneField } from "../../components/Fields";
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import { MAX_DATE, PERMISSIONS } from "../../constants";
import { getAttendantId, getEmployeeType, getPlaceId, getUserId } from "../../lib";
import { EmployeeType, TagType } from '../../models';
import { formatPoints } from '../../utils';

const useStyles = makeStyles(theme => ({
    title: {
        margin: '10px 0 10px 0',
        textAlign: 'center',
        fontWeight: 'bold'
    },
}));

const FillinByCustomer = () => {
    const props = useListContext();
    const { permissions } = usePermissions();
    const classes = useStyles();

    let ids = [];
    let customerData = props.data;

    if (customerData) {
        Object.keys(customerData).forEach((customer) => {
            if (!customerData[customer].balance) {
                customerData[customer].balance = 0;
            }
        });

        ids = Object.values(customerData).map(item => item.id);
    }

    return (
        <Datagrid bulkActionButtons={false} style={{ marginTop: 40 }} data={customerData} total={1}>
            <ReferenceField label="Nome" basePath="customers" reference="customers" source="id" emptyText="Não Identificado" link={permissions?.includes(PERMISSIONS.LIST_CUSTOMER) && "show"}>
                <TextField source="name" emptyText="Não Identificado" />
            </ReferenceField>
            <ReferenceField label="CPF" basePath="customers" reference="customers" source="id" emptyText="Não Identificado" link={permissions?.includes(PERMISSIONS.LIST_CUSTOMER) && "show"}>
                <PartiallyHiddenCpfField source="cpf" emptyText="Não Identificado" />
            </ReferenceField>
            <Permission permission={PERMISSIONS.LIST_CUSTOMER}>
                <ReferenceField label="Telefone" basePath="customers" reference="customers" source="id" emptyText="Não Identificado" link={permissions?.includes(PERMISSIONS.LIST_CUSTOMER) && "show"}>
                    <PhoneField source="cellphone" emptyText="Não Identificado" />
                </ReferenceField>
            </Permission>
            <NumberField
                source="fillins"
                label="Abastecimentos"
                textAlign="center"
                emptyText='0'
            />
            {
                Permission({
                    permission: PERMISSIONS.CUSTOMER_STATEMENT,
                    children: (
                        <ReferenceField
                            label="Saldo"
                            source="id"
                            reference="customer-balance"
                            emptyText="0"
                            sortable={false}
                            link={false}
                        >
                            <FunctionField
                                source="balance"
                                render={record => formatPoints(+record?.balance)}
                            />
                        </ReferenceField>
                    )
                })
            }
        </Datagrid>
    );
};

const ListFilters = (props) => {
    const placeId = getPlaceId();
    const employeeType = getEmployeeType();
    return (
        <Filter {...props}>
            <DateInput source="from" label="De" options={{ format: 'DD/MM/YYYY' }} inputProps={{ max: MAX_DATE }} alwaysOn />
            <DateInput source="to" label="Até" options={{ format: 'DD/MM/YYYY' }} inputProps={{ max: MAX_DATE }} alwaysOn />
            {
                !placeId &&
                <ReferenceArrayInput
                    source="placeIds"
                    reference="stations"
                    filter={getEmployeeType() === EmployeeType.attendant && { attendantId: getAttendantId() }}
                    sort={{ field: "name", order: "ASC" }}
                    alwaysOn
                >
                    <SelectArrayInput
                        label="Posto"
                        style={{ minWidth: 180 }}
                        optionText={'name'}
                    />
                </ReferenceArrayInput>
            }
            {
                (employeeType === EmployeeType.admin || [EmployeeType.chain, EmployeeType.place].includes(employeeType)) &&
                <CheckChainUsesModule module={ModuleKey.PROMOTER} alwaysOn>
                    <ReferenceArrayInput
                        source="promoterIds"
                        reference="promoters"
                        sort={{ field: "name", order: "ASC" }}
                        emptyText="Todos"
                    >
                        <SelectArrayInput
                            label="Promoter"
                            style={{ minWidth: 180 }}
                            optionText={'name'}
                        />
                    </ReferenceArrayInput>
                </CheckChainUsesModule>
            }
            <NumberInput
                source="amountFillins"
                label="Min. de abastecimentos"
                textAlign="center"
                min={1}
                alwaysOn
            />
            <Permission permission={PERMISSIONS.CUSTOMER_STATEMENT} alwaysOn>
                <TextInput
                    style={{ width: 150 }}
                    source="balanceFrom"
                    label="Saldo Min."
                    format={value => !value ? '' : `${Number(value).toFixed(2)}`}
                    parse={value => !value ? 0 : +value.replace(/[^\d]/g, '') / 100}
                />
            </Permission>
            <Permission permission={PERMISSIONS.CUSTOMER_STATEMENT} alwaysOn>
                <TextInput
                    style={{ width: 150 }}
                    source="balanceTo"
                    label="Saldo Máx."
                    format={value => !value ? '' : `${Number(value).toFixed(2)}`}
                    parse={value => !value ? 0 : +value.replace(/[^\d]/g, '') / 100}
                />
            </Permission>
            <Permission permission={PERMISSIONS.TAG} alwaysOn>
                <ReferenceArrayInput
                    style={{ minWidth: 180 }}
                    source="tagIds"
                    sort={{ field: 'name', order: "ASC" }}
                    reference="chains/chainId/tag"
                    filter={{ type: TagType.client }}
                >
                    <SelectArrayInput
                        label="Etiqueta"
                        optionText="name"
                    />
                </ReferenceArrayInput>
            </Permission>
        </Filter>
    );
};


const FilterTablePrint = () => {
    const classes = useStyles();
    const { filterValues } = useListContext();
    return (
        <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
            <DateField source="from" textAlign="center" label="De" sortable={false} />
            <DateField source="to" textAlign="center" label="Até" sortable={false} />
            <ReferenceArrayField label="Postos" emptyText="Todos" source="placeIds" textAlign="center" basePath="stations" reference="stations" link={false}>
                <SingleFieldList style={{ flexDirection: 'column', gap: 2, margin: '2px 0' }}>
                    <TextField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <NumberField source="amountFillins" label="Qnt. Abastecimentos" textAlign="center" emptyText="Não definido" />
            <TextField source="balanceFrom" label="Saldo Min." textAlign="center" emptyText="Não definido" />
            <TextField source="balanceTo" label="Saldo Máx." textAlign="center" emptyText="Não definido" />
            <Permission permission={PERMISSIONS.TAG}>
                <ReferenceArrayField label="Etiquetas" emptyText="Todos" source="tagIds" textAlign="center" reference="chains/chainId/tag" link={false}>
                    <SingleFieldList style={{ flexDirection: 'column', gap: 2, margin: '2px 0' }}>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
            </Permission>
            <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
        </Datagrid>
    );
}

const fillinByCustomerExport = async (data, fetchRelatedRecords) => {
    const relations = [
        { field: 'id', resource: 'customers' },
        { field: 'id', resource: 'customer-balance' }
    ];

    const fetchData = await fetchRelatedRecords(relations);

    const getData = (resource) => {
        const results = fetchData.find(({ resource: res }) => res === resource)?.results;
        if (results) {
            return results.reduce((acc, curr) => {
                acc[curr.id] = curr;
                return acc;
            }, {});
        }
        return {};
    };

    const customers = getData('customers');
    const balance = getData('customer-balance');

    return data.map((c) => ({
        nome: customers[c.id]?.name,
        cpf: customers[c.id]?.cpf,
        abastecimentos: c.fillins,
        telefone: customers[c.id]?.cellphone,
        pontos: balance[c.id]?.balance || 0
    }));
}

const ReportFillinByCustomer = (props) => {
    const placeId = getPlaceId();

    const filterValues = {
        from: moment().startOf('day').subtract(1, "month").toISOString(),
        to: moment().endOf('day').toISOString(),
    };

    if (placeId) {
        filterValues.placeIds = [placeId];
    }

    if (getEmployeeType() === EmployeeType.promoter) {
        filterValues.promoterIds = [getUserId()];
    }

    if (getEmployeeType() === EmployeeType.attendant) {
        filterValues.attendantIds = [getAttendantId()];
    }

    return (
        <Permission permission={PERMISSIONS.FILLIN_BY_CUSTOMER_REPORT}>
            <ReportList
                {...props}
                basePath="fillin-by-customer"
                title="Abastecimentos por Cliente"
                resource="chains/chainId/reports/fillin-by-customer"
                filters={<ListFilters />}
                sort={{ field: 'balance', order: 'DESC' }}
                perPage={25}
                pagination={<ListPagination />}
                filterDefaultValues={filterValues}
                bulkActionButtons={false}
                customExporter={fillinByCustomerExport}
                titleOnPrint="Relatório de Abast. por Cliente"
                filterTablePrint={<FilterTablePrint />}
                landscape
            >
                <FillinByCustomer />
            </ReportList>
        </Permission>
    );
};
export default ReportFillinByCustomer;