import Casino from '@mui/icons-material/Casino';
import { PERMISSIONS } from '../../constants';
import create from './DrawingCreate';
import list from './DrawingList';
import edit from './DrawingEdit';
import show from './DrawingShow';

export default {
  create,
  list,
  edit,
  show,
  name: 'chains/chainId/drawings',
  permissions: [PERMISSIONS.DRAWING],
  options: { label: 'Sorteios' },
  icon: Casino,
}