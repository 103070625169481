import React, { useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  FunctionField,
  DateField,
  Button,
  useRefresh,
  useDataProvider,
  useNotify,
  RichTextField,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  TextFieldProps,
  useRecordContext,
} from 'react-admin';
import ListPagination from '../../components/ListPagination';
import { DATE_FORMATER, PERMISSIONS } from '../../constants';
import Permission from '../../components/Permission';
import { makeStyles } from '@mui/styles';
import { DispatchMessageOrigin } from '../../models';
import { Tooltip } from '../../components';

const useStyles = makeStyles(theme => ({
  richTextImage: {
    "& img": {
      maxWidth: 300,
      maxHeight: 300
    }
  }
}));

const CardinalityField: React.FC<TextFieldProps> = ({ source, textAlign }) => {
  const record = useRecordContext();
  return (
    record && source && record[source] ?
      <NumberField record={record} source={source} />
      :
      <Tooltip
        title="A cardinalidade do envio será definida no momento do envio"
        textAlign={textAlign}
        placement="bottom">A definir</Tooltip>
  );
}

const DispatchMessageList: React.FC = props => {
  const [loading, setLoading] = useState<boolean>(false);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();
  const { richTextImage } = useStyles();

  const cancelDispatchMessage = (record) => {
    setLoading(true);
    dataProvider.delete('chains/chainId/dispatch-messages', { id: record.id, previousData: record })
      .then(() => {
        notify('Envio cancelado com sucesso.');
        refresh();
      })
      .catch(() => { notify('Erro ao cancelar envio.'); })
      .finally(() => { setLoading(false); });
  };

  return (
    <Permission permission={PERMISSIONS.DISPATCH_MESSAGE}>
      <List
        {...props}
        perPage={25}
        pagination={<ListPagination />}
        exporter={false}
        bulkActionButtons={false}
        title="Envios"
        sort={{ field: 'sendAt', order: 'DESC' }}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source="message.title" label="Título" />
          <RichTextField className={richTextImage} source="message.body" label="Mensagem" />
          <FunctionField label='Tipo' render={record => record?.type === 'sms' ? 'SMS' : 'Notificação pelo aplicativo'} />
          <DateField source="sendAt" label="Data" options={DATE_FORMATER} />
          <CardinalityField source="cardinality" label="Cardinalidade" textAlign="center" />
          <FunctionField label='Status' render={record => {
            switch (record?.status) {
              case 'scheduled':
                return 'Agendado';
              case 'canceled':
                return 'Cancelado';
              case 'dispatched':
                return 'Enviado';
              case 'dispatched-with-error':
                return 'Enviado';
            }
          }} />
          <FunctionField
            label="Grupo de Clientes"
            sortable={false}
            render={(record) => {
              switch (record?.origin) {
                case DispatchMessageOrigin.unifiedSend:
                  return (
                    <ReferenceField source="messageId" reference="chains/chainId/unified-send" link={false} emptyText="Sem Grupo">
                      <ReferenceField source="customerGroupId" reference="chains/chainId/customer-groups" link={false}>
                        <ChipField source="name" />
                      </ReferenceField>
                    </ReferenceField>
                  )
                case DispatchMessageOrigin.discountMarketingCampaign:
                  return (
                    <ReferenceField source="messageId" reference="marketing-discounts" link={false} emptyText="Sem Grupo">
                      <ReferenceField source="discountId" reference="discounts" link={false}>
                        <ReferenceArrayField reference="chains/chainId/customer-groups" source="customerGroupIds">
                          <SingleFieldList linkType={false}>
                            <ChipField source="name" />
                          </SingleFieldList>
                        </ReferenceArrayField>
                      </ReferenceField>
                    </ReferenceField>
                  )
                default:
                  return null;
              }
            }}
          />
          <FunctionField label="Grupo de Postos"
            sortable={false}
            render={(record) => {
              switch (record?.origin) {
                case DispatchMessageOrigin.unifiedSend:
                  return (
                    <ReferenceField source="messageId" reference="chains/chainId/unified-send" emptyText="Sem Grupo">
                      <ReferenceField source="stationGroupId" reference="chains/chainId/station-groups" link={false}>
                        <TextField source="name" />
                      </ReferenceField>
                    </ReferenceField>
                  );
                default:
                  return null;
              }
            }}
          />
          <FunctionField label="Categoria de clientes"
            sortable={false}
            render={(record) => {
              switch (record?.origin) {
                case DispatchMessageOrigin.unifiedSend:
                  return (
                    <ReferenceField source="messageId" reference="chains/chainId/unified-send" emptyText="Sem Grupo">
                      <ReferenceField source="clientCategoryId" reference="client-categories" link={false}>
                        <TextField source="label" />
                      </ReferenceField>
                    </ReferenceField>
                  );
                default:
                  return null;
              }
            }}
          />
          <FunctionField source="origin" label="Tipo" render={record => DispatchMessageOrigin.translateOrigin(record ? record.origin : null)} />
          <FunctionField label='' render={record => {
            if (record?.status === 'scheduled') {
              return <Button label='Cancelar Envio' onClick={() => cancelDispatchMessage(record)} disabled={loading} />;
            }
          }} />
        </Datagrid>
      </List>
    </Permission>
  );
};

export default DispatchMessageList;
