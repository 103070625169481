import { Button, Confirm } from 'ra-ui-materialui';
import React, { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import { Tooltip, Typography } from '@mui/material';
import { useDataProvider, useGetList, useGetRecordId, useNotify, useResourceContext } from 'react-admin';
import { BulkSendStatus } from '../../models';

const ResendToAllButton: React.FC = () => {

  const resource = useResourceContext();
  const bulkSendId = useGetRecordId();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const { total, isLoading } = useGetList(resource, { filter: { bulkSendId, status: BulkSendStatus.failed } });

  const [open, setOpen] = useState(false);
  const [isSendLoading, setIsSendLoading] = useState(false);

  const handleDialogClose = () => {
    setOpen(false);
  }

  const handleConfirm = () => {
    handleDialogClose();
    setIsSendLoading(true);

    dataProvider.create('bulk-sends/resend', { data: { bulkSendId, customerStatus: BulkSendStatus.failed } })
      .then(() => {
        notify('Reenvio em andamento. Por favor, aguarde e recarregue a página para verificar o status.');
      })
      .catch(() => {
        notify('Erro ao realizar o reenvio', { type: 'error' });
      })
      .finally(() => {
        setIsSendLoading(false);
      });
  }

  const handleClick = () => {
    setOpen(true);
  }

  return (
    <>
      <Confirm
        isOpen={open}
        title="Reenvio em massa"
        content={
          <Typography variant="body1" color="rgba(0,0,0,0.6)">
            Ao confirmar, será realizado um novo envio para todos os clientes que possuem status de erro. Totalizando <strong>{total} clientes</strong>
          </Typography>
        }
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
      <Tooltip
        title="Reenviar para todos que estão com status de erro"
        placement="bottom"
        arrow
      >
        <div>
          <Button
            label="Reenviar"
            startIcon={<SendIcon />}
            onClick={handleClick}
            disabled={isLoading || isSendLoading || total === 0}
          />
        </div>
      </Tooltip>
    </>
  );
}

export default ResendToAllButton;