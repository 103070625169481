import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { BooleanInput, FormDataConsumer, ImageField, ImageInput, TextInput, useDataProvider } from 'react-admin';
import { LazyLoadAutoCompleteInput, PriceInput } from '../../../components';
import { discountTypes } from '../../../constants';

const FormProduct: React.FC<{ formData?: any, setProductInfo: (value: any) => void }> = ({ formData, setProductInfo }) => {

  const discountTypeChoices = Object.entries(discountTypes).map(([value, label]) => ({ value, label }));
  const dataProvider = useDataProvider();

  useEffect(() => {
    if (formData.hasProduct && formData.productId) {
      dataProvider.getOne('chains/chainId/products', { id: formData.productId })
        .then(({ data }) => {
          setProductInfo(data);
        });
    } else {
      setProductInfo(null);
    }
  }, [formData.hasProduct, formData.productId]);

  return (
    <>
      <Grid container md={8}>
        <Grid item xs={12}>
          <BooleanInput source="hasProduct" label="Vincular produto existente?" fullWidth />
        </Grid>
        <FormDataConsumer>
          {({ formData }) => (
            formData.hasProduct ?
              <Grid item xs={12}>
                {/* @ts-ignore */}
                <LazyLoadAutoCompleteInput
                  label="Produto"
                  source="productId"
                  format={choice => `${choice.name}`}
                  style={{ minWidth: 300 }}
                  reference="chains/chainId/products"
                  fullWidth
                />
              </Grid>
              :
              <>
                <Grid item xs={12}>
                  <TextInput source="productName" label="Nome" fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <TextInput source="productDescription" label="Descrição" fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <PriceInput source="productValue" label="Valor em reais " fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <ImageInput source="productImageFile" label="Imagem">
                    <ImageField source="src" label="Imagem" />
                  </ImageInput>
                </Grid>
              </>
          )}
        </FormDataConsumer>
      </Grid>
    </>
  );
}

export default FormProduct;