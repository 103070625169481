import 'moment/locale/pt-br';
import { Admin, CustomRoutes, Resource } from "react-admin";
import { Route } from 'react-router-dom';
import Dashboard from './Dashboard';
import { Layout } from './containers';
import customRoutes from './customRoutes';
import { StoreProvider } from './hooks/useStore';
import { isEmpty } from './lib';
import authProvider from './providers/authProvider';
import i18nProvider from './providers/i18nProvider';
import resources from './resources';
import login from './resources/login';
import dataProvider from './store/providers/react-admin-data';
import { theme } from './theme';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { memoryStore } from './store/memoryStore';
import ComponentWithPermissions from './resources/ComponentWithPermissions';
dayjs.locale('pt-br');

const App = () => {
  return (
    <StoreProvider>
      <Admin
        dashboard={Dashboard}
        customRoutes={CustomRoutes}
        loginPage={login}
        theme={theme}
        title="PontuaX"
        layout={Layout}
        dataProvider={dataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        store={memoryStore()}
      >
        {
          permissions => {
            let initialResources = [...resources
              .filter(r => isEmpty(r.permissions) || r.permissions.some(r => permissions && permissions.includes(r)))
              .map(parameters => {
                Object.keys(parameters).forEach(key => {
                  const p = parameters[key];
                  if (p instanceof ComponentWithPermissions) {
                    parameters[key] = p.getComponent(permissions);
                  }
                });
                return <Resource {...parameters} key={parameters.name} hasCreate={!!parameters.create} hasEdit={!!parameters.edit} hasShow={!!parameters.show} />;
              })
            ];

            if (permissions) {
              initialResources = initialResources.concat(
                [
                  <Resource name="app-payment-methods" />,
                  <Resource name="attendants" hasCreate hasEdit hasShow />,
                  <Resource name="billings" hasCreate />,
                  <Resource name="chains" hasCreate hasEdit hasShow />,
                  <Resource name="chains-config" />,
                  <Resource name="chains/chainId/customers/customerId/coupons" />,
                  <Resource name="chains/chainId/customers/transactions" />,
                  <Resource name="chains/chainId/devices" />,
                  <Resource name="chains/chainId/fillin-cashback" />,
                  <Resource name="chains/chainId/import-points" />,
                  <Resource name="chains/chainId/inbox-notifications" />,
                  <Resource name="chains/chainId/limit-number-fillin" hasCreate />,
                  <Resource name="chains/chainId/marketing-campaigns" />,
                  <Resource name="chains/chainId/notification-messages" />,
                  <Resource name="chains/chainId/point-fillin-interval" hasCreate />,
                  <Resource name="chains/chainId/point-limit" hasCreate />,
                  <Resource name="chains/chainId/products" />,
                  <Resource name="chains/chainId/reports/added-points" />,
                  <Resource name="chains/chainId/reports/advertisement-viwing" />,
                  <Resource name="chains/chainId/reports/approved-transactions" />,
                  <Resource name="chains/chainId/reports/attendant" />,
                  <Resource name="chains/chainId/reports/billings" />,
                  <Resource name="chains/chainId/reports/blocked-customers" />,
                  <Resource name="chains/chainId/reports/coupons" />,
                  <Resource name="chains/chainId/reports/customers" />,
                  <Resource name="chains/chainId/reports/customers-deleted" />,
                  <Resource name="chains/chainId/reports/customers-without-drawing" />,
                  <Resource name="chains/chainId/reports/default-points" />,
                  <Resource name="chains/chainId/reports/detailed-points" />,
                  <Resource name="chains/chainId/reports/device-customer" />,
                  <Resource name="chains/chainId/reports/discount" />,
                  <Resource name="chains/chainId/reports/drawing" />,
                  <Resource name="chains/chainId/reports/favorite-station" />,
                  <Resource name="chains/chainId/reports/fillin-by-attendant" />,
                  <Resource name="chains/chainId/reports/fillin-by-customer" />,
                  <Resource name="chains/chainId/reports/fillin-by-fuel" />,
                  <Resource name="chains/chainId/reports/fillin-by-station" />,
                  <Resource name="chains/chainId/reports/fillins" />,
                  <Resource name="chains/chainId/reports/first-fillin" />,
                  <Resource name="chains/chainId/reports/fixed-discount-report" />,
                  <Resource name="chains/chainId/reports/inactive-customers" />,
                  <Resource name="chains/chainId/reports/monthly-evolution" />,
                  <Resource name="chains/chainId/reports/new-customers" />,
                  <Resource name="chains/chainId/reports/notifications" />,
                  <Resource name="chains/chainId/reports/partner-values" />,
                  <Resource name="chains/chainId/reports/partners" />,
                  <Resource name="chains/chainId/reports/payments-by-pix" />,
                  <Resource name="chains/chainId/reports/pending-transaction" />,
                  <Resource name="chains/chainId/reports/points-balance" />,
                  <Resource name="chains/chainId/reports/products" />,
                  <Resource name="chains/chainId/reports/recurring-customers" />,
                  <Resource name="chains/chainId/reports/redeemed-coupons" />,
                  <Resource name="chains/chainId/reports/redemption-x-points" />,
                  <Resource name="chains/chainId/reports/refered-customers" />,
                  <Resource name="chains/chainId/reports/refunded-coupons" />,
                  <Resource name="chains/chainId/reports/registered-discounts" />,
                  <Resource name="chains/chainId/reports/registration-customers" />,
                  <Resource name="chains/chainId/reports/station" />,
                  <Resource name="chains/chainId/reports/top-customers" />,
                  <Resource name="chains/chainId/station-groups" hasCreate hasEdit />,
                  <Resource name="chains/chainId/stations" />,
                  <Resource name="client-categories" />,
                  <Resource name="companies" />,
                  <Resource name="coupons" />,
                  <Resource name="customer-balance" />,
                  <Resource name="customer-discounts" />,
                  <Resource name="customer-last-fillin" />,
                  <Resource name="customers" hasShow hasEdit />,
                  <Resource name="customers/customerId/transactions" options={{ customerId: null }} />,
                  <Resource name="drawing-lucky-numbers" />,
                  <Resource name="drawing-rule-fuels" />,
                  <Resource name="drawing-rules" />,
                  <Resource name="employees" hasCreate hasEdit hasShow />,
                  <Resource name="employees/recovery" />,
                  <Resource name="fillins" />,
                  <Resource name="fuel-discounts" />,
                  <Resource name="fuels" />,
                  <Resource name="permissions" />,
                  <Resource name="places" hasCreate hasEdit hasShow />,
                  <Resource name="rating-poll-items" />,
                  <Resource name="report-coupons" />,
                  <Resource name="reports/concentrator-error" />,
                  <Resource name="reports/current-fillins" />,
                  <Resource name="reports/expiring-points-by-customer" />,
                  <Resource name="reports/expiring-points-by-date" />,
                  <Resource name="reports/external-partners" />,
                  <Resource name="reports/socket-error" />,
                  <Resource name="reports/station-rating" />,
                  <Resource name="sales" />,
                  <Resource name="security-rules" />,
                  <Resource name="station-attendants" />,
                  <Resource name="station-qrcodes" />,
                  <Resource name="transaction-redemptions" />,
                  <Resource name="transactions" />,
                ]
              );
            }
            return initialResources;
          }}
        <CustomRoutes>
          {
            customRoutes.map(customRoute => <Route path={customRoute.path} element={customRoute.element} />)
          }
        </CustomRoutes>
      </Admin>
    </StoreProvider>
  );
};

export default App;
