import React from 'react';
import { FormDataConsumer, RadioButtonGroupInput, TextInput } from 'react-admin';
import { discountTypes } from '../../../constants';

const FormDiscountType: React.FC<{ formData?: any }> = ({ formData }) => {

  const discountTypeChoices = Object.entries(discountTypes).map(([value, label]) => ({ value, label }));

  return (
    <>
      <RadioButtonGroupInput
        label="Tipo de desconto"
        source="type"
        choices={!!formData.customerIds ? discountTypeChoices.filter(d => d.value === 'special') : discountTypeChoices}
        defaultValue={"special"}
        optionText="label"
        optionValue="value"
      />
      <FormDataConsumer>
        {({ formData }) => formData.type === 'special' && (
          <TextInput source="description" label="Descrição" required fullWidth />
        )}
      </FormDataConsumer>
    </>
  );
}

export default FormDiscountType;