import SaveIcon from '@mui/icons-material/Save';
import { Box, Step, StepButton, Stepper } from '@mui/material';
import React, { useState } from 'react';
import { ButtonProps, FormDataConsumer, SimpleForm, Toolbar, useNotify, useRecordContext } from 'react-admin';
import WizardButton from './wizardButton';

interface FormStep {
  label: string;
  component: React.ReactElement;
  validate?: ((formData: any) => { error: boolean, message?: string }) | ((formData: any) => Promise<{ error: boolean, message?: string }>),
  onNextFunction?: (formData: any, nextStep: () => void) => void;
  disableToolbarButton?: boolean | null;
  disableToolbar?: boolean | null;
  disableStep?: (data: any, completedSteps: { [k: string]: boolean }) => boolean;
}

interface WizardFormProps {
  defaultValues?: any;
  formSteps: FormStep[];
  handleConcluedStep?: (index: number) => void;
  completedSteps: { [k: string]: boolean };
  isLoading?: boolean | null;
  sanitizeEmptyValues?: boolean | undefined;
  transformRecord?: (record: any) => any;
}

const WizardForm: React.FC<WizardFormProps> = ({ formSteps, defaultValues, completedSteps, sanitizeEmptyValues, handleConcluedStep, isLoading, transformRecord }) => {

  const [index, setIndex] = useState(0);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const notify = useNotify();
  const record = useRecordContext();

  const nextStep = (value?: number | null) => {
    if (handleConcluedStep) {
      handleConcluedStep(index);
    }

    if (value !== null && value !== undefined) { setIndex(value); }

    if ((formSteps.length - 1) > index) {
      setIndex(index => index + 1);
    }
  }

  const handleSimpleFormButton = async (values: any) => {
    try {
      return await new Promise(async (resolve) => resolve(await (async () => {
        setIsButtonLoading(true);
        const { onNextFunction, validate } = formSteps[index];

        if (validate) {
          const { error, message } = await validate(values);
          if (error && message) {
            notify(message, { type: 'warning' });
            return;
          }
        }

        if (onNextFunction) {
          onNextFunction(values, nextStep);
        } else {
          nextStep();
        }
      })()));
    } finally {
      setIsButtonLoading(false);
    }
  }

  const SimpleFormButton: React.FC<ButtonProps> = ({ ...props }) => (
    <WizardButton
      {...props}
      onClick={(values) => handleSimpleFormButton(values)}
    />
  );

  const ToolbarCustom = (props) => (
    <Toolbar>
      <FormDataConsumer>
        {({ formData }) => (
          <SimpleFormButton
            {...props}
            formData={formData}
            label={(formSteps.length - 1) === index ? 'Salvar' : 'Próximo'}
            startIcon={(formSteps.length - 1) === index && <SaveIcon />}
            variant="contained"
            size="medium"
            isLoading={isButtonLoading || isLoading}
            disabled={formSteps[index].disableToolbarButton || false}
          />
        )}
      </FormDataConsumer>
    </Toolbar>
  );

  return (
    <Box style={{ marginTop: 20 }}>
      <SimpleForm
        defaultValues={defaultValues || null}
        toolbar={formSteps[index].disableToolbar !== true && <ToolbarCustom />}
        // warnWhenUnsavedChanges
        sanitizeEmptyValues={sanitizeEmptyValues}
        record={transformRecord ? transformRecord(record) : record}
      >
        <FormDataConsumer>
          {({ formData }) =>
            <Stepper sx={{ width: '100%', marginBottom: '30px' }} activeStep={index}>
              {formSteps.map(({ label, disableStep }, idx) => (
                <Step key={label} completed={completedSteps[idx] === true}>
                  <StepButton
                    color="inherit"
                    disabled={(isLoading || (disableStep ? disableStep(formData, completedSteps) : false)) || false}
                    onClick={() => setIndex(idx)}
                  >
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) =>
            React.cloneElement(formSteps[index].component, { nextStep, SimpleFormButton, formData })
          }
        </FormDataConsumer>
      </SimpleForm>
    </Box>
  );
}

export default React.memo(WizardForm);