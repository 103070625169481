import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  ImageInput,
  ImageField,
} from 'react-admin';
import { ImageInformations } from '../../components';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';

export default props => (
  <Permission permission={PERMISSIONS.ADMIN}>
    <Create {...props} title="Adicionar serviço" >
      <SimpleForm>
        <TextInput source="name" label="Nome" validate={required("Nome é obrigatório")} />
        <TextInput source="description" label="Descrição" />
        <ImageInput source="imagefile" label="Imagem" accept="image/*">
          <ImageField source="src" label="Imagem" />
        </ImageInput>
        <ImageInformations position={-20}/>
      </SimpleForm>
    </Create>
  </Permission >
);
