import React, { useState, useEffect } from 'react';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import { PERMISSIONS } from '../../constants';
import { getChainId, getEmployeeType, getAttendantId } from '../../lib';
import useDashboardContext from '../../hooks/useDashboardContext';
import { DashboardWrapper, RotatedAxisTick } from '../../components';
import moment from 'moment';
import { EmployeeType } from '../../models';

export const FillinsByFuelChart = () => {
  const [chartData, setChartData] = useState(null);

  const { permissions } = usePermissions();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { from, to, chain, origin, stationIds } = useDashboardContext();

  const chainId = permissions && permissions.includes(PERMISSIONS.ADMIN) ? chain : getChainId();

  useEffect(() => {

    const filter = {
      from: moment.utc(from),
      to: moment.utc(to),
      origin,
    };
    if (stationIds && stationIds.length > 0) { filter.stationIds = stationIds; }
    if (getEmployeeType() === EmployeeType.attendant) { filter.attendantId = getAttendantId(); }

    if (permissions && permissions.includes(PERMISSIONS.ADITION_POINTS)) {
      dataProvider.getList('dashboard/fillin-by-fuel', {
        filter, chainId
      }).then(response => {
        const fuels = response.data.sort((a, b) =>
          a.totalFillins < b.totalFillins ? 1 : (a.totalFillins > b.totalFillins ? -1 : 0));

        setChartData(fuels.filter(f => f.id !== '0' && f.totalFillins > 0));
      })
        .catch((e) => { notify('Erro ao carregar gráfico de abastecimentos por combustível', { type: 'warning' }); });
    }
  }, [permissions, from, to, chain, origin, stationIds]);

  return (
    (!chartData || chartData.length > 0) &&
    <DashboardWrapper
      permission={PERMISSIONS.FILLINS_CHART}
      title='Abastecimentos por Combustível'
      width='45%'
      link='/report-fillin-by-fuel'
      content={
        <BarChart data={chartData}>
          <XAxis dataKey="name" tick={<RotatedAxisTick fontSize={12} />} interval={0} height={94} />
          <YAxis width={40} domain={[0, 'auto']} type='number' />
          <Tooltip />
          <CartesianGrid strokeDasharray="3 3" stroke="#DFE2E6" />
          <Bar dataKey="totalFillins" fill="#82ca9d" barSize={50} name='Abastecimentos' />
        </BarChart>
      }
    />
  );
};