import { Bank } from "../models";
interface BankServiceProps {
  getBanks(): Promise<{ data: Array<Bank>, error?: string }>;
}

class BankService implements BankServiceProps {
  async getBanks(): Promise<{ data: Bank[], error?: string }> {
    const request = new Request('https://brasilapi.com.br/api/banks/v1', {
      method: 'get',
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });

    const response = await fetch(request);
    if (response.status < 200 || response.status >= 300) {
      return { data: [], error: 'Erro ao retornar a lista de Bancos' };
    }

    const data = await response.json();

    return { data: (data as unknown as Bank[]).filter(item => item.code && item.name).map((bank: Bank) => ({ id: bank.code, code: bank.code, name: bank.name })) };
  }
}

export default BankService;