import React from 'react'
import {
    Edit,
    TextInput,
    required,
    SimpleForm,
} from 'react-admin'

import { PERMISSIONS } from '../../constants'
import Permission from '../../components/Permission'

const edit = ({ ...props }) => (
    <Permission permission={PERMISSIONS.ADMIN}>
        <Edit {...props} title="Editar grupo de permissões">
            <SimpleForm>
                <TextInput source="name" label="Nome" validate={required()} InputProps={{ autoComplete: 'off' }} />
            </SimpleForm>
        </Edit>
    </Permission>
)

export default edit
