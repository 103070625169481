import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { Datagrid, DateField, FunctionField, NumberField, ReferenceField, ReferenceInput, SelectInput, TextField, useListContext, usePermissions, useRecordContext } from 'react-admin';
import { Permission, ReportList } from '../../components';
import { BetweenDatesTimesInput } from '../../components/BetweenDatesInput';
import { CPFField } from '../../components/Fields';
import ListPagination from '../../components/ListPagination';
import { PERMISSIONS } from '../../constants';
import { getChainId, getPlaceId, getUserId } from '../../lib';
import RequiredChain from '../../components/RequiredChain';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
}));

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  return (
    <Datagrid classes={{ headerCell: classes.headerCell }} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
      <DateField source="from" textAlign="center" label="De" sortable={false} locales="pt-BR" />
      <DateField source="to" textAlign="center" label="Até" sortable={false} locales="pt-BR" />
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} locales="pt-BR" />
    </Datagrid>
  );
};

const ProductField = ({ ...props }) => {
  const record = useRecordContext();
  if (!record) { return null; }

  if (record.productId) {
    return (
      <ReferenceField
        emptyText="Produto não identificado"
        source="productId"
        basePath="chains/chainId/products"
        reference="chains/chainId/products"
        link={false}
        sortable={false}
        {...props}
      >
        <TextField source="name" />
      </ReferenceField>
    );
  }

  return (
    <FunctionField {...props} render={() => record.isCashback ? 'Cashback' : 'Produto não identificado'} />
  );
};

const RedeemedCouponsList = () => {
  const classes = useStyles();
  const props = useListContext();

  let data = [];
  let summaryData = [];

  if (props.data) {
    const reportInfo = props.data[0];

    if (reportInfo) {
      data = reportInfo.data;
      summaryData = [reportInfo.summaryData];
    }
  }

  return (
    <>
      <Typography className={classes.title}>Totais</Typography>
      <Datagrid bulkActionButtons={false} style={{ marginBottom: 60 }} data={summaryData} total={1}>
        <NumberField
          label="Pontos"
          source="total_value"
          locales="pt-BR"
          sortable={false}
          textAlign="center"
          options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }} />
        <NumberField
          label="Valor"
          source="total_partner_value"
          locales="pt-BR"
          sortable={false}
          textAlign="center"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <NumberField
          label="Cupons"
          source="count"
          textAlign="center"
          sortable={false}
        />
      </Datagrid>
      <Typography className={classes.title}>Extrato de Cupons Resgatados</Typography>
      <Datagrid
        bulkActionButtons={false}
        resource={props.resource}
        data={data}
        rowClick={false}
      >
        {
          Permission({
            permission: PERMISSIONS.COUPONS_CODE,
            children: (
              <TextField source="barcode" label="Cupom" />
            )
          })
        }
        <ProductField label="Produto" />
        <ReferenceField
          label="Cliente"
          source="customerId"
          reference="customers"
          emptyText="Cliente não identificado"
          link={false}
          sortable={false}
          textAlign="center"
        >
          <CPFField source="cpf" />
        </ReferenceField>
        <NumberField
          source="value"
          label="Pontos"
          textAlign="center"
          emptyText='-'
        />
        <NumberField
          label="Valor"
          source="partnerValue"
          locales="pt-BR"
          emptyText="R$ 0,00"
          textAlign="center"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <DateField source="redeemedAt" label="Data de troca" textAlign="center" showTime />
        <DateField source="createdAt" label="Data de geração" textAlign="center" showTime />
      </Datagrid>
    </>
  );
};

const RedeemedCoupons = (props) => {
  const { permissions } = usePermissions();
  const listFilters = [
    <BetweenDatesTimesInput alwaysOn />,
  ];

  if (permissions && permissions.includes(PERMISSIONS.ADMIN)) {
    listFilters.push(
      <ReferenceInput
        source="chainId"
        reference="chains"
        perPage={false}
        sort={{ field: "name", order: "ASC" }}
        allowEmpty
        alwaysOn
      >
        <SelectInput
          label="Rede"
          emptyText="Todos"
          style={{ minWidth: 180 }}
          optionText={'name'}
        />
      </ReferenceInput>);
  }

  return (
    <Permission permission={PERMISSIONS.REDEEMED_COUPONS_REPORT}>
      <RequiredChain>
        <ReportList
          {...props}
          basePath="report-redeemed-coupons"
          title="Cupons Resgatados"
          resource="chains/chainId/reports/redeemed-coupons"
          filters={listFilters}
          perPage={25}
          pagination={<ListPagination />}
          filterDefaultValues={{
            chainId: getChainId(),
            employeeId: getPlaceId() && getUserId(),
            from: moment().subtract(1, 'month').startOf('day').toISOString(),
            to: moment().endOf('day').toISOString()
          }}
          sort={{ field: 'redeemedAt', order: 'DESC' }}
          bulkActionButtons={false}
          titleOnPrint="Relatório de Cupons Resgatados"
          filterTablePrint={<FilterTablePrint />}
          landscape={true}
        >
          <RedeemedCouponsList />
        </ReportList>
      </RequiredChain>
    </Permission>
  );
};

export default RedeemedCoupons;