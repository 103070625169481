import React from 'react';
import { getChainId } from '../../lib';
import { CardContent, Paper, Typography } from '@mui/material';

const Wrapper = ({ children }) => (
  <CardContent>
    <Paper sx={{ padding: 2 }}>
      {children}
    </Paper>
  </CardContent>
);

const RequiredChain = ({ children }) => {
  return (
    <>
      {
        getChainId() ? children :
          <Wrapper>
            <Typography>Selecione uma rede para continuar</Typography>
          </Wrapper>
      }
    </>
  );
}

export default RequiredChain;