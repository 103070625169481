import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import {
    ArrayField,
    Datagrid,
    DateField,
    FormDataConsumer,
    NumberField,
    ReferenceArrayField,
    ReferenceArrayInput,
    ReferenceField,
    SelectArrayInput,
    SingleFieldList,
    TextField,
    useListContext
} from 'react-admin';
import { ReportList } from '../../components';
import Permission from '../../components/Permission';
import { PERMISSIONS } from "../../constants";
import { getPlaceId } from "../../lib";

import { groupBy } from 'lodash';
import { MonthYearInput } from '../../components/Inputs';

const useStyles = makeStyles(theme => ({
    title: {
        margin: '10px 0 10px 0',
        textAlign: 'center',
        fontWeight: 'bold'
    },
}));

const MonthlyEvolution = () => {
    const props = useListContext();
    const classes = useStyles();

    const data = groupBy(props.data, 'month');

    Object.keys(data).forEach(key => {
        const totalValue = data[key].reduce((acc, item) => acc += parseFloat(item.total_value), 0);
        const totalAmount = data[key].reduce((acc, item) => acc += parseFloat(item.total_amount), 0);
        const totalFillin = data[key].reduce((acc, item) => acc += parseInt(item.total), 0);
        data[key] = {
            month: key,
            totalValue,
            totalAmount,
            avgTicket: totalValue / totalFillin,
            monthlyEvolutions: data[key],
        };
    });

    const summary = Object.values(data).reduce((prev, curr) => {
        return {
            totalValue: prev.totalValue += curr.totalValue,
            totalAmount: prev.totalAmount += curr.totalAmount,
        }

    }, { totalValue: 0, totalAmount: 0 })

    return (
        <>
            <Typography className={classes.title}>Total</Typography>
            <Datagrid data={[summary]} empty={false} bulkActionButtons={false}>
                <NumberField
                    label="Valor Total"
                    source="totalValue"
                    textAlign="center"
                    emptyText='R$ 0,00'
                    options={{
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }}
                    sortable={false}
                />
                <NumberField
                    label="Volume Total (L)"
                    source="totalAmount"
                    textAlign="center"
                    emptyText='0'
                    options={{
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }}
                    sortable={false}
                />
            </Datagrid>
            <Typography className={classes.title}>Evolução Mensal</Typography>
            <Datagrid
                data={Object.values(data)}
                bulkActionButtons={false}
                empty={
                    <Typography variant="body1">Nenhum registro encontrado</Typography>
                }
            >
                <TextField source="month" label="Mês" />
                <NumberField
                    source="totalAmount"
                    label="Volume total (L)"
                    textAlign="center"
                    emptyText='0'
                    options={{
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }}
                    sortable={false}
                />
                <NumberField
                    source="totalValue"
                    label="Valor total"
                    textAlign="center"
                    emptyText='R$ 0,00'
                    options={{
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }}
                    sortable={false}
                />
                <NumberField
                    source="avgTicket"
                    label="Ticket médio"
                    textAlign="center"
                    emptyText='0'
                    options={{
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }}
                    sortable={false}
                />
                <ArrayField source="monthlyEvolutions" label="Combustíveis" sortable={false}>
                    <Datagrid bulkActionButtons={false}>
                        <ReferenceField label="Combustível" basePath="fuels" reference="fuels" source="fuel_id" emptyText="Não Identificado">
                            <TextField source="name" />
                        </ReferenceField>
                        <NumberField
                            source="total_amount"
                            label="Volume (L)"
                            textAlign="center"
                            emptyText='0'
                            options={{
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            }}
                        />
                        <NumberField
                            source="total_value"
                            label="Valor"
                            textAlign="center"
                            emptyText='R$ 0,00'
                            options={{
                                style: 'currency',
                                currency: 'BRL',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            }}
                        />
                        <NumberField
                            source="avg_ticket"
                            label="Ticket médio"
                            textAlign="center"
                            emptyText='0'
                            options={{
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            }}
                        />
                    </Datagrid>
                </ArrayField>
            </Datagrid>
        </>
    );
};

const listFilters = [
    <FormDataConsumer alwaysOn>
        {({ formData }) => (
            <MonthYearInput
                source="from"
                label="De"
                format={date => moment(date).startOf('month').toISOString()}
                maxDate={moment(formData.to).subtract(1, 'month')}
                alwaysOn
            />
        )}
    </FormDataConsumer>,
    <FormDataConsumer alwaysOn>
        {({ formData }) => (
            <MonthYearInput
                source="to"
                label="Até"
                format={date => moment(date).endOf('month').toISOString()}
                minDate={moment(formData.from).add(1, 'month')}
                alwaysOn
            />
        )}
    </FormDataConsumer>,
    <ReferenceArrayInput
        source="placeIds"
        reference="stations"
        sort={{ field: "name", order: "ASC" }}
        alwaysOn
    >
        <SelectArrayInput
            label="Posto"
            emptyText="Todos"
            style={{ minWidth: 180 }}
            optionText="name"
        />
    </ReferenceArrayInput>,
    <ReferenceArrayInput
        source="fuelIds"
        reference="fuels"
        sort={{ field: "name", order: "ASC" }}
        alwaysOn
    >
        <SelectArrayInput
            label="Combustível"
            emptyText="Todos"
            style={{ minWidth: 180 }}
            optionText="name"
        />
    </ReferenceArrayInput>,
];

const FilterTablePrint = () => {
    const classes = useStyles();
    const { filterValues } = useListContext();
    return (
        <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
            <DateField source="from" textAlign="center" label="De" sortable={false} />
            <DateField source="to" textAlign="center" label="Até" sortable={false} />
            <ReferenceArrayField label="Postos" emptyText="Todos" source="placeIds" textAlign="center" basePath="stations" reference="stations" link={false}>
                <SingleFieldList style={{ flexDirection: 'column', gap: 2, margin: '2px 0' }}>
                    <TextField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <ReferenceArrayField label="Combustíveis" emptyText="Todos" source="fuelIds" textAlign="center" basePath="fuels" reference="fuels" link={false}>
                <SingleFieldList style={{ flexDirection: 'column', gap: 2, margin: '2px 0' }}>
                    <TextField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
        </Datagrid>
    );
}

const ReportMonthlyEvolution = (props) => {
    const placeId = getPlaceId();
    const stationsIndex = listFilters.findIndex(({ props }) => props.reference === 'stations');
    if (placeId && stationsIndex > -1) {
        listFilters.splice(stationsIndex, 1);
    }

    return (
        <Permission permission={PERMISSIONS.MONTHLY_EVOLUTION_REPORT}>
            <ReportList
                {...props}
                basePath="fillins"
                title="Evolução Mensal"
                resource="chains/chainId/reports/monthly-evolution"
                filters={listFilters}
                sort={{ field: 'month', order: 'ASC' }}
                perPage={null}
                showPagination={false}
                filterDefaultValues={{
                    placeIds: placeId ? [placeId] : null,
                    from: moment().startOf('month').subtract(2, "month").toISOString(),
                    to: moment().endOf('month').toISOString()
                }}
                bulkActionButtons={false}
                exporter={false}
                titleOnPrint="Relatório de Evolução Mensal"
                filterTablePrint={<FilterTablePrint />}
                landscape
            >
                <MonthlyEvolution />
            </ReportList>
        </Permission>
    );
};

export default ReportMonthlyEvolution;