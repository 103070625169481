import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateTimeInput, useDataProvider, useNotify } from 'react-admin';
import { DropDownPartners, Loader, ReportLayout, TableGeneric } from '../../components';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { getChainId, getPlaceId } from '../../lib';
import { formatNumber } from '../../utils';

const SoldProductsReport = ({ products }) =>
    <TableGeneric
        headCells={[
            'Nome',
            'Quantidade',
            'Valor'
        ]}
        arrayData={products}
        title="Produtos Vendidos"
        style={{ width: '100%', margin: '1em 0' }} />

const ReportSoldProducts = props => {
    const [from, setFrom] = useState(moment().startOf('days'));
    const [to, setTo] = useState(moment().endOf('days'));
    const [products, setProducts] = useState([]);
    const [chains, setChains] = useState([]);
    const [places, setPlaces] = useState([]);
    const [chainId, setChainId] = useState(getChainId());
    const [placeId, setPlaceId] = useState(getPlaceId());
    const [productId, setProductId] = useState();
    const [loading, setLoading] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const fetchPlaces = () => {
        const filter = chainId ? { chainId, syncType: 'auto-system' } : { syncType: 'auto-system' };
        dataProvider.getList('places', { filter, sort: { field: 'name', order: 'ASC' } })
            .then(({ data }) => { setPlaces(data); })
            .catch(() => { notify('Erro ao carregar locais de compra.', { type: 'warning' }); });
    };

    useEffect(() => {
        if (chainId) {
            if (!placeId) { fetchPlaces(); };
        } else {
            dataProvider.getList('chains', { sort: { field: 'name', order: 'ASC' } })
                .then(({ data }) => {
                    setChains(data);
                    fetchPlaces();
                })
                .catch(() => { notify('Erro ao carregar redes de postos.', { type: 'warning' }); });
        };
    }, []);

    useEffect(() => {
        setLoading(true);

        const filter = { from, to };
        if (placeId) { filter.placeId = placeId; }
        else if (chainId) { filter.chainId = chainId; };
        if (productId) { filter.productId = productId };

        dataProvider.getList('reports/sold-products', { filter })
            .then(({ data }) => {
                const products = data.map(p => {
                    const totalValue = p.total_value
                        ? formatNumber(Number(p.total_value), { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                        : 'Não Definido';

                    return ({
                        name: p.fuel_name || p.sale_product_name,
                        id: p.id,
                        amount: p.total_amount,
                        value: totalValue
                    })
                });

                setProducts(products);
            })
            .catch(() => { notify('Erro ao carregar produtos vendidos', { type: 'warning' }) })
            .finally(() => { setLoading(false); });
    }, [from, to, chainId, placeId, productId]);

    const filters = [{
        from: from.format('DD/MM/YYYY  HH:mm'),
        to: to.format('DD/MM/YYYY  HH:mm'),
        place: placeId && places.length ? places.find(p => p.id === placeId).name : 'Todos',
        product: productId ? products.find(p => p.id === productId).name : 'Todos'
    }]

    return (
        <ReportLayout
            permission={PERMISSIONS.SOLD_PRODUCTS_REPORT}
            title='Relatório de Produtos Vendidos'
            filterTablePrint={
                <TableGeneric
                    headCells={[
                        'De',
                        'Até',
                        'Local de compra',
                        'Produto'
                    ]}
                    arrayData={filters}
                    title="Filtros"
                    style={{ width: '100%', margin: '1em 0' }}
                />
            }
            filters={[
                <DateTimeInput
                    label='De'
                    ampm={false}
                    disableFuture
                    maxDate={to}
                    parse={(value) => moment(value).toISOString()}
                    defaultValue={moment().startOf('day').toISOString()}
                    onChange={date => setFrom(date)}
                />,
                <DateTimeInput
                    label='Até'
                    ampm={false}
                    disableFuture
                    minDate={from}
                    parse={(value) => moment(value).toISOString()}
                    defaultValue={moment().endOf('day').toISOString()}
                    onChange={date => setTo(date)}
                />,
                <Permission permission={PERMISSIONS.ADMIN}>
                    <DropDownPartners
                        label="Rede de troca"
                        value={chainId}
                        setValue={setChainId}
                        arrayData={chains}
                    />
                </Permission>,
                !getPlaceId() &&
                <DropDownPartners
                    label="Local de compra"
                    value={placeId}
                    setValue={setPlaceId}
                    arrayData={chainId ? places.filter(p => p.chainId === chainId) : places}
                />,
                <DropDownPartners
                    label='Produto'
                    value={productId}
                    setValue={setProductId}
                    arrayData={products}
                />
            ]}
            content={loading ? <Loader /> :
                <SoldProductsReport
                    products={products ? products.map(({ name, amount, value }) => ({ name, amount, value })) : []}
                />}
        />
    )
}

export default ReportSoldProducts;