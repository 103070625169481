import { useEffect, useState } from 'react';
import { ArrayInput, BooleanInput, Edit, FormDataConsumer, GetListParams, ReferenceArrayInput, SelectArrayInput, SelectInput, SimpleForm, SimpleFormIterator, TextInput, Toolbar, UpdateWithConfirmButton, required, useDataProvider, useNotify, useRefresh } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { getChainId } from '../../lib';

const DefaultPointsFuels = (props) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [fuels, setFuels] = useState<any[]>([]);
  const [selectsAllPlaces, setselectsAllPlaces] = useState(false);

  useEffect(() => {
    dataProvider.getList('fuels', { sort: { field: 'name', order: 'ASC' } } as GetListParams)
      .then(({ data }) => setFuels(data))
      .catch(() => notify('Erro ao buscar os combustíveis', { type: 'warning' }));
  }, []);
  const navigate = useNavigate();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify('ra.notification.updated', { messageArgs: { smart_count: 1 } });
    refresh();
  };

  const onError = () => {
    notify('ra.notification.data_provider_error', { type: 'error' });
    navigate('/default-points-fuels-edit');
  };

  const ToolbarCustom = () => {
    return (
      <Toolbar>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <UpdateWithConfirmButton
                {...rest}
                data={formData}
                confirmTitle="Atualizar pontuação"
                confirmContent={formData.fuels && formData.fuels.some(grf => grf.factor > 2) ? "A pontuação parece estar muito alta. Deseja confirmar?" : "A pontuação será atualizada. Esta medida terá efeito imediato."}
              />
            );
          }}
        </FormDataConsumer>
      </Toolbar>
    );
  }

  return (
    <Permission permission={PERMISSIONS.DEFAULT_POINTS_FUEL}>
      <Edit id={getChainId()} title="Atualizar pontuação" resource="default-points-fuels" mutationMode="pessimistic" mutationOptions={{ onSuccess, onError }}>
        <SimpleForm toolbar={<ToolbarCustom />}>
          <FormDataConsumer>
            {() => {
              return (
                <>
                  <div style={{ display: 'flex' }}>
                    {!selectsAllPlaces &&
                      <ReferenceArrayInput
                        filter={{ chainId: getChainId(), type: 'station' }}
                        sort={{ field: 'name', order: 'ASC' }}
                        source='placeIds'
                        reference='places'
                        validate={required()}
                        perPage={null}
                      >
                        <SelectArrayInput
                          label='Locais'
                          optionText='name'
                          style={{ minWidth: 320, marginRight: 12, marginTop: 2 }} />
                      </ReferenceArrayInput>
                    }
                    <BooleanInput
                      source='selectsAllPlaces'
                      label='Selecionar todos os postos'
                      onChange={value => setselectsAllPlaces(value)}
                    />
                  </div>
                </>
              )
            }}
          </FormDataConsumer>
          <ArrayInput source='fuels' label='Pontos por combustível'>
            <SimpleFormIterator disableReordering>
              <FormDataConsumer>
                {({ formData, scopedFormData, getSource }) => {
                  if (!formData.fuels) { return <></>; }
                  const selectedFuelsIds = formData.fuels.filter(f => f).map(fuel => fuel.fuelId);

                  const availableFuels = fuels.filter(({ id }) => {
                    return !selectedFuelsIds.includes(id) || (scopedFormData ? scopedFormData.fuelId === id : false);
                  });

                  availableFuels.map((fuel) => fuel.fuelId = fuel.id);

                  return (
                    <SelectInput
                      label='Combustível'
                      style={{ minWidth: 220, marginRight: 10, marginTop: 16 }}
                      choices={availableFuels}
                      source={getSource && getSource('fuelId')}
                      defaultValue={null}
                      validate={[required()]}
                    />
                  )
                }}
              </FormDataConsumer>
              <TextInput
                source='factor'
                style={{ minWidth: 150, marginTop: 2, marginRight: 10 }}
                label='Fator Pontos/Litro'
                format={value => (!value ? '0,00' : `${Number(value).toFixed(2)}`)}
                parse={value => (!value ? 0 : +value.replace(/[^\d]/g, '') / 100)}
                defaultValue={0}
                required
              />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Edit>
    </Permission>
  );
}

export default DefaultPointsFuels;