import FilterIcon from '@mui/icons-material/FilterListSharp';
import { Button, Card, Grid } from '@mui/material';
import { useState } from "react";
import { SimpleForm } from "react-admin";
import { DashboardChainInput, DashboardIntervalPicker, DashboardSelectOrigin, DashboardStationInput, Permission } from "../../components";
import { PERMISSIONS } from "../../constants";
import { getPlaceId } from '../../lib';

export const DashboardAsideFilters = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ borderRadius: 8 }}>
      <Card variant="outlined" style={{ padding: 8, borderRadius: 12, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => setIsOpen(!isOpen)}
          style={{ paddingBlock: 4, paddingInline: 6 }}
        >
          <FilterIcon titleAccess="Mais Informações" />
          {isOpen && 'Resumir'}
        </Button>
        <SimpleForm toolbar={null}>
          <DashboardIntervalPicker isOpen={isOpen} />
          <DashboardSelectOrigin isOpen={isOpen} />
          <Permission permission={PERMISSIONS.ADMIN}>
            <DashboardChainInput isOpen={isOpen} />
          </Permission>
          {
            !getPlaceId() &&
            <DashboardStationInput isOpen={isOpen} />
          }
        </SimpleForm>
      </Card>
    </Grid>
  );
};