import CampaignIcon from '@mui/icons-material/Campaign';
import list from './PromoterList';
import create from './PromoterCreate';
import edit from './PromoterEdit';

export default {
  edit,
  list,
  create,
  name: 'promoters',
  permissions: [],
  options: { label: 'Promoters' },
  icon: CampaignIcon,
}
