import { useState } from 'react';
import {
    Button,
    Confirm,
    useDataProvider,
    useNotify,
    useRefresh,
} from 'react-admin';

const RefundButton = ({ record, customerId }) => {
    const [showDialog, setShowDialog] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState()
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    const handleClick = () => {
        setShowDialog(true)
    };

    const handleCloseClick = () => {
        setShowDialog(false)
    };

    const handleSubmit = () => {
        setIsSubmitting(true)
        dataProvider.delete(`chains/chainId/customers/${customerId}/coupons`, { id: record.id })
            .then((response) => {
                console.log("🚀 ~ .then ~ response:", response)
                notify('Cupom estornado');
                setShowDialog(false)
                setIsSubmitting(false)
                refresh();
            })
            .catch((e) => {
                console.error(e);
                setIsSubmitting(false)
                notify('Erro ao estornar cupom', { type: 'warning' })
            });
    };

    return (
        <>
            {!record.redeemed && !record.refunded &&
                <Button onClick={handleClick} label="Estornar">
                </Button>}

            <Confirm
                isOpen={showDialog}
                loading={isSubmitting}
                title="Estornar cupom"
                content="Se você estornar este cupom, o cliente terá seus pontos de volta. Esta ação não pode ser desfeita."
                onConfirm={handleSubmit}
                onClose={handleCloseClick}
            />
        </>
    );
}

export default RefundButton;