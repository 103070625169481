import UserIcon from '@mui/icons-material/People'
import list from './SystemUserList'
import create from './SystemUserCreate'
import edit from './SystemUserEdit'
import { PERMISSIONS } from '../../constants'

export default {
  edit,
  list,
  create,
  name: 'system-users',
  permissions: [PERMISSIONS.ADMIN_PROFILE],
  options: { label: 'Usuários do sistema' },
  icon: UserIcon,
}
