import Group from '@mui/icons-material/Group';
import { PERMISSIONS } from '../../../constants';
import list from './pointLimitList';
import create from './pointLimitCreate';
import show from './pointLimitShow';
import ComponentWithPermissions from '../../ComponentWithPermissions';

export default {
  list,
  show,
  create: new ComponentWithPermissions({ component: create as React.ComponentType, permissions: [PERMISSIONS.POINT_RULES] }),
  name: 'chains/chainId/point-limit',
  options: { label: 'Limite de Pontuação' },
  icon: Group,
}