import LocalGasStation from '@mui/icons-material/LocalGasStation'
import list from './StationsList'
import create from './CreateStation'
import edit from './EditStation'
import show from './ShowStation'

export default {
  show,
  list,
  edit,
  create,
  name: 'stations',
  options: { label: 'Postos de combustível' },
  icon: LocalGasStation,
}
