import { PERMISSIONS } from '../../constants';
import list from './DiscountList';
import create from './DiscountCreate';
import show from './DiscountShow';

export default {
  list,
  create,
  show,
  name: 'discounts',
  permissions: [PERMISSIONS.FUEL_DISCOUNTS]
}