import { Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import {
  BulkDeleteButton,
  ChipField,
  CreateButton,
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
  TopToolbar
} from 'react-admin';
import ListPagination from '../../../components/ListPagination';
import { DATE_FORMATER, PERMISSIONS } from '../../../constants';
import { PeriodPointType } from '../../../models/periodPointType';
import { Permission } from "../../../components";
import CloneRuleButton from "../CloneRuleButton";

const useStyles = makeStyles({
  listActions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    background: 'none',
    alignItems: 'center'
  },
  warning: {
    '&::before': {
      content: "'* '",
      color: '#ff0000',
      fontSize: 20
    }
  }
});

const ListActions = () => {
  const classes = useStyles();

  return (
    <TopToolbar className={classes.listActions}>
      <Typography className={classes.warning}>
        Recomendamos que essas informações estejam presentes no regulamento do programa de fidelidade do posto
      </Typography>
      <CreateButton />
    </TopToolbar>
  );
};

const BulkActionButtons = props => (
  <BulkDeleteButton {...props} label="Deletar" />
);

export default props => (
  <List
    title="Limite de Pontos"
    sort={{ field: 'createdAt', order: 'DESC' }}
    pagination={<ListPagination />}
    actions={<ListActions />}
    exporter={false}
    perPage={25}
    {...props}
  >
    <Datagrid rowClick="show" bulkActionButtons={<BulkActionButtons />}>
      <TextField source="identify" label="Código" />
      <ReferenceArrayField label="Postos" reference="stations" source="stationIds">
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <FunctionField
        textAlign="center"
        label="Periodo"
        render={record => PeriodPointType.translateType(record?.period)}
      />
      <NumberField label="Quantidade de Pontos" source="amountPoints" textAlign='center' />
      <DateField
        source="createdAt"
        label="Data de Criação"
        options={DATE_FORMATER}
        sortable={false}
        textAlign="center"
        showTime />
      <Permission permission={PERMISSIONS.POINT_RULES}>
        <CloneRuleButton />
      </Permission>
    </Datagrid>
  </List>
)
