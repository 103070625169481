import ReviewsIcon from '@mui/icons-material/Reviews';
import list from './RatingPollItemList';
import create from './RatingPollItemCreate';
import edit from './RatingPollItemEdit';

export default {
  edit,
  list,
  create,
  name: 'rating-poll-items',
  permissions: [],
  options: { label: 'Enquetes de avaliação' },
  icon: ReviewsIcon,
}
