export enum PaymentGatewayType {
  fixpay = 'fixpay',
  quality = 'quality',
  conferePix = 'confere_pix',
}

export namespace PaymentGatewayType {
  export const translateType = (type: PaymentGatewayType): string => {
    switch (type) {
      case PaymentGatewayType.fixpay:
        return "FixPay";
      case PaymentGatewayType.quality:
        return "Quality Bank";
      case PaymentGatewayType.conferePix:
        return "Confere Pix";
      default:
        return "";
    }
  };

  export const paymentGatewayChoices = Object.values(PaymentGatewayType)
    .filter(x => typeof x === "string")
    .map((type) => ({
      id: type,
      name: PaymentGatewayType.translateType(type),
    }));
}