import React from 'react';
import { Datagrid, Filter, List, ReferenceField, ReferenceInput, SearchInput, SelectInput, TextField, usePermissions } from 'react-admin';
import { Permission, RecoveryUserExpand } from '../../components';
import { PERMISSIONS } from '../../constants';
import ListPagination from '../../components/ListPagination';

const DeletedUsersDatagrid = props => {
  const { permissions } = usePermissions();

  return (
    <Datagrid {...props} bulkActionButtons={false} rowClick="" expand={<RecoveryUserExpand />}>
      {permissions && permissions.includes(PERMISSIONS.ADMIN) &&
        <ReferenceField source="chainId" reference="chains" label="Rede">
          <TextField source="name" />
        </ReferenceField>}
      <TextField source="name" label="Nome" />
      <TextField source="login" label="Login" />
      {permissions && permissions.includes(PERMISSIONS.ADMIN) &&
        <ReferenceField
          source="profileId"
          reference="profiles"
          emptyText="Não definido"
          label="Perfil"
        >
          <TextField source="name" />
        </ReferenceField>}
    </Datagrid>
  );
};

const ListFilters = (props) => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn />
    <Permission permission={PERMISSIONS.ADMIN} alwaysOn>
      <ReferenceInput
        source="chainId"
        reference="chains"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={null}
      >
        <SelectInput label="Rede" optionText="name" emptyText="Todas"/>
      </ReferenceInput>
    </Permission>
  </Filter>
);

const DeletedUserList = props => (
  <List
    {...props}
    basePath="employees/recovery"
    resource="employees/recovery"
    title="Usuários Deletados da Rede"
    pagination={<ListPagination />}
    filters={<ListFilters />}
    perPage={25}
    exporter={false}
    actions={false}
  >
    <DeletedUsersDatagrid />
  </List>
);

export default DeletedUserList;
