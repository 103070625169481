import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

import { PERMISSIONS } from '../../constants';
import Permission from '../../components/Permission';


export default props => {
  return (
    <Permission permission={PERMISSIONS.ADMIN}>
      <Edit {...props} title="Editar usuário">
        <SimpleForm>
          <TextInput source="name" label="Nome" validate={required()} InputProps={{ autoComplete: 'off' }} />
          <TextInput source="token" label="Token" InputProps={{ autoComplete: 'off' }} />
        </SimpleForm>
      </Edit>
    </Permission>
  )
};
