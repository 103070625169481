import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import ReactDOM from 'react-dom';
import './assets/fonts/fonts.css';

import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
const firebaseConfigProd = {
    apiKey: "AIzaSyBocOqGwdGHr2Q-UzNntRm3Kx7eQ76aqg8",
    authDomain: "pontuax-admin-prod.firebaseapp.com",
    databaseURL: "https://pontuax-admin-prod.firebaseio.com",
    projectId: "pontuax-admin-prod",
    storageBucket: "pontuax-admin-prod.appspot.com",
    messagingSenderId: "769733006886",
    appId: "1:769733006886:web:0297205291342d7fa0f8e7",
    measurementId: "G-XM2Y09HVTD"
};

const firebaseConfigDev = {
    apiKey: "AIzaSyAMfJZYXLCNDUpvE7XgeQ-j92axyVbnWX0",
    authDomain: "pontuax-admin-dev.firebaseapp.com",
    databaseURL: "https://pontuax-admin-dev.firebaseio.com",
    projectId: "pontuax-admin-dev",
    storageBucket: "pontuax-admin-dev.appspot.com",
    messagingSenderId: "431514782044",
    appId: "1:431514782044:web:d328bfa5fef6e157825999",
    measurementId: "G-G5JG523C6M"
};

// Initialize Firebase
const app = initializeApp(process.env.REACT_APP_ENVIRONMENT === 'production' ? firebaseConfigProd : firebaseConfigDev);
const analytics = getAnalytics(app);
logEvent(analytics, 'app_started');