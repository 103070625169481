import { PERMISSIONS } from '../../constants'
import create from './SaleProductsCreate'
import list from './SaleProductsList'
import edit from './SaleProductsEdit'

export default {
  create,
  list,
  edit,
  name: 'chains/chainId/sale-products',
  permissions: [PERMISSIONS.CREATE_PRODUCT, PERMISSIONS.UPDATE_PRODUCT]
}