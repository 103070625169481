import React, { useState, useEffect } from 'react';
import { useDataProvider, usePermissions } from 'react-admin';
import { PERMISSIONS } from '../../constants';
import { getChainId, getEmployeeType, getAttendantId } from '../../lib';
import { forceTwoDigits } from '../../utils';
import useDashboardContext from '../../hooks/useDashboardContext';
import Permission from '../../components/Permission';
import moment from 'moment';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { CardWithIcon } from './CardWithIcon';
import { EmployeeType } from '../../models';

export const FillinsAverageTime = () => {
  const [avg, setAvg] = useState('--:--:--');

  const dataProvider = useDataProvider();
  const { permissions } = usePermissions();
  const { from, to, chain, stationIds } = useDashboardContext();

  const chainId = permissions && permissions.includes(PERMISSIONS.ADMIN) ? chain : getChainId();

  useEffect(() => {
    const filter = { from, to };
    if (stationIds && stationIds.length > 0) { filter.placeIds = stationIds; }
    if (getEmployeeType() === EmployeeType.attendant) { filter.attendantId = getAttendantId(); }

    dataProvider.getList('dashboard/fillin-average-time', {
      filter, chainId,
    }).then(({ data }) => {
      const { 0: { average } } = data;

      let avg = '--:--:--';
      if (average) {
        // hours must be more than 24
        const totalHour = moment.duration('00:00:00').add(average * 60, 'seconds').asHours();
        const hour = Math.floor(totalHour);
        const minutes = Math.floor((totalHour - hour) * 60);
        const seconds = Math.floor((((totalHour - hour) * 60) - minutes) * 60);

        avg = `${forceTwoDigits(hour)}:${forceTwoDigits(minutes)}:${forceTwoDigits(seconds)}`;
      }

      setAvg(avg);
    }).catch(err => console.error(err));
  }, [from, to, chain, stationIds]);

  return (
    <Permission permission={PERMISSIONS.FILLINS_AVERAGE_TIME}>
      <CardWithIcon
        icon={AccessTimeIcon}
        title="Um abastecimento a cada"
        subtitle={avg}
      />
    </Permission>
  );
};