import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from 'moment';
import React from "react";
import {
    Datagrid,
    DateField,
    Filter,
    NumberField,
    ReferenceArrayInput,
    ReferenceField,
    ReferenceInput,
    SelectArrayInput,
    SelectInput,
    TextField,
    useListContext,
    useRecordContext
} from 'react-admin';
import { CustomizableDatagrid, ReportList } from '../../components';
import { BetweenDatesInput } from "../../components/BetweenDatesInput";
import { CPFField } from '../../components/Fields';
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import { PERMISSIONS } from "../../constants";
import { getChainId } from "../../lib";
import RequiredChain from "../../components/RequiredChain";

const useStyles = makeStyles(theme => ({
    title: {
        margin: '10px 0 10px 0',
        textAlign: 'center',
        fontWeight: 'bold'
    },
}));

const CustomerField = () => {
    const record = useRecordContext();
    if (!record) { return null; }

    return record.customer_id
        ? <ReferenceField
            label="Cliente"
            emptyText="Não identificado"
            source="customer_id"
            basePath="customers"
            reference="customers"
            link="show"
        >
            <TextField source="name" />
        </ReferenceField>
        : <CPFField source="customer_cpf" label="CPF" emptyText="Não identificado" record={record} />
}

const RefundedCouponsList = () => {
    const classes = useStyles();
    const props = useListContext();

    let data = [];
    let summaryData = [];

    if (props.data) {
        const reportInfo = props.data[0];

        if (reportInfo) {
            data = reportInfo.data;
            summaryData = [reportInfo.summaryData];
        }
    }

    return (
        <>
            <Typography className={classes.title}>Total</Typography>
            <Datagrid bulkActionButtons={false} classes={{ headerCell: classes.headerCell }} style={{ marginBottom: 60 }} data={summaryData} total={1}>
                <NumberField
                    source="total_points"
                    label="Pontos"
                    locales="pt-BR"
                    textAlign="center"
                    emptyText="0"
                    sortable={false}
                />
                <NumberField
                    source="total_value"
                    label="Valor em Reais"
                    locales="pt-BR"
                    textAlign="center"
                    emptyText="R$ 0,00"
                    options={{
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }}
                    sortable={false}
                />
                <NumberField
                    source="total_coupons"
                    label="Quantidade de Cupons"
                    locales="pt-BR"
                    textAlign="center"
                    emptyText="0"
                    sortable={false}
                />
            </Datagrid>

            <Typography className={classes.title}>Pagamentos</Typography>
            <CustomizableDatagrid bulkActionButtons={false} resource={props.resource} data={data} >
                <CustomerField label="Cliente" />
                <TextField
                    source="barcode"
                    label="Código do Cupon"
                    textAlign="center"
                    emptyText="Não definido"
                />
                <NumberField
                    source="value"
                    label="Pontos"
                    textAlign="center"
                    emptyText="0"
                    options={{
                        maximumFractionDigits: 2
                    }}
                />
                <NumberField
                    source="partner_value"
                    label="Valor"
                    locales="pt-BR"
                    textAlign="center"
                    emptyText="Não definido"
                    options={{
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }}
                />
                <DateField source="refunded_at" label="Data do Estorno" showTime locales="pt-BR" />
                <ReferenceField label="Estorno realizado por" emptyText="Não identificado" source="refund_employee_id" basePath="employees" reference="employees">
                    <TextField source="name" />
                </ReferenceField>
            </CustomizableDatagrid>
        </>
    );
};

const FilterTablePrint = () => {
    const { filterValues } = useListContext();

    return (
        <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
            <DateField source="from" textAlign="center" label="De" sortable={false} locales="pt-BR" />
            <DateField source="to" textAlign="center" label="Até" sortable={false} locales="pt-BR" />
            <ReferenceField label="Posto" emptyText="Todos" source="placeId" textAlign="center" basePath="places" reference="places" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} locales="pt-BR" />
        </Datagrid>
    );
}

const FilterList = () => {
    const { filterValues } = useListContext();

    React.useEffect(() => {
        if (filterValues.productIds) {
            delete filterValues.productIds;
        }
    }, [filterValues.placeId]);

    return (
        <Filter>
            <BetweenDatesInput alwaysOn />
            <ReferenceInput
                source="placeId"
                reference="stations"
                sort={{ field: "name", order: "ASC" }}
                perPage={false}
                alwaysOn
            >
                <SelectInput
                    label="Posto"
                    emptyText="Todos"
                    optionText={'name'}
                />
            </ReferenceInput>
            {(filterValues.placeId &&
                <ReferenceArrayInput
                    source="productIds"
                    reference='chains/chainId/products'
                    filter={{ placeId: filterValues.placeId }}
                    sort={{ field: "name", order: "ASC" }}
                    style={{ width: 160 }}
                    alwaysOn
                >
                    <SelectArrayInput
                        emptyText="Todos"
                        label="Produto"
                        optionText='name'
                    />
                </ReferenceArrayInput>
            )}
        </Filter>
    );
}

const ReportRefundedCoupons = (props) => {
    return (
        <Permission permission={PERMISSIONS.REFUNDED_COUPONS_REPORT}>
            <RequiredChain>
                <ReportList
                    {...props}
                    basePath="report-refunded-coupons"
                    title="Cupons estornados"
                    resource="chains/chainId/reports/refunded-coupons"
                    filters={<FilterList />}
                    filter={getChainId() ? { chainId: getChainId() } : null}
                    sort={{ field: 'coupon.created_at', order: 'DESC' }}
                    perPage={25}
                    filterDefaultValues={{
                        from: moment().startOf('day').toISOString(),
                        to: moment().endOf('day').toISOString()
                    }}
                    pagination={<ListPagination />}
                    bulkActionButtons={false}
                    titleOnPrint="Relatório de cupons estornados"
                    filterTablePrint={<FilterTablePrint />}
                    landscape={true}
                    showPagination
                >
                    <RefundedCouponsList />
                </ReportList>
            </RequiredChain>
        </Permission>
    )
};

export default ReportRefundedCoupons;