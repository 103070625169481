import { makeStyles } from "@mui/styles";
import moment from 'moment';
import { useEffect, useState } from "react";
import { Datagrid, DateField, ReferenceField, ReferenceInput, SelectInput, TextField, useListContext, useNotify, } from 'react-admin';
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { GenericDatagrid, GenericDatagridField, Permission, ReportList, RotatedAxisTick } from '../../components';
import { BetweenDatesInput } from "../../components/BetweenDatesInput";
import ListPagination from "../../components/ListPagination";
import { PERMISSIONS } from "../../constants";
import { getChainId, getPlaceId } from "../../lib";
import { formatNumber } from '../../utils';

const colors = ['#608DE0', '#E0348A', '#E0AD3F', '#34E034', '#17E0DC', '#9934E0', '#E0793F', '#E05C3F', '#4612E0', '#16E097', '#E0D134', '#E016CE'];

const useStyles = makeStyles(theme => ({
	groupInput: {
		minWidth: 180,
		'& p': {
			height: 0,
		},
	}
}));

const StationRatingList = () => {
	const [stationData, setStationData] = useState([]);
	const { data, error, filters } = useListContext();
	const notify = useNotify();
	const stationRating = data ? Object.values(data)[0] : undefined;

	if (error) { notify('Erro ao carregar Relatório de Avaliações do Posto', { type: 'warning' }); };

	const sortData = (a, b) => {
		return b.avg - a.avg;
	}

	useEffect(() => {
		if (stationRating) {
			if (filters && filters.stationId) {
				setStationData(stationRating.data);
			} else {
				const filteredData = stationRating.data.filter(station => !!station.avg).sort(sortData);
				setStationData(filteredData);
			}
		}
	}, [data]);

	const content = [
		<GenericDatagridField
			label='Posto'
			source='name'
		/>,
		<GenericDatagridField
			label='Nota Média'
			source='avg'
			render={({ avg }) => formatNumber(avg, { minimumFractionDigits: 2 })}
		/>,
		<GenericDatagridField
			label='Total de Avaliações'
			source='count'
			render={({ count }) => formatNumber(count)}
		/>
	];

	return (
		<>
			<GenericDatagrid
				style={{ margin: '0 0 40px 0' }}
				textAlign='center'
				title='Relatório de Avaliação de Postos'
				data={stationData}
			>
				{content}
			</GenericDatagrid>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
				<LineChart width={790} height={600} data={stationRating && stationRating.summaryData}>
					<XAxis dataKey="date" tick={<RotatedAxisTick fontSize={16} />} height={100} />
					<CartesianGrid strokeDasharray="3 3" />
					<YAxis domain={[0, 5]} type='number' />
					<Tooltip itemStyle={{ fontSize: 12 }} formatter={(avg) => formatNumber(avg, { minimumFractionDigits: 2 })} />

					{stationRating && stationRating.data.map((station, i) =>
						stationRating.summaryData && stationRating.summaryData.some(point => point[`${station.id}-avg`]) &&
						<Line
							connectNulls
							type='monotone'
							dataKey={`${station.id}-avg`}
							stroke={i <= 11 ? colors[i] : colors[((i + 1) % 12) - 1]}
							name={station.name}
							activeDot={{ r: 5 }}
							key={i}
						/>
					)}
				</LineChart>
			</div>
		</>);
};


const stationRatingExporter = async (data, fetchRelatedRecords) => {
	let stationRatingInfo = data[0].data;
	const relations = [{ field: 'id', resource: 'places' }];

	const [fetchStations] = await fetchRelatedRecords(relations, stationRatingInfo);

	const getStation = (id) => {
		return fetchStations?.results?.find(station => station.id === id);
	}

	stationRatingInfo = stationRatingInfo.map(stationRating => {
		const { id, ...restStationRating } = stationRating;
		restStationRating.avg = Number.parseFloat(restStationRating.avg).toFixed(2);
		const station = getStation(id);
		return { ...restStationRating, station_name: station ? station.name : 'Sem nome' };
	});

	return stationRatingInfo;
}

const FilterTablePrint = () => {
	const { filterValues } = useListContext();
	return (
		<>
			<Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
				<DateField source="from" textAlign="center" label="De" sortable={false} />
				<DateField source="to" textAlign="center" label="Até" sortable={false} />
				<ReferenceField label="Posto" emptyText="Todos" source="stationId" textAlign="center" reference="stations" link={false}>
					<TextField source="name" />
				</ReferenceField>
				<DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
			</Datagrid>
			<StationRatingList />
		</>
	);
}

const StationRatingReport = (props) => {
	const listFilters = [
		<BetweenDatesInput alwaysOn />,
		<ReferenceInput source="stationId" reference="stations" perPage={null} sort={{ field: "name", order: "ASC" }} alwaysOn>
			<SelectInput
				label="Posto"
				emptyText="Todos"
				optionText={'name'}
			/>
		</ReferenceInput>,
		<ReferenceInput source="chainId" reference="chains" perPage={null} sort={{ field: "name", order: "ASC" }} alwaysOn={!getChainId()}>
			<SelectInput
				label="Rede"
				emptyText="Todos"
				optionText={'name'}
			/>
		</ReferenceInput>,
	];

	const placeId = getPlaceId();
	const stationsIndex = listFilters.findIndex(({ props }) => props.reference === 'stations')
	if (placeId && stationsIndex > -1) {
		listFilters.splice(stationsIndex, 1)
	}

	return (
		<Permission permission={PERMISSIONS.STATION_RATING_REPORT}>
			<ReportList
				{...props}
				basePath="report-station-rating"
				title="Avaliação de Postos"
				resource="reports/station-rating"
				filters={listFilters}
				sort={{ field: 'name', order: 'DESC' }}
				perPage={10}
				filterDefaultValues={{
					stationId: placeId,
					from: moment().subtract(30, 'd').startOf('day').toISOString(),
					to: moment().endOf('day').toISOString()
				}}
				pagination={<ListPagination />}
				bulkActionButtons={false}
				customExporter={stationRatingExporter}
				filterTablePrint={<FilterTablePrint />}
				landscape
			>
				<StationRatingList />
			</ReportList>
		</Permission >
	);
};

export default StationRatingReport;