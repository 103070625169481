import Group from '@mui/icons-material/Group';
import { PERMISSIONS } from '../../../constants';
import list from './limitNumberFillinList';
import create from './limitNumberFillinCreate';
import show from './limitNumberFillinShow';
import ComponentWithPermissions from '../../ComponentWithPermissions';

export default {
  list,
  show,
  create: new ComponentWithPermissions({ component: create as React.ComponentType, permissions: [PERMISSIONS.POINT_RULES] }),
  name: 'chains/chainId/limit-number-fillin',
  options: { label: 'Limite de Número de Abastecimentos' },
  icon: Group,
}