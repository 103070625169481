import TimeIcon from '@mui/icons-material/DateRange';
import { Collapse } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DateInput, DateTimeInput, FilterList } from 'react-admin';
import useDashboardContext from '../../hooks/useDashboardContext';
import moment from 'moment';

const useStyle = makeStyles({
  date: isOpen => ({
    marginTop: 8,
    marginLeft: isOpen && 16,
    padding: 4,
    width: isOpen ? '6em' : '5.5em',
  })
});

const DashboardIntervalPicker = ({ isOpen }) => {
  const { from, setFrom, to, setTo } = useDashboardContext();

  return (
    <>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <FilterList
          label="Intervalo"
          icon={<TimeIcon titleAccess="Intervalo" />}
        />
      </Collapse>
      <DateInput
        label='De'
        source="from"
        ampm={false}
        parse={(value) => moment(value).toISOString()}
        defaultValue={moment().startOf('day').subtract(30, 'days').toISOString()}
        onChange={date => setFrom(date.target.value)}
        inputProps={{
          max: moment(to).format('YYYY-MM-DD')
        }}
        sx={{ minWidth: 160 }}
      />
      <DateInput
        label='Até'
        source="to"
        ampm={false}
        parse={(value) => moment(value).toISOString()}
        defaultValue={moment().endOf('day').toISOString()}
        onChange={date => setTo(date.target.value)}
        inputProps={{
          min: moment(from).format('YYYY-MM-DD'),
          max: moment().format('YYYY-MM-DD')
        }}
        sx={{ minWidth: 160 }}
      />
    </>
  );
}

export default DashboardIntervalPicker;