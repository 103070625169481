import {
  Button, useDataProvider,
  useRecordContext, useRefresh
} from 'react-admin';

const GiftDisableButton = (props) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const record = useRecordContext();
  if (!record) { return null; }

  return (
    <Button
      {...props}
      disabled={!record?.isEnabled}
      label={record?.isEnabled ? "Desativar brinde" : "Brinde desativado"}
      variant={props.isOutlined ? "outlined" : undefined}
      onClick={() => {
        dataProvider.update('gifts', { id: record.id, data: { isEnabled: false }, previousData: { ...record } })
          .then(() => {
            refresh();
          })
          .catch(() => console.log("Erro na mensagem"));
      }}
    />
  );
};

export default GiftDisableButton;